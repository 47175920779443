import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { NavList } from 'modules/common';
import { X_AXIS_FILTERS_MAP } from './dashboard.tools';
import './dashboard.scss';
import 'modules/common/lists/list-header.scss';

function DateFilter({ onSelectDateRange, dateRange, children }) {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={children ? 8 : 12} className="px-0">
          <NavList activeKey={dateRange.dateRange} onSelect={onSelectDateRange}>
            {Object.values(X_AXIS_FILTERS_MAP).map(({ dateRange, value }) => {
              return (
                <NavList.Item eventKey={dateRange} key={dateRange}>
                  {value}
                </NavList.Item>
              );
            })}
          </NavList>
        </Col>
        {children}
      </Row>
    </Container>
  );
}

DateFilter.propTypes = {
  onSelectDateRange: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  children: PropTypes.any,
};
export default DateFilter;
