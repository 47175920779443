import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { LogOnMount } from 'react-amplitude-hooks';

import { Button, Typography, setTitle } from 'modules/common';
import 'modules/pages/pages.scss';
import { LOGIN_STATUSES, profileCandidateSelector, loginStatusSelector } from 'core/login';

const getReturnText = profileCandidate => {
  const { isReadOnly, isDonor, isAgency, isMyPagesOnly, isMerchOnly } = profileCandidate;
  if (isAgency) {
    if (isReadOnly) return 'Donors';
    if (isMyPagesOnly) return 'Pages';
    if (isMerchOnly) return 'Merchandise';
    return 'Dashboard';
  }

  if (isReadOnly) return 'Donors';
  if (isDonor) return 'Team Pages';
  if (isMyPagesOnly) return 'Pages';
  if (isMerchOnly) return 'Merchandise';
  return 'Dashboard';
};

const getRedirectLink = (profileCandidate, returnText) => {
  const returnTextMap = {
    Donors: 'people/donors',
    Dashboard: 'dashboard',
    Pages: 'pages/my-pages',
    Merchandise: 'merchandise/products',
  };
  if (returnText === 'Team Pages') {
    return '/team-pages/pages';
  }
  return `/${profileCandidate.revv_uid}/${returnTextMap[returnText]}`;
};

class PageNotFound extends React.Component {
  render() {
    const { state } = this.props.location;

    const returnText = getReturnText(this.props.profileCandidate);
    const redirectLink = getRedirectLink(this.props.profileCandidate, returnText);

    return (
      <div className="error-page">
        {setTitle('Not Found')}
        <LogOnMount
          eventType="404 page"
          eventProperties={{
            referrer: state ? state.referrer : 'none',
          }}
        />
        <Typography variant="h1" className="error-code">
          404
        </Typography>
        <Typography variant="h2" className="page-title">
          Whoops!
        </Typography>

        <div className="body">
          <Typography>Page not found.</Typography>
          {this.props.loginStatus === LOGIN_STATUSES.LOGGED_IN ? (
            <Button
              variant="primary"
              onClick={() => {
                if (this.props.history) this.props.history.push(redirectLink);
                else window.location.href = redirectLink;
              }}
            >
              Return to {returnText}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                if (this.props.history) this.props.history.push('/login');
                else window.location.href = '/login';
              }}
            >
              Return to Login
            </Button>
          )}
        </div>
      </div>
    );
  }
}

PageNotFound.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  loginStatus: PropTypes.oneOf(Object.values(LOGIN_STATUSES)).isRequired,
  profileCandidate: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profileCandidate: profileCandidateSelector(state),
    loginStatus: loginStatusSelector(state),
  };
};

export default connect(mapStateToProps)(PageNotFound);
