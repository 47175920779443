import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal, DragDrop } from 'modules/common';
import UpsellItem from './upsell-item.component';
import { CommitteeUpsell, PanelUpsell } from 'modules/pages/upsells/drawer/new-upsell';

const DISABLED_DRAG_UPSELL_TYPES = [CommitteeUpsell.type, PanelUpsell.type];

function UpsellsList({
  upsells = [],
  onRemoveUpsell,
  onUpdateEditedUpsellGroup,
  onEditExistingUpsell,
  disabledUpsellFlow = false,
}) {
  const [selectedUpsellToRemove, setSelectedUpsellToRemove] = useState(null);
  const shownUpsells = upsells || [];

  // panel types kept at bottom and are disabled
  const disabledItems = disabledUpsellFlow
    ? shownUpsells
    : shownUpsells.filter(upsell => DISABLED_DRAG_UPSELL_TYPES.includes(upsell.upsell_page.type));

  return (
    <>
      <DragDrop
        items={shownUpsells}
        onUpdate={onUpdateEditedUpsellGroup}
        name="upsell_pages"
        disabledItems={disabledItems}
      >
        {({ item }) => {
          if (item._destroy) return null;
          return (
            <UpsellItem
              upsell={item}
              setSelectedUpsellToRemove={setSelectedUpsellToRemove}
              onEditExistingUpsell={onEditExistingUpsell}
              disabledUpsellFlow={disabledUpsellFlow}
            />
          );
        }}
      </DragDrop>

      <ConfirmModal
        show={!!selectedUpsellToRemove}
        title="Remove Existing Upsell"
        buttonText="Remove Upsell"
        buttonColor="cancel"
        handleClose={() => setSelectedUpsellToRemove(null)}
        onSubmit={() => {
          onRemoveUpsell(selectedUpsellToRemove);
          setSelectedUpsellToRemove(null);
        }}
      >
        Are you sure you want to remove this upsell from your page?
      </ConfirmModal>
    </>
  );
}

UpsellsList.propTypes = {
  onRemoveUpsell: PropTypes.func.isRequired,
  onUpdateEditedUpsellGroup: PropTypes.func.isRequired,
  onEditExistingUpsell: PropTypes.func.isRequired,
  disabledUpsellFlow: PropTypes.bool,
  upsells: PropTypes.array,
};

export default UpsellsList;
