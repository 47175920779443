import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';

function FormContainer({ children, className }) {
  return (
    <Container fluid>
      <Row className={className ? className : 'py-3 justify-content-center'}>{children}</Row>
    </Container>
  );
}

FormContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default FormContainer;
