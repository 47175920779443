import produce from 'immer';

import { SET_DONORS_FILTER, SET_LEADS_FILTER } from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialPeopleState = {
  donorsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    isRevvUser: null,
    activeTabKey: 'all',
    contributorType: [],
    page: 1,
  },
  leadsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    sourceType: null,
    page: 1,
  },
};

export default produce((state = initialPeopleState, action) => {
  switch (action.type) {
    case SET_DONORS_FILTER:
      return { ...state, donorsFilter: action.filter };
    case SET_LEADS_FILTER:
      return { ...state, leadsFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialPeopleState };
    default:
      return state;
  }
}, initialPeopleState);
