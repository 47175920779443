import produce from 'immer';

import { SET_TOAST, CLEAR_TOAST } from './types';

const initialState = null;

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_TOAST:
      draft = action.response;
      break;

    case CLEAR_TOAST:
      draft = null;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
