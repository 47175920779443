import { ExternalLink } from 'modules/common';
import React from 'react';

export const DYNAMIC_DONATION_TYPES = {
  AVERAGE_CONTRIBUTION: 'AVERAGE_CONTRIBUTION',
  MOST_RECENT_CONTRIBUTION: 'MOST_RECENT_CONTRIBUTION',
  MULTIPLY_EXISTING_AMOUNTS: 'MULTIPLY_EXISTING_AMOUNTS',
  REPLACE_EXISTING_AMOUNTS: 'REPLACE_EXISTING_AMOUNTS',
};

export const DONATION_AMOUNT_ARRAY_MULTIPLY_STRING = '0.5,0.75,1,1.25,1.5,1.75,2';
export const DONATION_AMOUNT_ARRAY_REPLACE_STRING = '50,100,250,500,1000,1650,3300';

export const defaultPageDonationAmounts = [25, 50, 100, 250, 500, 1650, 3300];

export const SEGEMENT_DYNAMIC_DONATION_TYPES = [
  { label: 'Average Contribution', value: 'AVERAGE_CONTRIBUTION' },
  { label: 'Most Recent Contribution', value: 'MOST_RECENT_CONTRIBUTION' },
  { label: 'Multiply Existing Amounts', value: 'MULTIPLY_EXISTING_AMOUNTS' },
  { label: 'Replace Existing Amounts', value: 'REPLACE_EXISTING_AMOUNTS' },
];

export const SEGMENT_DYNAMIC_PREVIEW_DESCRIPTION_MAP = {
  AVERAGE_CONTRIBUTION: "Enter a Donor's Average Contribution to preview how it will work",
  MOST_RECENT_CONTRIBUTION: "Enter a Donor's Most Recent Contribution to preview how it will work",
};

export const showPreviewTextDynamicAmounts = ['AVERAGE_CONTRIBUTION', 'MOST_RECENT_CONTRIBUTION'];
export const showMultiplyDonationAmounts = [
  'AVERAGE_CONTRIBUTION',
  'MOST_RECENT_CONTRIBUTION',
  'MULTIPLY_EXISTING_AMOUNTS',
];

const DESCRIPTION_TEXT_MAP = {
  AVERAGE_CONTRIBUTION:
    "Customize the suggested Donation Amount Buttons by multiplying the Donor's average contribution.",
  MOST_RECENT_CONTRIBUTION:
    "Customize the suggested Donation Amount Buttons by multiplying the Donor's most recent contribution.",
  MULTIPLY_EXISTING_AMOUNTS:
    'Customize the suggested Donation Amount Buttons by multiplying the existing amounts on the page.',
  REPLACE_EXISTING_AMOUNTS:
    'Customize the suggested Donation Amount Buttons by replacing the existing amounts on the page with new amounts. Try the Preview tool to see how it works.',
};

export const getDescriptionText = dynamicAmountType => {
  return (
    <>
      {DESCRIPTION_TEXT_MAP[dynamicAmountType]}{' '}
      <ExternalLink href="https://support.winred.com/en/articles/8594211-dynamic-amounts">Learn More</ExternalLink>.
    </>
  );
};
