import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import {
  AddListItemButton,
  DragDrop,
  FlexBinaryRowValidator,
  FormCardSpaced,
  TitleWithCog,
  Input,
  Typography,
  useLocalStorage,
  Error,
  useToast,
} from 'modules/common';
import uuid from 'core/utilities/uuid';
import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { noop } from 'core/utilities';

import SearchCommittee from './search-committee.component';
import { generateCandidateAmounts, getAllowedCandidatesToAdd, useValidateCommmittees } from './tools';
import CommitteeCard from './committee-card.component';
import { setCandidateLevel } from 'modules/pages/common/addCommittee/tools';
import './committees.scss';

/**
 * general list of committees/candidates
 */
export function ConnectedCommitteeList({
  eventName,
  onChange,
  selectedCandidates = [],
  committeeHasAmounts = false,
  searchTitle = '',
  addCommitteeDescription,
  addCommitteeButtonDescription,
  className = '',
  disabled = false,
  showConduitingAndDataSharingToggle = false,
  isTeamPages = false,
  isPetitionPage = false,
  splitEvenlyName = 'splitEvenly',
  splitEvenly = true,
  GET_COMMITTEES_QUERY,
  onlyAllowProfileCandidate = false,
  isPage = false,
}) {
  const [showExtraOptions, setShowExtraOptions] = useLocalStorage('committeesExtraOptions', false);
  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [allCandidatesToAdd, setAllCandidatesToAdd] = useState([]);
  const [timeoutId, setTimeoutId] = useState('');

  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const isRequiredForOrganization = isOrganization && !isPage;

  const [loadCommittees, { loading, data, error }] = useLazyQuery(GET_COMMITTEES_QUERY);

  const setToast = useToast();

  // saves results after api call is finished
  useEffect(() => {
    if (loading || !data) return;

    // federal, state, and team pages
    const allResults =
      data.viewer?.candidate?.available_pac_candidates?.results ||
      data.viewer?.state_level_organization?.available_pac_candidates?.results ||
      data.win_red_directory_candidates?.results ||
      [];

    const allCandidatesToAdd = allResults.map(c => setCandidateLevel(c));
    setAllCandidatesToAdd(allCandidatesToAdd);
  }, [data, loading, setAllCandidatesToAdd]);

  // lazy load results after 3 chars min
  const _setDropdownSearchValue = useCallback(
    value => {
      setDropdownSearchValue(value);

      // reset dropdown values so we dont show previous results before searching
      setAllCandidatesToAdd([]);

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        const variables = { limit: 20, search: value };
        if (isOrganization) variables.organizationRevvUid = profileCandidate.organization_revv_uid;
        if (value && value.length > 2) loadCommittees({ variables });
      }, 400);

      setTimeoutId(_id);
    },
    [setDropdownSearchValue, timeoutId, loadCommittees, isOrganization, profileCandidate]
  );

  const toggleExtraOptions = useCallback(() => setShowExtraOptions(state => !state), [setShowExtraOptions]);

  // only allow candidates not already added to committee list
  const allowedCandidatesToAdd = useMemo(
    () => getAllowedCandidatesToAdd(selectedCandidates, allCandidatesToAdd),
    [selectedCandidates, allCandidatesToAdd]
  );

  // when selecting a new canddiate from the lazy loaded dropdown to add to a page
  const onSelectCandidate = useCallback(
    (newRevvUid, oldRevvUid) => {
      // if the candidate existed already then remove destroy flag to reselect that candidate
      // then remove temp candidate since we are replacing it
      const candidateExisted = selectedCandidates.find(c => {
        const _candidate = c.candidate || c.organization;
        return _candidate.revv_uid === newRevvUid;
      });
      if (candidateExisted) {
        const newCandidates = selectedCandidates
          .map(candidate => {
            if (candidate === candidateExisted) {
              candidate._destroy = false;
              candidate.vendorFees = []; // reset selected vendor fees when adding a candidate back
              if (committeeHasAmounts) candidate.pageAmounts = generateCandidateAmounts();
            }

            return candidate;
          })
          .filter(selectedCandidates => {
            const candidate = selectedCandidates.candidate || selectedCandidates.organization;
            return candidate.revv_uid !== oldRevvUid;
          });

        onChange({ target: { name: eventName, value: newCandidates } });
        return;
      }

      // else is a new candidate so replace the temp with the new candidate
      const newCandidate = allowedCandidatesToAdd.find(c => c.revv_uid === newRevvUid);

      const newCandidates = selectedCandidates.map(conduitCandidate => {
        const candidate = conduitCandidate.candidate || conduitCandidate.organization;

        if (candidate.revv_uid === oldRevvUid) {
          // format selected pac candidate to a conduit page candidate format to add to page
          return {
            ...conduitCandidate,
            ...{
              ...newCandidate,
              candidate: {
                revv_uid: newCandidate.revv_uid,
                avatar: newCandidate.avatar,
                avatar_image_url: newCandidate.avatar_image_url,
                first_name: newCandidate.first_name,
                last_name: newCandidate.last_name,
                name: newCandidate.name,
                state: newCandidate.state,
                office: newCandidate.office,
                district: newCandidate.district,
                available_vendors: newCandidate.available_vendors,
                can_access_vendors: newCandidate.can_access_vendors || false,
              },
            },
            _isNew: true,
          };
        }

        return conduitCandidate;
      });

      onChange({ target: { name: eventName, value: newCandidates } });
    },
    [allowedCandidatesToAdd, committeeHasAmounts, eventName, onChange, selectedCandidates]
  );

  // If there are state level candidates, donations must be split evenly.
  const splitEvenlyIfStateLevelPresent = useCallback(() => {
    // If splitting evenly, the check should pass.
    if (splitEvenly) return true;
    // If not splitting evenly and there are zero state level candidates the check should pass.
    return selectedCandidates.filter(candidate => candidate._isStateLevel && !candidate._destroy).length === 0;
  }, [selectedCandidates, splitEvenly]);

  // Clear the percentages when donations are not split evenly.
  const clearCandidatePercentages = () =>
    onChange({
      target: {
        name: eventName,
        value: selectedCandidates.map(candidate => ({ ...candidate, defaultPercentage: '' })),
      },
    });

  const addCandidate = useCallback(() => {
    const newCandidate = {
      _isNew: true,
      candidate: {
        name: '',
        revv_uid: uuid(),
        available_vendors: [],
      },
      allowDataSharing: true,
      id: selectedCandidates.length + 1,
    };
    if (committeeHasAmounts) newCandidate.pageAmounts = generateCandidateAmounts();
    const newCandidates = [...selectedCandidates, newCandidate];
    onChange({ target: { name: eventName, value: newCandidates } });
  }, [onChange, committeeHasAmounts, eventName, selectedCandidates]);

  const removeCandidate = useCallback(
    editedCandidate => {
      if (!onlyAllowProfileCandidate) {
        const newCandidates = selectedCandidates
          .map(conduitCandidate => {
            const candidate = conduitCandidate.candidate || conduitCandidate.organization;
            const _editedCandidate = editedCandidate.candidate || editedCandidate.organization;

            if (candidate.revv_uid === _editedCandidate.revv_uid) conduitCandidate._destroy = true;
            return conduitCandidate;
          })
          .filter(v => Boolean(v));

        onChange({ target: { name: eventName, value: newCandidates } });
      } else {
        setToast({
          message: 'Your organization cannot be removed from a page that accepts contributions other than Personal.',
          isError: true,
        });
      }
    },
    [eventName, onChange, selectedCandidates, onlyAllowProfileCandidate, setToast]
  );

  // at least one is required so check if at least one is on the ui
  const hasCandidateValues = useMemo(
    () =>
      selectedCandidates
        .filter(c => !c._destroy)
        .map(c => c.candidate?.name || c.organization?.name)
        .join(''),
    [selectedCandidates]
  );

  // dynamically create any error messages for organization candidates (this may be complex so extract here)
  const { organizationIsValid, warningMessage, errorMessage, hideMessages } = useValidateCommmittees({
    selectedCandidates,
    profileCandidate,
    isOrganization,
  });

  const toggleSplitEvenly = event => onChange(event, () => !event.target.value && clearCandidatePercentages());

  const dragDropDisabledCandidates = disabled
    ? selectedCandidates
    : !splitEvenly || isPetitionPage
    ? selectedCandidates
    : [];

  const percentagesAddUpTo100Percent = () => {
    // if split evenly or petition page then we arent manually settings percentages so this will always be good to go
    if (splitEvenly || isPetitionPage) return true;

    const sum = selectedCandidates
      .filter(c => !c._destroy)
      .reduce((total, candidate) => {
        total = total + parseInt(Number(candidate.defaultPercentage) * 100, 10);
        return total;
      }, 0);

    return sum === 10000;
  };

  if (error) return <Error error={error} />;

  return (
    <FormCardSpaced
      className={'add-candidates-container-card ' + className}
      title={
        <TitleWithCog
          title="Add Committees"
          showCog={showConduitingAndDataSharingToggle}
          onClickCog={toggleExtraOptions}
          cogEnabled={showExtraOptions}
        />
      }
      subtitle={addCommitteeDescription}
    >
      <div>
        {showConduitingAndDataSharingToggle && showExtraOptions && (
          <FormCardSpaced className="mb-3">
            <FlexBinaryRowValidator
              disabled={disabled}
              title="Evenly Split Donation Amounts?"
              subTitle="Split the donations evenly amongst the candidates."
              value={splitEvenly}
              name={splitEvenlyName}
              onChange={toggleSplitEvenly}
              validators={[splitEvenlyIfStateLevelPresent]}
              errorMessages={['If merchant candidates are added, then the donation amounts must be split evenly.']}
            />
          </FormCardSpaced>
        )}

        <DragDrop
          items={selectedCandidates}
          onUpdate={onChange}
          name={eventName}
          disabledItems={dragDropDisabledCandidates}
        >
          {({ item: conduitCandidate, index, sortingDisabled }) => {
            const candidate = conduitCandidate.candidate || conduitCandidate.organization;

            return conduitCandidate._destroy ? null : (
              <div key={candidate.revv_uid + index}>
                {conduitCandidate._isNew && !candidate?.name ? (
                  <SearchCommittee
                    conduitCandidate={conduitCandidate}
                    searchTitle={searchTitle}
                    removeCandidate={removeCandidate}
                    allowedCandidatesToAdd={allowedCandidatesToAdd}
                    onSelectCandidate={onSelectCandidate}
                    dropdownSearchValue={dropdownSearchValue}
                    setDropdownSearchValue={_setDropdownSearchValue}
                    loading={loading}
                    noOptionsMessage={
                      dropdownSearchValue.length < 3
                        ? 'Please enter at least 3 characters to search.'
                        : 'No results found.'
                    }
                  />
                ) : (
                  <CommitteeCard
                    isBillPay={conduitCandidate.vendorFees?.length > 0 || candidate.available_vendors?.length > 0}
                    sortingDisabled={sortingDisabled}
                    conduitCandidate={conduitCandidate}
                    selectedCandidates={selectedCandidates}
                    eventName={eventName}
                    committeeHasAmounts={committeeHasAmounts}
                    disabled={disabled}
                    isOrganization={isOrganization}
                    isTeamPages={isTeamPages}
                    isPetitionPage={isPetitionPage}
                    percentagesAddUpTo100Percent={percentagesAddUpTo100Percent}
                    hasCandidateValues={hasCandidateValues}
                    // Petition pages and team pages don't have a choice
                    splitEvenly={isTeamPages || isPetitionPage || splitEvenly}
                    onChange={onChange}
                    onRemove={removeCandidate}
                    showExtraOptions={showConduitingAndDataSharingToggle && showExtraOptions}
                  />
                )}
              </div>
            );
          }}
        </DragDrop>
      </div>

      {!splitEvenly && (
        <div className="my-1 d-flex justify-content-end">
          <Input
            hidden
            value={hasCandidateValues}
            onChange={noop}
            validators={[percentagesAddUpTo100Percent]}
            errorMessages={['Percentages must add up to 100%']}
          />
        </div>
      )}

      {hideMessages ? null : (
        <div>
          <div className="mt-1">
            <Input
              hidden
              value={hasCandidateValues}
              onChange={noop}
              validators={[() => organizationIsValid]}
              errorMessages={[errorMessage]}
            />
            {warningMessage}
          </div>

          <Typography>
            <span className="font-weight-bold p--mirage">Important Notice:</span>
            <span>
              &nbsp;WinRed offers functionality that allows you to add candidates and groups to your pages and upsells.
              This gives donors who visit your pages the option to give to entities other than your own. It is your
              responsibility to evaluate the legality of using this feature and whether it might trigger any fundraising
              requirements or registrations with state, local election or nonprofit agencies.
            </span>
          </Typography>
        </div>
      )}

      <AddListItemButton
        addItem={addCandidate}
        addText="Add Committee"
        addSubText={addCommitteeButtonDescription}
        disabled={disabled || onlyAllowProfileCandidate}
      />

      {/* if org then use custom validation */}
      {disabled || isRequiredForOrganization ? null : (
        <Input
          hidden
          value={hasCandidateValues}
          onChange={noop}
          validators={['required']}
          errorMessages={[`You must add at least one committee to your ${isPage ? 'page' : 'upsell'}.`]}
        />
      )}
    </FormCardSpaced>
  );
}

ConnectedCommitteeList.propTypes = {
  eventName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCandidates: PropTypes.array.isRequired,
  committeeHasAmounts: PropTypes.bool,
  addCommitteeDescription: PropTypes.string.isRequired,
  addCommitteeButtonDescription: PropTypes.string.isRequired,
  searchTitle: PropTypes.string,
  showConduitingAndDataSharingToggle: PropTypes.bool,
  splitEvenly: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isTeamPages: PropTypes.bool,
  isPetitionPage: PropTypes.bool,
};
