import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';
import { upsellNavTabs } from '../../../../pages/upsells-list.container';
import { useSelector } from 'react-redux';
import { upsellTabSelector } from '../../../../pages/store';

function NavBarMenuPages({
  toggleSubNav,
  hasActiveChildPages,
  showMobileSubNav,
  organizationRevvUid,
  isOrganization,
  removeAccess,
  isMyPagesOnly,
}) {

  const activeUpsellTab = useSelector(upsellTabSelector);
  const [upsellsUrl, setUpsellsUrl] = useState(null);

  useEffect(() => {
    if (activeUpsellTab) {
      const urlExtension = upsellNavTabs.find(item => item.value === activeUpsellTab).url;
      setUpsellsUrl(urlExtension);
    }
  }, [activeUpsellTab, upsellsUrl]);

  if (removeAccess.includes(true)) return null;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildPages,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showPagesSubNav')}>
          Pages
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size="xs"
            color={showMobileSubNav ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>
      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        <Nav.Link eventKey={`/${organizationRevvUid}/pages/my-pages`} href={`/${organizationRevvUid}/pages/my-pages`}>
          My Pages
        </Nav.Link>
        {isOrganization || isMyPagesOnly ? null : (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/pages/connected-pages`}
            href={`/${organizationRevvUid}/pages/connected-pages`}
          >
            Connected Pages
          </Nav.Link>
        )}
        {isMyPagesOnly ? null : (
          <>
            <Nav.Link eventKey={`/${organizationRevvUid}/pages/campaigns`} href={`/${organizationRevvUid}/pages/campaigns`}>
              Campaigns
            </Nav.Link>
            <Nav.Link
              eventKey={upsellsUrl ? `/${organizationRevvUid}${upsellsUrl}` : `/${organizationRevvUid}/pages/upsell-flows`}
              href={upsellsUrl ? `/${organizationRevvUid}${upsellsUrl}` : `/${organizationRevvUid}/pages/upsell-flows`}
            >
              Upsells
            </Nav.Link>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/pages/petition-pathways`}
              href={`/${organizationRevvUid}/pages/petition-pathways`}
            >
              Petition Pathways
            </Nav.Link>
          </>
        )}
      </MobileSubMenu>
    </>
  );
}

NavBarMenuPages.propTypes = {
  isOrganization: PropTypes.bool.isRequired,
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildPages: PropTypes.bool.isRequired,
  showMobileSubNav: PropTypes.bool.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
};

export default NavBarMenuPages;
