import React from 'react';

import { ListHeader, IndexList, Typography, ExternalLink } from 'modules/common';

import { useSelector } from 'react-redux';
import { profileCandidateDisputeRatesSelector, profileCandidateEligibleForFinesSelector } from '../../core/login';
import { Col, Container, Row } from 'react-bootstrap';
import { Breakpoint } from 'react-socks';
import { DisputesTableMobileComponent } from './disputes-table-mobile.component';
import { DisputesTableDesktopComponent } from './disputes-table-desktop.component';

function DisputesList() {
  const disputeRates = useSelector(profileCandidateDisputeRatesSelector);
  const isEligibleForFines = useSelector(profileCandidateEligibleForFinesSelector);

  const headerSubtitle = (
    <div>
      Months where your Dispute Status is available.{' '}
      <ExternalLink href="https://support.winred.com/en/articles/6492995">Learn More</ExternalLink>.
    </div>
  );

  const renderHeader = () => {
    return (
      <>
        <ListHeader title="Visa Dispute History" subtitle={headerSubtitle} />
        {isEligibleForFines ? (
          <Container fluid className="trends-bar">
            <Row className="justify-content-center">
              <Col lg={11} className="px-3">
                <Row>
                  <Col sm={3} lg="auto" className="trend-box">
                    <Typography variant="h5" className="d-flex text-winred-red">
                      You are eligible for fines.
                    </Typography>
                    <div className="d-flex">
                      <Typography className="mb-0">
                        <span className="value">
                          You are eligible for fines until you have three consecutive months of Low Dispute Volume,
                          Good, or Warning status.
                        </span>
                      </Typography>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        ) : null}
      </>
    );
  };

  return (
    <IndexList data={disputeRates} renderHeader={renderHeader} pageTitle="Dispute Activity">
      <>
        <Breakpoint small down>
          <DisputesTableMobileComponent disputeRates={disputeRates} />
        </Breakpoint>

        <Breakpoint medium up>
          <DisputesTableDesktopComponent disputeRates={disputeRates} />
        </Breakpoint>
      </>
    </IndexList>
  );
}

export { DisputesList };
