import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormContainer, FormColumn } from 'modules/common';

function IndexTable({ children, className }) {
  return (
    <FormContainer>
      <FormColumn className={classnames('index-table-container', className)}>{children}</FormColumn>
    </FormContainer>
  );
}

IndexTable.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default IndexTable;
