import React from 'react';
import PropTypes from 'prop-types';

import { FormCard, FormCardSpaced } from 'modules/common';
import { UpsellName, UpsellAction, AcceptButton, UpsellVideoEmbed, EnhancedUpsell, UpsellDonations } from './sections';

function SingleUpsell(props) {
  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
        <AcceptButton {...props} />
      </FormCardSpaced>

      <FormCard title="Amount" subtitle="Enter a donation amount for your upsell.">
        <UpsellDonations {...props} maxNumberOfItems={3} />
      </FormCard>

      <UpsellVideoEmbed {...props} />

      <EnhancedUpsell {...props} />
    </>
  );
}

SingleUpsell.title = 'Single Amount';
SingleUpsell.description = 'Ask donors for another donation.';
SingleUpsell.icon = 'upsell-single';
SingleUpsell.type = 'SINGLEAMOUNT';
SingleUpsell.widgets = [];

SingleUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default SingleUpsell;
