import gql from 'graphql-tag';
import { upsellGroupFragment, upsellFragment } from './upsells.queries';
import { availablePacCandidateFragment, campaignFragment } from './page-fragments';

// dropdown to add an upsell to an upsell group
export const GET_NEW_ORGANIZATION_UPSELLS_QUERY = gql`
  query getOrganizationUpsells($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: 9999 }, include_public: true) {
          results {
            revv_uid
            name
            type
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
          }
        }
      }
    }
  }
`;

// when selecting a new upsell to create get data needed to generate it
export const GET_NEW_ORGANIZATION_UPSELL_QUERY = gql`
  query getOrganizationNewUpsell($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        enable_donor_cover_fees
        available_pac_candidates(filters: { revvUid: $organizationRevvUid }) {
          results {
            ...AvailablePacCandidate
          }
        }
      }
    }
  }
  ${availablePacCandidateFragment}
`;

// upsell group details
export const GET_ORGANIZATION_UPSELL_GROUP_QUERY = gql`
  query getOrganizationUpsellGroup($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells {
          total_count
        }
        upsell_groups(filters: { revvUid: $revv_uid }) {
          results {
            ...UpsellGroup
          }
        }
      }
    }
  }
  ${upsellGroupFragment}
`;

// when adding an existing upsell group to a page (need to get rest of details)
export const GET_ADD_ORGANIZATION_UPSELL_GROUP_QUERY = gql`
  query getAddOrganizationUpsellGroups($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { revvUid: $revv_uid }, include_public: true) {
          results {
            ...UpsellGroup
          }
        }
      }
    }
  }
  ${upsellGroupFragment}
  ${upsellFragment}
`;

// get campaigns for slate/connected campaign upsell cards
export const GET_ORGANIZATION_UPSELL_CAMPAIGNS = gql`
  query getOrganizationUpsellCampaigns($limit: Int!, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $limit }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

// get an upsell for upsell details view
export const GET_ORGANIZATION_UPSELL_QUERY = gql`
  query getOrganizationUpsell($organizationRevvUid: String, $revv_uid: String!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { revvUid: $organizationRevvUid }) {
          results {
            ...AvailablePacCandidate
          }
        }
        upsells(filters: { revvUid: $revv_uid }, include_public: true) {
          results {
            ...Upsell
          }
        }
      }
    }
  }
  ${upsellFragment}
  ${availablePacCandidateFragment}
`;

// get list of all upsells for list view
export const GET_ORGANIZATION_UPSELLS_LIST_QUERY = gql`
  query getOrganizationUpsellsList(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $type: UpsellTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            type: $type
          }
        ) {
          current_page
          page_count
          total_count
          results {
            created_at
            updated_at
            revv_uid
            name
            type
            public_upsell
            bill_pay_exists
            upsell_group_count
            upsell_pathway_count
            upsell_preview_url
            upsell_statistics {
              view
              accepted
              percentage
              revenue_per_view
            }
          }
        }
      }
    }
  }
`;

// get list of all upsell groups for list view
export const GET_ORGANIZATION_UPSELL_GROUPS_LIST_QUERY = gql`
  query getOrganizationUpsellGroupsList(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsell_groups(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
          }
        ) {
          current_page
          page_count
          total_count
          results {
            created_at
            updated_at
            revv_uid
            name
            page_count
            upsell_count
            public_upsell_group
            bill_pay_exists
            upsell_group_statistics {
              view
              accepted
              percentage
            }
            upsell_pages {
              id
              position
              upsell_page {
                type
                revv_uid
              }
            }
          }
        }
      }
    }
  }
`;

// get list of all upsells and upsell groups for adding upsell groups/upsells
export const GET_ORGANIZATION_UPSELLS_AND_UPSELL_GROUPS_QUERY = gql`
  query getOrganizationUpsellsAndUpsellGroup($organizationRevvUid: String!, $limit: Int!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
            type
          }
        }
        upsell_groups(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            public_upsell_group
            upsell_creator
            upsell_creator_revv_uid
          }
        }
      }
    }
  }
`;
export const GET_ORGANIZATION_UPSELLS_QUERY = gql`
  query getExistingOrganizationUpsells($organizationRevvUid: String!, $limit: Int!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
            type
          }
        }
      }
    }
  }
`;

export const SEARCH_ORGANIZATION_UPSELLS_QUERY = gql`
  query searchExistingOrganizationUpsells($organizationRevvUid: String!, $limit: Int!, $search: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            name
            revv_uid
          }
        }
      }
    }
  }
`;

export const SEARCH_ORGANIZATION_UPSELLS_ID_QUERY = gql`
  query searchExistingOrganizationUpsellIds($limit: Int!, $organizationRevvUid: String, $search: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            id
            revv_uid
            name
            type
          }
        }
      }
    }
  }
`;

export const SEARCH_ORGANIZATION_UPSELLS_GROUP_QUERY = gql`
  query searchExistingOrganizationUpsellGroups($organizationRevvUid: String!, $limit: Int!, $search: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            name
            revv_uid
          }
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION_UPSELL_MUTATION = gql`
  mutation createOrganizationUpsellMutation($upsell: CreateUpsellInput!) {
    organizationCreateUpsell(upsell: $upsell) {
      errors
      upsell {
        ...Upsell
      }
    }
  }
  ${upsellFragment}
`;

export const CREATE_ORGANIZATION_UPSELL_GROUP_MUTATION = gql`
  mutation createOrganizationUpsellGroup($upsellGroup: CreateUpsellGroupInput!) {
    organizationCreateUpsellGroup(upsellGroup: $upsellGroup) {
      errors
      upsellGroup {
        ...UpsellGroup
      }
    }
  }
  ${upsellGroupFragment}
`;

export const DUPLICATE_ORGANIZATION_UPSELL_MUTATION = gql`
  mutation OrganizationDuplicateUpsell($upsell: DuplicateUpsellInput!) {
    organizationDuplicateUpsell(upsell: $upsell) {
      errors
      duplicatedUpsell {
        revv_uid
        name
        type
      }
    }
  }
`;

export const DUPLICATE_ORGANIZATION_UPSELL_FLOW_MUTATION = gql`
  mutation OrganizationDuplicateUpsellFlow($upsellGroup: DuplicateUpsellGroupInput!) {
    organizationDuplicateUpsellGroup(upsellGroup: $upsellGroup) {
      errors
      duplicatedUpsellGroup {
        revv_uid
        name
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_UPSELL_MUTATION = gql`
  mutation updateOrganizationUpsellMutation($upsell: UpdateUpsellInput!) {
    organizationUpdateUpsell(upsell: $upsell) {
      errors
      upsell {
        ...Upsell
      }
    }
  }
  ${upsellFragment}
`;

export const UPDATE_ORGANIZATION_UPSELL_GROUP_MUTATION = gql`
  mutation updateOrganizationUpsellGroupMutation($upsellGroup: UpdateUpsellGroupInput!) {
    organizationUpdateUpsellGroup(upsellGroup: $upsellGroup) {
      errors
      upsellGroup {
        ...UpsellGroup
      }
    }
  }
  ${upsellGroupFragment}
`;

export const ORGANIZATION_UPSELL_FLOW_ARCHIVE_MUTATION = gql`
  mutation organizationArchiveUpsellFlow($upsellFlow: ArchiveUpsellFlowInput!) {
    organizationArchiveUpsellFlow(upsellFlow: $upsellFlow) {
      errors
      message
      dammed
    }
  }
`;

export const ORGANIZATION_UPSELL_ARCHIVE_MUTATION = gql`
  mutation organizationArchiveUpsellMutation($upsell: ArchiveUpsellInput!) {
    organizationArchiveUpsell(upsell: $upsell) {
      errors
      message
      dammed
    }
  }
`;

export const GET_ORGANIZATION_UPSELL_CAMPAIGN_OPTIONS = gql`
  query getOrganzationUpsellCampaignOptions($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD], limit: 9999 }) {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;
