import {
  getPastWeek,
  getMonthToDate,
  getPreviousMonth,
  getPreviousQuarter,
  getYearToDate,
  getAllTime,
} from 'core/utilities';
import { Icon } from '../../common';
import React from 'react';

export const getDashBoardDateRange = newDateRange => X_AXIS_FILTERS_MAP[newDateRange];

export const X_AXIS_FILTERS_MAP = {
  PAST_WEEK: { dateRange: 'PAST_WEEK', value: 'Past Week', interval: 'DAY', getDateRange: getPastWeek },
  MONTH_TO_DATE: { dateRange: 'MONTH_TO_DATE', value: 'Month to Date', interval: 'DAY', getDateRange: getMonthToDate },
  PREVIOUS_MONTH: {
    dateRange: 'PREVIOUS_MONTH',
    value: 'Previous Month',
    interval: 'DAY',
    getDateRange: getPreviousMonth,
  },
  PREVIOUS_QUARTER: {
    dateRange: 'PREVIOUS_QUARTER',
    value: 'Previous Quarter',
    interval: 'DAY',
    getDateRange: getPreviousQuarter,
  },
  YEAR_TO_DATE: { dateRange: 'YEAR_TO_DATE', value: 'Year to Date', interval: 'MONTH', getDateRange: getYearToDate },
  ALL_TIME: { dateRange: 'ALL_TIME', value: 'All Time', interval: 'MONTH', getDateRange: getAllTime },
};

export const DISPUTE_STATUSES = {
  LOW_DISPUTE_VOLUME: { value: 'LOW_DISPUTE_VOLUME', label: 'Low Dispute Volume' },
  GOOD: { value: 'GOOD', label: 'Good' },
  WARNING: { value: 'WARNING', label: 'Warning' },
  STANDARD_LEVEL: { value: 'STANDARD_LEVEL', label: 'High' },
  EXCESSIVE_LEVEL: { value: 'EXCESSIVE_LEVEL', label: 'Excessive' },
};

export const getDisputeIcon = disputeStatus => {
  switch (disputeStatus) {
    case DISPUTE_STATUSES.WARNING.value:
      return <Icon icon="yellow-calendar" />;
    case DISPUTE_STATUSES.STANDARD_LEVEL.value:
      return <Icon icon="orange-calendar" />;
    case DISPUTE_STATUSES.EXCESSIVE_LEVEL.value:
      return <Icon icon="red-calendar" />;
    default:
      // cases of LOW_DISPUTE_VOLUME and GOOD, both get green icons
      return <Icon icon="green-calendar" />;
  }
};

export const formatDisputeRate = dispute => {
  if (dispute.percentage && dispute.status !== DISPUTE_STATUSES.LOW_DISPUTE_VOLUME.value) {
    return (dispute.percentage * 100).toFixed(2) + '%';
  } else {
    return '-';
  }
};
