import gql from 'graphql-tag';

import { abTestFragment } from './abtesting.queries';

export const GET_ORGANIZATION_AB_TESTING_QUERY = gql`
  query getOrganizationAbTests(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $status: AbTestStatusTypeEnum
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        ab_tests(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            status: $status
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
          }
        ) {
          page_count
          total_count
          current_page
          results {
            ...AbTest
          }
        }
      }
    }
  }
  ${abTestFragment}
`;

export const CREATE_ORGANIZATION_AB_TEST_MUTATION = gql`
  mutation createOrganizationAbTestMutation($ab_test: AbTestInput!, $organizationRevvUid: String) {
    organizationCreateOrUpdateAbTest(abTest: $ab_test, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;

export const UDPATE_ORGANIZATION_AB_TEST_MUTATION = gql`
  mutation updateOrganizationAbTestMutation($ab_test: AbTestInput!, $organizationRevvUid: String) {
    organizationCreateOrUpdateAbTest(abTest: $ab_test, organizationRevvUid: $organizationRevvUid) {
      errors
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;

export const END_ORGANIZATION_AB_TEST_MUTATION = gql`
  mutation endOrganizationAbTestMutation($abTestRevvUid: String!, $organizationRevvUid: String) {
    organizationEndAbTest(abTestRevvUid: $abTestRevvUid, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;
