import { convertEpochTime } from 'core/utilities';
import { formatConduitsForServer } from './page.tools';
import { OG_TITLE, OG_DESCRIPTION } from './new-page.tools';

export const updateTeamPageForServer = page => {
  page = JSON.parse(JSON.stringify(page));

  [
    '__typename',
    'created_at',
    'updated_at',
    'url',
    'recurringUpsell',
    'recurringUpsellOptIn',
    'smsOptinUpsell',
    'smsOptinUpsellOptIn',
    'doubleUpsell',
    'doubleUpsellOptIn',
  ].forEach(k => delete page[k]);

  // make sure all amounts have a value
  if (page.pageAmounts.length)
    page.pageAmounts = page.pageAmounts
      .map(donation => {
        delete donation.__typename;
        if (donation.new) {
          delete donation.id;
        }
        delete donation.new;
        return donation;
      })
      .filter(donationAmount => donationAmount.amount);

  page.internalName = page.publicTitle;
  page = formatConduitsForServer({ item: page, isTeamPage: true });

  // All team pages are evenly split and personal donations active.
  page.splitEvenly = true;
  page.enablePersonalDonations = true;
  if (page.recurringDefaultValue === null) {
    page.recurringDefaultValue = true;
  }

  return page;
};

export const getSaveTeamPageEventData = page => {
  const conduitPageCandidates = (page.conduitPageCandidates || []).filter(c => {
    if (c._isNew) return !c._destroy;
    return true;
  });
  return {
    'page id': page.revv_uid,
    'created date': convertEpochTime(page.created_at),
    'internal name': page.internalName,
    slug: page.slug,
    title: page.publicTitle,
    amounts: page.pageAmounts.map(amountObject => {
      return amountObject.amount.toString();
    }),
    type: page.type,
    'candidate count': conduitPageCandidates.length,
    candidates: conduitPageCandidates.map(candidate => candidate.name).join(', '),
  };
};

export const cantSaveTeamPage = newState => {
  const isSamePageState = JSON.stringify(newState.page) === JSON.stringify(newState.originalPage);

  // cant save if no candidates selected
  const noCandidates = (newState.page.conduitPageCandidates || []).filter(c => !c._destroy).length === 0;

  // all page candidates must have a chosen name
  const emptyCandidate = (newState.page.conduitPageCandidates || []).filter(c => !c.name && !c._destroy).length > 0;

  return isSamePageState || noCandidates || emptyCandidate;
};

export const defaultTeamPage = props => ({
  enableMobileField: false,
  requireMobileField: false,
  enableSmsOptin: true,
  enableRecurringUpsell: true,
  enableShippingDetails: false,
  disclaimer: '',
  sourceCode: '',
  enableDoubleUpsell: true,

  recurringAvailable: true,
  recurringInterval: 'MONTH',
  recurringIntervalSelectable: false,
  recurringEndAt: '',

  confirmationParagraph: 'Thank you for your donation!',
  status: 'LIVE',
  layout: 'FLOATING',
  position: 'CENTER',
  locale: 'ENGLISH',
  internalName: '',
  slug: '',
  callToAction: '',
  publicTitle: '',
  primaryColor: '#C24242',
  secondaryColor: '#003D7B',
  backgroundColor: '#B7455A',
  pageAmounts: [
    { id: 1, new: true, position: 1, amount: 25, destroy: false },
    { id: 2, new: true, position: 2, amount: 50, destroy: false },
    { id: 3, new: true, position: 3, amount: 100, destroy: false },
    { id: 4, new: true, position: 4, amount: 250, destroy: false },
    { id: 5, new: true, position: 5, amount: 500, destroy: false },
    { id: 6, new: true, position: 6, amount: 3300 / 2, destroy: false },
    { id: 7, new: true, position: 7, amount: 3300, destroy: false },
  ],
  conduitPageCandidates: [],
  type: 'CONDUIT',
  customFields: [],

  ogTitle: OG_TITLE,
  ogDescription: OG_DESCRIPTION,
  ogAssignedImage: null,
  ogVideo: '',

  twitterTitle: OG_TITLE,
  twitterDescription: OG_DESCRIPTION,
  twitterAssignedImage: null,
});
