import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { profileCandidateSelector } from 'core/login';

export default function useTypeAhead(query, limit = 20, addedVariables = {}) {
  const profileCandidate = useSelector(profileCandidateSelector);

  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const [timeoutId, setTimeoutId] = useState('');

  const [searchQuery, results] = useLazyQuery(query);

  // lazy load results after 3 chars min
  const _setDropdownSearchValue = useCallback(
    value => {
      setDropdownSearchValue(value);

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        const variables = {
          limit,
          search: value,
          organizationRevvUid: profileCandidate.organization_revv_uid,
          ...addedVariables,
        };
        if (value && value.length > 2) searchQuery({ variables });
      }, 400);

      setTimeoutId(_id);
    },
    [setDropdownSearchValue, timeoutId, searchQuery, profileCandidate, limit, addedVariables]
  );

  return [results, dropdownSearchValue, _setDropdownSearchValue];
}
