export const formatCampaignOptions = data => {
  if (!data.viewer) return { data };

  const campaignOptions = createCampaignOptions(data);
  return { data: { ...data, campaignOptions } };
};

export const createCampaignOptions = data => {
  if (!data?.viewer) return [];
  const candidate = data.viewer.candidate || data.viewer.state_level_organization;
  const campaignOptions = createCandidateCampaignOptions(candidate);

  return campaignOptions;
};

export const createCandidateCampaignOptions = candidate => {
  if (!candidate) return [];

  const campaignOptions = (candidate.campaigns?.results || []).map(campaign => ({
    label: campaign.name,
    value: campaign,
  }));

  return campaignOptions;
};

export const CANDIDATE_OFFICE_MAP = {
  house: 'US House',
  senate: 'US Senate',
  president: 'President',
  national_committee: 'National Party',
  state_committee: 'State Party',
  pac: 'PAC',
  super_pac: 'Super PAC',
  state_governor: 'Governor',
  state_senate: 'State Senate',
  state_house: 'State House',
  state_executive: 'State Executive',
  state_local: 'Local',
  state_other: 'Other',
};

export const getCandidateLabel = (candidate, searchDisplay) => {
  const name =
    candidate.first_name && candidate.last_name ? `${candidate.first_name} ${candidate.last_name}` : candidate.name;
  const office = candidate?.office ? CANDIDATE_OFFICE_MAP[candidate?.office] : '';
  const state = candidate?.state ? `${office ? ' ' : ''}${candidate?.state}` : '';
  const district = candidate?.district ? `-${candidate?.district}` : '';
  const candidateDetials = `${office}${state}${district}`;
  return searchDisplay
    ? `${name}${candidateDetials && ` (${candidateDetials})`}`
    : candidateDetials && `: ${candidateDetials}`;
};
