import { dateAndSearchBuilder, LIST_PAGE_SIZE } from 'modules/common';

export function queryBuilderUpsellPathways(filter) {
  let variables = {
    page: filter.page || 1,
    limit: LIST_PAGE_SIZE,
    type: filter.type,
    name: filter.search,
  };

  variables = dateAndSearchBuilder({
    variables,
    search: filter.search,
    dateRangeStart: filter.dateRangeStart,
    dateRangeEnd: filter.dateRangeEnd,
  });

  return variables;
}
