import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Input, FloatGroup, Card, Icon } from 'modules/common';
import CustomFieldRequired from './required.component';
// import CustomFieldDefault from './default-value.component';
import { FlexBinaryChoiceRow } from 'modules/common';
import '../custom.scss';

let tempKey = 1;
const newOption = {
  key: tempKey,
  value: '',
};

function CustomFieldChoice({ updateCustomField, formState, isSelectField, disabled }) {
  const fieldOptions = formState.options || [{ ...newOption }];
  const optionValues = fieldOptions.map(opt => opt.value);

  // update option within field data within a custom field
  const updateOptionDataField = (key, value) => {
    const newOptions = fieldOptions.map(option => {
      if (option.key === key) option.value = value;
      return option;
    });
    updateCustomField({ target: { name: 'options', value: newOptions } });
  };

  const addOption = () => {
    const option = { ...newOption, key: ++tempKey };
    const newOptions = [...fieldOptions, option];
    updateCustomField({ target: { name: 'options', value: newOptions } });
  };

  const removeOption = key => {
    const newOptions = fieldOptions.filter(option => option.key !== key);
    updateCustomField({ target: { name: 'options', value: newOptions } });
  };

  const checkDuplicateOption = option => {
    const duplicates = optionValues.filter(optionValue => optionValue === option).length < 2;
    return duplicates;
  };

  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="label"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Field Label"
          value={formState.label}
          maxLength="255"
          disabled={disabled}
          onChange={updateCustomField}
          data-testid="custom-field-label"
        />
      </FloatGroup>

      <div className="mt-2">
        {fieldOptions.map(fieldOption => {
          return (
            <div key={fieldOption.key} className="position-relative">
              <FloatGroup>
                <Input
                  type="text"
                  name="label"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  placeholder="Field Option"
                  disabled={disabled}
                  value={fieldOption.value}
                  maxLength="255"
                  onChange={e => updateOptionDataField(fieldOption.key, e.target.value)}
                  validators={[
                    formState.other_field_enabled ? () => true : 'required',
                    checkDuplicateOption
                  ]}
                  errorMessages={['Field Option is required', 'Cannot have the same option twice.']}
                />
              </FloatGroup>
              <Icon
                icon="times"
                size="1x"
                className={classnames('mr-2 mt-1 custom-close-btn', { pointer: !disabled })}
                onClick={disabled ? null : () => removeOption(fieldOption.key)}
              />
            </div>
          );
        })}
      </div>

      <Card className="d-flex flex-row my-3 p-3 pointer" variant="green" onClick={addOption}>
        <Icon icon="plus-circle" size="1x" className="mr-2 mt-1" />
        <div>{isSelectField ? 'Add Select Field Option' : formState.input_type === 'MULTIPLE_CHOICE' ? 'Add Checkbox Option' : 'Add Radio Button Option'}</div>
      </Card>

      {/* <CustomFieldDefault
        handleUpdateValue={updateCustomField}
        fieldOptions={fieldOptions}
        selectedValue={formState.default_value}
      /> */}

      <FlexBinaryChoiceRow
        title="Show Other Field"
        subTitle="Allow users to enter in their own value"
        value={formState.other_field_enabled || false}
        name="other_field_enabled"
        onChange={updateCustomField}
        disabled={disabled}
      />
      <CustomFieldRequired onChange={updateCustomField} value={formState.required || false} disabled={disabled} />
    </>
  );
}

CustomFieldChoice.propTypes = {
  updateCustomField: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  isSelectField: PropTypes.bool,
};

export default CustomFieldChoice;
