import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';

function NavBarMenuBillpays({
  toggleSubNav,
  hasActiveChildBillpays,
  showMobileSubNav,
  organizationRevvUid,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildBillpays,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showBillpaysSubNav')}>
          Bill-Pays
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size="xs"
            color={showMobileSubNav ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>
      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        <Nav.Link
          eventKey={`/${organizationRevvUid}/vendors/billpays/pages`}
          href={`/${organizationRevvUid}/vendors/billpays/pages`}
        >
          Bill-Pays on Pages
        </Nav.Link>
        <Nav.Link
          eventKey={`/${organizationRevvUid}/vendors/billpays/upsells`}
          href={`/${organizationRevvUid}/vendors/billpays/upsells`}
        >
          Bill-Pays on Upsells
        </Nav.Link>
      </MobileSubMenu>
    </>
  );
}

NavBarMenuBillpays.propTypes = {
  isOrganization: PropTypes.bool.isRequired,
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildBillpays: PropTypes.bool.isRequired,
  showMobileSubNav: PropTypes.bool.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
};

export default NavBarMenuBillpays;
