import { getDateOnly } from 'core/utilities';

/**
 * adds and formats amount/amountPredicate to filter if needed
 */
export const amountBuilder = (variables, filter) => {
  if (filter.amount) {
    variables.amount = Number(filter.amount);
    variables.amountPredicate = filter.amountPredicate;
  }

  return variables;
};

/**
 * adds date ranges and search term if needed
 */
export const dateAndSearchBuilder = ({ variables, search = '', dateRangeStart = '', dateRangeEnd = '' }) => {
  if (search !== null && search !== '') variables.search = search;

  /* Dates are given the candidate timezone on the API side and on our side, we
  assume that the user is requesting dates based on the candidate timezone. This
  makes all searches consistent by candidate, regardless of where the user is
  located. */
  if (dateRangeStart !== null && dateRangeStart !== '') {
    const _dateRangeStart = typeof dateRangeStart === 'function' ? dateRangeStart() : dateRangeStart;
    variables.dateRangeStart = getDateOnly(_dateRangeStart);
  }

  if (dateRangeEnd !== null && dateRangeEnd !== '') {
    const _dateRangeEnd = typeof dateRangeEnd === 'function' ? dateRangeEnd() : dateRangeEnd;
    variables.dateRangeEnd = getDateOnly(_dateRangeEnd);
  }

  return variables;
};

export const statusAndHouseAndCategoryBuilder = (variables, filter) => {
  if (filter.productCategory) {
    variables.productCategory = filter.productCategory;
  }

  if (filter.status) {
    if (filter.status === 'live') {
      variables.status = 'LIVE';
    } else if (filter.status === 'not_live') {
      variables.status = 'NOT_LIVE';
    }
  }

  if (filter.fulfillmentHouse) {
    variables.fulfillmentHouse = filter.fulfillmentHouse;
  }

  return variables;
};
