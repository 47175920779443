import React from 'react';
import classname from 'classnames';

import { Checkbox } from 'modules/common';

export function WinRedDisclaimer({ text, name, label, value, disabled, onChange, children }) {
  return (
    <div
      className={classname('mt-4 p-4 rounded  border-dashed border-2', {
        'border-winred-green bg--panache': disabled,
        'border-rajah bg--banana': !disabled,
      })}
    >
      {text}

      <div className="rounded p-3 border-1 border-nepal bg--white font-weight-bold c--mirage">
        <Checkbox name={name} label={label} value={value || false} disabled={disabled} onChange={onChange} />
      </div>

      {children}
    </div>
  );
}
