import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import 'whatwg-fetch';
import { graphql } from '@apollo/client/react/hoc';

import { Form } from 'react-bootstrap';

import { LOGIN_MUTATION } from 'core/middleware/queries';
import { Button, Card, Input, FloatGroup, setTitle, withAmplitude } from 'modules/common';
import { setLoginStatus, LOGIN_STATUSES } from 'core/login';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';

import LoginHeader from './login-header.component';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
    };

    this.localStorageDisabled = false;
    try {
      /* If localStorage is null or access is disabled, disable logging in and
      report to the user via a banner. */
      if (!localStorage) {
        this.localStorageDisabled = true;
      } else {
        localStorage.getItem('test');
      }
    } catch (err) {
      this.localStorageDisabled = true;
    }
    if (this.localStorageDisabled) {
      props.setToast({
        message: 'Please enable cookies or exit private browsing session to login.',
        isError: true,
        isPermanent: true,
      });
    }
    this.validation = React.createRef();
  }

  handleForgotPassword = event => {
    event.preventDefault();
    this.props.history.push('/forgot-password');
  };

  onLoginSubmit = event => {
    event.preventDefault();
    if (this.validation.current.errors()) return;

    const { email, password } = this.state;

    this.setState({ loading: true }, () => {
      this.props.loginUser({ email, password }).then(this.onLoginComplete).catch(this.onLoginError);
    });
  };

  onLoginError = () => {
    this.setState({ loading: false });
    this.props.setToast({
      message: 'There was an error logging in. Check your connection and try again.',
      isError: true,
    });
  };

  onLoginComplete = ({ data }) => {
    this.setState({ loading: false });

    if (!data || !data.login) {
      this.props.setToast({
        message: 'There was an error logging in. Check your email and password and try again.',
        isError: true,
      });
      return;
    }

    if (data.login.error) {
      this.props.setToast({
        message: data.login.error,
        isError: true,
      });
      return;
    }

    if (data.login.token) {
      this.props.setLoginStatus({
        status: LOGIN_STATUSES.LOGGING_IN,
        token: data.login.token,
      });
      return;
    }

    this.props.logEvent('log in');
    const doesTwoFactorNeedInstalled = data.login.user.install_two_factor;

    this.props.setLoginStatus({
      status: doesTwoFactorNeedInstalled ? LOGIN_STATUSES.TWO_FA_SETUP : LOGIN_STATUSES.TWO_FA_VERIFICATION,
      doesTwoFactorNeedInstalled,
    });
  };

  onSignUp() {
    const registrationUrl = process.env.REACT_APP_WINRED_REGISTRATION_URL;
    window.location.href = `${registrationUrl}/?utm_source=portal-login&utm_medium=portal`;
    return null;
  }

  render() {
    const loading = this.state.loading;

    return (
      <>
        {setTitle('Login')}
        <LoginHeader
          title="Log in to Your WinRed Account"
          subtitle="Welcome back to WinRed! Sign in by entering your email and password below."
        >
          <Card>
            <Card.Body>
              <ValidationBlock ref={this.validation}>
                <Form onSubmit={this.onLoginSubmit}>
                  <FloatGroup>
                    <Input
                      className="recording-sensitive"
                      placeholder="Email Address"
                      id="email-address"
                      name="email-address"
                      disabled={loading}
                      aria-label="Email Address"
                      type="email"
                      value={this.state.email}
                      validators={['required']}
                      errorMessages={['Enter a valid email address.']}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </FloatGroup>

                  <FloatGroup>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      aria-label="Password"
                      className="recording-sensitive"
                      placeholder="Password"
                      disabled={loading}
                      value={this.state.password}
                      validators={['required']}
                      errorMessages={['Enter a password.']}
                      onChange={e =>
                        this.setState({
                          password: e.target.value,
                        })
                      }
                    />
                  </FloatGroup>

                  <Button
                    variant="primary"
                    size="lg"
                    fullWidth
                    type="submit"
                    disabled={loading || this.localStorageDisabled}
                    ariaLabel="Sign In"
                  >
                    {loading ? 'Signing In...' : 'Sign In'}
                  </Button>

                  <div className="text-center mt-2">
                    <a href="/" onClick={this.handleForgotPassword}>
                      Forgot your password?
                    </a>
                  </div>
                </Form>
              </ValidationBlock>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="success"
                size="lg"
                fullWidth
                ariaLabel="Sign Up"
                disabled={loading}
                onClick={this.onSignUp}
              >
                Don&apos;t have an account? Apply here!
              </Button>
            </Card.Footer>
          </Card>
        </LoginHeader>
      </>
    );
  }
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  setLoginStatus: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setLoginStatus: payload => dispatch(setLoginStatus(payload)),
  };
};

export default compose(
  withRouter,
  withAmplitude,
  connect(null, mapDispatchToProps),
  graphql(LOGIN_MUTATION, {
    props: ({ mutate }) => ({
      loginUser: variables => mutate({ variables }),
    }),
  })
)(LoginContainer);
