import React from 'react';
import { Link } from 'react-router-dom';
import { IndexTableContextMenu } from 'modules/common';
import { Dropdown } from 'react-bootstrap';

export const PagesDropdownMenu = ({ dropdownAction, item, profileCandidate }) => {
  const { revv_uid, type, status, qrCodeUrl, highDollar, billPayExists, pageFees } = item;
  const { isAdmin, isEditor, isMyPagesOnly, isPagesOnly } = profileCandidate;
  const canExportDonations = isAdmin || isEditor || isMyPagesOnly;
  return (
    <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
      {billPayExists ? (
        <Dropdown.Item as="div" disabled className="page-billpay-notify no-pointer p-3">
          <div>Bill-Pay</div>
          <p className="font-weight-light m-0">This page has a Bill-Pay attached.</p>
        </Dropdown.Item>
      ) : null}
      {highDollar ? (
        <Dropdown.Item as="div" disabled className="page-high-dollar-notify no-pointer p-3">
          <div>High Dollar Page</div>
          <p className="font-weight-light m-0 word-wrap-break-word">
            Minimum $500 donations. {pageFees?.revvFeePercentage}% processing rate for donations made through this page.
          </p>
        </Dropdown.Item>
      ) : null}
      <Dropdown.Item as={Link} to={`/${profileCandidate.organization_revv_uid}/pages/my-pages/${revv_uid}`}>
        View Details
      </Dropdown.Item>

      {status === 'ARCHIVED' ? null : (
        <>
          <Dropdown.Item eventKey="copy">Copy URL</Dropdown.Item>
          <Dropdown.Item as="a" target="_blank" rel="noopener noreferrer" href={item.url}>
            View Page
          </Dropdown.Item>
          <Dropdown.Item eventKey={billPayExists ? 'duplicate-bill-pay' : 'duplicate'}>Duplicate Page</Dropdown.Item>
          {isMyPagesOnly || isPagesOnly ? null : <Dropdown.Item eventKey="bulk-page">Bulk Duplicate</Dropdown.Item>}
          {qrCodeUrl && <Dropdown.Item eventKey="qrCode">View QR Code</Dropdown.Item>}
        </>
      )}
      {type === 'PETITION' && <Dropdown.Item eventKey="exportLeads">Export Leads</Dropdown.Item>}
      {type !== 'PETITION' && canExportDonations && (
        <Dropdown.Item eventKey="exportDonations">Export Donations</Dropdown.Item>
      )}
    </IndexTableContextMenu>
  );
};
