import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import { Card, Icon } from 'modules/common';
import '../common.scss';

function InfoCardBody({ icon, color, iconColor, link, children }) {
  return (
    <Card variant={color} className="info-card-body mt-3 px-0" as={link ? Link : 'div'} to={link}>
      <Container fluid>
        <Row>
          <Col xs={2} className="info-card-icon">
            <Icon className="icon" icon={icon} color={iconColor || color} />
          </Col>
          <Col xs={10} className="p-0">
            <Container fluid>{children}</Container>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

InfoCardBody.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

InfoCardBody.defaultProps = {
  color: 'blue',
  cardSubtitle: '',
};

export default InfoCardBody;
