import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { profileCandidateSelector } from 'core/login';
import Typography from '../typography.component';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';

function CommitteeApprovalHeader({ committee }) {
  const profileCandidate = useSelector(profileCandidateSelector);

  return (
    <div className="d-flex align-items-center">
      <img
        className="h-8 w-8 rounded-full flex-none mr-2 object-cover"
        alt="connection profile"
        src={committee?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar}
      />
      <Typography className="mr-3" variant="h4">
        {committee?.name || ''}
      </Typography>
      <Typography className="mr-3 mb-0" variant="body">
        connecting to
      </Typography>
      <img
        className="h-8 w-8 rounded-full flex-none mr-2 object-cover"
        alt="connection profile"
        src={profileCandidate?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar}
      />
      <Typography variant="h4">{profileCandidate.name}</Typography>
    </div>
  );
}

CommitteeApprovalHeader.propTypes = {
  committee: PropTypes.object,
};

export { CommitteeApprovalHeader };
