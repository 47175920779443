import gql from 'graphql-tag';
import { exportFragment } from './exports.queries';

export const GET_ORGANIZATION_EXPORTS_QUERY = gql`
  query getOrganizationReportActivities(
    $page: Int
    $limit: Int
    $revvUid: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $reportRecurrence: ReportRecurrenceTypeEnum
    $reportType: ReportActivityTypeEnum
    $reportStatus: ReportStatusTypeEnum
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        report_activity(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            reportRecurrence: $reportRecurrence
            reportType: $reportType
            reportStatus: $reportStatus
          }
        ) {
          ...ReportActivities
        }
      }
    }
  }
  ${exportFragment}
`;
