import React from 'react';
import PropTypes from 'prop-types';
import { Card, Input, FloatGroup } from 'modules/common';

function UpsellName({ editedUpsell, onUpdateEditedUpsell }) {
  return (
    <>
      <Card.Title subtitle="A descriptive name for your upsell.">Upsell Name</Card.Title>
      <FloatGroup>
        <Input
          type="text"
          name="name"
          value={editedUpsell.name}
          onChange={onUpdateEditedUpsell}
          id="name"
          disabled={editedUpsell._isDisabled}
          ariaLabel="Upsell Name"
          placeholder="Upsell Name*"
          validators={['required']}
          errorMessages={['Upsell Name is required.']}
        />
      </FloatGroup>
    </>
  );
}

UpsellName.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default UpsellName;
