import { createSelector } from 'reselect';

const utilitiesSelector = state => state.utilities;

export const exportsFilterSelector = createSelector(utilitiesSelector, utilities => utilities.exportsFilter);
export const customReportsFilterSelector = createSelector(
  utilitiesSelector,
  utilities => utilities.customReportsFilter
);
export const integrationsFilterSelector = createSelector(utilitiesSelector, utilities => utilities.integrationsFilter);
export const abTestingFilterSelector = createSelector(utilitiesSelector, utilities => utilities.abTestingFilter);
export const mediaLibraryFilterSelector = createSelector(utilitiesSelector, utilities => utilities.mediaLibraryFilter);
