import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';

import { Error, withAmplitude, AmplitudePropTypes, FormBody } from 'modules/common';
import { profileSelector } from 'core/login';
import { setToast } from 'core/toast';
import { convertEpochTime } from 'core/utilities';
import { UPDATE_TEAM_PAGE_MUTATION, GET_TEAM_PAGE_QUERY, GET_TEAM_PAGES_QUERY } from 'core/middleware/queries';
import { convertCandidatesForUi } from 'modules/pages/pages/page.tools';

import TeamPageForm from './team-pages/page-form.component';
import { updateTeamPageForServer, getSavePageEventData } from './pages';
import { extractSlug } from './team-pages/tools';

class TeamPageContainer extends Component {
  state = {
    mutation: {
      loading: false,
      errors: null,
    },
  };

  /**
   * updates page data from ui to server
   */
  updatePage = page => {
    const formattedPage = updateTeamPageForServer(page);

    return new Promise((resolve, reject) => {
      this.setState({ mutation: { loading: true } }, () => {
        this.props
          .savePage({ page: formattedPage })
          .then(
            ({
              data: {
                userUpdatePage: { page, errors },
              },
            }) => {
              if (errors) {
                this.props.setToast({
                  message: errors,
                  isError: true,
                });

                this.setState({ mutation: { loading: false } });
                return reject(errors);
              }

              const eventParams = getSavePageEventData(page);
              this.props.logEvent('save page', eventParams);

              this.props.setToast({ message: 'Your team page has been saved.' });
              this.setState({ mutation: { loading: false } });

              page = convertCandidatesForUi(page);
              resolve(page);
            }
          )
          .catch(errors => {
            this.props.setToast({
              message: errors,
              isError: true,
            });

            reject(errors);
            this.setState({ mutation: { loading: false } });
          });
      });
    });
  };

  render() {
    const { data } = this.props;
    let { mutation, error } = this.state;
    const loading = data.loading || mutation.loading;

    if (data.error || data.errors) return <Error error={data.error || data.errors} errorAction={data.refetch} />;
    if (error || mutation.errors || mutation.error) return <Error error={error || mutation.errors || mutation.error} />;

    if (!loading && !data.page) {
      return (
        <Redirect
          to={{
            pathname: '/page-not-found',
            state: { referrer: this.props.location.pathname },
          }}
        />
      );
    }

    return (
      <FormBody>
        <TeamPageForm page={data.page} pageTitle="" loading={loading} savePage={this.updatePage} />
      </FormBody>
    );
  }
}

TeamPageContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  setToast: PropTypes.func.isRequired,
  savePage: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: profileSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(
  withAmplitude,
  graphql(GET_TEAM_PAGE_QUERY, {
    options: props => ({
      variables: { revv_uid: props.match.params.pageId },
    }),
    props: ({ data, ownProps }) => {
      if (!data.viewer) return { data };
      data.page = data.viewer.pages.results[0];
      if (!data.page) return { data }; // if page doesnt exist then end here

      const eventParams = {
        'page id': data.page.revv_uid,
        'created date': convertEpochTime(data.page.created_at),
      };

      ownProps.logEvent('view team page details', eventParams);
      let formattedData = { ...data };
      formattedData.page = convertCandidatesForUi({ ...data.page });
      formattedData.page = extractSlug({ ...formattedData.page });
      return { data: formattedData };
    },
  }),
  graphql(UPDATE_TEAM_PAGE_MUTATION, {
    props: ({ mutate }) => ({
      savePage: variables => {
        variables = { ...variables };

        variables.page.slug = variables.page.slugPrefix + variables.page.slug;
        delete variables.page.slugPrefix;

        return mutate({ variables, refetchQueries: GET_TEAM_PAGES_QUERY });
      },
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(TeamPageContainer);
