import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { useOrganization, useToast } from 'modules/common/hooks';
import {
  RUN_ORGANIZATION_REPORT_MUTATION,
  RUN_CANDIDATE_REPORT_MUTATION,
  RUN_VENDOR_REPORT_MUTATION,  
} from 'core/middleware/queries';
import { format, isValid } from 'date-fns';
import { useSelector } from 'react-redux';
import { profileCandidateSelector } from 'core/login';

export const FREQUENCY_OPTIONS = [
  { label: 'None (on-demand only)', value: 'CUSTOM' },
  { label: 'Nightly', value: 'NIGHTLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Hourly', value: 'HOURLY' },
];

export const FREQUENCY_OPTIONS_TEXT_MAP = {
  CUSTOM: 'Manual',
  NIGHTLY: 'Daily',
  HOURLY: 'Hourly',
  MONTHLY: 'Monthly on the 1st',
  WEEKLY: 'Weekly on Monday',
};

export const CUSTOM_REPORT_TYPES = [
  {
    icon: 'donate',
    name: 'Donations Report',
    value: 'CHARGES_REPORT',
    isOrganization: true,
  },
  {
    icon: 'donate',
    name: 'Donations Report',
    value: 'CONDUIT_CANDIDATE_CHARGES_REPORT',
    isOrganization: false,
  },
  {
    icon: 'disputes-report',
    name: 'Disputes Report',
    value: 'CONDUIT_CANDIDATE_CHARGES_DISPUTES_REPORT',
    isOrganization: false,
  },
  {
    icon: 'user',
    name: 'Donors Report',
    value: 'ORGANIZATION_CUSTOMERS_REPORT',
    isOrganization: true,
  },
  {
    icon: 'pen-nib',
    name: 'Leads Report',
    value: 'LEADS_REPORT',
    isOrganization: true,
  },
  {
    icon: 'money-pledge-report',
    name: 'Money Pledges Report',
    value: 'PLEDGES_REPORT',
    isOrganization: null,
  },
  {
    icon: 'box-alt',
    isOrganization: null,
    name: 'Orders Report',
    value: 'MERCH_AND_ORDERS_REPORT',
  },
  {
    icon: 'donate',
    name: 'Origin Donations Report',
    value: 'CHARGES_REPORT',
    isOrganization: false,
    canCreateNew: false,
  },
  {
    icon: 'user',
    name: 'Origin Donors Report',
    value: 'ORGANIZATION_CUSTOMERS_REPORT',
    isOrganization: false,
    canCreateNew: false,
  },
  {
    icon: 'pen-nib',
    name: 'Origin Leads Report',
    value: 'LEADS_REPORT',
    isOrganization: false,
    canCreateNew: false,
  },
  {
    icon: 'subscription-icon',
    name: 'Origin Subscriptions Report',
    value: 'SUBSCRIPTIONS_REPORT',
    isOrganization: false,
    canCreateNew: false,
  },
  {
    icon: 'refunds-report',
    name: 'Refunds Report',
    value: 'CONDUIT_CANDIDATE_CHARGES_REFUNDS_REPORT',
    isOrganization: false,
  },
  {
    icon: 'subscriptions-report',
    name: 'Subscriptions Report',
    value: 'SUBSCRIPTIONS_REPORT',
    isOrganization: true,
  },
];

export const VENDOR_CUSTOM_REPORT_TYPES = [
  {
    icon: 'fee-report',
    name: 'Bill-Pay Fees Report',
    value: 'PARTNERSHIP_CHARGE_FEES_REPORT',
  },
];

export const CONDITIONAL_OPERATOR_OPTIONS = [
  { label: 'Less Than', value: 'lt' },
  { label: 'less Than or Equal to', value: 'lteq' },
  { label: 'Greater Than', value: 'gt' },
  { label: 'Greater Than or Equal to', value: 'gteq' },
  { label: 'Equals', value: 'eq' },
  { label: 'Contains', value: 'cont' },
  { label: 'Is Present', value: 'present' },
  { label: 'Is Blank', value: 'blank' },
];

export const getReportUIDetails = (report_type, isOrganization, isAgency) => {
  if (isAgency) return VENDOR_CUSTOM_REPORT_TYPES.find(cr => cr.value === report_type);

  return CUSTOM_REPORT_TYPES.filter(cr => cr.isOrganization === isOrganization || cr.isOrganization === null).find(
    cr => {
      return cr.value === report_type;
    }
  );
};

export const getDeliveryMethodText = ({ integration, recipients_string }) => {
  if (integration === null) return `Email${recipients_string ? ` - ${recipients_string.replace(/,/g, ', ')}` : ''}`;

  switch (integration.type) {
    case 'FTP':
      return `FTP - Address: ${integration.ftpFields.ftpAddress}/${integration.ftpFields.ftpPath}`;

    case 'S3':
      return `S3 - Bucket: ${integration.s3Fields.bucket}`;

    default:
      return '';
  }
};

export const generateIntegrationOptions = data => {
  const integrations = (data.viewer?.candidate || data.viewer?.state_level_organization || data.viewer?.vendor)
    .integrations.results;

  return integrations.map(integration => {
    if (integration.type === 'FTP')
      return {
        label: `FTP - Address ${integration.ftpFields.ftpAddress} - ${integration.active ? 'Active' : 'Inactive'}`,
        value: integration.id,
        active: integration.active,
      };

    return {
      label: `S3 - Bucket ${integration.s3Fields.bucket} - ${integration.active ? 'Active' : 'Inactive'}`,
      value: integration.id,
      active: integration.active,
    };
  });
};

export const useRunCandidateReport = () => {
  const [isOrganization, profileCandidate] = useOrganization();
  const sendToast = useToast();
  const [runReportMutation, data] = useMutation(
    isOrganization ? RUN_ORGANIZATION_REPORT_MUTATION : RUN_CANDIDATE_REPORT_MUTATION
  );

  const attributes = { organizationRevvUid: profileCandidate.organization_revv_uid };
  const runReport = revv_uid => {
    runReportMutation({ variables: { attributes: { ...attributes, revv_uid } } });
  };

  useEffect(() => {
    if (!data.called || data.loading) return;
    if (data.error) {
      sendToast({ message: data.error.message, isError: true });
    } else {
      const { message } = data?.data?.candidateRunReportDefinition || data?.data?.organizationRunReportDefinition || {};

      sendToast({ message });
    }
  }, [data.called, data.data, data.error, data.loading, sendToast]);

  return runReport;
};

export const useRunVendorReport = () => {
  const profileCandidate = useSelector(profileCandidateSelector);

  const sendToast = useToast();
  const [runReportMutation, data] = useMutation(RUN_VENDOR_REPORT_MUTATION);

  const attributes = { organizationRevvUid: profileCandidate.organization_revv_uid };
  const runReport = revv_uid => {
    runReportMutation({ variables: { attributes: { ...attributes, revv_uid } } });
  };

  useEffect(() => {
    if (!data.called || data.loading) return;
    if (data.error) {
      sendToast({ message: data.error.message, isError: true });
    } else {
      const { message } = data?.data?.vendorRunReportDefinition || {};

      sendToast({ message });
    }
  }, [data.called, data.data, data.error, data.loading, sendToast]);

  return runReport;
};


const convertSearchConditions = condition => {
  const isDate = typeof condition.value === 'object' && isValid(condition.value);

  return {
    attributeName: condition.attribute_name,
    // Don't send an id for new search condition
    id: condition?._isNew ? null : condition.id,
    value: isDate ? format(condition.value, 'yyyy-MM-dd') : condition.value || '',
    predicate: condition.predicate,
    destroy: condition.destroy || false,
  };
};

export const convertReportColumn = (column, index) => ({
  // Don't send an id for new columns
  id: column?._isNew ? null : column.id,
  name: column.name,
  key: column.key,
  position: index + 1,
  maxlength: column.maxlength,
  static: column.static,
  staticValue: column.static_value,
  lambdaOptions: column.lambda_options,
  destroy: column.destroy || false,
});

export const formatReportForServer = (report, organizationRevvUid) => {
  const reportPayload = {
    active: report.active,
    conditionOperator: report.condition_operator,
    customFilename: report.custom_filename,
    frequencyEnum: report.frequency_enum === 'CUSTOM' ? null : report.frequency_enum,
    name: report.name,
    recipientsString: report.recipients_string,
    reportType: report.report_type,
    reporterType: report.reporter_type,
    revv_uid: report.revv_uid,
    timezone: report.timezone,
    useTimezoneInReport: report.use_timezone_in_report,
    note: report.note,
    organizationRevvUid: organizationRevvUid,
  };

  if (report.integrationId || report.integration) {
    reportPayload.integrationId = report.integrationId || report.integration.id;

    delete reportPayload.recipientsString;
  } else if (!report.integrationId || !report.integration) {
    reportPayload.integrationId = null;
  }

  reportPayload.searchConditions = report.search_conditions
    ?.filter(c => !(c?.destroy && c._isNew))
    .map(convertSearchConditions);

  reportPayload.reportColumns = report.report_columns.filter(c => !(c?.destroy && c._isNew)).map(convertReportColumn);
  return reportPayload;
};

export const createNewReport = (report_type, profile, isOrganization) => {
  let reportType = CUSTOM_REPORT_TYPES.find(
    c => c.value.toLowerCase() === report_type && (c.isOrganization === null || c.isOrganization === isOrganization)
  );
  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

  if (!reportType) reportType = VENDOR_CUSTOM_REPORT_TYPES[0];

  return {
    active: true,
    condition_operator: 'RANSACK_ALL',
    frequency_enum: 'CUSTOM',
    name: reportType.name,
    search_conditions: [],
    report_columns: [],
    timezone,
    report_type: report_type.toUpperCase(),
    recipients_string: profile.email,
    file_name: '%Y-%m-%d_',
    use_timezone_in_report: true,
  };
};

export const convertEnumLabel = label => {
  return label
    .split('_')
    .map(s => s[0].toUpperCase() + s.slice(1))
    .join(' ');
};
