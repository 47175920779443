import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';

import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { setToast } from 'core/toast';
import { withAmplitude } from './tools';

export default function withDownloadReport(FeatureComponent) {
  function DownloadReportWrapper(props) {
    const {
      client,
      queryBuilder,
      reportQuery,
      filter,
      isOrganization,
      profileCandidate,
      downloadReportKey,
      setToast,
      logEvent,
    } = props;

    const downloadReport = (event, query, variables) => {
      const queryVariables = variables || {
        organizationRevvUid: profileCandidate.organization_revv_uid,
        ...queryBuilder(filter, isOrganization),
      };

      client
        .query({
          query: query || reportQuery,
          variables: queryVariables,
        })
        .then(response => {
          const _response =
            response.data.viewer.candidate || response.data.viewer.state_level_organization || response.data.viewer;
          const message = _response[downloadReportKey];

          // if no reports then will return null
          if (!message) {
            setToast({
              message: 'We couldn’t find that report.',
              isError: true,
            });
            return;
          }

          setToast({ message });
          logEvent(`download ${downloadReportKey}`, {
            records: _response[downloadReportKey]?.total_count || '',
          });
        })
        .catch(response => {
          setToast({
            message: response.message,
            isError: true,
          });
        });
    };

    return <FeatureComponent downloadReport={downloadReport} {...props} />;
  }

  DownloadReportWrapper.propTypes = {
    client: PropTypes.object.isRequired,
    queryBuilder: PropTypes.func.isRequired,
    reportQuery: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    downloadReportKey: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
    logEvent: PropTypes.func.isRequired,
    isOrganization: PropTypes.bool.isRequired,
    profileCandidate: PropTypes.object,
  };

  const mapStateToProps = state => {
    return {
      isOrganization: isOrganizationSelector(state),
      profileCandidate: profileCandidateSelector(state),
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      setToast: message => dispatch(setToast(message)),
    };
  };

  return compose(withApollo, withAmplitude, connect(mapStateToProps, mapDispatchToProps))(DownloadReportWrapper);
}
