import React from 'react';
import PropTypes from 'prop-types';

import { FormCard } from 'modules/common';
import SocialMediaInput from './social-media-inputs.component';

function SocialMediaCard({ onChange, confirmationFacebookHandle, confirmationTwitterHandle, disabled }) {
  return (
    <FormCard subtitle="Add social media buttons to your confirmation page." title="Social Media Profiles">
      <SocialMediaInput
        onChange={onChange}
        disabled={disabled}
        facebookName="confirmationFacebookHandle"
        facebookValue={confirmationFacebookHandle}
        twitterName="confirmationTwitterHandle"
        twitterValue={confirmationTwitterHandle}
      />
    </FormCard>
  );
}

SocialMediaCard.propTypes = {
  confirmationFacebookHandle: PropTypes.string,
  confirmationTwitterHandle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SocialMediaCard;
