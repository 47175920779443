import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

function Subscribable(props) {
  return (
    <FormCard title="Subscribable Upsells are not editable using WinRed’s Committee Portal. Use the WinRed Power Portal instead."></FormCard>
  );
}

Subscribable.title = 'Subscribable';
Subscribable.description = '';
Subscribable.icon = 'upsell-subscribable';
Subscribable.type = 'SUBSCRIBABLEITEM';
Subscribable.widgets = [];

Subscribable.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default Subscribable;
