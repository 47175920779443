import gql from 'graphql-tag';
import { widgetFragment } from '../widgets.queries';
import { upsellGroupFragment } from '../pages/upsells.queries';

export const settingsFragment = gql`
  fragment SettingsStorefront on StorefrontSettings {
    revv_uid
    title
    qrCodeUrl
    storefrontUrl

    disclaimer
    longDisclaimer

    primaryColor
    secondaryColor
    backgroundColor
    logoAssignedImage {
      id
      image {
        id
        originalUrl
      }
    }
    heroAssignedImage {
      id
      image {
        id
        originalUrl
      }
    }
    mobileHeroAssignedImage {
      id
      image {
        id
        originalUrl
      }
    }
    heroProducts {
      name
      value
    }
    heroImageProductId

    storeItems {
      id
      position
      product {
        id
      }
    }
    products {
      active_in_storefront
      active
      id
      name
      options {
        name
      }
      price
      product_images {
        image_url
      }
      variants {
        active
        variant_price
      }
    }

    enableBusinessDonations
    enablePacDonations

    ogTitle
    ogDescription
    ogVideo
    ogAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    twitterTitle
    twitterDescription
    twitterAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    fulfillmentHouse
    fulfillmentHousesAllowed

    checkoutPage {
      mobileDisclaimerText
      revv_uid
      sourceCode
      confirmationPreviewPageUrl
      smsOptinCallout
      logoAssignedImage {
        id
        image {
          id
          originalUrl
        }
      }
      primaryColor
      secondaryColor
      enableMobileField
      requireMobileField
      enableSmsOptin

      enableAdditionalAmount
      dynamicAmounts
      roundValues
      pageMinimumAmount
      pageMaximumAmount
      callToAction
      hasOtherField

      enablePhoneField
      requirePhoneField

      enableEmployerDetails
      enableEmployerNameAndOccupation
      employerAddress
      employerCity
      employerState
      employerZip
      shippingAddressPrechecked
      shippingDisclaimer

      bundlerId
      showBundlerId

      customFields {
        page_custom_field_id
        global_custom_field_id
        name
        input_type
        position
        form_section
        label
        default_value
        placeholder
        required
        unique_parameter
        other_field_enabled
      }

      pageAmounts {
        amount
        description
        id
        position
        preselected
        revv_uid
      }
      minimumAmount
      maximumAmount

      confirmationParagraph
      confirmationRedirectBoolean
      confirmationCustomLink
      confirmationCustomLinkCopy
      confirmationFacebookHandle
      confirmationTwitterHandle
      confirmationVideoEmbed

      widgets {
        ...Widget
      }

      upsellPageGroup {
        ...UpsellGroup
      }
        pathway {
            name
            id
            revv_uid
            pathway_type
        }
      cssOverride
    }
  }
  ${widgetFragment}
  ${upsellGroupFragment}
`;

export const GET_STOREFRONT_SETTINGS = gql`
  query getStorefront($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        storefront_settings {
          ...SettingsStorefront
        }
        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${settingsFragment}
`;

export const ACTIVATE_STOREFRONT = gql`
  mutation ActivateStorefrontMutation($organizationRevvUid: String) {
    candidateActivateStorefront(organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
`;

export const SAVE_STOREFRONT = gql`
  mutation UpdateStorefrontSettingsMutation(
    $storefrontSettings: BaseStorefrontSettingsInput!
    $organizationRevvUid: String
  ) {
    candidateUpdateStorefrontSettings(
      storefrontSettings: $storefrontSettings
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      storefrontSettings {
        ...SettingsStorefront
      }
    }
  }
  ${settingsFragment}
`;

export const UPDATE_STOREFRONT_FULFILLMENT_HOUSE = gql`
  mutation UpdateCandidateStorefrontFulfillmentHouse(
    $storefrontFulfillmentHouse: StorefrontFulfillmentHouseInput!
    $organizationRevvUid: String
  ) {
    candidateUpdateStorefrontFulfillmentHouse(
      storefrontFulfillmentHouse: $storefrontFulfillmentHouse
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      message
    }
  }
`;
