import gql from 'graphql-tag';

export const GET_PAYOUTS_QUERY = gql`
  query getPayouts(
    $amount: Float
    $amountPredicate: Predicate
    $limit: Int
    $page: Int
    $status: PayoutStatusEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $arrivalDateStart: String
    $arrivalDateEnd: String
    $payoutRevvUid: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        balance_aggregation {
          total_balance
          available_soon
          available_to_payout
          in_transit
        }
        payouts(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            limit: $limit
            page: $page
            search: $search
            status: $status
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            arrivalDateStart: $arrivalDateStart
            arrivalDateEnd: $arrivalDateEnd
            payoutRevvUid: $payoutRevvUid
          }
        ) {
          total_count
          page_count
          current_page

          net_cents
          refund_cents
          fee_cents
          gross_cents

          results {
            revv_uid
            created_at
            status
            estimatedArrivalAt
            statementDescriptor
            refundCents
            refundDisputeCents
            netRefunds
            grossCents
            feeCents
            netCents
            reportUrl
            failureCode
            failureReason
          }
        }
      }
    }
  }
`;

export const GET_PAYOUT_QUERY = gql`
  query getPayout(
    $payoutRevvUid: String
    $transactionsPage: Int
    $transactionsLimit: Int
    $status: PayoutStatusEnum
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        payouts(filters: { payoutRevvUid: $payoutRevvUid, status: $status }) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            status
            estimatedArrivalAt
            statementDescriptor
            reportUrl
            failureCode
            failureReason
            grossCents
            refundCents
            refundGrossCents
            merchFeeTotalRefundCents
            processingFeeReturnedCents
            disputeCents
            agencyRefundCents
            processingFeeCents
            feeCents
            merchFee
            merchFeeCents
            disputeFeeCents
            netRefunds
            # bankAccount {
            #   account_holder_name
            #   routing_number
            #   last4
            #   bank_name
            #   currency
            #   default_for_currency
            # }
            partnerFees {
              revv_uid
              name
              feeCents
            }
            refundedPartnerFees {
              revv_uid
              name
              feeCents
            }
            adjustmentFees {
              name
              feeCents
            }
            adjustmentFeeTotalCents
            partnerFeeCents
            netCents
            transactions(filters: { page: $transactionsPage, limit: $transactionsLimit }) {
              current_page
              next_page
              page_count
              previous_page
              total_count
              results {
                created_at
                revv_uid
                gross_cents
                fee_cents
                net_cents
                balance_cents
                transaction_type
                charge_revv_uid
                code_name
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PAYOUT_REPORT = gql`
  query getPayoutDetailsReport($payoutRevvUid: String, $status: PayoutStatusEnum, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        payouts(filters: { payoutRevvUid: $payoutRevvUid, status: $status }) {
          results {
            exportPayoutDetailReport
            revv_uid
          }
        }
      }
    }
  }
`;

export const GET_PAYOUTS_REPORT = gql`
  query exportCandidatePayoutsReport(
    $amount: Float
    $amountPredicate: Predicate
    $limit: Int
    $page: Int
    $status: PayoutStatusEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $arrivalDateStart: String
    $arrivalDateEnd: String
    $payoutRevvUid: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_payouts_report(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            limit: $limit
            page: $page
            search: $search
            status: $status
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            arrivalDateStart: $arrivalDateStart
            arrivalDateEnd: $arrivalDateEnd
            payoutRevvUid: $payoutRevvUid
          }
        )
      }
    }
  }
`;

export const EXPORT_LEDGER_REPORT = gql`
  mutation ExportCandidatePayoutLedgerReport($payout: ExportPayoutReportInput!, $organizationRevvUid: String) {
    candidateExportPayoutLedgerReport(payout: $payout, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;

export const EXPORT_PAYOUT_FEES_REPORT = gql`
  mutation ExportCandidatePayoutFeesReport($payout: ExportPayoutReportInput!, $organizationRevvUid: String) {
    candidateExportPayoutFeesReport(payout: $payout, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;
