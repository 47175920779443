import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'react-recompose';
import { Breakpoint } from 'react-socks';
import { useMutation } from '@apollo/client';

import { EmptyList, Pagination, LIST_PAGE_SIZE, withCopyToClipBoard, useAmplitude, ConfirmModal } from 'modules/common';
import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { setToast } from 'core/toast';
import {
  DUPLICATE_CANDIDATE_UPSELL_MUTATION,
  DUPLICATE_ORGANIZATION_UPSELL_MUTATION,
  UPSELL_ARCHIVE_MUTATION,
  ORGANIZATION_UPSELL_ARCHIVE_MUTATION,
} from 'core/middleware/queries';

import ListBodyDesktop from './upsells-list-body/desktop.component';
import ListBodyMobile from './upsells-list-body/mobile.component';
import UpsellDrawer from './upsell-drawer.component';
import { isEmptyList } from 'modules/common/lists/pagination.tools';

export const UPSELL_DESCRIPTION =
  "Upsells are additional asks you can make to donors after they make a donation. Create an Upsell and add it to your page to maximize your donors' value.";

function UpsellsListBody({
  candidate,
  isDrawerOpen,
  selectedUpsell,
  toggleDrawer,
  onChangeFilter,
  handleCreateNew,
  setIsProcessingData,
  refetch,
  clearFilter,
  filter,
}) {
  const [logEvent] = useAmplitude();
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const dispatch = useDispatch();
  const sendToast = message => dispatch(setToast(message));

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [selectedArchiveUpsell, setSelectedArchiveUpsell] = useState(null);

  const [duplicate, { loading }] = useMutation(
    isOrganization ? DUPLICATE_ORGANIZATION_UPSELL_MUTATION : DUPLICATE_CANDIDATE_UPSELL_MUTATION
  );
  const [archive, { loading: loadingArchive }] = useMutation(
    isOrganization ? ORGANIZATION_UPSELL_ARCHIVE_MUTATION : UPSELL_ARCHIVE_MUTATION
  );

  const dropdownAction = (eventKey, item) => {
    switch (eventKey) {
      case 'details':
        toggleDrawer(item);
        break;
      case 'archive':
        setShowArchiveModal(true);
        setSelectedArchiveUpsell(item);
        break;
      case 'duplicate':
        duplicateUpsell(item.revv_uid);
        break;
      case 'preview':
        window.open(item.upsell_preview_url, '_blank');
        break;
      default:
        break;
    }
  };

  const duplicateUpsell = async revvUid => {
    const upsell = { revvUid, organizationRevvUid: profileCandidate.organization_revv_uid };
    setIsProcessingData(loading);

    try {
      const { data } = await duplicate({
        variables: {
          upsell,
          organizationRevvUid: profileCandidate.organization_revv_uid,
        },
      });

      let { errors: error, duplicatedUpsell } = data.candidateDuplicateUpsell || data.organizationDuplicateUpsell;
      setIsProcessingData(loading);

      if (error && error.length > 0) {
        sendToast({
          message: error[0],
          isError: true,
        });
        return;
      }

      logEvent('duplicated upsell', {
        'upsell id': revvUid,
      });

      sendToast({
        message: 'Upsell duplicated.',
      });
      toggleDrawer({ ...duplicatedUpsell, isNew: true });
    } catch (error) {
      let { message } = error;

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const { current_page, page_count, total_count, results } = candidate?.upsells || {};

  const confirmArchive = async () => {
    const archivedUpsellRevvUid = selectedArchiveUpsell.revv_uid;

    // Close the modal and clear the selected upsell.
    setShowArchiveModal(false);
    setIsProcessingData(loadingArchive);

    const upsell = { revvUid: archivedUpsellRevvUid };
    if (isOrganization) upsell.organizationRevvUid = profileCandidate.organization_revv_uid;

    try {
      const { data } = await archive({
        variables: { upsell, organizationRevvUid: profileCandidate.organization_revv_uid },
      });

      setIsProcessingData(loadingArchive);

      let { errors: error } = data.candidateArchiveUpsell || data.organizationArchiveUpsell;

      if (error && error.length > 0) {
        sendToast({
          message: error,
          isError: true,
        });
        return;
      }

      logEvent('upsell archive', {
        'upsell flow id': archivedUpsellRevvUid,
      });

      sendToast({
        message: 'Your upsell has been archived.',
        isError: false,
      });

      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const attachedPages = selectedArchiveUpsell?.upsell_group_count || selectedArchiveUpsell?.page_count;

  if (isEmptyList(results, filter)) {
    return (
      <>
        <EmptyList
          text="No Upsells"
          description={UPSELL_DESCRIPTION}
          buttonText="Create Upsell"
          icon="donate"
          onClick={handleCreateNew}
        />
        <UpsellDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} selectedUpsell={selectedUpsell} />
      </>
    );
  }

  return (
    <>
      <Breakpoint small down>
        <ListBodyMobile items={results} navigateToItem={toggleDrawer} />
      </Breakpoint>
      <Breakpoint medium up>
        <ListBodyDesktop items={results} navigateToItem={toggleDrawer} dropdownAction={dropdownAction} />
      </Breakpoint>

      <Pagination
        logEventName="upsells"
        currentPage={current_page}
        limit={LIST_PAGE_SIZE}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        results={results}
        filter={filter}
        clearFilter={clearFilter}
      />
      <UpsellDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} selectedUpsell={selectedUpsell} />

      <ConfirmModal
        show={showArchiveModal}
        title="Archive Upsell"
        buttonText="Archive Upsell"
        buttonColor="error"
        handleClose={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
        onSubmit={confirmArchive}
      >
        {attachedPages > 0
          ? `Are you sure you want to archive this upsell? It currently belongs to ${attachedPages} page${
              attachedPages !== 1 ? 's' : ''
            }. Archiving it will remove it from those pages.`
          : `Are you sure you want to archive this upsell? This cannot be undone.`}
      </ConfirmModal>
    </>
  );
}

UpsellsListBody.propTypes = {
  candidate: PropTypes.object.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setIsProcessingData: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default compose(withCopyToClipBoard)(UpsellsListBody);
