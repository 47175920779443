import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Dropdown from 'react-bootstrap/Dropdown';
import NavLink from 'react-bootstrap/NavLink';
import Nav from 'react-bootstrap/Nav';

import Icon from '../../icon.component';

const ACTIVE_COLOR = '#6290FF';
const INACTIVE_COLOR = '#314459';

function NavDropdown({ children, title, onSelect, activeKey }) {
  const handleClick = eventKey => () => {
    if (eventKey !== null && eventKey !== undefined) {
      onSelect(eventKey);
    }
  };

  const hasActiveChild = !!children.find(child => child.props.eventKey === activeKey);

  return (
    <Dropdown
      className={classNames('winred-nav__nav-item', {
        'winred-nav__nav-item--active': hasActiveChild,
      })}
    >
      <Dropdown.Toggle as={NavLink} className={classNames('nav-link', 'winred-nav__nav-link')}>
        {title} <Icon icon="angle-down" color={hasActiveChild ? ACTIVE_COLOR : INACTIVE_COLOR} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="winred-dropdown__menu">
        {children.map((child, i) => (
          <Nav.Item
            key={child.props.eventKey || i}
            role="button"
            onClick={handleClick(child.props.eventKey)}
            tabIndex={0}
            onKeyPress={handleClick(child.props.eventKey)}
          >
            {child}
          </Nav.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

NavDropdown.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
};

export default NavDropdown;
