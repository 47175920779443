import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'react-recompose';

import { withAmplitude, withList } from 'modules/common';

export default function withFilter(FeatureComponent) {
  class WithFilterWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = { ...props.filter };
    }

    /**
     * logs the filter state to amplitude
     */
    logFilterEvent = () => {
      this.props.logEvent(`filter ${this.props.listKey}`, this.state);
    };

    /**
     * reset internal state and redux state
     */
    clearFilter = (event, overrideFilter) => {
      this.setState({ ...(overrideFilter || this.props.initialState) }, this.submitFilter);
    };

    /**
     * take the current filter and submits it to redux so gql query is triggered
     */
    submitFilter = event => {
      event?.preventDefault && event.preventDefault();
      event?.preventDefault && event.stopPropagation();

      this.logFilterEvent();
      this.props.setReduxFilter(this.state);
    };

    /**
     * change filter state. optionally save to redux/trigger gql query as well via submitFilter flag
     */
    onChangeFilter = ({ target: { name, value } }, submitFilter = false) => {
      const newFilter = {
        ...this.state,
        page: 1,
      };

      // if given some more values add that (can be single value or array of values)
      if (name) {
        if (Array.isArray(name)) {
          for (let i = 0; i < name.length; i++) {
            newFilter[name[i]] = value[i];
          }
        } else {
          newFilter[name] = value;
        }
      }

      this.setState(newFilter, () => {
        if (submitFilter) this.submitFilter();
      });
    };

    render() {
      return (
        <FeatureComponent
          onChangeFilter={this.onChangeFilter}
          submitFilter={this.submitFilter}
          clearFilter={this.clearFilter}
          filterState={this.state}
          {...this.props}
        />
      );
    }
  }

  WithFilterWrapper.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    setReduxFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    listKey: PropTypes.string.isRequired,
    logEvent: PropTypes.func.isRequired,
    data: PropTypes.object,
    initialState: PropTypes.object.isRequired,
  };

  return compose(withRouter, withApollo, withAmplitude, withList)(WithFilterWrapper);
}
