import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { Card } from 'modules/common';
import RequiredLabel from './required.component';
import '../custom.scss';

function CustomFieldCheckboxPreview({ formState, disabled }) {
  const [checked, setChecked] = useState(formState.default_value);
  useEffect(() => setChecked(formState.default_value), [formState.default_value]);

  return (
    <div>
      <Card.Title className="preview-title">
        {formState.label}
        <RequiredLabel formState={formState} />
      </Card.Title>
      <Form.Check
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => setChecked(!checked)}
        label={formState.placeholder || ''}
      />
    </div>
  );
}

CustomFieldCheckboxPreview.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default CustomFieldCheckboxPreview;
