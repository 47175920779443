import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { PageNotFound } from 'modules/common';
import {
  LOGIN_STATUSES,
  setInitialRoute,
  loginStatusSelector,
  loginInitialRouteSelector,
  isDonorSelector,
} from 'core/login';
import {
  profileIsAdminSelector,
  profileIsReadOnlySelector,
  profileIsPagesOnlySelector,
  profileCandidateSelector,
  isAgencySelector,
  profileIsMyPagesOnlySelector,
  profileIsMerchOnlySelector,
} from 'core/login/login.selectors';

// LOGGED IN PAGES
import Dashboard from 'modules/dashboard';
import Pages from 'modules/pages';
import People from 'modules/people';
import Revenue from 'modules/revenue';
import Accounting from 'modules/accounting';
import Utilities from 'modules/utilities';
import StoreFront from 'modules/storefront';
import Logout from 'modules/login';
import SettingsContainer from 'modules/settings';
import VendorRouter from 'modules/vendors';

// login
import Login from 'modules/login/login.container';
import TwofaSetup from 'modules/login/2fa-setup.container';
import TwofaVerification from 'modules/login/2fa-verification.container';
import LoggingIn from 'modules/login/logging-in.container';

import ForgotPassword from 'modules/login/forgot-password.container';
import ResetPassword from 'modules/login/reset-password.container';

import Footer from 'modules/layout/footer.component';
import CacheContainer from 'modules/layout/layout.container';
import NavBarContainer from './navbar-container.component';
import DonorUserRoutes from './donorUser.routes';
import ToastHandler from './toastHandler.container.js';
import './layout.scss';

import { getDefaultRoute } from './utilities';
import { DisputesList } from '../dashboard/disputes-list.container';

class MainLayout extends Component {
  /**
   * we always want to keep track of waht route the user wanted to go to in case they are not logged in
   * this allows us to redirect after login if this value is set and user is logged in
   */
  componentDidMount() {
    const { location } = this.props;
    if (location.pathname !== '/') {
      this.props.setInitialRoute(`${location.pathname}${location.search || ''}${location.hash || ''}`);
    }
  }

  /**
   * here is were we redirect if the user logged in and came from some other page
   * we only redirect if the current status is logged in and previous was not logged in
   */
  componentDidUpdate(prevProps) {
    let {
      isDonor,
      loginStatus,
      initialRoute,
      isReadOnly,
      isMerchOnly,
      isMyPagesOnly,
      profileCandidate,
      location,
      isAgency,
    } = this.props;

    const prevOrgId = prevProps.profileCandidate.organization_revv_uid;
    const currentOrgId = this.props.profileCandidate.organization_revv_uid;

    // check for switched candidates from dropdown
    const changedCandidate = prevOrgId && currentOrgId && prevOrgId !== currentOrgId;
    if (changedCandidate) initialRoute = `${profileCandidate.organization_revv_uid}/`;

    // when going from logging in to logged in (first log into winred) then set what url we are redirecting to
    const loggingIn = loginStatus === LOGIN_STATUSES.LOGGED_IN && prevProps.loginStatus !== LOGIN_STATUSES.LOGGED_IN;

    if (loggingIn || changedCandidate) {
      // remove beginning slash if present
      if (initialRoute && initialRoute[0] === '/') initialRoute = initialRoute.substring(1);

      // set default route if not redirecting from somewhere
      const defaultRoute = getDefaultRoute({
        isDonor,
        isReadOnly,
        isMerchOnly,
        isMyPagesOnly,
        isAgency,
        profileCandidate,
      });

      this.props.setInitialRoute(null);

      // there's a few pages we wouldn't want to redirect to after login on purpose...
      if (/login|logout|sign_in/i.test(initialRoute)) initialRoute = defaultRoute;

      // donors only have one route to go to
      if (initialRoute === defaultRoute && isDonor) initialRoute = 'team-pages/pages';

      // read only vendors have to go to donors route
      if (isAgency && isReadOnly && initialRoute === defaultRoute) {
        initialRoute = `/${profileCandidate.organization_revv_uid}vendors/people/donors`;
      }

      // set a route to go to if we dont have one at this point
      if (!initialRoute) initialRoute = defaultRoute;

      // if old url scheme then redirect to new one with org id in url - (but only if not team pages)
      const isOnTeamPages = isDonor || location.pathname.includes('/team-pages');

      // if we are not on team pages then lets verify the route we are going to
      if (!isOnTeamPages) {
        if (!initialRoute.includes(profileCandidate.organization_revv_uid)) {
          initialRoute = `${profileCandidate.organization_revv_uid}/${initialRoute}`;
        }

        // redirect plain routes to home page
        if (
          profileCandidate.organization_revv_uid === initialRoute ||
          `${profileCandidate.organization_revv_uid}/` === initialRoute
        ) {
          initialRoute = defaultRoute;
        }

        // make sure if we are an vendors we have an agency route
        if (isAgency && !initialRoute.includes(`${profileCandidate.organization_revv_uid}/vendors/`)) {
          initialRoute = defaultRoute;
        }
      }

      this.props.history.push(`/${initialRoute}`);
    }
  }

  render() {
    const {
      loginStatus,
      isDonor,
      isAdmin,
      isReadOnly,
      isPagesOnly,
      isMerchOnly,
      isMyPagesOnly,
      profileCandidate,
      location,
      isAgency,
    } = this.props;
    const organizationRevvUid = profileCandidate.organization_revv_uid;

    const defaultRoute = isDonor
      ? '/team-pages/pages'
      : isReadOnly
      ? `/${organizationRevvUid || ''}/people/donors`
      : isMerchOnly
      ? `/${organizationRevvUid || ''}/merchandise/products`
      : isMyPagesOnly || isPagesOnly
      ? `/${organizationRevvUid || ''}/pages/my-pages`
      : `/${organizationRevvUid || ''}/dashboard`;

    // if we are not logged in then either we are
    // forgetting password or default redirect to login screen
    if (loginStatus === LOGIN_STATUSES.NOT_LOGGED_IN) {
      return (
        <>
          <ToastHandler isTop />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route path="logout" render={() => <Redirect to="/login" />} />
            <Route exact path="/page-not-found" component={PageNotFound} />
            <Route path="*" render={() => <Redirect to="/login" />} />
          </Switch>
        </>
      );

      // if the store saw data in local storage on website init then we are in logging in status
      // to varify the local storage token and get the user profile
    } else if (loginStatus === LOGIN_STATUSES.LOGGING_IN) {
      return (
        <>
          <ToastHandler isTop />
          <Switch>
            <Route path="/" component={LoggingIn} />
            <Route path="/:organizationRevvUid" component={LoggingIn} />
            <Route path="/team-pages" component={LoggingIn} />
          </Switch>
        </>
      );
    } else if (loginStatus === LOGIN_STATUSES.TWO_FA_SETUP) {
      return (
        <>
          <ToastHandler isTop />
          <Switch>
            <Route path="/" component={TwofaSetup} />
          </Switch>
        </>
      );
    } else if (loginStatus === LOGIN_STATUSES.TWO_FA_VERIFICATION) {
      return (
        <>
          <ToastHandler isTop />
          <Switch>
            <Route path="/" component={TwofaVerification} />
          </Switch>
        </>
      );
    } else if (isDonor || location.pathname.includes('/team-pages')) {
      return (
        <div className="layout-container">
          <NavBarContainer userIsDonor>
            <DonorUserRoutes />
          </NavBarContainer>
          <Footer />
        </div>
      );
    }

    // else we are logged in as a normal user so we're free to do anything+

    if (isAgency) {
      return (
        <div className="layout-container">
          <NavBarContainer>
            <CacheContainer cacheKey={this.props.location.pathname}>
              <Switch>
                <Route
                  path="/:organizationRevvUid/vendors"
                  render={routeProps => {
                    return <VendorRouter {...routeProps} />;
                  }}
                />
                <Route path="/team-pages" render={routeProps => <DonorUserRoutes {...routeProps} />} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
                <Route exact path={`/${organizationRevvUid}`} render={() => <Redirect to={defaultRoute} />} />
                <Route exact path={`/${organizationRevvUid}/`} render={() => <Redirect to={defaultRoute} />} />
                <Route exact path="/login" render={() => <Redirect to={defaultRoute} />} />
                <Route exact path="/users/sign_in" render={() => <Redirect to={defaultRoute} />} />
                <Route exact path="/page-not-found" component={PageNotFound} />
                {/* for some reason routes like /my-pagesg were not redirecting so we force one instead */}
                <Route
                  render={() => {
                    this.props.history.push('/page-not-found');
                    return <Redirect to="/page-not-found" />;
                  }}
                />
              </Switch>
            </CacheContainer>
          </NavBarContainer>
          <Footer />
        </div>
      );
    }

    return (
      <div className="layout-container">
        <NavBarContainer>
          <CacheContainer cacheKey={this.props.location.pathname}>
            <Switch>
              <Route
                path="/:organizationRevvUid/dashboard"
                render={routeProps => <Dashboard {...routeProps} key={this.props.location.pathname} />}
              />
              {isReadOnly || isMerchOnly ? null : (
                <Route path="/:organizationRevvUid/pages" render={routeProps => <Pages {...routeProps} />} />
              )}
              {isPagesOnly || isMerchOnly || isMyPagesOnly ? null : (
                <Route path="/:organizationRevvUid/people" render={routeProps => <People {...routeProps} />} />
              )}
              {isReadOnly || isPagesOnly || isMerchOnly || isMyPagesOnly ? null : (
                <Route path="/:organizationRevvUid/revenue" render={routeProps => <Revenue {...routeProps} />} />
              )}
              {isReadOnly || isPagesOnly || isMerchOnly || isMyPagesOnly ? null : (
                <Route path="/:organizationRevvUid/utilities" render={routeProps => <Utilities {...routeProps} />} />
              )}
              {isMyPagesOnly ? null : (
                <Route path="/:organizationRevvUid/merchandise" render={routeProps => <StoreFront {...routeProps} />} />
              )}

              {isReadOnly || isPagesOnly || isMerchOnly || isMyPagesOnly ? null : (
                <Route
                  path={[
                    '/:organizationRevvUid/accounting',
                    '/:organizationRevvUid/transactions',
                    '/:organizationRevvUid/payouts',
                  ]}
                  render={routeProps => <Accounting {...routeProps} />}
                />
              )}
              {isAdmin ? (
                <Route
                  path="/:organizationRevvUid/settings"
                  render={routeProps => <SettingsContainer {...routeProps} />}
                />
              ) : null}
              <Route path="/:organizationRevvUid/dispute_rates" component={DisputesList} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
              <Route exact path={`/${organizationRevvUid}`} render={() => <Redirect to={defaultRoute} />} />
              <Route exact path={`/${organizationRevvUid}/`} render={() => <Redirect to={defaultRoute} />} />
              <Route exact path="/login" render={() => <Redirect to={defaultRoute} />} />
              <Route exact path="/users/sign_in" render={() => <Redirect to={defaultRoute} />} />
              <Route exact path="/page-not-found" component={PageNotFound} />
              {/* for some reason routes like /my-pagesg were not redirecting so we force one instead */}
              <Route
                render={() => {
                  this.props.history.push('/page-not-found');
                  return <Redirect to="/page-not-found" />;
                }}
              />
            </Switch>
          </CacheContainer>
        </NavBarContainer>
        <Footer />
      </div>
    );
  }
}

MainLayout.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loginStatus: PropTypes.oneOf(Object.keys(LOGIN_STATUSES)),

  initialRoute: PropTypes.string,
  setInitialRoute: PropTypes.func.isRequired,

  isDonor: PropTypes.bool,

  isAdmin: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isPagesOnly: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object,
  organizationRevvUid: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    isDonor: isDonorSelector(state),
    initialRoute: loginInitialRouteSelector(state),
    loginStatus: loginStatusSelector(state),
    isAdmin: profileIsAdminSelector(state),
    isReadOnly: profileIsReadOnlySelector(state),
    isPagesOnly: profileIsPagesOnlySelector(state),
    isMyPagesOnly: profileIsMyPagesOnlySelector(state),
    isMerchOnly: profileIsMerchOnlySelector(state),
    profileCandidate: profileCandidateSelector(state),
    isAgency: isAgencySelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setInitialRoute: route => dispatch(setInitialRoute(route)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
