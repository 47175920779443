import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { profileMaxPersonalDonationSelector } from 'core/login';
import { withApollo } from '@apollo/client/react/hoc';

import uuid from 'core/utilities/uuid';
import { DoubleAddListItemButton, Input, DragDrop, SpinnerContainer, withAmplitude } from 'modules/common';
import { isOrganizationSelector } from 'core/login';
import { setToast } from 'core/toast';
import { noop } from 'core/utilities';
import { GET_PRESET_UPSELL_CARD } from 'core/middleware/queries';
import {
  UpsellTypeSelector,
  UpsellPresetSelector,
  UpsellCollapsible,
} from 'modules/pages/upsells/drawer/new-upsell/sections/add-upsell';
import { generateNewPanelUpsellCard, createDropdownOptions, PANEL_UPSELLS_MAP } from './tools';
import { formatPresetPanelUpsellCard } from 'modules/pages/upsells/tools';

const getItemStyle = (isDragging, draggableStyle) => {
  const style = {
    ...draggableStyle,
    // marginBottom: '1rem',
    zIndex: 99999,
  };

  return style;
};

function UpsellCards({
  editedUpsell,
  onUpdateEditedUpsell,
  presetOptions,
  presetCards,
  setToast,
  client,
  logEvent,
  maxPersonalDonation,
  isOrganization,
}) {
  const disabled = editedUpsell._isDisabled;

  const [loading, setLoading] = useState(false);
  const upsellCards = editedUpsell.cards || [];
  const dropdownOptions = createDropdownOptions();

  const selectUpsellCardType = ({ target: { value } }, upsellCard) => {
    const selectedSubPanel = PANEL_UPSELLS_MAP[value] || {};

    const generatedUpsellCard = generateNewPanelUpsellCard({
      editedUpsell,
      upsellType: value,
      maxDonation: maxPersonalDonation,
    });

    const newUpsell = {
      ...upsellCard,
      ...generatedUpsellCard,
      ...selectedSubPanel,
    };

    const newCards = upsellCards.map(card => (card.revv_uid === newUpsell.revv_uid ? newUpsell : card));
    onUpdateEditedUpsell({ target: { name: 'cards', value: newCards } });
  };

  const updateUpsellCard = ({ target: { name, value } }, upsellCard) => {
    const newValue = upsellCards.map(uC => {
      if (uC !== upsellCard) return uC;
      uC = { ...uC };

      // replacing the whole thing
      if (name === 'card') return value;

      uC[name] = value;
      return uC;
    });

    onUpdateEditedUpsell({ target: { name: 'cards', value: newValue } });
  };

  const addUpsellCard = () => {
    const value = [...upsellCards, { name: '', type: '', _isNew: true, revv_uid: uuid() }];
    onUpdateEditedUpsell({ target: { name: 'cards', value } });
  };

  const addPresetUpsellCard = () => {
    const value = [...upsellCards, { name: '', type: '', _isPreset: true, _isNew: true, revv_uid: uuid() }];
    onUpdateEditedUpsell({ target: { name: 'cards', value } });
  };

  const selectPresetUpsellCard = async ({ target: { value } }, upsellCard) => {
    const selectedPresetCard = presetCards.find(pc => pc.revv_uid === value);
    if (!selectedPresetCard) return;

    try {
      setLoading(true);
      const { data } = await client.query({
        query: GET_PRESET_UPSELL_CARD,
        variables: { revv_uid: value },
      });
      setLoading(false);

      if (data.error || data.errors) {
        setToast({
          message: data.error || data.errors,
          isError: true,
        });
        return;
      }

      let [selectedPresetCard] = data.viewer.candidate.preset_panel_cards.results;
      if (!selectedPresetCard) {
        this.props.setToast({
          message: 'Retrieved preset upsell card not found.',
          isError: true,
        });
        return;
      }

      logEvent('add preset card', {
        id: selectedPresetCard.revv_uid,
        name: selectedPresetCard.public_name,
      });

      selectedPresetCard = formatPresetPanelUpsellCard(selectedPresetCard, upsellCards);

      // replace temp card with retrieved card and save to upsell
      const newCards = upsellCards.map(card => (card.revv_uid === upsellCard.revv_uid ? selectedPresetCard : card));
      onUpdateEditedUpsell({ target: { name: 'cards', value: newCards } });
    } catch (error) {
      setLoading(false);
      setToast({
        message: `There was an error retrieving the preset upsell card: ${error.message || error}`,
        isError: true,
      });
    }
  };

  const removeUpsellCard = upsellCard => {
    const value = upsellCards.map(uC => {
      if (uC === upsellCard) {
        uC = { ...uC };
        uC._destroy = true;
      }
      return uC;
    });
    onUpdateEditedUpsell({ target: { name: 'cards', value } });
  };

  const hasAtLeastOneUpsellCard = upsellCards.filter(upsell => !upsell._destroy).length;

  const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // reorder positions
    let position = 1;
    result = result.map(upsellCard => {
      if (!upsellCard._destroy) {
        upsellCard.position = position;
        position++;
      }
      return upsellCard;
    });

    return result;
  };

  const getDraggableDefault = upsellCard => (upsellCard._isPreset ? false : !!upsellCard._isNew);

  // if parent upsell is disabled then all panel cards are not sortable
  const sortableUpsellCards = editedUpsell._isDisabled
    ? []
    : upsellCards.filter(upsellCard => upsellCard.type && !upsellCard._destroy);

  const nonSortableUpsellCard = editedUpsell._isDisabled ? upsellCards : [];

  const newUpsellCards = upsellCards.filter(upsellCard => !upsellCard.type && !upsellCard._destroy);

  return (
    <>
      <DragDrop
        reorder={reorder}
        items={sortableUpsellCards}
        onUpdate={onUpdateEditedUpsell}
        name="cards"
        getItemStyle={getItemStyle}
        getDraggableDefault={getDraggableDefault}
        disabledItems={nonSortableUpsellCard}
      >
        {({ item, index, isDragging, toggleDraggable }) => {
          return (
            <UpsellCollapsible
              key={item.revv_uid}
              upsellCard={item}
              editedUpsell={editedUpsell}
              index={index}
              updateUpsellCard={updateUpsellCard}
              removeUpsellCard={removeUpsellCard}
              isDragging={isDragging}
              toggleDraggable={toggleDraggable}
            />
          );
        }}
      </DragDrop>

      {newUpsellCards.map(upsellCard => {
        // preset selector
        if (upsellCard._isPreset) {
          if (loading) return <SpinnerContainer key={upsellCard.revv_uid} />;
          if (disabled) return null;

          return (
            <UpsellPresetSelector
              key={upsellCard.revv_uid}
              updateUpsellCard={selectPresetUpsellCard}
              upsellCard={upsellCard}
              dropdownOptions={presetOptions}
              upsellCards={upsellCards}
              removeUpsellCard={removeUpsellCard}
            />
          );
        }

        // upsell card selector
        return (
          <UpsellTypeSelector
            key={upsellCard.revv_uid}
            isOrganization={isOrganization}
            updateUpsellCard={selectUpsellCardType}
            upsellCard={upsellCard}
            dropdownOptions={dropdownOptions}
            upsellCards={upsellCards}
            removeUpsellCard={removeUpsellCard}
          />
        );
      })}

      {disabled ? null : (
        <Container fluid className="mt-3 px-0">
          <DoubleAddListItemButton
            addItem1={addUpsellCard}
            addText1="Add Upsell Card"
            addSubText1="Add upsell cards to your panel."
            disableAdd1={newUpsellCards.length > 0}
            addItem2={addPresetUpsellCard}
            addText2="Add Preset Panel Card"
            addSubText2="Add upsell cards to your panel that WinRed has created."
            disableAdd2={newUpsellCards.length > 0}
          />
        </Container>
      )}

      <Input
        hidden
        value={hasAtLeastOneUpsellCard}
        onChange={noop}
        validators={['required']}
        errorMessages={['You must add at least one upsell card to your panel upsell.']}
      />
    </>
  );
}

UpsellCards.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  maxPersonalDonation: PropTypes.number.isRequired,
  presetOptions: PropTypes.array.isRequired,
  presetCards: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
  maxPersonalDonation: profileMaxPersonalDonationSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setToast: message => dispatch(setToast(message)),
});

export default compose(withAmplitude, withApollo, connect(mapStateToProps, mapDispatchToProps))(UpsellCards);
