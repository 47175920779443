import { amountBuilder, dateAndSearchBuilder, LIST_PAGE_SIZE } from 'modules/common';

export default function queryBuilder(filter) {
  let variables = {
    page: filter.page,
    limit: LIST_PAGE_SIZE,
  };

  variables = amountBuilder(variables, filter);
  variables = dateAndSearchBuilder({
    variables,
    search: filter.search,
    dateRangeStart: filter.dateRangeStart,
    dateRangeEnd: filter.dateRangeEnd,
  });

  if (filter.activeTabKey && filter.activeTabKey !== 'all') {
    variables.status = filter.activeTabKey.toUpperCase();
  }

  return variables;
}
