import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import WidgetContainer from './widget-container.component';
import { Draggable } from 'react-beautiful-dnd';

export function WidgetsContainerDraggable({
  widgets,
  widget,
  index,
  updateWidget,
  onChangeWidgetType,
  removeWidget,
  primaryColor,
  secondaryColor,
  isPartOfUpsell,
  selectableWidgets,
  donationAmounts,
  upsellType,
  disabled,
  editedUpsell,
  isDraggable,
  page,
}) {
  const [disableDrag, setDisableDrag] = useState(false);
  const disableDragToggle = useCallback(
    val => {
      if (disableDrag !== val) setDisableDrag(val);
    },
    [setDisableDrag, disableDrag]
  );
  return (
    <Draggable key={widget.id} draggableId={widget.id.toString()} index={index} isDragDisabled={disableDrag}>
      {provided => (
        <div key={widget.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <WidgetContainer
            key={widget.id}
            widget={widget}
            widgets={widgets}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isPartOfUpsell={isPartOfUpsell}
            selectableWidgets={selectableWidgets}
            updateWidget={updateWidget}
            onChangeWidgetType={onChangeWidgetType}
            removeWidget={removeWidget}
            donationAmounts={donationAmounts}
            upsellType={upsellType}
            disabled={disabled}
            editedUpsell={editedUpsell}
            isDraggable={isDraggable}
            disableDragToggle={disableDragToggle}
            page={page}
          />
        </div>
      )}
    </Draggable>
  );
}

WidgetsContainerDraggable.propTypes = {
  widgets: PropTypes.array,
  widget: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateWidget: PropTypes.func.isRequired,
  onChangeWidgetType: PropTypes.func.isRequired,
  removeWidget: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  isPartOfUpsell: PropTypes.bool,
  selectableWidgets: PropTypes.array,
  donationAmounts: PropTypes.array,
  upsellType: PropTypes.string,
  disabled: PropTypes.bool,
  editedUpsell: PropTypes.object,
  isDraggable: PropTypes.bool,
};
