import React, { useEffect } from 'react';

import { LIST_PAGE_SIZE } from 'modules/common';
import { Button } from 'modules/common/form';
import './pagination.scss';
import { formatNumberWithCommas } from 'core/utilities/formatters.js';
import { Breakpoint } from 'react-socks';
import { PaginationMobile } from './pagination-mobile.component';
import { PaginationDesktop } from './pagination-desktop.component';
import { isLastPageTool, isPageIndexOff } from './pagination.tools';
import { noop } from 'core/utilities';
import { PropTypes } from 'prop-types';

function Pagination({
  setPage,
  currentPage,
  pageCount,
  totalCount,
  limit = LIST_PAGE_SIZE,
  name = 'page',
  filter = {},
  results = [],
  clearFilter = noop,
}) {
  const roundedTotalPaginationNumber = Math.ceil(totalCount / 25);
  const uiTotalCount = formatNumberWithCommas(totalCount);

  const isFirstPage = currentPage === 1;
  const isLastPage = isLastPageTool(pageCount, currentPage, totalCount, limit);

  const prevPage = () => {
    const prevPageNumber = currentPage - 1;
    setPage({ target: { name, value: prevPageNumber } }, true);
  };

  const nextPage = () => {
    const nextPageNumber = currentPage + 1;
    setPage({ target: { name, value: nextPageNumber } }, true);
  };

  const goToPage = pageNumber => {
    setPage({ target: { name, value: pageNumber } }, true);
  };

  /* Catching archiving of last single item on last page.  */
  useEffect(() => {
    if (isPageIndexOff(results, filter)) {
      clearFilter();
    }
  }, [results, filter, clearFilter]);

  // Generate page buttons
  const pageButtons = Array.from({ length: roundedTotalPaginationNumber }, (_, index) => (
    <>
      <Button
        disabled={roundedTotalPaginationNumber === 1}
        square
        key={index}
        variant="primary"
        size="lg"
        onClick={() => {
          if (currentPage !== index + 1) {
            goToPage(index + 1);
          }
        }}
        ariaLabel={`Go to page ${index + 1}`}
        className={`mx-1 px-3 my-1 ${
          currentPage === index + 1
            ? 'bg--winred-blue-light c--white border-winred-blue-light'
            : 'bg--white c--black border-white'
        }`}
      >
        {formatNumberWithCommas(index + 1)}
      </Button>
    </>
  ));

  // Generate mobile page buttons
  const mobileForwardChevronPageButton = Array.from({ length: roundedTotalPaginationNumber }, (_, index) => (
    <>
      <Button
        disabled={roundedTotalPaginationNumber === 1}
        square
        key={index}
        variant="primary"
        size="lg"
        onClick={() => goToPage(index + 1)}
        ariaLabel={`Go to page ${index + 1}`}
        className={`mx-1 px-3 my-1 ${
          currentPage === index + 1
            ? 'bg--winred-blue-light c--white border-winred-blue-light'
            : 'bg--white c--black border-white'
        }`}
      >
        {'>'}
      </Button>
    </>
  ));

  const mobileBackwardChevronPageButton = Array.from({ length: roundedTotalPaginationNumber }, (_, index) => (
    <>
      <Button
        disabled={roundedTotalPaginationNumber === 1}
        square
        key={index}
        variant="primary"
        size="lg"
        onClick={() => goToPage(index + 1)}
        ariaLabel={`Go to page ${index + 1}`}
        className={`mx-1 px-3 my-1 ${
          currentPage === index + 1
            ? 'bg--winred-blue-light c--white border-winred-blue-light'
            : 'bg--white c--black border-white'
        }`}
      >
        {'<'}
      </Button>
    </>
  ));

  const ellipses = <span className="mx-2">...</span>;

  return (
    <div className="winred-pagination d-flex justify-content-between flex-row px-2 pb-0">
      <Breakpoint medium up>
        <Button variant="primary" size="lg" onClick={prevPage} disabled={isFirstPage} ariaLabel="previous page">
          Previous
        </Button>
      </Breakpoint>

      <div className="flex-grow-1 d-flex justify-center">
        <Breakpoint small down>
          <PaginationMobile
            pageButtons={pageButtons}
            mobileForwardChevronPageButton={mobileForwardChevronPageButton}
            mobileBackwardChevronPageButton={mobileBackwardChevronPageButton}
            currentPage={currentPage}
            roundedTotalPaginationNumber={roundedTotalPaginationNumber}
            ellipses={ellipses}
          ></PaginationMobile>
        </Breakpoint>
      </div>

      <div className="d-flex align-items-center">
        <Breakpoint medium up>
          <PaginationDesktop
            pageButtons={pageButtons}
            currentPage={currentPage}
            roundedTotalPaginationNumber={roundedTotalPaginationNumber}
            ellipses={ellipses}
            uiTotalCount={uiTotalCount}
          ></PaginationDesktop>
        </Breakpoint>

        <Breakpoint medium up>
          <Button
            variant="primary"
            size="lg"
            disabled={isLastPage}
            onClick={nextPage}
            ariaLabel="next page"
            className="ml-2"
          >
            Next
          </Button>
        </Breakpoint>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  limit: PropTypes.number,
  name: PropTypes.string,
  filter: PropTypes.object,
  results: PropTypes.array,
  clearFilter: PropTypes.func,
};

export default Pagination;
