import React, { useMemo } from 'react';
import Image from 'react-bootstrap/Image';

import { Typography, Icon } from 'modules/common';
import { getAvatar } from 'core/utilities';
import uuid from 'core/utilities/uuid';
import { getCandidateLabel } from '../page-common.tools';

export const DEFAULT_PAGE_AMOUNT = {
  amount: null,
  description: '',
};

/**
 * default values for a candidate's amounts (amount and amount 2)
 * this is for committee upsell
 */
export const generateCandidateAmounts = () => {
  return [
    { ...DEFAULT_PAGE_AMOUNT, _isNew: true, amount: 25 },
    { ...DEFAULT_PAGE_AMOUNT, _isNew: true, amount: 50 },
  ];
};

export const generateAgency = () => {
  return { revv_uid: uuid(), name: '', fee: '', last_change: null, _isNew: true, _destroy: false };
};

export const getAllowedCandidatesToAdd = (selectedCandidates, allCandidatesToAdd) => {
  const allowedCandidatesToAdd = (allCandidatesToAdd || []).filter(allCandidate => {
    const existingCandidate = (selectedCandidates || []).find(conduitCandidate => {
      const candidate = conduitCandidate.candidate || conduitCandidate.organization;
      return candidate.revv_uid === allCandidate.revv_uid;
    });
    return !existingCandidate || existingCandidate._destroy;
  });

  return allowedCandidatesToAdd;
};

export const getLabel = ({ icon, label }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <div className="candidate-select-image-container">
        <Image className="candidate-select-image" src={icon} />
      </div>
      <span>{label}</span>
    </div>
  );
};

export const getCandidateDropdownOptions = (allowedCandidatesToAdd, inputValue) => {
  if (!inputValue) return [];
  const inputValueLower = inputValue.toLowerCase();

  const filterResults = allowedCandidatesToAdd.filter(option => {
    let candidateName = option.name || '';
    // if first AND last name exist search by that
    if (option.first_name && option.last_name) candidateName = `${option.first_name} ${option.last_name}`;
    return candidateName.toLowerCase().includes(inputValueLower);
  });

  const options = filterResults.map(candidate => ({
    value: candidate.revv_uid,
    label: getCandidateLabel(candidate, true),
    icon: getAvatar(candidate),
  }));

  return options;
};

export const useValidateCommmittees = ({ selectedCandidates, profileCandidate, isOrganization }) => {
  return useMemo(() => {
    // temp fix: if team pages (aka have no candidate then hide disclaimer for now)
    if (!profileCandidate) return { hideMessages: true };

    // [sc24031] hide validation if profile federal candidate is NOT super pac
    if (!isOrganization && profileCandidate.office !== 'super_pac') return { hideMessages: true };

    let organizationIsValid = true;
    let errorMessage = '';

    const _candidate = selectedCandidates[0]?.candidate || selectedCandidates[0]?.organization;

    const onlyHostCandidateExists =
      selectedCandidates.filter(c => !c._destroy).length === 1 &&
      _candidate.revv_uid === profileCandidate.organization_revv_uid;

    // if only the host candidate is on the page then dont show extra details
    if (onlyHostCandidateExists) return { hideMessages: true };

    const numberOfFederalCandidates = selectedCandidates.filter(
      c => !c._isStateLevel && !c._destroy && (c.candidate?.name || c.organization?.name)
    );
    const numberOfStateCandidates = selectedCandidates.filter(
      c => c._isStateLevel && !c._destroy && (c.candidate?.name || c.organization?.name)
    );

    if (numberOfFederalCandidates.length > 0 && numberOfStateCandidates.length > 4) {
      organizationIsValid = false;
      errorMessage = 'You cannot have more than 4 Merchant Candidates.';
    } else if (numberOfStateCandidates.length > 5) {
      organizationIsValid = false;
      errorMessage = 'You cannot have more than 5 Merchant Candidates.';
    }

    const maxNumberOfStateLevels = numberOfFederalCandidates.length ? 4 : 5;
    const countMessage = `${numberOfStateCandidates.length} out of ${maxNumberOfStateLevels} Merchant Committees`;
    const lawMessage = ` You can only add up to ${maxNumberOfStateLevels} Merchant candidates.`;

    const warningMessage = (
      <div className="d-flex">
        <Icon icon="exclamation-triangle" color="gold" size="lg" />
        <Typography className="ml-2">
          <span className="mr-2 font-weight-bold">{countMessage}</span>
          <span>{lawMessage}</span>
        </Typography>
      </div>
    );

    return { organizationIsValid, warningMessage, errorMessage };
  }, [selectedCandidates, profileCandidate, isOrganization]);
};

export const setCandidateLevel = conduitCandidate => {
  const candidate = conduitCandidate.candidate || conduitCandidate.organization || conduitCandidate;

  return {
    ...conduitCandidate,
    _isStateLevel: candidate.revv_uid.startsWith('rv_org_'),
  };
};
