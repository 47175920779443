import React, { useCallback, useState } from 'react';

import FloatGroup from './float-group.component';
import Input from './input.component';
import { noop } from 'lodash';

function MoneyInput({ isCents, value, onChange, removeExtraCharacters = false, ...props }) {
  const [hasChanged, setHasChanged] = useState(false);

  // always convert from cents and dollars so ui is in dollars but value itself is in cents (sent to api)
  // also always needs to be int not string
  const _onChange = useCallback(
    ({ target: { name, value } }) => {
      setHasChanged(true);
      const _value = isCents ? parseFloat(value, 10) * 100 : parseFloat(value, 10);
      onChange({ target: { name, value: _value } });
    },
    [isCents, onChange]
  );

  let _value = isCents ? parseFloat(value, 10) / 100 : value;
  if (isNaN(_value)) _value = '';

  // if original value make sure decimals is 2 digits
  if (!hasChanged && _value) _value = _value.toFixed(2);

  return (
    <FloatGroup className="prepend-label-dollar">
      <Input
        onKeyDown={removeExtraCharacters ? evt => ['e', '+', '-'].includes(evt.key) && evt.preventDefault() : noop}
        type="number"
        moneyInput
        prepend="$"
        onChange={_onChange}
        value={_value}
        {...props}
      />
    </FloatGroup>
  );
}

export default MoneyInput;
