import {
  SET_DONATIONS_FILTER,
  CLEAR_DONATIONS_FILTER,
  SET_SUBSCRIPTIONS_FILTER,
  CLEAR_SUBSCRIPTIONS_FILTER,
  SET_TEAM_DONATIONS_FILTER,
  CLEAR_TEAM_DONATIONS_FILTER,
  SET_MONEYPLEDGE_FILTER,
  CLEAR_MONEYPLEDGE_FILTER,
} from './types';

export const setDonationsFilter = filter => ({
  type: SET_DONATIONS_FILTER,
  filter,
});

export const clearDonationsFilter = () => ({ type: CLEAR_DONATIONS_FILTER });

export const setSubscriptionsFilter = filter => ({
  type: SET_SUBSCRIPTIONS_FILTER,
  filter,
});

export const clearSubscriptionsFilter = () => ({
  type: CLEAR_SUBSCRIPTIONS_FILTER,
});

export const setTeamDonationsFilter = filter => ({
  type: SET_TEAM_DONATIONS_FILTER,
  filter,
});

export const clearTeamDonationsFilter = () => ({ type: CLEAR_TEAM_DONATIONS_FILTER });

export const setMoneyPledgeFilter = filter => ({
  type: SET_MONEYPLEDGE_FILTER,
  filter,
});

export const clearMoneyPledgeFilter = () => ({ type: CLEAR_MONEYPLEDGE_FILTER });
