import React from 'react';
import PropTypes from 'prop-types';
import { Amplitude } from 'react-amplitude-hooks';
import { isDevelopment } from 'core/utilities';

export const AmplitudePropTypes = { logEvent: PropTypes.func };

/**
 * HOC to add amplitude
 * @param {React Component} Component
 * @return {React Component}
 */
function withAmplitude(Component) {
  function WithAmplitudeWrapper(props) {
    return (
      <Amplitude>
        {({ logEvent }) => {
          const _logEvent = (...args) => {
            if (isDevelopment) console.debug('amplitude', args);
            else logEvent(...args);
          };

          return <Component logEvent={_logEvent} {...props} />;
        }}
      </Amplitude>
    );
  }
  return WithAmplitudeWrapper;
}

export default withAmplitude;
