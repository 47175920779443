import gql from 'graphql-tag';

const transfersFragment = gql`
  fragment Transfers on VendorTransferResult {
    current_page
    page_count
    total_count
    results {
      created_at
      revv_uid
      status
      vendor_earned_count
      transfer_amount_cents
      transfer_amount_count
    }
  }
`;

const transferFragment = gql`
  fragment Transfer on VendorTransferResult {
    results {
      created_at
      revv_uid
      status
      processed_at
      vendor_earned_cents
      vendor_earned_count

      transfer_refund_cents
      transfer_refund_count

      transfer_dispute_cents
      transfer_dispute_count

      transfer_amount_cents
      transfer_amount_count

      stripe_transfer_id
      statement_descriptor
      estimated_arrival_at
      payout_revv_uid
      bank_account {
        account_holder_name
        routing_number
        last4
        bank_name
        currency
        default_for_currency
      }
    }
  }
`;

export const GET_VENDOR_TRANSFERS_QUERY = gql`
  query getVendorTransfers(
    $page: Int
    $limit: Int
    $transferAmount: Float
    $transferAmountPredicate: RansackPredicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        name

        transfers(
          filters: {
            page: $page
            limit: $limit
            transferAmount: $transferAmount
            transferAmountPredicate: $transferAmountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          ...Transfers
        }
      }
    }
  }
  ${transfersFragment}
`;
export const GET_VENDOR_TRANSFER_QUERY = gql`
  query getVendorTransfer($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        name
        account_type
        transfers(filters: { revvUid: $revv_uid }) {
          ...Transfer
        }
        bank_accounts {
          revv_uid
          bank_name
          status
        }
      }
    }
  }
  ${transferFragment}
`;
export const GET_VENDOR_TRANSFERS_REPORT_QUERY = gql`
  query getVendorTransfersReport(
    $page: Int
    $limit: Int
    $transferAmount: Float
    $transferAmountPredicate: RansackPredicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $revvUid: String
    $organizationRevvUid: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        name
        account_type
        export_transfers_report(
          filters: {
            page: $page
            limit: $limit
            transferAmount: $transferAmount
            transferAmountPredicate: $transferAmountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            revvUid: $revvUid
          }
        )
      }
    }
  }
`;
