import gql from 'graphql-tag';

export const GET_VENDOR_SUBSCRIPTIONS_REPORT = gql`
  query getVendorSubscriptionsReport(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $contributorType: [ContributorTypeEnum!]
    $recipientRevvUid: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        export_subscriptions_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
            recipientRevvUid: $recipientRevvUid
          }
        )
      }
    }
  }
`;

export const GET_VENDOR_SUBSCRIPTIONS_QUERY = gql`
  query getVendorSubscriptions(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
    $recipientRevvUid: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        recipients {
          revv_uid
          name
        }

        subscription_statistics(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            contributorType: $contributorType
            recipientRevvUid: $recipientRevvUid
          }
        ) {
          remaining_this_month {
            subscription_count
            subscription_revenue
            subscription_average
          }
          total_active {
            subscription_count
            subscription_average
            subscription_revenue
          }
        }
        subscriptions(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
            recipientRevvUid: $recipientRevvUid
          }
        ) {
          current_page
          page_count
          total_count
          results {
            created_at
            first_name
            last_name
            business_name
            pac_name
            email
            recurring
            revv_uid
            interval
            amount
            contributor_type
            vendor_amount
            vendor_amount_cents
            origin_organization_name
            origin_organization_revv_uid
            recipient {
              name
              revv_uid
              avatar {
                image {
                  thumbnailUrl
                }
              }
            }
            vendor_charge_revv_uid
            client_charge_revv_uid
            subscription_status
          }
        }
      }
    }
  }
`;

export const CANCEL_VENDOR_SUBSCRIPTION_MUTATION = gql`
  mutation cancelVendorSubscription($revvUid: String!, $organizationRevvUid: String!) {
    organizationCancelSubscription(revvUid: $revvUid, organizationRevvUid: $organizationRevvUid) {
      errors
      subscription {
        status
      }
    }
  }
`;

export const GET_VENDOR_SUBSCRIPTION_QUERY = gql`
  query getVendorSubscription($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        subscriptions(filters: { revvUid: $revv_uid }) {
          results {
            created_at
            first_name
            last_name
            amount
            canceled_at
            end_at
            source_url
            source_code
            email
            phone
            recurring
            card_expiry
            card_last4
            card_brand
            revv_uid
            profile_revv_uid
            user_type
            interval
            subscription_status
            contributor_type
            pac_name
            business_name

            origin_organization_name
            origin_organization_revv_uid
            recipient {
              revv_uid
              is_connected
              name
            }
            vendor_charge_revv_uid
            client_charge_revv_uid

            amount_cents
            vendor_amount_cents
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_RECIPIENTS_QUERY = gql`
  query getVendorRecipients($organizationRevvUid: String) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        name
        recipients {
          name
          revv_uid
        }
      }
    }
  }
`;
