import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';

import { ConfirmModal, EmptyList, Typography, useClipBoard, useToast } from 'modules/common';

import { ImagesTableDesktop } from './list-body/desktop.component';
import { ImagesTableMobile } from './list-body/mobile.component';
import { noop } from 'lodash';
import { useMutation } from '@apollo/client';
import { REMOVE_CANDIDATE_IMAGE, REMOVE_ORGANIZATION_IMAGE } from 'core/middleware/queries';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';

export function ImageListBody({
  images,
  setSelectedImageId,
  selectedImageId,
  isFilter,
  handleCloseFilter,
  refetch,
  imageUri,
  onSelectedImageDeletion,
}) {
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);
  const [removeModal, setRemoveModal] = useState({
    display: false,
    selectedImage: null,
  });
  const copyToClipBoard = useClipBoard();
  const setToast = useToast();

  const handleOnImageSelection = id => {
    if (selectedImageId === id) {
      setSelectedImageId(null);
    } else {
      setSelectedImageId(id);
    }
  };

  const handleCloseRemoveModal = () => {
    setRemoveModal({
      display: false,
      selectedImage: null,
    });
  };

  const [removeImage] = useMutation(isOrganization ? REMOVE_ORGANIZATION_IMAGE : REMOVE_CANDIDATE_IMAGE);

  const onRemoveImage = async image => {
    if (image.thumbnailUrl === imageUri) {
      onSelectedImageDeletion();
    }
    const variables = {
      imageAttributes: {
        imageId: image.id,
        organizationRevvUid: profileCandidate.organization_revv_uid,
      },
    };
    const response = await removeImage({ variables });
    const { errors } = response;
    if (errors) {
      setToast({ message: errors, isError: true });
      return;
    }
    refetch();
    setToast({ message: 'Image has been removed' });
    try {
    } catch (error) {
      console.error(error.message);
      setToast({ message: error, isError: true });
    }
  };

  const dropdownAction = (eventKey, image) => {
    switch (eventKey) {
      case 'copy':
        copyToClipBoard({
          text: image.originalUrl,
          toast: 'Image URL copied to clipboard.',
          logEventParams: {
            name: 'copy url',
            parameters: {
              'image id': image.id,
            },
          },
        });
        break;
      case 'delete':
        setRemoveModal({
          display: true,
          selectedImage: image,
        });
        break;
      default:
        break;
    }
  };

  if (!images || images.length === 0) {
    return (
      <EmptyList
        text="No Images"
        description={
          isFilter
            ? 'Clear image search filter or try another search operation'
            : 'There are currently no images uploaded for this data type'
        }
        buttonText={isFilter ? 'Clear Filter' : ''}
        icon="background-image"
        iconColor="green"
        name="images"
        onClick={isFilter ? handleCloseFilter : noop}
      />
    );
  }

  return (
    <>
      <div className="mt-3">
        <Breakpoint small down>
          <ImagesTableMobile
            items={images}
            dropdownAction={dropdownAction}
            handleOnImageSelection={handleOnImageSelection}
            selectedImageId={selectedImageId}
          />
        </Breakpoint>
        <Breakpoint medium up>
          <ImagesTableDesktop
            items={images}
            dropdownAction={dropdownAction}
            handleOnImageSelection={handleOnImageSelection}
            selectedImageId={selectedImageId}
          />
        </Breakpoint>
      </div>
      <ConfirmModal
        show={removeModal.display}
        title=" "
        buttonText="Delete Image"
        handleClose={handleCloseRemoveModal}
        onCancel={handleCloseRemoveModal}
        showCancelButton
        buttonColor="error"
        onSubmit={() => {
          onRemoveImage(removeModal.selectedImage);
          handleCloseRemoveModal();
        }}
      >
        <div className="text-center py-4">
          <Typography color="mirage" className="mb-0 font-medium text-xl">
            Are you sure you want to delete this image?
          </Typography>
          <Typography className="m-0 text-center">
            {`${removeModal.selectedImage?.imageFileName} will be removed from ${removeModal.selectedImage?.assignedImages?.length} page(s)`}
          </Typography>
        </div>
      </ConfirmModal>
    </>
  );
}

ImageListBody.propTypes = {
  images: PropTypes.array.isRequired,
};
