import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import { DateRangeInput, IconButton, WinRedSearchInput, SearchFilter, ToolTip, Icon } from 'modules/common';

class ListFilter extends Component {
  state = {
    showFilterPopover: false,
    filterPopover: null,
  };

  /**
   * get filter popover ref to open and close it
   */
  attachFilterPopoverRef = filterPopover => this.setState({ filterPopover });

  /**
   * toggle filter popover open and closed
   */
  toggleFilterPopover = () => {
    this.setState({ showFilterPopover: !this.state.showFilterPopover });
  };

  /**
   * on filter popover blur hide popover
   */
  handlePopoverBlur = () => {
    this.setState({ showFilterPopover: false });
  };

  /**
   * wrapper to close dropdown before clearing
   */
  clearFilter = event => {
    this.setState({ showFilterPopover: false });
    this.props.clearFilter(event);
  };

  /**
   * wrapper to close dropdown before submitting
   */
  submitFilter = event => {
    this.setState({ showFilterPopover: false });
    this.props.submitFilter(event);
  };

  /**
   * wrapper to tell parents this is coming from the search bar
   */
  handleSearchSubmit = event => {
    event?.preventDefault && event.preventDefault();
    event?.preventDefault && event.stopPropagation();
    this.props.submitFilter(event, true);
  };

  render() {
    const { filterPopover, showFilterPopover } = this.state;
    const {
      filter,
      onChangeFilter,
      searchPlaceholder,
      noSearch,
      actionComponent,
      dateHeader,
      noDropdown,
      toggleOpen,
      disabled,
      numberOfFilters,
      noChildProps,
      onlySearch,
      filterSearchToolTip,
    } = this.props;

    return (
      <>
        <Form
          noValidate
          onSubmit={this.handleSearchSubmit}
          className={classnames('pr-1', {
            'd-none d-lg-block min-width-19': noSearch,
          })}
        >
          {noSearch ? null : (
            <div className="d-flex align-items-center">
              {filterSearchToolTip ? (
                <ToolTip
                  tooltip={filterSearchToolTip}
                  placement="bottom"
                  className="mr-2 hidden md:block"
                  classNameOverlay="text-wrap"
                >
                  <Icon icon="info-circle" color="gray" size="lg" />
                </ToolTip>
              ) : null}
              <WinRedSearchInput
                placeholder={`Search ${searchPlaceholder}`}
                value={filter.search}
                onChange={onChangeFilter}
                disabled={disabled}
              />
            </div>
          )}
        </Form>

        {actionComponent}

        {onlySearch ? null : (
          <div className="pr-1">
            <IconButton
              text={numberOfFilters}
              active={numberOfFilters > 0}
              disabled={disabled}
              icon="filter"
              onClick={toggleOpen || this.toggleFilterPopover}
              ref={this.attachFilterPopoverRef}
              aria-label="filter dropdown menu for table"
            />
            {noDropdown && this.props.children ? (
              React.cloneElement(this.props.children, noChildProps ? null : { ...this.props })
            ) : (
              <SearchFilter
                target={filterPopover}
                show={showFilterPopover}
                onSubmit={this.submitFilter}
                onClear={this.clearFilter}
                onBlur={this.handlePopoverBlur}
                disableSubmit={false}
              >
                <Form.Group>
                  <DateRangeInput
                    title={dateHeader || 'Date'}
                    startDate={filter.dateRangeStart}
                    endDate={filter.dateRangeEnd}
                    onStartDateChange={onChangeFilter}
                    onEndDateChange={onChangeFilter}
                  />
                </Form.Group>

                {this.props.children}
              </SearchFilter>
            )}
          </div>
        )}
      </>
    );
  }
}

ListFilter.propTypes = {
  clearFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
  submitFilter: PropTypes.func.isRequired,

  onChangeFilter: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  dateHeader: PropTypes.string,
  noSearch: PropTypes.bool,
  noDropdown: PropTypes.bool,
  toggleOpen: PropTypes.func,
  actionComponent: PropTypes.object,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  numberOfFilters: PropTypes.number.isRequired,
};

ListFilter.defaultProps = {
  filter: {},
  disabled: false,
};

export default ListFilter;
