import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AmplitudePropTypes, Icon, Table, Typography, ToolTip, withAmplitude } from 'modules/common';
import { formatNumberWithCommas } from 'core/utilities';
import ShareModal from './shareModal.component';

function RenderTable({ items, navigateToItem, logEvent }) {
  const [shareUrl, setShareUrl] = useState(false);

  const tableBody = items.map((item, i) => {
    const { revv_uid, internalName, totalRaised } = item;
    const _totalRaised = `$${formatNumberWithCommas((totalRaised || 0).toFixed(2))}`;
    const navigateHandler = () => navigateToItem(revv_uid);

    return (
      <Table.Row key={revv_uid} onClick={navigateHandler} onKeyPress={navigateHandler} tabIndex="0" role="link">
        <Table.Cell textAlign="center" className="icon-cell">
          <ToolTip tooltip="Live">
            <Icon icon="donate" color="green" />
          </ToolTip>
        </Table.Cell>
        <Table.Cell>
          <Typography variant="bodyMedium" className="c--mirage mb-0">
            {internalName}
          </Typography>
        </Table.Cell>
        <Table.Cell>{_totalRaised}</Table.Cell>
        <Table.Cell textAlign="right" className="pr-3">
          <button
            className="share-team-page"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShareUrl(item.url);
              logEvent('share team page');
            }}
          >
            Share Page
          </button>
          <button
            className="view-team-page"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              window.open(item.url);
            }}
          >
            View Page
          </button>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <Table data-testid="team-pages-index">
        <Table.Head>
          <Table.Row>
            <Table.HeaderCell className="d-none d-md-table-cell" />
            <Table.HeaderCell className="team-page-name">Name</Table.HeaderCell>
            <Table.HeaderCell>Total Raised</Table.HeaderCell>
            <Table.HeaderCell className="d-none d-md-table-cell" />
          </Table.Row>
        </Table.Head>
        <Table.Body className="pointer">{tableBody}</Table.Body>
      </Table>

      <ShareModal shareUrl={shareUrl} setShareUrl={setShareUrl} />
    </>
  );
}

RenderTable.propTypes = {
  items: PropTypes.array.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  navigateToItem: PropTypes.func.isRequired,
};

export default withAmplitude(RenderTable);
