import React from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import classnames from 'classnames';
import { InputGroup } from 'react-bootstrap';

import FloatGroup from 'modules/common/form/float-group.component';
import SubText from 'modules/common/sub-text.component';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import { ValidatorComponent } from 'core/validation';
import { scssColors } from 'core/utilities';

import './index.scss';

class Slider extends ValidatorComponent {
  handleFocusChange = (isActive, setActive) => () => {
    setActive(isActive);
    const { onFocus } = this.props;

    if (typeof onFocus === 'function' && isActive) {
      onFocus();
    } else if (!isActive) {
      /* Call onBlur from ValidatorComponent which will also call onBlur from props if it exists. */
      this.onBlur();
    }
  };

  /**
   * wrapper to keep event structure standardized
   */
  onChange = value => this.props.onChange({ target: { name: this.props.name, value: value[0] } });

  render() {
    let {
      value,
      min,
      max,
      step,
      description,
      errorMessageClasses,
      errorIcon,
      invalidIcon,
      isWarning,
      title,
      onChange,
      ...rest
    } = this.props;

    if (value < min) value = min;

    const errorMessage = this.errorMessage();

    return (
      <FloatGroup.Context.Consumer>
        {floatGroup => (
          <>
            <FloatGroup>
              <div className="winred-slider-container">
                <div className="winred-slider">
                  <Range
                    step={step}
                    min={min}
                    max={max}
                    values={[value]}
                    onChange={this.onChange}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: '36px',
                          display: 'flex',
                          width: '100%',
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '4px',
                            background: getTrackBackground({
                              values: [value],
                              colors: [scssColors['winred-red'], scssColors.mystic],
                              min: min,
                              max: max,
                            }),
                            alignSelf: 'center',
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '25px',
                          width: '25px',
                          borderRadius: '25px',
                          backgroundColor: scssColors['winred-red'],
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: `0px 1px 2px ${scssColors.mystic}`,
                        }}
                      >
                        <div className="slider-value">{value}</div>
                      </div>
                    )}
                    {...rest}
                  />
                </div>

                <InputGroup.Append>
                  <InputGroup.Text>{title}</InputGroup.Text>
                </InputGroup.Append>
              </div>

              {description ? (
                <SubText description={description} className={classnames({ 'mb-0': errorMessage })} />
              ) : null}

              <InputErrorMessage
                errorMessageClasses={errorMessageClasses}
                errorMessage={errorMessage}
                errorIcon={errorIcon || invalidIcon}
                isWarning={isWarning}
              />
            </FloatGroup>
          </>
        )}
      </FloatGroup.Context.Consumer>
    );
  }
}

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};

Slider.defaultProps = {
  value: 0,
  min: 0,
  max: 100,
  step: 1,
};

export default Slider;
