import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, useMoneyPledgeOptions, SpinnerContainer, Error } from 'modules/common';
import StandardUpsell from './standard-upsell.component';
import MoneyPledge from '../moneyPledge.component';

function MoneyPledgeUpsell({ upsellCard, onUpdateEditedUpsell }) {
  const { loading, error, moneyPledgeOptions } = useMoneyPledgeOptions();

  if (error) return <Error error={error} />;
  if (loading) return <SpinnerContainer className="py-3" />;

  return (
    <div className="mt-3">
      <FormCardSpaced title="Money Pledge" subtitle="Add a money pledge to your upsell card" className="mx-4">
        <MoneyPledge
          editedUpsell={upsellCard}
          onUpdateEditedUpsell={onUpdateEditedUpsell}
          moneyPledgeOptions={moneyPledgeOptions}
        />
      </FormCardSpaced>
      <StandardUpsell upsellCard={upsellCard} onUpdateEditedUpsell={onUpdateEditedUpsell} />
    </div>
  );
}

MoneyPledgeUpsell.propTypes = {
  upsellCard: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default MoneyPledgeUpsell;
