import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ORGANIZATION_TIMEZONES, GET_CANDIDATE_TIMEZONES } from 'core/middleware/queries';
import { useOrganization } from 'modules/common/hooks';

export default function useTimezoneOptions() {
  const [isOrganization, profileCandidate] = useOrganization();
  const [getTimezones, { data, loading }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_TIMEZONES : GET_CANDIDATE_TIMEZONES
  );
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useEffect(() => {
    const variables = {};
    if (isOrganization) variables.organizationRevvUid = profileCandidate.revv_uid;
    getTimezones({ variables });
  }, [getTimezones, isOrganization, profileCandidate]);

  useEffect(() => {
    if (!data?.viewer) return;
    const timezones = (data.viewer.candidate || data.viewer.state_level_organization)?.available_time_zones || [];
    setTimezoneOptions(timezones.map(timezone => ({ label: timezone.name, value: timezone.value })));
  }, [data]);

  return [timezoneOptions, loading];
}
