export const SET_INITIAL_ROUTE = 'SET_INITIAL_ROUTE';

export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_CANDIDATE = 'SET_PROFILE_CANDIDATE';
export const SWITCH_PROFILE_CANDIDATE = 'SWITCH_PROFILE_CANDIDATE';

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

export const LOGIN_STATUSES = {
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGING_IN: 'LOGGING_IN',
  LOGGED_IN: 'LOGGED_IN',
  TWO_FA_SETUP: 'TWO_FA_SETUP',
  TWO_FA_VERIFICATION: 'TWO_FA_VERIFICATION',
};

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE = 'SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE';
export const SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS = 'SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS';
export const SET_ACTIVATE_STOREFRONT = 'SET_ACTIVATE_STOREFRONT';
