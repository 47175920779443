import {
  SET_EXPORTS_FILTER,
  SET_CUSTOM_REPORTS_FILTER,
  SET_INTEGRATIONS_FILTER,
  SET_AB_TESTING_FILTER,
  SET_MEDIA_LIBRARY_FILTER,
} from './types';

export const setExportsFilter = filter => ({
  type: SET_EXPORTS_FILTER,
  filter,
});

export const setCustomReportsFilter = filter => ({
  type: SET_CUSTOM_REPORTS_FILTER,
  filter,
});

export const setIntegrationsFilter = filter => ({
  type: SET_INTEGRATIONS_FILTER,
  filter,
});

export const setAbTestingFilter = filter => ({
  type: SET_AB_TESTING_FILTER,
  filter,
});
export const setMediaLibraryFilter = filter => ({
  type: SET_MEDIA_LIBRARY_FILTER,
  filter,
});
