import { gql } from 'apollo-boost';

export const GET_ORGANIZATION_DUPLICATION_TEMPLATES = gql`
  query getOrganizationPageDuplicationTemplates($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        page_duplication_templates {
          id
          name
          slug
          revv_uid
          recurring_agency_fees_enabled
          upsell_agency_fees_enabled
          source_code
          partnership_templates {
            id
            name
            feePercentage
            partnershipId
            avatar {
              id
              image {
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION_DUPLICATION_TEMPLATE = gql`
  mutation createOrganizationTemplateMutation($template: BasePageDuplicationTemplateInputType!) {
    organizationCreatePageDuplicationTemplate(template: $template) {
      errors
      pageDuplicationTemplate {
        created_at
        deleted_at
        id
        name
        recurring_agency_fees_enabled
        slug
        updated_at
        upsell_agency_fees_enabled
        revv_uid
        source_code
        partnership_templates {
          id
          feePercentage
          partnershipId
          avatar {
            id
            image {
              thumbnailUrl
            }
          }
        }
      }
      dammed
    }
  }
`;

export const UPDATE_ORGANIZATION_DUPLICATION_TEMPLATE = gql`
  mutation UpdateOrganizationTemplateMutation($template: UpdatePageDuplicationTemplateInputType!) {
    organizationUpdatePageDuplicationTemplate(template: $template) {
      errors
      pageDuplicationTemplate {
        created_at
        deleted_at
        id
        name
        recurring_agency_fees_enabled
        slug
        updated_at
        upsell_agency_fees_enabled
        revv_uid
        source_code
        partnership_templates {
          id
          feePercentage
          partnershipId
          avatar {
            id
            image {
              thumbnailUrl
            }
          }
        }
      }
      dammed
    }
  }
`;

export const DELETE_ORGANIZATION_DUPLICATION_TEMPLATE = gql`
  mutation DeleteTemplateMutation($revvUid: String!, $organizationRevvUid: String) {
    organizationDeletePageDuplicationTemplate(revvUid: $revvUid, organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;
