import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FloatGroup, FormCardSpaced, Input } from 'modules/common/form';
import { Card, ExternalLink, Typography, DoubleAddListItemButton } from 'modules/common';
import { SelectExistingThermometer } from './thermometer/select-existing.component';
import { SelectedThermometer } from './thermometer/selected-thermometer.component';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import { resetThermometerFields } from '../tools';
import { ThermometerWidgetDrawer } from '../drawer/thermometer-widget-drawer.component';

function WidgetThermometer({ widget, updateWidget, disabled = false }) {
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loadedThermometers, setLoadedThermometers] = useState({ loaded: false, results: [] });
  const [selectExistingView, setSelectExistingView] = useState(false || widget.isEdit);
  const [selectedThermometer, setSelectedThermometer] = useState(false || widget.isEdit);

  const toggleDrawer = () => {
    if (!drawerOpen && !widget.isEdit) {
      const tempWidget = { ...widget };
      updateWidget({
        target: {
          name: 'widgets',
          value: resetThermometerFields(tempWidget.isPetitionPage, tempWidget.revv_uid !== '', tempWidget),
        },
      });
    }
    setDrawerOpen(drawerOpen ? false : true);
  };

  const toggleSelectExisting = () => {
    if (selectedThermometer) setSelectedThermometer(false);
    setSelectExistingView(selectExistingView ? false : true);
  };

  const toggleSelectedThermometer = () => {
    setSelectExistingView(true);
    setSelectedThermometer(true);
  };

  const onRemoveSelection = () => {
    let tempWidget = { ...widget };
    updateWidget({
      target: { name: 'widgets', value: resetThermometerFields(tempWidget.isPetitionPage, true, tempWidget) },
    });
    setSelectExistingView(false);
  };

  return (
    <>
      <FormCardSpaced>
        <Card.Title>Thermometer</Card.Title>
        <Typography>
          Add a thermometer to your page to show progress towards your goal.&nbsp;
          <ExternalLink href="https://support.winred.com/en/articles/7979688-enable-the-thermometer-widget">
            Learn More
          </ExternalLink>
        </Typography>
        <FloatGroup>
          <Input
            type="text"
            name="thermometerLabel"
            placeholder="Thermometer Callout (Optional)"
            value={widget.thermometerLabel}
            disabled={disabled}
            maxLength="54"
            onChange={updateWidget}
            description="Add a donor-facing callout above your Thermometer."
          />
        </FloatGroup>
        {selectExistingView ? (
          selectedThermometer ? (
            <SelectedThermometer
              widget={widget}
              toggleSelectExisting={toggleSelectExisting}
              toggleDrawer={toggleDrawer}
              onRemoveSelection={onRemoveSelection}
            />
          ) : (
            <SelectExistingThermometer
              widget={widget}
              toggleSelectExisting={toggleSelectExisting}
              onChange={updateWidget}
              profileCandidate={profileCandidate}
              isOrganization={isOrganization}
              loadedThermometers={loadedThermometers}
              setLoadedThermometers={setLoadedThermometers}
              setSelectedThermometer={setSelectedThermometer}
            />
          )
        ) : (
          <DoubleAddListItemButton
            addItem1={toggleDrawer}
            addText1="Create New Thermometer"
            addSubText1="Create a new thermometer to add to your page."
            disabled1={disabled}
            addItem2={toggleSelectExisting}
            addText2="Add Existing Thermometer"
            addSubText2="Add a thermometer that you've previously created."
            disabled2={disabled}
          />
        )}
      </FormCardSpaced>
      <ThermometerWidgetDrawer
        open={drawerOpen}
        closeDrawer={toggleDrawer}
        data={widget}
        onChange={updateWidget}
        toggleSelectedThermometer={toggleSelectedThermometer}
      />
    </>
  );
}

WidgetThermometer.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default WidgetThermometer;
