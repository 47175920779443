import React from 'react';
import PropTypes from 'prop-types';
import '../custom.scss';

function RequiredLabel({ formState }) {
  return formState.required && formState.label ? <span className="required-asterisk">*</span> : null;
}

RequiredLabel.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default RequiredLabel;
