import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuRevenue({
  pathname,
  handleSelect,
  organizationRevvUid,
  showRevenueSubNav,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  const donationsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/revenue/donations`
    : `/${organizationRevvUid}/revenue/donations`;

  const subscriptionsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/revenue/subscriptions`
    : `/${organizationRevvUid}/revenue/subscriptions`;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for revenue menu"
      showSubmenu={showRevenueSubNav}
    >
      <NavList.Item eventKey={donationsRoute} href={donationsRoute}>
        Donations
      </NavList.Item>
      {isAgency ? null : (
        <NavList.Item eventKey={subscriptionsRoute} href={subscriptionsRoute}>
          Subscriptions
        </NavList.Item>
      )}

      {isAgency ? null : (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/revenue/money-pledges`}
          href={`/${organizationRevvUid}/revenue/money-pledges`}
        >
          Money Pledges
        </NavList.Item>
      )}
    </SubMenuContainer>
  );
}

NavBarSubMenuRevenue.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showRevenueSubNav: PropTypes.bool.isRequired,
};

export default NavBarSubMenuRevenue;
