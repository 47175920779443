import Honeybadger from 'honeybadger-js';

const HoneybadgerConfig = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HB_WINRED_CLIENT_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENVIRONMENT,
  disabled: !!process.env.REACT_APP_HONEYBADGER_DISABLED || false,
  revision: process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION,
});

export default HoneybadgerConfig;

export const setHoneyContext = profile => {
  const honeyBadgerContext = {
    user_id: profile.revv_uid,
    candidate: profile.candidate?.name ?? 'Donor User',
    version: window.appVersion,
  };

  console.debug({ honeyBadgerContext });
  Honeybadger.setContext(honeyBadgerContext);
};
