import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'react-bootstrap/Image';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';

function ProfileImage({ candidate, label = 'committee menu', className = '' }) {
  const candidateAvatarUrl = candidate?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar;

  return (
    <div className={classnames('menu-avatar-container', className)} aria-label={label}>
      <Image className="navbar-avatar-image" alt="Committee logo" src={candidateAvatarUrl} />
    </div>
  );
}

ProfileImage.propTypes = {
  candidate: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default ProfileImage;
