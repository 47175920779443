import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Nav from 'react-bootstrap/Nav';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';

function NavBarMenuStoreFront({
  toggleSubNav,
  hasActiveChildStoreFront,
  showMobileSubNav,
  organizationRevvUid,
  approved,
  removeAccess,
}) {
  if (removeAccess.includes(true) || !approved) return null;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildStoreFront,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showStoreFrontSubNav')}>
          Merchandise
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size={'xs'}
            color={hasActiveChildStoreFront ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>

      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        <Nav.Link
          eventKey={`/${organizationRevvUid}/merchandise/products`}
          href={`/${organizationRevvUid}/merchandise/products`}
        >
          Products
        </Nav.Link>
        <Nav.Link
          eventKey={`/${organizationRevvUid}/merchandise/orders`}
          href={`/${organizationRevvUid}/merchandise/orders`}
        >
          Orders
        </Nav.Link>
        <Nav.Link
          eventKey={`/${organizationRevvUid}/merchandise/settings`}
          href={`/${organizationRevvUid}/merchandise/settings`}
        >
          Storefront Settings
        </Nav.Link>
      </MobileSubMenu>
    </>
  );
}

NavBarMenuStoreFront.propTypes = {
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildStoreFront: PropTypes.bool.isRequired,
  showMobileSubNav: PropTypes.bool.isRequired,
};

export default NavBarMenuStoreFront;
