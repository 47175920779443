import gql from 'graphql-tag';

export const productFragments = {
  products: gql`
    fragment Products on ProductResult {
      current_page
      page_count
      total_count

      results {
        id
        revv_uid
        created_at
        name
        type
        price_cents
        base_price_cents
        active_in_storefront
        active
        user_defined_category
        fulfillment_house
      }
    }
  `,
  product: gql`
    fragment Product on Product {
      id
      revv_uid
      created_at
      name
      slug
      description
      price_cents
      active_in_storefront
      active
      caption
      type
      fulfillment_house
      source
      user_defined_category
      use_shipping_rates
      base_price_cents
      base_shipping_cost_cents
      additional_shipping_cost_cents
      merch_campaign_minimum
      allow_price_editing
      age_group
      gender
      custom_color
      color
      product_images {
        id
        image {
          id
        }
        image_url
        created_at
      }
      options {
        id
        name
        option_set_values {
          id
          value
          position
          option {
            id
          }
        }
      }
      variants {
        id
        revv_uid
        created_at
        sku
        name
        active
        additional_shipping_cost
        variant_image {
          revv_uid
          image_url
        }
        variant_price
        inventory_count
        low_inventory
        options {
          id
          value
          merch_product_option_id
          merch_product_option_name
        }
      }
    }
  `,
};

export const GET_PRODUCTS_QUERY = gql`
  query getProducts(
    $page: Int
    $limit: Int
    $search: String
    $organizationRevvUid: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: RansackPredicate
    $fulfillmentHouse: FulfillmentHouseEnum
    $productCategory: MerchProductUserDefinedCategoryEnum
    $status: MerchProductStatusEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        store_products(
          filters: {
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            price: $amount
            pricePredicate: $amountPredicate
            fulfillmentHouse: $fulfillmentHouse
            productCategory: $productCategory
            status: $status
          }
        ) {
          ...Products
        }
      }
    }
  }
  ${productFragments.products}
`;

export const GET_PRODUCT_QUERY = gql`
  query getProduct($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        storefront_settings {
          fulfillmentHouse
        }
        store_products(filters: { revvUid: $revv_uid }) {
          results {
            ...Product
          }
        }
      }
    }
  }
  ${productFragments.product}
`;

export const TOGGLE_ACTIVE_MUTATION = gql`
  mutation UpdateStorefrontProductMutation($product: StorefrontProductInput!, $organizationRevvUid: String) {
    candidateUpdateStorefrontProduct(product: $product, organizationRevvUid: $organizationRevvUid) {
      message
      errors
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createCandidateMerchProductMutation($merchProduct: CreateMerchProductInput!, $organizationRevvUid: String) {
    candidateCreateMerchProduct(merchProduct: $merchProduct, organizationRevvUid: $organizationRevvUid) {
      errors
      merchProduct {
        ...Product
      }
    }
  }
  ${productFragments.product}
`;

export const UPDATE_PRODUCT = gql`
  mutation updateCandidateMerchProductMutation($merchProduct: UpdateMerchProductInput!, $organizationRevvUid: String) {
    candidateUpdateMerchProduct(merchProduct: $merchProduct, organizationRevvUid: $organizationRevvUid) {
      errors
      merchProduct {
        ...Product
      }
    }
  }
  ${productFragments.product}
`;
