import React from 'react';
import { Switch } from 'react-router-dom';

/** switch routers dont like react fragments so we remove those here on render  */
export const FragmentSupportingSwitch = ({ children }) => {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(React, [Switch, null].concat(flattenedChildren));
};

function flatten(target, children) {
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}
