import gql from 'graphql-tag';

export const CANDIDATE_BULK_ACTION_HISTORY = gql`
  query getCandidateBulkActionHistory($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        bulk_action_history {
          money_pledge {
            created_at
            whodunnit
          }
          recurring_callout {
            created_at
            whodunnit
          }
          upsell_flow {
            created_at
            whodunnit
          }
          upsell_pathway {
            created_at
            whodunnit
          }
          sms {
            created_at
            whodunnit
          }
          open_graph {
            created_at
            whodunnit
          }
          exit_intent_widget {
            created_at
            whodunnit
          }
          donor_covers_fees {
            created_at
            whodunnit
          }
          page_images {
            created_at
            whodunnit
          }
          thermometer_widget {
            created_at
            whodunnit
          }
          petition_pathway {
            created_at
            whodunnit
          }
        }
      }
    }
  }
`;

export const BULK_ACTION_UPSELLS = gql`
  query getBlukActionUpsells($limit: Int!, $search: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { limit: $limit, search: $search }) {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;

export const BULK_ACTION_MONEY_PLEDGES = gql`
  query getBlukActionUpsellsMoneyPledges($limit: Int!, $search: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: $limit, search: $search }) {
          results {
            revv_uid
            name
            public_name
            money_pledge_date
          }
        }
      }
    }
  }
`;

export const BULK_ACTION_PATHWAYS = gql`
  query getBlukActionPathways(
    $limit: Int!
    $search: String
    $organizationRevvUid: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(filters: { limit: $limit, search: $search, pathwayType: $pathwayType }) {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;
