import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'react-recompose';
import { Breakpoint } from 'react-socks';

import { EmptyList, Pagination, LIST_PAGE_SIZE, withCopyToClipBoard, ConfirmModal } from 'modules/common';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import { setToast } from 'core/toast';

import { useMutation } from '@apollo/client';
import honeybadger from 'honeybadger-js';
import {
  ORGANIZATION_PATHWAY_ARCHIVE_MUTATION,
  ORGANIZATION_PATHWAY_DUPLICATE_MUTATION,
} from '../../../core/middleware/queries/pages/pathways.organization.queries';
import {
  PATHWAY_ARCHIVE_MUTATION,
  PATHWAY_DUPLICATE_MUTATION,
} from '../../../core/middleware/queries/pages/pathways.queries';
import { useHistory } from 'react-router-dom';
import { UpsellPathwayItemsDesktop } from './list-body/upsell-pathway-items-desktop.component';
import { UpsellPathwayItemsMobile } from './list-body/upsell-pathway-items-mobile.component';
import { isEmptyList } from 'modules/common/lists/pagination.tools';

function UpsellPathwaysListBody({
  candidate,
  toggleDrawer,
  onChangeFilter,
  setIsProcessingData,
  refetch,
  filter,
  clearFilter,
}) {
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const dispatch = useDispatch();
  const sendToast = message => dispatch(setToast(message));
  const history = useHistory();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [selectedArchivePathway, setSelectedArchivePathway] = useState(null);

  const [duplicate, { loading }] = useMutation(
    isOrganization ? ORGANIZATION_PATHWAY_DUPLICATE_MUTATION : PATHWAY_DUPLICATE_MUTATION
  );
  const [archive, { loading: loadingArchive }] = useMutation(
    isOrganization ? ORGANIZATION_PATHWAY_ARCHIVE_MUTATION : PATHWAY_ARCHIVE_MUTATION
  );

  const dropdownAction = (eventKey, item) => {
    switch (eventKey) {
      case 'details':
        toggleDrawer(item);
        break;
      case 'archive':
        setShowArchiveModal(true);
        setSelectedArchivePathway(item);
        break;
      case 'duplicate':
        duplicatePathway(item);
        break;
      case 'preview':
        window.open(item?.upsell_preview_url, '_blank');
        break;
      default:
        break;
    }
  };

  const duplicatePathway = async pathway => {
    setIsProcessingData(loading);

    try {
      const { data } = await duplicate({
        variables: {
          pathway: {
            id: pathway.id,
            name: pathway.name,
          },
          organizationRevvUid: profileCandidate.organization_revv_uid,
        },
      });

      let { errors: error } = data.candidateDuplicatePathway || data.organizationDuplicatePathway;
      setIsProcessingData(loading);

      if (error && error.length > 0) {
        sendToast({
          message: error[0],
          isError: true,
        });
        return;
      }

      sendToast({
        message: 'Pathway duplicated.',
      });
      sendToast({
        message: 'Your Pathway has been duplicated.',
        isError: false,
      });

      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;
      honeybadger.notify(message);
      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const { current_page, page_count, total_count, results } = candidate?.pathways;

  const confirmArchive = async () => {
    const archivedPathwayId = selectedArchivePathway?.id;

    setShowArchiveModal(false);
    setIsProcessingData(loadingArchive);

    const pathway = { id: archivedPathwayId };
    try {
      const { data } = await archive({
        variables: { pathway, organizationRevvUid: profileCandidate.organization_revv_uid },
      });

      setIsProcessingData(loadingArchive);

      let { errors: error } = data.candidateArchivePathway || data.organizationArchivePathway;

      if (error && error.length > 0) {
        sendToast({
          message: error,
          isError: true,
        });
        return;
      }

      sendToast({
        message: 'Your Pathway has been archived.',
        isError: false,
      });

      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;
      honeybadger.notify(message);

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const attachedPages = selectedArchivePathway?.page_count;

  const navigateToItem = uuid => {
    const url = `${history.location.pathname}/${uuid}`;
    history.push(url);
  };

  if (isEmptyList(candidate?.pathways?.results, filter)) {
    return (
      <>
        <EmptyList
          text="No Upsell Pathways"
          description="Upsell Pathways are a series of Upsells used to guide Donors from a Donation Page to Upsells for additional asks based on specific criteria. Create an Upsell Pathway and add it to your page to personalize your end user's experience."
          buttonText="Create Upsell Pathway"
          icon="upsell-pathway-icon"
          iconSize="lg"
          onClick={() => {
            history.push(`/${profileCandidate.organization_revv_uid}/pages/new-upsell-pathway`);
          }}
        />
      </>
    );
  }

  return (
    <>
      <Breakpoint small down>
        <UpsellPathwayItemsMobile items={results} navigateToItem={navigateToItem} profileCandidate={profileCandidate} />
      </Breakpoint>
      <Breakpoint medium up>
        <UpsellPathwayItemsDesktop
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
        />
      </Breakpoint>

      <Pagination
        logEventName="upsell-pathways"
        currentPage={current_page}
        limit={LIST_PAGE_SIZE}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        filter={filter}
        results={results}
        clearFilter={clearFilter}
      />

      <ConfirmModal
        show={showArchiveModal}
        title="Archive Upsell Pathway"
        buttonText="Archive Upsell Pathway"
        buttonColor="error"
        showCancelButton
        handleClose={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
        onSubmit={confirmArchive}
      >
        {attachedPages > 0
          ? `Are you sure you want to archive this Upsell Pathway? It currently belongs to ${attachedPages} page${
              attachedPages !== 1 ? 's' : ''
            }. Archiving it will remove it from those pages.`
          : `Are you sure you want to archive this Upsell Pathway? This cannot be undone.`}
      </ConfirmModal>
    </>
  );
}

UpsellPathwaysListBody.propTypes = {
  candidate: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setIsProcessingData: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};

export default compose(withCopyToClipBoard)(UpsellPathwaysListBody);
