import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { WinRedChip, ButtonLink, ToolTip, Icon } from 'modules/common';
import { convertEpochTime, noEvent } from 'core/utilities';
import { DISABLED_UPSELL_TYPES } from '../types';
import { UPSELL_COMPONENT_MAP } from '../tools';

function renderUpsellItemMobile({ items, navigateToItem }) {
  if (!items) return null;

  return items.map(item => {
    const { revv_uid, name, upsell_statistics, upsell_group_count, created_at, public_upsell, bill_pay_exists } = item;
    const rowDisabled = DISABLED_UPSELL_TYPES.includes(item.type);
    const upsellComponent = UPSELL_COMPONENT_MAP[item.type] || {};

    return (
      <div
        key={revv_uid}
        className="list-item-card"
        onClick={() => navigateToItem(item)}
        onKeyPress={() => navigateToItem(item)}
        tabIndex="0"
        role="link"
      >
        <Row className="no-gutters">
          <Col xs="2">
            <ToolTip tooltip={upsellComponent.title}>
              <Icon icon={upsellComponent.icon} />
            </ToolTip>
          </Col>
          <Col xs="10">
            <Row className="no-gutters">
              <Col className="list-item-card-title">
                <span>{name}</span>
                {public_upsell ? (
                  <WinRedChip className="ml-0 ml-md-2 mt-1 mt-md-0" variant="yellow">
                    Public
                  </WinRedChip>
                ) : null}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                {bill_pay_exists ? (
                  <WinRedChip className="ml-0 ml-md-2 mt-1 mt-md-0 cursor-default" variant="purple">
                    Bill-Pay
                  </WinRedChip>
                ) : null}
              </Col>
            </Row>
            <Row className="no-gutters">
              {upsell_group_count || 0} Attached Upsell Flow{upsell_group_count !== 1 ? 's' : ''}
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">Opt-In Rate: {(upsell_statistics.percentage * 100).toFixed(2)}%</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{convertEpochTime(created_at)}</Col>
            </Row>
            {!item.upsell_preview_url ? null : (
              <Row className="no-gutters mt-2">
                <ButtonLink
                  disabled={rowDisabled}
                  skinnyButton
                  className="mr-2"
                  onClick={noEvent}
                  onKeyPress={noEvent}
                  url={item.upsell_preview_url}
                >
                  Preview
                </ButtonLink>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  });
}

renderUpsellItemMobile.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
};

export default renderUpsellItemMobile;
