import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';
import './trend.scss';

function TrendValue({ children, spacer = false }) {
  return (
    <Typography className={classnames('mb-0 ', { 'mr-3': spacer })}>
      <span className="value">{children}</span>
    </Typography>
  );
}

TrendValue.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TrendValue;
