import produce from 'immer';

import { SET_ORDERS_FILTER, SET_PRODUCTS_FILTER } from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialStorefrontState = {
  ordersFilter: {
    page: 1,
    activeTabKey: 'all',
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    search: null,
  },

  productsFilter: {
    page: 1,
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    status: '',
    fulfillmentHouse: null,
    productCategory: null,
  },
};

export const storefrontReducer = produce((state = initialStorefrontState, action) => {
  let filter = {};

  switch (action.type) {
    case SET_ORDERS_FILTER:
      filter = { ...action.filter };
      return { ...state, ordersFilter: filter };
    case SET_PRODUCTS_FILTER:
      filter = { ...action.filter };
      return { ...state, productsFilter: filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialStorefrontState };
    default:
      return state;
  }
}, initialStorefrontState);
