import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuUtilities({
  pathname,
  handleSelect,
  organizationRevvUid,
  removeAccess,
  showUtilitiesSubNav,
  profileCandidate,
  isAgency,
}) {
  if (removeAccess.includes(true)) return null;

  const integrationsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/utilities/integrations`
    : `/${organizationRevvUid}/utilities/integrations`;

  const exportsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/utilities/exports`
    : `/${organizationRevvUid}/utilities/exports`;

  const customReportsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/utilities/custom-reports`
    : `/${organizationRevvUid}/utilities/custom-reports`;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for utilities menu"
      showSubmenu={showUtilitiesSubNav}
    >
      {(profileCandidate.org_approved_by_win_red || profileCandidate.org_approved_by_revv) && !isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/utilities/emails`}
          href={`/${organizationRevvUid}/utilities/emails`}
        >
          Emails
        </NavList.Item>
      ) : null}

      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/utilities/notifications`}
          href={`/${organizationRevvUid}/utilities/notifications`}
        >
          Notifications
        </NavList.Item>
      ) : null}

      <NavList.Item eventKey={integrationsRoute} href={integrationsRoute}>
        Integrations
      </NavList.Item>

      <NavList.Item eventKey={exportsRoute} href={exportsRoute}>
        Data Exports
      </NavList.Item>
      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/utilities/media`}
          href={`/${organizationRevvUid}/utilities/media`}
        >
          Media Library
        </NavList.Item>
      ) : null}

      <NavList.Item eventKey={customReportsRoute} href={customReportsRoute}>
        Custom Reports
      </NavList.Item>

      {isAgency ? null : (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/utilities/bulk-actions`}
          href={`/${organizationRevvUid}/utilities/bulk-actions`}
        >
          Bulk Actions
        </NavList.Item>
      )}

      {profileCandidate.ab_tests_feature_enabled && (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/utilities/ab-testing`}
          href={`/${organizationRevvUid}/utilities/ab-testing`}
        >
          A/B Tests
        </NavList.Item>
      )}
    </SubMenuContainer>
  );
}

NavBarSubMenuUtilities.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showUtilitiesSubNav: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
};

export default NavBarSubMenuUtilities;
