import React from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { scssColors } from 'core/utilities';
import { xTickFormatter, yTickFormatter, CustomXAxis, CustomYAxis, generateCustomGrowthToolTip } from './chart.tools';
import './charts.scss';

const ROUNDED_TOP_RADIUS = [5, 5, 0, 0];
const DOMAIN = ['auto', 'auto'];

function WinRedBarChart({ data, xProp, yProp, yProp2, yDiffProp, dateRange }) {
  const xAxisTicks = [data[0][xProp], data[data.length - 1][xProp]];

  const toolTipFormat = dateRange === 'ALL_TIME' || dateRange === 'YEAR_TO_DATE' ? 'MMMM yyyy' : null;
  const CustomToolTip = generateCustomGrowthToolTip(xProp, yProp, yProp2, yDiffProp, toolTipFormat);

  const isOnlyOnePoint = data.length === 1;
  if (isOnlyOnePoint) {
    const data2 = { ...data[0] };
    data2.date--;
    data = [data[0], data2];
  }

  return (
    <ResponsiveContainer aspect={4} debounce={300} className="winred-chart">
      <ComposedChart data={data} stackOffset="sign">
        <XAxis
          dataKey={xProp}
          tickLine={false}
          axisLine={false}
          interval={isOnlyOnePoint ? 1 : 'preserveStart'}
          ticks={xAxisTicks}
          tickFormatter={xTickFormatter}
          tick={<CustomXAxis />}
        />

        <YAxis
          tickLine={false}
          domain={DOMAIN}
          axisLine={false}
          orientation="right"
          tick={<CustomYAxis />}
          tickFormatter={yTickFormatter}
        />

        <Tooltip content={CustomToolTip} animationDuration={300} />

        <Bar
          dataKey={yProp}
          fill={scssColors['jagged-ice']}
          stroke={scssColors['jagged-ice']}
          stackId="stack"
          radius={ROUNDED_TOP_RADIUS}
        />
        {yProp2 ? (
          <Bar
            dataKey={yProp2}
            fill={scssColors['oyster-pink']}
            stroke={scssColors['oyster-pink']}
            stackId="stack"
            radius={ROUNDED_TOP_RADIUS}
          />
        ) : null}
        {yDiffProp ? (
          <Line dataKey={yDiffProp} fill={scssColors['winred-green']} stroke={scssColors['winred-green']} />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

WinRedBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  xProp: PropTypes.string.isRequired,
  yProp: PropTypes.string.isRequired,
  yProp2: PropTypes.string,
  yDiffProp: PropTypes.string,
  dateRange: PropTypes.string.isRequired,
};

export default WinRedBarChart;
