import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { setTitle, SpinnerContainer, Error, IndexTable } from 'modules/common';

function IndexList({
  data,
  renderHeader,
  children,
  pageTitle = '',
  noCandidate,
  loading,
  error,
  refetch,
  candidate,
  className,
  shouldTitleCase = true,
}) {
  // HOC vs hook apollo have different data structures
  data = { ...(data?.data || {}), ...data };

  let content = '';
  const _error = data.error || data.errors || error;

  if (_error) {
    content = <Error error={_error} errorAction={refetch || data.refetch} />;
  } else if (data.loading || loading) {
    content = <SpinnerContainer />;
  } else {
    content = <IndexTable className={className}>{children}</IndexTable>;
  }

  return (
    <main>
      {setTitle(pageTitle, shouldTitleCase)}
      {renderHeader(data)}
      {content}
    </main>
  );
}

IndexList.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  noCandidate: PropTypes.bool,
  pageTitle: PropTypes.string.isRequired,
  renderHeader: PropTypes.func.isRequired,
  shouldTitleCase: PropTypes.bool,
  skipNotFound: PropTypes.bool,
};

export default withRouter(IndexList);
