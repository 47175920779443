import gql from 'graphql-tag';

export const GET_ORGANIZATION_NOTIFICATIONS_QUERY = gql`
  query getOrganizationEmailNotifications($organizationRevvUid: String, $filters: EmailNotificationFilter) {
    viewer {
      email
      state_level_organization(revvUid: $organizationRevvUid) {
        email_notification_integrations(filters: $filters) {
          revvUid
          active
          recipients
          source
          note
          emailNotificationEvents {
            eventType
            enabled
          }
          donationFields {
            id
            attributeName
            predicate
            value
            name
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_NOTIFICATION_QUERY = gql`
  query getOrganizationEmailNotification($revvUid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns {
          results {
            revv_uid
            name
          }
        }
        email_notification_integrations(filters: { revvUid: $revvUid }) {
          revvUid
          active
          recipients
          emailNotificationType
          source
          note
          emailNotificationEvents {
            eventType
            enabled
          }
          donationFields {
            id
            attributeName
            predicate
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PAYOUT_NOTIFICATION_QUERY = gql`
  query getOrganizationPayoutNotification($organizationRevvUid: String) {
    viewer {
      email
      state_level_organization(revvUid: $organizationRevvUid) {
        payout_report_notification {
          recipients
        }
      }
    }
  }
`;

export const ORGANIZATION_NOTIFICATION_PAYOUT_MUTATION = gql`
  mutation organizationPayoutReportNotification($payoutReportNotification: PayoutReportNotificationInput!) {
    organizationPayoutReportNotification(payoutReportNotification: $payoutReportNotification) {
      errors
      dammed
      payoutReportNotification {
        recipients
      }
    }
  }
`;

export const CREATE_ORGANIZATION_NOTIFICATION_MUTATION = gql`
  mutation organizationNotificationChargeIntegration($integration: EmailNotificationIntegrationInput!) {
    organizationEmailNotificationIntegration(integration: $integration) {
      errors
      dammed
      emailNotificationIntegration {
        revvUid
        active
        recipients
        source
        note
        emailNotificationEvents {
          eventType
          enabled
        }
        donationFields {
          id
          attributeName
          predicate
          value
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_NOTIFICATION_MUTATION = gql`
  mutation organizationNotificationChargeIntegration($integration: EmailNotificationIntegrationInput!) {
    organizationEmailNotificationIntegration(integration: $integration) {
      errors
      dammed
      emailNotificationIntegration {
        revvUid
        active
        recipients
        source
        note
        emailNotificationEvents {
          eventType
          enabled
        }
        donationFields {
          id
          attributeName
          predicate
          value
          name
        }
      }
    }
  }
`;

export const GET_NEW_ORGANIZATION_NOTIFICATION_CHARGE_QUERY = gql`
  query getNewOrganizationChargeNotification($organizationRevvUid: String) {
    viewer {
      email
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ORGANIZATION_NOTIFICATION_MUTATION = gql`
  mutation deleteOrganizationIntegrationMutation($integration: DeleteIntegrationInput!) {
    organizationDeleteIntegration(integration: $integration) {
      errors
      message
    }
  }
`;

export const DELETE_ORGANIZATION_PAYOUT_NOTIFICATION_MUTATION = gql`
  mutation organizationArchivePayoutReportNotification($organizationRevvUid: String!) {
    organizationArchivePayoutReportNotification(organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;
