import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BsCard from 'react-bootstrap/Card';

import Typography from './typography.component';

const VARIANTS = {
  alt: 'border-catskill bg--catskill-white',
  blue: 'bg--white-lilac border-mystic',
  green: 'bg--panache border-surf-crest',
  red: 'bg--rose-white border-oyster-pink c--winred-light-red',
  gold: 'bg--romance border-pearl-bush',
  gray: 'bg--porcelain border-mystic',
  upload: 'bg-transparent border-mystic border-dashed',
  'upload-white': 'bg-white border-mystic border-dashed',
  orange: 'border-rajah bg-banana border-dashed',
};

const Card = props => {
  let { children, variant, pro, square, className, disabled, ...rest } = props;
  if (disabled) variant = 'alt';

  return (
    <BsCard
      className={classNames(
        'rounded',
        'border',
        {
          'border-catskill bg--white': !variant && !pro,
          [VARIANTS[variant]]: !pro && variant,
        },
        className
      )}
      {...rest}
    >
      {children}
    </BsCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  pro: PropTypes.bool,
  square: PropTypes.bool,
  className: PropTypes.string,
};

// expose bootstrap subcomponents on our version of Card
// not using a loop to set these so that IDE autocomplete works
Card.Body = BsCard.Body;
Card.Footer = BsCard.Footer;
Card.Header = BsCard.Header;
Card.Img = BsCard.Img;
Card.ImgOverlay = BsCard.ImgOverlay;
Card.Link = BsCard.Link;
Card.Subtitle = BsCard.Subtitle;
Card.Text = BsCard.Text;

/* Override the card title component and use a h4 tag instead of the bootstrap default of a h5 tag. */
const Title = ({ className, classNameSubtitle, children, subtitle, ...restProps }) => (
  <>
    <div className={classNames('mb-0', 'winred-h5', className)} {...restProps}>
      {children}
    </div>
    {subtitle ? <Typography className={classNameSubtitle}>{subtitle}</Typography> : null}
  </>
);

Title.propTypes = {
  className: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  children: PropTypes.any,
  subtitle: PropTypes.any,
};

Card.Title = Title;

export default Card;
