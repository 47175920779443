import { isNull } from 'lodash';
import { createFilter } from 'react-select/dist/react-select.cjs.prod';

export const CATEGORIES_PAGE_LIMIT = 10;

export const createOptions = data => {
  if (!data?.viewer) return [];

  const candidate = data.viewer.candidate || data.viewer.state_level_organization;
  const productListOptions = candidate.store_products.results.map(product => ({
    label: `${product.name} (${product.active_in_storefront ? 'Active' : 'Inactive'})`,
    value: product,
  }));
  return productListOptions;
};

export const productsListDropdownFilter = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
});

export const displayCategoryInformation = item => {
  const { parent_category, sub_category_count } = item;
  if (isNull(parent_category)) return `Subcategories: ${sub_category_count}`;
  return `Category: ${parent_category.internal_name}`;
};

export const reformatIndexArray = array => {
  let reformattedArray = [];
  array.forEach(category => {
    reformattedArray.push({
      revvUid: category.revv_uid,
      position: category.position,
    });
  });
  return reformattedArray;
};

export const reorderIndexArray = (array, startIndex, endIndex) => {
  let result = array;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // reorder positions
  let position = 1;
  result = result.map(category => {
    category.position = position++;
    return category;
  });
  return result;
};

export const reorderPositionIndexArray = (array, startIndex, endIndex) => {
  let result = array;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // reorder positions
  let position_index = 1;
  result = result.map(category => {
    category.position_index = position_index++;
    return category;
  });
  return result;
};

export const checkForSelectedCategoryFields = (category, isEditMode) => {
  return {
    revv_uid: isEditMode ? category?.revv_uid : '',
    category_name: isEditMode ? category?.internal_name : '',
    is_subcategory: isEditMode ? !isNull(category?.parent_category) : false,
    category_parent: isEditMode ? category?.parent_category : null,
    slug_url: isEditMode ? category?.slug : '',
    products: isEditMode ? category?.products : [],
    heroAssignedImage: isEditMode ? category?.hero_assigned_image : null,
    mobileHeroAssignedImage: isEditMode ? category?.hero_mobile_assigned_image : null,
  };
};

export const createMainCategoryOptions = categories => {
  let mainCategoryOptions = [];
  categories.forEach(category => {
    if (isNull(category.parent_category))
      mainCategoryOptions.push({
        label: category.internal_name,
        value: {
          id: category.id,
          revv_uid: category.revv_uid,
          internal_name: category.internal_name,
          __typename: category.__typename,
        },
      });
  });
  return mainCategoryOptions;
};

export const formatImageForServer = (item, prop) => {
  if (isNull(item[prop])) return null;
  let newImage = {
    imageId: item[prop].imageId || item[prop].image.id || item[prop].image.imageId,
  };
  if (item[prop].id) {
    newImage.id = item[prop].id;
  }
  if (item[prop].destroy) {
    newImage.destroy = true;
  }
  return newImage;
};

export const formatCategoryForServer = (formData, isEditMode) => {
  const { revv_uid, category_name, is_subcategory, category_parent, slug_url, products } = formData;
  let productsReformat = [];
  products.forEach(product => {
    if (product.destroy) {
      productsReformat.push({ productId: product.id, destroy: true });
    } else {
      productsReformat.push({ productId: product.id });
    }
  });

  let variables = {
    category: {
      internalName: category_name,
      slug: slug_url,
      products: productsReformat,
      heroAssignedImage: formatImageForServer(formData, 'heroAssignedImage'),
      mobileHeroAssignedImage: formatImageForServer(formData, 'mobileHeroAssignedImage'),
    },
  };
  if (isNull(variables.category.heroAssignedImage)) {
    delete variables.category.heroAssignedImage;
  }
  if (isNull(variables.category.mobileHeroAssignedImage)) {
    delete variables.category.mobileHeroAssignedImage;
  }
  if (is_subcategory && !isEditMode) {
    variables.category.parentCategoryId = category_parent.id;
  }
  if (isEditMode) {
    variables.category.revvUid = revv_uid;
    variables.category.parentCategoryId = category_parent ? category_parent.id : null;
  }
  return variables;
};

export const getSlugUrlPrependText = (width, slug) => {
  return width <= 576 ? `.../storefront/?collection=` : `secure.winred.com/${slug}/storefront/?collection=`;
};
