import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/common';
import Icon from '../icon.component';
import './filters.scss';

function FilterItems({ onChangeFilter, uiFilter }) {
  if (!uiFilter || uiFilter.length === 0) return null;

  return (
    <div className="mb-3 filter-items-container">
      {uiFilter.map(item => {
        const onClick = () => onChangeFilter({ target: { name: item.name, value: item.defaultValue, requiredFilter: !!item.requiredFilter } }, true);

        return (
          <Button key={item.name} className="cursor-default mr-2 my-2" variant="primary" skinnyButton>
            <div className="d-flex justify-content-center">
              <div>{item.uiValue}</div>
              {item.requiredFilter ? null : (
                <div
                  className="pointer ml-2 no-outline"
                  onClick={onClick}
                  role="button"
                  tabIndex="0"
                  onKeyPress={onClick}
                >
                  <Icon icon="times" color="white" size="lg" />
                </div>
              )}
            </div>
          </Button>
        );
      })}
    </div>
  );
}

FilterItems.propTypes = {
  uiFilter: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default FilterItems;
