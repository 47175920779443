import React from 'react';
import PropTypes from 'prop-types';

import { Select, FloatGroup, Input, ColorPickerInput, FlexBinaryChoiceRow, FormCardSpaced } from 'modules/common';

const ANIMATION_STYLE_OPTIONS = [
  { label: 'Bounce', value: 'BOUNCE' },
  { label: 'Flash', value: 'FLASH' },
  { label: 'Pulse', value: 'PULSE' },
  { label: 'Rubber Band', value: 'RUBBERBAND' },
  { label: 'Shake X', value: 'SHAKEX' },
  { label: 'Shake Y', value: 'SHAKEY' },
  { label: 'Head Shake', value: 'HEADSHAKE' },
  { label: 'Swing', value: 'SWING' },
  { label: 'Tada', value: 'TADA' },
  { label: 'Wobble', value: 'WOBBLE' },
  { label: 'Jello', value: 'JELLO' },
  { label: 'Heart Beat', value: 'HEARTBEAT' },
];

const ANIMATION_DURATION_OPTIONS = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Fast (800ms)', value: 'FAST' },
  { label: 'Faster (500ms)', value: 'FASTER' },
  { label: 'Slow (2s)', value: 'SLOW' },
  { label: 'Slower (3s)', value: 'SLOWER' },
];

function WidgetAmountAnimation({ widget, updateWidget, donationAmounts, disabled = false }) {
  const animationStyleValue = ANIMATION_STYLE_OPTIONS.find(opt => opt.value === widget.amountButtonAnimationStyle);
  const animationDurationValue = ANIMATION_DURATION_OPTIONS.find(
    opt => opt.value === widget.amountButtonAnimationDuration
  );

  return (
    <div className="widget-button-animation">
      <FloatGroup>
        <Input
          type="text"
          placeholder="Amount Button Position"
          name="amountButtonAnimationPosition"
          value={widget.amountButtonAnimationPosition}
          onChange={updateWidget}
          numbersOnly
          disabled={disabled}
          max={donationAmounts.length}
          description="The position of the amount button that you would like animated on pageload. 1 corresponds to the first amount button, 2 corresponds to the second, etc."
          validators={['required']}
          errorMessages={['Amount Button Position is required.']}
        />
      </FloatGroup>

      <Select
        label="Animation Style"
        floatLabel="Animation Style"
        placeholder="Select Animation Style..."
        name="amountButtonAnimationStyle"
        onChange={updateWidget}
        disabled={disabled}
        value={animationStyleValue}
        options={ANIMATION_STYLE_OPTIONS}
        validators={['required']}
        errorMessages={['Animation Style is required.']}
      />

      <FormCardSpaced
        className="mb-3"
        classNameFooter="pb-1 widget-button-animation_footer"
        footer={
          widget.amountButtonAnimationRepeat ? (
            <FloatGroup>
              <Input
                type="text"
                disabled={disabled}
                placeholder="Pause Between Animations (Seconds)"
                name="amountButtonAnimationRepeatAfter"
                value={widget.amountButtonAnimationRepeatAfter}
                onChange={updateWidget}
                max={25}
                numbersOnly
                description="The amount of time between each animation cycle when it is looped (Max 25 seconds)."
              />
            </FloatGroup>
          ) : null
        }
      >
        <FlexBinaryChoiceRow
          name="amountButtonAnimationRepeat"
          title="Loop Animation"
          disabled={disabled}
          subTitle="Repeat the animation. Not shown on the preview button."
          value={widget.amountButtonAnimationRepeat}
          onChange={updateWidget}
        />
      </FormCardSpaced>

      <FloatGroup>
        <Input
          type="text"
          placeholder="Animation Delay (Seconds)"
          name="amountButtonAnimationDelay"
          value={widget.amountButtonAnimationDelay}
          onChange={updateWidget}
          disabled={disabled}
          max={5}
          numbersOnly
          description="The amount of time before the animation happens after the page is loaded (Max 5 seconds)."
        />
      </FloatGroup>

      <Select
        label="Animation Duration"
        floatLabel="Animation Duration"
        placeholder="Select Animation Duration..."
        name="amountButtonAnimationDuration"
        onChange={updateWidget}
        disabled={disabled}
        value={animationDurationValue}
        options={ANIMATION_DURATION_OPTIONS}
        validators={['required']}
        errorMessages={['Animation Speed is required.']}
        description="The speed of the animation for your button."
      />

      <ColorPickerInput
        value={widget.amountButtonAnimationColor}
        name="amountButtonAnimationColor"
        setColorAction={updateWidget}
        disabled={disabled}
        placeholder="Animated Button Color"
        label="Animated Button Color"
        description="Select a color for the animated button."
      />
    </div>
  );
}

WidgetAmountAnimation.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
  donationAmounts: PropTypes.array.isRequired,
};

export default WidgetAmountAnimation;
