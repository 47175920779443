import { convertEpoch, UI_DATE_TIME_FORMAT } from 'core/utilities';

export const COMMITTEE_CONNECT_ACTIVITY_TYPES = {
  VENDOR_BILL_PAY_DISCONNECTED: 'VENDOR_BILL_PAY_DISCONNECTED',
  SENT_BILL_PAY_CONNECTION: 'SENT_BILL_PAY_CONNECTION',
  APPROVED_BILL_PAY_CONNECTION: 'APPROVED_BILL_PAY_CONNECTION',
  VENDOR_DEFAULT_FEE_CHANGED: 'VENDOR_DEFAULT_FEE_CHANGED',
};

export const COMMITTEE_CONNECT_DRAWER_TYPES = {
  OPEN_ADD_COMMITTEE: 'OPEN_ADD_COMMITTEE',
  OPEN_APPROVE_COMMITTEE: 'OPEN_APPROVE_COMMITTEE',
  OPEN_DISCONNECT_COMMITTEE: 'OPEN_DISCONNECT_COMMITTEE',
  OPEN_EDIT_COMMITTEE: 'OPEN_EDIT_COMMITTEE',
};

export const getCommitteeConnectActivityText = activity => {
  switch (activity.activityType) {
    case COMMITTEE_CONNECT_ACTIVITY_TYPES.VENDOR_DEFAULT_FEE_CHANGED:
      return `${activity.email} updated Default Percentage Fee to ${activity.feeChangedTo}% from ${
        activity.feeChangedFrom
      }% on ${convertEpoch(activity.date, UI_DATE_TIME_FORMAT)}`;

    case COMMITTEE_CONNECT_ACTIVITY_TYPES.VENDOR_BILL_PAY_DISCONNECTED:
      return `${activity.sourceName} disconnected from ${activity.destinationName} on ${convertEpoch(
        activity.date,
        UI_DATE_TIME_FORMAT
      )} by ${activity.email}`;

    case COMMITTEE_CONNECT_ACTIVITY_TYPES.SENT_BILL_PAY_CONNECTION:
      return `${activity.sourceName} sent a Bill-Pay connection to ${activity.destinationName} on ${convertEpoch(
        activity.date,
        UI_DATE_TIME_FORMAT
      )} by ${activity.email}`;

    default:
      return `${activity.sourceName} approved a Bill-Pay connection to ${activity.destinationName} on ${convertEpoch(
        activity.date,
        UI_DATE_TIME_FORMAT
      )} by ${activity.email}`;
  }
};

export const COMMITTEE_CONNECTION_CARD_VARIANTS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  RECIEVED: 'RECIEVED',
  SENT: 'SENT',
};
