import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import { Button, Card, FormCard, Input, FloatGroup, useAmplitude, useToast, WinRedTextEditor } from 'modules/common';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';

import { emailValidator } from 'core/utilities/formatters';
import { EMAIL_FAILED } from 'modules/utilities/emails/emails.tools';
import { SEND_ORGANIZATION_TEST_EMAIL_MUTATION, SEND_TEST_EMAIL_MUTATION } from 'core/middleware/queries';

export default function AdditionalOptions(props) {
  const { campaign, onChange } = props;
  const [logEvent] = useAmplitude();

  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const sendToast = useToast();
  const [testEmails, setTestEmails] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [sendTestEmail] = useMutation(
    isOrganization ? SEND_ORGANIZATION_TEST_EMAIL_MUTATION : SEND_TEST_EMAIL_MUTATION
  );

  const onChangeTestEmails = ({ target: { value } }) => {
    const emails = value.split(',');
    // only allow 5 email addresses
    if (emails.length > 5) return;
    setTestEmails(value);
  };

  const handleSendTestEmail = async () => {
    let emailInvalid = false;

    // Evaluate each email and stop if one is invalid.
    testEmails.split(',').some(email => {
      if (!emailValidator.test(email.trim())) {
        emailInvalid = true;
        sendToast({ message: `${email} is not a valid email address!`, isError: true });
      }
      return emailInvalid;
    });

    // Exit if any emails are invalid.
    if (emailInvalid) return;

    setLoading(true);
    try {
      const variables = {
        organizationRevvUid: profileCandidate.organization_revv_uid,
        attributes: {
          revv_uid: campaign.revv_uid,
          email: testEmails,
          organizationRevvUid: profileCandidate.organization_revv_uid,
          type: 'initiative',
        },
      };

      // Send the test email
      const { data } = await sendTestEmail({ variables });
      setLoading(false);

      /* If the response returns successfully but the email failed to send, let
        the user know. */
      const responseEmail = data?.testEmail || data?.organizationTestEmail;
      if (data && EMAIL_FAILED.test(responseEmail.message)) {
        sendToast({ message: responseEmail.message, isError: true });
        return;
      }

      sendToast({ message: "We've sent you a test email." });
      logEvent('send test email');
    } catch (error) {
      sendToast({ message: `Failed to send test email: ${error}`, isError: true });
    }
    setLoading(false);
  };

  return (
    <>
      <Card.Title
        classNameBody="p-0 border-0"
        subtitle="Override the email body on any emails sent by charges associated with this campaign. If no text is entered, then the default email receipt will be sent."
      >
        Email Body Override
      </Card.Title>
      <FloatGroup>
        <WinRedTextEditor
          initialValue={campaign.email_body_text}
          value={campaign.email_body_text}
          name="email_body_text"
          onChange={onChange}
        />
      </FloatGroup>
      {/* Only show the email preview if the campaign has been saved. */}
      {!campaign._isNew && (
        <FormCard
          className="mb-3"
          classNameBody="pb-3"
          title="Email Body Override Preview"
          subtitle="Test out your email using the tool below. Make sure you save your changes before sending a test email. Enter a comma separated list of email addresses to send a test of this email."
        >
          <div className="d-flex flex-row align-items-center">
            <Input
              classNameWrapper="w-100"
              name="testEmails"
              placeholder="Emails"
              value={testEmails}
              onChange={onChangeTestEmails}
            />
            <Button
              variant="primary"
              size="lg"
              onClick={handleSendTestEmail}
              className="ml-3 text-white text-nowrap"
              disabled={isLoading || testEmails.length === 0}
            >
              Send Test Email
            </Button>
          </div>
        </FormCard>
      )}
    </>
  );
}
AdditionalOptions.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
