import { amountBuilder, dateAndSearchBuilder, LIST_PAGE_SIZE } from 'modules/common';

export default function queryBuilder(filter) {
  let variables = {
    page: filter.page || 1,
    limit: LIST_PAGE_SIZE,
  };

  variables = amountBuilder(variables, filter);

  variables = dateAndSearchBuilder({
    variables,
    search: filter.search,
    dateRangeStart: filter.dateRangeStart,
    dateRangeEnd: filter.dateRangeEnd,
  });

  if (filter.status && filter.status !== 'all') {
    variables.status = filter.status.toUpperCase();
  }

  return variables;
}
