import React from 'react';
import PropTypes from 'prop-types';
import { Input, FloatGroup } from 'modules/common';
import CustomFieldRequired from './required.component';

function CustomFieldToggleText({ updateCustomField, formState, isNumberField, disabled }) {
  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="label"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Field Label"
          value={formState.label}
          maxLength="255"
          disabled={disabled}
          onChange={updateCustomField}
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          name="placeholder"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Field Placeholder"
          value={formState.placeholder}
          maxLength="255"
          disabled={disabled}
          onChange={updateCustomField}
          validators={['required']}
          errorMessages={['Field Placeholder is required.']}
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          name="default_value"
          placeholder="Default Value"
          value={formState.default_value}
          maxLength="255"
          disabled={disabled}
          numbersOnly={isNumberField}
          onChange={updateCustomField}
        />
      </FloatGroup>

      <CustomFieldRequired onChange={updateCustomField} value={formState.required} disabled={disabled} />
    </>
  );
}

CustomFieldToggleText.propTypes = {
  updateCustomField: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  isNumberField: PropTypes.bool,
};

CustomFieldToggleText.defaultProps = {
  isNumberField: false,
};

export default CustomFieldToggleText;
