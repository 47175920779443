import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './chip.scss';

const COLOR_MAP = {
  red: 'winred-chip__red',
  green: 'winred-chip__green',
  purple: 'border-purple-dark text-purple-dark bg-purple-light',
  yellow: 'winred-chip__yellow',
  default: 'bg--catskill-white border-lynx c--lynx',
};

function WinRedChip({ variant = '', className = '', children, onClick }) {
  const chipColors = COLOR_MAP[variant] || COLOR_MAP['default'];
  return (
    <div
      onClick={onClick}
      onKeyPress={onClick}
      role={onClick ? 'button' : null}
      tabIndex={onClick ? '0' : null}
      className={classnames(
        'winred-chip text-sm font-medium rounded-3xl tracking-normal font-roboto',
        className,
        chipColors
      )}
    >
      {children}
    </div>
  );
}

WinRedChip.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default WinRedChip;
