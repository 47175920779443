import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuAccounting({
  pathname,
  handleSelect,
  organizationRevvUid,
  removeAccess,
  showAccountingSubNav,
  isAgency,
}) {
  if (removeAccess.includes(true)) return null;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for accounting menu"
      showSubmenu={showAccountingSubNav}
    >
      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/accounting/ledger`}
          href={`/${organizationRevvUid}/accounting/ledger`}
        >
          Ledger
        </NavList.Item>
      ) : null}
      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/accounting/payouts`}
          href={`/${organizationRevvUid}/accounting/payouts`}
        >
          Payouts
        </NavList.Item>
      ) : null}

      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/accounting/payouts/report`}
          href={`/${organizationRevvUid}/accounting/payouts/report`}
        >
          Payout Report
        </NavList.Item>
      ) : null}
      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/accounting/notifications/payouts`}
          href={`/${organizationRevvUid}/accounting/notifications/payouts`}
        >
          Payout Notification
        </NavList.Item>
      ) : null}
      {!isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/accounting/blocked`}
          href={`/${organizationRevvUid}/accounting/blocked`}
        >
          Blocked Donors
        </NavList.Item>
      ) : null}
      {isAgency ? (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/vendors/accounting/transfers`}
          href={`/${organizationRevvUid}/vendors/accounting/transfers`}
        >
          Transfers
        </NavList.Item>
      ) : null}
    </SubMenuContainer>
  );
}

NavBarSubMenuAccounting.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showAccountingSubNav: PropTypes.bool.isRequired,
};

export default NavBarSubMenuAccounting;
