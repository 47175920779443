import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, WinRedFormInput } from 'modules/common';

const nameValidator = [{ validator: 'required', message: 'Name is required.' }];
const csvValidator = [
  {
    validator: value => {
      const hasSpaces = new RegExp(/\s/);

      return !hasSpaces.test(value);
    },
    message: 'CSV Filename cannot have spaces',
  },
];

function ReportDetails({ report, onChange }) {
  return (
    <FormCardSpaced>
      <WinRedFormInput
        description="A descriptive name for your report."
        placeholder="Report Name"
        name="name"
        validators={nameValidator}
        value={report.name || ''}
        onChange={onChange}
      />
      <WinRedFormInput
        doNotWrap={true}
        append=".csv"
        description="Set a descriptive name for your file. You may use dynamic variables for Year %y, Month %m, or Day %d in the file name."
        placeholder="CSV File Name"
        name="custom_filename"
        validators={csvValidator}
        value={report.custom_filename}
        onChange={onChange}
      />
      <WinRedFormInput
        as="textarea"
        description="A brief description of what this report is for and why it was created."
        placeholder="Report Notes (Recommended)"
        name="note"
        value={report.note || ''}
        onChange={onChange}
      />
    </FormCardSpaced>
  );
}

ReportDetails.propTypes = {
  isNew: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportDetails };
