import React from 'react';
import PropTypes from 'prop-types';
import { FlexBinaryChoiceRow, FormCard } from 'modules/common';

function Geographical({ editedUpsell, onUpdateEditedUpsell }) {
  return (
    <FormCard>
      <FlexBinaryChoiceRow
        name="geographical_sort"
        title="Geographical Sort"
        subTitle="When enabled, the page will move the candidates located near the donor to the top of the page."
        value={editedUpsell.geographical_sort}
        onChange={onUpdateEditedUpsell}
        disabled={editedUpsell._isDisabled}
      />
    </FormCard>
  );
}

Geographical.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default Geographical;
