import React from 'react';
import PropTypes from 'prop-types';
import { VideoEmbed } from 'modules/common';

export default function UpsellVideoEmbed({ editedUpsell, onUpdateEditedUpsell, className }) {
  return (
    <VideoEmbed
      disabled={editedUpsell._isDisabled}
      className={className}
      onChange={onUpdateEditedUpsell}
      value={editedUpsell.video_embed}
      name="video_embed"
    />
  );
}

UpsellVideoEmbed.propTypes = {
  className: PropTypes.string,
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};