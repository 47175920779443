import gql from 'graphql-tag';

export const GET_NOTIFICATIONS_QUERY = gql`
  query getFederalEmailNotifications($organizationRevvUid: String, $filters: EmailNotificationFilter) {
    viewer {
      email
      candidate(revvUid: $organizationRevvUid) {
        email_notification_integrations(filters: $filters) {
          revvUid
          active
          recipients
          source
          note
          emailNotificationEvents {
            eventType
            enabled
          }
          donationFields {
            id
            attributeName
            predicate
            value
            name
          }
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_QUERY = gql`
  query getFederalEmailNotification($revvUid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns {
          results {
            revv_uid
            name
          }
        }
        email_notification_integrations(filters: { revvUid: $revvUid }) {
          revvUid
          active
          recipients
          emailNotificationType
          source
          note
          emailNotificationEvents {
            eventType
            enabled
          }
          donationFields {
            id
            attributeName
            predicate
            value
          }
        }
      }
    }
  }
`;

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation candidateEmailNotification($integration: EmailNotificationIntegrationInput!, $organizationRevvUid: String) {
    candidateEmailNotificationIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      emailNotificationIntegration {
        revvUid
        active
        recipients
        source
        note
        emailNotificationEvents {
          eventType
          enabled
        }
        donationFields {
          id
          name
          attributeName
          predicate
          value
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation candidateEmailNotification($integration: EmailNotificationIntegrationInput!, $organizationRevvUid: String) {
    candidateEmailNotificationIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      emailNotificationIntegration {
        revvUid
        active
        recipients
        source
        note
        emailNotificationEvents {
          eventType
          enabled
        }
        donationFields {
          id
          attributeName
          predicate
          value
          name
        }
      }
    }
  }
`;

export const UPDATE_CANIDATE_ORGANIZATION_MUTATION = gql`
  mutation candidateOrganizationEmailNotification(
    $integration: EmailNotificationIntegrationInput!
    $organizationRevvUid: String
  ) {
    candidateEmailNotificationIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      emailNotificationIntegration {
        revvUid
        active
        recipients
        source
        note
        emailNotificationEvents {
          eventType
          enabled
        }
        donationFields {
          id
          attributeName
          predicate
          value
          name
        }
      }
    }
  }
`;

export const GET_NEW_NOTIFICATION_CHARGE_QUERY = gql`
  query getNewChargeNotification($organizationRevvUid: String) {
    viewer {
      email
      candidate(revvUid: $organizationRevvUid) {
        campaigns {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;

export const GET_PAYOUT_NOTIFICATION_QUERY = gql`
  query getPayoutNotification($organizationRevvUid: String) {
    viewer {
      email
      candidate(revvUid: $organizationRevvUid) {
        payout_report_notification {
          recipients
        }
      }
    }
  }
`;

export const NOTIFICATION_PAYOUT_MUTATION = gql`
  mutation candidatePayoutReportNotification(
    $payoutReportNotification: PayoutReportNotificationInput!
    $organizationRevvUid: String
  ) {
    candidatePayoutReportNotification(
      payoutReportNotification: $payoutReportNotification
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      dammed
      payoutReportNotification {
        recipients
      }
    }
  }
`;

export const DELETE_PAYOUT_NOTIFICATION_MUTATION = gql`
  mutation candidateArchivePayoutReportNotification($organizationRevvUid: String) {
    candidateArchivePayoutReportNotification(organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;

export const DELETE_NOTIFICATION_MUTATION = gql`
  mutation DeleteNotificationMutation($integration: DeleteIntegrationInput!, $organizationRevvUid: String) {
    candidateDeleteIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;
