import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { Icon, Button, Typography, useClipBoard, Image, ButtonLink } from 'modules/common';
import WinRedModal from 'modules/common/modals/winred-modal.component';
import './qr-code-modal.scss';

export function QRCodeModal({ handleClose, show, slug, url, storefrontUrl }) {
  const copyToClipBoard = useClipBoard();
  // const setToast = useToast();
  // const [fetching, setFetching] = useState(false);

  let _url = storefrontUrl ? new URL(storefrontUrl) : null;
  if (_url) {
    _url = _url.pathname.replace(/^\//g, '');
  }

  // const download = (url, name) => {
  //   if (!url) return;

  //   setFetching(true);
  //   fetch(url)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       setFetching(false);
  //       const blobURL = URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = blobURL;
  //       a.style = 'display: none';

  //       if (name && name.length) a.download = name;
  //       document.body.appendChild(a);
  //       a.click();
  //     })
  //     .catch(error => {
  //       setToast({ error: true, message: error });
  //     });
  // };

  const onCopyUrl = () => {
    handleClose();
    copyToClipBoard({
      text: url,
      toast: 'Copied QR Code Image URL to clipboard.',
      logEventParams: { name: 'copy page qr code url', slug },
    });
  };

  return (
    <WinRedModal show={show} onHide={handleClose} contentClassName="qr-code-modal">
      <Modal.Header closeButton>
        <div></div>
        <div className="d-flex align-items-center">
          <Icon icon="qrcode" size="2x" />
          <Typography variant="h2" className="mb-0 pl-2">
            QR Code
          </Typography>
        </div>
      </Modal.Header>

      <Modal.Body>
        {_url && <Typography className="mt-2 mb-0">{_url}</Typography>}
        <Image src={url} />
        {slug && (
          <div className="slug-container">
            <Typography className="mb-1">
              URL Slug: <span className="font-weight-bold">{slug}</span>
            </Typography>
            <Typography className="mb-0" italic>
              If you update the URL Slug then this QR code will no longer work. A new QR Code will be generated for you.
            </Typography>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onCopyUrl}>Copy Image URL</Button>
        {/* <Button disabled={fetching} onClick={() => download(url, `${slug}-qrcode.png`)}>
          Download PNG
        </Button> */}

        <ButtonLink url={url} download={`${slug}-qrcode.png`} className="mr-2">
          Download PNG
        </ButtonLink>
      </Modal.Footer>
    </WinRedModal>
  );
}
