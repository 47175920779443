import { formatDateUTC, getFormattedDate } from 'core/utilities';

export const formatThermometerForSever = (data, profileCandidate) => {
  const { internal_name, thermometer_type, query_date, goals, boost, data_source } = data;
  const { isOrganization, organization_revv_uid } = profileCandidate;
  const variables = {
    organizationRevvUid: organization_revv_uid,
    campaign: {
      initiativeType: thermometer_type,
      name: internal_name,
      thermometerFields: {
        boostGoal: boost ? parseInt(boost) : 0,
        queryDate: getFormattedDate(query_date, 'dd-MM-yyyy'),
        goals: goals.map((goal, idx) => {
          let tempGoal = { ...goal };
          tempGoal.amount = tempGoal.amount_cents ? parseInt(tempGoal.amount_cents, 10) : null;
          delete tempGoal.amount_cents;
          delete tempGoal.__typename;
          delete tempGoal._isNew;
          if (goal._isNew) tempGoal.position = idx + 1;
          return tempGoal;
        }),
        dataSource: data_source,
      },
    },
  };
  if (data.isEdit) variables.campaign.revv_uid = data.revv_uid;
  if (isOrganization) variables.campaign.organizationRevvUid = organization_revv_uid;
  return variables;
};

export const formatThermometerForUi = (newData, oldData = {}) => {
  const {
    initiative_type,
    name,
    statistics,
    revv_uid,
    thermometer_fields: { boost_goal, goals, query_date, page_count, data_source },
  } = newData;
  let widget = {
    revv_uid,
    internal_name: name,
    thermometer_type: initiative_type,
    query_date: query_date ? Date.parse(formatDateUTC(parseInt(query_date * 1000))) : '',
    goals,
    boost: boost_goal,
    page_count: page_count ? page_count : 0,
    statistics,
    isEdit: true,
    data_source: data_source,
  };
  if (Object.keys(oldData).length !== 0) {
    widget = {
      ...widget,
      id: oldData.id,
      isPetitionPage: oldData.isPetitionPage,
      type: oldData.type,
      _destroy: oldData._destroy,
      _disabled: oldData._disabled,
      _name: oldData._name,
      _isCardOpen: oldData._isCardOpen,
    };
  }

  return widget;
};

export const generateThermometerOptions = data => {
  return data.map(item => {
    return {
      label: item.name,
      value: item,
    };
  });
};
