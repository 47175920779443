import gql from 'graphql-tag';

export const CREATE_INTEGRATION_ISP = gql`
  mutation ISPIntegrationMutation($integration: ISPIntegrationInput!, $organizationRevvUid: String) {
    candidateIspIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      ispIntegration {
        revv_uid
      }
    }
  }
`;

export const CREATE_ORGANIZATION_INTEGRATION_ISP = gql`
  mutation ISPIntegrationOrganizationMutation($integration: ISPIntegrationInput!) {
    organizationIspIntegration(integration: $integration) {
      errors
      ispIntegration {
        revv_uid
      }
    }
  }
`;

export const CREATE_VENDOR_INTEGRATION_ISP = gql`
  mutation ISPIntegrationVendorMutation($integration: ISPIntegrationInput!) {
    organizationIspIntegration(integration: $integration) {
      errors
      ispIntegration {
        revv_uid
      }
    }
  }
`;
