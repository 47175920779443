import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ListGroup from 'react-bootstrap/ListGroup';
import { Typography } from 'modules/common';
import '../../common.scss';

function InformationListItem({ label, value, className, classNameLabel, classNameContainer, variant }) {
  return (
    <ListGroup.Item className={classNameContainer}>
      <Typography color="nepal" className={classnames('m-0 info-list-label', classNameLabel)}>
        {label}
      </Typography>
      <Typography variant={variant} className={classnames('m-0 info-list-value', className)} color="mirage">
        {value}
      </Typography>
    </ListGroup.Item>
  );
}

InformationListItem.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  value: PropTypes.any.isRequired,
  className: PropTypes.any,
  classNameLabel: PropTypes.any,
  classNameContainer: PropTypes.any,
};

InformationListItem.defaultProps = {
  className: '',
  variant: 'bodyMedium',
  classNameContainer: '',
};

export default InformationListItem;
