import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Typography, FormCard, Select, Card, Icon } from 'modules/common';

const onlyOneCardAllowedTypes = ['DOUBLE', 'RECURRING', 'PASSWORD', 'MOBILEOPTIN'];

function UpsellTypeSelector({ updateUpsellCard, upsellCard, dropdownOptions, upsellCards, removeUpsellCard, isOrganization }) {
  // internal state to make select dropdown update - dont need this value outside this component
  const [selected, setSelected] = useState(null);

  const _updateUpsellCard = ({ target: { value } }) => {
    setSelected(value);
    updateUpsellCard({ target: { name: 'type', value } }, upsellCard);
  };

  const removeCollapsibleCard = event => {
    event.preventDefault();
    event.stopPropagation();
    removeUpsellCard(upsellCard);
  };

  // Don't allow multiples of certain card types
  const cardTypesExisting = upsellCards.map(card => card.type && !card?._destroy);
  const cardTypesWeDontWant = onlyOneCardAllowedTypes.filter(x => cardTypesExisting.includes(x));
  // [ch18394] Slate upsells are not currently supported on state level organizations.
  if(isOrganization) cardTypesWeDontWant.push('CONDUIT')
  const validOptions = dropdownOptions.filter(opt => {
    return !cardTypesWeDontWant.includes(opt.value);
  });

  return (
    <FormCard className='mt-3'>
      <Container fluid>
        <Row>
          <Col xs={12} md={5}>
            <Card.Title>Upsell Type</Card.Title>
            <Typography className="mb-3">Select what type of upsell this card will be.</Typography>
          </Col>

          <Col xs={12} md={7}>
            <Row className="mb-2">
              <Col xs={11}>
                <Select
                  placeholder="Select Upsell Type"
                  name="type"
                  onChange={_updateUpsellCard}
                  value={selected}
                  errorMessageClasses="position-absolute"
                  options={validOptions}
                  validators={['required']}
                  errorMessages={['Selecting an upsell type is required.']}
                />
              </Col>
              <Col xs="auto" className="d-flex align-items-center pl-0">
                <div
                  className="mb-3 pointer no-outline"
                  onClick={removeCollapsibleCard}
                  onKeyPress={removeCollapsibleCard}
                  role="button"
                  tabIndex={0}
                >
                  <Icon icon="times" color="gray" size="lg" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FormCard>
  );
}

UpsellTypeSelector.propTypes = {
  updateUpsellCard: PropTypes.func.isRequired,
  removeUpsellCard: PropTypes.func.isRequired,
  upsellCard: PropTypes.object.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  upsellCards: PropTypes.array.isRequired,
};

export default UpsellTypeSelector;
