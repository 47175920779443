import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { withApollo } from '@apollo/client/react/hoc';
import 'whatwg-fetch';
import honeybadger from 'honeybadger-js';

import { setTitle, withAmplitude } from 'modules/common';

import { setToast } from 'core/toast';
import {
  setLoginStatus,
  setProfile,
  setProfileCandidate,
  LOGIN_STATUSES,
  loginTokenSelector,
  profileCandidateSelector,
  getProfileVariables,
} from 'core/login';
import { GET_USER_CANDIDATES } from 'core/middleware/queries';
import uuid from 'core/utilities/uuid';
import { FeaturesContext } from 'featureProvider';
import { getInitialCandidate, deleteLoggedInCandidate } from 'core/login/login-reducer.tools';

class LoggingInContainer extends Component {
  static contextType = FeaturesContext;

  async componentDidMount() {
    // if we dont even have the token in localstorage then not logged in go to login page
    let { token } = this.props;
    if (!token) return this.setNotLoggedIn();

    // we need to see what type of user they are from the number of candidates/orgs user has
    try {
      const userTypeResponse = await this.props.client.query({
        query: GET_USER_CANDIDATES,
        variables: { profile_uid: 'profile_1' },
      });

      const profile = getInitialCandidate({
        apiProfile: userTypeResponse.data.viewer,
      });

      this.props.setProfile({ profile });

      // if donor user then we are done go ahead and login
      if (!profile.candidate || profile.candidate.isDonor) {
        this.props.setLoginStatus({ status: LOGIN_STATUSES.LOGGED_IN, token });
        return;
      }

      // this is not a donor user they have some type of org
      const profileResponse = await this.props.client.query(getProfileVariables(profile.candidate));

      this.props.setProfileCandidate(profileResponse.data.viewer);
      this.props.setLoginStatus({ status: LOGIN_STATUSES.LOGGED_IN, token });
    } catch (error) {
      this.props.setToast({
        message: 'There was an error retrieving your profile. Check your connection and try again.',
        isError: true,
      });

      this.honeyBadgerError(error);
      return this.setNotLoggedIn();
    }
  }

  honeyBadgerError = error => {
    const context = {
      badgerId: uuid(),
      version: window.appVersion,
      url: window.location.href,
      originComponent: 'logging-in.container',
    };

    console.error(context);
    console.error(error);
    honeybadger.notify(error, { context });

    this.props.logEvent('500 page', {
      'honeybadger id': context.badgerId,
    });
  };

  /**
   * sets store to not logged in and redirects to login screen
   */
  setNotLoggedIn = () => {
    let { setLoginStatus, history } = this.props;
    deleteLoggedInCandidate();
    setLoginStatus({ status: LOGIN_STATUSES.NOT_LOGGED_IN, token: null });
    history.push('/login');
  };

  render() {
    return setTitle('Loading');
  }
}

LoggingInContainer.propTypes = {
  client: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  setLoginStatus: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  token: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    token: loginTokenSelector(state),
    profileCandidate: profileCandidateSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setProfile: data => dispatch(setProfile(data)),
    setProfileCandidate: data => dispatch(setProfileCandidate(data)),
    setLoginStatus: (status, token) => dispatch(setLoginStatus(status, token)),
  };
};

export default compose(
  withRouter,
  withApollo,
  withAmplitude,
  connect(mapStateToProps, mapDispatchToProps)
)(LoggingInContainer);
