import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import Item from './nav-list/item.component';
import NavDropdown from './nav-list/nav-dropdown.component';
import Group from './nav-list/group.component';

function NavList({ onSelect, children, activeKey, ariaLabel = '', className }) {
  if (!Array.isArray(children)) children = [children];

  return (
    <div aria-label={ariaLabel} className={classNames('nav scroll-x flex-nowrap', className)} onSelect={onSelect}>
      {children.map(
        child => child && cloneElement(child, { activeKey, onSelect, key: child.key || child.props.eventKey })
      )}
    </div>
  );
}

NavList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  activeKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
};

NavList.Item = Item;
NavList.Dropdown = NavDropdown;
NavList.DropdownItem = Dropdown.Item;
NavList.Group = Group;

export default NavList;
