import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import { Card, Icon, Typography } from 'modules/common';

function FileSelector({
  onFiles,
  children,
  maxFileSize,
  accept = 'image/jpeg, image/jpg, image/png, image/gif',
  variant = 'upload',
}) {
  const fileSize = maxFileSize >= 1024 * 1024 ? `${maxFileSize / 1024 / 1024}MB` : `${maxFileSize / 1024}KB`;

  return (
    <Dropzone onDrop={onFiles} accept={accept} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="pointer">
          <Card variant={variant}>
            <Card.Body className="d-flex flex-column align-items-center">
              <input {...getInputProps()} />

              {children ? (
                children
              ) : (
                <>
                  <Icon icon="cloud-upload" size="3x" />
                  <Typography noGutter>Drag and drop or browse to upload an image.</Typography>
                  <Typography noGutter className="tiny">
                    JPEG, PNG, or GIF. Maximum size is {fileSize}.
                  </Typography>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </Dropzone>
  );
}

FileSelector.propTypes = {
  onFiles: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default FileSelector;
