import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { useSelector } from 'react-redux';

import { QRCodeModal } from 'modules/common/qr-code';

import { profileCandidateSelector } from 'core/login';
import { Pagination, useClipBoard, useExpandedRows, ConfirmModal, Typography } from 'modules/common';

import PagesTableDesktop from './list-body/desktop.component';
import PagesTableMobile from './list-body/mobile.component';
import { EmptyPageList } from './empty-page-list.component';

import {
  EXPORT_PETITION_PAGE_LEADS,
  EXPORT_ORGANIZATION_PETITION_PAGE_LEADS,
  EXPORT_PAGE_DONATIONS,
  EXPORT_ORGANIZATION_PAGE_DONATIONS,
} from '../../../core/middleware/queries';
import { useMutation } from '@apollo/client';
import { useToast } from '../../common';
import { BulkPageDrawer } from './bulk-page-drawer/bulk-page-drawer.component';
import { isEmptyList } from 'modules/common/lists/pagination.tools';

function PagesListBody({
  candidate,
  onChangeFilter,
  handleCreateNewPage,
  handleDuplicatePage,
  filter,
  clearFilter,
  refetch,
}) {
  const history = useHistory();
  const match = useRouteMatch();

  const profileCandidate = useSelector(profileCandidateSelector);
  const copyToClipBoard = useClipBoard();
  const setToast = useToast();

  const { pages } = candidate || {};
  const { current_page, page_count, total_count, results } = pages || {};
  const [bulkPageDrawer, setBulkPageDrawer] = useState({
    selectedPage: null,
    display: false,
  });
  const [expandedRows, expandItem] = useExpandedRows();
  const [showDuplicateModal, setShowDuplicateModal] = useState({
    modal: false,
    page: null,
  });

  const [exportLeads] = useMutation(
    profileCandidate.isOrganization ? EXPORT_ORGANIZATION_PETITION_PAGE_LEADS : EXPORT_PETITION_PAGE_LEADS
  );
  const [exportDonations] = useMutation(
    profileCandidate.isOrganization ? EXPORT_ORGANIZATION_PAGE_DONATIONS : EXPORT_PAGE_DONATIONS
  );

  const navigateToItem = uuid => {
    history.push(`${match.url}/${uuid}`);
  };

  const [qrPage, setQrPage] = useState(null);

  const closeDuplicateModal = () => {
    setShowDuplicateModal({
      modal: false,
      page: null,
    });
  };

  const toggleBulkPageDrawer = (selectedPage = null) => {
    if (bulkPageDrawer.display) {
      refetch();
    }
    setBulkPageDrawer({ selectedPage, display: !bulkPageDrawer.display });
  };
  const handleExportLeads = async (organizationRevvUid, pageUid) => {
    const variables = {
      organizationRevvUid: organizationRevvUid,
      page: {
        revvUid: pageUid,
        organizationRevvUid: organizationRevvUid,
      },
    };
    try {
      const { data } = await exportLeads({ variables });
      let { errors: error } = data.candidateExportPetitionPageLeads || data.organizationExportPetitionPageLeads;
      if (error && error.length > 0) {
        setToast({ message: error, isError: true });
        return;
      }
      setToast({ message: 'Your report is being generated. We will send you an email when it is ready.' });
    } catch (error) {
      setToast({ message: error, isError: true });
    }
  };
  const handleExportDonations = async (organizationRevvUid, pageUid) => {
    const variables = {
      organizationRevvUid: organizationRevvUid,
      page: {
        revvUid: pageUid,
        organizationRevvUid: organizationRevvUid,
      },
    };
    try {
      const { data } = await exportDonations({ variables });
      let { errors: error, message } = data.candidateExportPageDonations || data.organizationExportPageDonations;
      if (error && error.length > 0) {
        setToast({ message: error, isError: true });
        return;
      }
      setToast({ message });
    } catch (error) {
      setToast({ message: error, isError: true });
    }
  };

  const dropdownAction = (eventKey, page) => {
    switch (eventKey) {
      case 'copy':
        copyToClipBoard({
          text: page.url,
          toast: 'Page URL copied to clipboard.',
          logEventParams: {
            name: 'copy url',
            parameters: {
              'page id': page.revv_uid,
              status: page.status,
            },
          },
        });
        break;

      case 'duplicate-bill-pay':
        setShowDuplicateModal({
          modal: true,
          page,
        });
        break;

      case 'duplicate':
        handleDuplicatePage(page.revv_uid);
        break;
      case 'bulk-page':
        toggleBulkPageDrawer(page);
        break;

      case 'qrCode':
        setQrPage(page);
        break;

      case 'exportLeads':
        handleExportLeads(profileCandidate.organization_revv_uid, page.revv_uid);
        break;
      case 'exportDonations':
        handleExportDonations(profileCandidate.organization_revv_uid, page.revv_uid);
        break;

      default:
        break;
    }
  };

  if (isEmptyList(results, filter)) {
    return (
      <>
        <EmptyPageList activeTabKey={filter.activeTabKey} handleCreateNewPage={handleCreateNewPage} />;
      </>
    );
  }

  return (
    <div className="mt-3">
      <Breakpoint small down>
        <PagesTableMobile
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
          expandItem={expandItem}
          expandedRows={expandedRows}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <PagesTableDesktop
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
          expandItem={expandItem}
          expandedRows={expandedRows}
        />
      </Breakpoint>

      <QRCodeModal show={!!qrPage} handleClose={() => setQrPage(null)} url={qrPage?.qrCodeUrl} slug={qrPage?.slug} />
      <BulkPageDrawer
        open={bulkPageDrawer?.display}
        onClose={toggleBulkPageDrawer}
        page={bulkPageDrawer?.selectedPage}
        profileCandidate={profileCandidate}
        isOrganization={profileCandidate.isOrganization}
      />
      <Pagination
        currentPage={current_page}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        filter={filter}
        results={results}
        clearFilter={clearFilter}
      />

      <ConfirmModal
        show={showDuplicateModal.modal}
        buttonText="Duplicate Page"
        onSubmit={() => {
          handleDuplicatePage(showDuplicateModal.page.revv_uid);
          closeDuplicateModal();
        }}
        showCancelButton={true}
        cancelButtonText="Cancel"
        onCancel={closeDuplicateModal}
        handleClose={closeDuplicateModal}
      >
        <Typography variant="h4" className="mt-5 mb-3 mb-1 text-center">
          Are you sure you want to duplicate this page?
        </Typography>
        <p className="text-center mb-4">
          The page you are trying to duplicate has <span className="c--winred-red">Bill-Pay fees attached</span> to one
          or more candidates.
        </p>
      </ConfirmModal>
    </div>
  );
}

PagesListBody.propTypes = {
  candidate: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  handleCreateNewPage: PropTypes.func.isRequired,
  handleDuplicatePage: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PagesListBody;
