import gql from 'graphql-tag';
import { reportDefinition, reportDefinitionList } from './reports.queries';

export const GET_VENDOR_CUSTOM_REPORTS_QUERY = gql`
  query getVendorReportDefinitions(
    $agencyRevvUid: String
    $revv_uid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $report_type: String
  ) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        report_definitions(
          filters: {
            revvUid: $revv_uid
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            reportType: $report_type
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...ReportDefinitionList
          }
        }
      }
    }
  }
  ${reportDefinitionList}
`;

export const GET_VENDOR_CUSTOM_REPORT_QUERY = gql`
  query getVendorCustomReports($revv_uid: String!, $organizationRevvUid: String!) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        report_definitions(filters: { revvUid: $revv_uid }) {
          results {
            ...ReportDefinition
          }
        }
      }
    }
  }
  ${reportDefinition}
`;

export const GET_VENDOR_REPORT_CONDITIONS = gql`
  query getVendorReportDefinitionConditions($agencyRevvUid: String, $reportType: String, $page: Int, $limit: Int) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        report_definition_conditions(filters: { reportType: $reportType, page: $page, limit: $limit }) {
          report_key
          attributes {
            value
            name
            value_options {
              name
              value
            }
            predicates {
              name
              value
            }
            data_type
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_REPORT_COLUMNS = gql`
  query getVendorReportColumns(
    $reportType: String
    $columnOptions: ColumsOptionsEnum
    $page: Int
    $limit: Int
    $agencyRevvUid: String
  ) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        report_definition_columns(
          filters: { reportType: $reportType, columnOptions: $columnOptions, page: $page, limit: $limit }
        ) {
          name
          value
        }
      }
    }
  }
`;

export const UPDATE_VENDOR_REPORT_MUTATION = gql`
  mutation updateVendorReportDefinitionMutation($report: UpdateReportDefinitionInput!) {
    vendorUpdateReportDefinition(reportDefinition: $report) {
      errors
      reportDefinition {
        ...ReportDefinition
      }
    }
  }
  ${reportDefinition}
`;

export const RUN_VENDOR_REPORT_MUTATION = gql`
  mutation vendorRunReportDefinition($attributes: ReportDefinitionRunInput!) {
    vendorRunReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const CANCEL_VENDOR_REPORT_MUTATION = gql`
  mutation vendorCancelReportDefinition($attributes: ReportDefinitionRunInput!) {
    vendorCancelReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const DELETE_VENDOR_REPORT_MUTATION = gql`
  mutation vendorDeleteReportDefinition($attributes: ReportDefinitionRunInput!) {
    vendorDeleteReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const CREATE_VENDOR_REPORT_MUTATION = gql`
  mutation createVendorReportDefinitionMutation($reportDefinition: CreateReportDefinitionInput!) {
    vendorCreateReportDefinition(reportDefinition: $reportDefinition) {
      reportDefinition {
        revv_uid
      }
      errors
    }
  }
`;

export const GET_VENDOR_REPORT_INTEGRATIONS = gql`
  query getIntegrations(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $active: Boolean
    $type: IntegrationTypeEnum
    $source: [IntegrationSourceTypeEnum!]!
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        active_integration_types
        integrations(
          filters: { page: $page, limit: $limit, revvUid: $revvUid, active: $active, type: $type, source: $source }
        ) {
          current_page
          page_count
          total_count
          results {
            id
            active
            type
            s3Fields {
              bucket
            }
            ftpFields {
              ftpAddress
              ftpPath
            }
          }
        }
      }
    }
  }
`;
