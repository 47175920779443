import React from 'react';
import PropTypes from 'prop-types';
import Typography from './typography.component';

const LogicTitle = ({ title, subTitle, conditional }) => {
  return (
    <div className="flex items-center">
      <div className="py-1 px-2 mr-3 bg-winred-blue rounded-sm text-white text-sm">{conditional}</div>
      <div>
        <Typography variant="h4" color="mirage" className="mb-0 font-medium">
          {title}
        </Typography>
        {subTitle ? <Typography className="mb-0">{subTitle}</Typography> : null}
      </div>
    </div>
  );
};

LogicTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  conditional: PropTypes.string.isRequired,
};

export default LogicTitle;
