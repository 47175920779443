import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Card, Typography, Icon, ButtonLink, WinRedChip } from 'modules/common';
import { convertEpochTime, formatNumberWithCommas, noEvent } from 'core/utilities';
import '../../upsell.scss';

function UpsellItemDesktop({ upsell, onEditExistingUpsell, onUpsellItemRemove, disabledUpsellFlow }) {
  const { name, upsell_statistics = {}, upsell_preview_url } = upsell.upsell_page;

  return (
    <Card onClick={onEditExistingUpsell} className="pointer d-none d-md-block">
      <Card.Body>
        <Row>
          {disabledUpsellFlow ? null : (
            <Col xs="auto" className="cursor-grab pl-2 pr-0 d-flex align-items-center">
              <Icon icon="bars" color="gray" size="lg" />
            </Col>
          )}
          <Col className="d-flex align-items-center flex-wrap">
            <div className="mr-2">
              <Typography className="mb-0" variant="h5">
                {name}
              </Typography>
              <Typography className="mb-0">{convertEpochTime(upsell.created_at)}</Typography>
            </div>

            {upsell.upsell_page?.public_upsell ? (
              <WinRedChip className="mr-2" variant="yellow">
                Public
              </WinRedChip>
            ) : null}
            {upsell.upsell_page?.bill_pay_exists ? (
              <WinRedChip className="ml-3 mt-1" variant="purple">
                Bill-Pay
              </WinRedChip>
            ) : null}
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            {upsell.upsell_page?.public_upsell ? null : (
              <>
                <div className="mr-3">
                  <Typography className="mb-0" variant="bodyMedium">
                    Opt-in Rate
                  </Typography>
                  <Typography className="mb-0">{((upsell_statistics.percentage || 0) * 100).toFixed(1)}%</Typography>
                </div>
                <div className="mr-3">
                  <Typography className="mb-0" variant="bodyMedium">
                    Views
                  </Typography>
                  <Typography className="mb-0">{formatNumberWithCommas(upsell_statistics.view)}</Typography>
                </div>
                <div className="mr-3">
                  <Typography className="mb-0" variant="bodyMedium">
                    Accepted
                  </Typography>
                  <Typography className="mb-0">{formatNumberWithCommas(upsell_statistics.accepted)}</Typography>
                </div>
              </>
            )}

            <div className="mr-3" onClick={noEvent} onKeyPress={noEvent} role="button" tabIndex="0">
              {upsell_preview_url ? <ButtonLink url={upsell_preview_url} text="Preview" className="mr-2" /> : null}
            </div>
            {disabledUpsellFlow ? null : (
              <div onClick={onUpsellItemRemove} onKeyPress={onUpsellItemRemove} role="button" tabIndex="0">
                <Icon icon="times" color="gray" size="lg" />
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

UpsellItemDesktop.propTypes = {
  upsell: PropTypes.object.isRequired,
  onEditExistingUpsell: PropTypes.func.isRequired,
  onUpsellItemRemove: PropTypes.func.isRequired,
  disabledUpsellFlow: PropTypes.bool.isRequired,
};

export default UpsellItemDesktop;
