import get from 'lodash/get';

export const getDataFieldValues = ({ field, info, profileCandidate }) => {
  let { formatter, extractor, keys, alwaysFormat, label, hidden, ...restProps } = field;

  if (typeof label === 'function') label = label(info);
  if (hidden && typeof hidden === 'function') hidden = hidden(info, profileCandidate);

  let { key } = field;
  let value = '';

  /* if keys is given then we want multiple values for one field */
  if (keys) {
    key = keys;
    value = keys.split(',').map(key => get(info, key));
  } else if (typeof key === 'function') {
    value = key(info, key);
  } else {
    value = extractor ? extractor(info, key) : get(info, key);
  }

  if ((formatter && value) || alwaysFormat) value = formatter(value);
  if (!value) value = 'Unknown';

  return { key, value, ...field, label, hidden, ...restProps };
};
