import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';
import { useSelector } from 'react-redux';
import { upsellTabSelector } from '../../../../pages/store';
import { upsellNavTabs } from '../../../../pages/upsells-list.container';

function NavBarSubMenuPages({
  pathname,
  handleSelect,
  organizationRevvUid,
  isOrganization,
  showPagesSubNav,
  removeAccess,
  isMyPagesOnly,
}) {
  const activeUpsellTab = useSelector(upsellTabSelector);
  const [upsellsUrl, setUpsellsUrl] = useState(null);

  useEffect(() => {
    if (activeUpsellTab) {
      const urlExtension = upsellNavTabs.find(item => item.value === activeUpsellTab).url;
      setUpsellsUrl(urlExtension);
    }
  }, [activeUpsellTab, upsellsUrl]);

  if (removeAccess.includes(true)) return null;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for pages menu"
      showSubmenu={showPagesSubNav}
    >
      <NavList.Item eventKey={`/${organizationRevvUid}/pages/my-pages`} href={`/${organizationRevvUid}/pages/my-pages`}>
        My Pages
      </NavList.Item>

      {isOrganization || isMyPagesOnly ? null : (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/pages/connected-pages`}
          href={`/${organizationRevvUid}/pages/connected-pages`}
        >
          Connected Pages
        </NavList.Item>
      )}
      {isMyPagesOnly ? null : (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/pages/campaigns`}
          href={`/${organizationRevvUid}/pages/campaigns`}
        >
          Campaigns
        </NavList.Item>
      )}
      {isMyPagesOnly ? null : (
        <NavList.Item
          eventKey={upsellsUrl ? `/${organizationRevvUid}${upsellsUrl}` : `/${organizationRevvUid}/pages/upsell-flows`}
          href={upsellsUrl ? `/${organizationRevvUid}${upsellsUrl}` : `/${organizationRevvUid}/pages/upsell-flows`}
        >
          Upsells
        </NavList.Item>
      )}
      {isMyPagesOnly ? null : (
        <NavList.Item
          eventKey={`/${organizationRevvUid}/pages/petition-pathways`}
          href={`/${organizationRevvUid}/pages/petition-pathways`}
        >
          Petition Pathways
        </NavList.Item>
      )}
    </SubMenuContainer>
  );
}

NavBarSubMenuPages.propTypes = {
  pathname: PropTypes.string.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showPagesSubNav: PropTypes.bool.isRequired,
};

export default NavBarSubMenuPages;
