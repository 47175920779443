import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'modules/common';

function ConfirmModalBody({ title, subtitle }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <Typography variant="bodyMedium" className="mt-3 mb-1 text-center">
        {title}
      </Typography>
      <Typography className="text-center">{subtitle}</Typography>
    </div>
  );
}

ConfirmModalBody.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
};

export default ConfirmModalBody;
