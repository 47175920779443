import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Typography } from 'modules/common';
import { profileIsReadOnlySelector, isDonorSelector } from 'core/login/login.selectors';
import 'modules/pages/pages.scss';

export default function HBError({ error }) {
  const isReadOnly = useSelector(profileIsReadOnlySelector);
  const isDonor = useSelector(isDonorSelector);

  const returnText = isReadOnly ? 'Donations' : isDonor ? 'Team Pages' : 'Dashboard';

  return (
    <div className="error-page">
      <Typography variant="h1" className="error-code">
        505
      </Typography>
      <Typography variant="h2" className="page-title">
        Whoops!
      </Typography>

      <div className="body">
        <Typography>{error ? error.toString() : ''}</Typography>

        <Button variant="primary" onClick={() => (window.location.href = '/')}>
          Return to {returnText}
        </Button>
      </div>
    </div>
  );
}
HBError.propTypes = {
  error: PropTypes.object,
};
HBError.defaultProps = {
  error: null,
};
