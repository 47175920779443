import React, { useCallback, useState } from 'react';
import WinRedInputNumbers from './winred-input-numbers.component';
import './inputs.scss';

function WinRedInputMoney({ onChange, value, isCents, ...rest }) {
  const [hasChanged, setHasChanged] = useState(false);

  const _onChange = useCallback(
    ({ target: { name, value } }) => {
      // only allow 2 decimals
      if (/\.\d\d\d+/.test(value)) return;

      // always convert from cents and dollars so ui is in dollars
      // but value itself is in cents (sent to api)
      // also always needs to be int not string
      setHasChanged(true);
      const _value = isCents ? parseFloat(value, 10) * 100 : parseFloat(value, 10);

      onChange({ target: { name, value: _value } });
    },
    [isCents, onChange]
  );

  let _value = isCents ? parseFloat(value, 10) / 100 : value;
  if (isNaN(_value)) _value = '';

  // if original value make sure decimals is 2 digits
  if (!hasChanged && _value) _value = _value.toFixed(2);

  return (
    <div className="prepend-label-dollar">
      <WinRedInputNumbers prepend="$" onChange={_onChange} value={_value} {...rest} />
    </div>
  );
}

export default WinRedInputMoney;
