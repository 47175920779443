import gql from 'graphql-tag';

const CONNECTED_COMMITTEE_FRAGMENT = gql`
  fragment VendorSettingsCandidate on Candidate {
    name
    created_at
    revv_uid
    vendor_settings {
      receivedConnections {
        name
        revv_uid
        is_revv_share
        default_fee_percentage
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      awaitingApproval {
        name
        revv_uid
        is_revv_share
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      connected {
        fullAccess {
          id
          defaultFeePercentage
          created_at
          updated_at
          vendor {
            name
            revv_uid
            is_revv_share
            avatar {
              image {
                thumbnailUrl
              }
            }
            contact_info {
              first_name
              last_name
              email
            }
            contact_address {
              address
              address2
              city
              state
              zip
            }
            w9_form {
              id
              originalUrl
              type
            }
          }
          vendorConnectActivity(filters: { page: $page, limit: $limit }) {
            page_count
            total_count
            current_page
            results {
              id
              activityType
              sourceName
              destinationName
              feeChangedFrom
              feeChangedTo
              date
              email
            }
          }
        }
        billPayOnly {
          id
          defaultFeePercentage
          created_at
          updated_at
          vendor {
            name
            revv_uid
            is_revv_share
            avatar {
              image {
                thumbnailUrl
              }
            }
            contact_info {
              first_name
              last_name
              email
            }
            contact_address {
              address
              address2
              city
              state
              zip
            }
            w9_form {
              id
              originalUrl
              type
            }
          }
          vendorConnectActivity(filters: { page: $page, limit: $limit }) {
            page_count
            total_count
            current_page
            results {
              id
              activityType
              sourceName
              destinationName
              feeChangedFrom
              feeChangedTo
              date
              email
            }
          }
        }
      }
      disconnected {
        id
        defaultFeePercentage
        created_at
        updated_at
        vendor {
          name
          revv_uid
          is_revv_share
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
        vendorConnectActivity(filters: { page: $page, limit: $limit }) {
          page_count
          total_count
          current_page
          results {
            id
            activityType
            sourceName
            destinationName
            feeChangedFrom
            feeChangedTo
            date
            email
          }
        }
      }
    }
  }
`;

export const GET_CONNECTED_COMMITTEES_QUERY = gql`
  query getCandidateVendorSettings($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        ...VendorSettingsCandidate
      }
    }
  }
  ${CONNECTED_COMMITTEE_FRAGMENT}
`;

export const GET_BILL_PAY_QUERY = `
  query getCandidateBillPayIdVendor($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        bill_pay_id
      }
    }
  }
`;

export const GET_CONNECTED_COMMITTEE_DETAILS_QUERY = gql`
  query getCandidateBillPayIdVendor($organizationRevvUid: String, $billPayId: String!) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        bill_pay_id
        vendor(billPayId: $billPayId) {
          name
          revv_uid
          is_revv_share
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

export const CONNECT_TO_VENDOR_MUTATION = gql`
  mutation candidateVendorConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: VendorConnectRequestInput!
  ) {
    candidateCreateVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      receiver {
        name
        revv_uid
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      errors
    }
  }
`;

export const APPROVE_CONNECT_TO_VENDOR_MUTATION = gql`
  mutation candidateAcceptVendorConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: AcceptVendorConnectRequestInput!
  ) {
    candidateAcceptVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;

export const EDIT_CONNECTION_TO_VENDOR_MUTATION = gql`
  mutation candidateUpdateBillPayConnectionMutation(
    $billPayConnection: UpdateBillPayConnectionInput!
    $organizationRevvUid: String!
  ) {
    candidateUpdateBillPayConnection(billPayConnection: $billPayConnection, organizationRevvUid: $organizationRevvUid) {
      message
      errors
    }
  }
`;

export const CANCEL_CONNECTION_REQUEST_MUTATION = gql`
  mutation candidateCancelVendorConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: CancelVendorConnectRequestInput!
  ) {
    candidateCancelVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;

export const DISCONNECT_FROM_VENDOR_REQUEST_MUTATION = gql`
  mutation candidateDisconnectBillPayConnectionMutation(
    $billPayConnection: DisconnectBillPayConnectionInput!
    $organizationRevvUid: String!
  ) {
    candidateDisconnectBillPayConnection(
      billPayConnection: $billPayConnection
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;
