import React from 'react';
import PropTypes from 'prop-types';

import { FormContainer, FormColumn, FormCard, Typography, WinRedInputNumbers } from 'modules/common';
import { maxValue, minValue } from 'core/utilities';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';

const percentageValidators = [
  {
    message: 'Default percentage fee is required.',
    validator: 'required',
  },
  {
    message: 'Max value of 100 percent',
    validator: inputValue => maxValue(100, inputValue),
  },
  {
    message: 'Min value of 0 percent',
    validator: inputValue => minValue(0, inputValue),
  },
];

function CommitteeConnectEditForm({ committee, defaultFeePercentage, loading, onChange }) {
  return (
    <FormContainer>
      <FormColumn>
        <FormCard>
          <div className="d-flex align-items-center">
            <img
              className="h-8 w-8 rounded-full flex-none mr-2"
              alt="connection profile"
              src={committee?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar}
            />
            <Typography className="mr-3" variant="h4">
              {committee?.name || ''}
            </Typography>
          </div>
          <div className="mt-3">
            <Typography className="mb-1" variant="bodyMedium">
              Default Percentage Fee
            </Typography>
            <div className="md:flex">
              <div className="flex-1">
                <WinRedInputNumbers
                  name="defaultFeePercentage"
                  append={'%'}
                  placeholder="Default Percentage Fee"
                  value={defaultFeePercentage}
                  className="winred-input input-with-append"
                  onChange={onChange}
                  doNotWrap={true}
                  validators={percentageValidators}
                />
              </div>
            </div>
          </div>
        </FormCard>
      </FormColumn>
    </FormContainer>
  );
}

CommitteeConnectEditForm.propTypes = {
  committee: PropTypes.object,
  defaultFeePercentage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { CommitteeConnectEditForm };
