import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';
import { formatAmount } from 'core/utilities';
import './../revenue.scss';

function TeamDonationTrends({ data }) {
  if (!data.viewer || !data.viewer.donations) return null;
  const amount = formatAmount(data.viewer.donations.gross_amount || 0);

  return (
    <div className="d-relative mr-3">
      <Typography variant="h5" className="mb-0 mt-1">
        {amount}
      </Typography>
      <Typography className="mb-0">Total Raised</Typography>
    </div>
  );
}

TeamDonationTrends.propTypes = {
  raised: PropTypes.number,
  donations: PropTypes.number,
};

export default TeamDonationTrends;
