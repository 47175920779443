import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormCardSpaced, Table, Typography, WinRedInput } from 'modules/common';
import { replaceSpacesWithDashes, urlSlugValidator } from 'core/utilities';

export const DuplicateErrorComponent = ({ data, isMobile = false, onSubmit }) => {
  const [slug, setSlug] = useState(data?.page_duplication_parameters?.custom_slug || '');
  const onChangeSlug = ({ target: { value } }) => {
    setSlug(replaceSpacesWithDashes(value));
  };

  const handleOnSubmit = () => {
    onSubmit({
      id: data.id,
      pageStatus: data.page_status,
      pageData: {
        ...data.page_duplication_parameters,
        customSlug: slug,
      },
    });
  };
  const ContainerComponentOutter = isMobile ? 'div' : Table.Row;
  const ContainerComponentInner = isMobile ? 'div' : Table.Cell;
  return (
    <ContainerComponentOutter className="slug-error-row w-full">
      <ContainerComponentInner colSpan={4} className={`align-top ${isMobile ? '' : 'pr-4'} pt-3`}>
        <div className="flex items-center sm:flex-row ">
          {data.errors.map((error, index) => {
            return (
              <Typography className="mb-0 mr-2" color="red" key={index}>
                {error}
              </Typography>
            );
          })}
        </div>
        <FormCardSpaced>
          <div className="flex items-start flex-col sm:flex-row ">
            <WinRedInput
              classNameContainer="flex-1 w-full"
              name="slug"
              placeholder="URL Slug"
              type="text"
              validators={[
                { message: 'URL Slug is required', validator: 'required' },
                {
                  message: 'URL slug can only contain lowercase letters, numbers, dashes and underscores.',
                  validator: urlSlugValidator,
                },
                { message: 'URL Slug cannot be over 50 characters', validator: slug => slug.length <= 50 },
              ]}
              value={slug}
              onChange={onChangeSlug}
            />
            <Button variant="primary" className={`sm:ml-3 ${isMobile && 'mt-2'}`} onClick={handleOnSubmit}>
              Duplicate Page
            </Button>
          </div>
        </FormCardSpaced>
      </ContainerComponentInner>
    </ContainerComponentOutter>
  );
};

DuplicateErrorComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
