import React, { useState, useCallback, useMemo } from 'react';
import honeybadger from 'honeybadger-js';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import WinRedSelectSearch from './winred-select-search.component';

function WinRedLazySelect({
  placeholder,
  query,
  baseVariables,
  onChange,
  formatLoadedOptions,
  initialValue,
  valueIsObject,
	searchKey = 'search',
  filterFunction,
  ...rest
}) {
  const [dropdownSearchValue, setDropdownSearchValue] = useState(initialValue || '');
  const [selectOptions, setSelectOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState('');

  const [loadOptions, { loading, error }] = useLazyQuery(query, {
    onCompleted: data => {
      const allResults = formatLoadedOptions(data);
      setSelectOptions(allResults);
    },
  });

  const cleansedOptions = useMemo(() => {
    if(filterFunction){
      const cleanOptions = selectOptions.filter(opt => filterFunction(opt));
      return cleanOptions;
    } else {
      return selectOptions;
    }

  }, [selectOptions, filterFunction]);

  // lazy load results after 3 chars min
  const _setDropdownSearchValue = useCallback(
    value => {
      if (!value) return;
      setDropdownSearchValue(value);

      // reset dropdown values so we dont show previous results before searching
      setSelectOptions([]);

      if (timeoutId) clearTimeout(timeoutId);
      const _id = setTimeout(() => {
        if (value && value.length > 2) loadOptions({ variables: { ...baseVariables, [searchKey]: value } });
      }, 400);

      setTimeoutId(_id);
    },
    [setDropdownSearchValue, timeoutId, loadOptions, baseVariables, searchKey]
  );

  const onSelect = ({ target: { name, value } }) => {
    setDropdownSearchValue(value);
    onChange({ target: { name, value } });
  };

  if (error) {
    console.error(error);
    honeybadger.notify(error);
  }

  return (
    <WinRedSelectSearch
      value={dropdownSearchValue}
      placeholder={placeholder}
      options={cleansedOptions}
      isLoading={loading}
      isClearable
      onChange={onSelect}
      hideDropdownIndicator
      dropdownSearchValue={dropdownSearchValue}
      onInputChange={_setDropdownSearchValue}
      filterOption={false}
      noOptionsMessage={
        dropdownSearchValue?.length < 3 ? 'Please enter at least 3 characters to search.' : 'No results found.'
      }
      valueIsObject={valueIsObject}
      {...rest}
    />
  );
}

WinRedLazySelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  baseVariables: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  formatLoadedOptions: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
  valueIsObject: PropTypes.bool,
  searchKey: PropTypes.string,
  filterFunction: PropTypes.func,
};

export default WinRedLazySelect;
