import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FormHeader = ({ children, navTabs }) => {
  return (
    <div className="page-header">
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg="11">
            <Row className={classname('header', { 'pb-0': navTabs })}>{children}</Row>
          </Col>
        </Row>
        {navTabs}
      </Container>
    </div>
  );
};

FormHeader.propTypes = {
  children: PropTypes.any.isRequired,
  navTabs: PropTypes.any,
};

export default FormHeader;
