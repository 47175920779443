// This list comes from Wikipedia deprecated timezones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
const deprecatedList = [
  { old: 'Australia/ACT', new: 'Australia/Sydney' },
  { old: 'Australia/LHI', new: 'Australia/Lord_Howe' },
  { old: 'Australia/North', new: 'Australia/Darwin' },
  { old: 'Australia/NSW', new: 'Australia/Sydney' },
  { old: 'Australia/Queensland', new: 'Australia/Brisbane' },
  { old: 'Australia/South', new: 'Australia/Adelaide' },
  { old: 'Australia/Tasmania', new: 'Australia/Hobart' },
  { old: 'Australia/Victoria', new: 'Australia/Melbourne' },
  { old: 'Australia/West', new: 'Australia/Perth' },
  { old: 'Brazil/Acre', new: 'America/Rio_Branco' },
  { old: 'Brazil/DeNoronha', new: 'America/Noronha' },
  { old: 'Brazil/East', new: 'America/Sao_Paulo' },
  { old: 'Brazil/West', new: 'America/Manaus' },
  { old: 'Canada/Atlantic', new: 'America/Halifax' },
  { old: 'Canada/Central', new: 'America/Winnipeg' },
  { old: 'Canada/Eastern', new: 'America/Toronto' },
  { old: 'Canada/Mountain', new: 'America/Edmonton' },
  { old: 'Canada/Newfoundland', new: 'America/St_Johns' },
  { old: 'Canada/Pacific', new: 'America/Vancouver' },
  { old: 'Canada/Saskatchewan', new: 'America/Regina' },
  { old: 'Canada/Yukon', new: 'America/Whitehorse' },
  { old: 'CET', new: 'Europe/Paris' },
  { old: 'Chile/Continental', new: 'America/Santiago' },
  { old: 'Chile/EasterIsland', new: 'Pacific/Easter' },
  { old: 'CST6CDT', new: 'America/Chicago' },
  { old: 'Cuba', new: 'America/Havana' },
  { old: 'EET', new: 'Europe/Sofia' },
  { old: 'Egypt', new: 'Africa/Cairo' },
  { old: 'Eire', new: 'Europe/Dublin' },
  { old: 'EST', new: 'America/Cancun' },
  { old: 'EST5EDT', new: 'America/New_York' },
  { old: 'Etc/Greenwich', new: 'Etc/GMT' },
  { old: 'Etc/Universal', new: 'Etc/UTC' },
  { old: 'Etc/Zulu', new: 'Etc/UTC' },
  { old: 'GB', new: 'Europe/London' },
  { old: 'GB-Eire', new: 'Europe/London' },
  { old: 'GMT+0', new: 'Etc/GMT' },
  { old: 'GMT0', new: 'Etc/GMT' },
  { old: 'GMT−0', new: 'Etc/GMT' },
  { old: 'Greenwich', new: 'Etc/GMT' },
  { old: 'Hongkong', new: 'Asia/Hong_Kong' },
  { old: 'HST', new: 'Pacific/Honolulu' },
  { old: 'Iceland', new: 'Atlantic/Reykjavik' },
  { old: 'Iran', new: 'Asia/Tehran' },
  { old: 'Israel', new: 'Asia/Jerusalem' },
  { old: 'Jamaica', new: 'America/Jamaica' },
  { old: 'Japan', new: 'Asia/Tokyo' },
  { old: 'Kwajalein', new: 'Pacific/Kwajalein' },
  { old: 'Libya', new: 'Africa/Tripoli' },
  { old: 'MET', new: 'Europe/Paris' },
  { old: 'Mexico/BajaNorte', new: 'America/Tijuana' },
  { old: 'Mexico/BajaSur', new: 'America/Mazatlan' },
  { old: 'Mexico/General', new: 'America/Mexico_City' },
  { old: 'MST', new: 'America/Phoenix' },
  { old: 'MST7MDT', new: 'America/Denver' },
  { old: 'Navajo', new: 'America/Denver' },
  { old: 'NZ', new: 'Pacific/Auckland' },
  { old: 'NZ-CHAT', new: 'Pacific/Chatham' },
  { old: 'Poland', new: 'Europe/Warsaw' },
  { old: 'Portugal', new: 'Europe/Lisbon' },
  { old: 'PRC', new: 'Asia/Shanghai' },
  { old: 'PST8PDT', new: 'America/Los_Angeles' },
  { old: 'ROC', new: 'Asia/Taipei' },
  { old: 'ROK', new: 'Asia/Seoul' },
  { old: 'Singapore', new: 'Asia/Singapore' },
  { old: 'Turkey', new: 'Europe/Istanbul' },
  { old: 'UCT', new: 'Etc/UCT' },
  { old: 'Universal', new: 'Etc/UTC' },
  { old: 'US/Alaska', new: 'America/Anchorage' },
  { old: 'US/Aleutian', new: 'America/Adak' },
  { old: 'US/Arizona', new: 'America/Phoenix' },
  { old: 'US/Central', new: 'America/Chicago' },
  { old: 'US/Eastern', new: 'America/New_York' },
  { old: 'US/East-Indiana', new: 'America/Indiana/Indianapolis' },
  { old: 'US/Hawaii', new: 'Pacific/Honolulu' },
  { old: 'US/Indiana-Starke', new: 'America/Indiana/Knox' },
  { old: 'US/Michigan', new: 'America/Detroit' },
  { old: 'US/Mountain', new: 'America/Denver' },
  { old: 'US/Pacific', new: 'America/Los_Angeles' },
  { old: 'US/Pacific-New', new: 'America/Los_Angeles' },
  { old: 'US/Samoa', new: 'Pacific/Pago_Pago' },
  { old: 'WET', new: 'Europe/Lisbon' },
  { old: 'W-SU', new: 'Europe/Moscow' },
  { old: 'Zulu', new: 'Etc/UTC' },
];

/**
 * see if a timezone given is depricated and return the corresponding new timezone if it is
 */
export const fixDepricatedTimeZone = timezone => {
  const timezoneMap = deprecatedList.find(d => d.old === timezone);
  if (timezoneMap) return timezoneMap.new;
  return timezone;
};
