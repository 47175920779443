import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isDevelopment } from 'core/utilities';

/* Get a list of enabled features from the environment variables. */
const ENABLED_FEATURES = process.env.REACT_APP_ENABLED_FEATURES
  ? process.env.REACT_APP_ENABLED_FEATURES.split(',')
  : [];

export const FeaturesContext = React.createContext({});

/* Create a component to provide the features context. */
export default function FeaturesProvider(props) {
  const [enabledFeatures, updateFeatures] = useState(ENABLED_FEATURES);

  const isEnabled = feature => enabledFeatures.includes(feature);

  const toggleFeature = feature => {
    if (isEnabled(feature)) {
      updateFeatures([...enabledFeatures.filter(f => f !== feature)]);
    } else {
      updateFeatures([...enabledFeatures, feature]);
    }
  };

  const features = {
    enabledFeatures,
    isEnabled,
  };

  /* Only allow toggling features via the console in dev and staging. */
  if (isDevelopment) window.toggleFeature = toggleFeature;

  return <FeaturesContext.Provider value={features}>{props.children}</FeaturesContext.Provider>;
}
FeaturesProvider.propTypes = {
  children: PropTypes.any,
};
FeaturesProvider.defaultProps = {
  children: null,
};
