/* We break all of the types out into their own file to prevent dependency
loops in the login reducer which causes an error in the console. */
export const revvUserRolesKeyMap = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  READ_ONLY: 'READ_ONLY',
  PAGES_ONLY: 'PAGES_ONLY',
  MERCHANDISE_ONLY: 'MERCHANDISE_ONLY',
  MY_PAGES_ONLY: 'MY_PAGES_ONLY',
};

export const revvUserRolesMap = {
  [revvUserRolesKeyMap.ADMIN]: 'Admin',
  [revvUserRolesKeyMap.EDITOR]: 'Editor',
  [revvUserRolesKeyMap.READ_ONLY]: 'Read Only',
  [revvUserRolesKeyMap.PAGES_ONLY]: 'Pages Only',
  [revvUserRolesKeyMap.MY_PAGES_ONLY]: 'My Pages Only',
  [revvUserRolesKeyMap.MERCHANDISE_ONLY]: 'Merchandise Only',
};

export const revvUserRoles = [
  { value: revvUserRolesKeyMap.ADMIN, label: revvUserRolesMap.ADMIN },
  { value: revvUserRolesKeyMap.EDITOR, label: revvUserRolesMap.EDITOR },
  { value: revvUserRolesKeyMap.READ_ONLY, label: revvUserRolesMap.READ_ONLY },
  { value: revvUserRolesKeyMap.PAGES_ONLY, label: revvUserRolesMap.PAGES_ONLY },
  { value: revvUserRolesKeyMap.MY_PAGES_ONLY, label: revvUserRolesMap.MY_PAGES_ONLY },
  { value: revvUserRolesKeyMap.MERCHANDISE_ONLY, label: revvUserRolesMap.MERCHANDISE_ONLY },
];

export const validActiveTabsWithoutPortal = [
  'general',
  'pages',
  'disclaimer',
  'users',
  'directory',
  'closure',
  'vendors',
];

export const validActiveTabsWithPortal = [
  'committee',
  'owners',
  'bank',
  'pages',
  'disclaimers',
  'users',
  'directory',
  'closure',
  'vendors',
  'activity',
];

export const validActiveTabsIndividual = [
  'individual',
  'bank',
  'pages',
  'disclaimers',
  'users',
  'directory',
  'closure',
  'vendors',
  'activity',
];

export const DEFAULT_CLOSURE = {
  remove_from_conduiting: false,
  remove_from_conduiting_on: '',
  cancel_recurring_and_pledges: false,
  cancel_recurring_and_pledges_on: '',
  archive_pages: false,
  archive_pages_on: '',
  confirmation_paragraph: '',
  confirmation_custom_link: '',
  confirmation_custom_link_copy: '',
};
