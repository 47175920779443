import React from 'react';
import PropTypes from 'prop-types';

import { FloatGroup, Input, FormCard } from 'modules/common';

export default function VideoEmbed({ className, disabled, name, onChange, subtitle, value, ...rest }) {
  return (
    <FormCard className={className} title="Video Embed Code" subtitle={subtitle}>
      <FloatGroup>
        <Input
          type="text"
          as="textarea"
          name={name}
          rows="4"
          disabled={disabled}
          placeholder="Video Embed Code"
          value={value}
          onChange={onChange}
          {...rest}
        />
      </FloatGroup>
    </FormCard>
  );
}

VideoEmbed.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.string.isRequired,
};
VideoEmbed.defaultProps = {
  subtitle: 'Enter a YouTube, Vimeo, Facebook, or Twitter embed code to display a video.',
};
