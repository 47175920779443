import gql from 'graphql-tag';

export const GET_ORGANIZATION_PAYOUTS_QUERY = gql`
  query getOrganizationPayouts(
    $amount: Float
    $amountPredicate: Predicate
    $limit: Int
    $page: Int
    $status: PayoutStatusEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $arrivalDateStart: String
    $arrivalDateEnd: String
    $payoutRevvUid: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        payouts(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            limit: $limit
            page: $page
            search: $search
            status: $status
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            arrivalDateStart: $arrivalDateStart
            arrivalDateEnd: $arrivalDateEnd
            payoutRevvUid: $payoutRevvUid
          }
        ) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            status
            estimatedArrivalAt
            statementDescriptor
            refundCents
            netRefunds
            refundDisputeCents
            feeCents
            grossCents
            netCents
            reportUrl
            failureCode
            failureReason
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PAYOUTS_REPORT = gql`
  query getOrganizationPayoutsReport(
    $amount: Float
    $amountPredicate: Predicate
    $limit: Int
    $page: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $payoutRevvUid: String
    $revvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $revvUid) {
        payouts(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            limit: $limit
            page: $page
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            revvUid: $payoutRevvUid
          }
        ) {
          results {
            exportPayoutDetailReport
            revv_uid
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PAYOUT_QUERY = gql`
  query getOrganizationPayout(
    $payoutRevvUid: String
    $organizationRevvUid: String
    $transactionsPage: Int
    $transactionsLimit: Int
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        payouts(filters: { search: $payoutRevvUid }) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            status
            estimatedArrivalAt
            statementDescriptor
            invoiceRevvUid
            reportUrl
            failureCode
            failureReason
            grossCents
            refundCents
            refundGrossCents
            merchFeeTotalRefundCents
            processingFeeReturnedCents
            disputeCents
            agencyRefundCents
            processingFeeCents
            feeCents
            merchFeeCents
            disputeFeeCents
            netRefunds
            # bankAccount {
            #   account_holder_name
            #   routing_number
            #   last4
            #   bank_name
            #   currency
            #   default_for_currency
            # }
            partnerFees {
              name
              feeCents
            }
            refundedPartnerFees {
              revv_uid
              name
              feeCents
            }
            partnerFeeCents
            netCents

            adjustmentFees {
              name
              feeCents
            }
            retriedPayouts {
              revv_uid
              gross
            }
            adjustmentFeeTotalCents

            payoutDetails(filters: { page: $transactionsPage, limit: $transactionsLimit }) {
              current_page
              next_page
              page_count
              previous_page
              total_count
              results {
                created_at
                revv_uid
                gross_cents
                fee_cents
                net_cents
                balance_cents
                transaction_type
                charge_revv_uid
                code_name
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PAYOUT_REPORT = gql`
  query getOrganizationPayoutDetailsReport($organizationRevvUid: String, $payoutRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        payouts(filters: { payoutRevvUid: $payoutRevvUid }) {
          results {
            exportPayoutDetailReport
            revv_uid
          }
        }
      }
    }
  }
`;

export const EXPORT_ORGANIZATION_PAYOUT_LEDGER_REPORT = gql`
  mutation ExportOrganizationPayoutLedgerReport($payout: ExportPayoutReportInput!, $organizationRevvUid: String) {
    organizationExportPayoutLedgerReport(payout: $payout, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;

export const EXPORT_ORGANIZATION_PAYOUT_FEES_REPORT = gql`
  mutation ExportOrganizationPayoutFeesReport($payout: ExportPayoutReportInput!, $organizationRevvUid: String) {
    organizationExportPayoutFeesReport(payout: $payout, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;
