import gql from 'graphql-tag';

import { productFragments } from './products.queries';

export const GET_ORGANIZATION_PRODUCTS_QUERY = gql`
  query getOrganizationProducts(
    $page: Int
    $limit: Int
    $search: String
    $organizationRevvUid: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: RansackPredicate
    $fulfillmentHouse: FulfillmentHouseEnum
    $productCategory: MerchProductUserDefinedCategoryEnum
    $status: MerchProductStatusEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        store_products(
          filters: {
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            price: $amount
            pricePredicate: $amountPredicate
            fulfillmentHouse: $fulfillmentHouse
            productCategory: $productCategory
            status: $status
          }
        ) {
          ...Products
        }
      }
    }
  }
  ${productFragments.products}
`;

export const GET_ORGANIZATION_PRODUCT_QUERY = gql`
  query getOrganizationProduct($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        storefront_settings {
          fulfillmentHouse
        }
        store_products(filters: { revvUid: $revv_uid }) {
          results {
            ...Product
          }
        }
      }
    }
  }
  ${productFragments.product}
`;

export const TOGGLE_ORGANIZATION_ACTIVE_MUTATION = gql`
  mutation UpdateStorefrontOrganizationProductMutation($product: StorefrontProductInput!) {
    organizationUpdateStorefrontProduct(product: $product) {
      message
      errors
    }
  }
`;

export const CREATE_ORGANIZATION_PRODUCT = gql`
  mutation createOrganizationMerchProductMutation(
    $merchProduct: CreateMerchProductInput!
    $organizationRevvUid: String
  ) {
    organizationCreateMerchProduct(merchProduct: $merchProduct, organizationRevvUid: $organizationRevvUid) {
      errors
      merchProduct {
        ...Product
      }
    }
  }
  ${productFragments.product}
`;

export const UPDATE_ORGANIZATION_PRODUCT = gql`
  mutation updateOrganizationMerchProductMutation(
    $merchProduct: UpdateMerchProductInput!
    $organizationRevvUid: String
  ) {
    organizationUpdateMerchProduct(merchProduct: $merchProduct, organizationRevvUid: $organizationRevvUid) {
      errors
      merchProduct {
        ...Product
      }
    }
  }
  ${productFragments.product}
`;
