import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ColorPickerInput,
  FlexBinaryChoiceRow,
  FormCard,
  WinRedInput,
  WinRedLazySelect,
  WinRedSelect,
} from 'modules/common/form';
import { useLazyQuery } from '@apollo/client';
import {
  GET_CANDIDATE_PAGE_POLL_RESULTS,
  GET_ORGANIZATION_PAGES_ID_NAME_QUERY,
  GET_ORGANIZATION_PAGE_POLL_RESULTS,
  GET_PAGES_ID_NAME_QUERY,
} from 'core/middleware/queries';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import { useSelector } from 'react-redux';
import { useToast } from 'modules/common/hooks';
import { isNull } from 'lodash';
import Typography from 'modules/common/typography.component';
import { Icon, SpinnerContainer, FormCardSpaced, ToolTip } from 'modules/common';
import { CUSTOM_POLL_RESULTS_TYPE_MAP, DEFAULT_CUSTOM_POLL_RESULTS, createPageResultsOptions } from '../tools';
import { formatBoostCustomFieldResultsForUi } from '../tools';
import { Col, Row } from 'react-bootstrap';
import { convertEpochTime } from 'core/utilities';
import { computePageIcon, computePageIconColor, computePageTooltip } from '../../../pages/pages/ui.tools';

const WidgetCustomFieldResults = ({ widget, updateWidget }) => {
  const [pollResultsOptions, setPollResultsOptions] = useState([]);
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);
  const setToast = useToast();

  const searchQuery = isOrganization ? GET_ORGANIZATION_PAGES_ID_NAME_QUERY : GET_PAGES_ID_NAME_QUERY;

  const queryVars = {
    organizationRevvUid: profileCandidate.organization_revv_uid,
    pageType: ['PETITION', 'DONATION'],
    limit: 20,
  };

  const onSelect = ({ target: { name, value } }) => {
    if (isNull(value)) {
      return;
    }
    let newWidget = {
      pollResultsPageId: `${value.id}`,
      pollResultsPage: value,
    };
    updateWidget({ target: { name: 'widgets', value: { ...newWidget } } });
  };

  const [getPollResults, { loading }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_PAGE_POLL_RESULTS : GET_CANDIDATE_PAGE_POLL_RESULTS
  );
  const handleGetPollResults = useCallback(
    async pageId => {
      const variables = {
        page: 1,
        limit: 9999,
        organizationRevvUid: profileCandidate.organization_revv_uid,
        pageRevvUid: pageId,
      };
      try {
        const queryResults = await getPollResults({ variables });
        const candidate = queryResults.data.viewer.candidate || queryResults.data.viewer.state_level_organization;
        const { results } = candidate.page_custom_fields;
        setPollResultsOptions(
          results.map(result => {
            return {
              value: result,
              label: `${result.label} [${CUSTOM_POLL_RESULTS_TYPE_MAP[result.input_type]}]`,
            };
          })
        );
      } catch (error) {
        console.error(error);
        setToast({ message: error, isError: true });
      }
    },
    [getPollResults, profileCandidate.organization_revv_uid, setToast]
  );

  const handlePollSelect = ({ target: { name, value } }) => {
    if (isNull(value)) {
      return;
    }
    let newWidget = {
      pollResultsCustomFieldId: value.page_custom_field_id,
      pollResultsCustomField: value,
      pollResultsLabel: value.label,
      pollResultsStats: value.customFieldStats,
      pollResultsOtherFieldResults: value.input_type === 'CHECK_BOX' ? false : value.other_field_enabled,
      boostPollResults: false,
      customFieldResultsBoostValues: formatBoostCustomFieldResultsForUi(widget, value),
    };
    updateWidget({ target: { name: 'widgets', value: { ...newWidget } } });
  };

  useEffect(() => {
    if (widget?.pollResultsPage) {
      handleGetPollResults(widget?.pollResultsPage?.revv_uid);
    }
  }, [handleGetPollResults, widget?.pollResultsPage]);

  const selectedPollOption = useMemo(
    () =>
      pollResultsOptions.find(
        o => o.value?.page_custom_field_id === widget?.pollResultsCustomField?.page_custom_field_id
      ),
    [pollResultsOptions, widget?.pollResultsCustomField?.page_custom_field_id]
  );

  const handleRemovePage = () => {
    let newWidget = DEFAULT_CUSTOM_POLL_RESULTS;
    updateWidget({ target: { name: 'widgets', value: { ...newWidget } } });
    setPollResultsOptions([]);
  };

  const handleOnDisplayChange = ({ target: { name, value } }) => {
    if (value) {
      updateWidget({ target: { name, value: 'PERCENTAGE' } });
    } else {
      updateWidget({ target: { name, value: 'WHOLENUMBER' } });
    }
  };

  const handleOptionBoostChange = ({ target: { name, value } }) => {
    let newValue = { ...widget.customFieldResultsBoostValues };
    newValue[name] = parseInt(value, 10) || 0;
    updateWidget({ target: { name: 'customFieldResultsBoostValues', value: newValue } });
  };

  return (
    <div>
      {widget?.pollResultsPage?.revv_uid ? (
        <Row className="flex justify-between items-center mb-4 border border-mystic px-3 py-2 mx-0 rounded-sm">
          <Col xs={10} sm={11} className="items-center justify-between w-full">
            <Row>
              <Col xs={12} lg={7} className="flex justify-start items-center">
                <ToolTip tooltip={computePageTooltip(widget?.pollResultsPage)}>
                  <Icon
                    icon={computePageIcon(widget?.pollResultsPage)}
                    color={computePageIconColor(widget?.pollResultsPage)}
                    className="text-2xl mr-0 hidden lg:mr-3 lg:block"
                  />
                </ToolTip>
                <div className="mb-2 sm:mb-0 md:ml-3">
                  <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
                    {widget?.pollResultsPage?.internalName || ''}
                  </Typography>
                  <Typography className="mb-0">{widget?.pollResultsPage?.slug}</Typography>
                </div>
              </Col>
              <Col xs={12} lg={5}>
                {convertEpochTime(widget?.pollResultsPage?.created_at)} <br />
                {widget?.pollResultsPage?.authorEmail && `by ${widget?.pollResultsPage?.authorEmail}`}
              </Col>
            </Row>
          </Col>
          <Col xs={2} sm={1} className="flex justify-end">
            <Icon icon="times" onClick={handleRemovePage} className="cursor-pointer" />
          </Col>
        </Row>
      ) : (
        <WinRedLazySelect
          name="pollResultsPage"
          baseVariables={queryVars}
          onChange={onSelect}
          query={searchQuery}
          placeholder="Search Pages"
          formatLoadedOptions={createPageResultsOptions}
          initialValue=""
          searchKey="search"
          description='Find a page to pull results from by searching the Internal Page Name or URL Slug. Use quotes to search for an exact phrase. e.g. "donate-today"'
        />
      )}

      {widget?.pollResultsPage?.revv_uid ? (
        loading ? (
          <SpinnerContainer />
        ) : (
          <WinRedSelect
            name="pollResultsCustomFieldId"
            description="The custom field that you would like to show the results of. You may display the results of a multiple choice, check box, or radio button custom field."
            options={pollResultsOptions}
            placeholder="Select Custom Field"
            validators={[{ validator: 'required', message: 'Custom Field is required' }]}
            value={selectedPollOption}
            onChange={handlePollSelect}
          />
        )
      ) : null}
      {selectedPollOption ? (
        <>
          <WinRedInput
            name="pollResultsLabel"
            placeholder="Poll Results Label"
            validators={[{ validator: 'required', message: 'Poll Results Label is Required' }]}
            value={widget?.pollResultsLabel}
            onChange={updateWidget}
            description="The text that will show above your poll results."
          />
          <ColorPickerInput
            value={widget.pollResultsColor}
            name="pollResultsColor"
            setColorAction={updateWidget}
            disabled={false}
            placeholder="Chart Color"
            label="Chart Color"
            description="The display color of your poll results."
            className="m-0"
          />
          <div className="-mt-6">
            <FormCard>
              <FlexBinaryChoiceRow
                name="pollResultsDisplayType"
                title="Poll Results Data Display"
                trueText="Percentage"
                falseText="Whole Number"
                subTitle="Select whether you would like to display poll results as a whole number or a percentage."
                value={widget.pollResultsDisplayType === 'PERCENTAGE'}
                onChange={handleOnDisplayChange}
              />
            </FormCard>
            {widget?.pollResultsCustomField.input_type !== 'CHECK_BOX' ? (
              <FormCard>
                <FlexBinaryChoiceRow
                  name="pollResultsOtherFieldResults"
                  title="Show Other Field Results"
                  subTitle='Toggle yes to display the results of the "Other" field.'
                  value={widget?.pollResultsOtherFieldResults}
                  onChange={updateWidget}
                />
              </FormCard>
            ) : null}
            {Object.keys(widget?.pollResultsStats.wholeNumber).length > 0 &&
            widget?.pollResultsCustomField.input_type !== 'CHECK_BOX' ? (
              <FormCard>
                <FlexBinaryChoiceRow
                  name="boostPollResults"
                  title="Boost Results"
                  subTitle="Select a certain custom field result to boost."
                  value={widget?.boostPollResults}
                  onChange={updateWidget}
                />
              </FormCard>
            ) : null}
            {widget?.boostPollResults &&
            widget?.customFieldResultsBoostValues &&
            widget?.pollResultsCustomField.input_type !== 'CHECK_BOX'
              ? Object.keys(widget?.customFieldResultsBoostValues).map((item, index) => (
                  <div key={index}>
                    <FormCardSpaced>
                      <Row>
                        <Col>
                          <WinRedInput
                            type="text"
                            name={`${item}-${index}`}
                            label={item}
                            value={item}
                            maxLength="255"
                            disabled={true}
                          />
                        </Col>
                        <Col>
                          <WinRedInput
                            numbersonly={'true'}
                            name={item}
                            description="Enter a whole number to boost the results of your selected option."
                            value={widget?.customFieldResultsBoostValues[item]}
                            onChange={handleOptionBoostChange}
                          />
                        </Col>
                      </Row>
                    </FormCardSpaced>
                  </div>
                ))
              : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default WidgetCustomFieldResults;
