import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddField,
  ConfirmModal,
  DrawerHeader,
  ExternalLink,
  FlexBinaryChoiceRow,
  FormCard,
  FormCardSpaced,
  Icon,
  Input,
  ProgressBar,
  SpinnerContainer,
  ToolTip,
  Typography,
  useToast,
} from 'modules/common';
import { computePageIcon, computePageIconColor, computePageTooltip } from '../ui.tools';
import { Breakpoint } from 'react-socks';
import { TemplateTableMobile } from './list-body/duplicate-templates/mobile.component';
import { TemplateTableDesktop } from './list-body/duplicate-templates/desktop.component';

import { Form } from 'react-bootstrap';
import { DELETE_ORGANIZATION_DUPLICATION_TEMPLATE } from 'core/middleware/queries/pages/templates.organization.queries';
import { DELETE_CANDIDATE_DUPLICATION_TEMPLATE } from 'core/middleware/queries/pages/templates.queries';
import { useMutation } from '@apollo/client';
import { noop } from 'lodash';

export const DuplicatePageBody = ({
  onClose,
  page,
  items,
  refetch,
  onCheck,
  selectedTemplates,
  onToggleAll,
  onChange,
  onShowTemplate,
  isOrganization,
  profileCandidate,
  pageStatus,
  setPageStatus,
  loading,
  progressInterval,
  requestAttempt,
}) => {
  const icon = computePageIcon(page);
  const iconColor = computePageIconColor(page);
  const toolTip = computePageTooltip(page);
  const [toggleAll, setToggleAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const setToast = useToast();
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleCheckToggleAll = ({ target: { checked } }) => {
    setToggleAll(checked);
    onToggleAll(checked);
  };

  const [deleteBulkPageTemplate] = useMutation(
    isOrganization ? DELETE_ORGANIZATION_DUPLICATION_TEMPLATE : DELETE_CANDIDATE_DUPLICATION_TEMPLATE
  );

  const onDeleteTemplate = async () => {
    const variables = {
      revvUid: selectedTemplate.revv_uid,
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };

    try {
      const response = await deleteBulkPageTemplate({ variables });
      const { errors, message } =
        response.data.candidateDeletePageDuplicationTemplate || response.data.organizationDeletePageDuplicationTemplate;
      setSelectedTemplate({});
      toggleDeleteModal();
      if (errors && errors.length > 0) {
        setToast({ message: errors, isError: true });
        return;
      }

      setToast({ message });
      refetch();
    } catch (error) {
      setToast({ message: 'There has been an error deleting your page template.', isError: true });
      toggleDeleteModal();
      console.error(error);
    }
  };

  const handleSingleCheck = (value, id) => {
    onCheck(value, id);
  };

  const dropdownAction = (eventKey, template) => {
    switch (eventKey) {
      case 'edit':
        onShowTemplate(true, template);
        break;
      case 'delete':
        setSelectedTemplate(template);
        toggleDeleteModal();
        break;
      default:
        break;
    }
  };

  const duplicateListProps = {
    items,
    dropdownAction,
    onCheck: handleSingleCheck,
    selectedTemplates,
    onChange,
  };

  if (loading) {
    let loadingText =
      requestAttempt === 0 ? `Processing ${selectedTemplates.length} Page(s)...` : 'Finishing page duplication....';
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-42 h-42">
          <SpinnerContainer />
        </div>
        <div className="w-8/12 sm:w-4/12  mt-4">
          <Typography variant="bodyMedium" className="text-center text-xl">
            {loadingText}
          </Typography>
        </div>
        <div className="w-8/12 sm:w-3/12">
          {requestAttempt === 0 ? (
            <ProgressBar
              color="#3d59ff"
              min={0}
              max={selectedTemplates.length}
              now={progressInterval}
              borderRadius="1rem"
              height="8px"
            />
          ) : null}
        </div>
        <div className="mt-2 w-10/12 sm:w-6/12">
          <Typography className="mb-0 text-center">
            Duplication in progress. This could take a few minutes. <br /> If you close this modal, the duplication
            process will continue in the background. You will receive an email when this is complete.&nbsp;
            <ExternalLink href="https://support.winred.com/en/articles/9338955-bulk-page-duplication">
              Learn More
            </ExternalLink>
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <>
      <DrawerHeader toggleOpen={onClose}>
        <div>
          <Typography variant="h2" className="mb-0">
            Bulk Duplicate Pages
          </Typography>
          <Typography className="mb-0">
            Duplicate pages will only have bill-pays attached to the current organization you are in.&nbsp;
            <ExternalLink href="https://support.winred.com/en/articles/9338955-bulk-page-duplication">
              Learn More
            </ExternalLink>
          </Typography>
        </div>
      </DrawerHeader>

      <FormCard className="mb-2" classNameBody="py-3 px-4">
        <div className="flex flex-col">
          <div className="">
            <Typography variant="bodyMedium" className="c--mirage mb-2">
              Page that is being duplicated.
            </Typography>
          </div>
          <div className="flex items-center">
            <ToolTip tooltip={toolTip}>
              <Icon icon={icon} color={iconColor} className="mr-3 text-xl" />
            </ToolTip>
            {page?.backgroundAssignedImage ? (
              <div
                className="page-image-rectangle"
                style={{
                  backgroundImage: `url(${page?.backgroundAssignedImage?.image?.thumbnailUrl})`,
                }}
              ></div>
            ) : (
              <div className="page-image-rectangle" style={{ backgroundColor: page?.backgroundColor }}></div>
            )}
            <div className="ml-2">
              <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
                {page?.internalName || ''}
              </Typography>
              <Typography className="mb-0">{page?.slug || ''}</Typography>
            </div>
          </div>
        </div>
      </FormCard>
      <FormCardSpaced className="mb-3">
        <FlexBinaryChoiceRow
          name="pageStatus"
          title="Page Status of New Pages"
          subTitle="Set the status for these new pages."
          value={pageStatus}
          onChange={e => setPageStatus(e.target.value)}
          trueText="Live"
          falseText="Draft"
        />
      </FormCardSpaced>

      <div className="mt-4">
        <div className="flex items-center ml-3 mb-4">
          <Form.Check type="checkbox" checked={toggleAll} onChange={handleCheckToggleAll} />
          <Typography className="tracking-wide uppercase ml-2 text-xs mb-0">Select All</Typography>
        </div>
        <div className="ml-2 mb-2">
          <Input
            hidden
            value={selectedTemplates.length > 0 ? true : ''}
            onChange={noop}
            validators={['required']}
            errorMessages={['At least one selected page template is required.']}
          />
          <Input
            hidden
            value={selectedTemplates.length}
            onChange={noop}
            validators={[() => selectedTemplates.length <= 100]}
            errorMessages={['Template amount cannot be over 100.']}
          />
        </div>
        <Breakpoint small down>
          <TemplateTableMobile {...duplicateListProps} />
        </Breakpoint>
        <Breakpoint medium up>
          <TemplateTableDesktop {...duplicateListProps} />
        </Breakpoint>
      </div>

      <AddField onClick={() => onShowTemplate(false)} text="Create Page Duplication Template" className="mt-4" />
      <ConfirmModal
        show={showDeleteModal}
        title="Delete Duplication Template"
        buttonText="Delete"
        handleClose={toggleDeleteModal}
        buttonColor="cancel"
        onSubmit={onDeleteTemplate}
        showCancelButton
        onCancel={toggleDeleteModal}
      >
        Are you sure you want to delete this duplication template?
      </ConfirmModal>
    </>
  );
};

DuplicatePageBody.propTypes = {
  onClose: PropTypes.func.isRequired,
  page: PropTypes.object,
  items: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  selectedTemplates: PropTypes.array.isRequired,
  onToggleAll: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onShowTemplate: PropTypes.func.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  pageStatus: PropTypes.bool.isRequired,
  setPageStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  progressInterval: PropTypes.number.isRequired,
  requestAttempt: PropTypes.number.isRequired,
};
