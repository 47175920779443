import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

const SpinnerContainer = ({ overlay = false, fixed = false, className = '' }) => (
  <Container fluid className="flex-grow-1">
    <div className={classnames('main-data-spinner', className, { 'spinner-overlay': overlay, 'spinner-fixed': fixed })}>
      {/* .spinner-border is a bootstrap class for the spinner */}
      <div className="spinner-border height-6 width-6 text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </Container>
);

SpinnerContainer.propTypes = {
  overlay: PropTypes.bool,
  fixed: PropTypes.bool,
  className: PropTypes.string,
};

export default SpinnerContainer;
