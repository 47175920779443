import React from 'react';
import PropTypes from 'prop-types';

import { FlexBinaryChoiceRow } from 'modules/common';

function CustomFieldRequired({ onChange, value, disabled }) {
  return (
    <FlexBinaryChoiceRow
      title="Require Field"
      subTitle="Select whether this field is required."
      value={value}
      name="required"
      onChange={onChange}
      disabled={disabled}
    />
  );
}

CustomFieldRequired.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default CustomFieldRequired;
