export const DASHBOARD_CHILD_PATHS = ['/dashboard'];
export const PAGES_CHILD_PATHS = [
  '/pages/my-pages',
  '/pages/connected-pages',
  '/pages/campaigns',
  '/pages/upsell-flows',
  '/pages/upsell-flows/upsells',
  '/pages/petition-pathways',
  '/pages/petition-pathways/pathways',
  '/pages/new-upsell-flow',
  '/pages/upsell-pathways',
  '/pages/new-upsell-pathway',
  '/pages/upsell-pathways/upsells',
  '/pages/upsell-pathways/conditions',
];

export const PEOPLE_CHILD_PATHS = ['/people/donors', '/people/leads'];
export const REVENUE_CHILD_PATHS = ['/revenue/donations', '/revenue/subscriptions', '/revenue/money-pledges'];
export const ACCOUNTING_CHILD_PATHS = [
  '/accounting/ledger',
  '/accounting/payouts',
  '/accounting/invoices',
  '/accounting/blocked',
  '/accounting/transfers',
  '/accounting/notifications/payouts',
];
export const UTILITIES_CHILD_PATHS = [
  '/utilities/emails',
  '/utilities/notifications',
  '/utilities/integrations',
  '/utilities/exports',
  '/utilities/media',
  '/utilities/custom-reports',
  '/utilities/bulk-actions',
  '/utilities/ab-testing',
];
export const STOREFRONT_CHILD_PATHS = ['/merchandise/products', '/merchandise/orders', '/merchandise/settings'];

export const BILLPAY_CHILD_PATHS = ['/billpays/pages', '/billpays/upsells'];
