import cloneDeep from 'lodash/cloneDeep';
import uuid from 'core/utilities/uuid';

import CampaignDetailsMerchandise from './campaign-details-merchandise.component';
import CampaignDetailsStandard from './campaign-details-standard.component';
import { formatAmount } from 'core/utilities';
import { showMultiplyDonationAmounts } from './segements/fields';

export const generateNewProduct = () => {
  return {
    id: uuid(),
    _isNew: true,
    name: '',
    merchandise_minimum_price_cents: '',
    merch_product: {
      name: null,
      revv_uid: null,
    },
  };
};

export const CAMPAIGN_NAME_MAP = {
  STANDARD: {
    title: 'Standard Campaign',
    subtitle: 'subtitle',
    component: CampaignDetailsStandard,
    new: {
      initiative_type: 'STANDARD',
      name: '',
      showCampaignSegments: false,
      campaignSegments: [],
    },
  },
  MERCHANDISE: {
    title: 'Merchandise Campaign',
    subtitle: 'subtitle',
    component: CampaignDetailsMerchandise,
    new: {
      initiative_type: 'MERCHANDISE',
      name: '',
      shipping_disclaimer: '',
      merch_products: [generateNewProduct()],
      showCampaignSegments: false,
      campaignSegments: [],
    },
  },
};

export const getProductOptions = data => {
  if (!data.viewer) return { data };
  const candidate = data.viewer && (data.viewer.candidate || data.viewer.state_level_organization);
  const productFieldOptions = candidate.products.map(p => ({
    label: p.name,
    value: p.revv_uid,
    price_cents: parseInt(p.price_cents || 0).toFixed(2),
    variants: p.variants,
    variants_count: p.variants_count,
  }));

  return { data, productFieldOptions };
};

export const formatCampaignForServer = (campaign, profileCandidate) => {
  const newCampaign = {
    emailBodyText: campaign.email_body_text,
    initiativeType: campaign.initiative_type,
    name: campaign.name,
    organizationRevvUid: profileCandidate.organization_revv_uid,
    recurringDonationRequired: campaign.recurring_donation_required
  };

  if (!campaign._isNew) newCampaign.revv_uid = campaign.revv_uid;
  if (campaign.shipping_disclaimer) newCampaign.shippingDisclaimer = campaign.shipping_disclaimer;

  // format merch products
  if (campaign.initiative_type === CAMPAIGN_NAME_MAP.MERCHANDISE.new.initiative_type) {
    newCampaign.merchProducts = campaign.merch_products
      .map(product => {
        const newProduct = {
          merchProductRevvUid: product.merch_product.revv_uid,

          // get in cents but needs to go back in dollars
          merchandiseMinimum: parseFloat(product.merchandise_minimum_price_cents) / 100,
        };

        if (!product._isNew) newProduct.id = product.id;
        if (product._isNew && product._destroy) return null;
        if (!product._isNew && product._destroy) newProduct.destroy = true;

        return newProduct;
      })
      .filter(v => Boolean(v));
  }
  if (campaign?.campaignSegments.length > 0) {
    newCampaign.campaignSegments = formatCampaignSegmentsForServer(campaign.campaignSegments);
    if (!campaign.showCampaignSegments) {
      newCampaign.campaignSegments = [...newCampaign.campaignSegments].map(segment => {
        return { ...segment, _destroy: true };
      });
    }
  } else {
    newCampaign.campaignSegments = [];
  }

  return newCampaign;
};

export const generateNewCampaign = campaignType => {
  let newCampaign = { revv_uid: uuid(), _isNew: true };

  if (/standard/i.test(campaignType)) {
    newCampaign = { ...newCampaign, ...cloneDeep(CAMPAIGN_NAME_MAP.STANDARD.new) };
  }

  if (/merchandise/i.test(campaignType)) {
    newCampaign = { ...newCampaign, ...cloneDeep(CAMPAIGN_NAME_MAP.MERCHANDISE.new) };
  }

  return newCampaign;
};

export const validCampaignTypes = ['merchandise', 'standard'];

export const getMinimumPriceCents = product => {
  let biggestValue = parseInt(formatAmount(product.price_cents, true, false, false));
  if (product.variants.length > 0) {
    product.variants.forEach(variant => {
      if (variant.variant_price > biggestValue) {
        biggestValue = variant.variant_price;
      }
    });
  }
  return biggestValue;
};

export const formatDynamicValuesFromServer = (valueString, _isMultiplier = true) => {
  let formattedDynamicValues = valueString.split(',').map(item => {
    return {
      amount: item,
      description: '',
      _isMultiplier,
      id: uuid(),
      preselected: false,
    };
  });
  return formattedDynamicValues;
};

export const generateNewCampaignSegment = () => {
  return {
    id: uuid(),
    _isNew: true,
    _isCardOpen: true,
    daysSinceLastDonation: '0',
    donationCount: '0',
    donationCountFrom: 0,
    donationCountFromDays: '0',
    showOtherField: true,
    useControlGroup: true,
    refreshTriggerCount: '0',
    refreshTriggerIntervalSeconds: '0',
    amountArrayString: formatDynamicValuesFromServer('0.5,0.75,1,1.25,1.5,1.75,2'),
    totalVolume: 0,
    otherAmount: 0,
    amountArrayType: 'AVERAGE_CONTRIBUTION',
    defaultAmountIndex: 100,
    controlGroupPercentage: 25,
  };
};

export const formatAmountArrayStringForServer = amountArrayString => {
  let formattedAmountArrayString = '';
  amountArrayString
    .filter(item => !item._destroy)
    .map((item, index) => {
      if (index === 0) {
        return (formattedAmountArrayString += item.amount);
      } else {
        return (formattedAmountArrayString += `,${item.amount}`);
      }
    });
  return formattedAmountArrayString;
};

export const formatCampaignSegmentsForServer = campaignSegments => {
  let formattedCampaignSegments = [...campaignSegments]
    .filter(item => {
      return !(item?._isNew && item?._destroy);
    })
    .map(segment => {
      let newSegment = {
        ...segment,
        amountArrayString: formatAmountArrayStringForServer(segment.amountArrayString),
        defaultAmountIndex: parseInt(segment.defaultAmountIndex),
        otherAmount: parseFloat(segment.otherAmount),
        controlGroupPercentage: parseFloat(segment.controlGroupPercentage).toFixed(4) / 100,
      };
      if (segment._isNew) {
        delete newSegment.id;
      } else {
        delete newSegment?.segmentControlStats;
        delete newSegment?.segmentVariantStats;
      }
      delete newSegment._isCardOpen;
      delete newSegment._isNew;
      delete newSegment.revvUid;
      return newSegment;
    });
  return formattedCampaignSegments;
};

export const formatCampaignSegmentsForUi = segments => {
  let formattedSegments = segments?.map(item => {
    return {
      id: item.id,
      revvUid: item.revv_uid,
      daysSinceLastDonation: item.days_since_last_donation,
      donationCount: item.donation_count,
      donationCountFrom: item.donation_count_from,
      donationCountFromDays: item.donation_count_from_days,
      showOtherField: item.show_other_field,
      refreshTriggerCount: item.refresh_trigger_count,
      refreshTriggerIntervalSeconds: item.refresh_trigger_interval_seconds,
      amountArrayString: formatDynamicValuesFromServer(
        item.amount_array_string,
        showMultiplyDonationAmounts.includes(item.amount_array_type)
      ),
      totalVolume: parseInt(item.total_volume),
      otherAmount: parseFloat(item.other_amount),
      amountArrayType: item.amount_array_type,
      defaultAmountIndex: item.default_amount_index,
      position: item.position,
      segmentControlStats: item.segment_control_stats,
      segmentVariantStats: item.segment_variant_stats,
      controlGroupPercentage: parseFloat(item.control_group_percentage) * 100,
      useControlGroup: item.use_control_group,
      _isCardOpen: false,
    };
  });
  return formattedSegments;
};

export const formatCampaignForUi = campaign => {
  let formattedCampaign = { ...campaign, campaignSegments: formatCampaignSegmentsForUi(campaign?.campaign_segments) };
  formattedCampaign.showCampaignSegments = formattedCampaign?.campaignSegments?.length > 0;
  delete formattedCampaign.campaign_segments;
  return formattedCampaign;
};
