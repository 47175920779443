import React, { useState, Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuid from 'core/utilities/uuid';

const _getItemStyle = (isDragging, draggableStyle) => {
  return {
    ...draggableStyle,
    zIndex: 100000,
    marginBottom: '1rem',
  };
};

const getListStyle = () => ({});

const portal = document.createElement('div');
portal.classList.add('winred-drag-drop-portal');
document.body.appendChild(portal);

class PortalAwareItem extends Component {
  render() {
    const { item, onRemove, onEdit, index, getItemStyle, provided, snapshot, toggleDraggable } = this.props;
    const { isDragging } = snapshot;

    const child = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(isDragging, provided.draggableProps.style)}
      >
        {this.props.children({ item, onRemove, onEdit, index, isDragging, toggleDraggable })}
      </div>
    );

    if (!isDragging) return child;
    return ReactDOM.createPortal(child, portal);
  }
}

function DraggableItem({
  item,
  index,
  onEdit,
  onRemove,
  draggableProps,
  getItemStyle,
  getDraggableDefault,
  children,
  draggableId,
}) {
  const [isDragDisabled, setDragDisabled] = useState(getDraggableDefault(item));

  return (
    <Draggable draggableId={draggableId} index={index} {...draggableProps} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <PortalAwareItem
          provided={provided}
          snapshot={snapshot}
          item={item}
          onRemove={onRemove}
          onEdit={onEdit}
          index={index}
          getItemStyle={getItemStyle}
          toggleDraggable={setDragDisabled}
        >
          {children}
        </PortalAwareItem>
      )}
    </Draggable>
  );
}

PortalAwareItem.propTypes = {
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired,
  isDragging: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  index: PropTypes.number,
  getItemStyle: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

function DragDrop({
  items,
  children,
  onRemove,
  onEdit,
  onUpdate,
  name,
  disabledItems,
  dragContextProps,
  droppableProps,
  draggableProps,
  reorder,
  getItemStyle,
  droppableId,
  getDraggableDefault,
}) {
  const _reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    // allow for custom reorder function
    const reorderFunc = reorder || _reorder;
    const newOrder = reorderFunc(items, result.source.index, result.destination.index);
    onUpdate({ target: { name: name, value: newOrder } });
  };

  const draggableItems = items?.filter(item => !disabledItems.includes(item));

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd} {...dragContextProps}>
        <Droppable droppableId={droppableId} {...droppableProps}>
          {(provided, snapshot) => (
            <div
              {...(provided.droppableProps || {})}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {draggableItems?.map((item, index) => {
                const draggableId =
                  item.candidate?.revv_uid?.toString() ||
                  item.candidate?.id?.toString() ||
                  item.revv_uid?.toString() ||
                  item.id?.toString() ||
                  item.id?.toString();

                return (
                  <DraggableItem
                    key={draggableId}
                    draggableId={draggableId}
                    index={index}
                    draggableProps={draggableProps}
                    item={item}
                    onRemove={onRemove}
                    onEdit={onEdit}
                    getItemStyle={getItemStyle}
                    getDraggableDefault={getDraggableDefault}
                  >
                    {children}
                  </DraggableItem>
                );
              })}
              {provided.placeholder}

              {disabledItems?.map(item => (
                <div key={item.id || item.revv_uid} className="mb-3">
                  {children({ item, onRemove, onEdit, sortingDisabled: true })}
                </div>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

DragDrop.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  reorder: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabledItems: PropTypes.array,
  dragContextProps: PropTypes.object,
  droppableProps: PropTypes.object,
  draggableProps: PropTypes.object,
  getItemStyle: PropTypes.func,
  getDraggableDefault: PropTypes.func,
  droppableId: PropTypes.string,
};

DragDrop.defaultProps = {
  disabledItems: [],
  dragContextProps: {},
  droppableProps: {},
  draggableProps: {},
  getItemStyle: _getItemStyle,
  droppableId: uuid(),
  getDraggableDefault: () => false,
};

export default DragDrop;
