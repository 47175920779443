import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

function Product() {
  return (
    <FormCard title="Product Upsells are not editable using WinRed’s Committee Portal. Use the WinRed Power Portal instead."></FormCard>
  );
}

Product.title = 'Product';
Product.description = '';
Product.icon = 'upsell-product';
Product.type = 'PRODUCT';
Product.widgets = [];

Product.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default Product;
