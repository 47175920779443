import gql from 'graphql-tag';

export const GET_LEAD_OPTIONS = gql`
  query getLeadOptions($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        lead_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_OPTIONS = gql`
  query getSubsciptionOptions($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        subscription_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_LEAD_OPTIONS = gql`
  query getOrganizationLeadOptions($organizationRevvUid: String) {
    viewer {
      vendors(revvUid: $organizationRevvUid) {
        lead_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_SUBSCRIPTION_OPTIONS = gql`
  query getOrganizationSubsciptionOptions($organizationRevvUid: String) {
    viewer {
      vendors(revvUid: $organizationRevvUid) {
        subscription_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export const GET_VENDOR_LEAD_OPTIONS = gql`
  query getVendorLeadOptions($organizationRevvUid: String) {
    viewer {
      vendors(revvUid: $organizationRevvUid) {
        lead_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export const GET_VENDOR_SUBSCRIPTION_OPTIONS = gql`
  query getVendorSubsciptionOptions($organizationRevvUid: String) {
    viewer {
      vendors(revvUid: $organizationRevvUid) {
        subscription_additional_data_options {
          name
          value
        }
      }
    }
  }
`;

export * from './integrations.queries';
export * from './integration.queries';
export * from './webhook.queries';
export * from './isp.queries';
export * from './iterable.queries';
