import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import { Typography, FormCard, Select, Card, Icon } from 'modules/common';

function UpsellPresetSelector({ updateUpsellCard, upsellCard, dropdownOptions, removeUpsellCard }) {
  // internal state to make select dropdown update - dont need this value outside this component
  const [selected, setSelected] = useState(null);

  const _updateUpsellCard = ({ target: { value } }) => {
    setSelected(value);
    updateUpsellCard({ target: { name: 'type', value } }, upsellCard);
  };

  const removeCollapsibleCard = event => {
    event.preventDefault();
    event.stopPropagation();
    removeUpsellCard(upsellCard);
  };

  return (
    <FormCard>
      <Container fluid>
        <Row>
          <Col xs={12} md={5}>
            <Card.Title>Select Preset Panel Card</Card.Title>
            <Typography className="mb-3">
              Select which preset panel card you would like to display on your panel.
            </Typography>
          </Col>

          <Col xs={12} md={7}>
            <Row className="mb-2">
              <Col xs={11}>
                <Select
                  placeholder="Select Preset Panel Card..."
                  name="type"
                  onChange={_updateUpsellCard}
                  value={selected}
                  disabled={upsellCard._isDisabled}
                  errorMessageClasses="position-absolute"
                  options={dropdownOptions}
                  validators={['required']}
                  errorMessages={['Selecting a preset panel card is required.']}
                />
              </Col>
              {upsellCard._isDisabled ? null : (
                <Col xs="auto" className="d-flex align-items-center pl-0">
                  <div
                    className="mb-3 pointer no-outline"
                    onClick={removeCollapsibleCard}
                    onKeyPress={removeCollapsibleCard}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon icon="times" color="gray" size="lg" />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </FormCard>
  );
}

UpsellPresetSelector.propTypes = {
  updateUpsellCard: PropTypes.func.isRequired,
  removeUpsellCard: PropTypes.func.isRequired,
  upsellCard: PropTypes.object.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  upsellCards: PropTypes.array.isRequired,
};

export default UpsellPresetSelector;
