import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

function MatchPro() {
  return (
    <FormCard title="MatchPro Upsells are not editable using WinRed’s Committee Portal. Use the WinRed Power Portal instead."></FormCard>
  );
}

MatchPro.title = 'MatchPro';
MatchPro.description = '';
MatchPro.icon = 'upsell-matchpro';
MatchPro.type = 'MATCHPRO';
MatchPro.widgets = [];

MatchPro.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default MatchPro;
