import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'modules/common';
import { TIMEZONES } from './timezones.tool';

export function TimezoneInput({ candidate, onChangeCandidate, description = '' }) {
  const selectedTimezone = useMemo(() => TIMEZONES.find(y => y.value === candidate?.timezone), [candidate?.timezone]);

  return (
    <Select
      isSearchable
      onChange={onChangeCandidate}
      name="timezone"
      floatLabel="Timezone"
      placeholder="Select Timezone..."
      value={selectedTimezone}
      options={TIMEZONES}
      validators={['required']}
      errorMessages={['Timezone is required.']}
      description={description}
    />
  );
}

TimezoneInput.propTypes = {
  candidate: PropTypes.object.isRequired,
  onChangeCandidate: PropTypes.func.isRequired,
};

export default TimezoneInput;
