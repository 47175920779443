import React from 'react';
import PropTypes from 'prop-types';
import { FormCardSpaced } from 'modules/common';
import { AcceptButton, UpsellVideoEmbed, EnhancedUpsell, UpsellName, UpsellAction, Widgets } from './sections';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';

function CreateUpsell(props) {
  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
        <AcceptButton {...props} />
      </FormCardSpaced>

      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />

      <Widgets {...props} widgetOptions={CreateUpsell.widgets} />
    </>
  );
}

CreateUpsell.title = 'Create Account';
CreateUpsell.description = 'Ask donors to create an account on WinRed.';
CreateUpsell.icon = 'upsell-create-account';
CreateUpsell.type = 'PASSWORD';
CreateUpsell.widgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

CreateUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default CreateUpsell;
