import React from 'react';
import uuid from 'core/utilities/uuid';
import {
  CustomFieldText,
  CustomFieldToggleText,
  CustomFieldCheckbox,
  CustomFieldChoice,
  CustomFieldParagraph,
} from './field-types';

import {
  CustomFieldTextPreview,
  CustomFieldToggleTextPreview,
  CustomFieldCheckboxPreview,
  CustomFieldParagraphPreview,
  CustomFieldRadioPreview,
  CustomFieldMultiChoicePreview,
} from './field-previews';

export const customFieldTypes = [
  { value: 'CHECK_BOX', label: 'Check Box' },
  { value: 'HIDDEN_FIELD', label: 'Hidden' },
  { value: 'INFORMATION', label: 'Paragraph' },
  { value: 'MULTIPLE_CHOICE', label: 'Multiple Choice' },
  { value: 'NUMBER_FIELD', label: 'Number Field' },
  { value: 'RADIO_BUTTONS', label: 'Radio Buttons' },
  { value: 'TEXT_AREA', label: 'Text Area' },
  { value: 'TEXT_FIELD', label: 'Text Field' },
  { value: 'TEXT_FIELD_TOGGLE', label: 'Text Field Toggle' },
];

export const isInvalidCustomFields = newState => {
  const invalidCustomFields = !!newState.page.customFields.find(cf => {
    if (cf.destroy) return false; // ignore any CFs we are destroying

    if (!cf.name) return true;
    if (!cf.position) return true;
    if (!cf.form_section) return true;
    if (!cf.input_type) return true;

    if (!['INFORMATION', 'HIDDEN_FIELD'].includes(cf.input_type) && !cf.label) return true;

    // for multi inputs - we have to have at least one option and it need to have a value
    if (['MULTIPLE_CHOICE', 'RADIO_BUTTONS'].includes(cf.input_type)) {
      if (cf.options.length === 0) return true;
      const emptyOptions = cf.options.filter(opt => !opt.value).length !== 0;
      if (emptyOptions) return true;
    }

    return false;
  });

  return invalidCustomFields;
};

export const formatCustomFieldsForUi = customFields => {
  return (customFields || []).map(cf => {
    cf.fromExisting = true;
    cf.originalPageCf = true; // mark that these were already on the page

    // if multi types then split default_values to options array and clear out default_value
    // we will convert back on send to server (default_value is used as csv for options on multi based inputs)
    if (['MULTIPLE_CHOICE', 'RADIO_BUTTONS'].includes(cf.input_type)) {
      cf.options = (cf.default_value || '').split(',').map(option => ({ key: option, value: option }));
      cf.default_value = '';
    }

    if (cf.input_type === 'CHECK_BOX') {
      cf.default_value = cf.default_value === 'true';
    }

    return cf;
  });
};

/**
 * returns the chosen custom field type UI
 */
export function chooseTab({ selectedCustomField, updateCustomField, disabled }) {
  let sideBar = null;
  let fieldEditor = null;

  const previewProps = {
    key: selectedCustomField.input_type,
    formState: selectedCustomField,
  };

  const customFieldProps = {
    key: selectedCustomField.input_type,
    formState: selectedCustomField,
    updateCustomField,
    disabled,
  };

  // need key on components to force rerender if input_type has
  // chanced else react will try to reuse the same component
  switch (selectedCustomField.input_type) {
    case 'TEXT_FIELD':
      sideBar = <CustomFieldTextPreview {...previewProps} />;
      fieldEditor = <CustomFieldText {...customFieldProps} />;
      break;

    case 'TEXT_FIELD_TOGGLE':
      sideBar = <CustomFieldToggleTextPreview {...previewProps} />;
      fieldEditor = <CustomFieldToggleText {...customFieldProps} />;
      break;

    case 'NUMBER_FIELD':
      sideBar = <CustomFieldTextPreview {...previewProps} isNumberField />;
      fieldEditor = <CustomFieldText {...customFieldProps} isNumberField />;
      break;

    case 'TEXT_AREA':
      sideBar = <CustomFieldTextPreview {...previewProps} textArea />;
      fieldEditor = <CustomFieldText {...customFieldProps} textArea />;
      break;

    case 'HIDDEN_FIELD':
      sideBar = <CustomFieldTextPreview {...previewProps} isHiddenField />;
      fieldEditor = <CustomFieldText {...customFieldProps} isHiddenField />;
      break;

    case 'CHECK_BOX':
      sideBar = <CustomFieldCheckboxPreview {...previewProps} />;
      fieldEditor = <CustomFieldCheckbox {...customFieldProps} />;
      break;

    case 'MULTIPLE_CHOICE':
      sideBar = <CustomFieldMultiChoicePreview {...previewProps} />;
      fieldEditor = <CustomFieldChoice {...customFieldProps} />;
      break;

    case 'RADIO_BUTTONS':
      sideBar = <CustomFieldRadioPreview {...previewProps} />;
      fieldEditor = <CustomFieldChoice {...customFieldProps} />;
      break;

    case 'INFORMATION':
      sideBar = <CustomFieldParagraphPreview {...previewProps} />;
      fieldEditor = <CustomFieldParagraph {...customFieldProps} />;
      break;

    default:
      sideBar = null;
      fieldEditor = null;
  }

  return [sideBar, fieldEditor];
}

export function generateNewCustomField(fromExisting, isPetitionPage) {
  return {
    page_custom_field_id: uuid(),
    name: '',
    form_section: isPetitionPage ? 'PETITION' : 'DONATION',
    position: 'BEFORE',
    input_type: '',
    default_value: '',
    placeholder: '',
    label: '',
    required: false,
    isNew: true,
    fromExisting,
    options: [],
    other_field_enabled: false
  };
}
