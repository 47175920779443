import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAmplitude } from 'modules/common';

export default function useFilter({ filter, listKey, setReduxFilter, initialState }) {
  const [state, setState] = useState({ ...filter });
  const dispatch = useDispatch();
  const [logEvent] = useAmplitude();

  /**
   * logs the filter state to amplitude
   */
  const logFilterEvent = useCallback(() => logEvent(`filter ${listKey}`, state), [listKey, logEvent, state]);

  /**
   * reset internal state and redux state
   */
  const clearFilter = useCallback(() => {
    const resetState = { ...initialState };
    setState(resetState);
    logFilterEvent();
    dispatch(setReduxFilter(resetState));
  }, [logFilterEvent, setReduxFilter, initialState, dispatch]);

  /**
   * take the current filter and submits it to redux so gql query is triggered
   */
  const _submitFilter = useCallback(
    (event, overrideState) => {
      event?.preventDefault && event.preventDefault();
      event?.preventDefault && event.stopPropagation();

      const overrideStateIsBool = typeof overrideState === 'boolean';

      logFilterEvent();
      dispatch(setReduxFilter(overrideStateIsBool ? state : overrideState || state));
    },
    [logFilterEvent, setReduxFilter, state, dispatch]
  );

  /**
   * change filter state. optionally save to redux/trigger gql query as well via submitFilter flag
   */
  const onChangeFilter = useCallback(
    ({ target: { name, value } }, triggerFilter = false) => {
      setState(oldState => {
        const newState = {
          ...oldState,
          page: 1,
        };

        // if given some more values add that (can be single value or array of values)
        if (name) {
          if (Array.isArray(name)) {
            for (let i = 0; i < name.length; i++) {
              newState[name[i]] = value[i];
            }
          } else {
            newState[name] = value;
          }
        }

        if (triggerFilter) _submitFilter(null, newState);
        return newState;
      });
    },
    [_submitFilter, setState]
  );

  return {
    onChangeFilter,
    submitFilter: _submitFilter,
    clearFilter,
    filterState: state,
  };
}
