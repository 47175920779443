import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';
import '../common.scss';

function InfoCardData({ data }) {
  return <Typography variant="bodyMedium">{data}</Typography>;
}

InfoCardData.propTypes = {
  data: PropTypes.any,
};

export default React.memo(InfoCardData);
