import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import '../../widgets.scss';
import { Typography } from 'modules/common';
import { getSecondsBeforeMidnight } from 'core/utilities';

function WidgetPreviewMidnight({ widget }) {
  const secondsLeft = getSecondsBeforeMidnight();
  const [timer, setTimer] = useState(secondsLeft);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) setTimer(timer - 1);
    }, 1000);

    if (timer === 0) clearInterval(intervalId);
    return () => intervalId && clearInterval(intervalId);
  }, [setTimer, timer]);

  const hours = Math.floor(timer / 3600).toFixed(0);
  const tensHours = Math.floor(hours / 10).toFixed(0);
  const onesHours = Math.floor(hours % 10).toFixed(0);

  const minutes = Math.floor(timer / 60) % 60;
  const tensMinutes = Math.floor(minutes / 10).toFixed(0);
  const onesMinutes = Math.floor(minutes % 10).toFixed(0);

  const seconds = (timer % 60).toFixed(0);
  const tensSeconds = Math.floor(seconds / 10).toFixed(0);
  const onesSeconds = Math.floor(seconds % 10).toFixed(0);

  return (
    <Container className="widget-preview_midnight">
      <Typography>{widget.countdownToMidnightLabel}</Typography>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <>
            <div>
              <div className="countdown-numbers midnight-numbers">
                <div>{tensHours}</div>
                <div>{onesHours}</div>
              </div>
              <div className="countdown-label">HOURS</div>
            </div>
            <div className="countdown-seperator">:</div>
          </>
          <div>
            <div className="countdown-numbers midnight-numbers">
              <div>{tensMinutes}</div>
              <div>{onesMinutes}</div>
            </div>
            <div className="countdown-label">MINUTES</div>
          </div>
          <div className="countdown-seperator">:</div>
          <div>
            <div className="countdown-numbers midnight-numbers">
              <div>{tensSeconds}</div>
              <div>{onesSeconds}</div>
            </div>
            <div className="countdown-label">SECONDS</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewMidnight.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default WidgetPreviewMidnight;
