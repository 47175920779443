import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import classNames from 'classnames';

import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';

import { withAmplitude, AmplitudePropTypes } from 'modules/common';
import { setToast } from 'core/toast';

import logo from 'assets/images/winred-logo.svg';
import logoMobile from 'assets/images/winred-shield.svg';

import Logout from '../logout.component';
import UserMenu from './profileMenus/user-menu.component';
import './nav-bar.scss';

const DONOR_PATHS = ['/team-pages/pages', '/team-pages/donations', '/logout', '/'];

class NavBarDonor extends Component {
  state = {
    showLogoutModal: false,
  };

  setNavMenuRef = node => (this.navMenuRef = node);

  handleSelect = (eventKey, event, isExternal) => {
    event.preventDefault();
    if (!eventKey) return;

    // log click event in amplitude
    let eventName = ((event && event.target && event.target.innerHTML) || '').toLowerCase();
    if (eventName.includes('settings')) {
      eventName = 'settings';
    } else if (event.target.getAttribute('alt')) {
      eventName = event.target.getAttribute('alt');
    }

    this.props.logEvent(`navbar click ${eventName}`);

    if (isExternal) return window.open(eventKey, '_blank');

    if (eventKey === '/logout') {
      this.setState({ showLogoutModal: true });
      return;
    }

    this.props.history.push(eventKey);
  };

  getActivePath() {
    const { location } = this.props;
    return DONOR_PATHS.find(path => location.pathname.startsWith(path));
  }

  hasActiveChild(eventKeys) {
    let activePath = this.getActivePath();
    return !!eventKeys.find(eventKey => eventKey === activePath);
  }

  handleLogout = isLoggingOut => {
    this.setState({ showLogoutModal: false });
    if (isLoggingOut) this.props.history.push('/logout');
  };

  render() {
    const activePath = this.getActivePath();

    return (
      <div className="top-nav-bar" ref={this.setNavMenuRef}>
        <Logout handleLogout={this.handleLogout} showLogoutModal={this.state.showLogoutModal} />

        <div className="mainnav">
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="11" md="12">
                <Navbar expand="lg" className="winred-nav d-flex" onSelect={this.handleSelect}>
                  <Nav.Item className="mt-2 mt-lg-0">
                    <Nav.Link eventKey="/" className="winred-nav-logo">
                      <img src={logo} alt="logo" className="d-none d-xl-block" />
                      <img src={logoMobile} alt="logo" className="d-block d-xl-none mr-2" />
                    </Nav.Link>
                  </Nav.Item>

                  <Navbar.Collapse id="main-navbar" className="order-sm-12 order-2 ">
                    <Nav activeKey={activePath}>
                      <Nav.Item
                        className={classNames('winred-nav__nav-item', {
                          'winred-nav__nav-item--active': activePath.includes(DONOR_PATHS[0]),
                        })}
                      >
                        <Nav.Link
                          className={classNames('winred-nav__nav-link')}
                          eventKey="/team-pages/pages"
                          href="/team-pages/pages"
                        >
                          My Team Pages
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item
                        className={classNames('winred-nav__nav-item', {
                          'winred-nav__nav-item--active': activePath.includes(DONOR_PATHS[1]),
                        })}
                      >
                        <Nav.Link
                          className={classNames('winred-nav__nav-link')}
                          eventKey="/team-pages/donations"
                          href="/team-pages/donations"
                        >
                          Donations
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>

                  <div className="d-flex order-4">
                    <Navbar.Toggle title="committee dropdown options" />
                    <UserMenu />
                  </div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

NavBarDonor.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(withRouter, withAmplitude, connect(null, mapDispatchToProps))(NavBarDonor);
