export const SET_DONATIONS_FILTER = 'SET_DONATIONS_FILTER';
export const CLEAR_DONATIONS_FILTER = 'CLEAR_DONATIONS_FILTER';

export const SET_SUBSCRIPTIONS_FILTER = 'SET_SUBSCRIPTIONS_FILTER';
export const CLEAR_SUBSCRIPTIONS_FILTER = 'CLEAR_SUBSCRIPTIONS_FILTER';

export const SET_TEAM_DONATIONS_FILTER = 'SET_TEAM_DONATIONS_FILTER';
export const CLEAR_TEAM_DONATIONS_FILTER = 'CLEAR_TEAM_DONATIONS_FILTER';

export const SET_MONEYPLEDGE_FILTER = 'SET_MONEYPLEDGE_FILTER';
export const CLEAR_MONEYPLEDGE_FILTER = 'CLEAR_MONEYPLEDGE_FILTER';
