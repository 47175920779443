import produce from 'immer';

import { SET_TRANSACTIONS_FILTER, SET_PAYOUTS_FILTER, SET_INVOICES_FILTER, SET_BLOCKED_FILTER } from './types';
import { TRANSACTION_TYPE_OPTIONS } from '../transactions/transactions.tools';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialAccountingState = {
  transactionsFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    page: 1,

    fee: null,
    feePredicate: 'EQ',
    gross: null,
    grossPredicate: 'EQ',
    net: null,
    netPredicate: 'EQ',

    state: '',
    transactionType: [...TRANSACTION_TYPE_OPTIONS],
    contributorType: [],

    sortBy: null,
    reportType: null,
  },

  payoutsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    arrivalDateStart: null,
    arrivalDateEnd: null,

    status: null,
    amountPredicate: 'EQ',
    amount: null,
    page: 1,
  },

  invoicesFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amountPredicate: 'EQ',
    amount: null,
    page: 1,
  },
  blockedFilter: {
    search: null,
    page: 1,
  },
};

export default produce((state = initialAccountingState, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_FILTER:
      return { ...state, transactionsFilter: action.filter };
    case SET_PAYOUTS_FILTER:
      return { ...state, payoutsFilter: action.filter };
    case SET_INVOICES_FILTER:
      return { ...state, invoicesFilter: action.filter };
    case SET_BLOCKED_FILTER:
      return { ...state, blockedFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialAccountingState };
    default:
      return state;
  }
}, initialAccountingState);
