import React from 'react';
import PropTypes from 'prop-types';
import { DISPUTE_STATUSES, formatDisputeRate, getDisputeIcon } from './dashboard';
import { Col, Row } from 'react-bootstrap';

function DisputesTableMobileComponent({ disputeRates }) {
  const mobileDonationCards = disputeRates.map((dispute, index) => {
    return (
      <div className="list-item-card" tabIndex={index}>
        <Row>
          <Col xs={12} className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="list-item-card-icon-mobile">{getDisputeIcon(dispute.status)}</div>
              <div className="ml-3">
                <div className="list-item-card-text">{dispute.month}</div>
                <div className="list-item-card-text break-all">{formatDisputeRate(dispute)}</div>
                <div className="list-item-card-title">{DISPUTE_STATUSES[dispute.status]?.label}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  return mobileDonationCards;
}

DisputesTableMobileComponent.propTypes = {
  disputeRates: PropTypes.array.isRequired,
};

export { DisputesTableMobileComponent };
