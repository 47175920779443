import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { profileCandidateSelector } from 'core/login';

import { BillPayInfo } from './bill-pay-info';
import { BillPaySentenceActivity } from './sentence-view';

const BillPayUpsell = ({ revv_uid, upsellType, isPublic, upsellCreator, isNew }) => {
  const profileCandidate = useSelector(profileCandidateSelector);

  if (isPublic && profileCandidate.organization_revv_uid !== upsellCreator) return null;

  return (
    <>
      <BillPayInfo upsellType={upsellType} />

      {!isNew ? <BillPaySentenceActivity revv_uid={revv_uid} profileCandidate={profileCandidate} /> : null}
    </>
  );
};

BillPayUpsell.propTypes = {
  revv_uid: PropTypes.string,
  upsellType: PropTypes.string,
  upsellCreator: PropTypes.string,
  isNew: PropTypes.bool,
  isPublic: PropTypes.bool,
};

export { BillPayUpsell };
