import gql from 'graphql-tag';

export const GET_ORGANIZATION_TIMEZONES = gql`
  query organizationTimezones($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_time_zones {
          name
          value
        }
      }
    }
  }
`;

export const GET_CANDIDATE_TIMEZONES = gql`
  query candidateTimezones {
    viewer {
      candidate {
        available_time_zones {
          value
          name
        }
      }
    }
  }
`;
