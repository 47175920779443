import React from 'react';
import PropTypes from 'prop-types';
import { FloatGroup, Typography, Select } from 'modules/common';

function CustomFieldDefault({ handleUpdateValue, fieldOptions, selectedValue, label, isMultipleChoice, disabled }) {
  const validFieldOptions = fieldOptions
    .filter(option => option.value)
    .map(option => ({ value: option.key, label: option.value }));

  // need to format for select box
  if (isMultipleChoice) {
    label = 'Default Values';
  } else {
    selectedValue = validFieldOptions.find(fo => fo.value === selectedValue);
  }

  return (
    <FloatGroup>
      <Typography>{label}</Typography>
      <Select
        name="default_value"
        onChange={handleUpdateValue}
        isMulti={isMultipleChoice}
        disabled={disabled}
        options={validFieldOptions}
        value={selectedValue}
      />
    </FloatGroup>
  );
}
CustomFieldDefault.propTypes = {
  fieldOptions: PropTypes.array.isRequired,
  selectedValue: PropTypes.any.isRequired,
  label: PropTypes.string,
  isMultipleChoice: PropTypes.bool,
  handleUpdateValue: PropTypes.func.isRequired,
};
CustomFieldDefault.defaultProps = {
  label: 'Default Value',
  isMultipleChoice: false,
};

export default CustomFieldDefault;
