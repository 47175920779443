import React from 'react';
import PropTypes from 'prop-types';

import { WinRedLineChart, Card, ChartHeader, ReportingTrend } from 'modules/common';
import DateFilter from '../date-filter.component';

import '../dashboard.scss';

const SUBTITLE = 'Gross amount of new recurring donations. Charts the growth of your recurring program over time.';

function RecurringRevenueGraph({ candidate, dateRange, onSelectDateRange }) {
  const data = candidate.subscriptions_mrr_graph;

  return (
    <Card className="dashboard-graph">
      <Card.Body>
        <ChartHeader title="New Recurring Revenue" subtitle={SUBTITLE} results={data.results} headerLength={8}>
          <ReportingTrend name="value of recurring processed" amount={data.gross_amount} />
        </ChartHeader>

        <WinRedLineChart
          fillLine
          data={data.results}
          xProp="date"
          yProp="donation_amount"
          dateRange={dateRange.dateRange}
        />
        <DateFilter onSelectDateRange={onSelectDateRange} dateRange={dateRange} />
      </Card.Body>
    </Card>
  );
}

RecurringRevenueGraph.propTypes = {
  candidate: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
};

export default RecurringRevenueGraph;
