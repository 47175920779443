import produce from 'immer';

import { SET_REPORTS_FILTER } from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialDashboardState = {
  reportsFilter: {
    search: null,
    dateRangeEnd: null,
    dateRangeStart: null,
    revvUids: [{ value: 'all_winred', label: 'All WinRed' }],
    page: 1,
    activeTabKey: 'origin',
  },
};

export default produce((state = initialDashboardState, action) => {
  switch (action.type) {
    case SET_REPORTS_FILTER:
      return { ...state, reportsFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialDashboardState };
    default:
      return state;
  }
}, initialDashboardState);
