/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { cloneDeep } from 'lodash';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql, withApollo } from '@apollo/client/react/hoc';

import {
  Button,
  Typography,
  SpinnerContainer,
  setTitle,
  withAmplitude,
  AmplitudePropTypes,
  ConfirmModal,
  FormHeader,
  FormColumn,
} from 'modules/common';
import { Input, FormContainer } from 'modules/common/form';
import uuid from 'core/utilities/uuid';
import { noop } from 'core/utilities';
import { profileCandidateSelector } from 'core/login';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';
import {
  CREATE_UPSELL_MUTATION,
  UPDATE_UPSELL_MUTATION,
  GET_UPSELL_QUERY,
  GET_ORGANIZATION_UPSELL_QUERY,
  CREATE_ORGANIZATION_UPSELL_MUTATION,
  UPDATE_ORGANIZATION_UPSELL_MUTATION,
  GET_NEW_ORGANIZATION_UPSELL_QUERY,
  GET_NEW_UPSELL_QUERY,
  GET_UPSELLS_LIST_QUERY,
  GET_ORGANIZATION_UPSELLS_LIST_QUERY,
  skipQuery,
} from 'core/middleware/queries';
import { profileMaxPersonalDonationSelector, isOrganizationSelector } from 'core/login/login.selectors';
import FlowDetails from './sidebar/flow-details.component';
import FlowStats from './sidebar/flow-stats.component';

import GenerateUpsell from './main/generateUpsell.component';
import UpsellsList from './main/upsells-list.component';
import { PanelUpsell } from 'modules/pages/upsells/drawer/new-upsell';
import { generateUpsellDefaultCandidate } from './tools';

import {
  cantSaveUpsellGroup,
  UPSELL_COMPONENT_MAP,
  generateNewUpsell,
  addUpsellMetaData,
  formatUpsellForUi,
  formatUpsellPagesForUi,
  updateUpsell,
} from './tools';
import { formatUpsellForServer, formatUpsellListForUi } from './format.tools';
import RemoveUpsellFlow from './remove-upsell-flow.component';
import './new-upsell.scss';
import { MAX_UPSELL_OPTIONS_TO_QUERY } from '../pages';
import { UpsellDrawer } from './drawer/upsell-drawer.component';

class UpsellForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableSaveButton: true,
      selectedUpsellComponent: null, // the upsell react component we are displaying in the drawer
      openNewUpsell: false, // do we open the upsell drawer
      originalUpsellFlow: null,
      editedUpsellGroup: props.editedUpsellGroup, // the current upsell flow being edited

      editedUpsell: null, // the upsell being edited in the drawer
      originalEditedUpsell: null, // original copy of upsell to see if changes were made

      showConfirmClose: false, // show confirm modal for closing an upsell form in the drawer

      savingUpsell: false, // when saving a new upsell or saving an edited upsell
      gettingUpsellData: false, // when querying data needed to generate new or existing upsell template
      showSaveConfirm: false,

      loading: false,
      closingDrawer: false,
      removedExistingUpsellGroupRevvUid: null,
    };

    this.validationBlock = React.createRef();
    this.validationBlockFlow = React.createRef();
  }

  setbackdropRef = node => (this.backdropRef = node);

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  handleClickOutside = event => {
    const { openNewUpsell } = this.state;

    const clickedBackdrop = openNewUpsell && this.backdropRef && this.backdropRef.contains(event.target);
    if (clickedBackdrop) {
      this.onConfirmCloseEdit();
      return;
    }

    // only log on outside click when open
    // for some reason react-select clear svg/path elements are
    // considered 'clicked outside' so ignore those types of clicks
    const clickedOutside = openNewUpsell && this.componentRef && !this.componentRef.contains(event.target);
    const clickedClear = event.target.tagName !== 'path' && event.target.tagName !== 'svg';

    if (clickedOutside && clickedClear) {
      this.onConfirmCloseEdit();
    }
  };

  /**
   * if we are a part of page builder we dont keep an interal state
   * instead we act as a dumb component using the editedFlow prop
   */
  static getDerivedStateFromProps(props, state) {
    // if is new then we dont need to keep track of an 'original' (there is none)
    // for an existing upsell group, if we already saved the original we dont need to again
    if (props.isNewUpsellGroup || state.originalUpsellFlow) return state;

    return {
      ...state,
      originalUpsellFlow: props.editedUpsellGroup,
      editedUpsellGroup: props.editedUpsellGroup,
    };
  }

  /**
   * this is only used when using the upsell flow details - not upsell tab in page builder
   */
  saveUpsellGroup = () => {
    if (this.validationBlockFlow.current.errors()) return;

    // if editing a current upsell group and this upsell group belongs to more than one page then show confirm dialog
    const multiPageUpsellFlow = !this.props.isNewUpsellGroup && this.editedUpsellGroup.page_count > 1;

    if (multiPageUpsellFlow && !this.props.isPartOfPage) {
      this.setState({ showSaveConfirm: true });
    } else {
      this._saveUpsellGroup();
    }
  };

  /**
   * we may want a confirm dialog so the actual save call
   * is here so we can call before/after the confirm dialog
   */
  _saveUpsellGroup = () => {
    this.setState({ disableSaveButton: true }, () => {
      const upsellGroup = cloneDeep(this.state.editedUpsellGroup);
      this.props
        .saveUpsellGroup(upsellGroup)
        .then(({ upsellGroup, isNew }) => {
          if (isNew) return;

          upsellGroup.upsell_pages = (upsellGroup.upsell_pages || []).map(upsell =>
            formatUpsellPagesForUi(upsell, null, this.props.profileCandidate)
          );
          this.setState({ editedUpsellGroup: upsellGroup });
        })
        .catch(() => this.setState({ disableSaveButton: false }));
    });
  };

  /**
   * we are updating the entire upsell flow here
   */
  onUpdateEditedUpsellGroup = ({ target: { name, value } }) => {
    let editedUpsellGroup = cloneDeep(this.editedUpsellGroup);

    // if key is `upsell_flow` then we are replacing the entire object
    // (so we can update multiple values at once)
    if (name === 'upsell_flow') editedUpsellGroup = value;
    else editedUpsellGroup[name] = value;

    // save in the context of a page tab is passing the new state up for
    // the page builder to deal with it - not actually saving
    if (this.props.isPartOfPage) return this.props.saveUpsellGroup(editedUpsellGroup);

    this.setState({
      disableSaveButton: cantSaveUpsellGroup(editedUpsellGroup, this.state.originalUpsellFlow),
      editedUpsellGroup: editedUpsellGroup,
    });
  };

  /**
   * the currently updated upsell is kept separate in state
   * just update the internal state until we save
   */
  onUpdateEditedUpsell = ({ target: { name, value } }) => {
    let editedUpsell = this.state.editedUpsell || {};
    this.setState({ editedUpsell: updateUpsell(editedUpsell, name, value) });
  };

  /**
   * when removing an upsell from the list of upsells
   */
  onRemoveUpsell = deletedUpsell => {
    let newUpsellList = [];

    // if new one then api doesnt know about it so just remove
    // else we need to mark it as destroyed for the api
    if (deletedUpsell._isNew) {
      newUpsellList = this.editedUpsellGroup.upsell_pages.filter(upsell => upsell.id !== deletedUpsell.id);
    } else {
      newUpsellList = this.editedUpsellGroup.upsell_pages.map(upsell => {
        if (upsell.id === deletedUpsell.id) upsell._destroy = true;
        return upsell;
      });
    }

    this.onUpdateEditedUpsellGroup({ target: { name: 'upsell_pages', value: newUpsellList } });
  };

  confirmSave = () => this.setState({ showSaveConfirm: false }, this._saveUpsellGroup);
  cancelSave = () => this.setState({ showSaveConfirm: false });

  /**
   * when saving the edited upsell to the backend
   */
  onSaveEditedUpsell = () => {
    const {
      updateUpsell,
      createUpsell,
      createOrganizationUpsell,
      updateOrganizationUpsell,
      setToast,
      profileCandidate,
      editedUpsellGroup,
      isPartOfStorefront,
    } = this.props;
    if (this.validationBlock.current.errors()) return;

    const editedUpsell = cloneDeep(this.state.editedUpsell);
    const formattedUpsell = formatUpsellForServer(editedUpsell);
    formattedUpsell.organizationRevvUid = profileCandidate.organization_revv_uid;

    let saveUpsell = this.state.editedUpsell._isNew ? createUpsell : updateUpsell;

    if (this.props.isOrganization) {
      saveUpsell = this.state.editedUpsell._isNew ? createOrganizationUpsell : updateOrganizationUpsell;
    }

    this.setState({ savingUpsell: true }, () => {
      const oldUpsellGroup = cloneDeep(editedUpsellGroup);
      saveUpsell({ upsell: formattedUpsell })
        .then(({ data }) => {
          let { upsell: newUpsellFromServer, errors } =
            data.candidateCreateUpsell ||
            data.candidateUpdateUpsell ||
            data.organizationCreateUpsell ||
            data.organizationUpdateUpsell;

          if (errors) {
            setToast({ message: errors, isError: true });
            this.setState({ savingUpsell: false });
            return;
          }

          const editedUpsellGroup = isPartOfStorefront ? cloneDeep(oldUpsellGroup) : cloneDeep(this.editedUpsellGroup);
          const newUpsell = formatUpsellForUi(newUpsellFromServer, profileCandidate);

          // the currently edited upsell will keep it's id so when we save, we know we just saved an existing upsell so we
          // need to replace it - we loop through the upsell flow's upsells to find the one we updated given the id and
          // replace it with the newly returned upsell we got from the save
          if (editedUpsell._id) {
            const newUpsellsForGroup = editedUpsellGroup.upsell_pages.map(oldUpsell => {
              if (oldUpsell.id === editedUpsell._id) {
                oldUpsell.upsell_page = cloneDeep(newUpsell);
              }

              return oldUpsell;
            });

            if(isPartOfStorefront) {
              editedUpsellGroup['upsell_pages'] = newUpsellsForGroup;
              this.onUpdateEditedUpsellGroup({ target: { name: 'upsell_flow', value: editedUpsellGroup } });
            } else {
              this.onUpdateEditedUpsellGroup({ target: { name: 'upsell_pages', value: newUpsellsForGroup } });
            }
            this.onConfirmCloseEdit();
            setToast({ message: `Upsell has been successfully saved.` });
            return;
          }

          // we want to make sure panel upsells are always last when adding new upsells
          const existingUpsells = editedUpsellGroup.upsell_pages.filter(
            upsell => upsell.upsell_page.type !== PanelUpsell.type
          );

          const newUpsells = [
            ...existingUpsells,
            {
              id: uuid(),
              _id: uuid(),
              _isNew: true,
              position: existingUpsells + 1,
              upsell_page: { ...newUpsell, revv_uid: newUpsell.revv_uid },
            },
          ];

          const lastUpsell = editedUpsellGroup.upsell_pages.find(
            upsell => upsell.upsell_page.type === PanelUpsell.type
          );
          if (lastUpsell) {
            newUpsells.push({ ...lastUpsell, position: existingUpsells + 2 });
          }

          this.onUpdateEditedUpsellGroup({ target: { name: 'upsell_pages', value: newUpsells } });
          this.onConfirmCloseEdit();

          setToast({ message: `Upsell has been successfully created.` });
        })
        .catch(errors => {
          setToast({
            message: errors,
            isError: true,
          });

          this.setState({ savingUpsell: false });
        });
    });
  };

  /**
   * close the edit drawer - reset select upsell
   */
  onConfirmCloseEdit = () => {
    this.setState({
      openNewUpsell: false,
      selectedUpsellComponent: null,
      editedUpsell: null,
      originalEditedUpsell: null,
      savingUpsell: false,
      closingDrawer: true,
    });
  };

  /**
   * when selecting to create a new upsell
   */
  onSelectNewUpsell = () => {
    this.setState(
      { closingDrawer: false, openNewUpsell: true, editedUpsell: { type: null, revv_uid: uuid(), _isNew: true } },
      this.scrollToDrawerTop
    );
  };

  /**
   * when selecting an existing upsell to edit
   */
  onEditExistingUpsell = async editedUpsell => {
    const newEditedUpsell = addUpsellMetaData({ ...cloneDeep(editedUpsell.upsell_page), _id: editedUpsell.id });

    const pacResults = await this.getPacCandidates();

    if (this.state.closingDrawer) {
      this.setState({ closingDrawer: false });
      if (this.state.openNewUpsell) {
        return;
      }
    }

    const defaultCandidates = generateUpsellDefaultCandidate({
      profileCandidate: this.props.profileCandidate,
      availablePacCandidates: pacResults?.availablePacCandidates.results,
    });

    newEditedUpsell._defaultCandidates = defaultCandidates || [];

    this.setState(
      {
        openNewUpsell: true,
        selectedUpsellComponent: UPSELL_COMPONENT_MAP[editedUpsell.upsell_page.type],
        editedUpsell: newEditedUpsell,
        originalEditedUpsell: cloneDeep(newEditedUpsell),
      },
      this.scrollToDrawerTop
    );
  };

  /**
   * when opening the drawer or selecting an upsell form - we always want to make sure
   * we are at the top of the drawer/form since there's scrolling inside the drawer
   */
  scrollToDrawerTop() {
    this.drawerRef &&
      this.drawerRef.scrollIntoView &&
      this.drawerRef.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
  }

  /**
   * when selecting an existing upsell to add to the list of upsells
   */
  onSelectExistingUpsell = async ({ target: { value } }) => {
    if (!value) return;
    try {
      this.setState({ gettingUpsellData: true });

      const variables = {
        revv_uid: value.revv_uid || value,
        organizationRevvUid: this.props.profileCandidate.organization_revv_uid,
      };

      const { data } = await this.props.client.query({
        query: this.props.isOrganization ? GET_ORGANIZATION_UPSELL_QUERY : GET_UPSELL_QUERY,
        variables,
        fetchPolicy: 'no-cache',
      });
      this.setState({ gettingUpsellData: false });

      if (data.error || data.errors) {
        this.props.setToast({
          message: data.error || data.errors,
          isError: true,
        });
        return;
      }

      const candidate = data.viewer && (data.viewer.candidate || data.viewer.state_level_organization);
      let [selectedUpsell] = candidate?.upsells?.results || [];

      if (!selectedUpsell) {
        this.props.setToast({
          message: 'Retrieved upsell not found.',
          isError: true,
        });
        return;
      }

      selectedUpsell = formatUpsellForUi(selectedUpsell, this.props.profileCandidate);

      // save meta data for generating slate upsells
      const defaultCandidates = generateUpsellDefaultCandidate({
        profileCandidate: this.props.profileCandidate,
        availablePacCandidates: candidate.available_pac_candidates.results,
      });

      selectedUpsell._defaultCandidates = defaultCandidates;

      const editedUpsellGroup = this.editedUpsellGroup;
      const upsellPages = editedUpsellGroup.upsell_pages || [];

      // we want to make sure panel upsells are always last when adding new upsells
      const existingUpsells = upsellPages.filter(upsell => upsell.upsell_page.type !== PanelUpsell.type);
      const newUpsells = [
        ...existingUpsells,
        {
          id: uuid(),
          _isNew: true,
          position: upsellPages.length + 1,
          upsell_page: cloneDeep(selectedUpsell),
        },
      ];

      const lastUpsell = upsellPages.find(upsell => upsell.upsell_page.type === PanelUpsell.type);
      if (lastUpsell) {
        newUpsells.push({ ...lastUpsell, position: existingUpsells + 2 });
      }

      this.onUpdateEditedUpsellGroup({ target: { name: 'upsell_pages', value: newUpsells } });
    } catch (error) {
      this.setState({ loading: false });
      this.props.setToast({
        message: `There was an error retrieving the upsell: ${error.message || error}`,
        isError: true,
      });
    }
  };

  /**
   * if part of part always return props as page builder handles ALL state
   * else we are the route container and handle our own state
   */
  get editedUpsellGroup() {
    if (this.props.isPartOfPage) return this.props.editedUpsellGroup;
    return this.state.editedUpsellGroup || this.props.editedUpsellGroup;
  }

  /**
   * when creating a new upsell, we set which upsell type from selectedUpsellComponent
   */
  setUpsellComponent = async selectedUpsellComponent => {
    const pacResults = await this.getPacCandidates();

    if (this.state.closingDrawer) {
      this.setState({ closingDrawer: false });
      return;
    }

    const newUpsell = {
      ...generateNewUpsell({
        upsellType: selectedUpsellComponent.type,
        maxDonation: this.props.maxPersonalDonation,
        profileCandidate: this.props.profileCandidate,
        availablePacCandidates: pacResults?.availablePacCandidates,
        enableDonorCoverFees: pacResults?.enableDonorCoverFees,
      }),
      type: selectedUpsellComponent.type,
      _isNew: this.state.editedUpsell?._isNew || false,
    };

    this.setState(
      {
        selectedUpsellComponent,
        editedUpsell: newUpsell,
        originalEditedUpsell: cloneDeep(newUpsell),
      },
      this.scrollToDrawerTop
    );
  };

  /** get the PAC candidate data for a candidate (akas the vendor data for generating new upsell, editing exist upsell, etc) */
  getPacCandidates = async () => {
    let returnValue = {
      availablePacCandidates: [],
      enableDonorCoverFees: this.props.profileCandidate?.enable_donor_cover_fees,
    };
    try {
      this.setState({ gettingUpsellData: true });

      const { isOrganization, profileCandidate } = this.props;
      const candidateRevvUid = isOrganization ? profileCandidate.organization_revv_uid : profileCandidate.revv_uid;

      const variables = {
        revvUid: candidateRevvUid,
        organizationRevvUid: this.props.profileCandidate.organization_revv_uid,
      };

      const { data } = await this.props.client.query({
        query: this.props.isOrganization ? GET_NEW_ORGANIZATION_UPSELL_QUERY : GET_NEW_UPSELL_QUERY,
        variables,
      });
      this.setState({ gettingUpsellData: false });

      if (data.error || data.errors) {
        this.props.setToast({
          message: data.error || data.errors,
          isError: true,
        });
        return returnValue;
      }

      const candidate = data?.viewer?.candidate || data?.viewer?.state_level_organization;
      const availablePacCandidates = candidate?.available_pac_candidates;
      returnValue.availablePacCandidates = availablePacCandidates;
      returnValue.enableDonorCoverFees = candidate?.enable_donor_cover_fees;
      return returnValue;
    } catch (error) {
      this.setState({ gettingUpsellData: false });
      this.props.setToast({
        message: error,
        isError: true,
      });
      return returnValue;
    }
  };

  /**
   * closing the edit upsell drawer
   */
  showCloseModal = () => this.setState({ showConfirmClose: true });
  hideCloseModal = () => this.setState({ showConfirmClose: false });

  /**
   * save ref of app drawer to scroll to top on open
   */
  setRef = node => (this.drawerRef = node);

  renderTitle(editedUpsellGroup) {
    const { isNewUpsellGroup } = this.props;
    if (isNewUpsellGroup) return 'New Upsell Flow';
    if (editedUpsellGroup && editedUpsellGroup.name) return editedUpsellGroup.name;
    return '';
  }

  /**
   * only need header if in upsell container - else we are in upsell page build tab - dont need it
   */
  renderHeader(editedUpsellGroup) {
    const { loading } = this.props;
    const { disableSaveButton } = this.state;
    const title = this.renderTitle(editedUpsellGroup);
    const _disableSaveButton = loading || disableSaveButton;

    return (
      <FormHeader>
        <Col>
          <Typography variant="h1">{title}</Typography>
        </Col>
        <Col sm="auto" className="justify-content-end">
          <div className="d-inline-flex">
            <Button
              variant="secondary"
              size="lg"
              onClick={this.saveUpsellGroup}
              className="mx-0 text-white"
              disabled={_disableSaveButton}
            >
              Save
            </Button>
          </div>
        </Col>
      </FormHeader>
    );
  }

  hasUpsellsInGroup = editedUpsellGroup => {
    // If there is no group, don't run the check.
    if (!editedUpsellGroup) return true;

    // If there are no pages in the flow, the check fails.
    if (!editedUpsellGroup.upsell_pages || editedUpsellGroup.upsell_pages.length === 0) return false;

    // See if the only pages in the flow are marked to be deleted.
    const nonDeletedPages = editedUpsellGroup.upsell_pages.reduce((numberOfPages, page) => {
      if (page._destroy) return numberOfPages;
      return numberOfPages + 1;
    }, 0);

    // If there are zero remaining pages, the check fails.
    return nonDeletedPages > 0;
  };

  renderContent(editedUpsellGroup) {
    const {
      openNewUpsell,
      selectedUpsellComponent,
      editedUpsell,
      savingUpsell,
      gettingUpsellData,
      showSaveConfirm,
      originalEditedUpsell,
    } = this.state;
    const { isPartOfPage, primaryColor, secondaryColor, disabled } = this.props;

    return (
      <>
        <Row className="pt-3 justify-content-center">
          <Col xs={12} md={4} className="mt-3">
            <FlowDetails
              editedUpsellGroup={editedUpsellGroup}
              onUpdateEditedUpsellGroup={this.onUpdateEditedUpsellGroup}
            />
            <FlowStats editedUpsellGroup={editedUpsellGroup} />

            {isPartOfPage && !disabled ? (
              <RemoveUpsellFlow
                saveUpsellGroup={this.props.saveUpsellGroup}
                onRemoveExistingUpsellFlow={this.props.onRemoveExistingUpsellFlow}
                editedUpsellGroup={this.state.editedUpsellGroup}
              />
            ) : null}
          </Col>

          <Col xs={12} md={8} className="mt-3">
            {this.state.loading ? (
              <SpinnerContainer />
            ) : (
              <>
                <Input
                  hidden
                  value={this.hasUpsellsInGroup(editedUpsellGroup)}
                  onChange={noop}
                  validators={['required']}
                  errorMessages={['At least one Upsell must be added to the Upsell Flow.']}
                  errorMessageClasses="no-upsells-in-group-error"
                />
                <UpsellsList
                  onEditExistingUpsell={this.onEditExistingUpsell}
                  onUpdateEditedUpsellGroup={this.onUpdateEditedUpsellGroup}
                  upsells={editedUpsellGroup.upsell_pages}
                  onRemoveUpsell={this.onRemoveUpsell}
                  disabledUpsellFlow={editedUpsellGroup._isDisabled}
                />

                {editedUpsellGroup._isDisabled ? null : (
                  <Row className="upsell-drawer">
                    <Col lg={12}>
                      <GenerateUpsell
                        upsellDropdownOptions={this.props.data?.upsellDropdownOptions || []}
                        editedUpsellGroup={editedUpsellGroup}
                        upsells={editedUpsellGroup.upsell_pages}
                        onSelectNewUpsell={this.onSelectNewUpsell}
                        onSelectExistingUpsell={this.onSelectExistingUpsell}
                        onChange={this._onChangeUpsell}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>

        <UpsellDrawer
          editedUpsell={editedUpsell}
          onUpdateEditedUpsell={this.onUpdateEditedUpsell}
          editedUpsellGroup={editedUpsellGroup}
          openNewUpsell={openNewUpsell}
          secondaryColor={secondaryColor}
          originalEditedUpsell={originalEditedUpsell}
          primaryColor={primaryColor}
          selectedUpsellComponent={selectedUpsellComponent}
          onConfirmCloseEdit={this.onConfirmCloseEdit}
          setbackdropRef={this.setbackdropRef}
          validationBlock={this.validationBlock}
          setUpsellComponent={this.setUpsellComponent}
          onSaveEditedUpsell={this.onSaveEditedUpsell}
          setRef={this.setRef}
          savingUpsell={savingUpsell}
          gettingUpsellData={gettingUpsellData}
        />

        <ConfirmModal
          show={showSaveConfirm}
          title="Save Upsell Flow"
          buttonText="Save Anyway"
          buttonColor="primary"
          showCancelButton
          handleClose={this.cancelSave}
          onCancel={this.cancelSave}
          onSubmit={this.confirmSave}
        >
          This upsell flow has been added to other pages. Editing this upsell flow will also edit it for all other pages
          it has been added to.
        </ConfirmModal>
      </>
    );
  }

  render() {
    const { loading, isNewUpsellGroup, isPartOfPage } = this.props;
    const editedUpsellGroup = this.editedUpsellGroup;
    const title = isNewUpsellGroup ? 'New Upsell Flow' : 'Edit Upsell Flow';

    // if embedded inside page build only render body
    if (isPartOfPage) {
      return loading ? <SpinnerContainer /> : this.renderContent(editedUpsellGroup);
    }

    return (
      <ValidationBlock ref={this.validationBlockFlow}>
        {setTitle(title)}
        {this.renderHeader(editedUpsellGroup)}
        <FormContainer className="justify-content-center">
          <FormColumn>{loading ? <SpinnerContainer /> : this.renderContent(editedUpsellGroup)}</FormColumn>
        </FormContainer>
      </ValidationBlock>
    );
  }
}

UpsellForm.propTypes = {
  saveUpsellGroup: PropTypes.func.isRequired,
  createUpsell: PropTypes.func.isRequired,
  updateUpsell: PropTypes.func.isRequired,

  setToast: PropTypes.func.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,

  isPartOfPage: PropTypes.bool,
  isPartOfStorefront: PropTypes.bool,
  isNewUpsellGroup: PropTypes.bool,

  loading: PropTypes.bool,
  editedUpsellGroup: PropTypes.object,
  upsellDropdownOptions: PropTypes.array,
  client: PropTypes.object.isRequired,

  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  maxPersonalDonation: PropTypes.number.isRequired,
  onRemoveExistingUpsellFlow: PropTypes.func,
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
  maxPersonalDonation: profileMaxPersonalDonationSelector(state),
  profileCandidate: profileCandidateSelector(state),
});
const mapDispatchToProps = dispatch => ({
  setToast: message => dispatch(setToast(message)),
});

const hasTooManyOptions = props => {
  // Skip the query if upsell count is not yet returned or undefined
  if (props?.upsellCount == null) {
    return true;
  }
  return props?.upsellCount > MAX_UPSELL_OPTIONS_TO_QUERY;
};

export default compose(
  withRouter,
  withAmplitude,
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_UPSELLS_LIST_QUERY, {
    skip: props => skipQuery({ props }) || hasTooManyOptions(props),
    options: props => ({
      variables: {
        page: 1,
        limit: 9999,
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
      },
      fetchPolicy: 'no-cache',
    }),
    props: ({ data, ownProps }) => formatUpsellListForUi(data, ownProps.profileCandidate),
  }),
  graphql(GET_ORGANIZATION_UPSELLS_LIST_QUERY, {
    skip: props => skipQuery({ props, isOrganizationQuery: true }) || hasTooManyOptions(props),
    options: props => ({
      variables: {
        page: 1,
        limit: 9999,
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
      },
      fetchPolicy: 'no-cache',
    }),
    props: ({ data, ownProps }) => formatUpsellListForUi(data, ownProps.profileCandidate),
  }),
  graphql(CREATE_UPSELL_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createUpsell: variables =>
        mutate({ variables: { ...variables, organizationRevvUid: ownProps.profileCandidate.organization_revv_uid } }),
    }),
  }),
  graphql(CREATE_ORGANIZATION_UPSELL_MUTATION, {
    props: ({ mutate }) => ({
      createOrganizationUpsell: variables => mutate({ variables }),
    }),
  }),
  graphql(UPDATE_ORGANIZATION_UPSELL_MUTATION, {
    props: ({ mutate }) => ({
      updateOrganizationUpsell: variables => mutate({ variables }),
    }),
  }),
  graphql(UPDATE_UPSELL_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateUpsell: variables =>
        mutate({ variables: { ...variables, organizationRevvUid: ownProps.profileCandidate.organization_revv_uid } }),
    }),
  })
)(UpsellForm);
