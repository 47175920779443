import React from 'react';
import PropTypes from 'prop-types';

function FormBody({ children }) {
  return (
    <div className="flex-1">
      <div className="overflow-hidden-x">{children}</div>
    </div>
  );
}

FormBody.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FormBody;
