import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';

import Button from '../button.component';
import './dropdown.scss';
import { Icon } from 'modules/common';

function WinRedDropdownIconContainer({
  children,
  title = 'Create New',
  variant = 'success',
  disabled,
  className = '',
  onClick,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <div className={classnames(className, 'winred-dropdown')}>
      <Dropdown
        disabled={disabled}
        as={ButtonGroup}
        onToggle={show => {
          setDropdownOpen(show);
        }}
      >
        <Button variant={variant} onClick={onClick} className="dropdown-left-btn">
          {title}
        </Button>
        <Dropdown.Toggle disabled={disabled} split variant={variant} as={Button} className="icon-dropdown">
          <Icon icon={dropdownOpen ? 'chevron-up' : 'chevron-down'} color="white" />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

WinRedDropdownIconContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default WinRedDropdownIconContainer;
