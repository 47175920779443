import React from 'react';
import PropTypes from 'prop-types';
import { Card, Input, FloatGroup } from 'modules/common';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function AcceptButton({ editedUpsell, onUpdateEditedUpsell, className, subtitle }) {
  return (
    <Row className={className}>
      <Col sm={12} md={12}>
        <Card.Title subtitle={subtitle}>Accept Button Text</Card.Title>
        <FloatGroup className="accept-form-group">
          <Input
            type="text"
            id="accept"
            name="accept"
            maxLength="100"
            disabled={editedUpsell._isDisabled}
            value={editedUpsell.accept}
            onChange={onUpdateEditedUpsell}
            ariaLabel="Accept Button Text"
            placeholder="Accept Button Text"
            validators={['required']}
            errorMessages={['Accept Button Text is required.']}
          />
        </FloatGroup>
      </Col>
    </Row>
  );
}

AcceptButton.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  className: PropTypes.string,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

AcceptButton.defaultProps = {
  className: '',
  subtitle: 'Enter copy for the accept upsell button.',
};

export default AcceptButton;
