import { useCallback, useState } from 'react';

function useExpandedRows() {
  const [expandedRows, setExpandedRows] = useState({});

  const expandItem = useCallback(
    (event, uuid) => {
      event && event.preventDefault();
      event && event.stopPropagation();

      if (!expandedRows[uuid]) expandedRows[uuid] = false;
      expandedRows[uuid] = !expandedRows[uuid];
      setExpandedRows({ ...expandedRows });
    },
    [expandedRows, setExpandedRows]
  );

  return [expandedRows, expandItem];
}

export default useExpandedRows;
