import React from 'react';
import PropTypes from 'prop-types';

import { Slider, FloatGroup, Input } from 'modules/common';

function WidgetShotclock({ widget, updateWidget, disabled = false }) {
  return (
    <>
      <Slider
        title="Minute(s)"
        name="shotclockLength"
        onChange={updateWidget}
        disabled={disabled}
        min={1}
        max={60}
        step={0.5}
        value={widget.shotclockLength}
        description="Select the amount of minutes that should elapse before the shotclock expires."
      />
      <FloatGroup>
        <Input
          type="text"
          name="shotclockLabel"
          placeholder="Shotclock Label"
          value={widget.shotclockLabel}
          disabled={disabled}
          maxLength="54"
          onChange={updateWidget}
          description="Add a donor-facing callout above your shotclock."
        />
      </FloatGroup>
    </>
  );
}

WidgetShotclock.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetShotclock;
