import React from 'react';
import { Link } from 'react-router-dom';

export function RouterLink({ to, children, onClick, ...rest }) {
  if (onClick) {
    return (
      <div className="pointer text-winred-red underline hover:text-winred-red-light" onClick={onClick} {...rest}>
        {children}
      </div>
    );
  }
  return (
    <Link to={to} className="text-winred-red underline hover:text-winred-red-light" {...rest}>
      {children}
    </Link>
  );
}
