import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/common';

export default function TitleWithCog({ title, showCog, cogEnabled, onClickCog, largeCog }) {
  return (
    <div className="d-flex mb-1">
      {title}
      {showCog && (
        <div role="button" className="ml-auto no-outline pointer" onClick={onClickCog}>
          <Icon size={largeCog && '2x'} icon={cogEnabled ? 'cog-solid' : 'cog'} />
        </div>
      )}
    </div>
  );
}
TitleWithCog.propTypes = {
  cogEnabled: PropTypes.bool,
  showCog: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClickCog: PropTypes.func.isRequired,
  largeCog: PropTypes.bool,
};
TitleWithCog.defaultProps = {
  cogEnabled: false,
  showCog: false,
};
