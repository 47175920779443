export const isMoneyThermometerType = thermometerType => ['REVENUE', 'DONATIONS'].includes(thermometerType);

export const getThermometerPreviewData = widget => {
  const { statistics, goals } = widget;
  let selectedGoal = 0;
  let percentage = 0;
  const isMoneyThermometer = isMoneyThermometerType(widget?.thermometer_type);
  const selectedCountVaraible = isMoneyThermometer ? 'donation_amount' : 'donor_count';
  goals.every((goal, index) => {
    let centsToDollars = isMoneyThermometer ? goal.amount_cents / 100 : goal.amount_cents;

    if (centsToDollars > statistics[selectedCountVaraible]) {
      selectedGoal = centsToDollars;
      return false;
    }
    if (index === goals.length - 1 && selectedGoal === 0) {
      selectedGoal = isMoneyThermometer
        ? goals[goals.length - 1].amount_cents / 100
        : goals[goals.length - 1].amount_cents;
    }
    return true;
  });
  percentage =
    statistics[selectedCountVaraible] / selectedGoal > 1 ? 1 : statistics[selectedCountVaraible] / selectedGoal;
  return { accumulating_amount: statistics[selectedCountVaraible], selectedGoal, percentage };
};

export const getWidgetId = widgets => {
  if (widgets.length === 0) {
    return 1;
  }
  let widgetIds = widgets
    .map(widget => {
      return widget.id;
    });
  const newId = Math.max(...widgetIds) + 1;
  return newId;
};
