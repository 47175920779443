import {
  SET_PAGES_FILTER,
  SET_CONNECTED_PAGES_FILTER,
  SET_TEAM_PAGES_FILTER,
  SET_UPSELLS_FILTER,
  SET_CAMPAIGNS_FILTER,
  SET_UPSELLS_ACTIVE_TAB,
  SET_PATHWAYS_ACTIVE_TAB,
  SET_PATHWAYS_FILTER,
} from './types';

export const setPagesFilter = filter => ({
  type: SET_PAGES_FILTER,
  filter,
});

export const setUpsellActiveTab = tab => ({ type: SET_UPSELLS_ACTIVE_TAB, tab });

export const setUpsellsFilter = filter => ({
  type: SET_UPSELLS_FILTER,
  filter,
});

export const setPathwaysActiveTab = tab => ({ type: SET_PATHWAYS_ACTIVE_TAB, tab });

export const setPathwaysFilter = filter => ({
  type: SET_PATHWAYS_FILTER,
  filter,
});

export const setConnectedPagesFilter = filter => ({
  type: SET_CONNECTED_PAGES_FILTER,
  filter,
});

export const setTeamPagesFilter = filter => ({
  type: SET_TEAM_PAGES_FILTER,
  filter,
});

export const setCampaignsFilter = filter => ({
  type: SET_CAMPAIGNS_FILTER,
  filter,
});
