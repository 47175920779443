import React, { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Table, Typography, IndexTableContextMenu, Image } from 'modules/common';
import { convertEpochTime } from 'core/utilities';
import transparent from '../../../../../../assets/images/transparent-checker.avif';

export function ImagesTableDesktop({ items, dropdownAction, handleOnImageSelection, selectedImageId }) {
  const tableBody = items.map(item => {
    const { id, created_at, thumbnailUrl, imageFileName } = item;

    return (
      <Fragment key={id}>
        <Table.Row
          key={id}
          className={`p-0 ${id === selectedImageId && 'selected-image'}`}
          tabIndex="0"
          onClick={() => handleOnImageSelection(id)}
          onKeyPress={() => handleOnImageSelection(id)}
        >
          <Table.Cell textAlign="center" className="py-1">
            {thumbnailUrl ? (
              <div
                style={{ backgroundImage: `url(${transparent})` }}
                className="w-24 h-24 bg-cover bg-center ml-2 flex justify-center items-center"
              >
                <Image alt={imageFileName} src={thumbnailUrl} className="w-full h-full object-contain" />
              </div>
            ) : null}
          </Table.Cell>
          <Table.Cell>{convertEpochTime(created_at)}</Table.Cell>
          <Table.Cell>
            <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
              {imageFileName}
            </Typography>
          </Table.Cell>
          <Table.Cell textAlign="center" noClick>
            <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
              <Dropdown.Item eventKey="copy">Copy URL</Dropdown.Item>
              <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
            </IndexTableContextMenu>
          </Table.Cell>
        </Table.Row>
      </Fragment>
    );
  });

  return (
    <Table data-testid="my-pages-index" className="pages-index-desktop">
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
}
