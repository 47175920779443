import { SET_TOAST, CLEAR_TOAST } from './types';

export const setToast = toast => {
  return {
    type: SET_TOAST,
    response: toast,
  };
};

export const clearToast = () => {
  return {
    type: CLEAR_TOAST,
  };
};
