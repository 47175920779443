export const formatImageForServer = (item, prop) => {
  if (item[prop]) {
    if (item[prop].imageId) {
      delete item[prop].image;
      delete item[prop].__typename;
    } else if (
      prop === 'twitterAssignedImage' ||
      prop === 'ogAssignedImage' ||
      prop === 'logoAssignedImage' ||
      prop === 'mobileBannerAssignedImage' ||
      prop === 'backgroundAssignedImage'
    ) {
      if (item[prop].__typename) {
        delete item[prop].__typename;
      }
      if (!item[prop].imageId) {
        item[prop].imageId = item[prop]?.image?.id;
        delete item[prop].id;
      }
      delete item[prop].image;
    } else {
      delete item[prop];
    }
  }
  return item;
};

export const formatDefaultSettingForServer = (item, prop) => {
  if (item[prop]?.__typename) {
    delete item[prop].__typename;
  }
  if (item[prop].enableMobileField) {
    delete item[prop].enableMobileField;
  }
  return item;
};

export const formatMarketingSettingForServer = (item, prop) => {
  if (item[prop]?.__typename) {
    delete item[prop].__typename;
  }
  if (item[prop]?.twitterAssignedImage) {
    if (item[prop]?.twitterAssignedImage?.image?.id) {
      item[prop].twitterAssignedImage.imageId = item[prop]?.twitterAssignedImage?.image?.id;
    }
    delete item[prop].twitterAssignedImage.image;
    if (item[prop].twitterAssignedImage?.__typename) {
      delete item[prop].twitterAssignedImage.__typename;
    }
  }

  if (item[prop]?.ogAssignedImage) {
    if (item[prop]?.ogAssignedImage?.image?.id) {
      item[prop].ogAssignedImage.imageId = item[prop]?.ogAssignedImage?.image?.id;
    }
    delete item[prop].ogAssignedImage.image;
    if (item[prop].ogAssignedImage?.__typename) {
      delete item[prop].ogAssignedImage.__typename;
    }
  }
  return item;
};

export const formatCustomFieldsForServer = customFields => {
  return customFields.map((cf, index) => {
    // convert any item to csv default_value
    let defaultValue =
      cf.options && cf.options.length ? cf.options.map(option => option.value).join(',') : cf.default_value;

    // for checkbox - defaultValue is a boolean but we always need string
    if (typeof defaultValue !== 'string') {
      defaultValue = defaultValue.toString();
    }

    return {
      // for new CFs - we create these to use as keys but the backend will generate them on save
      pageCustomFieldId: cf.isNew && !cf.isReplaced ? null : cf.page_custom_field_id,

      // if creating a new CF on a page and not from an existing CF (a completly new one)
      // new dont need to pass the global_custom_field_id as it will be generated for us
      globalCustomFieldId: cf.isNew && !cf.fromExisting ? null : cf.global_custom_field_id,
      parameterName: cf.name,
      inputType: cf.input_type,
      position: cf.position,
      positionIndex: cf.position_index || index + 1,
      target: cf.form_section,
      label: cf.label,
      placeholder: cf.placeholder,
      defaultValue: defaultValue,
      required: cf.required,
      otherFieldEnabled: cf.other_field_enabled,
      destroy: !!cf.destroy,
    };
  });
};

/* We add a temporary donation amount ID for new donation amounts so we can
  manage them but we need to remove it before saving the page. */
export const formatAmounts = (amounts, isMultiplier = false) => {
  const formattedAmount = amounts
    .filter(amount => !(amount._destroy && amount._isNew))
    .map((donation, index) => {
      // filter out multipliers if we dont want multiplier and vice versa
      if (isMultiplier && !donation._isMultiplier) return null;
      if (!isMultiplier && donation._isMultiplier) return null;

      const newDonation = {
        position: index + 1,
        amount: donation.amount ? parseFloat(donation.amount) : 0,
        description: donation.description,
      };

      if (!donation._isNew) newDonation.id = donation.id;
      if (!donation._isNew && donation._destroy) newDonation.destroy = true;
      if (donation._isNew && donation._destroy) return null;

      return newDonation;
    })
    .filter(v => Boolean(v));

  return formattedAmount;
};

export const formatMoneyPledgeForServer = page => {
  page.moneyPledgeCampaignRevvUid = page?.moneyPledgeCampaign?.revv_uid ?? '';
  delete page.moneyPledgeCampaign;
  return page;
};
