import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormCardSpaced, ImagePrompt, ConfirmModal } from 'modules/common';

function ImageCard({
  name,
  onChange,
  imageType,
  assignedImage,
  title,
  subtitle,
  removeTitle,
  removeBody,
  children,
  className,
  classNameBody,
  disabled = false,
  isNested = false,
}) {
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);
  const imageUri = assignedImage?.image?.thumbnailUrl || assignedImage?.image?.originalUrl || null;

  return (
    <FormCardSpaced title={title} subtitle={subtitle} className={className} classNameBody={classNameBody}>
      <ImagePrompt
        disabled={disabled}
        imageUri={imageUri}
        name={name}
        selectImageAction={onChange}
        imageType={imageType}
        removeImageAction={() => setShowRemoveLogoModal(true)}
        isNested={isNested}
      />

      <ConfirmModal
        show={showRemoveLogoModal}
        title={removeTitle}
        buttonText="Remove"
        handleClose={() => setShowRemoveLogoModal(false)}
        buttonColor="cancel"
        onSubmit={() => {
          const value = {
            destroy: true,
            imageId: assignedImage?.image?.id,
            id: assignedImage?.id,
          };

          onChange({ target: { name: name, value } });
          setShowRemoveLogoModal(false);
        }}
      >
        {removeBody}
      </ConfirmModal>
      {children}
    </FormCardSpaced>
  );
}

ImageCard.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
  assignedImage: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  removeTitle: PropTypes.string.isRequired,
  removeBody: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
};

ImageCard.defaultProps = {
  className: '',
  classNameBody: '',
};

export default ImageCard;
