export const computePageIconColor = item => {
  if (item?.videoPage && item?.status !== 'ARCHIVED') return 'green';
  if (item?.highDollar && item?.status !== 'ARCHIVED') return 'gold';

  switch (item?.status) {
    case 'PASSWORD_PROTECTED':
      return 'blue';
    case 'LIVE':
      return 'green';
    case 'ARCHIVED':
      return 'red';
    default:
      return 'gray';
  }
};

export const computePageIcon = item => {
  if (item?.videoPage) return 'video';
  return item?.type === 'PETITION' ? 'pen-nib' : 'donate';
};

export const computePageTooltip = item => {
  let tooltip = '';

  switch (item?.status) {
    case 'PASSWORD_PROTECTED':
      tooltip = 'Password';
      break;
    case 'LIVE':
      tooltip = 'Live';
      break;
    case 'DRAFT':
      tooltip = 'Draft';
      break;
    case 'ARCHIVED':
      tooltip = 'Archived';
      break;
    default:
      tooltip = '';
      break;
  }

  if (item?.type === 'PETITION') tooltip = 'Petition ' + tooltip;
  if (item?.highDollar) return 'High Dollar ' + tooltip;

  return tooltip;
};

export const PETITION_PAGE_DESCRIPTION = `You can split leads that are made through WinRed pages with other committees. Any leads who sign a petition will be sent to any committees added to a page. To add a committee to this page, click "Add committee" and search for the committee by name.`;
export const DESCRIPTION = `You can split donations that are made through WinRed pages with other committees. If you have chosen to customize the default split percentage, candidates will be ordered by percentage weight on the landing page. To add a committee to this page, click "Add committee" and search for the committee by name.`;

export const addCommitteeButtonDescription = `Add committees to your page.`;
