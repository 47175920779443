import React from 'react';
import { ExternalLink, FlexBinaryChoiceRow, FormCard, Typography } from 'modules/common';

const DonorCoverFees = ({ editedUpsell, onUpdateEditedUpsell }) => {
  return (
    <FormCard>
      <FlexBinaryChoiceRow
        name="donor_cover_fees"
        title="Allow Donors to Cover Processing Fees"
        subTitle={
          <Typography>
            Select whether you would like to allow your donors to cover processing fees.&nbsp;
            <ExternalLink href="https://support.winred.com/en/articles/5964535-enable-cover-processing-fees">
              Learn More
            </ExternalLink>
          </Typography>
        }
        value={editedUpsell.donor_cover_fees}
        disabled={editedUpsell._isDisabled}
        onChange={onUpdateEditedUpsell}
      />
    </FormCard>
  );
};

export default DonorCoverFees;
