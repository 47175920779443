import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, FlexBinaryChoiceRow } from 'modules/common';
import UpsellAmountMultiplierFooter from './amount-multiplier/footer.component';
import UpsellAmountMultiplierAmounts from './amount-multiplier/amounts.component';

function UpsellAmountMultiplier({ editedUpsell, onUpdateEditedUpsell, maxDonationAmounts }) {
  const onUpdateAmountType = ({ target: { name, value } }) => {
    const newEditedUpsell = { ...editedUpsell };
    newEditedUpsell[name] = value;

    // Enable the other amount when switching to dynamic and disable when switching to static
    newEditedUpsell.include_other_field = value ? true : false;

    onUpdateEditedUpsell({ target: { name: 'card', value: newEditedUpsell } });
  };

  return (
    <>
      <FormCardSpaced
        className="no-bottom-border-radius p-0"
        footer={
          <UpsellAmountMultiplierAmounts
            editedUpsell={editedUpsell}
            onUpdateEditedUpsell={onUpdateEditedUpsell}
            maxDonationAmounts={maxDonationAmounts}
          />
        }
      >
        <FlexBinaryChoiceRow
          trueText="Dynamic"
          falseText="Static"
          title="Donation Amount Buttons"
          subTitle="Customize your suggested donation amount buttons."
          value={editedUpsell.multiplier_amounts}
          disabled={editedUpsell._isDisabled}
          name="multiplier_amounts"
          onChange={onUpdateAmountType}
        />
      </FormCardSpaced>

      <UpsellAmountMultiplierFooter
        editedUpsell={editedUpsell}
        onUpdateEditedUpsell={onUpdateEditedUpsell}
        maxDonationAmounts={maxDonationAmounts}
      />
    </>
  );
}

UpsellAmountMultiplier.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  maxDonationAmounts: PropTypes.number,
};

UpsellAmountMultiplier.defaultProps = {
  maxDonationAmounts: 9999,
};

export default UpsellAmountMultiplier;
