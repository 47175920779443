import React from 'react';
import { ViewMoreDropdown } from 'modules/common';

function IndexTableContextMenu({ onSelect, children, link, className = '', customIcon, dropdownColorClass, ...rest }) {
  return (
    <div className={'winred-dropdown-container ' + className}>
      <ViewMoreDropdown onSelect={onSelect} aria-label="row item dropdown menu" customIcon={customIcon} dropdownColorClass={dropdownColorClass} {...rest}>
        {children}
      </ViewMoreDropdown>
    </div>
  );
}

export default IndexTableContextMenu;
