import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';

import { profileCandidateSelector } from 'core/login';
import { FormCard, PaymentDetails } from 'modules/common';
import { getDataFieldValues } from './tools';
import InformationListItem from './information-list-item.component';

function InformationList({ data, title, subtitle, dataFields, className, children, showPaymentDetails }) {
  const profileCandidate = useSelector(profileCandidateSelector);

  return (
    <div className={classnames('detail-card-container', className)}>
      <FormCard
        title={title}
        subtitle={subtitle}
        className="detail-card"
        classNameBody="information-list-card"
        classNameTitle="text-capitalize list-group-title"
      >
        <ListGroup variant="flush" as="dl">
          {dataFields.map(field => {
            const { key, value, hidden, ...listItemProps } = getDataFieldValues({
              field,
              info: data,
              profileCandidate,
            });

            if (field.hideIfEmpty && value === 'Unknown') return null;
            if (hidden) return null;

            if (children) return children({ key, value, field, data, listItemProps });
            return <InformationListItem key={key} value={value} {...listItemProps} />;
          })}
          {showPaymentDetails && <PaymentDetails data={data} />}
        </ListGroup>
      </FormCard>
    </div>
  );
}

InformationList.propTypes = {
  children: PropTypes.func,
  showPaymentDetails: PropTypes.bool,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  dataFields: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default memo(InformationList);
