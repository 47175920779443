import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import honeybadger from 'honeybadger-js';
import uuid from 'core/utilities/uuid';
import { ValidationContext } from './validation-container.component.js';
import { ValidationContext as ValidationContextOld } from 'core/validation/validation-block.component';

function useValidation({ value, validators = [], validateOnMount = false, onBlur, ref }) {
  const [validatorId] = useState(() => uuid());
  const [validationError, setValidationError] = useState('');

  let { attach, detach } = useContext(ValidationContext) || {};

  // if no context given try to see if we have the old context wrapped still (for slowly moving over old inputs)
  const oldContext = useContext(ValidationContextOld);
  const isUsingOldValidation = useRef();

  if (!attach) {
    attach = oldContext?.attach;
    detach = oldContext?.detach;
    isUsingOldValidation.current = true;
  }

  // runs through each validator stopping and returning invalid message if any validator returns false
  const checkIfIsInvalid = useCallback(
    ({ target: { value: newValue } } = { target: {} }) => {
      if (!validators || validators.length === 0) return null;
      const _value = newValue || value;
      let _message = '';

      for (let { validator, message } of validators) {
        if (validator === 'required' && !_value) {
          _message = message;
          break;
        }
        if (validator === 'non-empty') {
          const trimmedValue = _value.trim();
          if (!trimmedValue) {
            _message = message;
          }
          break;
        }
        if (typeof validator === 'function' && !validator(_value)) {
          _message = message;
          break;
        }
        if (validator instanceof RegExp && !(_value || '').match(validator)) {
          _message = message;
          break;
        }
      }

      setValidationError(_message);

      return _message;
    },
    [value, validators]
  );

  // attach and detach from the validation container
  useEffect(() => {
    // dont register if no validations
    if (!validators || validators.length === 0) return;
    const error = `There is no validation container to attach this validation. please use useValidationContainer hook to add a validation container.`;
    if (!attach) {
      console.error(error);
      honeybadger.notify(error);
      return;
    }

    attach({ validatorId, checkIfIsInvalid, ref });
    return () => {
      if (!validators || validators.length !== 0 || !detach) detach({ validatorId });
    };
  }, [detach, attach, validatorId, validators, checkIfIsInvalid, ref]);

  const validateInput = useCallback(
    event => {
      setValidationError(checkIfIsInvalid(event));
      if (onBlur) onBlur(event);
    },
    [checkIfIsInvalid, onBlur]
  );

  // if we are validating on mount or if container triggered errors then trigger errors locally as well
  useEffect(() => {
    if (validateOnMount) validateInput();
  }, [validateInput, validateOnMount]);

  // validate onBlur if this is a required field.
  const validationBlur = useCallback(
    event => {
      setValidationError(checkIfIsInvalid(event));
      if (onBlur) onBlur(event);
    },
    [onBlur, checkIfIsInvalid]
  );

  return [validationBlur, validationError, validateInput];
}

export default useValidation;
