import React from 'react';
import PropTypes from 'prop-types';

import uuid from 'core/utilities/uuid';
import { Input, AddField } from 'modules/common';
import { noop } from 'core/utilities';

function EditableList({
  items,
  onChange,
  eventName,
  newItem,
  isRequired = false,
  requiredErrorMessage = 'At least one item is required.',
  children,
  showAddButton = true,
  maxNumberOfItems = 9999,
  isMultiplier,
  disabled = false,
  additionalField,
  addFieldText = 'Add Field',
}) {
  const _newItem = () => {
    if (typeof newItem === 'function') return newItem();
    else return newItem;
  };

  items = items || [{ ..._newItem(), _isNew: true, id: uuid() }];

  const updateItem = ({ target: { name, value } }, item) => {
    const newValue = items.map(i => {
      if (i === item) {
        i = { ...i };
        i[name] = value;
      }

      return i;
    });

    onChange({ target: { name: eventName, value: newValue } });
  };

  const addItem = () => {
    const value = [...items, { ..._newItem(), _isNew: true, id: uuid() }];
    onChange({ target: { name: eventName, value } });
  };

  const removeItem = item => {
    const value = items.map(i => {
      if (i === item) {
        i = { ...i };
        i._destroy = true;
      }
      return i;
    });

    onChange({ target: { name: eventName, value } });
  };

  let numberOfItems = isMultiplier ? items.filter(amt => amt._isMultiplier) : items.filter(amt => !amt._isMultiplier);
  numberOfItems = numberOfItems.filter(c => !c._destroy).length;

  const showAddField = (showAddButton || numberOfItems === 0) && maxNumberOfItems > numberOfItems;

  return (
    <>
      {children({ updateItem, removeItem })}

      {additionalField}
      {showAddField && !disabled ? <AddField onClick={addItem} text={addFieldText} /> : null}

      {isRequired ? (
        <Input
          hidden
          value={numberOfItems}
          onChange={noop}
          validators={['required']}
          errorMessages={[requiredErrorMessage]}
        />
      ) : null}
    </>
  );
}

EditableList.propTypes = {
  onChange: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  newItem: PropTypes.any.isRequired,

  isRequired: PropTypes.bool,
  requiredErrorMessage: PropTypes.string,
  children: PropTypes.any.isRequired,
  showAddButton: PropTypes.bool,
  isMultiplier: PropTypes.bool,
  maxNumberOfItems: PropTypes.number,
  additionalField: PropTypes.any,
  addFieldText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default EditableList;
