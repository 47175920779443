import gql from 'graphql-tag';

const fragments = {
  teamPage: gql`
    fragment TeamPage on Page {
      created_at
      updated_at
      revv_uid
      url
      internalName
      slug
      publicTitle
      type
      status
      password
      callToAction
      recurringUpsell {
        revv_uid
        conversionRate
      }
      smsOptinUpsell {
        revv_uid
        conversionRate
      }
      doubleUpsell {
        revv_uid
        conversionRate
      }
      primaryColor
      secondaryColor
      backgroundColor
      layout
      position
      enableMobileField
      requireMobileField
      enableSmsOptin
      enableShippingDetails
      recurringAvailable
      recurringIntervalSelectable
      sourceCode
      pageAmounts {
        id
        amount
        position
      }
      recurringInterval
      recurringEndAt
      recurringDefaultValue
      confirmationRedirectBoolean
      confirmationRedirectUrl
      confirmationParagraph
      confirmationCustomLinkCopy
      confirmationCustomLink
      confirmationFacebookHandle
      confirmationTwitterHandle
      bundlerId
      locale
      disclaimer
      longDisclaimer
      mobileBannerAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      logoAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      backgroundAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      conduitPageCandidates {
        candidate {
          avatar {
            id
            image {
              id
              thumbnailUrl
            }
          }
          name
          revv_uid
        }
        position
      }
      conduitPageOrganizations {
        organization {
          avatar {
            id
            image {
              id
              thumbnailUrl
            }
          }
          name
          revv_uid
        }
        position
      }
    }
  `,
};

export const GET_TEAM_PAGES_QUERY = gql`
  query getTeamPages($page: Int, $limit: Int, $search: String, $status: StatusEnum) {
    viewer {
      pages(filters: { page: $page, limit: $limit, search: $search, status: $status }) {
        current_page
        page_count
        total_count
        results {
          created_at
          updated_at
          revv_uid
          internalName
          slug
          status
          url
        }
        raised_amounts {
          revv_uid
          raised_amount
        }
      }
    }
  }
`;
export const GET_TEAM_PAGE_QUERY = gql`
  query getTeamPage($revv_uid: String!) {
    viewer {
      pages(filters: { revvUid: $revv_uid }) {
        results {
          ...TeamPage
        }
      }
    }
  }
  ${fragments.teamPage}
`;

export const CREATE_TEAM_PAGE_MUTATION = gql`
  mutation createTeamPageMutation($page: CreatePageInput!) {
    userCreatePage(page: $page) {
      errors
      page {
        ...TeamPage
      }
    }
  }
  ${fragments.teamPage}
`;

export const UPDATE_TEAM_PAGE_MUTATION = gql`
  mutation updateTeamPageMutation($page: UpdatePageInput!) {
    userUpdatePage(page: $page) {
      errors
      page {
        ...TeamPage
      }
    }
  }
  ${fragments.teamPage}
`;

export const ARCHIVE_TEAM_PAGE_MUTATION = gql`
  mutation ArchiveTeamPageMutation($page: ArchivePageInput!) {
    userArchivePage(page: $page) {
      errors
      page {
        ...TeamPage
      }
    }
  }
  ${fragments.teamPage}
`;

export const GET_ALL_PUBLIC_CANDIDATES_QUERY = gql`
  query getCandidates($limit: Int, $search: String) {
    win_red_directory_candidates(filters: { limit: $limit, search: $search }) {
      results {
        name
        first_name
        last_name
        revv_uid
        avatar_image_url
        avatar {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
    }
  }
`;
