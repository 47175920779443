import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import WinRedSelect from './winred-select.component';
import { components } from 'react-select';

import Icon from '../../icon.component';

const PrependedSearchIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <Icon icon="search" size="lg" color="gray" style={{ position: 'absolute', left: 6 }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

PrependedSearchIcon.propTypes = {
  children: PropTypes.any,
};

function WinRedSelectSearch(props) {
  const components = useMemo(() => ({ ValueContainer: PrependedSearchIcon }), []);

  return <WinRedSelect components={components} prependSearchIcon {...props} />;
}

WinRedSelectSearch.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  pro: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isMulti: PropTypes.bool,
  errorIcon: PropTypes.string,
  invalidIcon: PropTypes.string,
  children: PropTypes.node,
  isClearable: PropTypes.bool,
};

export default WinRedSelectSearch;
