import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { AmplitudePropTypes, Icon, Typography, WinRedModal, withAmplitude } from 'modules/common';
import { Modal, Col, Row } from 'react-bootstrap';

const facebookMessage = encodeURIComponent(
  `I'm raising money for my favorite cause via WinRed! Make a donation to them through my fundraising page!`
);

const twitterMessage = encodeURIComponent(
  `I'm raising money for my favorite cause via @winred! Make a donation to them through my fundraising page!`
);

function ShareModal(props) {
  const { shareUrl, setShareUrl } = props;

  const shareOnTwitter = () => {
    props.logEvent('tweet team page');
    const encodedURL = encodeURIComponent(shareUrl);
    window.open(`https://twitter.com/intent/tweet?text=${twitterMessage}&url=${encodedURL}`, '_blank');
  };

  const shareOnFacebook = () => {
    props.logEvent('share team page on facebook');
    const encodedURL = encodeURIComponent(shareUrl);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=src=sdkpreparse&quote=${facebookMessage}&u=${encodedURL}`,
      '_blank'
    );
  };

  return (
    <WinRedModal show={!!shareUrl} onHide={() => setShareUrl(false)}>
      <Modal.Header style={{ borderBottom: 0 }} closeButton>
        <Modal.Title />
      </Modal.Header>
      <Typography variant="h5" className="text-center">
        Share your team page!
      </Typography>

      <Row className="mt-3 mb-4">
        <Col className={classNames('d-flex', 'justify-content-center', 'align-items-center')}>
          <button className="share-button twitter" onClick={shareOnTwitter}>
            <Icon icon="twitter" size="2x" color="white" className="mr-2 " />
            Twitter
          </button>
          <button
            className="share-button facebook"
            data-href={shareUrl}
            data-layout="button"
            data-size="large"
            onClick={shareOnFacebook}
          >
            <Icon icon="facebook" size="2x" color="white" className="mr-2" />
            Facebook
          </button>
        </Col>
      </Row>
    </WinRedModal>
  );
}
ShareModal.propTypes = {
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  shareUrl: PropTypes.any.isRequired,
  setShareUrl: PropTypes.func.isRequired,
};

export default compose(withAmplitude, withRouter)(ShareModal);
