import React from 'react';

import { Image } from 'modules/common';
import { getAvatar } from 'core/utilities';
import './avatar.scss';

function WinRedAvatar({ item, avatar }) {
  const _avatar = avatar || getAvatar(item);

  return (
    <div className="candidate-avatar-container">
      <Image src={_avatar} className="candidate-avatar" />
    </div>
  );
}

export default WinRedAvatar;
