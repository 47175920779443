import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';

import { Typography, TableInput, TableInputContainer, EditableList, Input, FloatGroup, Select } from 'modules/common';
import { mostRecentBillPaySentence } from 'modules/common/bill-pay-activity';
import { sortByLabel } from 'core/utilities';
import { generateAgency } from './tools';

export function CommitteeCardBillPay({ conduitCandidate, onChangeCommittee }) {
  const candidate = conduitCandidate.candidate || conduitCandidate.organization;

  let availableVendors = candidate.available_vendors
    .filter(vendor => {
      // if the fee exists on the candidate already then remove it but only if it's not destroyed
      const matchingCandidateFee = conduitCandidate.vendorFees?.find(fee => fee.partnershipId === vendor.id);

      return !matchingCandidateFee || matchingCandidateFee._destroy;
    })
    .map(vendor => ({ label: vendor.name, value: vendor }));

  availableVendors.sort(sortByLabel);

  const onSelectVendor = ({ target: { value } }, overwrittenVendorFee) => {
    // if selecting back and old fee then just use that one to set it back to _destroy false
    // we dont want to remove and destory the same object so we see if it already existed and if it did then add it back instead
    // of leaving the destroyed version there and having two of the same object (api doesnt like you destroying and adding the same object)
    const oldVendorFee = conduitCandidate.vendorFees.find(candidateFee => candidateFee.partnershipId === value.id);

    if (oldVendorFee) {
      let inplaceChangeIndex = null;

      let newVendorFess = conduitCandidate.vendorFees
        .map((candidateFee, index) => {
          if (candidateFee.partnershipId === oldVendorFee.partnershipId) {
            inplaceChangeIndex = index;
            return {
              ...oldVendorFee,
              _destroy: false,
              _isNew: false,
              feePercentage: value.defaultFeePercentage || '',
            };
          }

          return candidateFee;
        })
        .filter(candidateFee => candidateFee.id !== overwrittenVendorFee.id);

      // move item that we put back into array to bottom of array so it looks like a new object
      newVendorFess.push(newVendorFess.splice(inplaceChangeIndex, 1)[0]);

      return onChangeCommittee({ target: { name: 'vendorFees', value: newVendorFess } });
    }

    // this is if we are adding a new vendor fee altogether (not previously removed)
    const newVendorFess = conduitCandidate.vendorFees?.map(candidateFee => {
      if (candidateFee.id === overwrittenVendorFee.id) {
        return {
          ...value,
          partnershipId: value.id,
          name: value.name,
          _isNew: true,
          _destroy: false,
          feePercentage: value.defaultFeePercentage || '',
        };
      }

      return candidateFee;
    });

    onChangeCommittee({ target: { name: 'vendorFees', value: newVendorFess } });
  };

  const disableVendors = !candidate.can_access_vendors;

  return (
    <>
      <Typography className="mb-0" variant="bodyMedium">
        Bill-Pay(s) (Vendor Fees)
      </Typography>
      <Typography>
        Allow vendors to collect a share of the revenue that comes from this page for this specific committee.
      </Typography>

      <EditableList
        disabled={availableVendors.length === 0 || disableVendors}
        items={conduitCandidate.vendorFees || []}
        onChange={onChangeCommittee}
        eventName="vendorFees"
        newItem={generateAgency}
        addFieldText="Add Bill-Pay"
      >
        {({ updateItem, removeItem }) => {
          return (
            <>
              {conduitCandidate.vendorFees?.map(vendorFee => {
                if (vendorFee._destroy) return null;
                const _updateItem = event => updateItem(event, vendorFee);
                const _selectedVendor = availableVendors?.find(o => o.value === vendorFee.name);

                return (
                  <div key={vendorFee.id || vendorFee.revv_uid} className="mb-0 mb-md-3">
                    <TableInputContainer onRemove={() => removeItem(vendorFee)}>
                      <TableInput lg={7}>
                        <FloatGroup>
                          {vendorFee._isNew && !vendorFee.name ? (
                            <Select
                              name="name"
                              onChange={event => onSelectVendor(event, vendorFee)}
                              floatLabel="Vendor"
                              placeholder="Select Vendor..."
                              maxLength={100}
                              value={_selectedVendor || ''}
                              options={availableVendors}
                              validators={['required']}
                              errorMessages={['Vendor is required.']}
                            />
                          ) : (
                            <Input
                              name="name"
                              disabled
                              value={vendorFee.name}
                              onChange={_updateItem}
                              maxLength={100}
                              placeholder="Vendor"
                              description={vendorFee.last_change || ''}
                              validators={['required']}
                              errorMessages={['Vendor is required.']}
                            />
                          )}
                        </FloatGroup>
                      </TableInput>
                      <TableInput lg={4}>
                        <FloatGroup>
                          <Input
                            name="feePercentage"
                            value={vendorFee.feePercentage || ''}
                            onChange={_updateItem}
                            disabled={disableVendors}
                            moneyInput
                            max={100}
                            placeholder="Vendor Fee"
                            append={<InputGroup.Text>%</InputGroup.Text>}
                            validators={['required']}
                            errorMessages={['Vendor Fee is required.']}
                          />
                        </FloatGroup>
                      </TableInput>
                    </TableInputContainer>
                    {vendorFee.mostRecentActivity && (
                      <Typography tag="p" className="italic mt-2 text-lynx text-sm">
                        {mostRecentBillPaySentence(vendorFee.mostRecentActivity)}
                      </Typography>
                    )}
                  </div>
                );
              })}
            </>
          );
        }}
      </EditableList>
    </>
  );
}

CommitteeCardBillPay.propTypes = {
  conduitCandidate: PropTypes.object.isRequired,
  onChangeCommittee: PropTypes.func.isRequired,
};
