import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Typography } from 'modules/common';

function SubText({ description = '', description2 = '', children, className, removeMarginTop = false, ...rest }) {
  return (
    <Typography italic className={classnames('d-flex flex-column', className, { 'mt-1': !removeMarginTop })} {...rest}>
      <span>{children || description}</span>
      <span>{description2}</span>
    </Typography>
  );
}

SubText.propTypes = {
  description: PropTypes.node,
  description2: PropTypes.node,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default SubText;
