import React from 'react';
import PropTypes from 'prop-types';
import FormColumn from './form-column.component';

function FormDoubleColumnMain({ children, className = '', ...rest }) {
  return (
    <FormColumn className={'order-lg-1 order-md-1 order-sm-2 ' + className} xs={12} md={8} lg={8} {...rest}>
      {children}
    </FormColumn>
  );
}

FormDoubleColumnMain.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FormDoubleColumnMain;
