import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import uuid from 'core/utilities/uuid';

import { Error, withAmplitude, AmplitudePropTypes, FormBody } from 'modules/common';
import { profileSelector } from 'core/login';
import { setToast } from 'core/toast';
import { GET_TEAM_PAGES_QUERY, CREATE_TEAM_PAGE_MUTATION } from 'core/middleware/queries';

import PageForm from './team-pages/page-form.component';
import { defaultTeamPage, updateTeamPageForServer, getSaveTeamPageEventData, queryBuilder } from './pages';

class TeamPageNewContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: cloneDeep(defaultTeamPage(props)),
      mutation: {
        loading: false,
        errors: null,
      },
    };
  }

  componentDidMount() {
    this.props.logEvent('view team page details');
  }

  /**
   * updates page data from ui to server
   */
  updatePage = page => {
    const formattedPage = updateTeamPageForServer(page);

    return new Promise((resolve, reject) => {
      this.setState({ mutation: { loading: true } }, () => {
        this.props
          .savePage({ page: formattedPage })
          .then(
            ({
              data: {
                userCreatePage: { page, errors },
              },
            }) => {
              if (errors) {
                this.props.setToast({
                  message: errors,
                  isError: true,
                });
                this.setState({ mutation: { loading: false } });
                return reject(errors);
              }

              const eventParams = getSaveTeamPageEventData(page);
              this.props.logEvent('save team page', eventParams);

              this.props.setToast({
                message: 'Your team page has been saved.',
              });

              this.props.history.push(`/team-pages/pages/${page.revv_uid}`);
            }
          )
          .catch(errors => {
            this.props.setToast({
              message: errors,
              isError: true,
            });

            reject(errors);
            this.setState({ mutation: { loading: false } });
          });
      });
    });
  };

  render() {
    let { mutation, error, page } = this.state;
    const loading = mutation.loading;

    if (error || mutation.errors || mutation.error) return <Error error={error || mutation.errors || mutation.error} />;

    return (
      <FormBody>
        <PageForm pageTitle="Create Page" page={page} loading={loading} savePage={this.updatePage} isNew />
      </FormBody>
    );
  }
}

TeamPageNewContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  setToast: PropTypes.func.isRequired,
  savePage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: profileSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(
  withRouter,
  withAmplitude,
  graphql(CREATE_TEAM_PAGE_MUTATION, {
    props: ({ mutate }) => ({
      savePage: variables => {
        variables = { ...variables };

        // generate slug based on title
        variables.page.slugPrefix = `${uuid().split('-')[0]}-`;
        variables.page.slug = variables.page.publicTitle
          .replace(/[^0-9A-Za-z ]/g, '')
          .replace(/ /g, '-')
          .substring(0, 35);

        // on save combine slug and slug prefix
        variables.page.slug = variables.page.slugPrefix + variables.page.slug;
        delete variables.page.slugPrefix;

        return mutate({
          variables,
          refetchQueries: () => {
            return [
              {
                query: GET_TEAM_PAGES_QUERY,
                variables: queryBuilder({}),
              },
            ];
          },
        });
      },
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(TeamPageNewContainer);
