import gql from 'graphql-tag';

export const GET_ORGANIZATION_DASHBOARD_DONATIONS = gql`
  query getOrganizationDashBoardDonations(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String!
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        donations_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          count
          average_donation_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DASHBOARD_RECURRING = gql`
  query getOrganizationDashBoardRecurring(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String!
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        recurring_donations_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DASHBOARD_GROWTH = gql`
  query getOrganizationDashBoardGrowth(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String!
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        subscriptions_mrr_growth_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          net_subscription_amount
          active_subscriptions_mrr {
            date
            donation_amount
          }
          canceled_subscriptions_mrr {
            date
            donation_amount
          }
          net_subscriptions_mrr {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DASHBOARD_SUBSCRIPTIONS = gql`
  query getOrganizationDashBoardSubscriptions(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String!
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        subscriptions_mrr_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;
