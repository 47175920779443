import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import Input from '../input.component';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const WinRedStripeCreditCardInput = ({ onTokenGeneration, cardValidationError, setIsCardLoading }) => {
  const wrapperProps = { onTokenGeneration, cardValidationError, setIsCardLoading };
  return (
    <Elements stripe={stripePromise}>
      <Wrapper {...wrapperProps} />
    </Elements>
  );
};

WinRedStripeCreditCardInput.propTypes = {
  onTokenGeneration: PropTypes.func.isRequired,
  cardValidationError: PropTypes.bool.isRequired,
};

const Wrapper = ({ onTokenGeneration, cardValidationError, setIsCardLoading }) => {
  const [stripeError, setStripeError] = useState(false);
  const [stripeMessage, setStripeMessage] = useState('Credit card is required');
  const [cardComplete, setCardComplete] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (cardValidationError) setStripeError(true);
  }, [cardValidationError]);

  const handleGetToken = async () => {
    const cardElement = elements.getElement(CardElement);

    try {
      setIsCardLoading(true);
      const results = await stripe.createToken(cardElement);
      onTokenGeneration(results);
      setIsCardLoading(false);
    } catch (error) {
      setIsCardLoading(false);
      console.error(error);
    }
  };

  const handleOnChange = e => {
    const { complete, error } = e;
    setStripeError(!complete);
    setCardComplete(complete);
    setStripeMessage(error?.message);
    if (complete) handleGetToken();
  };

  return (
    <div autoComplete="new-password" className="winred-credit-card">
      <CardElement
        autocomplete="new-password"
        onChange={handleOnChange}
        options={{
          classes: { base: `react-stripe-card-element ${stripeError ? 'stripe-error' : ''}` },
          style: {
            base: {
              fontSize: '15px',
              color: '#1e2b3a',
              fontWeight: '500',
              '::placeholder': {
                color: stripeError ? '#dd4d5f' : '#1e2b3a',
                fontWeight: '500',
              },
            },
          },
        }}
      />
      <Input
        hidden
        value={cardComplete}
        name="stripe-card"
        onChange={() => {}}
        validators={['required']}
        errorMessages={[stripeMessage || 'Credit card is required']}
      />
    </div>
  );
};

Wrapper.propTypes = {
  onTokenGeneration: PropTypes.func.isRequired,
  cardValidationError: PropTypes.bool.isRequired,
};

export default WinRedStripeCreditCardInput;
