import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { COMPARISON_LABELS, Typography } from 'modules/common';

function AmountInput({ title, name, amountName, onChange, value, valuePredicate, classNameFormGroup }) {
  const valuePredicateIcon = (COMPARISON_LABELS[valuePredicate] || {}).icon || '';

  const _onChange = event => {
    const newVal = event.target.value;

    /* Don't allow for values with more than 2 decimal places */
    if (!newVal.match(/\.\d\d\d+/)) {
      onChange(event);
    }
  };

  const handleOnWheel = e => {
    e.target.blur();
    return;
  };

  return (
    <Form.Group className={classNameFormGroup}>
      <Typography variant="bodyMedium" className="mb-1">
        {title}
      </Typography>
      <InputGroup>
        <DropdownButton as={InputGroup.Prepend} variant="outline-primary" title={valuePredicateIcon}>
          {Object.entries(COMPARISON_LABELS).map(([key, value]) => (
            <Dropdown.Item onSelect={() => onChange({ target: { name, value: value.value } })} eventKey={key} key={key}>
              {value.icon}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <div className="input-group-prepend input-group-append">
          <InputGroup.Text>$</InputGroup.Text>
        </div>
        <Form.Control
          value={value || ''}
          type="number"
          inputMode="decimal"
          pattern="\d*"
          className="winred-input input-with-prepend"
          name={amountName}
          onChange={_onChange}
          onWheel={handleOnWheel}
        />
      </InputGroup>
    </Form.Group>
  );
}

AmountInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classNameFormGroup: PropTypes.string,
  amountName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  valuePredicate: PropTypes.string,
};

AmountInput.defaultProps = {
  amountName: 'amount',
};

export default AmountInput;
