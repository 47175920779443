import { convertEpochTime } from 'core/utilities';
import { IndexTableContextMenu } from 'modules/common/lists';
import Typography from 'modules/common/typography.component';
import React, { Fragment } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import transparent from '../../../../../../assets/images/transparent-checker.avif';
import { Image } from 'modules/common';

export function ImagesTableMobile({ items, selectedImageId, handleOnImageSelection, dropdownAction }) {
  if (!items) return null;

  return items.map(item => {
    const { id, created_at, thumbnailUrl, imageFileName } = item;

    return (
      <Fragment key={id}>
        <div
          className={`list-item-card ${id === selectedImageId && 'selected-image'}`}
          tabIndex="0"
          onClick={() => handleOnImageSelection(id)}
          onKeyPress={() => handleOnImageSelection(id)}
        >
          <Row className="flex justify-between px-0">
            <Col xs={3}>
              {thumbnailUrl ? (
                <div
                  style={{ backgroundImage: `url(${transparent})` }}
                  className="w-14 h-14 bg-cover bg-center ml-2 flex justify-center items-center"
                >
                  <Image alt={imageFileName} src={thumbnailUrl} className="w-full h-full object-contain" />
                </div>
              ) : null}
            </Col>
            <Col xs={7}>
              <Typography className="mb-1">{convertEpochTime(created_at)}</Typography>
              <Typography variant="bodyMedium" className="c--mirage mb-0 break-all">
                {imageFileName}
              </Typography>
            </Col>
            <Col xs={2}>
              <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
                <Dropdown.Item eventKey="copy">Copy URL</Dropdown.Item>
                <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
              </IndexTableContextMenu>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  });
}
