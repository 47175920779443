import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import { clearToast, toastSelector } from 'core/toast';
import { withAmplitude } from 'modules/common';
import './toast.scss';

class ToastContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.toast && prevProps.toast !== this.props.toast) {
      // if error then report to amplitude
      if (this.props.toast?.isError) {
        this.props.logEvent('toast error', {
          message: this.message,
        });
      }

      clearTimeout(this.toastTimer);
      if (!this.props.toast?.isPermanent) this.clearToast();
    }
  }

  clearToast() {
    this.toastTimer = setTimeout(() => {
      this.props.clearToast();
    }, this.props.toast?.duration || 3000);
  }

  get message() {
    const { toast } = this.props;
    if (!toast) return null;

    let toastMessage = toast.message?.message || toast.message || 'An Unknown Error Occurred';
    if (Array.isArray(toastMessage)) {
      toastMessage = toastMessage[0];
    }

    // remove or replace unwanted details
    toastMessage = toastMessage.replace(/graphql error: /g, '');
    if (/network error: /g.test(toastMessage))
      toastMessage = 'There was an error. Check your connection and try again.';

    return toastMessage;
  }

  render() {
    const { toast, isTop } = this.props;
    if (!toast) return null;
    const toastMessage = this.message;

    return (
      <div
        className={classNames(
          'toast-wrapper',
          { error: toast.isError },
          { success: !toast.isError },
          { warning: toast.isWarning },
          { 'toast-top': isTop },
          { 'toast-bottom': !isTop }
        )}
      >
        <Container>
          <div className="toast-contents">
            {toastMessage}
            <div
              className="toast-close-icon"
              onClick={this.props.clearToast}
              onKeyPress={this.props.clearToast}
              role="button"
              tabIndex="0"
            >
              X
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

ToastContainer.propTypes = {
  toast: PropTypes.object,
  isTop: PropTypes.bool,
  clearToast: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    toast: toastSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearToast: () => dispatch(clearToast()),
  };
};

export default compose(withRouter, withAmplitude, connect(mapStateToProps, mapDispatchToProps))(ToastContainer);
