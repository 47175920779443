import React from 'react';
import PropTypes from 'prop-types';

import { Input, FloatGroup } from 'modules/common';
import CustomFieldRequired from './required.component';
import CustomFieldDefault from './default-value.component';

const fieldOptions = [
  { key: true, value: 'Checked' },
  { key: false, value: 'Unchecked' },
];

function CustomFieldCheckbox({ updateCustomField, formState, disabled }) {
  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="label"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={disabled}
          placeholder="Field Label"
          value={formState.label}
          maxLength="255"
          onChange={updateCustomField}
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          name="placeholder"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={disabled}
          placeholder="Field Option"
          value={formState.placeholder}
          maxLength="255"
          onChange={updateCustomField}
          validators={['required']}
          errorMessages={['Field Option is required.']}
        />
      </FloatGroup>

      <CustomFieldDefault
        handleUpdateValue={updateCustomField}
        fieldOptions={fieldOptions}
        disabled={disabled}
        selectedValue={!!formState.default_value}
        label="Default Behavior"
      />

      <CustomFieldRequired onChange={updateCustomField} value={formState.required} disabled={disabled} />
    </>
  );
}

CustomFieldCheckbox.propTypes = {
  formState: PropTypes.object.isRequired,
  updateCustomField: PropTypes.func.isRequired,
};

export default CustomFieldCheckbox;
