import React, { useCallback, useMemo } from 'react';
import WinRedFormInput from './winred-form-input.component';
import './inputs.scss';

function WinRedInputNumbers({ onChange, max, removeDecimalPoint = false, csvZipCodeValues = false, ...rest }) {
  const removeSymbolsFromInput = useMemo(() => {
    if (removeDecimalPoint) {
      return ['e', '+', '-', '.'];
    }
    return ['e', '+', '-'];
  }, [removeDecimalPoint]);

  const _onChange = useCallback(
    ({ target: { name, value } }) => {
      // make sure only digits are entered
      if(csvZipCodeValues){
        value = value.replace(/[^0-9,\s]/g, '');
      } else {
        value = value.replace(/[^0-9.]/g, '');
      }
      if (max && value > max) return;
      onChange({ target: { name, value } });
    },
    [csvZipCodeValues, max, onChange]
  );

  const handlePaste = e => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const containsForbiddenChar = removeSymbolsFromInput.some(char => pastedText.includes(char));
    if (!containsForbiddenChar) {
      _onChange({ target: { name: e.target.name, value: pastedText } });
      return;
    }
  };

  return (
    <WinRedFormInput
      onKeyDown={evt => removeSymbolsFromInput.includes(evt.key) && evt.preventDefault()}
      onPaste={handlePaste}
      type="number"
      onChange={_onChange}
      {...rest}
    />
  );
}

export default WinRedInputNumbers;
