import { useCallback } from 'react';
import { useAmplitude as _useAmplitude } from 'react-amplitude-hooks';
import { isDevelopment } from 'core/utilities';

function useAmplitude() {
  const { logEvent } = _useAmplitude();

  /* eslint-disable react-hooks/exhaustive-deps */
  const _logEvent = useCallback((...args) => {
    if (isDevelopment) console.debug('amplitude', args);
    logEvent(...args);
  }, []);

  return [_logEvent];
}

export default useAmplitude;
