import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Icon, ToolTip, withAmplitude } from 'modules/common';
import { formatNumberWithCommas } from 'core/utilities';
import ShareModal from './shareModal.component';

function RenderMobile({ items, navigateToItem, logEvent }) {
  const [shareUrl, setShareUrl] = useState(false);
  if (!items) return null;

  return (
    <>
      {items.map(item => {
        const { revv_uid, internalName, totalRaised } = item;
        const _totalRaised = `$${formatNumberWithCommas((totalRaised || 0).toFixed(2))}`;
        const navigateHandler = () => navigateToItem(revv_uid);

        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            key={revv_uid}
            className="list-item-card"
            onClick={navigateHandler}
            onKeyPress={navigateHandler}
            tabIndex="0"
            role="link"
          >
            <Row className="no-gutters">
              <Col xs="2" className="list-item-card-icon">
                <ToolTip tooltip="Live">
                  <Icon icon="donate" color="green" />
                </ToolTip>
              </Col>
              <Col xs="10">
                <Row className="no-gutters">
                  <Col className="list-item-card-title">{internalName}</Col>
                </Row>
                <Row className="no-gutters">
                  <Col className="list-item-card-text">{_totalRaised}</Col>
                </Row>
                <Row className="no-gutters mt-3">
                  <Col className="list-item-card-text">
                    <button
                      className="share-team-page"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShareUrl(item.url);
                        logEvent('share team page');
                      }}
                    >
                      Share Page
                    </button>
                    <button
                      className="view-team-page"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(item.url);
                      }}
                    >
                      View Page
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      })}
      <ShareModal shareUrl={shareUrl} setShareUrl={setShareUrl} />
    </>
  );
}

export default withAmplitude(RenderMobile);
