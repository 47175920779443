import gql from 'graphql-tag';

export const INVITE_ORGANIZATION_USER_MUTATION = gql`
  mutation inviteOrganizationUser($email: String!, $roleType: RoleTypeEnum!, $organizationRevvUid: String!) {
    organizationInviteUser(email: $email, roleType: $roleType, organizationRevvUid: $organizationRevvUid) {
      errors
      user {
        revv_uid
        first_name
        last_name
        email
      }
    }
  }
`;

export const REMOVE_ORGANIZATION_USERS_MUTATION = gql`
  mutation removeOrganizationUsers($profileUids: [String!]!, $organizationRevvUid: String!) {
    organizationRemoveUsers(profileUids: $profileUids, organizationRevvUid: $organizationRevvUid) {
      errors
      allRemoved
    }
  }
`;
