import React from 'react';
import PropTypes from 'prop-types';
import { FormCardSpaced } from 'modules/common';
import { AcceptButton, UpsellVideoEmbed, EnhancedUpsell, UpsellName, UpsellAction, Widgets } from './sections';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';

function MobileDonation(props) {
  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
        <AcceptButton {...props} />
      </FormCardSpaced>

      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />

      <Widgets {...props} widgetOptions={MobileDonation.widgets} />
    </>
  );
}

MobileDonation.title = 'Mobile Opt-In';
MobileDonation.description = 'Ask donors to opt in to SMS messages.';
MobileDonation.icon = 'mobile-upsell';
MobileDonation.type = 'MOBILEOPTIN';
MobileDonation.widgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

MobileDonation.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default MobileDonation;
