import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';
import { Typography } from 'modules/common';
import './trend.scss';

function TrendBox({ children, divider, name, ...rest }) {
  return (
    <Col sm={3} lg="auto" className={classnames('trend-box', { 'trends-divider': divider })} {...rest}>
      <Typography variant="h5" className="d-flex">
        {name}
      </Typography>
      <div className="d-flex">{children}</div>
    </Col>
  );
}

TrendBox.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
TrendBox.defaultProps = {
  divider: true,
};

export default TrendBox;
