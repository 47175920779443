import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '../../drawer/drawer.component';
import '../widgets.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, useValidationContainer, useToast } from 'modules/common';
import { ThermometerDrawerBody } from '../drawer/thermometer/thermometer-body.component';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import {
  CREATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION,
  CREATE_THERMOMETER_CAMPAIGN_MUTATION,
  UPDATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION,
  UPDATE_THERMOMETER_CAMPAIGN_MUTATION,
} from 'core/middleware/queries';
import { formatThermometerForSever, formatThermometerForUi } from '../drawer/thermometer/formating';

export const ThermometerWidgetDrawer = ({ open, closeDrawer, data, onChange, toggleSelectedThermometer }) => {
  const [ValidationContainer, checkValidationErrors] = useValidationContainer();
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);
  const [formData, setFormData] = useState({ ...data });
  const setToast = useToast();

  const [createThermometerWidget] = useMutation(
    isOrganization ? CREATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION : CREATE_THERMOMETER_CAMPAIGN_MUTATION
  );
  const [updateThermometerWidget] = useMutation(
    isOrganization ? UPDATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION : UPDATE_THERMOMETER_CAMPAIGN_MUTATION
  );

  const onChangeFormData = ({ target: { name, value } }) => {
    if (name === 'thermometer_type') {
      return setFormData({ ...formData, [name]: value, goals: [{ amount_cents: '', _isNew: true }], boost: '' });
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleOnClose = () => {
    setFormData({ ...data });
    closeDrawer();
  };

  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);
  const handleClickOutside = event => {
    const clickedBackdrop = open && backdropRef && backdropRef.contains(event.target);

    if (clickedBackdrop) {
      return handleOnClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  useEffect(() => {
    if (open) {
      setFormData({ ...data });
    }
  }, [open, data]);

  const handlePostSaveOrUpdate = campaign => {
    onChange({ target: { name: 'widgets', value: formatThermometerForUi(campaign, formData) } });
    toggleSelectedThermometer();
    handleOnClose();
  };

  const onSaveOrUpdate = async () => {
    if (checkValidationErrors().length > 0) return;
    const isEdit = { ...data }?.isEdit;
    const selectedMutation = isEdit ? updateThermometerWidget : createThermometerWidget;
    const variables = formatThermometerForSever(formData, profileCandidate);
    try {
      const response = await selectedMutation({ variables });
      const dataReturnValue = `${isOrganization ? 'organization' : 'candidate'}${isEdit ? 'Update' : 'Create'}Campaign`;
      const { errors, campaign } = response.data[dataReturnValue];
      if (errors) {
        setToast({ message: errors, isError: true });
      }
      setToast({ message: `Your thermometer widget has been ${isEdit ? 'updated' : 'created'}.` });
      handlePostSaveOrUpdate(campaign);
    } catch (error) {
      setToast({
        message: error,
        isError: true,
      });
    }
  };
  return (
    <div>
      <Drawer
        open={open}
        className={'widget-drawer'}
        leftAlignTitle={''}
        subtitle={''}
        icon={'times'}
        setbackdropRef={setbackdropRef}
        footer={
          <Container className="widget-footer p-3">
            <Row>
              <Col className="d-flex">
                <Button variant="outline-primary" className="mr-2" onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button variant="success" className="mr-2" onClick={onSaveOrUpdate}>
                  Save
                </Button>
              </Col>
            </Row>
          </Container>
        }
      >
        <ValidationContainer>
          <ThermometerDrawerBody toggleDrawer={handleOnClose} data={formData} onChange={onChangeFormData} />
        </ValidationContainer>
      </Drawer>
    </div>
  );
};

ThermometerWidgetDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
