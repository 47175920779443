import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import { Button } from '../form';
import { noop } from 'core/utilities';

class SearchFilter extends Component {
  static defaultProps = {
    target: null,
    onBlur: noop,
    disableSubmit: false,
    disableClear: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  handleClickOutside = event => {
    const { show, onBlur } = this.props;
    if (this.target && !this.target.contains(event.target) && show) {
      onBlur();
    }
  };

  setRef = node => (this.target = node);

  render() {
    const { target, show, disableSubmit, disableClear, onSubmit, onClear, children } = this.props;
    if (!target) return null;

    return (
      <div
        className={classNames('filter-popover', 'd-none', 'position-absolute', { 'd-block': show })}
        ref={this.setRef}
      >
        {children}
        <ButtonToolbar>
          <Button variant="primary" className="btn-md rounded-pill" onClick={onClear} disabled={disableClear}>
            Clear
          </Button>
          <Button variant="primary" className="btn-md rounded-pill" onClick={onSubmit} disabled={disableSubmit}>
            Done
          </Button>
        </ButtonToolbar>
      </div>
    );
  }
}

SearchFilter.propTypes = {
  target: PropTypes.instanceOf(Element),
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disableSubmit: PropTypes.bool,
  disableClear: PropTypes.bool,
  children: PropTypes.node,
};

export default SearchFilter;
