import { Drawer, DrawerHeader, SpinnerContainer } from '../../../common';
import classNames from 'classnames';
import UpsellFlowFooter from './upsell-footer.component';
import { ValidationBlock } from '../../../../core/validation';
import DrawerBody from './drawer-body.component';
import React from 'react';
import PropTypes from 'prop-types';

export function UpsellDrawer({
  openNewUpsell,
  selectedUpsellComponent,
  editedUpsell,
  originalEditedUpsell,
  editedUpsellGroup,
  primaryColor,
  secondaryColor,
  setbackdropRef,
  onConfirmCloseEdit,
  onSaveEditedUpsell,
  validationBlock,
  setRef,
  setUpsellComponent,
  onUpdateEditedUpsell,
  savingUpsell,
  gettingUpsellData,
  hidePanelUpsell = false,
}) {
  return (
    <div className="upsell-drawer">
      <Drawer
        open={openNewUpsell}
        className={classNames({
          'upsell-selected': selectedUpsellComponent,
        })}
        leftAlignTitle={!!selectedUpsellComponent}
        subtitle={selectedUpsellComponent?.description}
        icon={selectedUpsellComponent?.icon ?? 'times'}
        setbackdropRef={setbackdropRef}
        footer={
          <UpsellFlowFooter
            editedUpsell={editedUpsell}
            originalEditedUpsell={originalEditedUpsell}
            selectedUpsellComponent={selectedUpsellComponent}
            onConfirmCloseEdit={onConfirmCloseEdit}
            onSaveEditedUpsell={onSaveEditedUpsell}
            validationBlock={validationBlock}
            disabledUpsellFlow={editedUpsellGroup?._isDisabled}
          />
        }
      >
        <div ref={setRef}>
          <DrawerHeader title={selectedUpsellComponent?.title ?? 'Create New Upsell'} toggleOpen={onConfirmCloseEdit} />
          {savingUpsell || gettingUpsellData ? (
            <SpinnerContainer overlay />
          ) : (
            <>
              <ValidationBlock ref={validationBlock}>
                <DrawerBody
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  selectedUpsellComponent={selectedUpsellComponent}
                  setUpsellComponent={setUpsellComponent}
                  onUpdateEditedUpsell={onUpdateEditedUpsell}
                  editedUpsell={editedUpsell}
                  upsells={editedUpsellGroup?.upsell_pages}
                  hidePanelUpsell={hidePanelUpsell}
                />
              </ValidationBlock>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}

UpsellDrawer.propTypes = {
  openNewUpsell: PropTypes.bool,
  selectedUpsellComponent: PropTypes.func,
  editedUpsell: PropTypes.object,
  originalEditedUpsell: PropTypes.object,
  editedUpsellGroup: PropTypes.object,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  setbackdropRef: PropTypes.func,
  onConfirmCloseEdit: PropTypes.func,
  onSaveEditedUpsell: PropTypes.func,
  validationBlock: PropTypes.object,
  setRef: PropTypes.func,
  setUpsellComponent: PropTypes.func,
  onUpdateEditedUpsell: PropTypes.func,
  savingUpsell: PropTypes.bool,
  gettingUpsellData: PropTypes.bool,
  hidePanelUpsell: PropTypes.bool,
};
