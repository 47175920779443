import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Container, Row, Col } from 'react-bootstrap';

import { Typography, FormHeader, RefreshData } from 'modules/common';
import NavTabs from './list-header/nav-tabs.component';
import './list-header.scss';

function ListHeader({
  title,
  activeTabKey,
  activeTabKeyName,
  onChangeFilter,
  navTabs,
  actionsBar,
  children,
  subtitle,
  subtitleNode,
  disabled,
  renderedAt,
  onRefresh,
  loading,
  subtitleVariant,
  classNameTitle,
  dataType,
  bottomBar,
}) {
  return (
    <FormHeader
      navTabs={
        <NavTabs
          title={title}
          activeTabKey={activeTabKey}
          activeTabKeyName={activeTabKeyName}
          onChangeFilter={onChangeFilter}
          navTabs={navTabs}
          disabled={disabled}
        >
          <div
            className={classnames(bottomBar ? 'bottom-bar' : 'list-header-refresh', {
              'lonely-header-refresh': navTabs.length === 0,
            })}
          >
            {bottomBar ? <div>{bottomBar}</div> : null}
            {onRefresh ? (
              <RefreshData
                onRefresh={onRefresh}
                loading={loading}
                renderedAt={renderedAt}
                dataType={dataType || title}
              />
            ) : null}
          </div>
        </NavTabs>
      }
    >
      <Col lg={12}>
        <Row>
          <Col>
            <Container fluid className="pl-0">
              <Row className="d-flex flex-column">
                <Col>
                  <Typography variant="h1" className={classnames(classNameTitle, { 'mb-0': !!subtitle })}>
                    {title || ''}
                  </Typography>
                </Col>
                <Col xs={12}>
                  {subtitle ? (
                    <Typography className="mb-0" variant={subtitleVariant}>
                      {subtitle}
                    </Typography>
                  ) : null}
                  {subtitleNode}
                </Col>
              </Row>
            </Container>
            {children}
          </Col>
          {actionsBar ? (
            <Col sm="auto" className="justify-content-end">
              <div className="d-inline-flex">{actionsBar}</div>
            </Col>
          ) : null}
        </Row>
      </Col>
    </FormHeader>
  );
}

ListHeader.propTypes = {
  title: PropTypes.any.isRequired,
  activeTabKey: PropTypes.string,
  onChangeFilter: PropTypes.func,
  navTabs: PropTypes.any,
  actionsBar: PropTypes.any,
  children: PropTypes.any,
  subtitle: PropTypes.any,
  disabled: PropTypes.bool,
  renderedAt: PropTypes.instanceOf(Date),
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  bottomBar: PropTypes.element,
};

ListHeader.defaultProps = {
  navTabs: [],
};

export default ListHeader;
