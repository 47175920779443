import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../nav-bar.scss';

function MobileSubMenu({ showMobileSubNav, children }) {
  return (
    <div
      className={classnames('nav', 'd-none', 'flex-column', {
        'winred-nav__active-submenu': showMobileSubNav,
      })}
    >
      {children}
    </div>
  );
}

MobileSubMenu.propTypes = {
  showMobileSubNav: PropTypes.bool,
  children: PropTypes.any,
};

export default MobileSubMenu;
