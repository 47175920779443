import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { Error, SpinnerContainer } from 'modules/common';
import { Campaign } from 'modules/pages/common/campaign.component';
import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { GET_UPSELL_CAMPAIGNS, GET_ORGANIZATION_UPSELL_CAMPAIGNS } from 'core/middleware/queries';

import { createCampaignOptions } from 'modules/pages/common/page-common.tools';

export default function ConnectedCampaign({ initiativeType, editedUpsell, onUpdateEditedUpsell }) {
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const variables = { limit: 9999, organizationRevvUid: profileCandidate.organization_revv_uid };
  const query = isOrganization ? GET_ORGANIZATION_UPSELL_CAMPAIGNS : GET_UPSELL_CAMPAIGNS;

  const { data, loading, error } = useQuery(query, { variables });

  if (loading) return <SpinnerContainer />;
  if (error) return <Error error={data.error} />;

  const campaignOptions = createCampaignOptions(data);

  return (
    <Campaign
      inUpsells={true}
      campaignOptions={campaignOptions}
      item={editedUpsell}
      onChange={onUpdateEditedUpsell}
    />
  );
}

ConnectedCampaign.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};
