import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';

function NavBarMenuPeople({
  toggleSubNav,
  hasActiveChildPeople,
  showMobileSubNav,
  organizationRevvUid,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  const donorRoute = isAgency
    ? `/${organizationRevvUid}/vendors/people/donors`
    : `/${organizationRevvUid}/people/donors`;
  const leadRoute = isAgency ? `/${organizationRevvUid}/vendors/people/leads` : `/${organizationRevvUid}/people/leads`;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildPeople,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showPeopleSubNav')}>
          People
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size={'xs'}
            color={hasActiveChildPeople ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>
      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        <Nav.Link eventKey={donorRoute} href={donorRoute}>
          Donor Profiles
        </Nav.Link>
        <Nav.Link eventKey={leadRoute} href={leadRoute}>
          Leads
        </Nav.Link>
      </MobileSubMenu>
    </>
  );
}

NavBarMenuPeople.propTypes = {
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildPeople: PropTypes.bool.isRequired,
  showMobileSubNav: PropTypes.bool.isRequired,
};

export default NavBarMenuPeople;
