import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';
import { ListHeader, Button } from 'modules/common';
import { setToast } from 'core/toast';

import { profileSelector } from 'core/login';
import { noop } from 'core/utilities';
import { GET_TEAM_PAGES_QUERY } from 'core/middleware/queries';
import { setTeamPagesFilter, teamPagesFilterSelector, initialPagesState } from './store';
import { TeamPagesListBody, queryBuilder } from './team-pages';
import { IndexTable, withFilter, AmplitudePropTypes, SpinnerContainer, withAmplitude } from 'modules/common';
import './pages.scss';

function TeamPagesList(props) {
  const [pages, updatePages] = useState({});

  // Merge the raised amounts into the pages results for display.
  useEffect(() => {
    if (!props.data.viewer) return;
    const { pages } = props.data.viewer;

    // Map all of the page ids to the raised amounts.
    const amounts = {};
    pages.raised_amounts.forEach(amount => {
      amounts[amount.revv_uid] = amount.raised_amount;
    });

    // Set the total raised for each page.
    pages.results = pages.results.map(page => {
      page.totalRaised = amounts[page.revv_uid];
      return page;
    });

    updatePages(pages);
  }, [props.data.viewer, updatePages, props.profile]);

  const handleCreateNewTeamPage = () => {
    props.logEvent('create team page');
    props.history.push('/team-pages/pages/new');
  };

  if (props.data.loading) {
    return <SpinnerContainer />;
  }

  return (
    <main>
      <ListHeader
        title={props.listKey}
        onChangeFilter={noop}
        actionsBar={
          <div className="create-btn">
            <Button variant="success" size="lg" ariaLabel="create a new page" onClick={handleCreateNewTeamPage}>
              Create Team Page
            </Button>
          </div>
        }
      />
      <IndexTable>
        <TeamPagesListBody
          onChangeFilter={props.onChangeFilter}
          pages={pages}
          handleCreateNewPage={handleCreateNewTeamPage}
        />
      </IndexTable>
    </main>
  );
}

TeamPagesList.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  logEvent: AmplitudePropTypes.logEvent.isRequired,
  data: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,

  initialState: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  downloadReportKey: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  setReduxFilter: PropTypes.func.isRequired,
};
TeamPagesList.defaultProps = {
  data: {},
};

const mapStateToProps = state => {
  return {
    profile: profileSelector(state),
    filter: teamPagesFilterSelector(state),
    downloadReportKey: 'export_user_team_pages_report',
    listKey: 'Team Pages',
    initialState: initialPagesState.teamPagesFilter,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setReduxFilter: filter => dispatch(setTeamPagesFilter(filter)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_TEAM_PAGES_QUERY, {
    options: props => ({ variables: queryBuilder(props.filter) }),
  }),
  withAmplitude,
  withFilter
)(TeamPagesList);
