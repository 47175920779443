import React, { useCallback, useState } from 'react';
import WinRedSelect from './winred-select.component';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import './inputs.scss';
import { Icon } from '../../index';

export function WinRedSelectGrouped({
  onChange,
  name,
  options,
  value,
  placeholder,
  validators,
  validateOnMount,
  maxWidthDropDown,
  ...rest
}) {
  const [expandedArray, setExpandedArray] = useState([]);

  const handleHeaderClickCallBack = useCallback(
    id => {
      const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
      const classes = node.classList;
      if (classes.contains('collapsed-select-option')) {
        if (expandedArray.includes(id)) {
          setExpandedArray(expandedArray.filter(item => item !== id));
        }
        node.classList.remove('collapsed-select-option');
      } else {
        if (!expandedArray.includes(id)) {
          setExpandedArray([...expandedArray, id]);
        }
        node.classList.add('collapsed-select-option');
      }
    },
    [expandedArray]
  );

  const CustomGroupHeading = props => {
    return (
      <div className="group-heading-wrapper" onClick={() => handleHeaderClickCallBack(props.id)}>
        <components.GroupHeading className="group-heading-component" {...props}>
          <div className="black-text">
            <span>
              <Icon icon={expandedArray.includes(props.id) ? 'caret-right' : 'caret-down'} color="gray" size="sm" />{' '}
            </span>
            {props.children}
          </div>
        </components.GroupHeading>
      </div>
    );
  };

  return (
    <WinRedSelect
      maxWidthDropDown={maxWidthDropDown}
      options={options}
      isGrouped={true}
      blurInputOnSelect={false}
      closeMenuOnSelect={true}
      components={{ GroupHeading: CustomGroupHeading }}
      onChange={onChange}
      value={value}
      name={name}
      placeholder={placeholder}
      minMenuHeight={180}
      maxMenuHeight={360}
      validators={validators}
      validateOnMount={validateOnMount}
      {...rest}
    />
  );
}

WinRedSelectGrouped.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.any.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  validateOnMount: PropTypes.bool,
  maxWidthDropDown: PropTypes.bool,
};
