import React, { useCallback } from 'react';
import { hasDuplicateField } from '../../pathways.tools';
import PropTypes from 'prop-types';
import { StepRequirementsRowDesktop } from './conditions-row/desktop.component';
import { Breakpoint } from 'react-socks';
import { StepRequirementsRowMobile } from './conditions-row/mobile.component';

export function StepRequirementRow({
  editedStep,
  condition,
  conditionOptions,
  predicateOptions,
  customValueOptions,
  onChange,
  campaignsList,
  disabledStep,
}) {
  const selectedConditionKey = conditionOptions
    ?.flatMap(group => group?.options)
    ?.find(option => option?.value === condition?.key);

  const selectedConditionPredicate = predicateOptions[selectedConditionKey?.value]?.find(
    option => option?.value === condition?.predicate
  );

  const _onChangeKey = useCallback(
    value => {
      const newConditions = editedStep?.conditions?.map(item => {
        if (item.revv_uid === condition.revv_uid) {
          return { ...item, key: value.value, condition_type: value?.condition_type, condition_type_label: value?.condition_type_label,  predicate: null, value: [] };
        } else {
          return item;
        }
      });
      onChange({ target: { name: 'conditions', value: newConditions } });
    },
    [condition, onChange, editedStep]
  );

  const _onChangePredicate = useCallback(
    event => {
      const newConditions = editedStep?.conditions?.map(item => {
        if (item.revv_uid === condition.revv_uid) {
          return { ...item, predicate: event.target.value, value: [] };
        } else {
          return item;
        }
      });
      onChange({ target: { name: 'conditions', value: newConditions } });
    },
    [condition, onChange, editedStep]
  );

  const _onChangeValue = useCallback(
    event => {
      const newConditions = editedStep?.conditions?.map(item => {
        if (item.revv_uid === condition.revv_uid) {
          return { ...item, value: event.target.value };
        } else {
          return item;
        }
      });
      onChange({ target: { name: 'conditions', value: newConditions } });
    },
    [condition, onChange, editedStep]
  );

  const _onRemove = useCallback(() => {
    if (disabledStep) return null;
    const newConditions = editedStep.conditions.map(item => {
      if (item.revv_uid === condition.revv_uid) {
        return { ...item, _destroy: true };
      } else {
        return item;
      }
    });
    onChange({ target: { name: 'conditions', value: newConditions } });
  }, [condition, onChange, editedStep, disabledStep]);

  const fieldValidators = [
    {
      message: 'Field is required.',
      validator: 'required',
    },
    {
      message: 'Redundant fields are not allowed.',
      validator: inputValue => !hasDuplicateField(editedStep.conditions, inputValue),
    },
  ];

  return (
    <>
      <Breakpoint small down>
        <StepRequirementsRowMobile
          condition={condition}
          _onRemove={_onRemove}
          campaignsList={campaignsList}
          predicateOptions={predicateOptions}
          conditionOptions={conditionOptions}
          _onChangeKey={_onChangeKey}
          _onChangePredicate={_onChangePredicate}
          _onChangeValue={_onChangeValue}
          customValueOptions={customValueOptions}
          selectedConditionKey={selectedConditionKey}
          selectedConditionPredicate={selectedConditionPredicate}
          fieldValidators={fieldValidators}
          disabledStep={disabledStep}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <StepRequirementsRowDesktop
          condition={condition}
          _onRemove={_onRemove}
          campaignsList={campaignsList}
          predicateOptions={predicateOptions}
          conditionOptions={conditionOptions}
          _onChangeKey={_onChangeKey}
          _onChangePredicate={_onChangePredicate}
          _onChangeValue={_onChangeValue}
          customValueOptions={customValueOptions}
          selectedConditionKey={selectedConditionKey}
          selectedConditionPredicate={selectedConditionPredicate}
          fieldValidators={fieldValidators}
          disabledStep={disabledStep}
        />
      </Breakpoint>
    </>
  );
}

StepRequirementRow.propTypes = {
  editedStep: PropTypes.object.isRequired,
  condition: PropTypes.object,
  conditionOptions: PropTypes.array,
  predicateOptions: PropTypes.object,
  customValueOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  campaignsList: PropTypes.array,
  disabledStep: PropTypes.bool,
};
