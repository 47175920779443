import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { profileCandidateSelector } from 'core/login';
import { ButtonLink, Table, Typography, IndexTableContextMenu, WinRedChip, ToolTip, Icon } from 'modules/common';
import { formatAmount, convertEpochTime, formatNumberWithCommas, noEvent } from 'core/utilities';
import { UPSELL_COMPONENT_MAP } from '../tools';
import { SHOW_REVENUE_PER_VIEW, DISABLED_UPSELL_TYPES } from '../types';

function UpsellListItemDesktop({ items, navigateToItem, dropdownAction }) {
  const profileCandidate = useSelector(profileCandidateSelector);

  const tableBody = items.map(item => {
    const { revv_uid, name, upsell_statistics, upsell_group_count, upsell_pathway_count, created_at, public_upsell, bill_pay_exists } = item;
    const rowDisabled = DISABLED_UPSELL_TYPES.includes(item.type);

    const upsellComponent = UPSELL_COMPONENT_MAP[item.type] || {};

    return (
      <Table.Row
        key={revv_uid}
        onClick={() => (!rowDisabled ? navigateToItem(item) : {})}
        className={classNames('d-none', 'd-md-table-row', {
          'click-disabled': rowDisabled,
        })}
      >
        <Table.Cell textAlign="center" className="icon-cell">
          <ToolTip tooltip={upsellComponent.title}>
            <Icon icon={upsellComponent.icon} size="lg" />
          </ToolTip>
        </Table.Cell>

        <Table.Cell>{convertEpochTime(created_at)}</Table.Cell>
        <Table.Cell className="d-flex align-items-center flex-wrap">
          <Typography variant="bodyMedium" className="c--mirage mb-0 mr-2">
            {name}
          </Typography>
          {public_upsell ? (
            <WinRedChip className="mr-2" variant="yellow">
              Public
            </WinRedChip>
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {bill_pay_exists ? (
            <WinRedChip className="cursor-default" variant="purple">
              Bill-Pay
            </WinRedChip>
          ) : null}
        </Table.Cell>
        <Table.Cell>{upsell_group_count}</Table.Cell>
        <Table.Cell>{upsell_pathway_count}</Table.Cell>
        <Table.Cell>{formatNumberWithCommas(upsell_statistics?.view)}</Table.Cell>
        {SHOW_REVENUE_PER_VIEW.includes(item.type) ? (
          <Table.Cell>{formatAmount(upsell_statistics?.revenue_per_view || 0)}</Table.Cell>
        ) : (
          <Table.Cell />
        )}
        <Table.Cell>{formatNumberWithCommas(upsell_statistics?.accepted)}</Table.Cell>
        <Table.Cell>{((upsell_statistics?.percentage || 0) * 100).toFixed(2)}%</Table.Cell>
        <Table.Cell className="pl-0">
          {!item.upsell_preview_url ? null : (
            <ButtonLink
              disabled={rowDisabled}
              skinnyButton
              onClick={noEvent}
              onKeyPress={noEvent}
              className="mr-2"
              url={item.upsell_preview_url}
            >
              Preview
            </ButtonLink>
          )}
        </Table.Cell>
        <Table.Cell textAlign="center" noClick>
          <IndexTableContextMenu
            disabled={rowDisabled}
            onSelect={eventKey => (!rowDisabled ? dropdownAction(eventKey, item) : {})}
          >
            <Dropdown.Item
              as={Link}
              to={`/${profileCandidate.organization_revv_uid}/pages/upsell-flows/upsells/${item.revv_uid}`}
              eventKey="details"
            >
              View Details
            </Dropdown.Item>
            <Dropdown.Item eventKey="duplicate">Duplicate</Dropdown.Item>
            {item.upsell_preview_url ? <Dropdown.Item eventKey="preview">Preview</Dropdown.Item> : null}
            <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
          </IndexTableContextMenu>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Upsell Name</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>Attached Flows</Table.HeaderCell>
          <Table.HeaderCell>Attached Pathways</Table.HeaderCell>
          <Table.HeaderCell>Views</Table.HeaderCell>
          <Table.HeaderCell>$ Per View</Table.HeaderCell>
          <Table.HeaderCell>Accepted</Table.HeaderCell>
          <Table.HeaderCell>Opt-In Rate</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell className="d-none d-md-table-cell" />
        </Table.Row>
      </Table.Head>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

UpsellListItemDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};

export default UpsellListItemDesktop;
