import React from 'react';
import PropTypes from 'prop-types';

import { Button, FormColumn, Icon, Typography, FormContainer, FormCardSpaced } from 'modules/common';
import { noop } from 'core/utilities';

function EmptyList({
  icon,
  text,
  description,
  buttonText,
  name,
  onClick,
  buttonVariant,
  iconColor,
  iconClassName,
  className,
  iconSize = "3x",
  ...rest
}) {
  const _onClick = () => onClick(name);

  return (
    <FormCardSpaced className={className}>
      <FormContainer>
        <FormColumn className="d-flex flex-column align-items-center empty-list-container">
          {icon ? <Icon icon={icon} size={iconSize} color={iconColor} className={iconClassName} /> : null}
          <Typography variant="h5" className="mt-3">
            {text}
          </Typography>
          {description && <Typography>{description}</Typography>}
          {buttonText && buttonText.length > 0 && (
            <Button variant={buttonVariant} size="lg" className="mt-3" onClick={onClick ? _onClick : null} {...rest}>
              {buttonText}
            </Button>
          )}
        </FormColumn>
      </FormContainer>
    </FormCardSpaced>
  );
}

EmptyList.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  description: PropTypes.any,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonVariant: PropTypes.string,
  iconColor: PropTypes.string,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
};

EmptyList.defaultProps = {
  buttonText: '',
  buttonVariant: 'primary',
  className: 'priary',
  description: '',
  iconColor: 'green',
  iconClassName: '',
  name: '',
  onClick: noop,
};

export default EmptyList;
