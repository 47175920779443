import { useState, useEffect } from 'react';

export const DEFAULT_PREVIEW_AMT = 100;

export const useMultiplierAmounts = ({ previewAmount, editedUpsell, maxPersonalDonation }) => {
  const maxDonationAmount = parseFloat(editedUpsell.maximum_amount) || maxPersonalDonation;
  const minDonationAmount = parseFloat(editedUpsell.minimum_amount) || 1;

  const [amounts, setAmounts] = useState([]);
  const [preselectedIndex, setPreselectedIndex] = useState(0);
  const { page_amounts, multiplier_amounts, round_values } = editedUpsell;

  useEffect(() => {
    const pageAmounts = (page_amounts || []).filter(amt => !amt._destroy && amt._isMultiplier === !!multiplier_amounts);
    const preselected = pageAmounts.findIndex(pa => pa.preselected);
    setPreselectedIndex(preselected);
  }, [page_amounts, multiplier_amounts]);

  useEffect(() => {
    if (!multiplier_amounts) {
      const newAmounts = page_amounts?.filter(amt => !amt._isMultiplier && !amt._destroy).map(amt => amt.amount);

      setAmounts([...new Set(newAmounts)]);
      return;
    }

    // get only multiplier amounts, set to fixed if needed, set to min/max if given, filter out dups
    const newAmounts = page_amounts
      .filter(amt => amt._isMultiplier && !amt._destroy)
      .map(amt => {
        let currentAmount = previewAmount === '' ? DEFAULT_PREVIEW_AMT : previewAmount;
        if (currentAmount > maxDonationAmount) {
          currentAmount = maxDonationAmount;
        } else if (currentAmount < minDonationAmount) {
          currentAmount = minDonationAmount;
        }

        let text = currentAmount * amt.amount;

        if (round_values) {
          // round to nearest 1 if under 10 else round to nearest 10 if above 10
          text = text >= 10 ? Math.round(text / 10) * 10 : Math.round(text);
          /* When comparing these numbers we only care about the first two digits of
          precision so if that is the same as the rounded value, use the rounded
          value. */
        } else if (parseFloat(text.toFixed(2)) === Math.round(text)) {
          text = Math.round(text);
        } else if (Math.round(text) !== text) {
          // if is float then make sure we have two decimal places
          text = text.toFixed(2);
        }

        return text;
      });

    setAmounts([...new Set(newAmounts)]);
  }, [minDonationAmount, maxDonationAmount, previewAmount, page_amounts, round_values, multiplier_amounts]);

  return [amounts, preselectedIndex];
};
