import React from 'react';
import PropTypes from 'prop-types';

import { WinRedLineChart, Card, ChartHeader, ReportingTrend } from 'modules/common';
import DateFilter from '../date-filter.component';
import '../dashboard.scss';

const SUBTITLE =
  'Total gross donation volume in the given date range, including all donations that have gone to your organization. Donations that have been refunded or disputed will not count toward these values.';

function ReportingGraph({ candidate, dateRange, onSelectDateRange }) {
  const data = candidate.donations_graph;

  return (
    <Card className="dashboard-graph">
      <Card.Body>
        <ChartHeader title="Donations" subtitle={SUBTITLE} results={data.results}>
          <ReportingTrend name="Raised" amount={data.gross_amount || 0} divide />
          <ReportingTrend name="Average Donation" amount={data.average_donation_amount || 0} divide />
          <ReportingTrend name="Donations" number={data.count} />
        </ChartHeader>

        <WinRedLineChart
          fillLine
          data={data.results}
          xProp="date"
          yProp="donation_amount"
          dateRange={dateRange.dateRange}
        />

        <DateFilter onSelectDateRange={onSelectDateRange} dateRange={dateRange} />
      </Card.Body>
    </Card>
  );
}

ReportingGraph.propTypes = {
  candidate: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
};

export default ReportingGraph;
