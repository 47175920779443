import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

import { UpsellName, ConnectedUpsellCommittee, Geographical } from './sections';

export function CommitteeUpsell(props) {
  return (
    <>
      <FormCard>
        <UpsellName {...props} />
      </FormCard>

      <ConnectedUpsellCommittee
        onChange={props.onUpdateEditedUpsell}
        selectedCandidates={props.editedUpsell.candidates}
        splitEvenly={props.editedUpsell.split_evenly}
        disabled={props.editedUpsell._isDisabled}
        showConduitingAndDataSharingToggle={!props.editedUpsell._isPreset}
        committeeHasAmounts
        className="pb-3"
      />

      <Geographical {...props} />
    </>
  );
}

CommitteeUpsell.title = 'Committee Panel';
CommitteeUpsell.description = 'Ask donors to donate to different committees without leaving the page.';
CommitteeUpsell.icon = 'upsell-committee-panel';
CommitteeUpsell.type = 'CONDUITPANEL';
CommitteeUpsell.widgets = [];

CommitteeUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};
