import React from 'react';
import PropTypes from 'prop-types';

import { FloatGroup, Input, FormCard, Icon } from 'modules/common';

function CssOverride({ onChange, cssOverride, className, disabled }) {
  return (
    <FormCard className={className} title="CSS Override" subtitle="Enter any custom CSS here to style your page.">
      <FloatGroup>
        <Input
          type="text"
          as="textarea"
          name="cssOverride"
          rows="4"
          value={cssOverride || ''}
          onChange={onChange}
          disabled={disabled}
          description={
            <>
              <Icon icon="exclamation-triangle" color="red" />
              <span>
                &nbsp;WinRed doesn&apos;t validate custom code for you, so be sure to check your code before publishing.
              </span>
            </>
          }
        />
      </FloatGroup>
    </FormCard>
  );
}

CssOverride.propTypes = {
  onChange: PropTypes.func.isRequired,
  cssOverride: PropTypes.string,
  className: PropTypes.string,
};

export default CssOverride;
