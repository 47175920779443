import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Icon, Typography } from 'modules/common';

function WinRedDropdownItem({
  icon,
  iconColor = 'gray',
  iconClassname = '',
  iconSize = 'lg',
  disabled = false,
  hide = false,
  title,
  url,
  description,
  name,
  onClick = null,
}) {
  const _onClick = useCallback(event => onClick && onClick(event, name), [onClick, name]);

  if (hide) return null;

  return (
    <Dropdown.Item
      disabled={disabled}
      className={classnames('d-flex pointer', {
        disabled,
      })}
      as={url ? Link : 'div'}
      to={url}
      onClick={_onClick}
    >
      {icon ? (
        <div className={'winred-dropdown-icon ' + (description ? 'pr-3 mt-1' : 'pr-2')}>
          <Icon className={iconClassname} icon={icon} color={iconColor} size={iconSize} />
        </div>
      ) : null}
      <div>
        <Typography variant={description ? 'bodyMedium' : null} className="mb-0">
          {title}
        </Typography>
        {description ? <Typography className="mb-0 d-flex flex-column">{description}</Typography> : null}
      </div>
    </Dropdown.Item>
  );
}

WinRedDropdownItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  description: PropTypes.node,
  onClick: PropTypes.func,
};

export default WinRedDropdownItem;
