import React, { useState } from 'react';
import FileSelector from '../../file-selector.component';
import { SpinnerContainer } from 'modules/common/loading';
import { ImageListBody } from './image-list-body.component';
import { WinRedSearchInput } from '../../inputs';
import Button from '../../button.component';
import { Icon } from 'modules/common';

export const ImageDrawerBody = ({
  handleFiles,
  imagesList,
  selectedImageId,
  setSelectedImageId,
  maxFileSize,
  loading,
  refetch,
  imageUri,
  onSelectedImageDeletion,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const handleCloseFilter = () => {
    setSearchText('');
    setIsFilter(false);
  };

  const onChangeSearch = ({ target: { value } }) => {
    if (value === '') {
      setSearchText(value);
      setIsFilter(false);
      return;
    }
    if (!isFilter) {
      setIsFilter(true);
    }
    setSelectedImageId(null);
    setSearchText(value);
    setFilteredItems(imagesList.filter(image => image.imageFileName.toLowerCase().includes(value.toLowerCase())));
  };

  if (loading) return <SpinnerContainer />;
  return (
    <div>
      {/* Upload Photo Area */}
      <form encType="multipart/form-data">
        <FileSelector onFiles={handleFiles} maxFileSize={maxFileSize} variant="upload-white" />
      </form>
      {/* Search Input */}
      <WinRedSearchInput
        onChange={onChangeSearch}
        className="my-3 rounded-full"
        placeholder="Search file name..."
        name="searchText"
        value={searchText}
      />
      {/* Image Display */}
      {isFilter ? (
        <>
          <Button key={1} className="cursor-default mr-2 my-2" variant="primary" skinnyButton>
            <div className="d-flex justify-content-center">
              <div>{searchText}</div>
              <div
                className="pointer ml-2 no-outline"
                onClick={handleCloseFilter}
                role="button"
                tabIndex="0"
                onKeyPress={handleCloseFilter}
              >
                <Icon icon="times" color="white" size="lg" />
              </div>
            </div>
          </Button>
        </>
      ) : null}
      <div className="imageList-container">
        <ImageListBody
          images={isFilter ? filteredItems : imagesList}
          setSelectedImageId={setSelectedImageId}
          selectedImageId={selectedImageId}
          isFilter={isFilter}
          handleCloseFilter={handleCloseFilter}
          refetch={refetch}
          imageUri={imageUri}
          onSelectedImageDeletion={onSelectedImageDeletion}
        />
      </div>
    </div>
  );
};
