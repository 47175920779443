import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ValidatorComponent } from 'core/validation';
import { ValidationBlock } from 'core/validation';
import { noEvent, noop } from 'core/utilities';
import { Icon, Typography, Button, Input } from 'modules/common';
import './collapsibleCard.scss';

class CollapsibleCard extends ValidatorComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.open,
      // collapsible body isnt rendered if collapsed - this forces render even if collapse (its just hidden by css)
      // use this to allow input validation inside collapsible body even if body is collapsed
      // set this to true when a collapsible has been opened so it's always "open" (in DOM)
      forceRender: props.forceRender,
      hasErrors: false,
    };

    this.validationCollapse = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open !== this.props.open) {
      this.setState({ isOpen: this.props.open });
    }

    const { hasErrors } = prevState;

    // if a collapsible has errors always check to see if it still has errors when anything changes
    // if the body has no more errors we want to get rid of the error state on the collapsible
    // need to be careful as setState is triggering an update so can easily cause infinite recursive rerenders
    if (hasErrors) {
      const errors = this.validationCollapse.current.errors();
      const _hasErrors = errors.length > 0;
      if (hasErrors === _hasErrors) return; // if no changes then dont trigger a rerender - can cause infinite render loop otherwise

      if (this._isMounted)
        this.setState(state => ({ hasErrors: _hasErrors, isOpen: _hasErrors ? true : state.isOpen }));
    }
  }

  toggleIsOpen = () => {
    // make sure there are no errors before collapsing
    const hasErrors = this.validationCollapse.current.errors() !== false;
    if (this._isMounted) {
      this.setState(
        state => ({ isOpen: hasErrors ? true : !state.isOpen, hasErrors }),
        () => this.props.onToggleOpen(this.state.isOpen)
      );
    }
  };

  componentDidMount() {
    // if body has been lazy loaded then stagger the render of body
    if (!this.props.forceRender)
      setTimeout(() => {
        if (this._isMounted) this.setState({ forceRender: true });
      }, 50 * this.props.index || 1);

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validationCallback = () => {
    const errors = this.validationCollapse.current.errors();
    if (errors && this._isMounted) this.setState({ hasErrors: true, isOpen: true });
    return !errors;
  };

  removeCollapsibleCard = event => {
    event && event.preventDefault();
    event && event.stopPropagation();
    this.props.removeCollapsibleCard(event);
  };

  render() {
    const {
      children,
      title,
      subtitle,
      customHeader,
      disableCollapse,
      removeCollapsibleCard,
      isDraggable,
      disableDragToggle,
      customRemoveButton,
      centerExpandBtns,
      removeText = 'Remove',
      collapseOnButtonOnly = false,
      bottomHeaderComponent,
    } = this.props;
    const { isOpen, forceRender, hasErrors } = this.state;

    const handleCollapseToggle = () => {
      return disableCollapse ? noop() : this.toggleIsOpen;
    };

    const renderRemoveButton = () =>
      customRemoveButton ? (
        <div
          onClick={this.removeCollapsibleCard}
          className={classnames('float-right collapse-remove-btn custom-remove-icon', { 'mt-1': !disableCollapse })}
        >
          {customRemoveButton}
        </div>
      ) : (
        <Button
          variant="error"
          onClick={this.removeCollapsibleCard}
          className={classnames('float-right collapse-remove-btn', { 'mt-1': !disableCollapse })}
        >
          {removeText}
        </Button>
      );

    const renderCardHeader = () => (
      <>
        <div className="d-flex align-items-center pr-0 mr-0 collapsible-header-content">
          {title}
          {subtitle && <Typography className="collapsible-subtitle">{subtitle}</Typography>}
        </div>
        <div onClick={noEvent} onKeyPress={noEvent} className="collapsible-btns">
          {!!centerExpandBtns ? (
            <div className={!!centerExpandBtns ? 'center-buttons' : ''}>
              <div>
                {removeCollapsibleCard ? renderRemoveButton() : null}

                {disableCollapse ? null : (
                  <Button
                    onClick={disableCollapse ? undefined : this.toggleIsOpen}
                    className={classnames('float-right', { 'mt-1': !disableCollapse })}
                    alt={true}
                  >
                    {isOpen ? 'Collapse' : 'Expand'}
                    <Icon
                      icon={isOpen ? 'minus-circle' : 'plus-circle'}
                      color="gray"
                      size="lg"
                      className="pointer ml-2"
                    />
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div>
              {removeCollapsibleCard ? renderRemoveButton() : null}

              {disableCollapse ? null : (
                <Button
                  onClick={disableCollapse ? undefined : this.toggleIsOpen}
                  className={classnames('float-right', { 'mt-1': !disableCollapse })}
                  alt={true}
                >
                  {isOpen ? 'Collapse' : 'Expand'}
                  <Icon
                    icon={isOpen ? 'minus-circle' : 'plus-circle'}
                    color="gray"
                    size="lg"
                    className="pointer ml-2"
                  />
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    );

    return (
      <>
        <Input
          hidden
          value={hasErrors}
          name="collapsibleCard"
          onChange={noop}
          validators={[this.validationCallback]}
          errorMessages={['']}
        />

        <ValidationBlock ref={this.validationCollapse}>
          <div
            className={classnames(`collapsible-card`, {
              'collapsible-card-errors': hasErrors,
              'collapsible-card-errors-closed': !isOpen,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              className={classnames(
                `collapsible-card-header ${bottomHeaderComponent ? 'flex flex-col' : 'flex justify-between'} mx-0`,
                {
                  open: isOpen,
                  pointer: !disableCollapse,
                  'collapsible-drag': isDraggable,
                  'custom-header': customHeader,
                }
              )}
              onMouseEnter={() => {
                if (!!disableDragToggle) disableDragToggle(false);
              }}
              onMouseLeave={() => {
                if (!!disableDragToggle) disableDragToggle(true);
              }}
            >
              {bottomHeaderComponent ? (
                <div className="flex justify-between">{renderCardHeader()}</div>
              ) : (
                renderCardHeader()
              )}

              {bottomHeaderComponent ? bottomHeaderComponent : null}
            </div>
            {isOpen || forceRender ? <div className={classnames({ 'collapse-body': !isOpen })}>{children}</div> : null}
          </div>
        </ValidationBlock>
      </>
    );
  }
}

CollapsibleCard.propTypes = {
  title: PropTypes.any.isRequired,
  customHeader: PropTypes.bool,
  customRemoveButton: PropTypes.any,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any,
  disableCollapse: PropTypes.bool,
  isDraggable: PropTypes.bool,
  subtitle: PropTypes.string,
  removeCollapsibleCard: PropTypes.func,
  forceRender: PropTypes.bool,
  onToggleOpen: PropTypes.func,
  disableDragToggle: PropTypes.func,
  removeText: PropTypes.string,
  centerExpandBtns: PropTypes.bool,
  bottomHeaderComponent: PropTypes.any,
};

CollapsibleCard.defaultProps = {
  children: null,
  subtitle: '',
  customHeader: false,
  customRemoveButton: null,
  disableCollapse: false,
  isDraggable: false,
  forceRender: true,
  onToggleOpen: noop,
};

export default CollapsibleCard;
