import React from 'react';
import PropTypes from 'prop-types';

import { WinRedBarChart, Card, ReportingTrend, ChartHeader } from 'modules/common';
import DateFilter from '../date-filter.component';

import '../dashboard.scss';

const SUBTITLE =
  "Your recurring program's total value of subscriptions created and canceled in the given date range. The best single measure of your recurring program's health.";

function GrowthGraph({ candidate, dateRange, onSelectDateRange }) {
  const data = candidate.subscriptions_mrr_growth_graph;

  const results = data.active_subscriptions_mrr.map((item, i) => {
    return {
      date: item.date,
      donation_amount: item.donation_amount,
      donation_amount2: (data.canceled_subscriptions_mrr[i]?.donation_amount ?? 0) * -1,
      diff: data.net_subscriptions_mrr[i]?.donation_amount,
    };
  });

  return (
    <Card className="dashboard-graph">
      <Card.Body>
        <ChartHeader title="Recurring Revenue Growth" subtitle={SUBTITLE} results={results} headerLength={8}>
          <ReportingTrend name="Value of Subscriptions Created" amount={data.net_subscription_amount} />
        </ChartHeader>

        <WinRedBarChart
          data={results}
          xProp="date"
          yProp="donation_amount"
          yProp2="donation_amount2"
          yDiffProp="diff"
          dateRange={dateRange.dateRange}
        />

        <DateFilter onSelectDateRange={onSelectDateRange} dateRange={dateRange} />
      </Card.Body>
    </Card>
  );
}

GrowthGraph.propTypes = {
  candidate: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
};

export default GrowthGraph;
