import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import { useToast, useAmplitude } from 'modules/common';

function useReportDownload({ query, variables, downloadReportKey }) {
  const setToast = useToast();
  const [logEvent] = useAmplitude();
  const client = useApolloClient();

  const downloadReport = useCallback(
    event => {
      client
        .query({ query, variables })
        .then(response => {
          const viewer = response.data.viewer;
          const _response = viewer.candidate || viewer.state_level_organization || viewer.vendor || viewer;
          const message = _response[downloadReportKey];

          // if no reports then will return null
          if (!message) {
            setToast({
              message: 'We couldn’t find that report.',
              isError: true,
            });
            return;
          }

          setToast({ message });
          logEvent(`download ${downloadReportKey}`, {
            records: _response[downloadReportKey]?.total_count || '',
          });
        })
        .catch(response => {
          setToast({
            message: response.message,
            isError: true,
          });
        });
    },
    [client, downloadReportKey, logEvent, setToast, query, variables]
  );

  return downloadReport;
}

export default useReportDownload;
