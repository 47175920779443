import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Icon, ToolTip } from 'modules/common';
import { convertEpochTime, formatAmount } from 'core/utilities';
import { getTeamDonationIconColor, getTeamDonationTooltip } from '../team-donations.tools';

function renderMobile({ items }) {
  if (!items) return null;

  return items.map(item => {
    const { revv_uid, created_at, first_name, last_name, email, amount, status } = item;
    const iconColor = getTeamDonationIconColor(status);
    const toolTip = getTeamDonationTooltip(status);

    return (
      <div key={revv_uid} className="list-item-card">
        <Row className="no-gutters">
          <Col xs="2" className="list-item-card-icon">
            <ToolTip tooltip={toolTip}>
              <Icon icon="donate" color={iconColor} />
            </ToolTip>
          </Col>
          <Col xs="10">
            <Row className="no-gutters">
              <Col className="list-item-card-title">{`${first_name} ${last_name}`}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text recording-sensitive">{email}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{convertEpochTime(created_at)}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{created_at}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{formatAmount(amount)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });
}

export default renderMobile;
