import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';

import { Typography } from 'modules/common';
import logo from 'assets/images/winred-logo.svg';

function LoginHeader({ title, subtitle, children }) {
  return (
    <div className="d-flex flex-column justify-content-center h-100">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="mb-5 text-center">
              <img src={logo} alt="WinRed logo" className="height-6 mb-5" />
              <Typography variant="h1">{title}</Typography>
              <Typography variant="body">{subtitle}</Typography>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} md={5} lg={4}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

LoginHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default LoginHeader;
