import gql from 'graphql-tag';

export const GET_CONNECTED_PAGES_QUERY = gql`
  query getConnectedPages(
    $page: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $sourceCandidateRevvUid: String
    $limit: Int
    $excludeTeamPages: Boolean
    $teamPagesOnly: Boolean
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        conduit_inclusion(
          filters: {
            page: $page
            search: $search
            updatedAtStart: $dateRangeStart
            updatedAtEnd: $dateRangeEnd
            limit: $limit
            excludeTeamPages: $excludeTeamPages
            sourceCandidateRevvUid: $sourceCandidateRevvUid
          }
        ) {
          current_page
          page_count
          total_count
          results {
            created_at
            revv_uid
            origin
            type
            data_sharing_allowed
            raised_amount
            split_percentage
            preview_url
            page_revv_uid
          }
        }
        pages(
          filters: {
            page: $page
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            limit: $limit
            teamPagesOnly: $teamPagesOnly
          }
        ) {
          current_page
          page_count
          total_count
          results {
            created_at
            updated_at
            revv_uid
            type
            status
            url
            authorCallout
          }
          raised_amounts {
            revv_uid
            raised_amount
          }
          allow_data_sharing {
            data_sharing_allowed
            revv_uid
          }
        }
      }
    }
  }
`;
