import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormCard, ImagePrompt, ConfirmModal, Input } from 'modules/common';
import { noop } from 'core/utilities';

function ImageOnUpsell({ editedUpsell, onUpdateEditedUpsell }) {
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);

  return (
    <FormCard classNameFooter="bg--catskill-white" title="Add Image" subtitle="Add an image to your upsell card.">
      <ImagePrompt
        imageUri={
          editedUpsell.logo_assigned_image && editedUpsell.logo_assigned_image.image
            ? editedUpsell.logo_assigned_image.image.thumbnailUrl
            : null
        }
        name="logo_assigned_image"
        selectImageAction={onUpdateEditedUpsell}
        imageType="LOGO"
        disabled={editedUpsell._isDisabled}
        removeImageAction={() => setShowRemoveLogoModal(true)}
        isNested
      />
      <Input
        hidden
        value={
          editedUpsell.logo_assigned_image && editedUpsell.logo_assigned_image.image
            ? editedUpsell.logo_assigned_image.image.thumbnailUrl
            : ''
        }
        onChange={noop}
      />
      <ConfirmModal
        show={showRemoveLogoModal}
        title="Remove Image"
        buttonText="Remove"
        handleClose={() => setShowRemoveLogoModal(false)}
        buttonColor="cancel"
        onSubmit={() => {
          const value = {
            destroy: true,
            imageId: editedUpsell.logo_assigned_image.image.id,
            id: editedUpsell.logo_assigned_image.id,
          };

          onUpdateEditedUpsell({ target: { name: 'logo_assigned_image', value } });
          setShowRemoveLogoModal(false);
        }}
      >
        Are you sure you want to remove this image from your upsell?
      </ConfirmModal>
    </FormCard>
  );
}

ImageOnUpsell.propTypes = {
  className: PropTypes.string,
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default ImageOnUpsell;
