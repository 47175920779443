import React from 'react';
import PropTypes from 'prop-types';

import { convertEpochTime, formatNumberWithCommas } from 'core/utilities';
import { Icon, ToolTip, WinRedChip } from 'modules/common';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { computeIconColor, computeTooltip } from '../../upsells';

export function UpsellPathwayItemsMobile({ items, navigateToItem }) {

  return items.map(item => {
    const {
      revv_uid,
      name,
      page_count,
      status,
      created_at,
      creator,
      upsell_count,
      bill_pay_exists
    } = item;

    const iconColor = computeIconColor(status);
    const toolTip = computeTooltip(status);
    const navigateHandler = () => navigateToItem(revv_uid);

    return (
      <div
        key={revv_uid}
        className="list-item-card"
        onClick={navigateHandler}
        onKeyPress={navigateHandler}
        tabIndex="0"
        role="link"
      >
        <Row className="no-gutters">
          <Col xs="2" className="list-item-card-icon">
            <ToolTip tooltip={toolTip}>
              <Icon icon="upsell-pathway-icon" color={iconColor} size="sm" />
            </ToolTip>
          </Col>
          <Col xs="10">
            <Row className="no-gutters">
              <Col className="list-item-card-title">
                {name}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                {bill_pay_exists ? (
                  <WinRedChip className="ml-0 ml-md-2 mt-1 mt-md-0 cursor-default" variant="purple">
                    Bill-Pay
                  </WinRedChip>
                ) : null}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">Upsells: {formatNumberWithCommas(upsell_count)}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">
                Attached Pages: {formatNumberWithCommas(page_count)}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">
                {convertEpochTime(created_at)}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">
                by {creator}
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    );
  });
}

UpsellPathwayItemsMobile.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
};
