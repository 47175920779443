import { getUrlInfoForCandidiate } from '../../index.tools.js';
import { fixDepricatedTimeZone } from 'core/utilities/timezone-conversions';
import { revvUserRolesKeyMap } from 'modules/settings/settings.types';
import { sendToIntercom, setAmplitudeUser } from 'modules/common';
import { setHoneyContext } from 'core/utilities/honeybadger';

import {
  GET_PROFILE_CANDIDATE,
  GET_PROFILE_ORGANIZATION,
  GET_PROFILE_VENDOR,
  GET_PROFILE_VENDOR_CANDIDATE,
  GET_PROFILE_VENDOR_ORGANIZATION,
} from 'core/middleware/queries';
import honeybadger from 'honeybadger-js';

export const initSettingsState = {
  isOrganization: false,
  isAgency: false,

  // toggle props
  committeesExtraOptions: false,
  fixedBackgroundOption: false,
  showAdvancedRecurring: false,
  donationDescriptions: false,
  upsellFlowCampaign: false,
  userMenu: true,
};

// get settings from local storage - if doesnt exist then create new settings
export const getSettings = candidate => {
  let settings = null;
  if (!candidate.organization_revv_uid) return settings;

  try {
    settings = window.localStorage.getItem(candidate.organization_revv_uid);
    if (!settings) throw Error;
    settings = JSON.parse(settings);

    // merge any new settings and save them
    settings = { ...initSettingsState, ...settings };
    window.localStorage.setItem(candidate.organization_revv_uid, JSON.stringify(settings));
  } catch {
    settings = { ...initSettingsState };
    window.localStorage.setItem(candidate.organization_revv_uid, JSON.stringify(settings));
  }

  return settings;
};

export const setLoggedInCandidate = ({ revvUid = '', agencyRevvUid = '', isAgencyCandidate = '' }) => {
  localStorage.setItem('winred_org_revv_uid', revvUid);
  localStorage.setItem('winred_org_vendor_revv_uid', agencyRevvUid);
  localStorage.setItem('winred_org_is_agency_candidate', isAgencyCandidate);
};

export const getLoggedInCandidate = () => {
  const orgRevvUid = localStorage.getItem('winred_org_revv_uid');
  const agencyRevvUid = localStorage.getItem('winred_org_vendor_revv_uid');
  const isAgencyCandidate = localStorage.getItem('winred_org_is_agency_candidate') === 'true';

  return { orgRevvUid, agencyRevvUid, isAgencyCandidate };
};

export const deleteLoggedInCandidate = () => {
  localStorage.removeItem('winred_org_revv_uid');
  localStorage.removeItem('winred_org_vendor_revv_uid');
  localStorage.removeItem('winred_org_is_agency_candidate');
};

// add props to keep track of candidate type and revv id
export const formatCandidate = (candidate, isOrganization = false) => {
  if (!candidate) return {};
  return {
    ...candidate,
    organization_revv_uid: candidate.organization_revv_uid || candidate.revv_uid,
    isOrganization,
    uiTimezone: fixDepricatedTimeZone(candidate.timezone),
    isAdmin: candidate.role_type === revvUserRolesKeyMap.ADMIN,
    isEditor: candidate.role_type === revvUserRolesKeyMap.EDITOR,
    isReadOnly: candidate.role_type === revvUserRolesKeyMap.READ_ONLY,
    isPagesOnly: candidate.role_type === revvUserRolesKeyMap.PAGES_ONLY,
    isMerchOnly: candidate.role_type === revvUserRolesKeyMap.MERCHANDISE_ONLY,
    isMyPagesOnly: candidate.role_type === revvUserRolesKeyMap.MY_PAGES_ONLY,
  };
};

// format a vendor that has nested candidate/state level orgs
export const formatVendor = vendor => {
  const formattedVendor = { ...vendor };

  formattedVendor.organization_revv_uid = vendor.organization_revv_uid || vendor.revv_uid;
  formattedVendor.isAgency = true;

  formattedVendor.isAdmin = vendor.role_type === revvUserRolesKeyMap.ADMIN;
  formattedVendor.isEditor = vendor.role_type === revvUserRolesKeyMap.EDITOR;
  formattedVendor.isReadOnly = vendor.role_type === revvUserRolesKeyMap.READ_ONLY;
  formattedVendor.isPagesOnly = vendor.role_type === revvUserRolesKeyMap.PAGES_ONLY;
  formattedVendor.isMyPagesOnly = vendor.role_type === revvUserRolesKeyMap.MY_PAGES_ONLY;
  formattedVendor.isMerchOnly = vendor.role_type === revvUserRolesKeyMap.MERCHANDISE_ONLY;

  if (vendor.state_level_organizations) {
    formattedVendor.state_level_organizations = vendor.state_level_organizations.map(org => formatCandidate(org, true));
  }

  if (vendor.candidates) {
    formattedVendor.candidates = vendor.candidates.map(org => formatCandidate(org));
  }

  const allCandidates = [...(formattedVendor.candidates || []), ...(formattedVendor.state_level_organizations || [])];

  formattedVendor.allCandidates = allCandidates.map(candidate => {
    candidate.isAgencyCandidate = true;
    candidate.agencyRevvUid = vendor.revv_uid;
    return candidate;
  });

  return formattedVendor;
};

export const findWantedCandidate = ({ organizationRevvUid, allCandidates, isAgencyCandidate, agencyRevvUid }) => {
  for (const candidate of allCandidates) {
    const orgId = organizationRevvUid || agencyRevvUid;

    // only find matching candidate if we've been told the candidate is not an agency candidate
    const matchingCandidate = !isAgencyCandidate && candidate.organization_revv_uid === orgId;

    if (matchingCandidate) return candidate;

    // if not found on top level then find candidate by vendor
    if (!matchingCandidate && candidate.isAgency && candidate.organization_revv_uid === agencyRevvUid) {
      // if we KNOW we are not an agency candidate (will be null if we are uncertain) then skip this step
      if (isAgencyCandidate === false) continue;

      const matchingVendorCandidate = candidate.allCandidates.find(
        vendorCandidate => vendorCandidate.organization_revv_uid === organizationRevvUid
      );
      if (matchingVendorCandidate) return matchingVendorCandidate;
    }
  }
};

export const getDefaultCandidate = ({ stateLevelCandidates, federalCandidates, vendors }) => {
  if (federalCandidates.length > 0) return federalCandidates[0];
  if (stateLevelCandidates.length > 0) return stateLevelCandidates[0];

  if (vendors.length > 0) {
    // if read only vendor then that vendor cant be selected for now
    const vendor = vendors[0];
    if (vendor.isReadOnly) return vendor.allCandidates[0];
    return vendor;
  }
};

/**
 * initial setup to find logged in candidate
 */
export const getInitialCandidate = ({ apiProfile }) => {
  const {
    organizationRevvUid: urlOrganizationRevvUid,
    agencyRevvUid: urlAgencyRevvUid,
    isRevvShare,
    isAgency,
    isAgencyCandidate,
  } = getUrlInfoForCandidiate();

  let {
    orgRevvUid: localStorageRevvUid,
    agencyRevvUid: localStorageAgencyRevvUid,
    isAgencyCandidate: localStorageIsAgencyCandidate,
  } = getLoggedInCandidate();

  // first we set based on url
  let _orgId = urlOrganizationRevvUid;
  let _agencyRevvUid = urlAgencyRevvUid;
  let _isAgencyCandidate = false;

  // url may overwrite some stuff (opening new tab from sidebar)
  if (isAgency && !isRevvShare && !isAgencyCandidate) {
    _orgId = null;
    _agencyRevvUid = urlAgencyRevvUid;
    _isAgencyCandidate = false;
  } else if (isRevvShare) {
    _orgId = urlAgencyRevvUid;
    _agencyRevvUid = null;
    _isAgencyCandidate = false;
  } else if (isAgencyCandidate) {
    _orgId = urlOrganizationRevvUid;
    _agencyRevvUid = urlAgencyRevvUid;
    _isAgencyCandidate = true;
  } else {
    // if the url didnt give us the info we needed we have to then we may have more info we can extract from local storage

    // this may be an agency candidate if we also have an agency tied to this matching orgId
    if (localStorageRevvUid === _orgId && localStorageAgencyRevvUid) {
      _isAgencyCandidate = true;
      _agencyRevvUid = localStorageAgencyRevvUid;
    }

    // this is a revv share
    if (_agencyRevvUid === localStorageRevvUid) {
      _orgId = _agencyRevvUid;
    }
  }

  // if we satill havent gotten any ids try using the local storage as the backup option
  if (!_orgId && !_agencyRevvUid) {
    _orgId = localStorageRevvUid;
    _agencyRevvUid = localStorageAgencyRevvUid;
    _isAgencyCandidate = localStorageIsAgencyCandidate;
  }

  // format all candidates for processing
  const stateLevelCandidates =
    apiProfile.state_level_organizations?.map(candidate => formatCandidate(candidate, true)) ?? [];
  const federalCandidates = apiProfile.candidates?.map(candidate => formatCandidate(candidate)) ?? [];
  const vendors = apiProfile.vendors?.map(vendor => formatVendor(vendor)) ?? [];
  const allCandidates = [...stateLevelCandidates, ...federalCandidates, ...vendors];

  let _selectedCandidate = findWantedCandidate({
    organizationRevvUid: _orgId,
    allCandidates,
    isAgencyCandidate: _isAgencyCandidate,
    agencyRevvUid: _agencyRevvUid,
  });

  // if we didnt request for a candidate then get selected federal candidate, if none exist then get first federal then first state level org
  if (!_selectedCandidate) {
    _selectedCandidate = getDefaultCandidate({ vendors, stateLevelCandidates, federalCandidates });
  }

  // donor candidate then end here
  if (!_selectedCandidate) {
    _selectedCandidate = { revv_uid: 'donor_user' };
    _selectedCandidate.isDonor = true;
    // set here since we wont set later on candidate details
    setThirdParty({
      ...apiProfile,
      candidate: _selectedCandidate,
      allCandidates,
    });
  } else {
    _selectedCandidate.isDonor = false;
  }

  setLoggedInCandidate({
    revvUid: _selectedCandidate.organization_revv_uid || '',
    agencyRevvUid: _selectedCandidate.agencyRevvUid || '',
    isAgencyCandidate: _selectedCandidate.isAgencyCandidate || '',
  });

  return {
    ...apiProfile,
    candidate: _selectedCandidate,
    allCandidates,
  };
};

/**
 * now that we know what candidate we are logged in as, lets get the rest of that data
 */
export const getUserCandidate = ({ apiProfile, reduxProfile, selectedCandidate }) => {
  // we were either given the selected candidate (sidebar switch) or we want to uset he redux selected candidate as default (login getting all data)
  const _selectedCandidate = selectedCandidate || reduxProfile.candidate;

  const stateLevelCandidate = formatCandidate(apiProfile.state_level_organization, true);
  const federalCandidate = formatCandidate(apiProfile.candidate);
  const vendor = apiProfile.vendor ? formatVendor(apiProfile.vendor) : {};

  const allCandidates = [stateLevelCandidate, federalCandidate, vendor];

  let apiSelectedCandidate = null;

  for (const candidate of allCandidates) {
    if (
      !_selectedCandidate.isAgencyCandidate &&
      candidate.organization_revv_uid === _selectedCandidate.organization_revv_uid
    ) {
      apiSelectedCandidate = candidate;
      break;
    }

    if (_selectedCandidate.isAgencyCandidate && candidate.isAgency && candidate.allCandidates) {
      for (const vendorCandidate of candidate.allCandidates) {
        if (
          vendorCandidate.organization_revv_uid === _selectedCandidate.organization_revv_uid &&
          candidate.organization_revv_uid === _selectedCandidate.agencyRevvUid
        ) {
          apiSelectedCandidate = vendorCandidate;
          break;
        }
      }
    }
  }

  if (!_selectedCandidate) throw Error('no candidate match');

  // if not found which shouldnt happen lets at least default to what the api returned
  // else merge the initial candidate with the full candidate matched
  let _fullSelectedCandidate = null;
  if (!_selectedCandidate) {
    _fullSelectedCandidate = getDefaultCandidate({
      vendor: [vendor],
      federalCandidate: [federalCandidate],
      stateLevelCandidate: [stateLevelCandidate],
    });
  } else {
    _fullSelectedCandidate = { ..._selectedCandidate, ...apiSelectedCandidate };
  }

  if (!_fullSelectedCandidate) {
    console.error(_selectedCandidate);
    throw Error('candidate mismatch!');
  }

  setLoggedInCandidate({
    revvUid: _selectedCandidate.organization_revv_uid || '',
    agencyRevvUid: _selectedCandidate.agencyRevvUid || '',
    isAgencyCandidate: _selectedCandidate.isAgencyCandidate || '',
  });

  const settings = getSettings(_fullSelectedCandidate);

  const profile = {
    ...reduxProfile,
    candidate: _fullSelectedCandidate,
    settings,
  };

  setThirdParty(profile);
  return profile;
};

const setThirdParty = profile => {
  // set 3rd party libraries here
  try {
    sendToIntercom(profile);
  } catch (error) {
    const context = {
      url: window.location.href,
      user_event: 'sendToIntercom called from login-reducer.tools.js',
    };
    honeybadger.notify(error, { context });
    console.error({ sendToIntercom: error });
  }

  try {
    setHoneyContext(profile);
  } catch (error) {
    const context = {
      url: window.location.href,
      user_event: 'setHoneyContext called from login-reducer.tools.js',
    };
    honeybadger.notify(error, { context });
    console.error({ setHoneyContext: error });
  }

  try {
    setAmplitudeUser(profile);
  } catch (error) {
    const context = {
      url: window.location.href,
      user_event: 'setAmplitudeUser called from login-reducer.tools.js',
    };
    honeybadger.notify(error, { context });
    console.error({ setAmplitudeUser: error });
  }
};

export const getProfileVariables = candidate => {
  const variables = { profile_uid: 'profile_1' };
  let query = null;

  if (candidate.isAgency || candidate.is_revv_share) {
    variables.agencyRevvUid = candidate.organization_revv_uid;
    query = GET_PROFILE_VENDOR;
  } else if (candidate.isAgencyCandidate) {
    variables.agencyRevvUid = candidate.agencyRevvUid;
    variables.organizationRevvUid = candidate.organization_revv_uid;
    query = candidate.isOrganization ? GET_PROFILE_VENDOR_ORGANIZATION : GET_PROFILE_VENDOR_CANDIDATE;
  } else {
    variables.organizationRevvUid = candidate.organization_revv_uid;
    query = candidate.isOrganization ? GET_PROFILE_ORGANIZATION : GET_PROFILE_CANDIDATE;
  }

  return { query, variables };
};

export const buildUrlParams = ({ isRevvShare, isAgency, agencyRevvUid, isAgencyCandidate, ...rest }) => {
  const params = new URLSearchParams({
    isrevvshare: isRevvShare,
    isagency: isAgency,
    agencyrevvuid: agencyRevvUid,
    isagencycandidate: isAgencyCandidate,
    ...rest,
  });

  return params.toString();
};
