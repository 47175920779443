import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { WinRedInput, useAmplitude, useToast, SpinnerContainer } from 'modules/common';
import { switchProfileCandidate, profileSelector, profileCandidateSelector, getProfileVariables } from 'core/login';

import { getFilteredCandidates } from '../navbar.tools';
import { CandidatesDrawerCandidate } from './candidate.component';

import './candidate-drawer.scss';

export default function CandidatesDrawerList({ closeUserMenu }) {
  const [isSwitchingCandidate, setIsSwitchingCandidate] = useState(false);

  const profile = useSelector(profileSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const [logEvent] = useAmplitude();
  const setToast = useToast();
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [searchTerm, setSearch] = useState('');
  const [shownCandidates, setShownCandidates] = useState(profile?.allCandidates ?? []);

  const onCandidateClick = useCallback(
    (event, selectedCandidate) => {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      setIsSwitchingCandidate(true);

      /**
       * change to the selected candidate
       */
      const changeCandidate = async () => {
        logEvent('navbar click candidate');

        try {
          await switchCandidate();
          logEvent('switch candidate');
        } catch (error) {
          let { message } = error;
          setToast({ isError: true, message });
          setIsSwitchingCandidate(false);
        }
      };

      /**
       * change to the selected organization
       */
      const changeOrganization = async () => {
        logEvent('navbar click organization');

        try {
          await switchCandidate();
          logEvent('switch organization');
        } catch (error) {
          let { message } = error;
          setToast({ isError: true, message });
          setIsSwitchingCandidate(false);
        }
      };

      /**
       * change to the selected vendor
       */
      const changeVendor = async () => {
        logEvent('navbar click vendor');
        try {
          await switchCandidate({ isAgency: true });
          logEvent('switch vendor');
        } catch (error) {
          let { message } = error;
          setToast({ isError: true, message });
          setIsSwitchingCandidate(false);
        }
      };

      /**
       * change to the selected vendor candidate
       */
      const changeVendorCandidate = async () => {
        logEvent('navbar click vendor candidate');
        try {
          await switchCandidate();
          logEvent('switch vendor candidate');
        } catch (error) {
          let { message } = error;
          setToast({ isError: true, message });
          setIsSwitchingCandidate(false);
        }
      };

      const switchCandidate = async () => {
        // TODO: bug in apollo for client.resetStore() doesnt actually reset store - using non public api
        // see https://github.com/apollographql/apollo-client/issues/1849
        await client.cache.reset();

        const newProfile = await client.query(getProfileVariables(selectedCandidate));

        dispatch(
          switchProfileCandidate({
            profile: newProfile.data.viewer,
            selectedSwitchCandidate: selectedCandidate,
          })
        );

        setSearch('');
        setIsSwitchingCandidate(false);
        closeUserMenu(true);
      };

      // find the correct function to call when switching
      if (selectedCandidate.isAgency) return changeVendor();
      if (selectedCandidate.isAgencyCandidate) return changeVendorCandidate();
      if (selectedCandidate.isOrganization) return changeOrganization();
      return changeCandidate();
    },
    [client, setToast, logEvent, dispatch, setIsSwitchingCandidate, closeUserMenu]
  );

  // on search get what candidates we should show in sidebar
  useEffect(() => {
    setShownCandidates(getFilteredCandidates({ allCandidates: profile?.allCandidates || [], searchTerm }));
  }, [profile?.allCandidates, searchTerm, setShownCandidates]);

  return (
    <div className="pt-3 candidiate-drawer-list">
      {isSwitchingCandidate ? <SpinnerContainer fixed /> : null}
      <div className="mx-3">
        <WinRedInput
          name="search"
          type="search"
          placeholder="Search Committees"
          value={searchTerm}
          onChange={({ target: { value } }) => setSearch(value)}
        />
      </div>
      <div className="mt-3">
        {shownCandidates.map(candidate => {
          // only select this candidate if its not a vendor candidate
          // the same candidate may be under vcendor but also under top level candidates - we dont want both selected
          const isSelectedCandidate =
            candidate.organization_revv_uid === profileCandidate.organization_revv_uid &&
            !profileCandidate.isAgencyCandidate;

          // Read Only, My Pages Only, and Merch Only vendors are not clickable on main vendor org, but connected orgs can be viewed
          const disabledClickOrgs = [candidate.isReadOnly, candidate.isMyPagesOnly, candidate.isMerchOnly];
          const isClickable = candidate.isAgency ? !disabledClickOrgs.includes(true) : true;

          return (
            <Fragment key={candidate.organization_revv_uid}>
              <CandidatesDrawerCandidate
                candidate={candidate}
                onCandidateClick={onCandidateClick}
                isSelectedCandidate={isSelectedCandidate}
                isClickable={isClickable}
              />
              {candidate.isAgency ? (
                <div className="agency-container">
                  {candidate.allCandidates.map(vendorCandidate => {
                    // is this vendor candidate the selected candidate?
                    const isSelectedCandidate =
                      vendorCandidate.organization_revv_uid === profileCandidate.organization_revv_uid;

                    // is this vendor candidate under the selected vendor? we may have the same vendor candidate under multiple vendors
                    // so we have to check if this vendor candidate that we selected actually belongs to this vendor
                    const isSelectedVendorCandidate =
                      profileCandidate.isAgencyCandidate &&
                      profileCandidate.agencyRevvUid === vendorCandidate.agencyRevvUid;

                    return (
                      <CandidatesDrawerCandidate
                        key={vendorCandidate.organization_revv_uid + '_vendor'}
                        candidate={vendorCandidate}
                        onCandidateClick={onCandidateClick}
                        isSelectedCandidate={isSelectedCandidate && isSelectedVendorCandidate}
                      />
                    );
                  })}
                </div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
