import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';

export default function WinredModal(props) {
  const { children, size="lg", ...restProps } = props;
  return (
    <Modal size={size} {...restProps}>
      {children}
    </Modal>
  );
}
