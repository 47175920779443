import React from 'react';
import PropTypes from 'prop-types';
import { FormCard, FormCardSpaced } from 'modules/common';

import { UpsellName, UpsellAction, UpsellVideoEmbed, EnhancedUpsell, UpsellDonations } from './sections';

function MultipleUpsell(props) {
  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
      </FormCardSpaced>

      <FormCard title="Amounts" subtitle="Customize your suggested amount buttons.">
        <UpsellDonations {...props} maxNumberOfItems={3} />
      </FormCard>

      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />
    </>
  );
}

MultipleUpsell.title = 'Multiple Amount';
MultipleUpsell.description = 'Ask donors to contribute more with multiple options.';
MultipleUpsell.icon = 'upsell-multiple';
MultipleUpsell.type = 'MULTIPLEAMOUNTS';
MultipleUpsell.widgets = [];

MultipleUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default MultipleUpsell;
