import gql from 'graphql-tag';

export const getBillPayActivityFragment = gql`
  fragment BillPayActivityFragment on BillPayActivityResult {
    page_count
    total_count
    current_page
    results {
      id
      objectName
      objectType
      objectRevvUid
      objectLink
      isArchived
      objectLinkText
      activityType
      vendorName
      organizationName
      hostOrganizationName
      changedFrom
      changedTo
      date
      email
    }
  }
`;

const GET_PAGE_BILL_PAY_ACTIVITY = gql`
  query getPageBillPayActivity($revv_uid: String!, $organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            billPayActivity(filters: { page: $page, limit: $limit }) {
              ...BillPayActivityFragment
            }
          }
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

const GET_PAGE_BILL_PAY_ACTIVITY_ORGANIZATION = gql`
  query getPageBillPayActivity($revv_uid: String!, $organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pages(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            billPayActivity(filters: { page: $page, limit: $limit }) {
              ...BillPayActivityFragment
            }
          }
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

const GET_SETTINGS_BILL_PAY_ACTIVITY = gql`
  query getSettingsBillPayActivity($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        billPayActivity(filters: { page: $page, limit: $limit }) {
          ...BillPayActivityFragment
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

const GET_SETTINGS_BILL_PAY_ACTIVITY_ORGANIZATION = gql`
  query getOrganizationSettingsBillPayActivity($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        billPayActivity(filters: { page: $page, limit: $limit }) {
          ...BillPayActivityFragment
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

const GET_UPSELL_BILL_PAY_ACTIVITY = gql`
  query getUpsellBillPayActivity($revv_uid: String!, $organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            billPayActivity(filters: { page: $page, limit: $limit }) {
              ...BillPayActivityFragment
            }
          }
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

const GET_UPSELL_BILL_PAY_ACTIVITY_ORGANIZATION = gql`
  query getOrganiztionUpsellBillPayActivity($revv_uid: String!, $organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        upsells(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            billPayActivity(filters: { page: $page, limit: $limit }) {
              ...BillPayActivityFragment
            }
          }
        }
      }
    }
  }
  ${getBillPayActivityFragment}
`;

export {
  GET_PAGE_BILL_PAY_ACTIVITY,
  GET_PAGE_BILL_PAY_ACTIVITY_ORGANIZATION,
  GET_SETTINGS_BILL_PAY_ACTIVITY,
  GET_SETTINGS_BILL_PAY_ACTIVITY_ORGANIZATION,
  GET_UPSELL_BILL_PAY_ACTIVITY,
  GET_UPSELL_BILL_PAY_ACTIVITY_ORGANIZATION,
};
