import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { Card } from 'modules/common';
import RequiredLabel from './required.component';
import '../custom.scss';

function CustomFieldRadioPreview({ formState, disabled }) {
  const [defaultValue, setDefaultValue] = useState(formState.default_value);
  useEffect(() => setDefaultValue(formState.default_value), [formState.default_value]);

  const [otherValue, setOtherValue] = useState('');

  if (!formState) return null;
  const radioOptions = formState.options || [];

  return (
    <div>
      <Card.Title className="preview-title">
        {formState.label}
        <RequiredLabel formState={formState} />
      </Card.Title>
      {radioOptions.map(radioOption => {
        if (!radioOption.value) return null;
        return (
          <Form.Check
            key={radioOption.key}
            disabled={disabled}
            type="radio"
            checked={defaultValue === radioOption.key}
            onChange={() => setDefaultValue(radioOption.key)}
            label={radioOption.value}
          />
        );
      })}
      {formState.other_field_enabled && (
        <div>
          <Form.Group controlId="formOtherOption" className="other-field-input-preview">
            <Form.Check
                type="radio"
                id="otherRadio"
                disabled={disabled}
                checked={defaultValue === 'other'}
                onChange={() => setDefaultValue('other')}
            />
            <Form.Control
                type="text"
                placeholder="Other"
                disabled={disabled}
                value={otherValue}
                onChange={e => setOtherValue(e.target.value)}
                style={{ height: '30px' }}
            />
          </Form.Group>
        </div>
      )}
    </div>
  );
}

CustomFieldRadioPreview.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default CustomFieldRadioPreview;
