import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import useValidation from './useValidation';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import SubText from 'modules/common/sub-text.component';

const popperModifiers = {
  options: {
    preventOverflow: {
      enabled: true,
    },
  },
};

function WinRedDatePicker({
  name,
  onChange,
  value,
  validators,
  validateOnMount,
  className = '',
  classNameContainer = '',
  onBlur,
  placeholder,
  onFocus,
  children,
  errorIcon,
  description,
  subDescription,
  ...rest
}) {
  const [focus, setFocus] = useState(false);
  const [validationBlur, validationError, validateInput] = useValidation({
    value,
    validators,
    validateOnMount,
    onBlur,
  });

  const _onChange = useCallback(
    value => {
      onChange({ target: { name, value } });
      validateInput({ target: { name, value } });
    },
    [onChange, name, validateInput]
  );

  const handleFocusChange = useCallback(
    event => {
      if (onFocus) onFocus(event);
      setFocus(true);
    },
    [onFocus]
  );

  const handleBlurChange = useCallback(
    event => {
      validationBlur(event);
      setFocus(!!value);
    },
    [validationBlur, value]
  );

  let labelClass = focus || (value && value.length !== 0);

  return (
    <>
      <div className={classnames('winred-input2', classNameContainer)}>
        <div className="float-label" onBlur={handleBlurChange} onFocus={handleFocusChange}>
          <DatePicker
            popperModifiers={popperModifiers}
            onChange={_onChange}
            name={name}
            selected={value}
            popperPlacement="auto-start"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            className={classnames(
              {
                'winred-input-error': !!validationError,
              },
              'form-control',
              className
            )}
            {...rest}
          />
          <label
            className={classnames(
              {
                'label label-float': labelClass,
                label: !labelClass,
                'winred-input-float-error': !!validationError,
              },
              className
            )}
          >
            {placeholder}
          </label>
        </div>
        <InputErrorMessage errorMessage={validationError} errorIcon={errorIcon} />
        {description ? <SubText description={description} description2={subDescription} /> : null}
        {children}
      </div>
    </>
  );
}

WinRedDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  validators: PropTypes.array,
  validateOnMount: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  children: PropTypes.node,
  errorIcon: PropTypes.string,
  description: PropTypes.string,
  subDescription: PropTypes.string,
};

export default WinRedDatePicker;
