import gql from 'graphql-tag';

export const reportDefinitionList = gql`
  fragment ReportDefinitionList on ReportDefinition {
    id
    revv_uid
    name
    pretty_report_type
    report_type
    active
    use_timezone_in_report
    timezone
    custom_filename
    recipients_string
    display_recurrence
    created_by
    created_at
    frequency_enum
    note
    disable_user_editing
    integration {
      id
      type
      active
      ftpFields {
        ftpAddress
        ftpPath
      }
      s3Fields {
        active
        bucket
      }
      cmdiFields {
        ftpAddress
        ftpPath
      }
    }
  }
`;

export const reportDefinition = gql`
  fragment ReportDefinition on ReportDefinition {
    id
    revv_uid
    name
    reporter_type
    pretty_report_type
    report_type
    active
    use_timezone_in_report
    timezone
    frequency_enum
    custom_filename
    recipients_string
    display_recurrence
    recurrence_range
    condition_operator
    created_by
    created_at
    note
    integration {
      id
      type
      active
      ftpFields {
        ftpAddress
        ftpPath
      }
      s3Fields {
        active
        bucket
      }
      cmdiFields {
        ftpAddress
        ftpPath
      }
    }
    search_conditions {
      id
      attribute_name
      value
      predicate
    }
    report_columns {
      id
      name
      key
      position
      maxlength
      static
      static_value
      lambda_options
      pretty_column_name
    }
  }
`;

export const GET_CANDIDATE_REPORTS_QUERY = gql`
  query getReportsDefinitions(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $report_type: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        report_definitions(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            reportType: $report_type
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...ReportDefinitionList
          }
        }
      }
    }
  }
  ${reportDefinitionList}
`;

export const GET_CANDIDATE_REPORT_QUERY = gql`
  query getReportDefinitions($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        report_definitions(filters: { revvUid: $revv_uid }) {
          results {
            ...ReportDefinition
          }
        }
      }
    }
  }
  ${reportDefinition}
`;

export const GET_CANDIDATE_PAYOUT_REPORT_QUERY = gql`
  query getReportDefinitions($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        payout_report {
          ...ReportDefinition
        }
      }
    }
  }
  ${reportDefinition}
`;

export const UPDATE_CANDIDATE_REPORT_MUTATION = gql`
  mutation updateCandidateReportDefinitionMutation($report: UpdateReportDefinitionInput!) {
    candidateUpdateReportDefinition(reportDefinition: $report) {
      errors
      reportDefinition {
        ...ReportDefinition
      }
    }
  }
  ${reportDefinition}
`;

export const GET_CANDIDATE_REPORT_CONDITIONS = gql`
  query getCandidateReportDefinitionConditions(
    $reportType: String
    $page: Int
    $limit: Int
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        report_definition_conditions(filters: { reportType: $reportType, page: $page, limit: $limit }) {
          report_key
          attributes {
            value
            name
            value_options {
              name
              value
            }
            predicates {
              name
              value
            }
            data_type
          }
        }
      }
    }
  }
`;

export const GET_CANDIDATE_REPORT_COLUMNS = gql`
  query getCandidateReportColumns(
    $reportType: String
    $columnOptions: ColumsOptionsEnum
    $page: Int
    $limit: Int
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        report_definition_columns(
          filters: { reportType: $reportType, columnOptions: $columnOptions, page: $page, limit: $limit }
        ) {
          name
          value
        }
      }
    }
  }
`;

export const RUN_CANDIDATE_REPORT_MUTATION = gql`
  mutation candidateReportDefinitionRun($attributes: ReportDefinitionRunInput!) {
    candidateRunReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const CANCEL_CANDIDATE_REPORT_MUTATION = gql`
  mutation candidateCancelReportDefinition($attributes: ReportDefinitionRunInput!) {
    candidateCancelReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const DELETE_CANDIDATE_REPORT_MUTATION = gql`
  mutation candidateDeleteReportDefinition($attributes: ReportDefinitionRunInput!) {
    candidateDeleteReportDefinition(reportAttributes: $attributes) {
      message
    }
  }
`;

export const CREATE_CANDIDATE_REPORT_MUTATION = gql`
  mutation candidateCreateReportDefinition($reportDefinition: CreateReportDefinitionInput!) {
    candidateCreateReportDefinition(reportDefinition: $reportDefinition) {
      reportDefinition {
        revv_uid
      }
      errors
    }
  }
`;

export const GET_CANDIATE_REPORT_INTEGRATIONS = gql`
  query getIntegrations(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $active: Boolean
    $type: IntegrationTypeEnum
    $source: [IntegrationSourceTypeEnum!]!
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        active_integration_types
        existing_integration_types
        integrations(
          filters: { page: $page, limit: $limit, revvUid: $revvUid, active: $active, type: $type, source: $source }
        ) {
          current_page
          page_count
          total_count
          results {
            id
            active
            type
            s3Fields {
              bucket
            }
            ftpFields {
              ftpAddress
              ftpPath
            }
          }
        }
      }
    }
  }
`;
