import React from 'react';

export function PaginationMobile({
  pageButtons,
  roundedTotalPaginationNumber,
  currentPage,
  mobileForwardChevronPageButton,
  mobileBackwardChevronPageButton,
}) {
  return (
    <div>
      {currentPage === 1 ? (
        roundedTotalPaginationNumber === 1 ? (
          <>{pageButtons[0]}</>
        ) : (
          <>
            {pageButtons[0]}
            {mobileForwardChevronPageButton[1]}
            {pageButtons[pageButtons.length - 1]}
          </>
        )
      ) : currentPage === pageButtons.length ? (
        <>
          {pageButtons[0]}
          {mobileBackwardChevronPageButton[currentPage - 2]}
          {pageButtons[pageButtons.length - 1]}
        </>
      ) : (
        <>
          {pageButtons[0]}
          {mobileBackwardChevronPageButton[currentPage - 2]}
          {pageButtons[currentPage - 1]}
          {mobileForwardChevronPageButton[currentPage]}
          {pageButtons[pageButtons.length - 1]}
        </>
      )}
    </div>
  );
}
