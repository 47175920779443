import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';

import { Form, Container, Row, Col } from 'react-bootstrap';

import {
  VERIFY_TWO_FACTOR,
  ENABLE_TWO_FACTOR,
  RESEND_TWO_FACTOR_CODE,
  RESEND_TWO_FACTOR_CODE_VOICE,
} from 'core/middleware/queries';
import { loginInitialRouteSelector, setLoginStatus, loginSelector, LOGIN_STATUSES } from 'core/login';
import { Button, Card, Input, FloatGroup, setTitle, withAmplitude, Typography } from 'modules/common';
import { isValidTwoFactorCode } from 'core/utilities';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';

import LoginHeader from './login-header.component';

class TwoFactorVerificationContainer extends Component {
  state = {
    verificationCode: '',
    loading: false,
  };

  validation = React.createRef();

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  resendCode = async (event, resendType) => {
    event.preventDefault();
    const resendFunction = resendType === 'text' ? this.props.resendTwoFactorCode : this.props.resendTwoFactorCodeVoice;

    this.setState({ loading: true, verificationCode: '' }, async () => {
      try {
        const response = await resendFunction();
        this.setState({ loading: false });

        const resendResponse = response.data.resendTwoFactorSms || response.data.resendTwoFactorVoice;

        const message =
          resendResponse?.message || resendResponse?.error || `There was an error sending your verification code.`;

        this.props.setToast({
          isError: resendResponse?.status === 'failed',
          message,
        });
      } catch (error) {
        this.setState({ loading: false });
        this.props.setToast({
          isError: true,
          message: `There was an error sending your verification code.`,
        });
      }
    });
  };

  onVerifySubmit = async event => {
    event.preventDefault();
    if (this.validation.current.errors()) return;

    this.setState({ loading: true }, async () => {
      try {
        const { validateTwoFactorAuth, loginProfile, enableTwoFactorAuth } = this.props;
        const twoFactorMutation = loginProfile.doesTwoFactorNeedInstalled ? enableTwoFactorAuth : validateTwoFactorAuth;

        const response = await twoFactorMutation({
          enableTwoFactorToken: this.state.verificationCode,
        });

        const twoFactorResponse = response.data.validateTwoFactor || response.data.enableTwoFactor;
        this.setState({ loading: false, verificationCode: '' });

        // if error or we didnt get back a token then something went wrong with the 2fa verifying
        if (twoFactorResponse.error || !twoFactorResponse.token) {
          this.props.setToast({
            isError: true,
            message: twoFactorResponse.error || `There was an error with the verification code.`,
          });
          return;
        }

        this.props.logEvent(`2fa ${loginProfile.doesTwoFactorNeedInstalled ? 'enable' : 'verify'}`);
        this.props.setLoginStatus({
          status: LOGIN_STATUSES.LOGGING_IN,
          token: twoFactorResponse.token,
        });
      } catch (error) {
        this.setState({ loading: false, verificationCode: '' });
        this.props.setToast({
          isError: true,
          message: `There was an error with the verification code.`,
        });
      }
    });
  };

  render() {
    const loading = this.state.loading;

    return (
      <>
        {setTitle('2FA Verification')}
        <LoginHeader title="2-Step Verification" subtitle="The extra step shows it's really you when signing in.">
          <Card>
            <Card.Body>
              <ValidationBlock ref={this.validation}>
                <Typography className="mb-4">
                  A text message with a 6-digit verification code was just sent to your mobile phone.
                </Typography>
                <Form onSubmit={this.onLoginSubmit}>
                  <FloatGroup>
                    <FloatGroup.Label htmlFor="mobile-phone">6-Digit Verification Code</FloatGroup.Label>
                    <Input
                      type="text"
                      numbersOnly
                      maxLength="6"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      disabled={loading}
                      placeholder="6-Digit Verification Code"
                      id="verificationCode"
                      name="verificationCode"
                      errorMessageClasses="position-absolute"
                      value={this.state.verificationCode}
                      onChange={this.onChange}
                      validateOnBlurOnly
                      validators={['required', isValidTwoFactorCode]}
                      errorMessages={[
                        '6-Digit Verification Code is required.',
                        '6-Digit Verification Code is required.',
                      ]}
                    />
                  </FloatGroup>

                  <div className="mt-5">
                    <Container fluid>
                      {/* the first button is auto submitted on enter but that is the resend button
                      so on the dom the first button is the next button but we need to switch it
                      to trick the form to use submit but the ui to see resend first */}
                      <Row className="d-flex flex-row-reverse">
                        <Col xs={4} className="px-0">
                          <Button
                            className="float-right"
                            variant="success"
                            size="lg"
                            type="submit"
                            disabled={loading}
                            ariaLabel="Next"
                            onClick={this.onVerifySubmit}
                          >
                            Next
                          </Button>
                        </Col>
                        <Col xs={8} className="px-0">
                          <Typography tag="div">
                            <div>Haven&apos;t received a code?</div>
                            <div>
                              Click&nbsp;
                              <button className="link-button" onClick={event => this.resendCode(event, 'text')}>
                                here
                              </button>
                              &nbsp;to resend.
                            </div>
                          </Typography>

                          <Typography tag="div">
                            Or authenticate by a{' '}
                            <button className="link-button" onClick={event => this.resendCode(event, 'phone')}>
                              phone call
                            </button>
                            .
                          </Typography>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Form>
              </ValidationBlock>
            </Card.Body>
          </Card>
        </LoginHeader>
      </>
    );
  }
}

TwoFactorVerificationContainer.propTypes = {
  history: PropTypes.object.isRequired,
  loginProfile: PropTypes.object.isRequired,
  initialRoute: PropTypes.string,
  setToast: PropTypes.func.isRequired,
  setLoginStatus: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  validateTwoFactorAuth: PropTypes.func.isRequired,
  enableTwoFactorAuth: PropTypes.func.isRequired,
  resendTwoFactorCode: PropTypes.func.isRequired,
  resendTwoFactorCodeVoice: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    initialRoute: loginInitialRouteSelector(state),
    loginProfile: loginSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setLoginStatus: payload => dispatch(setLoginStatus(payload)),
  };
};

export default compose(
  withRouter,
  withAmplitude,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(VERIFY_TWO_FACTOR, {
    props: ({ mutate }) => ({
      validateTwoFactorAuth: variables => mutate({ variables }),
    }),
  }),
  graphql(ENABLE_TWO_FACTOR, {
    props: ({ mutate }) => ({
      enableTwoFactorAuth: variables => mutate({ variables }),
    }),
  }),
  graphql(RESEND_TWO_FACTOR_CODE, {
    props: ({ mutate }) => ({
      resendTwoFactorCode: variables => mutate({ variables }),
    }),
  }),
  graphql(RESEND_TWO_FACTOR_CODE_VOICE, {
    props: ({ mutate }) => ({
      resendTwoFactorCodeVoice: variables => mutate({ variables }),
    }),
  })
)(TwoFactorVerificationContainer);
