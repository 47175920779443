import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { FormCard, FlexBinaryChoiceRow, ImagePrompt, ConfirmModal, Input } from 'modules/common';
import { noop } from 'core/utilities';

function EnhancedUpsell({ editedUpsell, onUpdateEditedUpsell }) {
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);

  const onToggleEnhanced = ({ target: { value } }) => {
    const newEditedUpsell = cloneDeep(editedUpsell);
    newEditedUpsell.enhanced = value;

    const imageId = editedUpsell?.logo_assigned_image?.image?.id;

    // if image exists and we disabled enhanced upsell we want to delete the image too
    // else if we want to disable enhanced upsells and image doesn't exist we just
    // need to set image to null to make sure we don't send anything back
    if (!newEditedUpsell.enhanced && imageId) {
      newEditedUpsell.enhanced = false;
      newEditedUpsell.logo_assigned_image = {
        destroy: true,
        imageId: imageId,
        id: editedUpsell.logo_assigned_image.id,
      };
      /* If enhanced is toggled and there is an image was added but not as part of
      the upsell on the server, just set  the assigned image to null. */
    } else if (!newEditedUpsell.enhanced && !newEditedUpsell.logo_assigned_image?.destroy) {
      newEditedUpsell.logo_assigned_image = null;
    }

    onUpdateEditedUpsell({ target: { name: 'upsell_pages', value: newEditedUpsell } });
  };

  return (
    <FormCard>
      <FlexBinaryChoiceRow
        name="enhanced"
        title="Enhanced Upsell"
        disabled={editedUpsell._isDisabled}
        subTitle="Enhance the upsell design for a more impactful donor experience. This replaces your the call to action with an image."
        value={editedUpsell.enhanced}
        onChange={onToggleEnhanced}
      />
      {!editedUpsell.enhanced ? null : (
        <>
          <ImagePrompt
            imageUri={
              editedUpsell.logo_assigned_image && editedUpsell.logo_assigned_image.image
                ? editedUpsell.logo_assigned_image.image.thumbnailUrl
                : null
            }
            name="logo_assigned_image"
            selectImageAction={onUpdateEditedUpsell}
            imageType="LOGO"
            disabled={editedUpsell._isDisabled}
            removeImageAction={() => setShowRemoveLogoModal(true)}
            isNested
          />

          <Input
            hidden
            value={editedUpsell.logo_assigned_image?.image?.thumbnailUrl || ''}
            onChange={noop}
            validators={['required']}
            errorMessages={['Enhanced Upsell image required.']}
          />

          <ConfirmModal
            show={showRemoveLogoModal}
            title="Remove Logo"
            buttonText="Remove"
            handleClose={() => setShowRemoveLogoModal(false)}
            buttonColor="cancel"
            onSubmit={() => {
              const value = {
                destroy: true,
                imageId: editedUpsell.logo_assigned_image.image.id,
                id: editedUpsell.logo_assigned_image.id,
              };

              onUpdateEditedUpsell({ target: { name: 'logo_assigned_image', value } });
              setShowRemoveLogoModal(false);
            }}
          >
            Are you sure you want to remove this logo from your upsell?
          </ConfirmModal>
        </>
      )}
    </FormCard>
  );
}

EnhancedUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default EnhancedUpsell;
