import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { EmptyList, CustomReportCard } from 'modules/common';
import { userMenuSelector } from 'core/login';
import { isEmptyList } from '../lists/pagination.tools';

const CustomReportsList = ({ customReports, numberOfFilters, resetFilters, updateReport, filter }) => {
  const isUserMenuOpen = useSelector(userMenuSelector);

  if (isEmptyList(customReports, filter)) {
    return (
      <Row className="mobile-fix-padding custom-reports-list">
        <Col xs={12}>
          <EmptyList
            buttonText={numberOfFilters > 0 ? 'Reset Filter' : ''}
            description="We couldn't find any reports."
            icon="user"
            name="filter"
            text="No Reports"
            onClick={resetFilters}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mobile-fix-padding custom-reports-list">
      {customReports.map(customReport => (
        <Col md={6} xl={isUserMenuOpen ? 6 : 4} xxl={4} key={customReport.revv_uid}>
          <CustomReportCard customReport={customReport} onUpdateReport={updateReport} />
        </Col>
      ))}
    </Row>
  );
};

CustomReportsList.propTypes = {
  customReports: PropTypes.array.isRequired,
  numberOfFilters: PropTypes.number.isRequired,

  resetFilters: PropTypes.func.isRequired,
  updateReport: PropTypes.func.isRequired,
};

export { CustomReportsList };
