import gql from 'graphql-tag';
import { donorFragments } from './donors.queries';

export const GET_VENDOR_DONORS_REPORT = gql`
  query getVendorDonorsReport(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: Predicate
    $isRevvUser: Boolean
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_donors_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            isRevvUser: $isRevvUser
            search: $search
          }
        )
      }
    }
  }
`;

export const GET_VENDOR_DONORS_QUERY = gql`
  query getVendorDonors(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: Predicate
    $isRevvUser: Boolean
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        donors(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            isRevvUser: $isRevvUser
            search: $search
          }
        ) {
          ...Donors
        }
      }
    }
  }
  ${donorFragments.donors}
`;

export const GET_VENDOR_DONOR_QUERY = gql`
  query getVendorDonor($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        donors(filters: { revvUid: $revv_uid }) {
          ...Donor
        }
      }
    }
  }
  ${donorFragments.donor}
`;
