import uuid from 'core/utilities/uuid';
import { formatConditionValue } from '../pathways/pathways.tools';
import { getIncomers, getOutgoers, MarkerType } from 'reactflow';

export const NODE_TYPES = {
  CONDITION_NODE: 'CONDITION_NODE',
  UPSELL_NODE: 'UPSELL_NODE',
  EDIT_NODE: 'EDIT_NODE',
  TRUE_NODE: 'TRUE_NODE',
  FALSE_NODE: 'FALSE_NODE',
  UPSELL_PANEL_NODE: 'UPSELL_PANEL_NODE',
  DONATION_PAGE: 'DONATION_PAGE-NODE',
  END_NODE: 'END_NODE',
};

export const UPSELL_TYPES = {
  PANEL: 'PANEL',
};

export const PATHWAY_TYPES = {
  UPSELL: 'UPSELL',
  PETITION: 'PETITION',
};

export const DONATION_PAGE_NODE_ID = 'donation-page-node-id';

export const maxConditionChainLength = 10;


export const formatUpsellPathwayForUi = (data, profileCandidate) => {
  const pathwayFromServer = data;
  let initialNodes = [];
  let initialEdges = [];
  const donationPageNode = {
    id: DONATION_PAGE_NODE_ID,
    position: { x: 0, y: 0 },
    data: { label: 'node 1 - donation page' },
    type: NODE_TYPES.DONATION_PAGE,
  };
  const firstEdit = generateNewEditNode(null, null);
  const firstEdgeToEdit = generateNewEdge(donationPageNode.id, firstEdit.id);
  initialEdges.push(firstEdgeToEdit);
  const confirmationNodeId = uuid();
  const confirmationPageNode = {
    id: confirmationNodeId,
    position: { x: 0, y: 0 },
    data: { label: 'Confirmation Page' },
    type: NODE_TYPES.END_NODE,
  };
  initialNodes.push(donationPageNode, firstEdit, confirmationPageNode);
  if (data?._isNew) {
    const edgeToFirstConfirmationPage = generateNewEdge(firstEdit.id, confirmationNodeId, null, null, MarkerType.Arrow);
    initialEdges.push(edgeToFirstConfirmationPage);
  } else {
    pathwayFromServer?.base_step_joins?.forEach(stepJoin => {
      // if first node, add edge from initial Edit, to this first node (position 1?)
      const step = stepJoin.step;
      const stepIdString = step.id.toString();
      if (stepJoin.position === 1) {
        const edgeToFirstRealNode = generateNewEdge(firstEdit.id, stepIdString, null, null, MarkerType.Arrow);
        initialEdges.push(edgeToFirstRealNode);
      }
      const stepType = step.node_type;
      const node = {
        id: stepIdString,
        hasStepId: !!step.id,
        stepId: step.id,
        stepJoinId: stepJoin.id,
        position: { x: 0, y: 0 },
        data: {
          label: step.name,
          name: step.name,
          statistics: step.statistics,
          stepId: step.id,
        },
        type: stepType,
        upsellPageType: step.upsell_page_type,
      };
      if (stepType === NODE_TYPES.CONDITION_NODE) {
        node.data.condition = { ...step };
        node.data.stepId = step.id;
        // node.data.falseStats = stepJoin.falsePathStatistics;
        // node.data.trueStats = stepJoin.truePathStatistics;
        node.data.trueAccepted = stepJoin.true_accepted;
        node.data.trueConversionRate = stepJoin.true_conversion_rate;
        node.data.trueViewsCount = stepJoin.true_views_count;
        node.data.falseAccepted = stepJoin.false_accepted;
        node.data.falseConversionRate = stepJoin.false_conversion_rate;
        node.data.falseViewsCount = stepJoin.false_views_count;
        const trueNode = generateNewTrueNode();
        const toTrue = generateNewEdge(stepIdString, trueNode.id, null, 'true');
        const editAfterTrue = generateNewEditNode(null, null);
        const trueToEdit = generateNewEdge(trueNode.id, editAfterTrue.id);
        const falseNode = generateNewFalseNode();
        const toFalse = generateNewEdge(stepIdString, falseNode.id, null, 'false');
        const editAfterFalse = generateNewEditNode(null, null);
        const falseToEdit = generateNewEdge(falseNode.id, editAfterFalse.id);
        const newEndNodeAfterFalsyEdit = !!stepJoin.pathway_false_next_step_id
          ? null
          : generateNewEndNode(editAfterFalse);
        const edgeBeyondFalsyEdit = generateNewEdge(
          editAfterFalse.id,
          stepJoin.pathway_false_next_step_id?.toString() || newEndNodeAfterFalsyEdit.id,
          null,
          null,
          MarkerType.Arrow
        );
        const newEndNodeAfterTruthyEdit = !!stepJoin.pathway_true_next_step_id
          ? null
          : generateNewEndNode(editAfterTrue);
        const edgeBeyondTruthyEdit = generateNewEdge(
          editAfterTrue.id,
          stepJoin.pathway_true_next_step_id?.toString() || newEndNodeAfterTruthyEdit.id,
          null,
          null,
          MarkerType.Arrow
        );
        initialNodes.push(node, trueNode, editAfterTrue, falseNode, editAfterFalse);
        if (!!newEndNodeAfterFalsyEdit) {
          initialNodes.push(newEndNodeAfterFalsyEdit);
        }
        if (!!newEndNodeAfterTruthyEdit) {
          initialNodes.push(newEndNodeAfterTruthyEdit);
        }
        initialEdges.push(toTrue, toFalse, trueToEdit, falseToEdit, edgeBeyondTruthyEdit, edgeBeyondFalsyEdit);
      } else if (stepType === NODE_TYPES.UPSELL_NODE && step.upsell_page_type !== UPSELL_TYPES.PANEL) {
        node.data.upsell = step.upsell_page;
        node.data.pathway_true_next_step_id = stepJoin.pathway_true_next_step_id?.toString();
        const editAfterUpsell = generateNewEditNode(null, null);
        const edgeToEdit = generateNewEdge(stepIdString, editAfterUpsell.id);
        let newEndNode = !stepJoin.pathway_true_next_step_id ? generateNewEndNode(editAfterUpsell) : null;
        const beyondEdit = generateNewEdge(
          editAfterUpsell.id,
          stepJoin.pathway_true_next_step_id?.toString() || newEndNode.id,
          null,
          null,
          MarkerType.Arrow
        );
        initialNodes.push(node, editAfterUpsell);
        if (newEndNode) {
          initialNodes.push(newEndNode);
        }
        initialEdges.push(edgeToEdit, beyondEdit);
      } else if (stepType === NODE_TYPES.UPSELL_NODE && step.upsell_page_type === UPSELL_TYPES.PANEL) {
        node.data.upsell = step.upsell_page
        node.data.pathway_true_next_step_id = null;
        initialNodes.push(node);
      }
    });
  }
  return { data: { ...data, initialNodes, initialEdges } };
};

export const formatUpsellPathwayForPageUi = (pathway, profileCandidate) => {
  const formattedData = formatUpsellPathwayForUi(pathway, profileCandidate);
  return formattedData.data;
};

export const findRealParentStep = (currentId, nodes, edges, branchType = null) => {
  const currentNode = nodes.find(node => node.id === currentId);
  if (
    currentNode?.type === NODE_TYPES.EDIT_NODE ||
    currentNode?.type === NODE_TYPES.FALSE_NODE ||
    currentNode?.type === NODE_TYPES.TRUE_NODE
  ) {
    const parentNodeId = edges.find(edge => edge.target === currentNode?.id).source;
    return findRealParentStep(parentNodeId, nodes, edges, currentNode?.type);
  }
  if (currentNode?.type === NODE_TYPES.UPSELL_NODE || currentNode?.type === NODE_TYPES.CONDITION_NODE) {
    return { currentNode, branchType };
  }
  if (currentNode?.type === NODE_TYPES.DONATION_PAGE) {
    return { currentNode, branchType: NODE_TYPES.DONATION_PAGE };
  }
};

export const findRealChildStep = (currentId, nodes, edges, branchType) => {
  const currentNode = nodes.find(node => node.id === currentId);
  if (
    currentNode?.type === NODE_TYPES.EDIT_NODE ||
    currentNode?.type === NODE_TYPES.FALSE_NODE ||
    currentNode?.type === NODE_TYPES.TRUE_NODE
  ) {
    const childNodeId = edges.find(edge => edge.source === currentNode?.id)?.target;
    return findRealChildStep(childNodeId, nodes, edges, currentNode?.type);
  }
  if (currentNode?.type === NODE_TYPES.UPSELL_NODE || currentNode?.type === NODE_TYPES.CONDITION_NODE) {
    return { currentNode, branchType };
  }
  if (currentNode?.type === NODE_TYPES.END_NODE) {
    return { currentNode, branchType: NODE_TYPES.END_NODE };
  } else {
    return null;
  }
};

export const insertNewBaseStepJoin = (originalBaseStepJoins = [], newNode, realChild, realParent, newStep, middleConditionDirection) => {
  const existingStepCounts = originalBaseStepJoins?.length;
  const mutableBaseStepJoins = [...originalBaseStepJoins];
  const newFist = realParent?.currentNode?.type === NODE_TYPES.DONATION_PAGE ? true : false;

  let trueNextStepId = null;
  let falseNextStepId = null;

  if(newStep.node_type === NODE_TYPES.UPSELL_NODE || (newStep.node_type === NODE_TYPES.CONDITION_NODE && !middleConditionDirection)){
    trueNextStepId = realChild?.currentNode?.stepId || realChild?.currentNode?.data?.stepId || null;
  } else if (newStep.node_type === NODE_TYPES.CONDITION_NODE && !!middleConditionDirection){
    if(middleConditionDirection === NODE_TYPES.TRUE_NODE){
      trueNextStepId = realChild?.currentNode?.stepId || realChild?.currentNode?.data?.stepId || null;
      falseNextStepId = null;
    } else {
      falseNextStepId = realChild?.currentNode?.stepId || realChild?.currentNode?.data?.stepId || null;
      trueNextStepId = null;
    }
  }

  const newBaseStepJoin = {
    _isNew: true,
    position: newFist ? 1 : existingStepCounts + 1,
    _upsell_page_revv_uid: newStep.upsell_page_revv_uid,
    _upsell_page_base_id: newStep.upsell_page_base_id,
    pathway_true_next_step_id: trueNextStepId,
    ...(newStep.node_type === NODE_TYPES.CONDITION_NODE && {
      pathway_false_next_step_id: falseNextStepId,
    }),
    step: {
      revv_uid: newStep.revv_uid,
      id: newStep.id,
      name: newStep.name,
      node_type: newStep.node_type,
      ...(newStep.node_type === NODE_TYPES.CONDITION_NODE && {
        conditions: newStep.conditions,
      }),
    },
  };

  const reconnectedBaseStepJoins =
    mutableBaseStepJoins.map(stepJoin => {
      const mutableStepJoin = { ...stepJoin };
      if (newFist) {
        mutableStepJoin.position = stepJoin.position + 1;
      }
      const currentStepId = stepJoin.step.id || stepJoin.step.stepId;
      const parentStepId = realParent?.currentNode?.data?.stepId || realParent?.currentNode?.stepId;
      if (currentStepId === parentStepId) {
        if (stepJoin.step.node_type === NODE_TYPES.UPSELL_NODE) {
          // update upsell child pointer
          mutableStepJoin.pathway_true_next_step_id = newStep.id;
        } else if (stepJoin.step.node_type === NODE_TYPES.CONDITION_NODE) {
          // update condition child pointer
          const isTrueAction = realParent.branchType === NODE_TYPES.TRUE_NODE;
          if (isTrueAction) {
            mutableStepJoin.pathway_true_next_step_id = newStep.id;
          } else {
            mutableStepJoin.pathway_false_next_step_id = newStep.id;
          }
        }
      }
      return mutableStepJoin;
    }) || [];

  reconnectedBaseStepJoins.push(newBaseStepJoin);
  return reconnectedBaseStepJoins;
};

export const generateNewUpsellNode = (index, handleRemoveUpsell, parentNode, viewDetails) => {
  const position = {
    x: parentNode?.position?.x || 0,
    y: parentNode?.position?.y + 100 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: { label: 'New Upsell Node', removeUpsell: handleRemoveUpsell, viewDetails: viewDetails },
    type: NODE_TYPES.UPSELL_NODE,
    _isNew: true,
  };
};

export const generateNewEditNode = (
  handleAddCondition,
  handleAddUpsell,
  handleAddExistingCondition,
  handleAddExistingUpsell,
  parentNode,
  addMiddleCondition,
  addExistingMiddleCondition
) => {
  const position = {
    x: parentNode?.position?.x || 0,
    y: parentNode?.position?.y + 50 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: {
      label: '+',
      isDropZone: true,
      addCondition: handleAddCondition,
      addUpsell: handleAddUpsell,
      addExistingCondition: handleAddExistingCondition,
      addExistingUpsell: handleAddExistingUpsell,
      addMiddleCondition: addMiddleCondition,
      addExistingMiddleCondition: addExistingMiddleCondition,
    },
    type: NODE_TYPES.EDIT_NODE,
  };
};

export const generateNewEndNode = parentNode => {
  const position = {
    x: parentNode?.position?.x || 0,
    y: parentNode?.position?.y + 50 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: {
      label: 'end-node',
    },
    type: NODE_TYPES.END_NODE,
  };
};

export const generateNewConditionNode = (
  handleRemoveCondition,
  parentNode,
  campaignsList,
  availableCustomConditions,
  viewDetails
) => {
  const position = {
    x: parentNode?.position?.x || 0,
    y: parentNode?.position?.y + 100 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: {
      label: 'new Condition with some predicate',
      removeCondition: handleRemoveCondition,
      viewDetails: viewDetails,
      campaignsList: campaignsList,
      availableCustomConditions: availableCustomConditions,
      _isNew: true,
    },
    type: NODE_TYPES.CONDITION_NODE,
    _isNew: true,
  };
};

export const generateNewTrueNode = parentNode => {
  const position = {
    x: parentNode?.position?.x - 100 || 0,
    y: parentNode?.position?.y + 50 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: { label: 'True' },
    type: NODE_TYPES.TRUE_NODE,
  };
};

export const generateNewFalseNode = parentNode => {
  const position = {
    x: parentNode?.position?.x + 100 || 0,
    y: parentNode?.position?.y + 50 || 0,
  };
  const id = uuid();
  return {
    id: id,
    position: position,
    x: position.x,
    y: position.y,
    data: { label: 'False' },
    type: NODE_TYPES.FALSE_NODE,
  };
};

export const generateNewEdge = (sourceId, targetId, type, sourceHandle, endMarkerType) => {
  const edge = {
    id: uuid(),
    source: sourceId,
    target: targetId,
    pathOptions: { borderRadius: 40 },
    type: type || 'smoothstep',
    style: { strokeWidth: 3 },
    data: { label: 'data-label', sourceId: sourceId, targetId: targetId },
    weight: 1,
  };
  if (sourceHandle) {
    edge.sourceHandle = sourceHandle;
    edge.weight = 5;
  }
  if (endMarkerType) {
    edge.markerEnd = { type: endMarkerType, width: 20, height: 20 };
  }

  return edge;
};

export const formatUpsellPathwayForServer = upsellPathway => {
  // when the upsell pathway has not been viewed or modified, return it's partial form only
  if (upsellPathway && !upsellPathway._destroy && !upsellPathway._isNew && !upsellPathway.base_step_joins) {
    const formattedPathway = { ...upsellPathway };
    delete formattedPathway.__typename;
    delete formattedPathway.initialEdges;
    delete formattedPathway.initialNodes;
    return formattedPathway;
  }
  const positionOneStepJoins = upsellPathway.base_step_joins?.filter(join => join.position === 1 && !join._destroy);
  if (positionOneStepJoins?.length !== 1) {
    console.error({ positionOneStepJoins: positionOneStepJoins }, 'step join position index errors');
  }
  const indexOfFistBaseStepJoin = upsellPathway.base_step_joins?.findIndex(
    item => item.position === 1 && !item._destroy
  );
  let sortedBaseStepJoins = upsellPathway.base_step_joins ? [...upsellPathway.base_step_joins] : [];
  if (indexOfFistBaseStepJoin !== -1) {
    const spliced = sortedBaseStepJoins.splice(indexOfFistBaseStepJoin, 1);
    const first_bsj = spliced[0];
    sortedBaseStepJoins.unshift(first_bsj);
  }
  const formattedPathway = { ...upsellPathway };
  const baseStepJoins = sortedBaseStepJoins
    .map((bsj, index) => {
      if (bsj?._isNew) {
        if (bsj?._destroy) {
          return null;
        } else {
          return {
            position: index + 1,
            pathway_step_id: bsj?.step.id,
            pathway_true_next_step_id: bsj?.pathway_true_next_step_id || null,
            pathway_false_next_step_id: bsj?.pathway_false_next_step_id || null,
            step: {
              id: bsj?.step.id,
              revv_uid: bsj?.step.revv_uid,
              name: bsj?.step.name,
            },
          };
        }
      }
      return {
        id: bsj?.id,
        position: index + 1,
        pathway_step_id: bsj?.step.id,
        pathway_true_next_step_id: bsj?.pathway_true_next_step_id || null,
        pathway_false_next_step_id: bsj?.pathway_false_next_step_id || null,
        ...(bsj?._destroy && { destroy: bsj?._destroy }),
        step: {
          id: bsj?.step.id,
          revv_uid: bsj?.step.revv_uid,
          name: bsj?.step.name,
        },
      };
    })
    .filter(item => {
      return !!item;
    });
  formattedPathway.base_step_joins = baseStepJoins;
  formattedPathway.pathway_type = PATHWAY_TYPES.UPSELL;
  delete formattedPathway.pathway_end_steps;
  delete formattedPathway._destroy;
  delete formattedPathway._isNew;
  delete formattedPathway.revv_uid;
  delete formattedPathway.created_at;
  delete formattedPathway.updated_at;
  delete formattedPathway.creator;
  delete formattedPathway.page_count;
  delete formattedPathway.__typename;
  delete formattedPathway._initialEdges;
  delete formattedPathway._initialNodes;
  delete formattedPathway.initialNodes;
  delete formattedPathway.initialEdges;
  delete formattedPathway.firstRealNodeForPage;
  delete formattedPathway.pathway_steps;
  delete formattedPathway.upsell_count;
  return formattedPathway;
};

export const formatUpsellStepsForServer = step => {
  let formattedStep = {
    name: step.name,
    step_type: step.step_type,
    pathway_type: PATHWAY_TYPES.UPSELL,
  };
  if (!step._isNew) {
    formattedStep.id = step.id;
  }
  const formattedConditions = step.conditions
    ?.filter(cond => !(cond._isNew && cond._destroy))
    ?.map((cond, index) => {
      const conditionValue =
        cond.predicate === 'IS_PRESENT' ||
        cond.predicate === 'IS_BLANK' ||
        cond.predicate === 'EXISTS' ||
        cond.predicate === 'DOES_NOT_EXIST'
          ? []
          : formatConditionValue(cond);
      const conditionType =
        cond.condition_type === 'Custom Fields' ? 'NATIVE' : cond.condition_type.replace(' Fields', '');
      if (cond._isNew) {
        return {
          name: 'condition name',
          key: cond.key,
          position: cond.position || index + 1,
          predicate: cond.predicate,
          value: conditionValue,
          destroy: cond._destroy,
          condition_type: conditionType,
        };
      }
      return {
        name: 'condition name',
        key: cond.key,
        position: cond.position || index + 1,
        predicate: cond.predicate,
        id: cond.id,
        value: conditionValue,
        destroy: cond._destroy,
        condition_type: conditionType,
      };
    });
  formattedStep.conditions = formattedConditions;
  return formattedStep;
};

export const removeEditNodesForPanelUpsell = (nodes, edges, edgeToReformat, setEdges, setNodes) => {
  const nodeToDelete = nodes.find(node => node.id === edgeToReformat.target);
  const edgeToDelete = edges.find(edge => edge.source === nodeToDelete.id);
  const newNodes = nodes
    .map(node => {
      if (node.id !== nodeToDelete.id) {
        return node;
      } else {
        return null;
      }
    })
    .filter(item => !!item);
  const newEdges = edges
    .map(edge => {
      if (edge.id !== edgeToDelete.id) {
        if (edge.id === edgeToReformat.id) {
          edge.target = edgeToDelete.target;
          return null;
        }
        return edge;
      }
      return null;
    })
    .filter(item => !!item);
  setEdges(newEdges);
  setNodes(newNodes);
};

export const layoutTree = (nodes, edges) => {
  let visited = new Set();
  const rowsOfNodes = [];
  const newNodes = new Map();
  let nodeStack = [{ ...nodes[0], parentRow: -1 }];
  //   traverse, assign rows
  do {
    const currentNode = nodeStack.pop();
    const currentRow = currentNode.parentRow + 1;
    //   add this nodeId, to the row mapping, also check if it was revisited
    if (visited.has(currentNode.id)) {
      const oldRowOfNode = newNodes.get(currentNode.id).row;
      if (currentRow > oldRowOfNode) {
        const updatedRow = rowsOfNodes[oldRowOfNode].filter(item => item !== currentNode.id);
        rowsOfNodes[oldRowOfNode] = updatedRow;
      } else {
        // this node is already in a deeper row
        continue;
      }
    }
    if (!rowsOfNodes[currentRow]) {
      rowsOfNodes[currentRow] = [];
    }
    rowsOfNodes[currentRow].push(currentNode.id);
    // update row number on the node
    const children = getOutgoers(currentNode, nodes, edges).map(child => {
      return child;
    });
    const newNode = { ...currentNode, row: currentRow, children: children };
    newNode.data.children = children;
    newNodes.set(currentNode.id, newNode);
    visited.add(currentNode.id);
    if (children.length > 0) {
      children.forEach(child => {
        const xDelta = child.type === NODE_TYPES.FALSE_NODE ? 1 : child.type === NODE_TYPES.TRUE_NODE ? -1 : 0;
        const newData = { ...child.data, _deltaX: xDelta, _parentNodeId: currentNode?.id, _parentRow: currentRow };
        nodeStack.push({ ...child, parentRow: currentRow, data: newData });
      });
    }
  } while (nodeStack.length > 0);
  //   find max row width / column count
  //   map over newNodes, and assign position, based on their row / col mapping
  const minXGap = 400;
  const yGap = 200;

  let newFlatNodes = [];
  newNodes.forEach(node => newFlatNodes.push(node));
  rowsOfNodes.forEach((row, rowIndex) => {
    if (rowIndex === 0) {
      const firstNode = newNodes.get(row[0]);
      firstNode.position = { x: 0, y: 0 };
      newNodes.set(row[0], firstNode);
    } else {
      const siblingCount = row.length;
      row.forEach(nodeId => {
        // find position of parent node
        const nodeType = newNodes.get(nodeId).type;

        let parentId;
        parentId = edges.find(edge => edge.target === nodeId)?.source;
        const parent = newNodes.get(parentId);
        const parentX = parent?.position?.x;
        const parentY = parent?.position?.y;
        const thisNode = newNodes.get(nodeId);
        let newPositions;
        // if single
        if (
          siblingCount === 1 ||
          nodeType === NODE_TYPES.EDIT_NODE ||
          nodeType === NODE_TYPES.UPSELL_NODE ||
          nodeType === NODE_TYPES.CONDITION_NODE ||
          nodeType === NODE_TYPES.END_NODE
        ) {
          const yDelta =
            nodeType === NODE_TYPES.EDIT_NODE
              ? yGap / 2
              : nodeType === NODE_TYPES.CONDITION_NODE || nodeType === NODE_TYPES.UPSELL_NODE
              ? yGap * 1.3
              : yGap;
          newPositions = { x: parentX, y: parentY + yDelta };
          thisNode.position = newPositions;
          newNodes.set(nodeId, thisNode);
          return;
        } else {
          // assign new y positions for condition True/False nodes
          const newX = parentX;
          newPositions = { x: newX, y: parentY + yGap };
          thisNode.position = newPositions;
          newNodes.set(nodeId, thisNode);
          return;
        }
      });
    }
  });

  let rowsOfConditions = rowsOfNodes.map(() => []);
  let rowsOfEndNodes = rowsOfNodes.map(() => []);
  let countOfEndNodes = 0;

  rowsOfNodes.forEach((row, index) => {
    row.forEach(item => {
      const node = newNodes.get(item);
      if (node.type === NODE_TYPES.CONDITION_NODE) {
        rowsOfConditions[index].push(node.id);
      } else if (node.type === NODE_TYPES.END_NODE) {
        rowsOfEndNodes[index].push(node.id);
        countOfEndNodes++;
      }
    });
  });

  const countSplitLevels = rowsOfConditions.filter(row => row.length > 0).length;

  // update the x positions
  assignNetXSteps(newFlatNodes[0], newNodes, edges, 0, countOfEndNodes, countSplitLevels, 1);
  let positionRanks = [];
  newNodes.forEach(node => {
    if (!positionRanks.includes(node.data._netXStepsTaken)) {
      positionRanks.push(node.data._netXStepsTaken);
    }
  });
  positionRanks.sort((a, b) => a - b);
  let positionedNodes = [];
  newNodes.forEach(node => {
    const newX = positionRanks.indexOf(node.data._netXStepsTaken) * minXGap;
    const updatedXposition = { ...node.position, x: newX };
    positionedNodes.push({ ...node, position: updatedXposition });
  });
  return positionedNodes;
};

export const assignNetXSteps = (
  currentNode,
  allNodes,
  allEdges,
  xDeltaSoFar,
  totalWidthUnits,
  totalSplitLevels,
  currentSplitLevel
) => {
  const childCount = currentNode?.children?.length || 0;
  const newDelta = (currentNode?.data?._deltaX ?? 0) * 2 ** (totalSplitLevels - currentSplitLevel);
  const currentXDeltaSoFar = xDeltaSoFar + newDelta;
  currentNode.data._netXStepsTaken = currentXDeltaSoFar;
  if (childCount > 0) {
    const newSplitLevel = childCount > 1 ? currentSplitLevel + 1 : currentSplitLevel;
    currentNode?.children.forEach(child => {
      const nextChildNode = allNodes.get(child.id);
      assignNetXSteps(
        nextChildNode,
        allNodes,
        allEdges,
        currentXDeltaSoFar,
        totalSplitLevels,
        totalSplitLevels,
        newSplitLevel
      );
    });
  }
};

export const deleteConditionBranch = (
  deletedConditionNode,
  nodes,
  edges,
  oldBaseStepJoins,
  branchingMethod,
  shouldUpdateFirstPosition
) => {
  const outGoers = getOutgoers(deletedConditionNode, nodes, edges);
  const incomers = getIncomers(deletedConditionNode, nodes, edges);
  const immediateParentEditNode = incomers[0];

  let nodesToDelete = [deletedConditionNode.id];
  let edgesToDelete = [];
  let stepJoinStepIdsToDelete = [deletedConditionNode?.stepId || deletedConditionNode.data?.stepId];
  let salvagedBooleanNode = null;
  let nextRedirectNode = null;

  let nodesToVisit = [];
  if (branchingMethod === 'TRUE') {
    const trueNodeToDestroy = outGoers.find(node => node.type === NODE_TYPES.TRUE_NODE);
    nodesToVisit.push(trueNodeToDestroy);
    salvagedBooleanNode = outGoers.find(node => node.type === NODE_TYPES.FALSE_NODE);
  } else if (branchingMethod === 'FALSE') {
    const falseNodeToDestroy = outGoers.find(node => node.type === NODE_TYPES.FALSE_NODE);
    nodesToVisit.push(falseNodeToDestroy);
    salvagedBooleanNode = outGoers.find(node => node.type === NODE_TYPES.TRUE_NODE);
  } else {
    outGoers.forEach(node => nodesToVisit.push(node));
  }

  if (!!salvagedBooleanNode) {
    nodesToDelete.push(salvagedBooleanNode.id);
    const outGoerOfDeadNode = getOutgoers(salvagedBooleanNode, nodes, edges);
    if (outGoerOfDeadNode.length > 0) {
      nodesToDelete.push(outGoerOfDeadNode[0].id);
      edgesToDelete.push(edges.find(edge => edge.source === outGoerOfDeadNode[0].id).id);
    }
    const falseToEditEdge = edges.find(edge => edge.source === salvagedBooleanNode.id);
    edgesToDelete.push(falseToEditEdge.id);
    const editToNextEdge = edges.find(edge => edge.source === outGoerOfDeadNode[0].id);
    edgesToDelete.push(editToNextEdge);
    const realChild = findRealChildStep(salvagedBooleanNode.id, nodes, edges);
    nextRedirectNode = realChild?.currentNode;
  }

  do {
    const thisNode = nodesToVisit.pop();
    //   handle node list
    nodesToDelete.push(thisNode.id);
    //   handle edge list
    const edgeAbove = edges.find(edge => edge.target === thisNode.id);
    const edgeBelow = edges.find(edge => edge.source === thisNode.id);
    if (!!edgeAbove && edgeAbove.source !== immediateParentEditNode.id) {
      edgesToDelete.push(edgeAbove.id);
    }
    if (!!edgeBelow) {
      edgesToDelete.push(edgeBelow.id);
    }
    //   handle base step joins list
    if (thisNode.hasStepId || thisNode.stepId || thisNode.data?.stepId) {
      stepJoinStepIdsToDelete.push(thisNode.data?.stepId || thisNode.stepId);
    }
    const childNodes = getOutgoers(thisNode, nodes, edges);
    childNodes.forEach(node => {
      nodesToVisit.push(node);
    });
  } while (nodesToVisit.length > 0);

  const newNodes = nodes.filter(node => {
    return !nodesToDelete.includes(node.id);
  });
  const newEdges = edges
    .map(edge => {
      if (edge.source === incomers[0].id) {
        // bypass the deleted condition
        if (!!nextRedirectNode) {
          edge.target = nextRedirectNode.id;
        } else {
          const newEndNode = generateNewEndNode(null);
          edge.target = newEndNode.id;
          newNodes.push(newEndNode);
        }
      }
      return edge;
    })
    .filter(item => !edgesToDelete.includes(item.id));

  const reconnectToStepId = nextRedirectNode?.data?.stepId || nextRedirectNode?.stepId || null;

  const newBaseStepJoins = oldBaseStepJoins
    .map(step_join => {
      if (step_join.pathway_false_next_step_id === deletedConditionNode.data?.stepId) {
        step_join.pathway_false_next_step_id = reconnectToStepId;
      } else if (step_join.pathway_true_next_step_id === deletedConditionNode.data?.stepId) {
        step_join.pathway_true_next_step_id = reconnectToStepId;
      }
      if (stepJoinStepIdsToDelete.includes(step_join.step?.id || step_join.stepId)) {
        if (step_join._isNew) {
          return null;
        } else {
          step_join._destroy = true;
        }
      }

      return step_join;
    })
    .filter(item => !!item);

  if (shouldUpdateFirstPosition) {
    const donationPageNode = newNodes.find(node => node.type === NODE_TYPES.DONATION_PAGE);
    const outgoers = getOutgoers(donationPageNode, newNodes, newEdges);
    if (outgoers.length > 0) {
      const child = outgoers[0];
      const realStepChild = findRealChildStep(child.id, newNodes, newEdges);
      newBaseStepJoins.forEach(stepJoin => {
        if (
          stepJoin.step.id === realStepChild?.currentNode?.stepId ||
          stepJoin.step.id === realStepChild?.currentNode?.data?.stepId
        ) {
          stepJoin.position = 1;
        }
      });
    }
  }
  return { newNodes, newEdges, newBaseStepJoins };
};

export const getConditionChainLength = (thisNode, nodes, edges, maxChainLength, countSoFar) => {
  let conditionCounter = countSoFar;
  const children = getOutgoers(thisNode, nodes, edges);
  if(children.length === 0){
    return false;
  }
  if(children.length > 0){
    let nodesToVisit = [];
    const conditionChildren = children?.map(child => {
      const realChild = findRealChildStep(child.id, nodes, edges, null);
      return realChild?.currentNode;
    })?.filter(node => node?.type === NODE_TYPES.CONDITION_NODE);
    if(conditionChildren.length === 0){
      return false;
    } else {
      conditionChildren.forEach(child => nodesToVisit.push(child));
    }
    do {
      const thisConditionNode = nodesToVisit.pop();
      conditionCounter++;
      const children = getOutgoers(thisConditionNode, nodes, edges);
      const conditionStepChildren = children?.map(child => {
        const realChild = findRealChildStep(child.id, nodes, edges, null);
        return realChild?.currentNode;
      })?.filter(node => node?.type === NODE_TYPES.CONDITION_NODE);
      if(conditionStepChildren.length > 0){
        conditionStepChildren.forEach(child => nodesToVisit.push(child));
      }
    } while (conditionCounter <= maxChainLength && nodesToVisit.length > 0)
    return conditionCounter;
  }
}

export const isExceedingConditionsLimit = (thisNode, nodes, edges, maxChainLength, countSoFar) => {
  const chainLength = getConditionChainLength(thisNode, nodes, edges, maxChainLength, countSoFar);
  return chainLength > maxChainLength;
}

export const getConsecutiveConditions = (thisNode, nodes, edges, maxChainLength) => {
  let countConsecutiveConditions = 0;
  let nodesToVisit = [thisNode];
  let conditionChainBroken = false;
  do {
    const visiting = nodesToVisit.pop();
    const incomers = getIncomers(visiting, nodes, edges);
    const parent = incomers.length > 0 ? incomers[0] : null;
    if (
      parent?.type === NODE_TYPES.TRUE_NODE ||
      parent?.type === NODE_TYPES.FALSE_NODE ||
      parent?.type === NODE_TYPES.EDIT_NODE ||
      parent?.type === NODE_TYPES.CONDITION_NODE
    ) {
      if (parent.type === NODE_TYPES.CONDITION_NODE) {
        countConsecutiveConditions++;
      }
      nodesToVisit.push(parent);
    } else {
      conditionChainBroken = true;
    }
  } while (nodesToVisit.length > 0 && !conditionChainBroken && countConsecutiveConditions <= maxChainLength);

  return countConsecutiveConditions;
};


export const mapStatsToNodes = (nodes, statisticsData) => {
  const stepJoinsStats = statisticsData[0]?.base_step_joins;
  const nodesWithStats = nodes.map(node => {
    if(node.type === NODE_TYPES.CONDITION_NODE || node.type === NODE_TYPES.UPSELL_NODE){
      node.data.isLoadingStats = false;
      const matchedJoin = stepJoinsStats.find(stepJoin => stepJoin.step?.id === node.stepId);
      node.data.statistics = matchedJoin.step.statistics;
      if(node.type === NODE_TYPES.CONDITION_NODE){
        node.data.trueViewsCount = matchedJoin.true_views_count;
        node.data.trueAccepted = matchedJoin.true_accepted;
        node.data.trueConversionRate = matchedJoin.true_conversion_rate;
        node.data.falseViewsCount = matchedJoin.false_views_count;
        node.data.falseAccepted = matchedJoin.false_accepted;
        node.data.falseConversionRate = matchedJoin.false_conversion_rate;
      }
      return node;
    } else {
      return node;
    }
  });
  return nodesWithStats;
}

export const checkForValidStepJoinPointers = (pathway) => {
  const liveStepJoins = pathway.base_step_joins.filter(stepJoin => !stepJoin._destroy);
  let headStepJoin;
  if(liveStepJoins.length > 0){
    headStepJoin = liveStepJoins?.reduce((prev, curr) => prev.position < curr.position ? prev : curr);
  }
  const stepJoinCount = liveStepJoins?.length;
  if(!headStepJoin && stepJoinCount > 0) {
    return false;
  }
  if(!headStepJoin && stepJoinCount === 0) {
    return true;
  }
  let visitedSteps = [
    {
      stepJoinId: headStepJoin.id,
      stepId: headStepJoin.step.id,
      nodeType: headStepJoin.step.node_type,
    }
  ];
  let stepsToVisit = [];
  if(headStepJoin.pathway_true_next_step_id){
    stepsToVisit.push(headStepJoin.pathway_true_next_step_id);
  }
  if(headStepJoin.pathway_false_next_step_id){
    stepsToVisit.push(headStepJoin.pathway_false_next_step_id);
  }
  const liveStepsMap = {};
  liveStepJoins.forEach(stepJoin => {
    liveStepsMap[stepJoin.step.id] = {
      id: stepJoin.id,
      stepId: stepJoin.step.id,
      nodeType: stepJoin.step.node_type,
      pathway_true_next_step_id: stepJoin.pathway_true_next_step_id,
      pathway_false_next_step_id: stepJoin.pathway_false_next_step_id,
    };
  });
  do {
    const nextStepId = stepsToVisit.pop();
    const nextStep = liveStepsMap[nextStepId];
    if(nextStep){
      visitedSteps.push({
        stepJoinId: nextStep.id,
        stepId: nextStep.stepId,
        nodeType: nextStep.nodeType,
      });
    }
    if(nextStep?.pathway_true_next_step_id){
      stepsToVisit.push(nextStep.pathway_true_next_step_id);
    }
    if(nextStep?.pathway_false_next_step_id){
      stepsToVisit.push(nextStep.pathway_false_next_step_id);
    }
  } while (stepsToVisit.length > 0);

  return visitedSteps.length >= stepJoinCount;
}