import gql from 'graphql-tag';
import { storefrontSettingsFragment } from './categories.queries';

// GET_ORGANIZATION_STOREFRONT_CATEGORIES
export const GET_ORGANIZATION_STOREFRONT_CATEGORIES = gql`
  query getStateLevelStorefrontCategoriesIndex(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        storefront_settings {
          ...StorefrontSettings
        }
      }
    }
  }
  ${storefrontSettingsFragment}
`;

// Notes:
// 1. pass category revvUid for update
// 2. pass "parentCategoryId": null to clear parent category field
// 3. pass destroy: true to remove a product
// 4. pass productId to add a product
// UPDATE_ORGANIZATION_MERCH_CATEGORY
export const UPDATE_ORGANIZATION_MERCH_CATEGORY = gql`
  mutation updateOrganizationMerchCategoryMutation($category: MerchCategoryInput!, $organizationRevvUid: String) {
    organizationCreateOrUpdateMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      category {
        id
        revv_uid
        position
        created_at
        internal_name
        parent_category {
          id
          internal_name
        }
        sub_category_count
        product_count
        slug
        products {
          id
          name
        }
        hero_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
        hero_mobile_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
    }
  }
`;

// CREATE_ORGANIZATION_MERCH_CATEGORY_PARENT
export const CREATE_ORGANIZATION_MERCH_CATEGORY = gql`
  mutation createOrganizationMerchCategoryMutation($category: MerchCategoryInput!, $organizationRevvUid: String) {
    organizationCreateOrUpdateMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      category {
        id
        position
        created_at
        revv_uid
        internal_name
        parent_category {
          id
          internal_name
        }
        sub_category_count
        product_count
        slug
        products {
          id
          name
        }
        hero_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
        hero_mobile_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
    }
  }
`;

// ARCHIVE_ORGANIZATION_MERCH_CATEGORY
export const ARCHIVE_ORGANIZATION_MERCH_CATEGORY = gql`
  mutation archiveOrganizationMerchCategory($category: ArchiveMerchCategoryInput!, $organizationRevvUid: String!) {
    organizationArchiveMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
    }
  }
`;
