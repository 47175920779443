import { isAgencySelector, profileCandidateSelector } from 'core/login';
import React from 'react';
import { useSelector } from 'react-redux';

import { Icon, Typography, useClipBoard } from '..';
import { FormCard } from '../form';

function CommitteeBillPayId() {
  const isAgency = useSelector(isAgencySelector);

  const copyToClipBoard = useClipBoard();

  const profileCandidate = useSelector(profileCandidateSelector);

  const copyUrl = () => {
    copyToClipBoard({
      text: profileCandidate.bill_pay_id,
      toast: 'Bill-Pay ID copied to clipboard.',
    });
  };

  return (
    <FormCard
      title="Bill-Pay ID"
      subtitle={`Provide this ID to ${
        isAgency ? 'Committees' : 'Vendors'
      } to make a Bill-Pay connection with your account`}
      className="m-0"
    >
      <div className="border-mystic bg-catskill-white border rounded-sm d-flex align-items-center mb-1">
        <div className="border-right border-mystic px-3 py-2.5 pointer">
          <Icon icon="copy" color="nepal" title="Copy Bill-Pay ID" onClick={copyUrl} />
        </div>
        <Typography variant="bodyMedium" className="mb-0 mx-2.5 ellipsis pointer" onClick={copyUrl}>
          {profileCandidate.bill_pay_id}
        </Typography>
      </div>
      <Typography variant="body" italic className="mb-3" onClick={copyUrl}>
        Click to copy Bill-Pay ID to clipboard.
      </Typography>
    </FormCard>
  );
}

export { CommitteeBillPayId };
