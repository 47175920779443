import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';
import '../common.scss';

function InfoCardTitle({ title, subtitle }) {
  return (
    <>
      <Typography variant="bodyMedium" className="text-capitalize">
        {title}
      </Typography>
      <Typography variant="div" className="id recording-sensitive">
        {subtitle}
      </Typography>
    </>
  );
}

InfoCardTitle.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
};

export default React.memo(InfoCardTitle);
