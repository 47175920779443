import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from 'modules/common';

function withList(FeatureComponent) {
  class ListLogic extends Component {
    state = {
      error: null,
    };

    componentDidMount() {
      // if we already have data then just log event
      if (this.props?.data?.viewer) return this.sendLogEvent();
      this.props?.data
        ?.refetch()
        .then(this.sendLogEvent)
        .catch(error => this.setState({ error }));
    }

    sendLogEvent = () => this.props.logEvent(`view ${this.props.listKey} index`);

    render() {
      const { error } = this.state;
      return (
        <>
          {setTitle(this.props.listKey)}
          <FeatureComponent {...this.props} error={error} />
        </>
      );
    }
  }

  ListLogic.propTypes = {
    data: PropTypes.object.isRequired,
    logEvent: PropTypes.func.isRequired,
    listKey: PropTypes.string.isRequired,
  };

  return ListLogic;
}

export default withList;
