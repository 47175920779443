import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';
import { convertEpochTime } from 'core/utilities';
import '../common.scss';

function InfoCardDate({ title, date, convertedDate }) {
  return (
    <Typography className="date">
      {title}: <span className="text-uppercase">{convertedDate ? convertedDate : convertEpochTime(date)}</span>
    </Typography>
  );
}

InfoCardDate.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.any,
  convertedDate: PropTypes.string,
};

export default React.memo(InfoCardDate);
