import React from 'react';
import PropTypes from 'prop-types';

import { ListFilter, WinRedFormSelect, dateRangeFilterUi, searchFilterUi, dropdownFilterUi } from 'modules/common';

const STATUS_OPTIONS = [
  { value: '', label: '' },
  { value: 'live', label: 'Live' },
  { value: 'draft', label: 'Draft' },
  { value: 'password_protected', label: 'Password Protected' },
  { value: 'archived', label: 'Archived' },
];

export const mapFilterToUi = (filter, navTabs, feesOptions) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: navTabs,
    filterKey: 'activeTabKey',
    label: 'Page Type',
    defaultValue: 'all',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: 'all',
    dropdownOptions: feesOptions,
    filterKey: 'fees',
    label: 'Bill-Pay',
  });

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: STATUS_OPTIONS,
    filterKey: 'status',
    label: 'Status',
  });

  return [uiFilter, numberOfFilters];
};

const selectedDropdownOption = (options, filter) => options.find(o => o.value === filter);

function PagesFilter({
  filter,
  onChangeFilter,
  clearFilter,
  submitFilter,
  numberOfFilters,
  feesOptions,
  filterSearchToolTip,
}) {
  return (
    <ListFilter
      submitFilter={submitFilter}
      clearFilter={clearFilter}
      onChangeFilter={onChangeFilter}
      filter={filter}
      searchPlaceholder="Pages"
      numberOfFilters={numberOfFilters}
      filterSearchToolTip={filterSearchToolTip}
    >
      <WinRedFormSelect
        title="Status"
        name="status"
        onChange={onChangeFilter}
        value={selectedDropdownOption(STATUS_OPTIONS, filter.status)}
        options={STATUS_OPTIONS}
      />

      <WinRedFormSelect
        title="Bill-Pay"
        name="fees"
        onChange={onChangeFilter}
        value={selectedDropdownOption(feesOptions, filter.fees)}
        options={feesOptions}
      />
    </ListFilter>
  );
}

PagesFilter.propTypes = {
  filter: PropTypes.object,
  numberOfFilters: PropTypes.number.isRequired,
  clearFilter: PropTypes.func.isRequired,
  submitFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default PagesFilter;
