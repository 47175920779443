import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import { FloatGroup, Input } from 'modules/common';
import ColorPicker from './color-picker.component';
import classnames from 'classnames';
class ColorPickerInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowPicker: false,
    };
  }

  /** when clicking outside a color picker we need to close it so watch for clicks ANYWHERE outside those submenus */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideColorPicker, { capture: true });
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideColorPicker, { capture: true });
  }

  handleClickOutsideColorPicker = event => {
    if (this.colorPickerRef && !this.colorPickerRef.contains(event.target)) {
      this.setState({ isShowPicker: false });
    }
  };

  setColorPickerRef = node => (this.colorPickerRef = node);

  onChange = color => this.props.setColorAction({ target: { name: this.props.name, value: color.hex } });

  onHide = () => this.setState({ isShowPicker: false });

  toggleColorPicker = () => this.setState({ isShowPicker: !this.state.isShowPicker });

  render() {
    const { value, label, description, disabled, className } = this.props;

    return (
      <div className={classnames('color-picker-row', className)} ref={this.setColorPickerRef}>
        <div className="color-picker-circle-wrapper">
          <div
            className={classname('color-picker-circle no-outline', { pointer: !disabled })}
            style={{ backgroundColor: value }}
            role={disabled ? null : 'button'}
            tabIndex={disabled ? null : '0'}
            onClick={disabled ? null : this.toggleColorPicker}
            onKeyPress={disabled ? null : this.toggleColorPicker}
          />
        </div>

        <div className="d-flex w-100 relative">
          <FloatGroup onClick={this.toggleColorPicker} className="w-100">
            <Input
              type="text"
              name={this.props.name}
              label={label}
              placeholder={label}
              value={value}
              readOnly
              disabled={disabled}
              maxLength={7}
              description={description}
              className="winred-input-color"
            />
          </FloatGroup>

          {this.state.isShowPicker && !disabled ? (
            <ColorPicker value={value} onChange={this.onChange} onHide={this.onHide} disabled={disabled} />
          ) : null}
        </div>
      </div>
    );
  }
}

ColorPickerInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setColorAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ColorPickerInput;
