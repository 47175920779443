import React from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';

import { Icon, Typography } from 'modules/common';
import cardLogo from 'assets/images/card-winred-logo.png';

/**
 * make sure we have a valid credit card brand - or default to generic credit card icon
 * @param {string} cardBrand
 */
const computeCreditCardIcon = cardBrand => {
  const validCardTypes = ['visa', 'discover', 'amex', 'mastercard'];
  let brand = cardBrand;
  if (cardBrand === 'american_express') brand = 'amex';

  // if we have valid brand show brand else show default cc icon
  if (validCardTypes.includes(brand)) return `cc-${brand}`;
  else return 'credit-card-front';
};

const PaymentDetails = ({ data }) => {
  const icon = computeCreditCardIcon(data.card_brand);

  return (
    <ListGroup.Item>
      <Typography tag="dt">Payment Details</Typography>
      <dd>
        <div className="payment-details">
          <ListGroup variant="flush" as="dl">
            <img className="card-logo" src={cardLogo} alt="WinRed logo" />
            <ListGroup.Item key="last4">
              <dd className="card-value recording-sensitive">{data.card_last4}</dd>
              <dd className="card-value recording-sensitive">{data.card_expiry}</dd>
              <dd>
                <Icon icon={icon} className="card-brand" size="3x" />
              </dd>
            </ListGroup.Item>
            <ListGroup.Item key="titles">
              <dd className="card-field">Number</dd>
              <dd className="card-field">Expires</dd>
              <dd />
            </ListGroup.Item>
          </ListGroup>
        </div>
      </dd>
    </ListGroup.Item>
  );
};

PaymentDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PaymentDetails;
