import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, FloatGroup, Input, FlexBinaryChoiceRow } from 'modules/common';
import { isValidURL } from 'core/utilities';

function ConfirmationButton({ item, onChange, name, disabled, subtitle }) {
  const [requireCustomButton, setRequireCustomButton] = useState(() =>
    item.confirmationCustomLink && item.confirmationCustomLink !== '' ? true : false
  );

  const toggleCustomButton = ({ target: { name, value } }) => {
    if (!value) {
      item.confirmationCustomLinkCopy = '';
      item.confirmationCustomLink = '';
      onChange({ target: { name, value: item } });
    }

    setRequireCustomButton(value);
  };

  return (
    <FormCardSpaced
      classNameFooter="pb-1 bg--catskill-white"
      footer={
        requireCustomButton && (
          <>
            <FloatGroup>
              <Input
                type="text"
                disabled={disabled}
                name="confirmationCustomLinkCopy"
                placeholder="Custom Button Text"
                value={item.confirmationCustomLinkCopy}
                maxLength="100"
                validators={['required']}
                errorMessages={['Custom Button Text is required.']}
                onChange={onChange}
              />
            </FloatGroup>
            <FloatGroup>
              <Input
                type="text"
                disabled={disabled}
                name="confirmationCustomLink"
                placeholder="Custom Button URL"
                validateOnBlurOnly
                value={item.confirmationCustomLink}
                validators={['required', isValidURL]}
                errorMessages={['Custom Button URL is required.', 'A valid URL is required.']}
                maxLength="255"
                onChange={onChange}
                description='Custom Button URL must include "https://".'
              />
            </FloatGroup>
          </>
        )
      }
    >
      <FlexBinaryChoiceRow
        disabled={disabled}
        title="Display Custom Button"
        subTitle={subtitle}
        value={requireCustomButton}
        name={name}
        onChange={toggleCustomButton}
      />
    </FormCardSpaced>
  );
}

ConfirmationButton.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  subtitle: PropTypes.string,
};

ConfirmationButton.defaultProps = {
  disabled: false,
  subtitle: 'Select whether to display a custom button on your confirmation page.',
};

export default ConfirmationButton;
