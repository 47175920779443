import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced } from 'modules/common';
import LogoImage from './logo-image.component';
import { AcceptButton } from '../index';

const customButtonUpsellTypes = ['MOBILEOPTIN', 'PASSWORD'];

function StandardUpsell({ upsellCard, onUpdateEditedUpsell, children }) {
  return (
    <div className="my-3 px-4">
      {customButtonUpsellTypes.includes(upsellCard.type) ? (
        <FormCardSpaced>
          <AcceptButton
            className="pb-3"
            editedUpsell={upsellCard}
            onUpdateEditedUpsell={onUpdateEditedUpsell}
            noDecline
            subtitle="Enter copy for the accept button."
          />
        </FormCardSpaced>
      ) : null}
      <FormCardSpaced>
        <LogoImage upsellCard={upsellCard} onUpdateEditedUpsell={onUpdateEditedUpsell} />
      </FormCardSpaced>

      {children}
    </div>
  );
}

StandardUpsell.propTypes = {
  upsellCard: PropTypes.object.isRequired,
  children: PropTypes.any,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

StandardUpsell.defaultProps = {
  children: null,
};

export default StandardUpsell;
