import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { Button } from 'modules/common/form';
import party from 'party-js';
import { EMOJII_MAP, SHAPE_MAP } from '../confetti/fields';

function WidgetConfettiAnimationPreview({ widget }) {
  const handleOnClick = e => {
    let confettiVariables = {
      count: party.variation.range(20, 40),
    };
    if (widget?.confettiUseEmoji) {
      if (!widget?.confettiEmoji) {
        return alert('Please choose Emoji');
      }
      let emojiSrc = '';
      if (widget?.widgetImage?.image?.thumbnailUrl) {
        emojiSrc = widget.widgetImage.image.thumbnailUrl;
      } else {
        emojiSrc = EMOJII_MAP[widget?.confettiEmoji];
      }
      party.resolvableShapes['customEmoji'] = `<img style="width:30px; height: 30px;" src="${emojiSrc}" alt="" />`;
      confettiVariables.shapes = ['customEmoji'];
    } else {
      if (widget?.confettiShape !== 'DEFAULT') {
        confettiVariables.shapes = [SHAPE_MAP[widget?.confettiShape]];
      }
      if (widget?.confettiColor) {
        confettiVariables.color = party.Color.fromHex(widget?.confettiColor);
      }
    }
    if (widget?.confettiAnimationStyle === 'SPARKLES') {
      party.sparkles(e.target, confettiVariables);
    } else {
      party.confetti(e.target, confettiVariables);
    }
  };
  return (
    <Container className="widget-preview_countdown">
      <Row className="justify-center">
        <Button onClick={e => handleOnClick(e)}>Test Confetti</Button>
      </Row>
    </Container>
  );
}

WidgetConfettiAnimationPreview.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default WidgetConfettiAnimationPreview;
