import produce from 'immer';

import {
  SET_BILL_PAY_PAGES_FILTER,
  SET_BILL_PAY_UPSELLS_FILTER,
  SET_DASHBOARD_FILTER,
  SET_TRANSFERS_FILTER,
  SET_CUSTOM_REPORTS_FILTER,
} from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';
import { getCandidateDate } from 'core/utilities/time';

export const initialVendorState = {
  billpayPagesFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    feePercentage: null,
    feePredicate: 'EQ',
    recipientRevvUid: null,
    pageType: 'all',
    status: 'all',
  },
  billpayUpsellsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    feePercentage: null,
    feePredicate: 'EQ',
    recipientRevvUid: null,
    upsellType: 'all',
  },
  dashboardFilter: {
    search: null,
    dateRangeStart: () => getCandidateDate(),
    dateRangeEnd: () => getCandidateDate(),
    page: 1,
  },
  transfersFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    transferAmount: null,
    transferAmountPredicate: 'EQ',
  },
  customReportsFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    report_type: null,
    page: 1,
  },
};

export default produce((state = initialVendorState, action) => {
  switch (action.type) {
    case SET_BILL_PAY_PAGES_FILTER:
      return { ...state, billpayPagesFilter: action.filter };
    case SET_BILL_PAY_UPSELLS_FILTER:
      return { ...state, billpayUpsellsFilter: action.filter };
    case SET_DASHBOARD_FILTER:
      return { ...state, dashboardFilter: action.filter };
    case SET_TRANSFERS_FILTER:
      return { ...state, transfersFilter: action.filter };
    case SET_CUSTOM_REPORTS_FILTER:
      return { ...state, customReportsFilter: action.filter };
    // goes at bottom
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialVendorState };
    default:
      return state;
  }
}, initialVendorState);
