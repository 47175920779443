import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, FormCard, FloatGroup, Typography, Input, ImagePrompt, ConfirmModal } from 'modules/common';
import 'modules/pages/pages.scss';

function TwitterOpenGraph({
  onChange,
  data,
  twitterImages,
  dataType = 'page',
  disabled,
  title,
  subtitle,
  twitterValidations,
}) {
  const [showRemoveTwitterImageModal, setShowRemoveTwitterImageModal] = useState(false);
  const twitterAssignedImage = data?.twitterAssignedImage?.image;
  const twitterImageResults =
    (twitterImages?.viewer?.candidate || twitterImages?.viewer?.state_level_organization)?.images?.results || null;

  const previewImage = useMemo(() => {
    if (!twitterImages && !twitterAssignedImage) {
      return null;
    }
    return twitterImageResults?.find(
      image =>
        image.id === twitterAssignedImage?.id ||
        (image.id === data?.twitterAssignedImage?.imageId && !data?.twitterAssignedImage?.destroy)
    )?.thumbnailUrl;
  }, [twitterImages, twitterAssignedImage, data, twitterImageResults]);

  return (
    <FormCard
      title={title || 'Twitter Graph Settings'}
      subtitle={
        subtitle ||
        `Twitter uses its own settings to populate a ${dataType}'s preview within Tweets. All WinRed ${dataType}s have
            default settings, but you can override them.`
      }
    >
      <FloatGroup>
        <Input
          type="text"
          name="twitterTitle"
          placeholder="Twitter Title"
          value={data?.twitterTitle || ''}
          onChange={onChange}
          disabled={disabled}
          validators={twitterValidations?.twitterTitle?.validators}
          errorMessages={twitterValidations?.twitterTitle?.errorMessages}
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          name="twitterDescription"
          placeholder="Twitter Description"
          value={data?.twitterDescription || ''}
          onChange={onChange}
          disabled={disabled}
          description="We recommend using less than 150 characters."
          validators={twitterValidations?.twitterDescription?.validators}
          errorMessages={twitterValidations?.twitterDescription?.errorMessages}
        />
      </FloatGroup>

      <Card.Title>Twitter Image</Card.Title>
      <Typography>
        Upload an image to populate the preview image for Twitter. Make sure your image is at least 600px by 315px and
        has a 2:1 aspect ratio. Otherwise, we will crop it to fit Twitter&apos;s standards.
      </Typography>

      <ImagePrompt
        imageUri={data?.twitterAssignedImage?.image?.thumbnailUrl ?? previewImage}
        name="twitterAssignedImage"
        disabled={disabled}
        selectImageAction={onChange}
        imageType="TWITTER_IMAGE"
        removeImageAction={() => setShowRemoveTwitterImageModal(true)}
        validators={twitterValidations?.twitterAssignedImage?.validators}
        errorMessages={twitterValidations?.twitterAssignedImage?.errorMessages}
        description={twitterValidations?.twitterAssignedImage?.description}
      />

      <ConfirmModal
        show={showRemoveTwitterImageModal}
        title="Remove Twitter Image"
        buttonText="Remove"
        handleClose={() => setShowRemoveTwitterImageModal(false)}
        buttonColor="cancel"
        onSubmit={() => {
          const value = {
            destroy: true,
            imageId: data.twitterAssignedImage?.image?.id,
          };
          onChange({ target: { name: 'twitterAssignedImage', value } });
          setShowRemoveTwitterImageModal(false);
        }}
      >
        Are you sure you want to remove this Twitter Image?
      </ConfirmModal>
    </FormCard>
  );
}

TwitterOpenGraph.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataType: PropTypes.string,
  twitterValidations: PropTypes.object,
  twitterImages: PropTypes.object,
};

export default TwitterOpenGraph;
