import React from 'react';
import PropTypes from 'prop-types';
import { FlexBinaryChoiceRow, FormCardSpaced } from 'modules/common';

function EmployerDetails({
  name,
  onChange,
  page,
  enableEmployerDetails,
  setEnableEmployerDetails,
  enableEmployerAddressDetails,
}) {
  const disableEmployerDetails = page._enableEmployerAddressDetails;
  const disableEmployerCityStateZip = page._enableEmployerCityStateZip || disableEmployerDetails;
  return (
    <>
      <FormCardSpaced>
        <FlexBinaryChoiceRow
          title="Display Employer Name &amp; Occupation"
          subTitle="Select whether to display Employer Name and Occupation."
          value={page.enableEmployerNameAndOccupation}
          name="enableEmployerNameAndOccupation"
          disabled={page.enableEmployerNameAndOccupation}
          onChange={onChange}
        />
      </FormCardSpaced>
      {page.enableEmployerNameAndOccupation || enableEmployerAddressDetails ? (
        <FormCardSpaced
          classNameFooter="bg--catskill-white"
          footer={
            enableEmployerDetails && (
              <>
                <FormCardSpaced className="mt-0 mb-3">
                  <FlexBinaryChoiceRow
                    title="Employer Address"
                    subTitle="Select whether to display the Employer Address field."
                    value={page.employerAddress || disableEmployerDetails}
                    name="employerAddress"
                    disabled={disableEmployerDetails}
                    onChange={onChange}
                  />
                </FormCardSpaced>
                <FormCardSpaced className="mt-0 mb-3">
                  <FlexBinaryChoiceRow
                    title="Employer City"
                    subTitle="Select whether to display the Employer City field."
                    value={page.employerCity || disableEmployerCityStateZip}
                    disabled={disableEmployerCityStateZip}
                    name="employerCity"
                    onChange={onChange}
                  />
                </FormCardSpaced>
                <FormCardSpaced className="mt-0 mb-3">
                  <FlexBinaryChoiceRow
                    title="Employer State"
                    disabled={disableEmployerCityStateZip}
                    subTitle="Select whether to display the Employer State field."
                    value={page.employerState || disableEmployerCityStateZip}
                    name="employerState"
                    onChange={onChange}
                  />
                </FormCardSpaced>
                <FormCardSpaced className="mt-0 mb-3">
                  <FlexBinaryChoiceRow
                    title="Employer Zip"
                    disabled={disableEmployerCityStateZip}
                    subTitle="Select whether to display the Employer Zip field."
                    value={page.employerZip || disableEmployerCityStateZip}
                    name="employerZip"
                    onChange={onChange}
                  />
                </FormCardSpaced>
              </>
            )
          }
        >
          <FlexBinaryChoiceRow
            title="Display Employer Address Details"
            subTitle="Select whether to display donor Employer Address details."
            value={enableEmployerDetails || disableEmployerDetails}
            disabled={disableEmployerDetails || disableEmployerCityStateZip}
            name="enableEmployerDetails"
            onChange={({ target: { value } }) => {
              // need to update multiple values
              const newCheckoutPage = { ...page };
              setEnableEmployerDetails(value);
              newCheckoutPage.employerAddress = value;
              newCheckoutPage.employerCity = value;
              newCheckoutPage.employerState = value;
              newCheckoutPage.employerZip = value;

              onChange({ target: { name, value: newCheckoutPage } });
            }}
          />
        </FormCardSpaced>
      ) : null}
    </>
  );
}

EmployerDetails.propTypes = {
  name: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setEnableEmployerDetails: PropTypes.func.isRequired,
  enableEmployerDetails: PropTypes.bool.isRequired,
};

export default EmployerDetails;
