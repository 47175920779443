import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Card, Icon, Select, SubText } from 'modules/common';
import { getLabel, getCandidateDropdownOptions } from './tools';
import './committees.scss';

function SearchCommittee({
  conduitCandidate,
  removeCandidate,
  allowedCandidatesToAdd,
  searchTitle,
  onSelectCandidate,
  dropdownSearchValue,
  setDropdownSearchValue,
  loading,
  noOptionsMessage,
}) {
  // get the formatted dropdown options based on searched dropdown input value
  const candidateDropdownOptions = useMemo(
    () => getCandidateDropdownOptions(allowedCandidatesToAdd, dropdownSearchValue),
    [allowedCandidatesToAdd, dropdownSearchValue]
  );

  const _removeCandidate = useCallback(() => removeCandidate(conduitCandidate), [removeCandidate, conduitCandidate]);

  const onChangeCommittee = ({ target: { value } }) => {
    const _candidate = conduitCandidate.candidate || conduitCandidate.organization;
    onSelectCandidate(value, _candidate.revv_uid);
  };

  return (
    <div className="mb-3">
      <Card className="info-card">
        <Row>
          <Col xs={10}>
            <Select
              onChange={onChangeCommittee}
              getOptionLabel={getLabel}
              value={dropdownSearchValue}
              options={candidateDropdownOptions}
              hideDropdownIndicator={false}
              dropdownSearchValue={dropdownSearchValue}
              isSearchable
              placeholder="Search committees..."
              prependSearchIcon
              filterOption={false}
              onInputChange={setDropdownSearchValue}
              validators={['required']}
              errorMessages={['Committee name is required.']}
              isLoading={loading}
              noOptionsMessage={noOptionsMessage}
            />
          </Col>
          <Col xs={2} className="ml-auto">
            <Icon icon="times" size="lg" className="pointer custom-close-btn mr-2" onClick={_removeCandidate} />
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            <SubText description={searchTitle || 'Search for a committee to add it to this page.'} />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

SearchCommittee.propTypes = {
  conduitCandidate: PropTypes.object.isRequired,
  removeCandidate: PropTypes.func.isRequired,
  allowedCandidatesToAdd: PropTypes.array.isRequired,
  searchTitle: PropTypes.node,
  onSelectCandidate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.string.isRequired,
};

export default SearchCommittee;
