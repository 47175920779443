import React from 'react';
import PropTypes from 'prop-types';
import { Timeline as EventTimeline, TimelineEvent } from 'react-event-timeline';

import { FormCard } from 'modules/common';
import { convertEpochTime } from 'core/utilities';
import './smooth-scroll.scss';

const timelineStyles = {
  line: {
    width: '3px',
    backgroundColor: '#94aac1',
    left: '3px',
    top: '-5px',
  },
  eventContainer: {
    boxShadow: '0',
    transform: 'translateY(-25%)',
  },
  bubble: {
    width: '9px',
    height: '9px',
    left: '0rem',
    marginLeft: '0px',
    top: '10px',
    border: '0px',
    backgroundColor: '#94aac1',
  },
  eventContentsContainer: {
    backgroundColor: 'transparent',
    padding: '0px',
    width: '99%',
    display: 'inline-block',
  },
  inlineTitles: {
    width: '50%',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 500,
    color: '#1e2b3a',
  },
  inlineSubTitles: {
    width: '50%',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    color: '#627890',
  },
};

const handleAnchorClick = (anchorId, event) => {
  event.preventDefault();

  const sectionId = anchorId + '_anchor';
  const section = document.getElementById(sectionId);

  if (section) {
    const rect = section.getBoundingClientRect();
    const verticalPosition = rect.top + window.scrollY;
    const currentScrollYPosition = window.scrollY;
    const options = {
      behavior: 'smooth',
      block: 'start',
    };
    section.scrollIntoView(options);
    window.scrollBy(0, verticalPosition - 250 - currentScrollYPosition);
  }
};

function Timeline({ title, events, useAnchorLinks = false }) {
  return (
    <div className="detail-card-container">
      <FormCard title={title} classNameBody="pb-4">
        <EventTimeline className="timeline p-0 m-0" lineStyle={timelineStyles.line}>
          {events.length &&
            events.map((timeLineEvent, index) => {
              return useAnchorLinks && timeLineEvent.anchorId ? (
                <a
                  href="#"
                  className="smooth-scroll"
                  key={timeLineEvent.id + timeLineEvent.title + index}
                  onClick={event => handleAnchorClick(timeLineEvent.anchorId, event)}
                >
                  <TimelineEvent
                    className="timeline-event"
                    icon={<span />}
                    style={timelineStyles.eventContainer}
                    bubbleStyle={timelineStyles.bubble}
                    key={timeLineEvent.id}
                    cardHeaderStyle={timelineStyles.eventContentsContainer}
                    container="card"
                    title={timeLineEvent.title}
                    titleStyle={timelineStyles.inlineTitles}
                    subtitle={convertEpochTime(timeLineEvent.date)}
                    subtitleStyle={timelineStyles.inlineSubTitles}
                  />
                </a>
              ) : (
                <TimelineEvent
                  className="timeline-event"
                  icon={<span />}
                  style={timelineStyles.eventContainer}
                  bubbleStyle={timelineStyles.bubble}
                  key={timeLineEvent.id}
                  cardHeaderStyle={timelineStyles.eventContentsContainer}
                  container="card"
                  title={timeLineEvent.title}
                  titleStyle={timelineStyles.inlineTitles}
                  subtitle={convertEpochTime(timeLineEvent.date)}
                  subtitleStyle={timelineStyles.inlineSubTitles}
                />
              );
            })}
        </EventTimeline>
      </FormCard>
    </div>
  );
}

Timeline.propTypes = {
  user: PropTypes.object,
  info: PropTypes.object,
  type: PropTypes.string,
  hideCard: PropTypes.bool,
  isOrganization: PropTypes.bool,
  title: PropTypes.string,
  events: PropTypes.array.isRequired,
  useAnchorLinks: PropTypes.bool,
};

Timeline.defaultProps = {
  donor: {},
};

export default Timeline;
