import React from 'react';

export function ExternalLink({ href, className = '', children }) {
  return (
    <a
      className={'text-winred-red underline hover:text-winred-red-light ' + className}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  );
}
