import React from 'react';
import PropTypes from 'prop-types';
import { DISPUTE_STATUSES, formatDisputeRate, getDisputeIcon } from './dashboard';
import { Icon, Table, ToolTip, Typography } from '../common';

function DisputesTableDesktopComponent({ disputeRates }) {
  const tableBody = disputeRates.map((dispute, index) => {
    return (
      <Table.Row key={index}>
        <Table.Cell textAlign="center" className="icon-cell">
          {getDisputeIcon(dispute?.status)}
        </Table.Cell>
        <Table.Cell>
          <Typography className="mb-0">{dispute.month}</Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography className="mb-0">{formatDisputeRate(dispute)}</Typography>
        </Table.Cell>
        <Table.Cell className="mb-0">
          <Typography variant="bodyMedium" className="mb-0 c--mirage">
            {DISPUTE_STATUSES[dispute.status]?.label}
          </Typography>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table>
      <Table.Head>
        <Table.Row className="no-pointer">
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Month</Table.HeaderCell>
          <Table.HeaderCell>
            Dispute Rate{' '}
            <ToolTip tooltip="This is calculated by taking the number of Visa disputes and dividing it by the number of Visa donations.">
              <Icon icon="info-circle" size="lg" color="grey" className="mb-0" />
            </ToolTip>
          </Table.HeaderCell>
          <Table.HeaderCell>Dispute Status</Table.HeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

DisputesTableDesktopComponent.propTypes = {
  disputeRates: PropTypes.array.isRequired,
};

export { DisputesTableDesktopComponent };
