import gql from 'graphql-tag';
import { moneyPledgeFragments } from './moneyPledge.queries';

export const GET_ORGANIZATION_MONEYPLEDGES_QUERY = gql`
  query getOrganizationMoneyPledges(
    $page: Int
    $limit: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $sortOnActionDate: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        money_pledge_statistics(
          filters: { search: $search, dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd }
        ) {
          totalAmount {
            pledgedAmount
            canceledAmount
            processedAmount
            failedAmount
          }
          conduitedAmount {
            pledgedAmount
            canceledAmount
            processedAmount
            failedAmount
          }
        }
        campaigns(
          filters: {
            initiativeType: [MONEYPLEDGE]
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sortOnActionDate: $sortOnActionDate
          }
        ) {
          ...MoneyPledges
        }
      }
    }
  }
  ${moneyPledgeFragments.moneyPledges}
`;

export const GET_ORGANIZATION_MONEYPLEDGE_QUERY = gql`
  query getOrganizationMoneyPledge($revvUid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { revvUid: $revvUid }) {
          results {
            ...MoneyPledge
          }
        }
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const CREATE_ORGANIZATION_MONEY_PLEDGE_MUTATION = gql`
  mutation orgCreateCampaignMutation($campaign: CreateCampaignInput!) {
    organizationCreateCampaign(campaign: $campaign) {
      errors
      campaign {
        ...MoneyPledge
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const UPDATE_ORGANIZATION_MONEY_PLEDGE_MUTATION = gql`
  mutation orgUpdateCampaignMutation($campaign: UpdateCampaignInput!) {
    organizationUpdateCampaign(campaign: $campaign) {
      errors
      campaign {
        ...MoneyPledge
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const REFRESH_ORGANIZATION_MONEYPLEDGE_STATISTICS_MUTATION = gql`
  mutation RefreshOrganizationMoneyPledgeStatistics($campaign: RefreshCampaignStatisticsInput!) {
    organizationRefreshCampaignStatistics(campaign: $campaign) {
      message
    }
  }
`;

export const ORGANIZATION_DOWNLOAD_MONEYPLEDGE_PAGE_LINKS_QUERY = gql`
  mutation organizationDownloadMoneyPledgePageLinks($campaign: DownloadCampaignPageLinksInput!) {
    organizationDownloadCampaignPageLinks(campaign: $campaign) {
      message
      downloadUrl
    }
  }
`;

export const GET_ORGANIZATION_MONEYPLEDGE_OPTIONS_REPORT = gql`
  query getOrganizationMoneyPledgeOptinsReport($revvUid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        export_money_pledge_opt_ins(filters: { revvUid: $revvUid })
      }
    }
  }
`;

export const ORGANIZATION_BULK_ACTION_UPDATE_PAGES_MUTATION = gql`
  mutation organizationBulkUpdatePages($bulkActions: BulkActionsInput, $organizationRevvUid: String!) {
    organizationBulkUpdatePages(bulkActions: $bulkActions, organizationRevvUid: $organizationRevvUid) {
      dammed
      errors
      message
      updatedPagesCount
    }
  }
`;

export const GET_ORGANIZATION_MONEYPLEDGE_OPTIONS_QUERY = gql`
  query getOrganizationMoneyPledgeOptions(
    $search: String
    $dateRangeStart: String
    $sortOnActionDate: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(
          filters: {
            initiativeType: [MONEYPLEDGE]
            limit: 9999
            search: $search
            dateRangeStart: $dateRangeStart
            sortOnActionDate: $sortOnActionDate
          }
        ) {
          results {
            revv_uid
            name
            money_pledge_date
          }
        }
      }
    }
  }
`;

export const CANCEL_ORGANIZATION_MONEYPLEDGE_MUTATION = gql`
  mutation cancelOrganizationPledgeMutation($pledge: CancelPledgeInput!) {
    organizationCancelPledge(pledge: $pledge) {
      errors
    }
  }
`;
