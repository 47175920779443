import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonTrue from './button-true.component';
import ButtonFalse from './button-false.component';
import Typography from '../../typography.component';
import '../index.scss';

function BooleanButtons({ title, subtitle, value, className, ...props }) {
  return (
    <div className="mb-4">
      {title && (
        <Typography variant="bodyMedium" className={classnames({ 'mb-0': subtitle, 'mb-2': !subtitle }, className)}>
          {title}
        </Typography>
      )}
      {subtitle && <Typography className="mb-2">{subtitle}</Typography>}
      <div className="d-flex align-items-center justify-content-between">
        <ButtonTrue value={value} className="mr-2" {...props} />
        <ButtonFalse value={value} {...props} />
      </div>
    </div>
  );
}

BooleanButtons.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.any,
};
BooleanButtons.defaultProps = {
  square: true,
  className: '',
  fullWidth: true,
};

export default React.memo(BooleanButtons);
