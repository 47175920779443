import gql from 'graphql-tag';
import { upsellFragment } from './upsells.queries';

export const GET_PATHWAYS_QUERY = gql`
  query getPathways(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
          }
        ) {
          current_page
          page_count
          total_count
          results {
            upsell_count
            revv_uid
            id
            name
            pathway_type
            updated_at
            created_at
            creator
            page_count
            step_count
            bill_pay_exists
          }
        }
      }
    }
  }
`;


export const GET_PATHWAY_NAMES_QUERY = gql`
    query getPathwayNames(
        $organizationRevvUid: String
        $page: Int
        $limit: Int
        $dateRangeStart: String
        $dateRangeEnd: String
        $name: String
        $pathwayType: PathwayTypeEnum
    ) {
        viewer {
            candidate(revvUid: $organizationRevvUid) {
                pathways(
                    filters: {
                        page: $page
                        limit: $limit
                        dateRangeStart: $dateRangeStart
                        dateRangeEnd: $dateRangeEnd
                        name: $name
                        pathwayType: $pathwayType
                    }
                ) {
                    current_page
                    page_count
                    total_count
                    results {
                        revv_uid
                        id
                        name
                        pathway_type
                    }
                }
            }
        }
    }
`;




export const conditionFragment = gql`
  fragment PathwayCondition on PathwayCondition {
    revv_uid
    id
    key
    position
    predicate
    value
    created_at
    updated_at
    deleted_at
    condition_type
  }
`;

export const partialConditionFragment = gql`
    fragment PartialPathwayCondition on PathwayCondition {
        revv_uid
        id
        key
        position
        predicate
        value
        condition_type
    }
`;

export const actionFragment = gql`
  fragment Action on PathwayAction {
    revv_uid
    id
    percentage
    position
    value
    created_at
    updated_at
    deleted_at
    statistics {
      dollarPerView
      donationCount
      donationVolume
      stepTaken
    }
  }
`;

export const stepFragment = gql`
  fragment Step on PathwayStep {
    revv_uid
    id
    step_type
    name
    upsell_page_base_id
    upsell_page_revv_uid
    upsell_page_type
    node_type
      upsell_page {
        id
        active
        created_at
        updated_at
        revv_uid
        name
        public_name
        bill_pay_exists
        type
        upsell_preview_url
      }
    pathway_type
    actions {
      ...Action
    }
    conditions {
      ...PathwayCondition
    }
    created_at
    updated_at
    creator
    pathway_count
    statistics {
      dollarPerView
      donationCount
      donationVolume
      stepTaken
    }
  }
  ${conditionFragment}
  ${actionFragment}
`;

export const upsellStepFragment = gql`
  fragment UpsellStep on PathwayStep {
    revv_uid
    id
    step_type
    name
    upsell_page_base_id
    upsell_page_revv_uid
    upsell_page_type
    node_type
    upsell_page {
      ...Upsell
    }
    pathway_type
    conditions {
      ...PathwayCondition
    }
    created_at
    updated_at
    creator
    pathway_count
    statistics {
      dollarPerView
      donationCount
      donationVolume
      stepTaken
    }
  }
  ${conditionFragment}
  ${upsellFragment}
`;

export const partialUpsellStepFragment = gql`
    fragment PartialUpsellStep on PathwayStep {
        revv_uid
        id
        step_type
        name
        upsell_page_base_id
        upsell_page_revv_uid
        upsell_page_type
        node_type
        upsell_page {
            id
            active
            revv_uid
            name
            public_name
            bill_pay_exists
            type
            upsell_preview_url
        }
        pathway_type
        conditions {
            ...PartialPathwayCondition
        }
        pathway_count
    }
    ${partialConditionFragment}
`;

export const upsellPathwayFragment = gql`
    fragment UpsellPathway on Pathway {
        redirect_with_utms
        replace_utm_with_wr_utm
        revv_uid
        id
        name
        updated_at
        created_at
        creator
        page_count
        upsell_count
        base_step_joins {
            true_accepted
            true_conversion_rate
            true_views_count
            false_accepted
            false_conversion_rate
            false_views_count
            pathway_true_next_step_id
            pathway_false_next_step_id
            revv_uid
            position
            step {
                ...UpsellStep
            }
            pathway {
                id
                revv_uid
            }
            id
            created_at
            updated_at
        }
    }
    ${upsellStepFragment}
`;

export const partialUpsellPathwayFragment = gql`
    fragment PartialUpsellPathway on Pathway {
        redirect_with_utms
        replace_utm_with_wr_utm
        revv_uid
        id
        name
        updated_at
        created_at
        creator
        page_count
        upsell_count
        base_step_joins {
            true_accepted
            true_conversion_rate
            true_views_count
            false_accepted
            false_conversion_rate
            false_views_count
            pathway_true_next_step_id
            pathway_false_next_step_id
            revv_uid
            position
            step {
                ...PartialUpsellStep
            }
            pathway {
                id
                revv_uid
            }
            id
            created_at
            updated_at
        }
    }
    ${partialUpsellStepFragment}
`;
export const pathwayFragment = gql`
  fragment Pathway on Pathway {
    revv_uid
    id
    name
    redirect_with_utms
    replace_utm_with_wr_utm
    pathway_type
    upsell_count
    page_count
    base_step_joins {
      true_accepted
      true_conversion_rate
      true_views_count
      false_accepted
      false_conversion_rate
      false_views_count
      pathway_true_next_step_id
      pathway_false_next_step_id
      position
      id
      step {
        ...Step
      }
      pathway {
        id
        revv_uid
        pathway_type
      }
    }
  }
  ${stepFragment}
`;

export const SEARCH_PATHWAYS_QUERY = gql`
  query searchPathways($organizationRevvUid: String, $name: String, $pathwayType: PathwayTypeEnum) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(filters: { name: $name, pathwayType: $pathwayType }) {
          total_count
          results {
            ...Pathway
          }
        }
      }
    }
  }
  ${pathwayFragment}
`;

export const GET_PATHWAY_QUERY = gql`
  query getPathway($organizationRevvUid: String, $revv_uid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(filters: { revvUid: $revv_uid }) {
          results {
            redirect_with_utms
            replace_utm_with_wr_utm
            revv_uid
            id
            name
            updated_at
            created_at
            creator
            page_count
            base_step_joins {
              position
              revv_uid
              step {
                ...Step
              }
              pathway {
                id
                revv_uid
              }
              id
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const GET_UPSELL_PATHWAY_QUERY = gql`
  query getUpsellPathway($organizationRevvUid: String, $revv_uid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(filters: { revvUid: $revv_uid }) {
          results {
            redirect_with_utms
            replace_utm_with_wr_utm
            revv_uid
            id
            name
            page_count
            upsell_count
            base_step_joins {
              pathway_true_next_step_id
              pathway_false_next_step_id
              position
              revv_uid
              step {
                ...PartialUpsellStep
              }
              pathway {
                id
                revv_uid
              }
              id
            }
          }
        }
      }
    }
  }
  ${partialUpsellStepFragment}
`;

export const GET_UPSELL_PATHWAY_STATS_QUERY = gql`
  query getUpsellPathwayStats($organizationRevvUid: String, $revv_uid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            id
            name
            base_step_joins {
              true_accepted
              true_conversion_rate
              true_views_count
              false_accepted
              false_conversion_rate
              false_views_count
              position
              revv_uid
              step {
                revv_uid
                id
                statistics {
                  dollarPerView
                  donationCount
                  donationVolume
                  stepTaken
                }
              }
              pathway {
                id
                revv_uid
              }
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_UPSELL_PATHWAYS_QUERY = gql`
  query getUpsellPathways(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
          }
        ) {
          current_page
          page_count
          total_count
          results {
            redirect_with_utms
            replace_utm_with_wr_utm
            revv_uid
            id
            name
            updated_at
            created_at
            creator
            page_count
            base_step_joins {
              true_accepted
              true_conversion_rate
              true_views_count
              false_accepted
              false_conversion_rate
              false_views_count
              pathway_true_next_step_id
              pathway_false_next_step_id
              position
              revv_uid
              step {
                ...UpsellStep
              }
              pathway {
                id
                revv_uid
              }
              id
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
  ${upsellStepFragment}
`;

export const GET_STEPS_QUERY = gql`
  query getSteps(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $step_type: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
    $nodeType: NodeTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathway_steps(
          filters: {
            page: $page
            limit: $limit
            step_type: $step_type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
            nodeType: $nodeType
          }
        ) {
          total_count
          current_page
          page_count
          results {
            ...Step
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const GET_UPSELL_STEPS_QUERY = gql`
  query getUpsellSteps(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $step_type: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
    $nodeType: NodeTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathway_steps(
          filters: {
            page: $page
            limit: $limit
            step_type: $step_type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
            nodeType: $nodeType
          }
        ) {
          total_count
          current_page
          page_count
          results {
            ...UpsellStep
          }
        }
      }
    }
  }
  ${upsellStepFragment}
`;

export const SEARCH_STEPS_QUERY = gql`
  query searchSteps($organizationRevvUid: String, $name: String, $step_type: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { name: $name, step_type: $step_type }) {
          total_count
          results {
            ...Step
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const CREATE_PATHWAY_MUTATION = gql`
  mutation createPathway($pathway: BasePathwayInput!, $organizationRevvUid: String!) {
    candidateCreateOrUpdatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway {
        revv_uid
        id
        name
        pathway_type
        redirect_with_utms
        replace_utm_with_wr_utm
        base_step_joins {
          position
          id
          step {
            ...Step
          }
          pathway {
            id
            revv_uid
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const CREATE_UPSELL_PATHWAY_MUTATION = gql`
  mutation createUpsellPathway($pathway: BasePathwayInput!, $organizationRevvUid: String!) {
    candidateCreateOrUpdatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway {
        revv_uid
        id
        name
        pathway_type
        redirect_with_utms
        replace_utm_with_wr_utm
        base_step_joins {
          pathway_true_next_step_id
          pathway_false_next_step_id
          position
          id
          step {
            ...PartialUpsellStep
          }
          pathway {
            id
            revv_uid
          }
        }
      }
    }
  }
  ${partialUpsellStepFragment}
`;

export const CREATE_OR_UPDATE_STEP_MUTATION = gql`
  mutation createOrUpdateStep($pathway_step: StepInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdatePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway_step {
        ...Step
      }
    }
  }
  ${stepFragment}
`;

export const GET_AVAILABLE_CONDITIONS_QUERY = gql`
  query getAvailableConditionsQuery($organizationRevvUid: String, $pathwayType: PathwayTypeEnum) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathways_available_conditions(filters: { pathwayType: $pathwayType }) {
          key
          name
          additional_field_values
          additional_field_type
          value
          position
          predicates
          condition_type
        }
      }
    }
  }
`;

export const GET_STEP_INACTIVE_ROUTES = gql`
  query getStepInactiveRoutes(
    $organizationRevvUid: String
    $revv_uid: String
    $action_status: ActionFilterTypeStatusEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            id
            step_type
            name
            actions(filters: { action_status: $action_status }) {
              ...Action
            }
          }
        }
      }
    }
  }
  ${actionFragment}
`;

export const GET_STEPS_INACTIVE_ROUTES = gql`
  query getStepsInactiveRoutes(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $action_status: ActionFilterTypeStatusEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { page: $page, limit: $limit }) {
          results {
            revv_uid
            id
            step_type
            name
            actions(filters: { action_status: $action_status }) {
              id
            }
          }
        }
      }
    }
  }
`;

export const PATHWAY_ARCHIVE_MUTATION = gql`
  mutation archivePathway($organizationRevvUid: String!, $pathway: BasePathwayInput!) {
    candidateArchivePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      message
    }
  }
`;

export const PATHWAY_DUPLICATE_MUTATION = gql`
  mutation duplicatePathway($organizationRevvUid: String!, $pathway: BasePathwayInput!) {
    candidateDuplicatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedPathway {
        ...Pathway
      }
      message
    }
  }
  ${pathwayFragment}
`;

export const PATHWAY_STEP_ARCHIVE_MUTATION = gql`
  mutation archivePathwayStep($organizationRevvUid: String!, $pathway_step: StepInput!) {
    candidateArchivePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      message
    }
  }
`;

export const PATHWAY_STEP_DUPLICATE_MUTATION = gql`
  mutation duplicatePathwayStep($organizationRevvUid: String!, $pathway_step: StepInput!) {
    candidateDuplicatePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedPathwayStep {
        ...Step
      }
      message
    }
  }
  ${stepFragment}
`;
