import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Row, Col } from 'react-bootstrap';

import { Icon, Typography, Card } from 'modules/common';
import '../new-upsell.scss';

function UpsellCard({ title, description, setUpsellComponent, component, disableCard }) {
  return (
    <Col
      sm={12}
      md={6}
      className={classnames('mb-3', { pointer: !disableCard, 'upsell-card-disabled': disableCard })}
      onClick={() => (disableCard ? null : setUpsellComponent(component))}
    >
      <Card className="upsell-card">
        <Card.Body className="d-flex align-items-center">
          <Row className="d-flex align-items-center">
            <Col xs="auto" className="upsell-card-image pr-0">
              <Icon icon={component.icon} color="gray" />
            </Col>
            <Col>
              <Typography variant="h5" className="mb-1">
                {title}
              </Typography>
              <Typography className="mb-0">{description}</Typography>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

UpsellCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setUpsellComponent: PropTypes.func.isRequired,
  component: PropTypes.any.isRequired,
  disableCard: PropTypes.bool,
};

UpsellCard.defaultProps = {
  disableCard: false,
};

export default UpsellCard;
