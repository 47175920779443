import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import {
  FormCardSpaced,
  Card,
  Typography,
  WinRedSelect,
  WinRedInput,
  useOrganization,
  generateIntegrationOptions,
  usePrevious,
} from 'modules/common';
import { emailValidator } from 'core/utilities/formatters';
import {
  GET_CANDIATE_REPORT_INTEGRATIONS,
  GET_ORGANIZATION_REPORT_INTEGRATIONS,
  GET_VENDOR_REPORT_INTEGRATIONS,
} from 'core/middleware/queries';
import { isAgencySelector } from 'core/login';

const recipentEmailValidators = [
  {
    message: 'Recipient Email(s) is required.',
    validator: 'required',
  },
  {
    message: 'Invalid email address.',
    validator: emails => {
      let emailsValid = true;

      // Evaluate each email and stop if one is invalid.
      emails.split(',').some(email => {
        if (!emailValidator.test(email.trim())) emailsValid = false;
        return !emailsValid;
      });

      // Exit if any emails are invalid.
      return emailsValid;
    },
  },
];
const ftpIntegrationValidators = [
  { message: 'FTP Integration is required.', validator: 'required' },
  {
    message: 'FTP Integration must be active',
    validator: option => option.label.includes('Active'),
  },
];
const s3IntegrationValidators = [
  { message: 'S3 Integration is required.', validator: 'required' },
  {
    message: 'S3 Integration must be active',
    validator: option => option.label.includes('Active'),
  },
];

const ACTION_OPTIONS = [
  { label: 'Send by email', value: '' },
  { label: 'FTP', value: 'FTP' },
  { label: 'Amazon S3', value: 'S3' },
];

function ReportAction({ report, onChange }) {
  const [isOrganization, profileCandidate] = useOrganization();

  const isAgency = useSelector(isAgencySelector);

  const prevReport = usePrevious(report);

  const variables = {
    limit: 25,
    organizationRevvUid: profileCandidate.organization_revv_uid,
    page: 1,
    source: isOrganization ? ['ORGANIZATION'] : ['ORGANIZATION', 'CANDIDATE'],
  };

  const query = isAgency
    ? GET_VENDOR_REPORT_INTEGRATIONS
    : isOrganization
    ? GET_ORGANIZATION_REPORT_INTEGRATIONS
    : GET_CANDIATE_REPORT_INTEGRATIONS;

  const { data: s3Integrations } = useQuery(query, {
    variables: {
      ...variables,
      type: 'S3',
    },
    errorPolicy: 'all',
  });
  const { data: ftpIntegrations } = useQuery(query, {
    variables: {
      ...variables,
      type: 'FTP',
    },
    errorPolicy: 'all',
  });

  const selectedIntegrationValue = useMemo(() => {
    if (report?.integration && ftpIntegrations && s3Integrations) {
      const integrationId = report.integrationId || report.integration?.id;
      let integrations = [];

      if (report.integration.type === 'FTP') {
        integrations =
          ftpIntegrations.viewer?.vendor?.integrations.results ||
          ftpIntegrations.viewer?.candidate?.integrations.results ||
          ftpIntegrations.viewer?.state_level_organization?.integrations.results;
      } else {
        integrations =
          s3Integrations.viewer?.vendor?.integrations.results ||
          s3Integrations.viewer?.candidate?.integrations.results ||
          s3Integrations.viewer?.state_level_organization?.integrations.results;
      }

      const selectedIntegration = integrations.find(integration => integration.id === integrationId);

      if (!selectedIntegration) {
        if (integrationId !== '') onChange({ target: { name: 'integrationId', value: '' } });
        return;
      }

      if (selectedIntegration.type === 'FTP')
        return {
          label: `FTP - Address ${selectedIntegration.ftpFields.ftpAddress} - ${
            selectedIntegration.active ? 'Active' : 'Inactive'
          }`,
          value: selectedIntegration.id,
        };

      return {
        label: `S3 - Bucket ${selectedIntegration.s3Fields.bucket} - ${
          selectedIntegration.active ? 'Active' : 'Inactive'
        }`,
        value: selectedIntegration.id,
      };
    }

    return '';
  }, [ftpIntegrations, report.integration, report.integrationId, s3Integrations, onChange]);

  const renderedCardFooter = useMemo(() => {
    if (!report.integration)
      return (
        <WinRedInput
          description="Enter the email address you wish to send this report to. You may also enter a comma separated list of emails. Recipients will need to be logged in to WinRed to download the report."
          name="recipients_string"
          placeholder="Recipient Email(s)"
          type="email"
          validators={recipentEmailValidators}
          value={report?.recipients_string}
          onChange={onChange}
        />
      );

    if (!ftpIntegrations || !s3Integrations) return null;

    return report.integration?.type === 'FTP' ? (
      <WinRedSelect
        description="Add previously created FTP Integration."
        placeholder="Select FTP Integration"
        name="integrationId"
        numberOfDropdownItems={3}
        options={generateIntegrationOptions(ftpIntegrations)}
        validators={ftpIntegrationValidators}
        validateOnMount={prevReport.integration && prevReport.integration?.type !== report.integration?.type}
        value={selectedIntegrationValue}
        onChange={e => onChange({ target: { name: 'integrationId', value: e.target.value } })}
      />
    ) : (
      <WinRedSelect
        description="Add previously created Amazon S3 Integration."
        placeholder="Select Amazon S3 Integration"
        name="integrationId"
        numberOfDropdownItems={3}
        options={generateIntegrationOptions(s3Integrations)}
        validators={s3IntegrationValidators}
        validateOnMount={prevReport.integration && prevReport.integration?.type !== report.integration?.type}
        value={selectedIntegrationValue}
        onChange={e => onChange({ target: { name: 'integrationId', value: e.target.value } })}
      />
    );
  }, [ftpIntegrations, s3Integrations, report, selectedIntegrationValue, onChange, prevReport]);

  return (
    <FormCardSpaced classNameFooter="bg--catskill-white" footer={renderedCardFooter}>
      <Row>
        <Col sm={12} md={8}>
          <Card.Title>Delivery Method</Card.Title>
          <Typography>
            Select how you want your report to be sent. Selecting an FTP or Amazon S3 delivery method will allow you to
            define the specific integration.
          </Typography>
        </Col>
        <Col sm={12} md={4}>
          <WinRedSelect
            name="integration"
            numberOfDropdownItems={3}
            options={ACTION_OPTIONS}
            value={
              report.integration
                ? { label: report.integration.type === 'FTP' ? 'FTP' : 'Amazon S3', value: report.integration?.type }
                : ACTION_OPTIONS[0]
            }
            onChange={e =>
              onChange({
                target: {
                  name: 'integration',
                  value: e.target.value === 'FTP' || e.target.value === 'S3' ? { type: e.target.value } : '',
                },
              })
            }
          />
        </Col>
      </Row>
    </FormCardSpaced>
  );
}

ReportAction.propTypes = {
  report: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportAction };
