import gql from 'graphql-tag';

export const abTestFragment = gql`
  fragment AbTest on AbTest {
    revv_uid
    created_at
    updated_at
    name
    notes
    status
    goal
    leader
    ended_at
    end_reason
    total_number_of_pages
    max_duration
    days_remaining
    confidence_interval_percentage
    improvement_percentage
    control_upsell_group {
      revv_uid
      name
    }
    control_upsells {
      position
      type
    }
    control_dollars_per_session_cents
    control_dollars_per_session_currency
    control_average_contribution_cents
    control_average_contribution_currency
    control_donations_count
    control_sessions_count
    control_raised_cents
    control_raised_currency
    probability_percentage
    min_sessions_for_probability_count
    variant_upsell_group {
      revv_uid
      name
    }
    variant_upsells {
      position
      type
    }
    variant_dollars_per_session_cents
    variant_dollars_per_session_currency
    variant_average_contribution_cents
    variant_average_contribution_currency
    variant_donations_count
    variant_sessions_count
    variant_raised_cents
    variant_raised_currency
  }
`;

export const GET_AB_TESTING_QUERY = gql`
  query getCandidateAbTests(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $status: AbTestStatusTypeEnum
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        ab_tests(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            status: $status
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
          }
        ) {
          page_count
          total_count
          current_page
          results {
            ...AbTest
          }
        }
      }
    }
  }
  ${abTestFragment}
`;

export const CREATE_AB_TEST_MUTATION = gql`
  mutation createCandidateAbTestMutation($ab_test: AbTestInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateAbTest(abTest: $ab_test, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;

export const UDPATE_AB_TEST_MUTATION = gql`
  mutation updateCandidateAbTestMutation($ab_test: AbTestInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateAbTest(abTest: $ab_test, organizationRevvUid: $organizationRevvUid) {
      errors
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;

export const END_AB_TEST_MUTATION = gql`
  mutation endCandidateAbTestMutation($abTestRevvUid: String!, $organizationRevvUid: String) {
    candidateEndAbTest(abTestRevvUid: $abTestRevvUid, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      abTest {
        ...AbTest
      }
    }
  }
  ${abTestFragment}
`;
