import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import Icon from 'modules/common/icon.component';

function ViewMoreDropdown({ onSelect, children, disabled, customIcon, dropdownColorClass, ...rest }) {
  return (
    <Dropdown onSelect={onSelect} {...rest}>
      <Dropdown.Toggle
        className={classNames('custom-dropdown-toggle', {
          'click-disabled': disabled,
          [dropdownColorClass]: dropdownColorClass,
        })}
        disabled={disabled}
      >
        {customIcon ? customIcon : <Icon icon="ellipsis-h" color="gray" />}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>{children}</Dropdown.Menu>
    </Dropdown>
  );
}

ViewMoreDropdown.propTypes = {
  onSelect: PropTypes.func,
  children: PropTypes.node.isRequired,
  customIcon: PropTypes.any,
  dropdownColorClass: PropTypes.string,
};

export default ViewMoreDropdown;
