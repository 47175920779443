export const EMAIL_NAMES_FOR_TYPE = {
  CONDUIT_CANDIDATE_DONATION: 'Donation Receipt',
  CONDUIT_CANDIDATE_RECURRING_DONATION: 'Recurring Donation Receipt',
  UPSELL_DONATION: 'Upsell Donation Receipt',
  ABANDONED_CART: 'Abandoned Cart Notification',
  UPCOMING_DONATION: 'Upcoming Donation Notification',
  MERCHANDISE_ORDER: 'Merchandise Order Receipt',
  UPCOMING_MONEY_BOMB_DONATION: 'Upcoming Money Pledge Notification (3 day)',
  UPCOMING_MONEY_BOMB_DONATION_TOMORROW: 'Upcoming Money Pledge Notification (1 day)',
};

export const ORGANIZATION_EMAIL_NAMES_FOR_TYPE = {
  DONATION: 'Donation Receipt',
  RECURRING_DONATION: 'Recurring Donation Receipt',
  RECURRING_UPSELL_DONATION: 'Recurring Upsell Receipt',
  DOUBLE_UPSELL_DONATION: 'Double Upsell Donation Receipt',
  MERCHANDISE_ORDER: 'Merchandise Order Receipt',
  REFUND_CONFIRMATION: 'Refund Confirmation Notification',
  UPCOMING_DONATION: 'Upcoming Donation Notification',
  UPCOMING_MONEY_BOMB_DONATION: 'Upcoming Money Pledge Notification (3 day)',
  UPCOMING_MONEY_BOMB_DONATION_TOMORROW: 'Upcoming Money Pledge Notification (1 day)',
};

export const EMAIL_FAILED = new RegExp(/(unable to send|does not exist)/);

export const emailBodyEditToolbar =
  'fontsizeselect | bold | italic | underline | strikethrough | alignleft | aligncenter | alignright | forecolor | backcolor | bullist numlist | code | link | image | removeformat';
