import gql from 'graphql-tag';

const VENDOR_CONNECTED_COMMITTEE_FRAGMENT = gql`
  fragment ConnectedCommitteeSettings on Vendor {
    name
    created_at
    revv_uid
    connected_committee_settings {
      receivedConnections {
        name
        revv_uid
        is_revv_share
        default_fee_percentage
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      awaitingApproval {
        name
        revv_uid
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      connected {
        fullAccess {
          id
          defaultFeePercentage
          created_at
          updated_at
          connectedCommittee {
            name
            revv_uid
            avatar {
              image {
                thumbnailUrl
              }
            }
          }
          vendorConnectActivity(filters: { page: $page, limit: $limit }) {
            page_count
            total_count
            current_page
            results {
              id
              activityType
              sourceName
              destinationName
              feeChangedFrom
              feeChangedTo
              date
              email
            }
          }
        }
      }
      disconnected {
        id
        defaultFeePercentage
        created_at
        updated_at
        connectedCommittee {
          name
          revv_uid
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
        vendorConnectActivity(filters: { page: $page, limit: $limit }) {
          page_count
          total_count
          current_page
          results {
            id
            activityType
            sourceName
            destinationName
            feeChangedFrom
            feeChangedTo
            date
            email
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_CONNECTED_COMMITTEES_QUERY = gql`
  query getVendorConnectedCommitteeSettings($vendorRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      vendor(revvUid: $vendorRevvUid) {
        ...ConnectedCommitteeSettings
      }
    }
  }
  ${VENDOR_CONNECTED_COMMITTEE_FRAGMENT}
`;

export const GET_VENDOR_BILL_PAY_QUERY = `
  query getVendorBillPayIdCommittee($vendorRevvUid: String) {
    viewer {
      vendor(revvUid: $vendorRevvUid) {
        bill_pay_id
      }
    }
  }
`;

export const GET_VENDOR_CONNECTED_COMMITTEE_DETAILS_QUERY = gql`
  query getVendorBillPayIdCommittee($vendorRevvUid: String, $billPayId: String!) {
    viewer {
      vendor(revvUid: $vendorRevvUid) {
        bill_pay_id
        committee(billPayId: $billPayId) {
          name
          revv_uid
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

export const VENDOR_CONNECT_TO_COMMITTEE_MUTATION = gql`
  mutation vendorConnectRequestMutation($vendorRevvUid: String!, $vendorConnectRequest: VendorConnectRequestInput!) {
    vendorCreateVendorConnectRequest(vendorConnectRequest: $vendorConnectRequest, vendorRevvUid: $vendorRevvUid) {
      receiver {
        name
        revv_uid
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      errors
    }
  }
`;

export const VENDOR_APPROVE_CONNECT_TO_COMMITTEE_MUTATION = gql`
  mutation vendorAcceptConnectRequestMutation(
    $vendorRevvUid: String!
    $vendorConnectRequest: AcceptVendorConnectRequestInput!
  ) {
    vendorAcceptVendorConnectRequest(vendorConnectRequest: $vendorConnectRequest, vendorRevvUid: $vendorRevvUid) {
      message
      errors
    }
  }
`;

export const VENDOR_EDIT_CONNECTION_MUTATION = gql`
  mutation vendorUpdateBillPayConnectionMutation(
    $billPayConnection: UpdateBillPayConnectionInput!
    $vendorRevvUid: String!
  ) {
    vendorUpdateBillPayConnection(billPayConnection: $billPayConnection, vendorRevvUid: $vendorRevvUid) {
      message
      errors
    }
  }
`;

export const VENDOR_CANCEL_CONNECTION_REQUEST_MUTATION = gql`
  mutation vendorCancelConnectRequestMutation(
    $vendorRevvUid: String!
    $vendorConnectRequest: CancelVendorConnectRequestInput!
  ) {
    vendorCancelVendorConnectRequest(vendorConnectRequest: $vendorConnectRequest, vendorRevvUid: $vendorRevvUid) {
      message
      errors
    }
  }
`;

export const VENDOR_DISCONNECT_FROM_COMMITTEE_REQUEST_MUTATION = gql`
  mutation vendorDisconnectBillPayConnectionMutation(
    $billPayConnection: DisconnectBillPayConnectionInput!
    $vendorRevvUid: String!
  ) {
    vendorDisconnectBillPayConnection(billPayConnection: $billPayConnection, vendorRevvUid: $vendorRevvUid) {
      message
      errors
    }
  }
`;
