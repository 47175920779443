import React from 'react';
import PropTypes from 'prop-types';
import CacheBuster from 'core/CacheBuster';

function CacheContainer({ children, cacheKey }) {
  return (
    <CacheBuster key={cacheKey}>
      {({ loading, shouldForceRefresh, refreshCacheAndReload }) => {
        if (loading) return null;

        if (!loading && shouldForceRefresh) {
          refreshCacheAndReload();
          return null;
        }

        return children;
      }}
    </CacheBuster>
  );
}

CacheContainer.propTypes = {
  cacheKey: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default CacheContainer;
