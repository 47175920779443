import React from 'react';
import { Link } from 'react-router-dom';

function ActivityLink({ url, target, children, isArchived }) {
  if (isArchived) {
    return <span>{children} (archived)</span>;
  }

  if (target === 'blank') {
    return (
      <a href={url} className="text-winred-red underline hover:text-winred-red-light" target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link to={url} className="text-winred-red underline hover:text-winred-red-light">
      {children}
    </Link>
  );
}

export { ActivityLink };
