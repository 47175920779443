import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_UPSELL_BILL_PAY_ACTIVITY, GET_UPSELL_BILL_PAY_ACTIVITY_ORGANIZATION } from './query';
import { generateBillPaySentence } from './utils';
import { LogActivityView } from '../lists/log-activity-view.component';

const BillPaySentenceActivity = ({ revv_uid, profileCandidate }) => {
  const { loading, error, data } = useQuery(
    profileCandidate.isOrganization ? GET_UPSELL_BILL_PAY_ACTIVITY_ORGANIZATION : GET_UPSELL_BILL_PAY_ACTIVITY,
    {
      variables: {
        revv_uid: revv_uid,
        organizationRevvUid: profileCandidate.organization_revv_uid,
      },
      fetchPolicy: 'network-only',
    }
  );

  const candidate = data?.viewer?.candidate || data?.viewer?.state_level_organization;
  const [upsell] = candidate?.upsells?.results ?? [];

  const activity = upsell?.billPayActivity?.results || [];
  const totalCount = upsell?.billPayActivity?.total_count || 0;

  return (
    <LogActivityView
      activity={activity}
      error={error}
      loading={loading}
      totalCount={totalCount}
      genertateSentence={generateBillPaySentence}
    />
  );
};

export { BillPaySentenceActivity };
