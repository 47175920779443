import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const VARIANT_MAP = {
  h1: {
    className: 'winred-h1',
    tag: 'h1',
  },
  h2: {
    className: 'winred-h2',
    tag: 'h2',
  },
  h4: {
    className: 'winred-h4',
    tag: 'h3', // not a typo, semantically correct to avoid skipping header levels for a11y
  },
  h5: {
    className: 'winred-h5',
    tag: 'h4', // not a typo, semantically correct to avoid skipping header levels for a11y
  },
  bodyMedium: {
    className: 'p--medium',
    tag: 'p',
  },
  body: {
    className: 'p',
    tag: 'p',
  },
  error: {
    className: 'p--medium c--winred-red',
    tag: 'p',
  },
  div: {
    className: 'p',
    tag: 'div',
  },
  tableHeader: {
    className: 'winred-table-header',
    tag: 'div',
  },
};

const COLOR_MAP = {
  mirage: 'c--mirage',
  black: 'c--black',
  green: 'c--winred-green',
  red: 'c--winred-red',
  orange: 'c--rajah',
  blue: 'c--winred-blue',
  lynx: 'c--lynx',
  white: 'c--white',
  'dodger-blue': 'c--dodger-blue',
};

const Typography = props => {
  const { children, variant, pro, color, italic, className, noGutter, breakClass, tag, ...restProps } = props;
  const variantData = VARIANT_MAP[variant] || VARIANT_MAP.body;
  const variantClass = variantData.className;
  const Tag = tag ? tag : variantData.tag;
  const breakValue = breakClass ? breakClass : 'break-normal';

  return (
    <Tag
      {...restProps}
      className={classNames(
        breakValue,
        {
          'font-italic': italic,
          [COLOR_MAP[color]]: color,
          pro,
          'mb-0': noGutter,
        },
        variantClass,
        className
      )}
    >
      {children}
    </Tag>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.keys(VARIANT_MAP)),
  pro: PropTypes.bool,
  color: PropTypes.string,
  italic: PropTypes.bool,
  className: PropTypes.string,
  noGutter: PropTypes.bool,
  tag: PropTypes.any,
};

export default Typography;
