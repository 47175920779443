import gql from 'graphql-tag';

export const widgetFragment = gql`
  fragment Widget on Widget {
    id
    type
    shotclockLabel
    shotclockLength
    multiplierFactor
    countdownEnd
    countdownLabel
    facebookHandle
    twitterHandle
    popupBodyCopy
    popupHeaderCopy
    popupButtonCopy
    position
    cookieExpiration
    countdownToMidnightLabel
    donationStreamAnimationDuration
    donationStreamMinimumDonation
    donationStreamMaximumDonation
    donationStreamCustomCopy
    donationStreamDirect
    donationStreamTextColor
    donationStreamBackgroundColor
    donationStreamHighlightColor
    amountButtonAnimationPosition
    amountButtonAnimationColor
    amountButtonAnimationStyle
    amountButtonAnimationDelay
    amountButtonAnimationDuration
    amountButtonAnimationRepeatAfter
    amountButtonAnimationRepeat
    acceptButtonAnimationColor
    acceptButtonAnimationStyle
    acceptButtonAnimationDelay
    acceptButtonAnimationDuration
    acceptButtonAnimationRepeat
    acceptButtonAnimationRepeatAfter
    confettiAnimationStyle
    confettiTriggerElement
    confettiColor
    confettiShape
    confettiUseEmoji
    confettiEmoji
    widgetImage {
      image {
        id
        thumbnailUrl
      }
    }
    pollResultsLabel
    pollResultsPage {
      id
      revv_uid
      created_at
      authorEmail
      publicTitle
      internalName
      slug
      type
      status
      videoPage
      highDollar
      backgroundColor
      backgroundAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
    }
    pollResultsColor
    pollResultsDisplayType
    pollResultsCustomField {
      page_custom_field_id
      name
      label
      default_value
      other_field_enabled
      input_type
    }
    pollResultsOtherFieldResults
    pollResultsStats {
      wholeNumber
      percentage
    }
    pollResultsBoostValues
  }
`;
