import escapeRegExp from 'lodash/escapeRegExp';

import { sortByName } from 'core/utilities';

import {
  PAGES_CHILD_PATHS,
  PEOPLE_CHILD_PATHS,
  REVENUE_CHILD_PATHS,
  ACCOUNTING_CHILD_PATHS,
  UTILITIES_CHILD_PATHS,
  STOREFRONT_CHILD_PATHS,
  BILLPAY_CHILD_PATHS,
} from './menus/menu.tool';

const findMatchingCandidate = (searchRegex, candidate) => {
  const matchingCandidate =
    searchRegex.test(candidate.first_name) || searchRegex.test(candidate.last_name) || searchRegex.test(candidate.name);

  return matchingCandidate;
};

export const getFilteredCandidates = ({ allCandidates = [], searchTerm }) => {
  const _allCandidates = JSON.parse(JSON.stringify(allCandidates));
  let filteredCandidates = searchTerm === '' ? _allCandidates : [];

  /* If the search term isn't blank, filter the candidates based on it. */
  if (searchTerm !== '') {
    const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');

    for (const candidate of _allCandidates) {
      // if simple candidate match then add candidate to list
      const matchingCandidate = findMatchingCandidate(searchRegex, candidate);
      if (matchingCandidate) filteredCandidates.push(candidate);

      const vendorIsMatch = matchingCandidate && candidate?.isAgency;

      // if the vendor is a match then all of it's candidates are a match as well else start empty
      let filteredVendorCandidates = vendorIsMatch ? [...(candidate.allCandidates || [])] : [];

      // only search vendor candidates if the vendor and all its candidates havent been added already
      if (!vendorIsMatch) {
        for (const vendorCandidate of candidate.allCandidates || []) {
          const matchingCandidate = findMatchingCandidate(searchRegex, vendorCandidate);
          if (matchingCandidate) filteredVendorCandidates.push(vendorCandidate);
        }
      }

      // if any vendor candidates matched - add the vendor itself if has not been added already
      candidate.allCandidates = filteredVendorCandidates;
      if (filteredVendorCandidates.length && !matchingCandidate) filteredCandidates.push(candidate);
    }
  }


  // sort candidates anmd agency candidates by name
  filteredCandidates.sort(sortByName);
  filteredCandidates = filteredCandidates.map(candidate => {
    const candidateAllCandidates = [...(candidate.allCandidates || [])];
    candidateAllCandidates.sort(sortByName);
    candidate.allCandidates = candidateAllCandidates;
    return candidate;
  });

  return filteredCandidates;
};

export const logClick = ({ subNav, logEvent, subNavStates }) => {
  if (subNav === 'showRevenueSubNav' && !subNavStates.showRevenueSubNav) {
    logEvent(`navbar click revenue`);
  } else if (subNav === 'showPeopleSubNav' && !subNavStates.showPeopleSubNav) {
    logEvent(`navbar click people`);
  } else if (subNav === 'showPagesSubNav' && !subNavStates.showPagesSubNav) {
    logEvent(`navbar click pages`);
  } else if (subNav === 'showAccountingSubNav' && !subNavStates.showAccountingSubNav) {
    logEvent(`navbar click accounting`);
  } else if (subNav === 'showUtilitiesSubNav' && !subNavStates.showUtilitiesSubNav) {
    logEvent(`navbar click utilities`);
  } else if (subNav === 'showDashboardSubNav' && !subNavStates.showDashboardSubNav) {
    logEvent(`navbar click dashboard`);
  } else if (subNav === 'showStoreFrontSubNav' && !subNavStates.showStoreFrontSubNav) {
    logEvent(`navbar click storefront`);
  }
};

export const getSubnavStates = eventKey => {
  return {
    showPagesSubNav: !!PAGES_CHILD_PATHS.find(path => eventKey.includes(path)),
    showPeopleSubNav: !!PEOPLE_CHILD_PATHS.find(path => eventKey.includes(path)),
    showRevenueSubNav: !!REVENUE_CHILD_PATHS.find(path => eventKey.includes(path)),
    showAccountingSubNav: !!ACCOUNTING_CHILD_PATHS.find(path => eventKey.includes(path)),
    showUtilitiesSubNav: !!UTILITIES_CHILD_PATHS.find(path => eventKey.includes(path)),
    showStoreFrontSubNav: !!STOREFRONT_CHILD_PATHS.find(path => eventKey.includes(path)),
    showBillpaysSubNav: !!BILLPAY_CHILD_PATHS.find(path => eventKey.includes(path)),
  };
};
