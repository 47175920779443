import React from 'react';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';

import { profileCandidateSelector } from 'core/login';
import logo from 'assets/images/winred-logo.svg';
import logoMobile from 'assets/images/winred-shield.svg';

const getUrl = (profileCandidate, isAgency, isReadOnly, isDonorUser, isMyPagesOnly, isMerchOnly) => {
  if (isAgency) {
    return `/${profileCandidate.organization_revv_uid}/vendors/dashboard/`;
  } else if (isReadOnly) {
    return `/${profileCandidate.organization_revv_uid}/people/donors`;
  } else if (isDonorUser) {
    return `/team-pages/pages/`;
  } else if (isMerchOnly) {
    return `/${profileCandidate.organization_revv_uid}/merchandise/products`;
  } else if (isMyPagesOnly) {
    return `/${profileCandidate.organization_revv_uid}/pages/my-pages`;
  } else {
    return `/${profileCandidate.organization_revv_uid}/dashboard/`;
  }
};

export default function NavbarLogo() {
  const profileCandidate = useSelector(profileCandidateSelector);
  const { isDonorUser, isAgency, isReadOnly, isMyPagesOnly, isMerchOnly } = profileCandidate;

  const url = getUrl(profileCandidate, isAgency, isReadOnly, isDonorUser, isMyPagesOnly, isMerchOnly);

  return (
    <Nav.Item className="ml-2">
      <Nav.Link eventKey={url} href={url} className="winred-nav-logo d-flex align-items-center p-0">
        <img src={logo} alt="logo" className="d-none d-xl-block" />
        <img src={logoMobile} alt="logo" className="d-block d-xl-none" />
      </Nav.Link>
    </Nav.Item>
  );
}
