import React from 'react';
import PropTypes from 'prop-types';
import { Input, FloatGroup } from 'modules/common';

function CustomFieldParagraph({ updateCustomField, formState, disabled }) {
  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="label"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Field Label"
          value={formState.label}
          maxLength="255"
          disabled={disabled}
          onChange={updateCustomField}
          data-testid="custom-field-label"
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          as="textarea"
          name="default_value"
          rows="10"
          autoComplete="off"
          autoCorrect="off"
          disabled={disabled}
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Body"
          value={formState.default_value}
          maxLength="255"
          onChange={updateCustomField}
        />
      </FloatGroup>
    </>
  );
}

CustomFieldParagraph.propTypes = {
  formState: PropTypes.object.isRequired,
  updateCustomField: PropTypes.func.isRequired,
};

export default CustomFieldParagraph;
