import gql from 'graphql-tag';
import { fullCampaignFragment } from './page-fragments';

export const GET_ORGANIZATIONS_CAMPAIGNS_QUERY = gql`
  query getOrganizationCampaigns(
    $page: Int
    $limit: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
    $initiativeType: [CampaignTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(
          filters: {
            initiativeType: $initiativeType
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          current_page
          page_count
          total_count
          results {
            initiative_type
            name
            created_at
            revv_uid
            statistics {
              donor_count
              donation_count
              donation_amount
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONS_CAMPAIGNS_NAMES_QUERY = gql`
  query getOrganizationCampaignsNames(
    $page: Int
    $limit: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(
          filters: {
            initiativeType: [STANDARD, MERCHANDISE]
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          current_page
          page_count
          total_count
          results {
            name
            revv_uid
          }
        }
      }
    }
  }
`;
export const GET_ORGANIZATIONS_THERMOMETER_CAMPAIGNS_QUERY = gql`
  query getOrganizationThermometerCampaigns(
    $page: Int
    $limit: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
    $initiativeType: [CampaignTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(
          filters: {
            initiativeType: $initiativeType
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          current_page
          page_count
          total_count
          results {
            initiative_type
            name
            created_at
            revv_uid
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_CAMPAIGN_QUERY = gql`
  query getOrganizationCampaign($organizationRevvUid: String, $revvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        products {
          name
          revv_uid
          price_cents
          variants {
            revv_uid
            name
            variant_price
          }
          variants_count
        }
        campaigns(filters: { revvUid: $revvUid }) {
          results {
            ...FullCampaignFragment
          }
        }
      }
    }
  }
  ${fullCampaignFragment}
`;
export const GET_ORGANIZATION_THERMOMETER_CAMPAIGN_QUERY = gql`
  query getOrganizationCampaign($organizationRevvUid: String, $revvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { revvUid: $revvUid }) {
          results {
            name
            revv_uid
            created_at
            initiative_type
            thermometer_fields {
              boost_goal
              query_date
              page_count
              goals {
                id
                amount_cents
                position
              }
              data_source
            }
            statistics {
              donor_count
              donation_count
              donation_amount
            }
          }
        }
      }
    }
  }
`;

export const GET_NEW_ORGANIZATION_CAMPAIGN_QUERY = gql`
  query getOrganizationNewCampaign($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        products {
          name
          revv_uid
          price_cents
          variants {
            revv_uid
            name
            variant_price
          }
          variants_count
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION_CAMPAIGN_MUTATION = gql`
  mutation creatCampaignMutation($campaign: CreateCampaignInput!) {
    organizationCreateCampaign(campaign: $campaign) {
      errors
      campaign {
        revv_uid
        initiative_type
        name
        created_at
        campaign_segments {
          id
          days_since_last_donation
          donation_count
          donation_count_from
          donation_count_from_days
          show_other_field
          refresh_trigger_count
          refresh_trigger_interval_seconds
          amount_array_type
          amount_array_string
          total_volume
          other_amount
          default_amount_index
          position
          use_control_group
          control_group_percentage
          segment_variant_stats: segment_conversion(control_group: false) {
            views
            donations
            conversion
            raised
            control_group
            __typename
          }
          segment_control_stats: segment_conversion(control_group: true) {
            views
            donations
            conversion
            raised
            control_group
            __typename
          }
        }
      }
    }
  }
`;
export const CREATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION = gql`
  mutation creatCampaignMutation($campaign: CreateCampaignInput!) {
    organizationCreateCampaign(campaign: $campaign) {
      errors
      campaign {
        revv_uid
        initiative_type
        name
        created_at
        thermometer_fields {
          boost_goal
          query_date
          page_count
          goals {
            id
            amount_cents
            position
          }
          data_source
        }
        statistics {
          donor_count
          donation_count
          donation_amount
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_THERMOMETER_CAMPAIGN_MUTATION = gql`
  mutation UpdateOrganizationThermometerCampaignMutation($campaign: UpdateCampaignInput!) {
    organizationUpdateCampaign(campaign: $campaign) {
      errors
      campaign {
        revv_uid
        initiative_type
        name
        created_at
        thermometer_fields {
          boost_goal
          query_date
          page_count
          goals {
            id
            amount_cents
            position
          }
          data_source
        }
        statistics {
          donor_count
          donation_count
          donation_amount
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_CAMPAIGN_MUTATION = gql`
  mutation UpdateOrganizationCampaignMutation($campaign: UpdateCampaignInput!) {
    organizationUpdateCampaign(campaign: $campaign) {
      errors
      campaign {
        ...FullCampaignFragment
      }
    }
  }
  ${fullCampaignFragment}
`;

export const ARCHIVE_ORGANIZATION_CAMPAIGN_MUTATION = gql`
  mutation organizationArchiveCampaign($campaign: ArchiveCampaignInput!) {
    organizationArchiveCampaign(campaign: $campaign) {
      errors
      message
    }
  }
`;
