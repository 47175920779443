import { convertEpochTime } from 'core/utilities';

import { BILL_PAY_ACTIVITY_TYPES } from './config';
import { formatValue, linkBuilder } from './utils';
import { ActivityLink } from './activity-link';

export function ListViewMobile({ items, isSettingsTab, profileCandidate }) {
  return items.map(billPay => {
    const activity = BILL_PAY_ACTIVITY_TYPES[billPay.activityType];
    const link = billPay.objectLink
      ? billPay.objectLink
      : linkBuilder(profileCandidate.organization_revv_uid, billPay.objectRevvUid, billPay.objectType);

    return (
      <div key={billPay.id} className="list-item-card pl-3">
        <div className="font-weight-bold">{activity?.displayName}</div>

        {isSettingsTab && activity.hasLink && (
          <ActivityLink url={link} target={billPay.objectLink ? 'blank' : null} isArchived={billPay.isArchived}>
            {billPay.objectLinkText || `/${billPay.objectRevvUid}`}
          </ActivityLink>
        )}

        <div>Vendor: {billPay.vendorName || '-'}</div>
        <div>Committee: {billPay.organizationName || '-'}</div>
        {billPay.hostOrganizationName && (
          <ul className="pl-4 mb-0 list-disc list-outside">
            <li>{`via ${billPay.hostOrganizationName}`}</li>
          </ul>
        )}
        <div>From: {formatValue(activity?.valueFormat, billPay.changedFrom) || '-'}</div>
        <div>To: {formatValue(activity?.valueFormat, billPay.changedTo) || '-'}</div>
        <div>By: {billPay.email}</div>
        <div>{convertEpochTime(billPay.date)}</div>
      </div>
    );
  });
}
