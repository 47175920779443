import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, SpinnerContainer, useRecursiveNetworkRequest, useToast } from 'modules/common';
import { PageTemplateDrawer } from './page-templates/page-template-drawer.component';
import { DuplicatePageBody } from './duplicate-page-body.component';
import './bulk-page-drawer.scss';

import { useLazyQuery } from '@apollo/client';
import { GET_CANDIDATE_DUPLICATION_TEMPLATES } from 'core/middleware/queries/pages/templates.queries';
import { GET_ORGANIZATION_DUPLICATION_TEMPLATES } from 'core/middleware/queries/pages/templates.organization.queries';
import { formatCustomName, formatCustomSlug, formatPageDuplicateTemplatesForServer } from '../page.tools';
import {
  CANDIDATE_BULK_PAGE_DUPLICATION,
  CANDIDATE_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB,
  ORGANIZATION_BULK_PAGE_DUPLICATION,
  ORGANIZATION_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB,
} from 'core/middleware/queries';
import { ValidationBlock } from 'core/validation';
import { DuplicateResultsBody } from './duplicate-results-body.component';
import { TimeoutScreen } from './timeout-screen.component';
import uuidv4 from 'core/utilities/uuid';

export const BulkPageDrawer = ({ open, onClose, page, profileCandidate, isOrganization }) => {
  const [isRequestTimedOut, setIsRequestTimedOut] = useState(false);
  const [pageDuplicateLoading, setPageDuplicateLoading] = useState(false);
  const [showCompletionDisplay, setShowCompletionDisplay] = useState(false);

  const [pageStatus, setPageStatus] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [templateDrawer, setTemplateDrawer] = useState({
    display: false,
    selectedTemplate: {},
    isEdit: false,
  });
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [duplicationResults, setDuplicationResults] = useState(null);

  const setToast = useToast();

  const bulkPageValidationBlock = createRef();

  const handleOnClose = () => {
    setIsRequestTimedOut(false);
    setShowCompletionDisplay(false);
    setPageStatus(true);
    setSelectedTemplates([]);
    setDuplicationResults(null);
    onClose();
  };

  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);

  const handleClickOutside = event => {
    const clickedBackdrop = open && backdropRef && backdropRef.contains(event.target);

    if (clickedBackdrop && !showCompletionDisplay) {
      handleOnClose();
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  const handleOnTemplateClose = () => {
    setTemplateDrawer({
      display: false,
      selectedTemplate: {},
      isEdit: false,
    });
  };

  const getBulkJobResults = isOrganization
    ? ORGANIZATION_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB
    : CANDIDATE_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB;

  const bulkDuplicatePageRequest = isOrganization
    ? ORGANIZATION_BULK_PAGE_DUPLICATION
    : CANDIDATE_BULK_PAGE_DUPLICATION;

  const onStatusMaxedOut = () => {
    setIsRequestTimedOut(true);
    setPageDuplicateLoading(false);
    setSelectedTemplates([]);
  };

  const onStatusCompletion = results => {
    if (results) {
      let reformattedResults = results?.map(result => {
        return {
          ...result,
          id: uuidv4(),
        };
      });
      setDuplicationResults(reformattedResults);
      setPageDuplicateLoading(false);
      setShowCompletionDisplay(true);
    } else {
      onStatusMaxedOut();
    }
  };

  const requestIntervalTime = useMemo(() => {
    let templatesLength = selectedTemplates.length;
    let intervalTime =
      templatesLength <= 5
        ? 4500
        : templatesLength <= 10
        ? 3000
        : templatesLength <= 25
        ? 2000
        : templatesLength <= 60
        ? 1500
        : 1000;
    return {
      initialWaitTime: intervalTime * templatesLength,
      intervalTime,
    };
  }, [selectedTemplates.length]);

  const { recursiveRequestMutation, mutationError, progressInterval, requestAttempt, clearQueryTimers } =
    useRecursiveNetworkRequest({
      initialMutation: bulkDuplicatePageRequest,
      getResultsQuery: getBulkJobResults,
      initialWaitTime: requestIntervalTime.initialWaitTime,
      intervalTime: requestIntervalTime.intervalTime,
      retryCount: 12,
      retryInterval: 10000,
      mutationFieldName: 'BulkDuplicatePage',
      onComplete: onStatusCompletion,
      onMaxedOut: onStatusMaxedOut,
    });

  const [getTemplates, { loading: getTemplatesLoading, error: getTemplatesError, refetch }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_DUPLICATION_TEMPLATES : GET_CANDIDATE_DUPLICATION_TEMPLATES,
    {
      variables: { organizationRevvUid: profileCandidate.organization_revv_uid },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const candidate = data.viewer.candidate || data.viewer.state_level_organization;
        const templates = candidate.page_duplication_templates;
        setTemplates(
          templates.map(template => {
            return {
              ...template,
              originalName: template.name,
              originalSlug: template.slug,
              name: formatCustomName(page?.internalName, template.name),
              slug: formatCustomSlug(page?.slug, template.slug),
            };
          })
        );
      },
    }
  );

  useEffect(() => {
    if (open) {
      getTemplates();
    }
  }, [open, getTemplates]);

  const handleTemplateSelection = (value, id) => {
    let newTemplateList;
    if (!value) {
      newTemplateList = selectedTemplates.filter(item => item !== id);
    } else {
      newTemplateList = [...selectedTemplates, id];
    }
    setSelectedTemplates(newTemplateList);
  };

  const handleToggleAllTemplates = value => {
    let newTemplatesList;
    if (!value) {
      newTemplatesList = [];
    } else {
      newTemplatesList = templates.map(item => {
        return item.id;
      });
    }
    setSelectedTemplates(newTemplatesList);
  };

  const onCreatePage = async () => {
    if (bulkPageValidationBlock.current.errors()) {
      setToast({
        message: 'Please correct the errors on this page before saving!',
        isError: true,
      });
      return;
    }
    const variables = {
      pageDuplication: {
        revvUid: page.revv_uid,
        duplicationSource: 'BULK_PAGE_CREATOR',
        page_status: pageStatus ? 'LIVE' : 'DRAFT',
        page_duplication_parameters: formatPageDuplicateTemplatesForServer(templates, selectedTemplates),
      },
    };
    setPageDuplicateLoading(true);
    if (isOrganization) {
      variables.pageDuplication.organizationRevvUid = profileCandidate.organization_revv_uid;
    } else {
      variables.organizationRevvUid = profileCandidate.organization_revv_uid;
    }
    try {
      await recursiveRequestMutation({ variables });
      setToast({ message: 'Your Page Duplications are Being Created.' });
    } catch (error) {
      clearQueryTimers();
      setPageDuplicateLoading(false);
      setToast({ message: error.message, isError: true });
      console.error(error);
    }
  };

  const onChangeTemplate = (id, name, value) => {
    let newTemplateList;
    newTemplateList = templates.map(item => {
      if (item.id === id) {
        return { ...item, [name]: value };
      }
      return { ...item };
    });
    setTemplates(newTemplateList);
  };

  const onShowTemplateDrawer = (isEdit, selectedTemplate = {}) => {
    setTemplateDrawer({ display: true, isEdit, selectedTemplate });
  };

  if (getTemplatesError || mutationError) {
    setToast({ message: getTemplatesError || mutationError, isError: true });
  }

  const sharedDrawerProps = {
    profileCandidate,
    isOrganization,
    onClose: templateDrawer.display ? handleOnTemplateClose : handleOnClose,
    page,
  };

  return (
    <>
      <Drawer
        className="bulk-page-drawer"
        setbackdropRef={setbackdropRef}
        open={open}
        footer={
          pageDuplicateLoading ? null : showCompletionDisplay || isRequestTimedOut ? (
            <div className="p-3">
              <Button variant="alt" onClick={handleOnClose}>
                Close
              </Button>
            </div>
          ) : (
            <div className="p-3">
              <Button variant="alt" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                disabled={selectedTemplates.length === 0}
                variant="success"
                className="mx-2"
                onClick={onCreatePage}
              >
                {selectedTemplates.length > 0
                  ? `Create ${selectedTemplates.length} Page${selectedTemplates.length === 1 ? '' : 's'}`
                  : `Create Pages`}
              </Button>
            </div>
          )
        }
      >
        {showCompletionDisplay ? (
          <DuplicateResultsBody {...sharedDrawerProps} results={duplicationResults} />
        ) : isRequestTimedOut ? (
          <TimeoutScreen />
        ) : (
          <ValidationBlock ref={bulkPageValidationBlock}>
            {getTemplatesLoading ? (
              <SpinnerContainer />
            ) : (
              <DuplicatePageBody
                {...sharedDrawerProps}
                items={templates}
                refetch={refetch}
                onCheck={handleTemplateSelection}
                selectedTemplates={selectedTemplates}
                onToggleAll={handleToggleAllTemplates}
                onChange={onChangeTemplate}
                onShowTemplate={onShowTemplateDrawer}
                pageStatus={pageStatus}
                setPageStatus={setPageStatus}
                loading={pageDuplicateLoading}
                progressInterval={progressInterval}
                requestAttempt={requestAttempt}
              />
            )}
          </ValidationBlock>
        )}
      </Drawer>

      <PageTemplateDrawer
        {...sharedDrawerProps}
        open={templateDrawer.display}
        template={templateDrawer}
        setTemplateDrawer={setTemplateDrawer}
        refetch={refetch}
      />
    </>
  );
};

BulkPageDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  page: PropTypes.object,
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
};
