import gql from 'graphql-tag';

export const ORGANIZATION_SETTINGS_OWNERS_MUTATION = gql`
  mutation saveOrganizationOwnersSettings($owners: [AccountOwnerInput!], $organizationRevvUid: String!) {
    updateOrganizationAccountOwners(owners: $owners, organizationRevvUid: $organizationRevvUid) {
      errors {
        revv_uid
        messages
      }
      owners {
        revv_uid
        email
        first_name
        last_name
        title
        phone
        dob_day
        dob_month
        dob_year
        ssn_last_4
        full_ssn_token_provided
        address
        address2
        city
        state
        zip
        stripe_identity_document_front_id
        stripe_identity_document_back_id
        account_opener
        owner
        status
      }
    }
  }
`;
