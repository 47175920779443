import gql from 'graphql-tag';
import { orderFragments } from './orders.queries';

export const GET_ORGANIZATION_ORDERS_REPORT = gql`
  query getOrganizationOrdersReport(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $status: MerchOrderStatusTypeEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        export_orders_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            status: $status
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_ORGANIZATION_ORDERS_QUERY = gql`
  query getOrganizationOrders(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $status: MerchOrderStatusTypeEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        orders(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            status: $status
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          ...Orders
        }
      }
    }
  }
  ${orderFragments.orders}
`;

export const GET_ORGANIZATION_ORDER_QUERY = gql`
  query getOrganizationOrder($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        orders(filters: { revvUid: $revv_uid }) {
          ...Order
        }
      }
    }
  }
  ${orderFragments.order}
`;
