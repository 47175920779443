import { SET_DONORS_FILTER, SET_LEADS_FILTER } from './types';

export const setDonorsFilter = filter => ({
  type: SET_DONORS_FILTER,
  filter,
});

export const setLeadsFilter = filter => ({
  type: SET_LEADS_FILTER,
  filter,
});
