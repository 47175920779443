import React from 'react';
import PropTypes from 'prop-types';

import { SocialMediaInput } from 'modules/common';

function WidgetSocial({ widget, updateWidget, disabled = false }) {
  return (
    <SocialMediaInput
      onChange={updateWidget}
      facebookName="facebookHandle"
      facebookValue={widget.facebookHandle}
      twitterName="twitterHandle"
      disabled={disabled}
      twitterValue={widget.twitterHandle}
      required
    />
  );
}

WidgetSocial.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetSocial;
