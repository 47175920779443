import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FileSelector, Icon, Typography } from 'modules/common';

const MAX_FILE_SIZE = 5000000;

function IdentificationImageUploader({ uploadedImage, onChange, imageKey }) {
  const handleFiles = async acceptedFiles => {
    const files = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
    const file = files[0];

    // if a file the user tried to upload was invalid then we wont have anything here
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      alert(`The file you chose is too large. The maximum size is ${MAX_FILE_SIZE / 1000 / 1000} Mb.`);
      return;
    }

    onChange(imageKey, file);
  };

  return (
    <div className="w-100">
      {uploadedImage ? (
        <Row className="mt-3 shrink-gutters">
          <Col xs={6} sm={4} lg={3}>
            <div className="winred-image-gallery-item d-flex justify-content-center align-items-center selected">
              <img src={uploadedImage.preview} alt={`option`} />
            </div>
          </Col>
        </Row>
      ) : (
        <form encType="multipart/form-data">
          <FileSelector onFiles={handleFiles} maxFileSize={MAX_FILE_SIZE} accept="image/jpeg, image/jpg, image/png">
            <>
              <Icon icon="cloud-upload" size="3x" />
              <Typography noGutter>Drag and drop or browse to upload an image.</Typography>
              <Typography noGutter className="tiny">
                JPEG or PNG only. Maximum size is 5MB.
              </Typography>
            </>
          </FileSelector>
        </form>
      )}
    </div>
  );
}

IdentificationImageUploader.propTypes = {
  uploadedImage: PropTypes.string,
  imageKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IdentificationImageUploader;
