import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Marquee from 'react-double-marquee';
import classNames from 'classnames';
import '../../widgets.scss';
import { SPEED_OPTIONS } from '../../tools';

function WidgetPreviewDonation(props) {
  const { widget } = props;
  let selectedSpeed = SPEED_OPTIONS.find(speed => speed.value === widget.donationStreamAnimationDuration);

  const selectColor = (widgetColor, defaultColor) => {
    return widgetColor === '' ? props[defaultColor] : widgetColor;
  };

  // if a custom speed add to list
  if (!selectedSpeed && widget.donationStreamAnimationDuration) {
    selectedSpeed = {
      label: 'Other',
      speed: 3 / widget.donationStreamAnimationDuration,
    };
  }

  return (
    <Container className="widget-preview_donation">
      <Row className="d-flex justify-content-center">
        <Col xs={12} className="widget-donation">
          <div
            style={{
              backgroundColor: widget.donationStreamBackgroundColor ? widget.donationStreamBackgroundColor : '',
            }}
          >
            <Marquee direction="left" delay={1} speed={selectedSpeed?.speed}>
              <span
                className="widget-donation__money"
                style={{ color: selectColor(widget.donationStreamHighlightColor, 'primaryColor') }}
              >
                $10
              </span>{' '}
              <span
                className="widget-donation__placeholder"
                style={{
                  color: selectColor(widget.donationStreamTextColor, 'secondaryColor'),
                }}
              >
                just donated by John Doe.
                <span className={classNames({ 'widget-donation__custom-copy': widget.donationStreamCustomCopy })}>
                  {widget.donationStreamCustomCopy}
                </span>
              </span>
            </Marquee>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewDonation.propTypes = {
  widget: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default WidgetPreviewDonation;
