import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      error
      token
      user {
        revv_uid
        install_two_factor
      }
    }
  }
`;

export const INSTALL_TWO_FACTOR = gql`
  mutation InstallTwoFactorMutation($installTwoFactorCellphone: String!, $installTwoFactorCountryCode: String!) {
    installTwoFactor(cellphone: $installTwoFactorCellphone, countryCode: $installTwoFactorCountryCode) {
      error
      status
      token
      user {
        revv_uid
        email
      }
    }
  }
`;

export const ENABLE_TWO_FACTOR = gql`
  mutation enableTwoFactor($enableTwoFactorToken: String!) {
    enableTwoFactor(token: $enableTwoFactorToken) {
      error
      token
    }
  }
`;

export const VERIFY_TWO_FACTOR = gql`
  mutation validateTwoFactor($enableTwoFactorToken: String!) {
    validateTwoFactor(token: $enableTwoFactorToken) {
      error
      token
    }
  }
`;

export const RESEND_TWO_FACTOR_CODE = gql`
  mutation {
    resendTwoFactorSms {
      status
      message
    }
  }
`;

export const RESEND_TWO_FACTOR_CODE_VOICE = gql`
  mutation {
    resendTwoFactorVoice {
      status
      message
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logout {
      user {
        email
        first_name
        revv_uid
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($password: String!) {
    changePassword(password: $password) {
      message
    }
  }
`;

/**
 * gets the user's candidates to see which candidate the person is logged in as (if any)
 * this also fetches all the data needed for the sidebar
 * we only want to add the bare minimum data needed herer for all candidates
 * we will fetch the entire candidate currently logged in below in GET_PROFILE_CANDIDATE
 */
export const GET_USER_CANDIDATES = gql`
  query getUserCandidates {
    viewer {
      email
      first_name
      full_name
      revv_uid
      token
      intercom_user_hash
      state_level_organizations {
        revv_uid
        name
        role_type
        avatar {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
      candidates {
        revv_uid
        organization_revv_uid
        role_type
        name
        avatar {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
      vendors {
        revv_uid
        name
        role_type
        is_revv_share
        avatar {
          id
          image {
            id
            thumbnailUrl
          }
        }
        candidates {
          revv_uid
          organization_revv_uid
          name
          role_type
          avatar {
            id
            image {
              id
              thumbnailUrl
            }
          }
        }
        state_level_organizations {
          revv_uid
          name
          role_type
          avatar {
            id
            image {
              id
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

const profileCandidateFragment = gql`
  fragment ProfileCandidate on Candidate {
    revv_uid
    organization_revv_uid
    bill_pay_id
    slug
    name
    created_at
    default_upsell_group_revv_uid
    default_upsell_pathway_revv_uid
    default_upsell_process_type
    default_money_bomb_revv_uid
    default_source_code
    recurring_callout
    enable_campaign_segments_feature
    role_type
    has_active_storefront
    maximum_contribution_cents
    status
    office
    state
    district
    election_year
    default_email_body_text
    org_approved_by_win_red
    timezone
    domain
    organization_subdomains {
      id
      subdomain
      status
    }
    disclaimer
    custom_subdomains_allowed
    long_disclaimer
    allow_transactions_to_be_voided
    ach_payments_feature_enabled
    can_auto_block_donors
    processor
    moov_cvv_optional
    virtual_terminal

    additional_money_bombs_enabled
    enable_business_and_pac_feature
    can_accept_business_donations
    can_accept_pac_donations
    allow_donations_to_process

    ab_tests_feature_enabled
    hds_shipstation_enabled
    enable_donor_cover_fees

    contribution_setting {
      enable_business_donations
      business_donations_by_default
      business_donations_maximum_amount_cents
      enable_pac_donations
      pac_donations_maximum_amount_cents

      enable_pac_donations_fec_id
      enable_business_donations_type_of_business
      enable_business_donations_tax_id
    }

    weekly_recurring_enabled
    connected_vendors {
      name
      revv_uid
    }
    available_vendors {
      id
    }

    merchandise_integration {
      revvUid
      enableHds
      enableAdvoc8
      enableMspDesign
      enableUsCraftBrands
      enableGgOutfitters
      enableAceSpecialties
      defaultFulfillmentHouse
    }

    avatar {
      id
      image {
        id
        thumbnailUrl
      }
    }
    account {
      status
      account_type
    }
    owners {
      status
      full_ssn_token_provided
    }
    bank_accounts {
      status
      revv_uid
    }
    hide_verification_message
    global_custom_fields {
      page_custom_field_id
      global_custom_field_id
      name
      input_type
      position
      form_section
      label
      default_value
      placeholder
      required
      other_field_enabled
    }
    dispute_rates {
      status
      month
      percentage
    }
    is_eligible_for_fines
  }
`;

const profileStateLevelOrganizationFragment = gql`
  fragment ProfileStateLevelOrganization on StateLevelOrganization {
    slug
    bill_pay_id
    default_upsell_process_type
    default_upsell_group_revv_uid
    default_upsell_pathway_revv_uid
    default_money_bomb_revv_uid
    default_source_code
    recurring_callout
    enable_campaign_segments_feature

    name
    first_name
    last_name
    office
    revv_uid
    created_at
    disclaimer
    custom_subdomains_allowed
    maximum_contribution_cents
    state_level_splits_active
    default_email_body_text
    timezone
    long_disclaimer
    enable_sql_transaction_view
    allow_transactions_to_be_voided
    ach_payments_feature_enabled
    can_auto_block_donors
    processor
    moov_cvv_optional
    virtual_terminal
    federal_merchant_account
    federal_merchant_office

    additional_money_bombs_enabled
    enable_business_and_pac_feature
    can_accept_business_donations
    can_accept_pac_donations
    allow_donations_to_process

    ab_tests_feature_enabled
    hds_shipstation_enabled
    enable_donor_cover_fees

    organization_subdomain {
      subdomain
    }
    organization_subdomains {
      id
      subdomain
      status
    }

    connected_vendors {
      name
      revv_uid
    }
    available_vendors {
      id
    }

    contribution_setting {
      enable_business_donations
      business_donations_by_default
      business_donations_maximum_amount_cents
      enable_pac_donations
      pac_donations_maximum_amount_cents

      enable_pac_donations_fec_id
      enable_business_donations_type_of_business
      enable_business_donations_tax_id
    }

    merchandise_integration {
      revvUid
      enableHds
      enableAdvoc8
      enableMspDesign
      enableUsCraftBrands
      enableGgOutfitters
      enableAceSpecialties
      defaultFulfillmentHouse
    }

    weekly_recurring_enabled

    enable_employer_address_fields
    enable_employer_city_state_zip_fields
    enable_employer_name_and_occupation_by_default
    org_approved_by_revv
    role_type
    has_active_storefront
    avatar {
      id
      image {
        id
        thumbnailUrl
      }
    }
    account {
      status
      account_type
      legal_entity
    }
    owners {
      status
      full_ssn_token_provided
    }
    bank_accounts {
      status
      revv_uid
    }
    hide_verification_message
    global_custom_fields {
      page_custom_field_id
      global_custom_field_id
      included_on_pages_by_default
      name
      input_type
      position
      form_section
      label
      default_value
      placeholder
      required
      other_field_enabled
    }
    dispute_rates {
      status
      month
      percentage
    }
    is_eligible_for_fines
  }
`;

/**
 * get logged in profileCandidate. this allows us to get all the data needed for the curent logged in candidate
 * either from login or from switching candidate
 */
export const GET_PROFILE_CANDIDATE = gql`
  query getProfileCandidate($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        ...ProfileCandidate
      }
    }
  }
  ${profileCandidateFragment}
`;

export const GET_PROFILE_ORGANIZATION = gql`
  query getProfileOrganization($organizationRevvUid: String) {
    viewer {
      revv_uid
      state_level_organization(revvUid: $organizationRevvUid) {
        ...ProfileStateLevelOrganization
      }
    }
  }
  ${profileStateLevelOrganizationFragment}
`;

export const GET_PROFILE_VENDOR = gql`
  query getProfileVendor($agencyRevvUid: String) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        revv_uid
        bill_pay_id
        name
        is_revv_share
        role_type
        avatar {
          image {
            id
            thumbnailUrl
          }
        }
        timezone
        processor

        candidates {
          name
          revv_uid
          organization_revv_uid
          timezone
          role_type
        }

        state_level_organizations {
          name
          revv_uid
          timezone
          role_type
        }
        w9_activity_log {
          activity_type
          email
          date
          file_name
        }
      }
    }
  }
`;

export const GET_PROFILE_VENDOR_CANDIDATE = gql`
  query getProfileVendorCandidate($organizationRevvUid: String, $agencyRevvUid: String) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        revv_uid
        candidates(revvUid: $organizationRevvUid) {
          ...ProfileCandidate
        }
      }
    }
  }
  ${profileCandidateFragment}
`;

export const GET_PROFILE_VENDOR_ORGANIZATION = gql`
  query getProfileVendorOrganization($organizationRevvUid: String, $agencyRevvUid: String) {
    viewer {
      vendor(revvUid: $agencyRevvUid) {
        revv_uid
        state_level_organizations(revvUid: $organizationRevvUid) {
          ...ProfileStateLevelOrganization
        }
      }
    }
  }
  ${profileStateLevelOrganizationFragment}
`;

export const GET_CANDIDATE_PAGE_POLL_RESULTS = gql`
  query getCandidateCustomFields($organizationRevvUid: String, $page: Int, $limit: Int, $pageRevvUid: String!) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        page_custom_fields(filters: { page: $page, limit: $limit, pageRevvUid: $pageRevvUid }) {
          page_count
          total_count
          results {
            page_custom_field_id
            name
            input_type
            label
            default_value
            other_field_enabled
            customFieldStats {
              wholeNumber
              percentage
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PAGE_POLL_RESULTS = gql`
  query getOrganizationCustomFields($organizationRevvUid: String, $page: Int, $limit: Int, $pageRevvUid: String!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        page_custom_fields(filters: { page: $page, limit: $limit, pageRevvUid: $pageRevvUid }) {
          page_count
          total_count
          results {
            page_custom_field_id
            name
            input_type
            label
            default_value
            other_field_enabled
            customFieldStats {
              wholeNumber
              percentage
            }
          }
        }
      }
    }
  }
`;
