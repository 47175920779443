import gql from 'graphql-tag';

export const moneyPledgeFragments = {
  moneyPledge: gql`
    fragment MoneyPledge on Campaign {
      initiative_type
      name
      public_name
      created_at
      revv_uid
      money_pledge_date
      email_body_text
      money_pledge_statistics {
        pledgedAmount
        failedAmount
        canceledAmount
        processedAmount
      }
    }
  `,
  moneyPledges: gql`
    fragment MoneyPledges on CampaignResult {
      current_page
      page_count
      total_count
      results {
        initiative_type
        name
        public_name
        created_at
        revv_uid
        statistics {
          donor_count
          donation_count
          donation_amount
        }
        money_pledge_statistics {
          pledgedAmount
          failedAmount
          canceledAmount
          processedAmount
        }
        money_pledge_date
      }
    }
  `,
};

export const GET_MONEYPLEDGES_QUERY = gql`
  query getMoneyPledges(
    $page: Int
    $limit: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $sortOnActionDate: String
    $organizationRevvUid: String!
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        money_pledge_statistics(
          filters: { search: $search, dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd }
        ) {
          totalAmount {
            pledgedAmount
            canceledAmount
            processedAmount
            failedAmount
          }
          conduitedAmount {
            pledgedAmount
            canceledAmount
            processedAmount
            failedAmount
          }
        }
        campaigns(
          filters: {
            initiativeType: [MONEYPLEDGE]
            page: $page
            limit: $limit
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sortOnActionDate: $sortOnActionDate
          }
        ) {
          ...MoneyPledges
        }
      }
    }
  }
  ${moneyPledgeFragments.moneyPledges}
`;

export const GET_MONEYPLEDGE_QUERY = gql`
  query getMoneyPledge($revvUid: String, $organizationRevvUid: String!) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { revvUid: $revvUid }) {
          results {
            ...MoneyPledge
          }
        }
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const CREATE_MONEY_PLEDGE_MUTATION = gql`
  mutation CreateMoneyPledgeCampaignMutation($campaign: CreateCampaignInput!, $organizationRevvUid: String!) {
    candidateCreateCampaign(campaign: $campaign, organizationRevvUid: $organizationRevvUid) {
      errors
      campaign {
        ...MoneyPledge
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const UPDATE_MONEY_PLEDGE_MUTATION = gql`
  mutation UpdateMoneyPledgeCampaignMutation($campaign: UpdateCampaignInput!, $organizationRevvUid: String!) {
    candidateUpdateCampaign(campaign: $campaign, organizationRevvUid: $organizationRevvUid) {
      errors
      campaign {
        ...MoneyPledge
      }
    }
  }
  ${moneyPledgeFragments.moneyPledge}
`;

export const REFRESH_CANDIDATE_MONEYPLEDGE_STATISTICS_MUTATION = gql`
  mutation RefreshMoneyPledgeStatistics($campaign: RefreshCampaignStatisticsInput!, $organizationRevvUid: String!) {
    candidateRefreshCampaignStatistics(campaign: $campaign, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;

export const CANDIDATE_DOWNLOAD_MONEYPLEDGE_PAGE_LINKS_QUERY = gql`
  mutation candidateDownloadMoneyPledgePageLinks(
    $campaign: DownloadCampaignPageLinksInput!
    $organizationRevvUid: String!
  ) {
    candidateDownloadCampaignPageLinks(campaign: $campaign, organizationRevvUid: $organizationRevvUid) {
      message
      downloadUrl
    }
  }
`;

export const GET_CANDIDATE_MONEYPLEDGE_OPTIONS_REPORT = gql`
  query getCandidateMoneyPledgeOptinsReport($revvUid: String, $organizationRevvUid: String!) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_money_pledge_opt_ins(filters: { revvUid: $revvUid })
      }
    }
  }
`;

export const CANDIDATE_BULK_ACTION_UPDATE_PAGES_MUTATION = gql`
  mutation candidateBulkUpdatePages($bulkActions: BulkActionsInput, $organizationRevvUid: String!) {
    candidateBulkUpdatePages(bulkActions: $bulkActions, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      updatedPagesCount
    }
  }
`;

export const GET_MONEYPLEDGE_OPTIONS_QUERY = gql`
  query getMoneyPledgeOptions($search: String, $dateRangeStart: String, $sortOnActionDate: String) {
    viewer {
      candidate {
        campaigns(
          filters: {
            initiativeType: [MONEYPLEDGE]
            limit: 9999
            search: $search
            dateRangeStart: $dateRangeStart
            sortOnActionDate: $sortOnActionDate
          }
        ) {
          results {
            revv_uid
            name
            money_pledge_date
          }
        }
      }
    }
  }
`;

export const CANCEL_MONEYPLEDGE_MUTATION = gql`
  mutation cancelPledgeMutation($pledge: CancelPledgeInput!, $organizationRevvUid: String) {
    candidateCancelPledge(pledge: $pledge, organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
`;
