import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  TableInputContainer,
  TableInput,
  WinRedSelect,
  WinRedInput,
  WinRedDatePicker,
  convertEnumLabel,
} from 'modules/common';

const attributeValidator = [{ message: 'Attribute is required.', validator: 'required' }];
const predicateValidator = [{ message: 'Predicate is required.', validator: 'required' }];
const valueValidator = [{ message: 'Value is required.', validator: 'required' }];

function ReportCondition({ condition, conditions, conditionAttributesOptions, onChange }) {
  const [attributeDetails, setAttributeDetails] = useState(null);

  useEffect(() => {
    if (attributeDetails && condition.attribute_name && condition.attribute_name !== attributeDetails?.value) {
      onChange({ id: condition.id, key: 'value', value: '' });
    }
  });

  const attribute = useMemo(
    () => conditionAttributesOptions.find(o => o.value === condition.attribute_name),
    [conditionAttributesOptions, condition.attribute_name]
  );

  useEffect(() => {
    if (!attribute) return [];

    setAttributeDetails(conditions.find(condition => condition.value === attribute.value));
  }, [attribute, condition.attribute_name, conditions]);

  const conditionPredicateOptions = useMemo(() => {
    if (!attributeDetails) return [];

    return attributeDetails.predicates.map(p => ({ label: p.name, value: p.value }));
  }, [attributeDetails]);

  const predicate = useMemo(
    () => conditionPredicateOptions.find(o => o.value === condition.predicate),
    [condition, conditionPredicateOptions]
  );

  const onRemove = useCallback(
    () => onChange({ id: condition.id, key: 'destroy', value: true }),
    [condition, onChange]
  );

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      onChange({ id: condition.id, key: name, value });
    },
    [condition, onChange]
  );

  const valueInput = useMemo(() => {
    const dataType = attributeDetails?.data_type || '';

    if (condition.predicate === 'present' || condition.predicate === 'blank') return '';

    switch (dataType) {
      case 'date': {
        const dateToUtc =
          typeof condition?.value === 'string' ? new Date(`${condition?.value}T00:00:00`) : condition?.value;

        return (
          <WinRedDatePicker
            placeholder={`${attribute.label} Date`}
            name="value"
            value={condition?.value ? dateToUtc : ''}
            validators={valueValidator}
            onChange={handleChange}
          />
        );
      }

      case 'enum': {
        const value = attributeDetails.value_options.find(o => o.name === condition?.value);

        return (
          <WinRedSelect
            label={`${attribute.label} Value`}
            name="value"
            options={attributeDetails.value_options.map(o => ({ label: convertEnumLabel(o.name), value: o.name }))}
            placeholder="Select Attribute Value..."
            validators={attributeValidator}
            value={value ? { label: convertEnumLabel(value.name), value: value.name } : ''}
            onChange={handleChange}
          />
        );
      }

      default:
        return (
          <WinRedInput
            name="value"
            placeholder="Value"
            validators={valueValidator}
            value={condition?.value || ''}
            onChange={handleChange}
          />
        );
    }
  }, [attribute, attributeDetails, condition, handleChange]);

  return (
    <TableInputContainer onRemove={onRemove}>
      <TableInput>
        <WinRedSelect
          label="Attribute"
          name="attribute_name"
          options={conditionAttributesOptions}
          placeholder="Select Attribute..."
          validators={attributeValidator}
          value={attribute || ''}
          onChange={handleChange}
        />
      </TableInput>
      <TableInput lg={4}>
        <WinRedSelect
          label="Predicate"
          name="predicate"
          options={conditionPredicateOptions}
          placeholder="Select Predicate..."
          validators={predicateValidator}
          value={predicate}
          onChange={handleChange}
        />
      </TableInput>
      <TableInput>{valueInput}</TableInput>
    </TableInputContainer>
  );
}

ReportCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  conditions: PropTypes.array.isRequired,
  conditionAttributesOptions: PropTypes.array.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportCondition };
