import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from 'modules/common';
import { scssColors, convertEpochDate, kFormatter, UI_DATE_FORMAT } from 'core/utilities';

export const xTickFormatter = value => convertEpochDate(value, UI_DATE_FORMAT);
export const yTickFormatter = value => kFormatter(value, true);

export const CustomXAxis = props => {
  const { x, y, payload, index } = props;

  // custom align x axis labels
  const textAnchor = index === 0 ? '' : 'end';
  const _x = index === 0 ? 3 : x;

  return (
    <g transform={`translate(${_x},${y})`}>
      <text x={0} y={0} dy={16} dx={0} textAnchor={textAnchor} fill={scssColors.nepal}>
        {convertEpochDate(payload.value, UI_DATE_FORMAT)}
      </text>
    </g>
  );
};

CustomXAxis.propTypes = {
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payload: PropTypes.object,
  index: PropTypes.number,
};

export const CustomYAxis = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} dx={10} fill={scssColors.nepal}>
        {kFormatter(payload.value, true)}
      </text>
    </g>
  );
};

CustomYAxis.propTypes = {
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payload: PropTypes.object,
};

export const generateCustomToolTip = (xProp, yProp, toolTipFormat) => {
  function CustomToolTip(props) {
    // payload[0] doesn't exist when tooltip isn't visible
    if (!props.payload || props.payload[0] == null) return null;

    const payload = props.payload[0].payload;
    const xAxisValue = xTickFormatter(payload[xProp], toolTipFormat);
    const yAxisValue = yTickFormatter(payload[yProp]);

    return (
      <Card>
        <Card.Body className="d-flex align-items-center p-2 m-0">
          <Typography className="p-0 m-0 mr-2">{xAxisValue}:</Typography>
          <Typography className="p-0 m-0" variant="bodyMedium">
            {yAxisValue}
          </Typography>
        </Card.Body>
      </Card>
    );
  }

  CustomToolTip.propTypes = {
    payload: PropTypes.array.isRequired,
  };

  return CustomToolTip;
};

generateCustomToolTip.propTypes = {
  xProp: PropTypes.string.isRequired,
  yProp: PropTypes.string.isRequired,
};

export const generateCustomGrowthToolTip = (xProp, yProp, yProp2, yDiffProp, toolTipFormat) => {
  function CustomToolTip(props) {
    // payload[0] doesn't exist when tooltip isn't visible
    if (!props.payload || props.payload[0] == null) return null;

    const payload = props.payload[0].payload;
    const xAxisValue = xTickFormatter(payload[xProp], toolTipFormat);
    const yAxisValue = yTickFormatter(payload[yProp]);
    const yAxisValue2 = yTickFormatter(payload[yProp2]);
    const yAxisDiffValue = yTickFormatter(payload[yDiffProp]);

    return (
      <Card>
        <Card.Body className="p-2 m-0">
          <Typography variant="bodyMedium" className="p-0 m-0 mr-2">
            {xAxisValue}
          </Typography>
          <Typography className="p-0 m-0">Subscriptions: {yAxisValue}</Typography>
          <Typography className="p-0 m-0">Subscriptions Canceled: {yAxisValue2}</Typography>
          <Typography className="p-0 m-0">Difference: {yAxisDiffValue}</Typography>
        </Card.Body>
      </Card>
    );
  }

  CustomToolTip.propTypes = {
    payload: PropTypes.array,
  };

  return CustomToolTip;
};

generateCustomGrowthToolTip.propTypes = {
  xProp: PropTypes.string.isRequired,
  yProp: PropTypes.string,
  yProp2: PropTypes.string,
  yDiffProp: PropTypes.string,
};
