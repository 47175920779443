import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import '../table.scss';

function TableRowExpandedMobile({ children }) {
  return <Row className="px-3 pt-3 bg--catskill-white c--black">{children}</Row>;
}

TableRowExpandedMobile.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableRowExpandedMobile;
