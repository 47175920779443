import React from 'react';
import PropTypes from 'prop-types';

import { FormCard, Typography, Checkbox } from 'modules/common';

function CommitteeConnectBillPayAgreement({ billPayAgreement, onChange }) {
  return (
    <FormCard variant={billPayAgreement ? 'green' : 'orange'}>
      <Typography variant="h4" className="font-medium text-winred-blue-charcoal mb-0">
        Bill-Pay Agreement
      </Typography>

      <Typography italic>
        WinRed's "Vendor Connect" is a tool for campaigns and their vendors to facilitate bill payments and data
        transfers automatically. This is a powerful connection that should be used with caution. Fees collected using
        Vendor Connect are NOT WinRed fees and should not be reported as such. By clicking the box below, you
        acknowledge understand the preceding as well as WinRed's Terms of Use.
      </Typography>

      <div className="bg-white border rounded p-3 mb-3">
        <Checkbox
          name="billPayAgreement"
          label="I accept this agreement"
          value={billPayAgreement}
          onChange={onChange}
        />
      </div>
    </FormCard>
  );
}

CommitteeConnectBillPayAgreement.propTypes = {
  billPayAgreement: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { CommitteeConnectBillPayAgreement };
