import React from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Line, XAxis, YAxis, Area, Tooltip, ResponsiveContainer } from 'recharts';

import { scssColors } from 'core/utilities';
import { xTickFormatter, yTickFormatter, CustomXAxis, CustomYAxis, generateCustomToolTip } from './chart.tools';
import './charts.scss';

function WinRedLineChart({ fillLine, data, xProp, yProp, dateRange }) {
  const xAxisTicks = [data[0][xProp], data[data.length - 1][xProp]];
  const ChartType = fillLine ? Area : Line;

  const toolTipFormat = dateRange === 'ALL_TIME' || dateRange === 'YEAR_TO_DATE' ? 'MMMM yyyy' : null;
  const CustomToolTip = generateCustomToolTip(xProp, yProp, toolTipFormat);

  const isOnlyOnePoint = data.length === 1;
  if (isOnlyOnePoint) {
    const data2 = { ...data[0] };
    data2.date--;
    data = [data[0], data2];
  }

  return (
    <ResponsiveContainer aspect={4} debounce={300} className="winred-chart">
      <ComposedChart data={data}>
        <XAxis
          dataKey={xProp}
          tickLine={false}
          axisLine={false}
          interval={isOnlyOnePoint ? 1 : 'preserveStart'}
          ticks={xAxisTicks}
          scale="point"
          tickFormatter={xTickFormatter}
          tick={<CustomXAxis data />}
        />

        <YAxis
          tickLine={false}
          domain={['auto', 'auto']}
          axisLine={false}
          orientation="right"
          tick={<CustomYAxis />}
          tickFormatter={yTickFormatter}
        />

        <Tooltip content={CustomToolTip} />

        <ChartType
          type="monotone"
          dataKey={yProp}
          fill={scssColors['jagged-ice']}
          stroke={scssColors['winred-green']}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

WinRedLineChart.propTypes = {
  fillLine: PropTypes.bool,
  data: PropTypes.array.isRequired,
  xProp: PropTypes.string.isRequired,
  yProp: PropTypes.string.isRequired,
  dateRange: PropTypes.string.isRequired,
};

export default WinRedLineChart;
