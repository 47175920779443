import gql from 'graphql-tag';

export const GET_TEAM_DONATIONS_REPORT = gql`
  query getTeamDonationsReport(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $status: DonationStatusEnum
  ) {
    viewer {
      export_donations_report(
        filters: {
          page: $page
          limit: $limit
          amount: $amount
          amountPredicate: $amountPredicate
          dateRangeStart: $dateRangeStart
          dateRangeEnd: $dateRangeEnd
          search: $search
          status: $status
        }
      )
    }
  }
`;

export const GET_TEAM_DONATIONS_QUERY = gql`
  query getTeamDonations(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $status: DonationStatusEnum
  ) {
    viewer {
      donations(
        filters: {
          page: $page
          limit: $limit
          amount: $amount
          amountPredicate: $amountPredicate
          dateRangeStart: $dateRangeStart
          dateRangeEnd: $dateRangeEnd
          search: $search
          status: $status
        }
      ) {
        current_page
        page_count
        total_count
        gross_amount

        results {
          created_at
          first_name
          last_name
          amount
          status
          source_url
          email
          phone
          source_code
          recurring
          revv_subscription_id
          revv_uid
          refundable
        }
      }
    }
  }
`;
