import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

function RadioButton({ name, label, value, onChange, disabled = null, deselect = false }) {
  const _onChange = useCallback(() => {
    onChange({ target: { name, value: !value } });
  }, [onChange, name, value]);

  return (
    <Form.Check
      type="radio"
      className="pointer"
      disabled={disabled}
      checked={value}
      onChange={_onChange}
      id={name + value}
    >
      <Form.Check.Input
        className="pointer"
        id={name + value}
        disabled={disabled}
        checked={value}
        onChange={_onChange}
        type="radio"
        onClick={() => {
          if (deselect && value) {
            _onChange();
          }
        }}
      />
      <Form.Check.Label className="pointer" id={name + value}>
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RadioButton;
