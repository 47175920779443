import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// https://github.com/revvco/revv-v3/blob/b36ab1306daead39554822269b79fd098f075fd1/app/assets/javascripts/application/app.amount_button_animation.coffee

const ANIMATE_MAP = {
  BOUNCE: 'bounce',
  FLASH: 'flash',
  PULSE: 'pulse',
  RUBBERBAND: 'rubberBand',
  SHAKEX: 'shakeX',
  SHAKEY: 'shakeY',
  HEADSHAKE: 'headShake',
  SWING: 'swing',
  TADA: 'tada',
  WOBBLE: 'wobble',
  JELLO: 'jello',
  HEARTBEAT: 'heartBeat',
};

const ANIMATE_SPEED_MAP = {
  FASTER: 'faster',
  FAST: 'fast',
  SLOW: 'slow',
  SLOWER: 'slower',
};

const ANIMATE_REPEAT_CLASS = 'animate__repeating';

function WinRedAnimate({ children, animation, delay, speed, isRepeating, repeatInterval }) {
  const element = createRef();

  const animationClass = `animate__${ANIMATE_MAP[animation] || ''}`; // what type of animation
  const delayClass = `animate__delay-${delay || 0}s`; // how long to wait til we start animation
  const speedClass = `animate__${ANIMATE_SPEED_MAP[speed] || ''}`; // speed of animation itself
  const repeatClass = isRepeating && repeatInterval === 0 ? `animate__infinite` : ''; // how many times we do animation (either once or infinite)
  const shouldRepeatClass = isRepeating ? ANIMATE_REPEAT_CLASS : '';

  useEffect(() => {
    let timeoutId;
    const animatedElement = element.current;
    if (isRepeating && repeatInterval !== 0 && animatedElement) {
      animatedElement.addEventListener('animationend', () => {
        /* Have to remove the delay class because otherwise it delays every re-render 
        when the animate type class is re-added. */
        animatedElement.classList.remove(delayClass);
        /* Remove the animation type class and then re-add it after the timeout. */
        animatedElement.classList.remove(animationClass);
        timeoutId = setTimeout(() => {
          /* Make sure the animation should still be repeating. We have to use a CSS class here instead 
          of the isRepeating boolean because useEffect is using the previous render's value for
          isRepeating, not the current value. */
          if (animatedElement.classList.contains(ANIMATE_REPEAT_CLASS)) animatedElement.classList.add(animationClass);
        }, parseInt(repeatInterval, 10) * 1000);
      });
      return () => {
        if (animatedElement) animatedElement.removeEventListener('animationend');
        if (timeoutId) clearTimeout(timeoutId);
      };
    }
  }, [element, animationClass, repeatInterval, isRepeating, delayClass]);

  // force react to rerender on any change of props to trigger animation again
  const key = animationClass + delayClass + speedClass + repeatClass + repeatInterval;

  return (
    <div
      ref={element}
      key={key}
      className={classnames(
        'animate__animated',
        animationClass,
        delayClass,
        speedClass,
        repeatClass,
        shouldRepeatClass
      )}
    >
      {children}
    </div>
  );
}

WinRedAnimate.propTypes = {
  children: PropTypes.any.isRequired,
  animation: PropTypes.string.isRequired,
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  speed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRepeating: PropTypes.bool,
};

export default WinRedAnimate;
