import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ListFilter, Drawer, Button, Icon } from 'modules/common';
import { Container, Row, Col, ButtonToolbar } from 'react-bootstrap';
import Typography from '../typography.component';

class FilterDrawer extends Component {
  state = {
    open: false,
    value: '',
    hasFilterErrors: false,
  };

  /**
   * wrapper to close drawer AND submit
   */
  submitFilter = event => {
    this.props.submitFilter(event);
    this.toggleOpen();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.filter !== prevProps.filter) {
      this.setState((prevState) => {
        const hasErrors = this.props.validationBlock?.current?.errors()?.length > 0;
        if (prevState.hasFilterErrors !== hasErrors) {
          return { hasFilterErrors: hasErrors };
        }
        return null;
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  setRef = node => (this.componentRef = node);
  setbackdropRef = node => (this.backdropRef = node);

  toggleOpen = () => this.setState(state => ({ open: !state.open }));

  handleClickOutside = event => {
    const { open } = this.state;

    const clickedBackdrop = open && this.backdropRef && this.backdropRef.contains(event.target);
    if (clickedBackdrop) this.setState({ open: false });

    // only log on outside click when open
    // for some reason react-select clear svg/path elements are
    // considered 'clicked outside' so ignore those types of clicks
    const clickedOutside = open && this.componentRef && !this.componentRef.contains(event.target);
    const clickedClear = event.target.tagName !== 'path' && event.target.tagName !== 'svg';
    if (clickedOutside && clickedClear) this.setState({ open: false });
  };

  render() {
    const {
      filter,
      submitFilter,
      onChangeFilter,
      clearFilter,
      candidate,
      numberOfFilters,
      isOrganization,
      children,
      name,
    } = this.props;

    const { open, value, hasFilterErrors } = this.state;

    return (
      <div ref={this.setRef} className="d-flex">
        <ListFilter
          ref={this.setRef}
          submitFilter={submitFilter}
          clearFilter={clearFilter}
          onChangeFilter={onChangeFilter}
          filter={filter}
          toggleOpen={this.toggleOpen}
          searchPlaceholder={name}
          noDropdown
          noSearch
          noChildProps
          numberOfFilters={numberOfFilters}
        >
          <div className="drawer-filter">
            <Drawer open={open} setbackdropRef={this.setbackdropRef}>
              <Container fluid>
                <Row className="d-flex align-items-center justify-content-between mb-4">
                  <Col xs={12} md={4} className="pointer" onClick={this.toggleOpen}>
                    <Icon icon="times" color="gray" size="2x" />
                  </Col>

                  <Col xs={12} md={4}>
                    <Typography className="mb-0" variant="h2">{`${name} Filters`}</Typography>
                  </Col>

                  <Col xs={12} md={4} className="d-flex align-items-center justify-content-end">
                    <ButtonToolbar>
                      <Button variant="primary" className="btn-md rounded-pill mr-2" onClick={clearFilter}>
                        Reset
                      </Button>
                      <Button
                        disabled={!!hasFilterErrors}
                        variant="primary"
                        className="btn-md rounded-pill"
                        onClick={this.submitFilter}
                      >
                        Apply Filters
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Container>
              {children({ filter, value, onChangeFilter, candidate, isOrganization })}
            </Drawer>
          </div>
        </ListFilter>
      </div>
    );
  }
}

FilterDrawer.propTypes = {
  candidate: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
  numberOfFilters: PropTypes.number.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  validationBlock: PropTypes.object,
};

export default FilterDrawer;
