import {
  SET_BILL_PAY_PAGES_FILTER,
  SET_BILL_PAY_UPSELLS_FILTER,
  SET_DASHBOARD_FILTER,
  SET_TRANSFERS_FILTER,
  SET_CUSTOM_REPORTS_FILTER,
} from './types';

export const setBillpayPagesFilter = filter => ({
  type: SET_BILL_PAY_PAGES_FILTER,
  filter,
});

export const setBillpayUpsellsFilter = filter => ({
  type: SET_BILL_PAY_UPSELLS_FILTER,
  filter,
});

export const setDashboardFilter = filter => ({
  type: SET_DASHBOARD_FILTER,
  filter,
});

export const setTransfersFilter = filter => ({
  type: SET_TRANSFERS_FILTER,
  filter,
});

export const setCustomReportsFilter = filter => ({
  type: SET_CUSTOM_REPORTS_FILTER,
  filter,
});
