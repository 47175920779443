import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { setToast } from 'core/toast';
import Generate from './generate.component';
import { CommitteeUpsell, MoneyPledgeUpsell, PanelUpsell } from '../drawer/new-upsell';
import { SEARCH_ORGANIZATION_UPSELLS_QUERY, SEARCH_UPSELLS_QUERY } from '../../../../core/middleware/queries';
import { isOrganizationSelector } from '../../../../core/login';
import { formatLoadedUpsellOptions } from '../upsells.tools';

const searchText = 'Add Existing Upsell';
const searchSubText = 'Search for previously created upsell by name below.';
const searchPlaceholder = 'Search Upsells';

const removeButton = 'Remove Upsell';
const removeText = 'Are you sure you want to remove this upsell from your upsell flow?';
const removeTitle = 'Remove Upsell';
const removeButtonText = 'Remove Upsell';

const createText = 'Create New Upsell';
const createSubText = 'Create a new upsell to add to your upsell flow.';

const addText = 'Add Existing Upsell';
const addSubText = "Add an upsell you've already created to your upsell flow.";

/**
 * UI for adding an existing upsell or creating a new one
 */
function GenerateUpsell({
  upsellDropdownOptions,
  onSelectNewUpsell,
  onSelectExistingUpsell,
  editedUpsellGroup,
  upsells = [],
  disabled,
}) {
  const isOrganization = useSelector(isOrganizationSelector);
  // remove upsells already selected or destroyed (remove all upsells that are actually shown in the ui list)
  // also remove panel upsells if a panel upsell already exists
  const doesPanelUpsellExist = !!(upsells || []).find(
    upsell =>
      upsell.upsell_page.type === PanelUpsell.type ||
      (upsell.upsell_page.type === CommitteeUpsell.type && !upsell._destroy)
  );

  const selectedUpsellIds = (editedUpsellGroup.upsell_pages || [])
    .filter(upsell => !upsell._destroy)
    .map(upsell => upsell.upsell_page.revv_uid);

  const filteredUpsellDropdownOptions = (upsellDropdownOptions || []).filter(upsell => {
    if ((doesPanelUpsellExist && upsell.value.type === PanelUpsell.type) || upsell.value.type === CommitteeUpsell.type)
      return false;

    if (
      upsell.value.type === MoneyPledgeUpsell.type &&
      upsell.value.money_pledge_campaign?.money_pledge_date < Date.now()
    )
      return false;
    return !selectedUpsellIds.includes(upsell.value.revv_uid);
  });

  const GET_UPSELLS_QUERY_SEARCH = isOrganization ? SEARCH_ORGANIZATION_UPSELLS_QUERY : SEARCH_UPSELLS_QUERY;

  return (
    <Generate
      onSelectNewUpsell={onSelectNewUpsell}
      onSelectExistingUpsell={onSelectExistingUpsell}
      upsellDropdownOptions={filteredUpsellDropdownOptions}
      searchText={searchText}
      searchSubText={searchSubText}
      searchPlaceholder={searchPlaceholder}
      removeText={removeText}
      removeTitle={removeTitle}
      removeButtonText={removeButtonText}
      removeButton={removeButton}
      createText={createText}
      createSubText={createSubText}
      addText={addText}
      addSubText={addSubText}
      disabled={disabled}
      searchQuery={GET_UPSELLS_QUERY_SEARCH}
      formatLoadedOptions={formatLoadedUpsellOptions}
    />
  );
}

GenerateUpsell.propTypes = {
  onSelectNewUpsell: PropTypes.func.isRequired,
  onSelectExistingUpsell: PropTypes.func.isRequired,
  isPartOfPage: PropTypes.bool,
  upsellDropdownOptions: PropTypes.array,
  upsells: PropTypes.array,
  editedUpsellGroup: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setToast: message => dispatch(setToast(message)),
});

export default connect(null, mapDispatchToProps)(GenerateUpsell);
