import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import { EmptyList, Pagination } from 'modules/common';
import ListBodyDesktop from './list-body/desktop.component';
import ListBodyMobile from './list-body/mobile.component';

function TeamDonationsListBody({ history, match, createNewTeamPage, donations, onChangeFilter }) {
  const { current_page, page_count, total_count, results } = donations;
  const navigateToItem = useCallback(uuid => history.push(`${match.url}/${uuid}`), [history, match]);

  if (!results || results.length === 0) {
    return (
      <EmptyList
        text="No Donations"
        description="Donations made through your team pages will appear here. Create a team page to start raising money for your favorite cause!"
        icon="donate"
        buttonText="Create Team Page"
        name="createPage"
        onClick={createNewTeamPage}
      />
    );
  }

  return (
    <>
      <Breakpoint small down>
        <ListBodyMobile items={results} navigateToItem={navigateToItem} />
      </Breakpoint>
      <Breakpoint medium up>
        <ListBodyDesktop items={results} navigateToItem={navigateToItem} />
      </Breakpoint>

      <Pagination currentPage={current_page} pageCount={page_count} totalCount={total_count} setPage={onChangeFilter} />
    </>
  );
}

TeamDonationsListBody.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  createNewTeamPage: PropTypes.func.isRequired,
  donations: PropTypes.object.isRequired,
};

export default withRouter(TeamDonationsListBody);
