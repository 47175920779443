import { convertEpochTime } from 'core/utilities';
import { formatCustomFieldsForServer, formatAmounts, formatMoneyPledgeForServer } from 'modules/common';

import { convertReccuringForServer } from './recurring.tools';
import {
  updateImagesForServer,
  formatConduitsForServer,
  updateTypeForServer,
  pageUpsellFlowEventParams,
  formatConfirmationPage,
  formatPageMinMaxAmounts,
  removeDestroyedImagesForServer,
} from './page.tools';
import { formatWidgetsAndThermometersForServer } from 'modules/common/widgets/tools';
import { formatUpsellGroupForServer } from 'modules/pages/upsells/tools';
import { formatPathwayForServer } from '../pathways/pathways.tools';
import { formatUpsellPathwayForServer, PATHWAY_TYPES } from '../upsell-pathways/react-flow.tools';

export const NEW_PAGE_TO_SERVER_REMOVED_FIELDS = [
  'doubleUpsellOptIn',
  'gallery',
  'shareDonorDetails',
  'recurringUpsellOptIn',
  'smsUpsellOptIn',
  'confirmationPreviewPageUrl',

  // temp internal props we dont need
  '_enable_business_donations',
  '_enable_pac_donations',
  '_conduitsHaveVendors',
  '_',
  '_enableEmployerAddressDetails',
  '_enableEmployerCityStateZip',
];

export const formatNewPageForServer = ({ page, isPetitionPage, profileCandidate }) => {
  page = JSON.parse(JSON.stringify(page));

  /* Remove variables state used locally that shouldn't be passed to the API */
  NEW_PAGE_TO_SERVER_REMOVED_FIELDS.forEach(k => delete page[k]);

  // make sure all amounts have a value
  if (page.pageAmounts.length) page.pageAmounts = page.pageAmounts.filter(donationAmount => donationAmount.amount);

  page = updateTypeForServer(page);
  page = convertReccuringForServer(page);
  page = updateImagesForServer(page);
  page = removeDestroyedImagesForServer(page);
  page.pageAmounts = formatAmounts(page.pageAmounts);
  page = formatConduitsForServer({ item: page });
  page.customFields = formatCustomFieldsForServer(page.customFields);

  if (isPetitionPage) {
    delete page.upsellPageGroup;
  } else if (page.upsellPageGroup?._isNew || page.upsellPageGroup?.revv_uid) {
    page = formatUpsellGroupForServer(page);
  }

  // if after formatting we still have the empty shell (DEFAULT_UPSELL_GROUP var) then the user never created a flow for this page so remove it
  if (!page.upsellPageGroup?.revvUid && !page.upsellPageGroup?.name) delete page.upsellPageGroup;

  page = formatMoneyPledgeForServer(page);

  page.initiativeCampaignRevvUid = page.campaign?.revv_uid;
  delete page.campaign;
  delete page.campaignsTotalCount;

  page = formatWidgetsAndThermometersForServer(page);
  page = formatConfirmationPage(page);
  page = formatPageMinMaxAmounts(page);

  if (page.additionalMoneyPledgeCampaign) {
    page.additionalMoneyPledgeCampaigns = [
      { moneyPledgeCampaignRevvUid: page.additionalMoneyPledgeCampaign.revv_uid, position: 1 },
    ];
  }
  delete page.additionalMoneyPledgeCampaign;

  //make sure that the default value for enableMobileField is true
  if (page.enableMobileField === undefined || page.enableMobileField === null) {
    page.enableMobileField = true;
  }

  if (page.pathway) {
    if (page.type === PATHWAY_TYPES.PETITION || page.pathway.pathway_type === PATHWAY_TYPES.PETITION) {
      page.pathway = formatPathwayForServer(page.pathway);
    } else {
      page.pathway = formatUpsellPathwayForServer(page.pathway, profileCandidate);
    }
  }

  if (page.pathway_steps) {
    delete page.pathway_steps;
  }
  if (page.upsellProcessType) {
    delete page.upsellProcessType;
  }

  return page;
};

export const OG_TITLE = `Donate to my WinRed Page!`;
export const OG_DESCRIPTION = `I'm raising money for my favorite causes on WinRed. Donate to my WinRed Team page today!`;

export const getSaveNewPageEventData = (page, isOrganization, isPetitionPage) => {
  const eventParams = {
    'page id': page.revv_uid,
    status: page.status,
    type: page.type,
    'created date': convertEpochTime(page.created_at),
    'internal name': page.internalName,
    slug: page.slug,
    title: page.publicTitle,
    'recurring upsell id': (page.recurringUpsell && page.recurringUpsell.revv_uid) || null,
    'double upsell id': (page.doubleUpsell && page.doubleUpsell.revv_uid) || null,
    layout: page.layout,
    position: page.position,
    'logo id': (page.logoAssignedImage && page.logoAssignedImage.image && page.logoAssignedImage.image.id) || null,
    'mobile id': page?.mobileBannerAssignedImage?.image?.id || null,
    'background image id':
      (page.backgroundAssignedImage && page.backgroundAssignedImage.image && page.backgroundAssignedImage.image.id) ||
      null,
    amounts: page.amounts ? page.pageAmounts.map(amountObject => amountObject.amount.toString()) : null,
    'mobile field enabled': page.enableMobileField,
    'mobile field required': page.requireMobileField,
    'mobile opt-in enabled': page.enableSmsOptin,
    'shipping information': page.enableShippingDetails,
    'recurring button enabled': page.recurringAvailable,
    'donor choose interval enabled': page.recurringIntervalSelectable,
    'default value': page.recurringDefaultValue,
    'recurring interval': page.recurringInterval,
    'recurring end date': page.recurringEndAt,
    campaign: page.campaign && page.campaign.revv_uid,
    'confirmation custom button enabled': page.confirmationCustomLink ? true : false,
    'facebook slug': page.confirmationFacebookHandle,
    'twitter handle': page.confirmationTwitterHandle,
    password: null,
    'password exact match': page.passwordExactMatch,
    'password callout': page.passwordCallout,
    language: page.locale,
    'candidate count': (page.conduitPageCandidates || []).length,
    candidates: (page.conduitPageCandidates || []).map(candidate => candidate.name).join(', '),
  };

  return pageUpsellFlowEventParams(page, eventParams, isOrganization, isPetitionPage);
};
