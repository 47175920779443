import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuMerchandise({
  pathname,
  handleSelect,
  organizationRevvUid,
  removeAccess,
  showStoreFrontSubNav,
  approved,
}) {
  if (!approved || removeAccess.includes(true)) return null;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for merchandise menu"
      showSubmenu={showStoreFrontSubNav}
    >
      <NavList.Item
        eventKey={`/${organizationRevvUid}/merchandise/products`}
        href={`/${organizationRevvUid}/merchandise/products`}
      >
        Products
      </NavList.Item>
      <NavList.Item
        eventKey={`/${organizationRevvUid}/merchandise/orders`}
        href={`/${organizationRevvUid}/merchandise/orders`}
      >
        Orders
      </NavList.Item>
      <NavList.Item
        eventKey={`/${organizationRevvUid}/merchandise/settings`}
        href={`/${organizationRevvUid}/merchandise/settings`}
      >
        Storefront Settings
      </NavList.Item>
    </SubMenuContainer>
  );
}

NavBarSubMenuMerchandise.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showStoreFrontSubNav: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
};

export default NavBarSubMenuMerchandise;
