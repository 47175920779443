import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Input } from 'modules/common';
import { noop } from 'core/utilities';
import { getCandidateLabel } from '../page-common.tools';

export function CommitteeCardDetails({
  conduitCandidate,
  isTeamPages = false,
  shouldShowPercentage = false,
  isBillPay = false,
}) {
  const candidate = conduitCandidate.candidate || conduitCandidate.organization;

  // all vendors fees for a candidate must sum to less than 100%
  const vendorFeeSumValidation = () => {
    if (!conduitCandidate.vendorFees) return true;
    const sumOfVendorFees = conduitCandidate.vendorFees.reduce((sum, curr) => {
      if (curr._destroy) return sum;
      return parseFloat(curr.feePercentage || 0) + sum;
    }, 0);

    return isNaN(sumOfVendorFees) || sumOfVendorFees < 100;
  };

  const name =
    candidate.first_name && candidate.last_name ? `${candidate.first_name} ${candidate.last_name}` : candidate.name;

  if (isTeamPages) return <h5 className="m-0">{name}</h5>;

  const type = conduitCandidate._isStateLevel ? 'Merchant' : 'Conduit';
  return (
    <>
      <Typography variant="bodyMedium" className="mb-0">
        {name}
      </Typography>
      <Typography className="mb-0">
        {`${type}${getCandidateLabel(candidate, false)}`}
        {shouldShowPercentage ? ` - ${conduitCandidate.defaultPercentage ?? 0}%` : ''}
      </Typography>
      {isBillPay
        ? conduitCandidate.originalVendorFees?.map(fee => (
            <Typography key={fee.name} className="mb-0">
              {fee.name} - {fee.feePercentage}%
            </Typography>
          ))
        : null}

      <Input
        hidden
        value={''}
        onChange={noop}
        validators={[vendorFeeSumValidation]}
        errorMessages={['The sum of all vendor fees cannot be more than or equal to 100%.']}
      />
    </>
  );
}

CommitteeCardDetails.propTypes = {
  conduitCandidate: PropTypes.object.isRequired,
  isTeamPages: PropTypes.bool,
  shouldShowPercentage: PropTypes.bool,
  isBillPay: PropTypes.bool,
};
