import gql from 'graphql-tag';

export const GET_TRANSACTIONS_QUERY = gql`
  query getTransactions(
    $limit: Int
    $page: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $fee: Float
    $feePredicate: RansackPredicate
    $gross: Float
    $grossPredicate: RansackPredicate
    $net: Float
    $netPredicate: RansackPredicate
    $transactionType: [ConduitCandidateTransactionTypeEnum!]
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        transactions(
          filters: {
            limit: $limit
            page: $page
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            fee: $fee
            feePredicate: $feePredicate
            gross: $gross
            grossPredicate: $grossPredicate
            net: $net
            netPredicate: $netPredicate
            transactionType: $transactionType
          }
        ) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            charge_revv_uid
            transaction_type
            code_name
            name
            business_name
            pac_name
            contributor_type
            gross_cents
            fee_cents
            net_cents
            balance_cents
            payout {
              revvUid
              status
              reportUrl
            }
          }
        }

        refund_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            fee: $fee
            feePredicate: $feePredicate
            gross: $gross
            grossPredicate: $grossPredicate
            net: $net
            netPredicate: $netPredicate
            limit: 99999
            trendType: REFUND
          }
        ) {
          gross_amount
          count
        }
        dispute_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            fee: $fee
            feePredicate: $feePredicate
            gross: $gross
            grossPredicate: $grossPredicate
            net: $net
            netPredicate: $netPredicate
            limit: 99999
            trendType: DISPUTE
          }
        ) {
          gross_amount
          count
        }
        donations_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            fee: $fee
            feePredicate: $feePredicate
            gross: $gross
            grossPredicate: $grossPredicate
            net: $net
            netPredicate: $netPredicate
            limit: 99999
          }
        ) {
          gross_amount
          count
        }

        payouts_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            fee: $fee
            feePredicate: $feePredicate
            gross: $gross
            grossPredicate: $grossPredicate
            net: $net
            netPredicate: $netPredicate
            limit: 99999
            trendType: PAYOUT
          }
        ) {
          net_cents
          count
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_REPORT = gql`
  query getTranactionsReport(
    $amount: Float
    $amountPredicate: Predicate
    $limit: Int
    $page: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $status: ConduitCandidateTransactionStatusEnum
    $transactionType: [ConduitCandidateTransactionTypeEnum!]
    $payoutRevvUid: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_transactions_report(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            limit: $limit
            page: $page
            search: $search
            status: $status
            transactionType: $transactionType
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            payoutRevvUid: $payoutRevvUid
          }
        )
      }
    }
  }
`;
