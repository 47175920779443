import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Icon, Typography, useAmplitude } from 'modules/common';
import { profileSelector } from 'core/login';

export default function UserMenu() {
  const [show, setShow] = useState(false);
  const profile = useSelector(profileSelector);

  const [logEvent] = useAmplitude();
  if (!profile) return null;

  const onToggleDropdown = isOpened => {
    logEvent(`navbar ${isOpened ? 'open' : 'close'} user dropdown`);
    setShow(isOpened);
  };

  const onClickAccount = () => {
    window.open(process.env.REACT_APP_ACCOUNT_URL, '_blank');
    logEvent(`navbar click my account`);
    setShow(false);
  };

  return (
    <NavDropdown
      alignRight
      onToggle={onToggleDropdown}
      show={show}
      aria-expanded={show}
      className="winred-nav__menu-nav-item dropdown-nav-item"
      title={
        <div className="user-icon-container ml-0">
          <Icon icon="user" />
        </div>
      }
    >
      <NavDropdown.Item disabled aria-disabled className="pl-2 pt-2 d-flex align-items-center">
        <div className="user-icon-container ml-0">
          <Icon icon="user" />
        </div>
        <div className="user-info-container  mt-2 mt-lg-0">
          <Typography variant="bodyMedium" className="mb-0">
            {profile?.first_name ?? ''}
          </Typography>
          <Typography className="recording-sensitive mb-0">{profile?.email ?? ''}</Typography>
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item onClick={onClickAccount}>
        <Icon icon="cogs" className="icon" /> My Account
      </NavDropdown.Item>
      <NavDropdown.Item eventKey="/logout">
        <Icon icon="sign-out" className="icon" /> Sign Out
      </NavDropdown.Item>
    </NavDropdown>
  );
}
