import gql from 'graphql-tag';

export const integrationsFragment = gql`
  fragment Integrations on Integration {
    createdAt
    createdBy
    type
    note
    name
    source
    active
    revvUid
    summaryFields {
      webhookType
    }
  }
`;

export const GET_INTEGRATIONS_QUERY = gql`
  query getIntegrations(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $active: Boolean
    $type: IntegrationTypeEnum
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $source: [IntegrationSourceTypeEnum!]
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        active_integration_types
        existing_integration_types
        integrations(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            active: $active
            type: $type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            source: $source
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...Integrations
          }
        }
      }
    }
  }
  ${integrationsFragment}
`;

export const GET_ORGANIZATION_INTEGRATIONS_QUERY = gql`
  query getOrganizationIntegrations(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $active: Boolean
    $type: IntegrationTypeEnum
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $source: [IntegrationSourceTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        active_integration_types
        existing_integration_types
        integrations(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            active: $active
            type: $type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            source: $source
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...Integrations
          }
        }
      }
    }
  }
  ${integrationsFragment}
`;

export const GET_VENDOR_INTEGRATIONS_QUERY = gql`
  query getVendorIntegrations(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $revvUid: String
    $active: Boolean
    $type: IntegrationTypeEnum
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        active_integration_types
        integrations(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            active: $active
            type: $type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
          }
        ) {
          current_page
          page_count
          total_count
          results {
            ...Integrations
          }
        }
      }
    }
  }
  ${integrationsFragment}
`;
