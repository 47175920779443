import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import '../../widgets.scss';

function WidgetPreviewSocial({ widget }) {
  return (
    <Container className="widget-preview_social">
      <Row className="d-flex justify-content-center">
        <Col xs={12}>
          <div className="social-link facebook-link">
            <a href={` https://www.facebook.com/${widget.facebookHandle}`} target="_blank" rel="noopener noreferrer">
              Like us on Facebook
            </a>
          </div>
          <div className="social-link twitter-link">
            <a href={`https://twitter.com/${widget.twitterHandle}`} target="_blank" rel="noopener noreferrer">
              Follow us on Twitter
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewSocial.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default WidgetPreviewSocial;
