import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, FloatGroup, Input } from 'modules/common';
import { getWidgetCountdownMinMaxDates } from 'core/utilities';

const MAX_DAYS = 100;

function WidgetCountdown({ widget, updateWidget, disabled = false }) {
  const { minDate, maxDate } = getWidgetCountdownMinMaxDates(MAX_DAYS);

  return (
    <>
      <FloatGroup className="label-datepicker">
        <DatePicker
          fullWidth
          placeholder="Countdown End Time"
          name="countdownEnd"
          value={widget.countdownEnd}
          disabled={disabled}
          onChange={updateWidget}
          minDate={minDate}
          maxDate={maxDate}
          validators={['required']}
          description={`Select a date a maximum of ${MAX_DAYS} days from today.`}
          errorMessages={['A Countdown End Time is required.']}
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          name="countdownLabel"
          placeholder="Countdown Label"
          value={widget.countdownLabel}
          disabled={disabled}
          maxLength="54"
          onChange={updateWidget}
          description="Add a donor-facing callout above your countdown."
        />
      </FloatGroup>
    </>
  );
}

WidgetCountdown.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetCountdown;
