import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EditableList, FormCardSpaced } from 'modules/common';
import './donation-amounts.scss';

const ERROR_MESSAGE = 'At least one donation amount is required.';
function DonationAmounts({
  eventName,
  amounts,
  updateAmounts,
  newDonation,
  children,
  maxNumberOfItems,
  subtitle,
  noCard,
  disabled,
  isMultiplier,
  headerAction,
  additionalField,
  footer,
  classNameFooter,
}) {
  const CardWrapper = noCard ? 'div' : FormCardSpaced;

  const cardWrapperProps = useMemo(() => {
    let cardProps = {
      footer: footer,
      className: classNames('donation-amounts', { 'mb-3': !noCard }),
      title: <div className="donation-amounts-header">{headerAction}</div>,
      subtitle: subtitle,
    };
    if (!noCard) {
      cardProps.classNameFooter = classNameFooter;
    }
    return cardProps;
  }, [classNameFooter, footer, headerAction, noCard, subtitle]);

  return (
    <CardWrapper {...cardWrapperProps}>
      {noCard && subtitle}
      <EditableList
        items={amounts}
        onChange={updateAmounts}
        eventName={eventName}
        isRequired
        requiredErrorMessage={ERROR_MESSAGE}
        newItem={newDonation}
        showAddButton
        maxNumberOfItems={maxNumberOfItems}
        isMultiplier={isMultiplier}
        disabled={disabled}
        additionalField={additionalField}
      >
        {({ updateItem, removeItem }) => children({ updateItem, removeItem })}
      </EditableList>
    </CardWrapper>
  );
}

DonationAmounts.propTypes = {
  eventName: PropTypes.string.isRequired,
  amounts: PropTypes.array.isRequired,
  updateAmounts: PropTypes.func.isRequired,
  newDonation: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  maxNumberOfItems: PropTypes.number,
  subtitle: PropTypes.string,
  noCard: PropTypes.bool,
  isMultiplier: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalField: PropTypes.any,
};

DonationAmounts.defaultProps = {
  additionalField: null,
  maxNumberOfItems: 9999,
  disabled: false,
};

export default DonationAmounts;
