import gql from 'graphql-tag';

import { VENDOR_IMAGES_FRAGMENT } from './settings';

export const GET_VENDOR_IMAGES_QUERY = gql`
  query getVendorImages(
    $imageType: [ImageTypeEnum!]
    $vendorRevvUid: String
    $limit: Int
    $sort: SortDirectionEnum
    $sort_by: ImageSortByEnum
  ) {
    viewer {
      vendor(revvUid: $vendorRevvUid) {
        ...VendorImages
      }
    }
  }
  ${VENDOR_IMAGES_FRAGMENT}
`;

export const UPLOAD_VENDOR_IMAGE_MUTATION = gql`
  mutation uploadImageMutation($newImage: File!, $vendorRevvUid: String!, $imageType: ImageTypeEnum!) {
    vendorUploadImage(vendorRevvUid: $vendorRevvUid, image: { imageType: $imageType, image: $newImage }) {
      image {
        id
        imageFileName
        created_at
        originalUrl
        thumbnailUrl
      }
      errors
    }
  }
`;
