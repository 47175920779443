import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import { Button } from 'modules/common';

function ColorPicker({ value, onChange, onHide, disabled }) {
  return (
    <div className="picker-container">
      <SketchPicker
        color={value}
        onChangeComplete={onChange}
        presetColors={[]}
        className="winred-sketch-picker"
        disableAlpha
        disabled={disabled}
      />
      <Button variant="primary" onClick={onHide}>
        Done
      </Button>
    </div>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ColorPicker;
