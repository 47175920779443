import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';

import { Form } from 'react-bootstrap';

import { INSTALL_TWO_FACTOR } from 'core/middleware/queries';
import { setLoginStatus, loginSelector, LOGIN_STATUSES } from 'core/login';
import { isValidPhoneNumber } from 'core/utilities';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';
import { PhoneNumberText, Button, Card, FloatGroup, setTitle, withAmplitude, Typography } from 'modules/common';

import LoginHeader from './login-header.component';

class TwoFactorSetupContainer extends Component {
  state = {
    mobilePhone: '',
    loading: false,
  };

  validation = React.createRef();

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  verifySetup = async event => {
    event.preventDefault();
    if (this.validation.current.errors()) return;

    this.setState({ loading: false }, async () => {
      try {
        const response = await this.props.installTwoFactorAuth({
          installTwoFactorCountryCode: '1',
          installTwoFactorCellphone: this.state.mobilePhone.replace(/\D/g, ''),
        });
        this.setState({ loading: false });

        if (!response.data.installTwoFactor || response.data.installTwoFactor?.error) {
          this.props.setToast({
            isError: true,
            message:
              response.data.installTwoFactor?.error ?? `There was an unknown error sending the verification code.`,
          });
          return;
        }

        this.props.logEvent('2fa setup');
        this.props.setLoginStatus({ status: LOGIN_STATUSES.TWO_FA_VERIFICATION });
      } catch (error) {
        this.setState({ loading: true });
        this.props.setToast({
          isError: true,
          message: `Error sending verification code.`,
        });
      }
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <>
        {setTitle('2FA Setup')}
        <LoginHeader title="Setup 2-Step Verification" subtitle="The extra step shows it's really you when signing in.">
          <Card>
            <Card.Body>
              <ValidationBlock ref={this.validation}>
                <Typography className="mb-4">
                  Enter your mobile phone number. A 6-digit verification code will be sent to this mobile phone number.
                </Typography>
                <Form onSubmit={this.onLoginSubmit}>
                  <FloatGroup>
                    <FloatGroup.Label htmlFor="mobile-phone">Mobile Phone Number</FloatGroup.Label>
                    <PhoneNumberText
                      type="text"
                      disabled={loading}
                      placeholder="Mobile Phone Number"
                      id="mobile-phone"
                      name="mobilePhone"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      maxLength="14"
                      validateOnBlurOnly
                      errorMessageClasses="position-absolute"
                      value={this.state.mobilePhone}
                      onChange={this.onChange}
                      validators={['required', isValidPhoneNumber]}
                      errorMessages={['Mobile Phone Number is required.', 'Invalid Mobile Phone Number']}
                    />
                  </FloatGroup>
                  <div className="mt-5 d-flex justify-content-end">
                    <Button
                      variant="success"
                      size="lg"
                      type="submit"
                      disabled={loading}
                      ariaLabel="Sign In"
                      onClick={this.verifySetup}
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
              </ValidationBlock>
            </Card.Body>
          </Card>
        </LoginHeader>
      </>
    );
  }
}

TwoFactorSetupContainer.propTypes = {
  setToast: PropTypes.func.isRequired,
  setLoginStatus: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  installTwoFactorAuth: PropTypes.func.isRequired,
  loginProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loginProfile: loginSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setLoginStatus: payload => dispatch(setLoginStatus(payload)),
  };
};

export default compose(
  withAmplitude,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(INSTALL_TWO_FACTOR, {
    props: ({ mutate }) => ({
      installTwoFactorAuth: variables => mutate({ variables }),
    }),
  })
)(TwoFactorSetupContainer);
