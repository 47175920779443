import gql from 'graphql-tag';

export const INVITE_VENDOR_USER_MUTATION = gql`
  mutation inviteVendorUser(
    $email: String!
    $roleType: RoleTypeEnum!
    $organizationRevvUid: String
    $vendorRevvUid: String!
  ) {
    vendorInviteUser(
      email: $email
      roleType: $roleType
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors
      user {
        revv_uid
        first_name
        last_name
        email
      }
      dammed
    }
  }
`;

export const REMOVE_VENDOR_USERS_MUTATION = gql`
  mutation removeVendorUser($profileUids: [String!]!, $organizationRevvUid: String, $vendorRevvUid: String!) {
    vendorRemoveUsers(
      profileUids: $profileUids
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors
      allRemoved
    }
  }
`;
