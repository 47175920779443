import gql from 'graphql-tag';

const ORGANIZATION_CONNECTED_COMMITTEE_FRAGMENT = gql`
  fragment VendorSettingsStateLevel on StateLevelOrganization {
    name
    created_at
    revv_uid
    vendor_settings {
      receivedConnections {
        name
        revv_uid
        is_revv_share
        default_fee_percentage
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      awaitingApproval {
        name
        revv_uid
        is_revv_share
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      connected {
        fullAccess {
          id
          defaultFeePercentage
          created_at
          updated_at
          vendor {
            name
            revv_uid
            is_revv_share
            avatar {
              image {
                thumbnailUrl
              }
            }
            contact_info {
              first_name
              last_name
              email
            }
            contact_address {
              address
              address2
              city
              state
              zip
            }
            w9_form {
              id
              originalUrl
              type
            }
          }
          vendorConnectActivity(filters: { page: $page, limit: $limit }) {
            page_count
            total_count
            current_page
            results {
              id
              activityType
              sourceName
              destinationName
              feeChangedFrom
              feeChangedTo
              date
              email
            }
          }
        }
        billPayOnly {
          id
          defaultFeePercentage
          created_at
          updated_at
          vendor {
            name
            revv_uid
            is_revv_share
            avatar {
              image {
                thumbnailUrl
              }
            }
            contact_info {
              first_name
              last_name
              email
            }
            contact_address {
              address
              address2
              city
              state
              zip
            }
            w9_form {
              id
              originalUrl
              type
            }
          }
          vendorConnectActivity(filters: { page: $page, limit: $limit }) {
            page_count
            total_count
            current_page
            results {
              id
              activityType
              sourceName
              destinationName
              feeChangedFrom
              feeChangedTo
              date
              email
            }
          }
        }
      }
      disconnected {
        id
        defaultFeePercentage
        created_at
        updated_at
        vendor {
          name
          revv_uid
          is_revv_share
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
        vendorConnectActivity(filters: { page: $page, limit: $limit }) {
          page_count
          total_count
          current_page
          results {
            id
            activityType
            sourceName
            destinationName
            feeChangedFrom
            feeChangedTo
            date
            email
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_CONNECTED_COMMITTEES_QUERY = gql`
  query getOrganizationVendorSettings($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        ...VendorSettingsStateLevel
      }
    }
  }
  ${ORGANIZATION_CONNECTED_COMMITTEE_FRAGMENT}
`;

export const GET_ORGANIZATION_BILL_PAY_QUERY = `
  query getStateLevelOrgBillPayIdVendor($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        bill_pay_id
      }
    }
  }
`;

export const GET_ORGANIZATION_CONNECTED_COMMITTEE_DETAILS_QUERY = gql`
  query getStateLevelOrgBillPayIdVendor($organizationRevvUid: String, $billPayId: String!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        bill_pay_id
        vendor(billPayId: $billPayId) {
          name
          revv_uid
          is_revv_share
          avatar {
            image {
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_CONNECT_TO_VENDOR_MUTATION = gql`
  mutation organizationConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: VendorConnectRequestInput!
  ) {
    organizationCreateVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      receiver {
        name
        revv_uid
        avatar {
          image {
            thumbnailUrl
          }
        }
      }
      errors
    }
  }
`;

export const ORGANZATION_APPROVE_CONNECT_TO_VENDOR_MUTATION = gql`
  mutation organizationAcceptConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: AcceptVendorConnectRequestInput!
  ) {
    organizationAcceptVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;

export const ORGANIZATION_EDIT_CONNECTION_TO_VENDOR_MUTATION = gql`
  mutation organizationUpdateBillPayConnectionMutation(
    $billPayConnection: UpdateBillPayConnectionInput!
    $organizationRevvUid: String!
  ) {
    organizationUpdateBillPayConnection(
      billPayConnection: $billPayConnection
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;

export const ORGANIZATION_CANCEL_CONNECTION_REQUEST_MUTATION = gql`
  mutation organizationCancelConnectRequestMutation(
    $organizationRevvUid: String!
    $vendorConnectRequest: CancelVendorConnectRequestInput!
  ) {
    organizationCancelVendorConnectRequest(
      vendorConnectRequest: $vendorConnectRequest
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;

export const ORGANIZATION_DISCONNECT_FROM_VENDOR_REQUEST_MUTATION = gql`
  mutation organizationDisconnectBillPayConnectionMutation(
    $billPayConnection: DisconnectBillPayConnectionInput!
    $organizationRevvUid: String!
  ) {
    organizationDisconnectBillPayConnection(
      billPayConnection: $billPayConnection
      organizationRevvUid: $organizationRevvUid
    ) {
      message
      errors
    }
  }
`;
