export const getTeamDonationIconColor = donation => {
  switch (donation.status) {
    case 'succeeded':
      return 'green';
    case 'failed':
      return 'red';
    case 'disputed':
      return 'red';
    default:
      return 'gray';
  }
};

export const getTeamDonationTooltip = donation => {
  switch (donation.status) {
    case 'succeeded':
      return 'Succeeded Donation';
    case 'failed':
      return 'Failed Donation';
    case 'disputed':
      return 'Disputed Donation';
    default:
      return 'Refunded Donation';
  }
};
