import gql from 'graphql-tag';

const fragments = {
  subscriptionsOrganization: gql`
    fragment OrganizationSubscriptions on CandidateSubscriptionResult {
      current_page
      page_count
      total_count

      results {
        created_at
        first_name
        last_name
        amount
        status
        source_url
        email
        phone
        source_code
        recurring
        revv_uid
        interval
        subscription_status
        contributor_type
        pac_name
        business_name
      }
    }
  `,
  subscriptionOrganization: gql`
    fragment OrganizationSubscription on CandidateSubscriptionResult {
      results {
        created_at
        first_name
        last_name
        amount
        canceled_at
        end_at
        status
        source_url
        source_code
        email
        phone
        recurring
        card_expiry
        card_last4
        card_brand
        revv_uid
        profile_revv_uid
        user_type
        interval
        subscription_status
        contributor_type
        pac_name
        business_name
      }
    }
  `,
};

export const GET_ORGANIZATION_SUBSCRIPTIONS_REPORT = gql`
  query getOrganizationSubscriptionsReport(
    $page: Int
    $organizationRevvUid: String
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $contributorType: [ContributorTypeEnum!]
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        export_subscriptions_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_ORGANIZATION_SUBSCRIPTIONS_QUERY = gql`
  query getOrganizationSubscriptions(
    $page: Int
    $organizationRevvUid: String
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $contributorType: [ContributorTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        subscription_statistics(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            contributorType: $contributorType
          }
        ) {
          remaining_this_month {
            subscription_count
            subscription_revenue
            subscription_average
          }
          total_active {
            subscription_count
            subscription_average
            subscription_revenue
          }
        }
        subscriptions(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
          }
        ) {
          ...OrganizationSubscriptions
        }
      }
    }
  }
  ${fragments.subscriptionsOrganization}
`;

export const CANCEL_ORGANIZATION_SUBSCRIPTION_MUTATION = gql`
  mutation cancelOrganizationSubscription($revvUid: String!, $organizationRevvUid: String!) {
    organizationCancelSubscription(revvUid: $revvUid, organizationRevvUid: $organizationRevvUid) {
      errors
      subscription {
        status
      }
    }
  }
`;

export const GET_ORGANIZATION_SUBSCRIPTION_QUERY = gql`
  query getOrganizationSubscription($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        subscriptions(filters: { revvUid: $revv_uid }) {
          ...OrganizationSubscription
        }
      }
    }
  }
  ${fragments.subscriptionOrganization}
`;
