import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IndexTableContextMenu } from './lists';
import { Dropdown } from 'react-bootstrap';
import brokenImagePicture from '../../assets/images/image-unavailable.png';

export const ImageDisplayCard = ({
  imageAlt,
  imageSrc,
  optionsList,
  onSelectOption,
  children,
  cardWidth = '',
  imageHeight = '',
  objectClass = '',
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const handleBrokenImage = () => {
    setIsImageBroken(true);
  };
  return (
    <div className={`${cardWidth ? `w-${cardWidth}` : ''} image-display m-2 relative bg-white`}>
      {optionsList?.length > 0 && (
        <div className="bg-white btn-group absolute top-2 right-2 w-8 h-5 flex justify-center items-center pl-2 border-1 border-catskill">
          <IndexTableContextMenu className="m-0" onSelect={onSelectOption}>
            {optionsList.map(option => {
              return (
                <Dropdown.Item key={option.key} eventKey={option.key}>
                  {option.label}
                </Dropdown.Item>
              );
            })}
          </IndexTableContextMenu>
        </div>
      )}
      {isImageBroken ? (
        <img
          alt="broken or loading"
          src={brokenImagePicture}
          className={`${imageHeight ? `h-${imageHeight}` : ''} w-full ${objectClass ? objectClass : ''}`}
        />
      ) : (
        <img
          alt={imageAlt}
          src={imageSrc}
          className={`${imageHeight ? `h-${imageHeight}` : ''} w-full ${objectClass ? objectClass : ''}`}
          onError={handleBrokenImage}
        />
      )}

      <div className="info-box p-3 bg-white border-catskill border-t-2 hover:bg-catskill">{children}</div>
    </div>
  );
};

ImageDisplayCard.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  optionsList: PropTypes.array,
  onSelectOption: PropTypes.func,
  children: PropTypes.any,
  cardWidth: PropTypes.any,
  imageHeight: PropTypes.any,
  objectClass: PropTypes.string,
};
