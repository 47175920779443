import gql from 'graphql-tag';
import { donationFragments } from './donations.queries';

export const GET_ORGANIZATION_DONATIONS_REPORT = gql`
  query getOrganizationDonationsReport(
    $search: String
    $page: Int
    $limit: Int
    $revvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $conduitCandidateRevvUid: String
    $status: DonationStatusEnum
    $campaignRevvUids: [String!]
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $payoutRevvUid: String
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $candidateSearch: Boolean
    $organizationRevvUid: String
    $origins: [String!]
    $ignoreCustomFields: Boolean
    $bundlerId: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        export_statistics_report(
          filters: {
            search: $search
            productName: $productName
            page: $page
            limit: $limit
            revvUid: $revvUid
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            conduitCandidateRevvUid: $conduitCandidateRevvUid
            status: $status
            campaignRevvUids: $campaignRevvUids
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            payoutRevvUid: $payoutRevvUid
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            candidateSearch: $candidateSearch
            origins: $origins
            ignoreCustomFields: $ignoreCustomFields
            bundlerId: $bundlerId
          }
        )
      }
    }
  }
`;

export const GET_ORGANIZATION_DONATIONS_QUERY = gql`
  query getOrganizationDonations(
    $search: String
    $page: Int
    $limit: Int
    $revvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $conduitCandidateRevvUid: String
    $status: DonationStatusEnum
    $campaignRevvUids: [String!]
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $payoutRevvUid: String
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $candidateSearch: Boolean
    $organizationRevvUid: String
    $origins: [String!]
    $bundlerId: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        origins {
          revv_uid
          name
        }
        recipients {
          revv_uid
          name
        }
        campaigns {
          results {
            revv_uid
            name
          }
        }
        statistics(
          filters: {
            search: $search
            productName: $productName
            page: $page
            limit: $limit
            revvUid: $revvUid
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            conduitCandidateRevvUid: $conduitCandidateRevvUid
            status: $status
            campaignRevvUids: $campaignRevvUids
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            payoutRevvUid: $payoutRevvUid
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            candidateSearch: $candidateSearch
            origins: $origins
            bundlerId: $bundlerId
          }
        ) {
          ...ReportingDonations
        }
      }
    }
  }
  ${donationFragments.donations}
`;

export const GET_ORGANIZATION_DONATION_QUERY = gql`
  query getOrganizationDonation($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        revv_uid
        statistics(filters: { revvUid: $revv_uid }) {
          ...ReportingDonation
        }
        donors {
          results {
            revv_uid
          }
        }
      }
    }
  }
  ${donationFragments.donation}
`;

export const REFUND_ORGANIZATION_DONATION_MUTATION = gql`
  mutation refundOrganizationDonation(
    $organizationRevvUid: String!
    $chargeUid: String!
    $refund: String!
    $partialAmount: String!
  ) {
    organizationRefund(
      chargeRefundForm: {
        organizationRevvUid: $organizationRevvUid
        chargeUid: $chargeUid
        refund: $refund
        partialAmount: $partialAmount
      }
    ) {
      message
      errors
    }
  }
`;
export const ORGANIZATION_CREATE_MOOV_ACCOUNT = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String) {
    moovCreateDonorAccount(firstName: $firstName, lastName: $lastName, email: $email) {
      errors
      dammed
      accountId
      accountToken
      email
    }
  }
`;
export const VIRTUAL_TERMINAL_ORGANIZATION_DONATION = gql`
  mutation createOrganizationVirtualTerminalDonation(
    $virtualTerminalDonation: VirtualTerminalDonationInput!
    $organizationRevvUid: String!
  ) {
    organizationCreateVirtualTerminalDonation(
      organizationRevvUid: $organizationRevvUid
      virtualTerminalDonation: $virtualTerminalDonation
    ) {
      errors
      message
    }
  }
`;
