import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'modules/common/icon.component';
import WinRedInput from 'modules/common/form/inputs/winred-input.component';
import './inputs.scss';

function WinRedSearchInput({
  name = 'search',
  value = '',
  onChange,
  placeholder,
  disabled = false,
  alwaysExpanded = false,
  ...rest
}) {
  const [active, setActive] = useState(false);

  return (
    <div className={classNames('search-input-container', { expand: active || value || alwaysExpanded })}>
      <Icon icon="search" size="lg" color="gray" />
      <WinRedInput
        className="rounded-pill recording-sensitive long-search"
        name={name}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        {...rest}
      />
    </div>
  );
}

WinRedSearchInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WinRedSearchInput;
