import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Button } from 'modules/common';

function SettingsSave({ onClick, disabled, className = '' }) {
  return (
    <Col className={'my-2 order-lg-1 order-md-1 order-sm-2' + className} xs={12} md={8} lg={8}>
      <Button className="mr-2" disabled={disabled} onClick={onClick}>
        Save
      </Button>
    </Col>
  );
}

SettingsSave.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default SettingsSave;
