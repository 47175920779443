import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { createMoneyPledgesOptions } from 'core/utilities';
import { GET_MONEYPLEDGE_OPTIONS_QUERY, GET_ORGANIZATION_MONEYPLEDGE_OPTIONS_QUERY } from 'core/middleware/queries';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';

export function useMoneyPledgeOptions() {
  const [moneyPledgeOptions, setMoneyPledgeOptions] = useState([]);

  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const variables = {
    limit: 9999,
    organizationRevvUid: profileCandidate.organization_revv_uid,
  };

  const { error, loading } = useQuery(
    isOrganization ? GET_ORGANIZATION_MONEYPLEDGE_OPTIONS_QUERY : GET_MONEYPLEDGE_OPTIONS_QUERY,
    {
      variables,
      onCompleted: data => {
        const options = createMoneyPledgesOptions(data);
        setMoneyPledgeOptions(options);
      },
    }
  );

  return { error, loading, moneyPledgeOptions };
}
