import React, { useCallback, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { components } from 'react-select';

import Input from './winred-input.component';

import useValidation from './useValidation';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import SubText from 'modules/common/sub-text.component';

import { customStyles } from './select-styling';
import { noop } from 'core/utilities';
import { ToolTip, Typography } from 'modules/common';

function WinRedSelect({
  name,
  onChange,
  classNameContainer,
  value,
  validators,
  validateOnMount,
  className,
  onBlur,
  disabled,
  placeholder,
  onFocus,
  numberOfDropdownItems = 3,
  children,
  options,
  title,
  noOptionsMessage,
  errorIcon,
  description,
  subDescription,
  hideMenu,
  prependSearchIcon,
  hideDropdownIndicator,
  isMulti,
  isGrouped,
  maxWidthDropDown,
  valueIsObject,
  ...rest
}) {
  const [focus, setFocus] = useState(false);
  const ref = useRef();

  const [validationBlur, validationError] = useValidation({
    value,
    validators,
    validateOnMount,
    onBlur,
    ref: ref?.current?.select?.inputRef,
  });

  const _onChange = useCallback(
    event => {
      let newValue = event && (event.value !== undefined || event.value !== null) ? event.value : event;
      // if multi select then we need whole event object
      if(isMulti && event.length === 0){
        newValue = null;
      } else if (isMulti || valueIsObject) newValue = event;
      if (isGrouped) {
        onChange(event);
      } else {
        onChange({ target: { name, value: newValue } });
      }
    },
    [onChange, name, isMulti, valueIsObject, isGrouped]
  );

  const handleFocusChange = useCallback(
    event => {
      if (onFocus) onFocus(event);
      setFocus(true);
    },
    [onFocus]
  );

  const handleBlurChange = useCallback(
    event => {
      validationBlur(event);
      setFocus(!!value);
    },
    [validationBlur, value]
  );

  const _noOptionsMessage = useCallback(() => noOptionsMessage || 'No results found.', [noOptionsMessage]);

  const styles = useMemo(
    () =>
      customStyles({
        hideDropdownIndicator,
        numberOfDropdownItems,
        validationError,
        prependSearchIcon,
        hideMenu,
        maxWidthDropDown,
      }),
    [numberOfDropdownItems, validationError, prependSearchIcon, hideMenu, hideDropdownIndicator, maxWidthDropDown]
  );

  if (disabled) {
    let displayValues = [];
    if (isMulti) {
      value.forEach(val => {
        displayValues.push(val.label);
      });
    }
    const displayValue = isMulti ? displayValues.join(', ') : value?.label || value || '';
    if (displayValue.length > 24) {
      return (
        <ToolTip tooltip={displayValue} placement="bottom">
          <Input name={name} disabled placeholder={placeholder} value={displayValue} onChange={noop} />
        </ToolTip>
      );
    }
    return <Input name={name} disabled placeholder={placeholder} value={displayValue} onChange={noop} />;
  }

  let labelClass = focus || (value && value.length !== 0);

  return (
    <>
      <div className={classnames('winred-input2 winred-input2-select', classNameContainer)}>
        {title ? (
          <Typography variant="bodyMedium" className="mb-0">
            {title}
          </Typography>
        ) : null}
        <div
          className={'float-label ' + (prependSearchIcon ? 'float-label-search' : '')}
          onBlur={handleBlurChange}
          onFocus={handleFocusChange}
        >
          <Select
            ref={ref}
            name={name}
            value={value || ''}
            components={components}
            maxMenuHeight={numberOfDropdownItems * 40}
            options={options}
            styles={styles}
            noOptionsMessage={_noOptionsMessage}
            onChange={_onChange}
            placeholder=""
            isMulti={isMulti}
            {...rest}
          />
          {placeholder ? (
            <label
              className={classnames(
                {
                  'label label-float': labelClass,
                  label: !labelClass,
                  'winred-input-float-error': !!validationError,
                },
                className
              )}
            >
              {placeholder}
            </label>
          ) : null}
        </div>
        <InputErrorMessage errorMessage={validationError} errorIcon={errorIcon} />
        {description ? <SubText description={description} description2={subDescription} /> : null}
        {children}
      </div>
    </>
  );
}

WinRedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  validators: PropTypes.array,
  validateOnMount: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  numberOfDropdownItems: PropTypes.number,
  children: PropTypes.node,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  errorIcon: PropTypes.node,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  hideMenu: PropTypes.bool,
  prependSearchIcon: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
  maxWidthDropDown: PropTypes.bool,
};

export default WinRedSelect;
