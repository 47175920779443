import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getFormattedMonthDay, convertEpochToDate } from './time';
import { formatMoneyPledgeForUi } from 'modules/revenue/moneyPledges/money-pledge.tools';

export const formatAmount = (amount, isCents = false, showCents = true, showDollarSign = true) => {
  if (isCents) amount = amount / 100;
  const isNegative = amount < 0;
  amount = Math.abs(amount);

  const commaAmount = formatNumberWithCommas(parseFloat(amount || 0).toFixed(showCents ? 2 : 0));

  if (isNegative) return `($${commaAmount})`;
  if (!showDollarSign) return commaAmount;
  return `$${commaAmount}`;
};

export const formatBoolean = value => {
  if (value) return 'true';
  return '';
};

export const formatMoneyPledgeLabel = mp =>
  `${mp.name} - Processing on ${getFormattedMonthDay(convertEpochToDate(mp?.money_pledge_date))}`;

export const formatPercentage = percentage => {
  if (typeof percentage === 'string') {
    return parseFloat((percentage * 100).toFixed(2));
  }
  return Number(percentage);
};

export const formatPhoneNumber = number => {
  /* Parse the number, using the US as the default country. */
  const parsedNumber = parsePhoneNumberFromString(`${number}`, 'US');
  /* If the number could be parsed, display the formatted number. Otherwise return the original number provided. */

  if (parsedNumber) {
    return parsedNumber.formatNational();
  } else {
    return number;
  }
};

export const isValidPhoneNumber = number => {
  const parsedNumber = parsePhoneNumberFromString(`${number}`, 'US');
  return parsedNumber && parsedNumber.isValid();
};

export const isValidTwoFactorCode = number => number.length === 6;

export const formatNumberWithCommas = number => {
  if (number === null || number === undefined) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const replaceSpacesWithDashes = string => {
  if (string) string = string.replace(/ /g, '-').toLowerCase();
  return string;
};

export const kFormatter = (num, isMoney) => {
  const sign = Math.sign(num) === -1 ? '-' : '';
  const fixedAmount = num % 10 === 0 ? 0 : 1;
  let formattedNumber = Math.abs(num);

  if (Math.abs(num) > 999) {
    formattedNumber = (Math.abs(num) / 1000).toFixed(fixedAmount) + 'k';
  }

  if (Math.abs(num) > 999999) {
    formattedNumber = (Math.abs(num) / 1000000).toFixed(fixedAmount) + 'M';
  }

  if (Math.abs(num) > 999999999) {
    formattedNumber = (Math.abs(num) / 1000000000).toFixed(fixedAmount) + 'B';
  }

  return `${sign}${isMoney ? '$' : ''}${formattedNumber}`;
};

export const isValidTaxId = taxId => {
  taxId = taxId || '';
  return taxId.replace(/\D/g, '').length === 9;
};

export const insertAt = (str, index, value) => {
  return str.substr(0, index) + value + str.substr(index);
};

export const isValidZipCode = zip => {
  if (!zip) return true; // return true as we dont have anything to validate

  if (zip.length < 5) return false;
  return true;
};

export const isValidUiSsn = fullSsn => {
  if (fullSsn.length !== 11) return false;
  return true;
};

export const toTitleCase = str => {
  const lowerCasedName = (str || '').toLowerCase();
  return lowerCasedName.charAt(0).toUpperCase() + lowerCasedName.slice(1);
};

export const toTitleCaseAll = sentence => {
  const words = sentence.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }

  return words.join(' ');
};

/*eslint-disable-next-line no-useless-escape*/
export const emailValidator = new RegExp(/\S+@\S+\.\S+/);

export const commaSeparatedEmailList = new RegExp(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/i);

export const urlSlugValidator = new RegExp(/^[0-9a-z_-]+$/);

export const characterLimit = inputValue => {
  return inputValue.length <= 1000;
};

export const maxValue = (maxValue, inputValue) => {
  if (typeof inputValue === 'string') {
    return Number(inputValue) <= maxValue;
  }

  return inputValue <= maxValue;
};

export const minValue = (minValue, inputValue) => {
  if (typeof inputValue === 'string') {
    return Number(inputValue) >= minValue;
  }

  return inputValue >= minValue;
};

export const pluralize = ({ singular, plural, count, showCount, zero }) => {
  if (count === 0 && zero) return zero;

  let output = singular;
  if (count !== 1) {
    output = plural || `${singular}s`;
  }

  return showCount ? `${count} ${output}` : output;
};

export const formatAddress = ([address = '', city = '', state = '', zip = '']) => {
  // check if address is present and return empty string if null
  let fullAddress = address ? address : '';
  // If address was not added no space will be added before city
  if (city && state) fullAddress += `${fullAddress ? ' ' : ''}${city}, ${state}`;
  // Account for fields being toggled on/off
  if (city && !state) fullAddress += `${fullAddress ? ', ' : ''}${city}`;
  if (!city && state) fullAddress += `${fullAddress ? ', ' : ''}${state}`;
  // If only zip is present no space will be added before it
  if (zip) fullAddress += `${fullAddress ? ' ' : ''}${zip}`;

  return fullAddress;
};

export const formatEmployerAddress = ([address = '', city = '', state = '', zip = '']) => {
  // Field will end up hidden in information-list.component
  if ([address, city, state, zip].includes('retired')) return '';

  return formatAddress([address, city, state, zip]);
};

export const sortByName = (a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1);
export const sortByLabel = (a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1);

export const sortByPosition = (a, b) => (a.position > b.position ? 1 : -1);

export const createMoneyPledgesOptions = data => {
  if (!data?.viewer) return [];

  const currentEpoch = Date.now();

  return ((data.viewer.candidate || data.viewer.state_level_organization)?.campaigns?.results || [])
    .filter(mp => mp.money_pledge_date * 1000 > currentEpoch)
    .map(mp => ({
      label: formatMoneyPledgeLabel(mp),
      value: formatMoneyPledgeForUi(mp),
    }));
};

export const getCandidate = data => {
  return data?.viewer?.candidate || data?.viewer?.state_level_organization || data?.viewer?.vendor;
};

export const addLeadingZero = number => {
  return number < 10 ? `0${number}` : number;
};

export const getRouteByOrgType = (organizationRevvUid, isAgency, route) => {
  return `/${organizationRevvUid}${isAgency ? `/vendors` : ''}${route}`;
};

export const formatCentsWithoutComma = amount => {
  amount = Math.abs(amount / 100);

  return parseFloat(amount.toFixed(2));
};
