import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import '../../widgets.scss';
import { Typography, Button, WinRedAnimate } from 'modules/common';
import { noop } from 'core/utilities';

const getButtonText = (upsellType, editedUpsell) => {
  switch (upsellType) {
    case 'DOUBLE':
      return 'Double My $100 Donation';
    case 'RECURRING':
      return 'Donate $100 Monthly';
    case 'CONDUIT':
      return 'Donate';
    case 'MONEYPLEDGE':
      const selectedMoneyPledge = editedUpsell.moneyPledgeOptions.find(
        entry => entry.value?.revv_uid === editedUpsell?.money_pledge_campaign?.revv_uid
      );
      const pledgeDate = new Date(selectedMoneyPledge?.value?.money_pledge_date);
      const dateOptions = { month: 'short', day: 'numeric' };
      const dateFormatted = pledgeDate?.toLocaleDateString('en-US', dateOptions);
      return `Donate an Additional $100 on ${dateFormatted}`;
    default:
      return editedUpsell?.accept || 'Donate';
  }
};

function WidgetPreviewAcceptAnimation({ widget, upsellType, editedUpsell }) {
  return (
    <Container className="widget-preview_btnAnimation">
      <Typography>{widget.countdownLabel || ''}</Typography>
      <Row>
        <Col xs={12} className="widget-preview_btnAnimation_btn-wrapper">
          <WinRedAnimate
            key={8}
            animation={widget.acceptButtonAnimationStyle}
            delay={widget.acceptButtonAnimationDelay}
            speed={widget.acceptButtonAnimationDuration}
            isRepeating={widget.acceptButtonAnimationRepeat}
            repeatInterval={widget.acceptButtonAnimationRepeatAfter}
          >
            <Button fullWidth={true} style={{ backgroundColor: widget.acceptButtonAnimationColor }} onClick={noop}>
              {upsellType ? getButtonText(upsellType, editedUpsell) : 'Donate'}
            </Button>
          </WinRedAnimate>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewAcceptAnimation.propTypes = {
  widget: PropTypes.object.isRequired,
  upsellType: PropTypes.string.isRequired,
  editedUpsell: PropTypes.object,
};

export default WidgetPreviewAcceptAnimation;
