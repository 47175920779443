

export const formatBlackDefaultTextColor = (html) => {
  if(html === null || html === undefined) {
    return '';
  }
  const color = '#000000';
  const isTextNode = (node) => node.nodeType === Node.TEXT_NODE;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // recursively wrap text nodes with colored span, unless it already has a parent span with color style
  const wrapNode = (node) => {
    node.childNodes.forEach(child => {
      if (isTextNode(child) && child.textContent.trim()) {
        const span = document.createElement('span');
        span.setAttribute('style', `color: ${color};`);
        span.textContent = child.textContent;
        node.replaceChild(span, child);
      } else if (!isTextNode(child) && !child.matches('span[style*="color"]')) {
        wrapNode(child);
      }
    });
  };

  wrapNode(doc.body);

  return doc.body.innerHTML;
};