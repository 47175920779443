import { convertEpochTime } from 'core/utilities';
import { dateRangeFilterUi, searchFilterUi, dropdownFilterUi } from 'modules/common';
import { store } from '../../../configureStore';

export const getDonorIconColor = donor => (donor.user_type ? 'gray' : 'green');

export const getDonorIcon = donor => {
  switch (donor.contributor_type) {
    case 'business':
      return 'briefcase';
    case 'pac':
      return 'sitemap';
    default:
      return 'user';
  }
};

export const formatDonorType = user => (user.user_type ? 'WinRed User' : 'Guest');
export const getDonorToolTip = donor => formatDonorType(donor);

export const getDonorDetailEventData = data => {
  const [donor] = (data.viewer.candidate || data.viewer.state_level_organization).donors.results;
  if (!donor) return;

  const donations = donor.donations.results;

  const highestDonationAmount = Math.max.apply(
    Math,
    donations.map(donation => donation.amount)
  );
  const [mostRecentDonation = {}] = donations;

  const eventParams = {
    'donor id': donor.revv_uid,
    'create date': convertEpochTime(donor.created_at),
    'donor user': donor.user_type,
    'card type': donor.card_brand,
    'donation count': donations.length,
    'highest donation amount': highestDonationAmount,
    'most recent donation date': convertEpochTime(mostRecentDonation.created_at),
    'most recent donation amount': mostRecentDonation.amount,
    'subscription count': donor.subscriptions.results.length,
  };

  return eventParams;
};

export const navTabsDonor = [
  { value: 'all', label: 'All' },
  { value: 'donor', label: 'Guests' },
  { value: 'donorUser', label: 'WinRed Users' },
];

export const mapFilterToUiDonor = (filter, navTabs) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: 'all',
    dropdownOptions: navTabs,
    filterKey: 'activeTabKey',
    label: 'Donor Type',
  });

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });

  return [uiFilter, numberOfFilters];
};

export const donorContributorTypeMap = {
  pac: 'PAC',
  personal: 'Personal',
  joint: 'Joint',
  business: 'Business',
};

export const isPacDonor = user => user.contributor_type === 'pac';
export const isBusinessDonor = user => user.contributor_type === 'business';

export const generateContributorTypeOptions = () => {
  const state = store.getState();
  const profileCandidate = state?.login?.profile?.candidate;

  const CONTRIBUTOR_TYPE = [{ value: 'PERSONAL', label: 'Personal' }];

  const contributionSetting = profileCandidate.contribution_setting || {};

  if (profileCandidate.can_accept_business_donations && contributionSetting.enable_business_donations) {
    CONTRIBUTOR_TYPE.push({ value: 'BUSINESS', label: 'Business' });
  }

  if (profileCandidate.can_accept_pac_donations && contributionSetting.enable_pac_donations) {
    CONTRIBUTOR_TYPE.push({ value: 'PAC', label: 'PAC' });
  }

  return CONTRIBUTOR_TYPE;
};
