import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import { FloatGroup, Input, Avatar } from 'modules/common';

import { CommitteeCardDetails } from './committee-card-details.component';
import { DEFAULT_PAGE_AMOUNT } from './tools';

export function SelectedAmountCommittee({
  conduitCandidate,
  isPetitionPage = false,
  isOrganization = false,
  isTeamPages = false,
  showExtraOptions = false,
  splitEvenly = false,
  isBillPay = false,
  onChange,
  eventName,
  selectedCandidates,
}) {
  const candidate = conduitCandidate.candidate || conduitCandidate.organization;

  /**
   * we have two page amounts in an array with two inputs for each object in the array
   * depending on if we are changing amount 1 or 2, edit that amount prop on that corresponding object in the array
   * and update all of pageAmounts on the overall upsell object. This is messy but keeps things standardized on the
   * overall upsell group view for other upsels that use pageAmount
   */
  const onChangeAmounts = ({ target: { name, value } }) => {
    const newCandidate = cloneDeep(conduitCandidate);

    const firstPageAmount = {
      ...((conduitCandidate.pageAmounts && conduitCandidate.pageAmounts[0]) || DEFAULT_PAGE_AMOUNT),
    };
    const secondPageAmount = {
      ...((conduitCandidate.pageAmounts && conduitCandidate.pageAmounts[1]) || DEFAULT_PAGE_AMOUNT),
    };

    if (name === 'amount') {
      newCandidate.pageAmounts = [{ ...firstPageAmount, amount: value }, secondPageAmount];
    } else {
      newCandidate.pageAmounts = [firstPageAmount, { ...secondPageAmount, amount: value }];
    }

    // now that we updated the pageAmounts for the current candidate
    // we need to update the candidate prop on the upsell itself
    const conduitPageCandidates = selectedCandidates.map(oldCandidate => {
      const _oldCandidate = oldCandidate.candidate || oldCandidate.organization;
      if (_oldCandidate.revv_uid === candidate.revv_uid) return newCandidate;
      return oldCandidate;
    });

    onChange({ target: { name: eventName, value: conduitPageCandidates } });
  };

  const amountOne =
    (conduitCandidate.pageAmounts && conduitCandidate.pageAmounts[0] && conduitCandidate?.pageAmounts[0].amount) || '';
  const amountTwo =
    (conduitCandidate.pageAmounts && conduitCandidate.pageAmounts[1] && conduitCandidate.pageAmounts[1].amount) || '';

  const shouldShowPercentage = !showExtraOptions && !isOrganization && !splitEvenly && !isPetitionPage;

  return (
    <Col>
      <Row>
        <Col xs="auto" className="d-flex align-items-center pr-0">
          <Avatar item={candidate} />
        </Col>

        <Col className="d-flex align-items-center">
          <div>
            <CommitteeCardDetails
              conduitCandidate={conduitCandidate}
              isTeamPages={isTeamPages}
              shouldShowPercentage={shouldShowPercentage}
              isBillPay={isBillPay}
            />
          </div>
        </Col>

        <Col xs={12} sm={3} className="amount-1">
          <FloatGroup>
            <Input
              placeholder="Amount"
              id="amount"
              name="amount"
              aria-label="Amount"
              numbersOnly
              value={amountOne}
              errorMessageClasses="position-absolute upsell-committee-amount-error"
              validators={['required']}
              errorMessages={['Amount is required.']}
              onChange={onChangeAmounts}
            />
          </FloatGroup>
        </Col>

        <Col xs={12} sm={3}>
          <FloatGroup>
            <Input
              placeholder="Amount 2"
              id="amount_2"
              name="amount_2"
              aria-label="Amount 2"
              numbersOnly
              value={amountTwo}
              errorMessageClasses="position-absolute upsell-committee-amount-error"
              validators={['required']}
              errorMessages={['Amount 2 is required.']}
              onChange={onChangeAmounts}
            />
          </FloatGroup>
        </Col>
      </Row>
    </Col>
  );
}

SelectedAmountCommittee.propTypes = {
  conduitCandidate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
  selectedCandidates: PropTypes.array.isRequired,
  isTeamPages: PropTypes.bool,
  shouldShowPercentage: PropTypes.bool,
  isBillPay: PropTypes.bool,
};
