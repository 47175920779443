import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { setToast } from 'core/toast';
import { Typography, SpinnerContainer } from 'modules/common';
import IdentificationModal from './id-image-uploader.component';

class IdForm extends Component {
  state = {
    loadingImage: false,
  };

  uploadImage = (imageKey, imageFile) => {
    this.setState({ loadingImage: true }, () => {
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('purpose', 'identity_document');

      fetch('https://uploads.stripe.com/v1/files', {
        method: 'POST',
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
        }),
      })
        .then(response => response.json())
        .then(response => {
          this.setState({ loadingImage: false });

          if (response.error) {
            this.props.setToast({
              message: response.error.message,
              isError: true,
            });
            return;
          }

          this.props.onChange({ target: { name: imageKey, value: response.id } });
        })
        .catch(() => {
          this.props.setToast({
            message: 'There was an error. Check your connection and try again.',
            isError: true,
          });
          this.setState({ loadingImage: false });
        });
    });
  };

  render() {
    const { ownerBeingEdited } = this.props;

    return (
      <div>
        <Row className="mt-2">
          <Col>
            <Typography variant="bodyMedium">Upload Government-issued Photo ID</Typography>

            <Typography italic>
              Upload a passport (preferred), driver&apos;s license, or other form of government-issued identification.
              Scans of both the front and back are required. Files must be in the following format:
            </Typography>

            <Typography tag="div" italic>
              <ul>
                <li>JPEG or PNG format (PDFs will not work)</li>
                <li>Smaller than 5MB</li>
                <li>In full color</li>
                <li>Rotated with the image right-side up</li>
                <li>All information must be clearly legible.</li>
              </ul>
            </Typography>
          </Col>
        </Row>

        {this.state.loadingImage ? (
          <SpinnerContainer />
        ) : (
          <Row>
            <Col md={6}>
              <Typography variant="bodyMedium">Front</Typography>
              {ownerBeingEdited.stripe_identity_document_front_id ? (
                <Typography variant="bodyMedium">Image uploaded successfully.</Typography>
              ) : (
                <IdentificationModal onChange={this.uploadImage} imageKey="stripe_identity_document_front_id" />
              )}
            </Col>
            <Col md={6}>
              <Typography variant="bodyMedium">Back</Typography>
              {ownerBeingEdited.stripe_identity_document_back_id ? (
                <Typography variant="bodyMedium">Image uploaded successfully.</Typography>
              ) : (
                <IdentificationModal onChange={this.uploadImage} imageKey="stripe_identity_document_back_id" />
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

IdForm.propTypes = {
  ownerBeingEdited: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  inputsDisabled: PropTypes.bool.isRequired,
  setToast: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(connect(null, mapDispatchToProps))(IdForm);
