import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal, FormCard, Button } from 'modules/common';

function RemoveUpsellFlow({ saveUpsellGroup, isPathway = false, onRemoveExistingUpsellFlow, editedUpsellGroup }) {
  const [showRemoveFlowModal, sethowRemoveFlowModal] = useState(false);

  const showConfirmRemoveFlow = () => sethowRemoveFlowModal(true);
  const cancelRemoveFlow = () => sethowRemoveFlowModal(false);

  const confirmRemoveFlow = () => {
    sethowRemoveFlowModal(false);
    saveUpsellGroup(null);
    if(!isPathway && !editedUpsellGroup?._isNew){
      onRemoveExistingUpsellFlow();
    }
  };

  const upsellType = isPathway ? "Pathway" : "Flow";

  return (
    <>
      <FormCard variant="red" classNameBody="p-3">
        {isPathway ?
          <div className="d-flex justify-content-end">
            <Button variant="cancel" onClick={showConfirmRemoveFlow}>
              Remove Upsell {upsellType}
            </Button>
          </div> :
          <Button variant="cancel" onClick={showConfirmRemoveFlow}>
            Remove Upsell {upsellType}
          </Button>}
      </FormCard>
      <ConfirmModal
        show={showRemoveFlowModal}
        title={`Remove Upsell ${upsellType}`}
        buttonText={`Remove Upsell ${upsellType}`}
        buttonColor="error"
        showCancelButton
        handleClose={cancelRemoveFlow}
        onCancel={cancelRemoveFlow}
        onSubmit={confirmRemoveFlow}
      >
        Are you sure you want to remove this Upsell {upsellType} from your page?
      </ConfirmModal>
    </>
  );
}

RemoveUpsellFlow.propTypes = {
  saveUpsellGroup: PropTypes.func.isRequired,
  isPathway: PropTypes.bool,
  onRemoveExistingUpsellFlow: PropTypes.func,
};

export default RemoveUpsellFlow;
