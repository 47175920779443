import gql from 'graphql-tag';

export const EMAIL_FRAGMENT = gql`
  fragment Email on Email {
    revv_uid
    body
    emailEvent
    emailLogo {
      id
      image {
        id
        thumbnailUrl
      }
    }
  }
`;

export const GET_EMAILS_QUERY = gql`
  query getEmails($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      email
      candidate(revvUid: $organizationRevvUid) {
        emails(filters: { page: $page, limit: $limit }) {
          results {
            ...Email
          }
        }
      }
    }
  }
  ${EMAIL_FRAGMENT}
`;

export const SEND_TEST_EMAIL_MUTATION = gql`
  mutation sendTestEmail($attributes: TestEmailInput!, $organizationRevvUid: String!) {
    testEmail(emailAttributes: $attributes, organizationRevvUid: $organizationRevvUid) {
      message
    }
  }
`;

export const UPDATE_EMAIL_MUTATION = gql`
  mutation updateEmail($email: UpdateEmailInput!, $organizationRevvUid: String) {
    updateEmail(emailAttributes: $email, organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
`;
