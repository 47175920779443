import React from 'react';
import { EmptyList } from 'modules/common';

export function EmptyPageList({ activeTabKey, handleCreateNewPage }) {
  if (activeTabKey === 'video') {
    return (
      <EmptyList
        text="No Video Pages"
        description="Create a video page to start raising money."
        buttonText="Create Video Page"
        icon="video"
        iconColor="green"
        name="video"
        onClick={() => handleCreateNewPage('video')}
      />
    );
  }
  if (activeTabKey === 'high_dollar') {
    return (
      <EmptyList
        text="No High Dollar Pages"
        description="Create a high dollar page to start raising money."
        buttonText="Create High Dollar Page"
        icon="donate"
        iconColor="gold"
        name="high-dollar"
        onClick={() => handleCreateNewPage('high-dollar')}
      />
    );
  }

  if (activeTabKey === 'petition') {
    return (
      <EmptyList
        text="No Petition Pages"
        description="Create a petition page to start raising money."
        buttonText="Create Petition Page"
        icon="pen-nib"
        iconColor="gray"
        name="page"
        onClick={() => handleCreateNewPage('petition')}
      />
    );
  }

  if (activeTabKey === 'conduit') {
    return (
      <EmptyList
        text="No Donation Pages"
        description="Create a donation page to start raising money."
        buttonText="Create Donation Page"
        icon="donate"
        iconColor="green"
        name="page"
        onClick={() => handleCreateNewPage('donation')}
      />
    );
  }

  return (
    <EmptyList
      text="No Pages"
      description="Create a donation page to start raising money."
      buttonText="Create Donation Page"
      icon="donate"
      iconColor="green"
      name="page"
      onClick={() => handleCreateNewPage('donation')}
    />
  );
}
