/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Context from './context';

class TableCell extends Component {
  render() {
    const { textAlign, children, className, noClick, ...rest } = this.props;
    const { onClick } = this.context;
    const handlesClick = onClick && !noClick;

    return (
      <td
        role="cell"
        onClick={handlesClick ? onClick : null}
        className={classNames(className, {
          [`text-${textAlign}`]: textAlign,
        })}
        {...rest}
      >
        {children}
      </td>
    );
  }
}

TableCell.propTypes = {
  textAlign: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  noClick: PropTypes.bool,
};

TableCell.defaultProps = {
  textAlign: 'left',
  noClick: false,
};

TableCell.contextType = Context;

export default TableCell;
