import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Context from './context';

class TableRow extends Component {
  static defaultProps = {
    onClick: null,
  };

  render() {
    const { onClick, children, className, innerRef, ...restProps } = this.props;
    return (
      <Context.Provider value={{ onClick }}>
        <tr role={onClick ? 'button' : 'row'} className={className} ref={innerRef} {...restProps}>
          {children}
        </tr>
      </Context.Provider>
    );
  }
}

TableRow.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TableRow;
