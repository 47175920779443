import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './tool-tip.scss';

const toolTipDelay = { show: 250, hide: 400 };

// placement https://popper.js.org/docs/v1/#popperplacements--codeenumcode

function WinRedTooltip({ tooltip, children, className, classNameOverlay = '', ...rest }) {
  if (!tooltip) return <span className={className}>{children}</span>;

  return (
    <span className={className}>
      <OverlayTrigger
        placement="right"
        delay={toolTipDelay}
        overlay={<Tooltip bsPrefix={'tooltip-inner ' + classNameOverlay}>{tooltip}</Tooltip>}
        {...rest}
      >
        {children}
      </OverlayTrigger>
    </span>
  );
}

WinRedTooltip.propTypes = {
  className: PropTypes.string,
  classNameOverlay: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default React.memo(WinRedTooltip);
