import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, SpinnerContainer, Table, Typography } from 'modules/common';
import { CommitteeComponent } from '../committee.component';
import { DuplicateErrorComponent } from './duplicate-page-error.component';

export const DuplicateResultsTableDesktop = ({ page, items, copyUrl, onSubmit, loading }) => {
  const tableBody = items?.map((item, index) => {
    const {
      page_duplication_parameters: { custom_name, custom_slug },
      template,
    } = item;

    const handleOnCopy = () => {
      copyUrl(item?.duplicated_page?.url);
    };

    if (loading.loading && loading.id === item.id) {
      return <SpinnerContainer className="py-5" />;
    }

    return (
      <Fragment key={item.id}>
        <Table.Row key={index} className="p-0" role="link" tabIndex="0">
          <Table.Cell className="align-top">
            <div className="info-container flex items-center">
              <Icon
                icon={item.errors ? 'exclamation-circle' : 'check-circle'}
                color={item.errors ? 'red' : 'green'}
                className="mr-3 text-xl"
              />
              {page?.backgroundAssignedImage ? (
                <div
                  className="page-image-rectangle"
                  style={{ backgroundImage: `url(${page?.backgroundAssignedImage?.image?.thumbnailUrl})` }}
                ></div>
              ) : (
                <div className="page-image-rectangle" style={{ backgroundColor: page?.backgroundColor }}></div>
              )}
              <div className="page-name flex flex-col ml-3">
                <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
                  {custom_name}
                </Typography>
                <Typography className={`mb-0 ${item.errors ? 'text-winred-red' : ''}`}>{custom_slug}</Typography>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="align-top">
            {template?.partnership_templates.map((template, index) => {
              return <CommitteeComponent item={template} key={index} isResults />;
            })}
          </Table.Cell>
          <Table.Cell className="align-top">
            {template?.upsell_agency_fees_enabled ? (
              <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center mr-2">
                Upsell
              </div>
            ) : null}
            {template?.recurring_agency_fees_enabled ? (
              <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center">
                Subscription
              </div>
            ) : null}
          </Table.Cell>
          <Table.Cell className="align-top">
            <Button skinnyButton onClick={handleOnCopy} variant="primary" disabled={item.errors}>
              Copy URL
            </Button>
          </Table.Cell>
        </Table.Row>
        {item?.errors ? <DuplicateErrorComponent data={item} onSubmit={onSubmit} /> : null}
      </Fragment>
    );
  });

  return (
    <Table data-testid="my-pages-index" className="pages-index-desktop my-4">
      <Table.Head className="duplicate-results-header">
        <Table.Row>
          <Table.HeaderCell className="text-center">Page Information</Table.HeaderCell>
          <Table.HeaderCell className="text-center">Vendor</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
};

DuplicateResultsTableDesktop.propTypes = {
  page: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  copyUrl: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
};
