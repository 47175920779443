import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';

import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { GET_UPSELL_CAMPAIGNS, GET_ORGANIZATION_UPSELL_CAMPAIGNS, skipQuery } from 'core/middleware/queries';
import { FormCardSpaced, SpinnerContainer, Error } from 'modules/common';
import { ConnectedUpsellCommittee } from '../';
import LogoImage from './logo-image.component';
import UpsellAmountMultiplier from '../upsell-amount-multiplier.component';
import { Campaign } from 'modules/pages/common/campaign.component';
import { formatCampaignOptions } from 'modules/pages/common/page-common.tools';

const addCommitteeDescription = `Donations made through this card will be split evenly among committees added unless otherwise designated by donors. To add a committee, click "Add committee" and search for the committee by name.`;

function SlateUpsell({ upsellCard, onUpdateEditedUpsell, data }) {
  if (data.loading) {
    return (
      <div className="mb-5">
        <SpinnerContainer />
      </div>
    );
  }
  if (data.error || data.errors) return <Error error={data.error || data.errors} />;

  return (
    <div className="mt-3 pb-4 px-4">
      <ConnectedUpsellCommittee
        onChange={onUpdateEditedUpsell}
        selectedCandidates={upsellCard.candidates}
        disabled={upsellCard._isDisabled}
        addCommitteeDescription={addCommitteeDescription}
        className="pb-3"
        splitEvenly={upsellCard.split_evenly}
        showConduitingAndDataSharingToggle={!upsellCard._isPreset}
      />

      <FormCardSpaced>
        <LogoImage
          upsellCard={upsellCard}
          onUpdateEditedUpsell={onUpdateEditedUpsell}
          subtitle="Add an image to your upsell card. The recommended image size is 1041x1041. If no image is added, the system will use the Committee's avatar."
        />
      </FormCardSpaced>

      <UpsellAmountMultiplier
        editedUpsell={upsellCard}
        onUpdateEditedUpsell={onUpdateEditedUpsell}
        maxDonationAmounts={3}
      />
  
      <Campaign
        item={upsellCard}
        onChange={onUpdateEditedUpsell}
        campaignOptions={data.campaignOptions}
        subtitle="Select a campaign for this upsell card."
        inUpsells
      />
    </div>
  );
}

SlateUpsell.propTypes = {
  upsellCard: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
  profileCandidate: profileCandidateSelector(state),
});

export default compose(
  connect(mapStateToProps),
  graphql(GET_UPSELL_CAMPAIGNS, {
    // XXX: Fix me for money pledges
    options: () => ({ variables: { limit: 9999 } }),
    props: ({ data }) => formatCampaignOptions(data),
    skip: props => skipQuery({ props }),
  }),
  graphql(GET_ORGANIZATION_UPSELL_CAMPAIGNS, {
    // XXX: Fix me for money pledges
    options: props => ({
      variables: {
        limit: 9999,
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
      },
    }),
    props: ({ data }) => formatCampaignOptions(data),
    skip: props => skipQuery({ props, isOrganizationQuery: true }),
  })
)(SlateUpsell);
