import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';
import { getRouteByOrgType } from 'core/utilities';

function NavBarMenuUtilities({
  toggleSubNav,
  hasActiveChildUtilities,
  profileCandidate,
  showMobileSubNav,
  organizationRevvUid,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true) || (isAgency && !(profileCandidate.isAdmin || profileCandidate.isEditor)))
    return null;

  const integrationsRoute = getRouteByOrgType(organizationRevvUid, isAgency, '/utilities/integrations');
  const dataExportsRoute = getRouteByOrgType(organizationRevvUid, isAgency, '/utilities/exports');
  const customReportsRoute = getRouteByOrgType(organizationRevvUid, isAgency, '/utilities/custom-reports');

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildUtilities,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showUtilitiesSubNav')}>
          Utilities
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size={'xs'}
            color={hasActiveChildUtilities ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>

      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        {profileCandidate.org_approved_by_win_red || profileCandidate.org_approved_by_revv ? (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/utilities/emails`}
            href={`/${organizationRevvUid}/utilities/emails`}
          >
            Emails
          </Nav.Link>
        ) : null}

        {!isAgency ? (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/utilities/notifications`}
            href={`/${organizationRevvUid}/utilities/notifications`}
          >
            Notifications
          </Nav.Link>
        ) : null}

        <Nav.Link eventKey={integrationsRoute} href={integrationsRoute}>
          Integrations
        </Nav.Link>

        <Nav.Link eventKey={dataExportsRoute} href={dataExportsRoute}>
          Data Exports
        </Nav.Link>
        {!isAgency ? (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/utilities/media`}
            href={`/${organizationRevvUid}/utilities/media`}
          >
            Media Library
          </Nav.Link>
        ) : null}

        <Nav.Link eventKey={customReportsRoute} href={customReportsRoute}>
          Custom Reports
        </Nav.Link>

        {!isAgency ? (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/utilities/bulk-actions`}
            href={`/${organizationRevvUid}/utilities/bulk-actions`}
          >
            Bulk Actions
          </Nav.Link>
        ) : null}

        {profileCandidate.ab_tests_feature_enabled && (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/utilities/ab-testing`}
            href={`/${organizationRevvUid}/utilities/ab-testing`}
          >
            A/B Tests
          </Nav.Link>
        )}
      </MobileSubMenu>
    </>
  );
}

NavBarMenuUtilities.propTypes = {
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildUtilities: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
};

export default NavBarMenuUtilities;
