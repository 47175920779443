import gql from 'graphql-tag';

export const CREATE_ORGANIZATION_BANK_ACCOUNT = gql`
  mutation createOrganizationBankAccount($bankAccount: BankAccountInput!, $organizationRevvUid: String!) {
    createOrganizationBankAccount(bankAccount: $bankAccount, organizationRevvUid: $organizationRevvUid) {
      errors
      bankAccount {
        revv_uid
        account_holder_name
        routing_number
        last4
        account_holder_type
        bank_name
        status
        currency
        country
        default_for_currency
      }
    }
  }
`;

export const DEFAULT_ORGANIZATION_BANK_ACCOUNT = gql`
  mutation defaultOrganizationBankAccount($revv_uid: String!, $organizationRevvUid: String!) {
    defaultOrganizationBankAccount(revvUid: $revv_uid, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;

export const REMOVE_ORGANIZATION_BANK_ACCOUNT = gql`
  mutation removeOrganizationBankAccount($revv_uid: String!, $organizationRevvUid: String!) {
    removeOrganizationBankAccount(revvUid: $revv_uid, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;

export const TRIGGER_ORGANIZATION_PAYOUT_MUTATION = gql`
  mutation triggerOrganizationPayout {
    triggerPayout {
      errors
    }
  }
`;
