import { createFilter } from 'react-select';
import uuid from 'core/utilities/uuid';
import {
  adjustTimeForConsistency,
  convertEpochDate,
  formatDateTime,
  isBeforeDay,
  MOMENT_FORMAT_IN_FNS,
  convertEpochToDate,
  getCandidateDate,
  convertEpochTime,
} from 'core/utilities/time';

export const formatMoneyPledgeForUi = moneyPledge => {
  const formattedMoneyPledge = {
    ...moneyPledge,
    money_pledge_date: convertEpochDate(moneyPledge.money_pledge_date),
  };

  return formattedMoneyPledge;
};

export const formatMoneyPledgeForServer = moneyPledge => {
  const newMoneyPledge = {
    initiativeType: 'MONEYPLEDGE',
    name: moneyPledge.name,
    publicName: moneyPledge.public_name,
    emailBodyText: moneyPledge.email_body_text,
  };

  /* [ch21153] Send in the candidate timezone offset at the end of the date time
  string and set to 5pm UTC (12pm EST) to keep all client dates the same. This sets
  it for Apollo and also fixes a bug for us. */
  const selectedDate = !moneyPledge._isNew ? new Date(moneyPledge.money_pledge_date) : moneyPledge.money_pledge_date;
  newMoneyPledge.moneyPledgeDate = formatDateTime(adjustTimeForConsistency(selectedDate), MOMENT_FORMAT_IN_FNS);

  if (!moneyPledge._isNew) newMoneyPledge.revv_uid = moneyPledge.revv_uid;

  return newMoneyPledge;
};

export const generateNewMoneyPledge = () => ({
  revv_uid: uuid(),
  _isNew: true,
  name: '',
  public_name: '',
  emailBodyOverride: '',
});

export const getIconColor = moneyPledgeDate => (isProcessed(moneyPledgeDate) ? 'gray' : 'green');

export const isProcessed = moneyPledgeDate => isBeforeDay(convertEpochToDate(moneyPledgeDate), getCandidateDate());

export const moneyPledgesDropdownFilter = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
});

export const getMoneyPledgeCardTitle = pledge => {
  switch (pledge.status) {
    case 'PLEDGED':
    case 'FULFILLED':
    case 'PROCESSED':
      return `${pledge.money_pledge_internal_name || 'Money Pledge'}`;
    case 'CANCELED':
      return 'Canceled Money Pledge';
    case 'FAILED':
      return 'Failed Money Pledge';
    default:
      return `${pledge.money_pledge_internal_name || 'Money Pledge'}`;
  }
};

export const getMoneyPledgeIcon = pledge => {
  switch (pledge.status) {
    case 'PLEDGED':
      return 'money-pledge';
    case 'FULFILLED':
      return 'money-pledge';
    case 'PROCESSED':
      return 'money-pledge';
    case 'CANCELED':
      return 'cancelled-money-pledge';
    case 'FAILED':
      return 'money-pledge';
    default:
      return 'money-pledge';
  }
};

export const getMoneyPledgeProcessCopy = pledge => {
  switch (pledge.status) {
    case 'FULFILLED':
    case 'PROCESSED':
    case 'CANCELED':
    case 'FAILED':
      return 'Processed';
    case 'PLEDGED':
      return 'Processing';
    default:
      return 'Processing';
  }
};

export const getMoneyPledgeColor = pledge => {
  if (!pledge.status) return 'green';
  switch (pledge.status) {
    case 'PLEDGED':
    case 'FULFILLED':
    case 'PROCESSED':
      return 'green';
    case 'CANCELED':
    case 'FAILED':
      return 'gray';
    default:
      return 'gray';
  }
};

export const cancelMoneyPledgeEmail = (moneyPledge, profile, donor) => {
  window.open(
    `mailto:support@winred.com
        ?subject=Requesting Money Pledge cancellation for ${moneyPledge.revv_uid}
        &body=
I am requesting a cancellation for the following money pledge:%0D%0A
Committee: ${profile.candidate.name}%0D%0A
Pledge ID: ${moneyPledge.revv_uid}%0D%0A
Date Created: ${convertEpochTime(moneyPledge.created_at)}%0D%0A
Donor Name: ${donor.first_name} ${donor.last_name}%0D%0A
Email: ${donor.email}`,
    '_blank'
  );
};

export const requestCancelMoneyPledgeText =
  'Money Pledges made through split conduit pages cannot be canceled from the WinRed portal. A member of the WinRed team must cancel the Money Pledges for you. Click Send Request to generate an email with all the details to send to support@winred.com.';
