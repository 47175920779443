import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { Card } from 'modules/common';
import RequiredLabel from './required.component';
import '../custom.scss';

function CustomFieldMultiChoicePreview({ formState, disabled }) {
  const [defaultValue, setDefaultValue] = useState(formState.default_value || []);
  useEffect(() => setDefaultValue(formState.default_value || []), [formState.default_value]);

  const [otherValue, setOtherValue] = useState('');

  if (!formState) return null;
  const checkboxOptions = formState.options || [];
  const isOtherChecked = defaultValue.includes('other');

  return (
    <div>
      <Card.Title className="preview-title">
        {formState.label}
        <RequiredLabel formState={formState} />
      </Card.Title>
      {checkboxOptions.map(radioOption => {
        if (!radioOption.value) return null;
        const isChecked = defaultValue.includes(radioOption.key);

        return (
          <Form.Check
            key={radioOption.key}
            type="checkbox"
            disabled={disabled}
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                setDefaultValue(defaultValue.filter(val => val !== radioOption.key));
              } else {
                setDefaultValue([...defaultValue, radioOption.key]);
              }
            }}
            label={radioOption.value}
          />
        );
      })}
      {formState.other_field_enabled && (
        <div>
          <Form.Group controlId="formOtherOption" className="other-field-input-preview">
            <Form.Check
                type="checkbox"
                id="otherCheckbox"
                disabled={disabled}
                checked={isOtherChecked}
                onChange={() => {
                  if (isOtherChecked) {
                    setDefaultValue(defaultValue.filter(val => val !== 'other'));
                  } else {
                    setDefaultValue([...defaultValue, 'other']);
                  } 
                }}
            />
            <Form.Control
                type="text"
                placeholder="Other"
                disabled={disabled}
                value={otherValue}
                onChange={e => setOtherValue(e.target.value)}
                style={{ height: '30px' }}
            />
          </Form.Group>
        </div>
      )}      
    </div>
  );
}

CustomFieldMultiChoicePreview.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default CustomFieldMultiChoicePreview;
