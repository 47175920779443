import gql from 'graphql-tag';

export const donorFragments = {
  donors: gql`
    fragment Donors on CandidateDonorResult {
      current_page
      page_count
      total_count

      results {
        created_at
        employer
        occupation
        is_revv_user
        email
        phone
        revv_uid

        first_name
        last_name
        pac_name
        business_name

        contributor_type
        user_type

        donations(limit: 1) {
          results {
            amount
          }
        }
      }
    }
  `,
  donor: gql`
    fragment Donor on CandidateDonorResult {
      results {
        revv_uid
        created_at
        employer
        occupation
        employer_address
        employer_city
        employer_state
        employer_zip
        email
        phone
        mobile
        address
        city
        state
        zip

        first_name
        last_name
        pac_name
        business_name
        fec_id

        contributor_type
        user_type
        type_of_business
        tax_id

        shipping_address
        shipping_city
        shipping_state
        shipping_zip

        card_last4
        card_brand
        card_expiry
        source_code
        donations {
          results {
            created_at
            first_name
            last_name
            amount
            source_url
            email
            phone
            source_code
            recurring
            charge_revv_uid
            revv_uid
            card_expiry
            card_last4
            card_brand

            charge_status
            refunded_amount
            disputed_amount
            voided_at

            refunds {
              amount_refunded
              refunded_at
            }
            disputes {
              amount_disputed
              disputed_at
            }
          }
        }
        subscriptions {
          results {
            created_at
            canceled_at
            interval
            first_name
            last_name
            amount
            source_url
            email
            phone
            source_code
            recurring
            revv_uid
            charge_revv_uid
            card_expiry
            card_last4
            card_brand
            subscription_status
          }
        }

        pledges {
          results {
            revv_uid
            created_at
            money_pledge_internal_name
            processing_date
            status
            conduit_amount_cents
            canceled_at
          }
        }
      }
    }
  `,
};

export const GET_DONORS_REPORT = gql`
  query getDonorsReport(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: Predicate
    $isRevvUser: Boolean
    $search: String
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_donors_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            isRevvUser: $isRevvUser
            search: $search
            contributorType: $contributorType
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_DONORS_QUERY = gql`
  query getDonors(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: Predicate
    $isRevvUser: Boolean
    $search: String
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        donors(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            isRevvUser: $isRevvUser
            search: $search
            contributorType: $contributorType
          }
        ) {
          ...Donors
        }
      }
    }
  }
  ${donorFragments.donors}
`;

export const GET_DONOR_QUERY = gql`
  query getDonor($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        donors(filters: { revvUid: $revv_uid }) {
          ...Donor
        }
      }
    }
  }
  ${donorFragments.donor}
`;
