import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { Icon, MoneyInput, Select, Card, Typography, FloatGroup } from 'modules/common';
import { getMinimumPriceCents } from '../tools';

function Product({ product, updateProduct, removeProduct, productFieldOptions, allowableOptions }) {
  const onSelectItem = ({ target: { value } }) => {
    const selectedProduct = productFieldOptions.find(opt => opt.value === value);
    updateProduct(
      { target: { name: 'merch_product', value: { name: selectedProduct.label, revv_uid: selectedProduct.value } } },
      product,
      selectedProduct
    );
  };

  const onSelectDonationAmount = ({ target: { value } }) => {
    updateProduct({ target: { name: 'merchandise_minimum_price_cents', value } }, product);
  };

  const selectedDropdownOption = productFieldOptions.find(o => o.value === product.merch_product.revv_uid);

  return (
    <div className="mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Row className="align-items-center">
                {/* selected product form */}
                {selectedDropdownOption ? (
                  <>
                    <Col>
                      <div className="d-flex justify-content-between">
                        <Typography variant="h5">
                          {selectedDropdownOption.label}&nbsp;(Min: ${getMinimumPriceCents(selectedDropdownOption)},{' '}
                          {selectedDropdownOption.variants_count} SKU)
                        </Typography>
                        <div className="pointer mr-2">
                          <Icon icon="times" size="lg" onClick={() => removeProduct(product)} />
                        </div>
                      </div>
                      <FloatGroup className="prepend-label-dollar">
                        <MoneyInput
                          isCents
                          placeholder="Minimum Donation Amount"
                          id="merchandise_minimum_price_cents"
                          name="merchandise_minimum_price_cents"
                          value={product.merchandise_minimum_price_cents}
                          validators={['required']}
                          errorMessages={['Minimum donation amount is required.']}
                          onChange={onSelectDonationAmount}
                        />
                      </FloatGroup>
                    </Col>
                  </>
                ) : null}

                {/* new product form */}
                {selectedDropdownOption ? null : (
                  <>
                    <Col>
                      <Typography className="mb-1" variant="h5">
                        Select a Product
                      </Typography>
                      <Select
                        name="name"
                        onChange={onSelectItem}
                        value={selectedDropdownOption}
                        options={allowableOptions}
                        placeholder="Select a product..."
                        validators={['required']}
                        errorMessages={['Product Name is required.']}
                      />
                    </Col>
                    <Col xs="auto" className="ml-auto mt-1">
                      <Icon
                        icon="times"
                        size="1x"
                        className="pointer custom-close-btn mr-2"
                        onClick={() => removeProduct(product)}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  productFieldOptions: PropTypes.array.isRequired,
  updateProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default Product;
