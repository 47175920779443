import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TableInputMove, TableInputContainer, TableInput, WinRedSelect, WinRedInput } from 'modules/common';

const fieldNameValidator = [{ message: 'Cell Value is required.', validator: 'required' }];
const nameValidator = [{ message: 'Column Header is required.', validator: 'required' }];

function ReportFieldRow({ report, row, columnOptions, onChange }) {
  const handleColumnChange = useCallback(
    ({ id, key, value }) => {
      onChange({
        target: {
          name: 'report_columns',
          value: [
            ...report.report_columns.map(column => {
              if (id !== column.id) return column;

              return { ...column, [key]: value };
            }),
          ],
        },
      });
    },
    [report.report_columns, onChange]
  );

  const onRemove = useCallback(
    () => handleColumnChange({ id: row.id, key: 'destroy', value: true }),
    [row, handleColumnChange]
  );

  const fieldName = useMemo(() => columnOptions.find(o => o.value === row.key), [columnOptions, row.key]);
  const fieldNameDisabled = useMemo(() => (row.static_value || '').length > 0, [row.static_value]);

  return (
    <TableInputContainer onRemove={onRemove}>
      <TableInputMove />
      <TableInput lg={4}>
        <WinRedSelect
          disabled={(row.static_value || '').length > 0}
          name="key"
          options={columnOptions}
          placeholder="Cell Value"
          validators={fieldNameValidator}
          value={fieldNameDisabled ? fieldName?.label : fieldName}
          onChange={({ target: { name, value } }) => handleColumnChange({ id: row.id, key: name, value })}
        />
      </TableInput>
      <TableInput>
        <WinRedInput
          name="name"
          placeholder="Column Header"
          validators={nameValidator}
          value={row.name}
          onChange={({ target: { name, value } }) => handleColumnChange({ id: row.id, key: name, value })}
        />
      </TableInput>
      <TableInput>
        <WinRedInput
          name="static_value"
          value={row.static_value}
          placeholder="Static Cell Value"
          onChange={({ target: { name, value } }) => handleColumnChange({ id: row.id, key: name, value })}
        />
      </TableInput>
    </TableInputContainer>
  );
}

ReportFieldRow.propTypes = {
  report: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  columnOptions: PropTypes.array.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportFieldRow };
