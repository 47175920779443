import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { Card, FloatGroup, Typography, Input, ImagePrompt, ConfirmModal, FormCard } from 'modules/common';
import { isValidURL, isMp4File } from 'core/utilities';
import 'modules/pages/pages.scss';

const MAX_FILE_SIZE = 1 * 1024 * 1024;

function OpenGraph({ onChange, data, ogImage, dataType = 'page', disabled, ogValidations }) {
  const [showRemoveOgImageModal, setShowRemoveOgImageModal] = useState(false);

  // if ogAssignedImage exists, then find image thumbnail url to show in preview
  const ogAssignedImage = data?.ogAssignedImage?.image;
  const imageResults = (ogImage?.viewer.candidate || ogImage?.viewer.state_level_organization)?.images.results || null;

  const previewImage = useMemo(() => {
    if (!ogAssignedImage && !imageResults) {
      return null;
    }
    return (
      ogAssignedImage ||
      imageResults?.find(
        image =>
          image.id === ogAssignedImage?.id ||
          (image.id === data?.ogAssignedImage?.imageId && !data?.ogAssignedImage?.destroy)
      )
    )?.thumbnailUrl;
  }, [ogAssignedImage, imageResults, data]);

  return (
    <FormCard
      title="Open Graph Settings"
      subtitle={`Open Graph tags are what populate the preview when your ${dataType} is shared on social media. All WinRed ${dataType}s
              have default settings, but you can override them. Look at the preview below to see what your ${dataType} will
              look like when it is shared on social media.`}
    >
      <FloatGroup>
        <Row>
          <Col md={8} lg={6} xl={5}>
            <Card className="og-preview">
              <div className="og-preview-image">
                {!previewImage ? (
                  <img
                    src="https://secure.winred.com/assets/win-red-og-image-896631482b4ad813cad4aa21149ea1f85e901f5e52f94c5cfbdf59e7cefabe4d.png"
                    alt="Open Graph"
                  />
                ) : (
                  <img src={previewImage} alt="Open Graph" />
                )}
              </div>
              <Card.Body>
                <Card.Title className="preview-title">
                  {data?.ogTitle || "WinRed - Conservatives' #1 fundraising technology"}
                </Card.Title>
                <Typography className="mb-0 d-flex flex-column">
                  <span className="og-preview-description">
                    {data?.ogDescription ||
                      'WinRed - Our technology changes how conservative & center-right groups fundraise online. Join now to start winning in 2020!'}
                  </span>
                  <span>secure.winred.com</span>
                </Typography>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </FloatGroup>

      <FloatGroup className="mt-4">
        <Input
          type="text"
          name="ogTitle"
          placeholder="Open Graph Title"
          value={data?.ogTitle || ''}
          onChange={onChange}
          disabled={disabled}
          validators={ogValidations?.ogTitle?.validators}
          errorMessages={ogValidations?.ogTitle?.errorMessages}
        />
      </FloatGroup>

      <FloatGroup className="mt-4">
        <Input
          type="text"
          name="ogDescription"
          maxLength="150"
          placeholder="Open Graph Description"
          value={data?.ogDescription || ''}
          onChange={onChange}
          disabled={disabled}
          description="We recommend using less than 150 characters."
          validators={ogValidations?.ogDescription?.validators}
          errorMessages={ogValidations?.ogDescription?.errorMessages}
        />
      </FloatGroup>

      <FloatGroup className="mt-4 mb-4">
        <Input
          type="text"
          name="ogVideo"
          placeholder="Open Graph Video URL"
          value={data?.ogVideo || ''}
          validateOnBlurOnly
          disabled={disabled}
          onChange={onChange}
          validators={[isValidURL, isMp4File]}
          errorMessages={['A valid URL is required.', 'Must be a direct link to a mp4 file.']}
          description="This must be a direct link to an .mp4 file, using https. Must be less than 3MB."
        />
      </FloatGroup>

      <Card.Title>Open Graph Image</Card.Title>
      <Typography>
        Upload an image to populate the preview image for most social media networks. Make sure your image is at least
        1200px by 630px and has a 1.9:1 aspect ratio. Otherwise, we will crop it to fit social media platform standards.
      </Typography>

      <ImagePrompt
        imageUri={previewImage}
        maxFileSize={MAX_FILE_SIZE}
        disabled={disabled}
        name="ogAssignedImage"
        selectImageAction={onChange}
        imageType="OG_IMAGE"
        removeImageAction={() => setShowRemoveOgImageModal(true)}
        validators={ogValidations?.ogAssignedImage?.validators}
        errorMessages={ogValidations?.ogAssignedImage?.errorMessages}
        description={ogValidations?.ogAssignedImage?.description}
      />

      <ConfirmModal
        show={showRemoveOgImageModal}
        title="Remove OG Image"
        buttonText="Remove"
        handleClose={() => setShowRemoveOgImageModal(false)}
        buttonColor="cancel"
        onSubmit={() => {
          const value = {
            destroy: true,
            imageId: data.ogAssignedImage?.image?.id,
          };

          onChange({ target: { name: 'ogAssignedImage', value } });
          setShowRemoveOgImageModal(false);
        }}
      >
        Are you sure you want to remove this Open Graph Image?
      </ConfirmModal>
    </FormCard>
  );
}

OpenGraph.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  ogImage: PropTypes.object,
  dataType: PropTypes.string,
  ogValidations: PropTypes.object,
};

export default OpenGraph;
