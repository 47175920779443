import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import './table-inputs.scss';
import TableInputRemove from './table-input-remove.component';

function TableInputContainer({ onRemove, children, ...rest }) {
  return (
    <Row className="table-input-container" {...rest}>
      {children}
      {onRemove ? <TableInputRemove onRemove={onRemove} /> : null}
    </Row>
  );
}

TableInputContainer.propTypes = {
  lastItem: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TableInputContainer;
