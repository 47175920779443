import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuBillpays({ removeAccess, pathname, handleSelect, organizationRevvUid, showBillpaysSubNav }) {
  if (removeAccess.includes(true)) return null;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for billpays menu"
      showSubmenu={showBillpaysSubNav}
    >
      <NavList.Item
        eventKey={`/${organizationRevvUid}/vendors/billpays/pages`}
        href={`/${organizationRevvUid}/vendors/billpays/pages`}
      >
        Bill-Pays on Pages
      </NavList.Item>

      <NavList.Item
        eventKey={`/${organizationRevvUid}/vendors/billpays/upsells`}
        href={`/${organizationRevvUid}/vendors/billpays/upsells`}
      >
        Bill-Pays on Upsells
      </NavList.Item>
    </SubMenuContainer>
  );
}

NavBarSubMenuBillpays.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showBillpaysSubNav: PropTypes.bool.isRequired,
};

export default NavBarSubMenuBillpays;
