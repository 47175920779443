import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'modules/common';

function MoneyPledge({ editedUpsell, onUpdateEditedUpsell, moneyPledgeOptions }) {
  const selectedMoneyPledge = moneyPledgeOptions.find(
    moneyPledge => moneyPledge.value.revv_uid === editedUpsell.money_pledge_campaign?.revv_uid
  );
  return (
    <Select
      name="money_pledge_campaign"
      isClearable
      label="Money Pledge"
      onChange={onUpdateEditedUpsell}
      disabled={editedUpsell._isDisabled}
      value={selectedMoneyPledge}
      options={moneyPledgeOptions}
      placeholder="Select a money pledge..."
      validators={['required']}
      errorMessages={['Money Pledge is required.']}
    />
  );
}

MoneyPledge.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  className: PropTypes.string,
  moneyPledgeOptions: PropTypes.array,
  subtitle: PropTypes.string,
};

MoneyPledge.defaultProps = {
  moneyPledgeOptions: [],
  subtitle: 'Select a money pledge for this upsell.',
};

export default MoneyPledge;
