import React from 'react';
import PropTypes from 'prop-types';

import { NavList } from 'modules/common';
import SubMenuContainer from './submenu-container.component';
import '../../nav-bar.scss';

function NavBarSubMenuPeople({
  pathname,
  handleSelect,
  organizationRevvUid,
  showPeopleSubNav,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  const donorRoute = isAgency
    ? `/${organizationRevvUid}/vendors/people/donors`
    : `/${organizationRevvUid}/people/donors`;
  const leadRoute = isAgency ? `/${organizationRevvUid}/vendors/people/leads` : `/${organizationRevvUid}/people/leads`;

  return (
    <SubMenuContainer
      pathname={pathname}
      handleSelect={handleSelect}
      label="submenu for people menu"
      showSubmenu={showPeopleSubNav}
    >
      <NavList.Item eventKey={donorRoute} href={donorRoute}>
        Donor Profiles
      </NavList.Item>
      <NavList.Item eventKey={leadRoute} href={leadRoute}>
        Leads
      </NavList.Item>
    </SubMenuContainer>
  );
}

NavBarSubMenuPeople.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  organizationRevvUid: PropTypes.string.isRequired,
  showPeopleSubNav: PropTypes.bool.isRequired,
};

export default NavBarSubMenuPeople;
