import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import { Col } from 'react-bootstrap';
import { Icon } from 'modules/common';
import './table-inputs.scss';

function TableInputRemove({ onRemove, className = '' }) {
  return (
    <Col xs="auto" className={classname('ml-auto input-remove-btn', className)}>
      <div className="pointer no-outline" onClick={onRemove} onKeyPress={onRemove} role="button" tabIndex={0}>
        <Icon icon="times" color="gray" size="lg" />
      </div>
    </Col>
  );
}

TableInputRemove.propTypes = {
  onRemove: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TableInputRemove;
