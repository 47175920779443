import amplitude from 'amplitude-js';
import { AmplitudeProvider } from 'react-amplitude-hooks';

export { amplitude, AmplitudeProvider };

export function setAmplitudeUser(profile, isOrganization) {
  if (profile.revv_uid) amplitude.getInstance().setUserId(profile.revv_uid);

  // The candidate can be empty if this is a donor user.
  if (!profile.candidate) {
    let identify = new amplitude.Identify();
    amplitude.getInstance().identify(identify);
    return;
  }

  let identify = new amplitude.Identify().set('candidate', profile.candidate?.name || 'Donor User');
  if (isOrganization !== null) identify = identify.set('Level', isOrganization ? 'State' : 'Federal');

  // set or unset these values based on if user has a value for these
  if (profile.candidate.office) {
    identify = identify.set('office', profile.candidate.office);
  } else {
    identify = identify.unset('office');
  }

  if (profile.candidate.state) {
    identify = identify.set('state', profile.candidate.state);
  } else {
    identify = identify.unset('state');
  }

  if (profile.candidate.district) {
    identify = identify.set('district', profile.candidate.district);
  } else {
    identify = identify.unset('district');
  }

  if (profile.candidate.election_year) {
    identify = identify.set('election year', profile.candidate.election_year);
  } else {
    identify = identify.unset('election year');
  }

  console.debug({ identify });
  amplitude.getInstance().identify(identify);
}

export const setAmplitudeNull = () => {
  amplitude.getInstance().setUserId(null);
};

export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
