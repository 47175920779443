import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Icon, Typography, useAmplitude } from 'modules/common';
import { profileSelector } from 'core/login';
import Logout from '../../logout.component';
import './candidate-drawer.scss';

export function CandidateDrawerFooter() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logEvent] = useAmplitude();
  const history = useHistory();
  const profile = useSelector(profileSelector);

  const onLogout = () => setShowLogoutModal(true);

  const handleLogout = isLoggingOut => {
    if (isLoggingOut) history.push('/logout');
    setShowLogoutModal(false);
  };

  const onUserProfile = () => {
    window.open(process.env.REACT_APP_ACCOUNT_URL, '_blank');
    logEvent(`navbar click my account`);
  };

  return (
    <div className="pb-4 mt-3 candidate-drawer-footer">
      <div className="d-flex justify-content-between align-items-center candidate-menu-footer">
        <div className="d-flex align-items-center pl-1">
          <div className="user-icon-container">
            <Icon icon="user" />
          </div>
          <Typography className="mb-0" variant="bodyMedium">
            {profile?.full_name || ''}
          </Typography>
        </div>
      </div>

      <div role="link" tabIndex="0">
        <a
          href={process.env.REACT_APP_SUPPORT_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 mt-3 c-lynx no-underline hover:no-underline"
        >
          <div className="pl-3">
            <Typography className="d-flex align-items-center">
              <Icon icon="life-ring" size="lg" color="#627890" className="mr-1" />
              <span className="ml-2">WinRed Help Center</span>
            </Typography>
          </div>
        </a>
      </div>

      <div className="d-flex pointer" onClick={onUserProfile} onKeyPress={onUserProfile} role="link" tabIndex="0">
        <div className="mr-3 pl-3">
          <Typography className="d-flex align-items-center">
            <Icon icon="cogs" size="lg" />
            <span className="ml-2">User Settings</span>
          </Typography>
        </div>
      </div>

      <div className="d-flex pointer" onClick={onLogout} onKeyPress={onLogout} role="link" tabIndex="0">
        <div className="mr-3 pl-3">
          <Typography className="d-flex align-items-center">
            <Icon icon="door-open" size="lg" />
            <span className="ml-2">Sign Out</span>
          </Typography>
        </div>
      </div>
      <Logout handleLogout={handleLogout} showLogoutModal={showLogoutModal} />
    </div>
  );
}
