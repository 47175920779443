import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';

import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { SpinnerContainer, Error } from 'modules/common';
import { GET_UPSELL_CAMPAIGNS, GET_ORGANIZATION_UPSELL_CAMPAIGNS, skipQuery } from 'core/middleware/queries';

import { Campaign } from 'modules/pages/common/campaign.component';
import { formatCampaignOptions } from 'modules/pages/common/page-common.tools';
import { StandardUpsell } from './';

function StandardUpsellCampaigns({ upsellCard, onUpdateEditedUpsell, data }) {
  if (data.loading) {
    return (
      <div className="mb-5">
        <SpinnerContainer />
      </div>
    );
  }

  if (data.error || data.errors) return <Error error={data.error || data.errors} />;

  return (
    <StandardUpsell upsellCard={upsellCard} onUpdateEditedUpsell={onUpdateEditedUpsell}>
      <Campaign
        item={upsellCard}
        onChange={onUpdateEditedUpsell}
        campaignOptions={data.campaignOptions}
        className="mb-3"
        subtitle="Select a campaign for this upsell card."
        inUpsells
      />
    </StandardUpsell>
  );
}

StandardUpsellCampaigns.propTypes = {
  upsellCard: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

StandardUpsellCampaigns.defaultProps = {
  campaignOptions: [],
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
  profileCandidate: profileCandidateSelector(state),
});

export default compose(
  connect(mapStateToProps),
  graphql(GET_UPSELL_CAMPAIGNS, {
    options: () => ({ variables: { limit: 9999 } }),
    props: ({ data }) => formatCampaignOptions(data),
    skip: props => skipQuery({ props }),
  }),
  graphql(GET_ORGANIZATION_UPSELL_CAMPAIGNS, {
    options: props => ({
      variables: {
        limit: 9999,
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
      },
    }),
    props: ({ data }) => formatCampaignOptions(data),
    skip: props => skipQuery({ props, isOrganizationQuery: true }),
  })
)(StandardUpsellCampaigns);
