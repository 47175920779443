import gql from 'graphql-tag';

export const GET_IMAGES_QUERY = gql`
  query getImages(
    $imageType: [ImageTypeEnum!]
    $organizationRevvUid: String
    $limit: Int
    $sort: SortDirectionEnum
    $sort_by: ImageSortByEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        images(filters: { imageType: $imageType, limit: $limit, sort: $sort, sortBy: $sort_by }) {
          results {
            id
            imageFileName
            created_at
            originalUrl
            thumbnailUrl
            assignedImages {
              id
              created_at
              imageAssignableType
              imageAssignableId
            }
          }
        }
      }
    }
  }
`;

export const UPLOAD_IMAGE_MUTATION = gql`
  mutation uploadImageMutation($newImage: File!, $organizationRevvUid: String!, $imageType: ImageTypeEnum!) {
    candidateUploadImage(
      organizationRevvUid: $organizationRevvUid
      image: { imageType: $imageType, image: $newImage }
    ) {
      image {
        id
        imageFileName
        created_at
        originalUrl
        thumbnailUrl
        assignedImages {
          id
          created_at
          imageAssignableType
          imageAssignableId
        }
      }
      errors
    }
  }
`;
