import gql from 'graphql-tag';

export const GET_WEBHOOK_AVAILABLE_INTEGRATION_FIELDS = gql`
  query getAvailableIntegrationFields(
    $organizationRevvUid: String
    $fieldEvent: IntegrationFieldEventEnum
    $availableFor: AvailableIntegrationFieldForEnum
    $source: IntegrationSourceTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        available_integration_fields(
          filters: { fieldEvent: $fieldEvent, availableFor: $availableFor, source: $source }
        ) {
          name
          value
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_WEBHOOK_AVAILABLE_INTEGRATION_FIELDS = gql`
  query getOrganizationAvailableIntegrationFields(
    $organizationRevvUid: String
    $fieldEvent: IntegrationFieldEventEnum
    $availableFor: AvailableIntegrationFieldForEnum
    $source: IntegrationSourceTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_integration_fields(
          filters: { fieldEvent: $fieldEvent, availableFor: $availableFor, source: $source }
        ) {
          name
          value
        }
      }
    }
  }
`;

export const GET_WEBHOOK_AVAILABLE_INTEGRATION_FILTERS = gql`
  query getAvailableIntegrationFilters($organizationRevvUid: String, $fieldEvent: IntegrationFieldEventEnum) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        available_integration_filters(filters: { fieldEvent: $fieldEvent }) {
          name
          value
          predicates {
            name
            value
          }
          valueOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_WEBHOOK_AVAILABLE_INTEGRATION_FILTERS = gql`
  query getOrganizationAvailableIntegrationFilters(
    $organizationRevvUid: String
    $fieldEvent: IntegrationFieldEventEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_integration_filters(filters: { fieldEvent: $fieldEvent }) {
          name
          value
          predicates {
            name
            value
          }
          valueOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_WEBHOOK_OPTIONS = gql`
  query getVendorWebhookOptions($organizationRevvUid: String) {
    viewer {
      vendors(revvUid: $organizationRevvUid) {
        webhook_additional_data_options {
          name
          value
        }
      }
    }
  }
`;
