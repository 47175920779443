import { createSelector } from 'reselect';

export const revenueSelector = state => state.revenue;

export const donationsFilterSelector = createSelector(revenueSelector, revenue => revenue.donationsFilter);

export const teamDonationsFilterSelector = createSelector(revenueSelector, revenue => revenue.teamDonationsFilter);

export const subscriptionsFilterSelector = createSelector(revenueSelector, revenue => revenue.subscriptionsFilter);

export const moneyPledgeFilterSelector = createSelector(revenueSelector, revenue => revenue.moneyPledgeFilter);
