import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader } from 'modules/common/drawer/drawer-header';
import {
  FormCardSpaced,
  MoneyInput,
  WinRedDatePicker,
  WinRedInput,
  WinRedSelect,
  WinRedInputNumbers,
} from 'modules/common/form';
import { ExternalLink } from 'modules/common/links/external-link.component';
import Typography from 'modules/common/typography.component';
import { thermometerWidgetOptionsCreate, thermometerWidgetOptionsPetition, thermometeDataSourceOptions } from './thermometer-fields';
import { ThermometerGoalList } from './thermometer-goal-list.component';
import { Icon } from 'modules/common';
import { isMoneyThermometerType } from '../../widgetTypes/thermometer/tools';

const internalNameValidator = [{ validator: 'required', message: 'Internal Name is required.' }];
const thermometerTypeValidator = [{ validator: 'required', message: 'Thermometer Type is required.' }];
const goalListValidator = [{ validator: 'required', message: 'Goal Amount is required' }];
const thermometerDataSourceValidator = [{ validator: 'required', message: 'Thermometer Data Source is required.' }];

export const ThermometerDrawerBody = ({ data, toggleDrawer, onChange }) => {
  const { internal_name, thermometer_type, query_date, goals, boost, isPetitionPage, isEdit, page_count, data_source } = data;
  const widgetSelectOptions = isPetitionPage ? thermometerWidgetOptionsPetition : thermometerWidgetOptionsCreate;
  const typeSelection = useMemo(() => {
    return widgetSelectOptions.find(o => o.value === thermometer_type);
  }, [thermometer_type, widgetSelectOptions]);
  const isMoneyThermometer = useMemo(() => {
    return isMoneyThermometerType(typeSelection?.value);
  }, [typeSelection]);
  const SelectedBoostComponent = isMoneyThermometer ? MoneyInput : WinRedInputNumbers;

  const commonBoostProps = useMemo(() => {
    return {
      name: 'boost',
      placeholder: 'Thermometer Boost (Optional)',
      value: boost,
      onChange,
      max: 999999,
      description: "Add a Boost to your thermometer so you're not starting at 0.",
    };
  }, [boost, onChange]);

  const selectedBoostProps = isMoneyThermometer
    ? { ...commonBoostProps, removeExtraCharacters: true, isCents: true }
    : { ...commonBoostProps, removeDecimalPoint: true };

  const dataSourceSelection = useMemo(() => {
    return thermometeDataSourceOptions.find(o => o.value === data_source);
  }, [data_source]);
  const disableDataSource = isEdit && data_source === 'ALL_PAGES';

  return (
    <>
      <DrawerHeader toggleOpen={toggleDrawer}>
        <div>
          <Typography variant="h2" className="mb-0">
            Thermometer
          </Typography>
          <Typography className="mb-0">
            Thermometers show users how close you are to your goal.&nbsp;
            <ExternalLink href="https://support.winred.com/en/articles/7979688-enable-the-thermometer-widget">
              Learn More
            </ExternalLink>
          </Typography>
        </div>
      </DrawerHeader>
      <div className="px-2 mb-5">
        <FormCardSpaced>
          {/* Internal Name */}
          <Typography color="mirage" className="mb-2 font-medium">
            Internal Name
          </Typography>
          <WinRedInput
            placeholder="Internal Name"
            onChange={onChange}
            name="internal_name"
            value={internal_name}
            description="Write an internal name that will help you identify this thermometer across multiple pages."
            validators={internalNameValidator}
          />
          {/* Thermometer Type */}
          <div className="mb-4">
            <Typography color="mirage" className="mb-2 font-medium">
              Thermometer Type
            </Typography>
            <WinRedSelect
              placeholder="Select thermometer type..."
              name="thermometer_type"
              options={widgetSelectOptions}
              value={typeSelection}
              onChange={onChange}
              disabled={isEdit}
              validators={thermometerTypeValidator}
            />
          </div>
          {/* thermometer Data Source */}
          <div className="mb-4">
            <Typography color="mirage" className="mb-1 font-medium">
              Thermometer Data Source
            </Typography>
            <Typography className="m-1">
              Select the source from which this thermometer is retrieving data.
            </Typography>
            <div className="d-flex">
              <Icon icon="exclamation-circle" size="lg" className="m-1" />
              <Typography italic className="m-1">
                If you select "All Pages", you cannot switch to "Pages with this Thermometer" at a later time.
              </Typography>
            </div>
            <WinRedSelect
              placeholder="Select thermometer data source..."
              name="data_source"
              options={thermometeDataSourceOptions}
              value={dataSourceSelection}
              onChange={onChange}
              disabled={disableDataSource}
              validators={thermometerDataSourceValidator}
            />
          </div>
          {/* Query Date */}
          <div className="my-2">
            <Typography color="mirage" className="mb-2 font-medium">
              Start Date
            </Typography>
            <WinRedDatePicker
              dateFormat="MM/dd/yyyy"
              onChange={onChange}
              name="query_date"
              description="The thermometer will collect data from all pages it has been added to, beginning from this date."
              value={query_date}
            />
          </div>
          {/* Thermometer Goals */}
          <div className="my-3">
            <ThermometerGoalList
              isMoneyThermometer={isMoneyThermometer}
              thermometerType={thermometer_type}
              goals={goals}
              onChange={onChange}
              validators={goalListValidator}
            />
          </div>
          {/* Thermometer Boost */}
          <Typography color="mirage" className="mb-2 font-medium">
            Thermometer Boost (Optional)
          </Typography>
          <SelectedBoostComponent {...selectedBoostProps} />
        </FormCardSpaced>
        {isEdit ? (
          <FormCardSpaced>
            <div className="d-flex">
              <Icon icon="info-circle" size="lg" className="mr-2" />
              <Typography className="m-0">
                Changes made to this thermometer will also effect {page_count} page(s) using this thermometer
              </Typography>
            </div>
          </FormCardSpaced>
        ) : null}
      </div>
    </>
  );
};

ThermometerDrawerBody.propTypes = {
  data: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
