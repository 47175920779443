import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Icon,
  Toggle,
  Typography,
  useOrganization,
  FREQUENCY_OPTIONS_TEXT_MAP,
  getDeliveryMethodText,
  getReportUIDetails,
  useRunCandidateReport,
  useRunVendorReport,  
} from 'modules/common';
import { CustomReportCardRow } from './custom-report-card-row.component';
import { formatDateTime } from 'core/utilities';

import 'modules/common/custom-reports/custom-reports.scss';
import { useSelector } from 'react-redux';
import { isAgencySelector } from 'core/login';

function CustomReportCard({ customReport, onUpdateReport }) {
  const history = useHistory();

  const runCandidateReport = useRunCandidateReport();
  const runVendorReport = useRunVendorReport();

  const [isOrganization, profileCandidate] = useOrganization();
  const isAgency = useSelector(isAgencySelector);

  const {
    active,
    custom_filename,
    recipients_string,
    frequency_enum,
    created_by,
    created_at,
    report_type,
    name,
    note,
    integration,
    revv_uid,
    disable_user_editing,
  } = customReport;
  const customReportDetails = getReportUIDetails(report_type, isOrganization, isAgency);
  const deliveryMethodText = getDeliveryMethodText({ integration, recipients_string });

  const editReport = useCallback(() => {
    if (isAgency) {
      history.push(`/${profileCandidate.organization_revv_uid}/vendors/utilities/custom-reports/${revv_uid}`);
      return;
    }

    history.push(`/${profileCandidate.organization_revv_uid}/utilities/custom-reports/${revv_uid}`);
  }, [history, isAgency, profileCandidate, revv_uid]);

  return (
    <div className="custom-report-card-container">
      <Card className="inline-flex custom-report-card">
        <Card.Body className="p-0">
          <Row className="custom-report-list-border py-4 pl-4 custom-report-card-header align-items-center">
            <Col xs={12} sm={frequency_enum === null || frequency_enum === 'CUSTOM' ? 12 : 8}>
              <div className="d-flex align-items-center">
                <Icon
                  className="create-report"
                  icon={customReportDetails?.icon}
                  color={customReportDetails?.iconColor || 'green'}
                />
                <div className="ml-3 overflow-hidden">
                  <Typography className="mb-0" variant="h5">
                    {customReportDetails?.name}
                  </Typography>
                  <Typography className="mb-0 truncate">{name}</Typography>
                </div>
              </div>
            </Col>
            {frequency_enum === null || frequency_enum === 'CUSTOM' ? null : (
              <Col className="d-flex justify-content-end" xs={12} sm={4}>
                <div className="d-flex align-items-center pr-3.5">
                  <Typography className="mr-3 mb-0">{active ? 'Active' : 'Inactive'}</Typography>
                  <Toggle name="active" value={active} onChange={() => onUpdateReport(customReport)} />
                </div>
              </Col>
            )}
          </Row>
          {custom_filename && <CustomReportCardRow label="File Name" value={custom_filename} />}
          <CustomReportCardRow label="Created By" value={created_by} />
          <CustomReportCardRow label="Date Created" value={formatDateTime(created_at * 1000)} />
          <CustomReportCardRow label="Frequency" value={FREQUENCY_OPTIONS_TEXT_MAP[frequency_enum || 'CUSTOM']} />
          <CustomReportCardRow label="Delivery Method" value={deliveryMethodText} />
          {note && <CustomReportCardRow label="Note" value={note} />}

          <Row className="py-4 mx-0 bg--catskill-white" style={{ minHeight: '83.5px' }}>
            <Col className="d-flex justify-content-end">
              {!disable_user_editing ? (
                <>
                  <Button className="ml-2" onClick={editReport} skinnyButton variant="alt">
                    Edit
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => (isAgency ? runVendorReport(revv_uid) : runCandidateReport(revv_uid))}
                    skinnyButton
                  >
                    Run Report
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

CustomReportCard.propTypes = {
  customReport: PropTypes.object.isRequired,

  onUpdateReport: PropTypes.func.isRequired,
};

export { CustomReportCard };
