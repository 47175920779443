import React from 'react';
import PropTypes from 'prop-types';
import { Input, FloatGroup } from 'modules/common';
import CustomFieldRequired from './required.component';

function CustomFieldText({ updateCustomField, formState, isNumberField, textArea, isHiddenField, disabled }) {
  return (
    <>
      {isHiddenField ? null : (
        <>
          <FloatGroup>
            <Input
              type="text"
              name="label"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder="Field Label"
              value={formState.label}
              disabled={disabled}
              maxLength="255"
              onChange={updateCustomField}
            />
          </FloatGroup>

          <FloatGroup>
            <Input
              type="text"
              name="placeholder"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder="Field Placeholder"
              disabled={disabled}
              value={formState.placeholder}
              maxLength="255"
              onChange={updateCustomField}
            />
          </FloatGroup>
        </>
      )}

      <FloatGroup>
        {textArea ? (
          <Input
            as="textarea"
            name="default_value"
            rows="10"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            disabled={disabled}
            placeholder="Default Value"
            value={formState.default_value}
            maxLength="255"
            onChange={updateCustomField}
          />
        ) : (
          <Input
            type="text"
            name="default_value"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Default Value"
            value={formState.default_value}
            maxLength="255"
            disabled={disabled}
            numbersOnly={isNumberField}
            onChange={updateCustomField}
          />
        )}
      </FloatGroup>

      {isHiddenField ? null : (
        <CustomFieldRequired onChange={updateCustomField} value={formState.required} disabled={disabled} />
      )}
    </>
  );
}

CustomFieldText.propTypes = {
  formState: PropTypes.object.isRequired,
  updateCustomField: PropTypes.func.isRequired,
  isNumberField: PropTypes.bool,
  textArea: PropTypes.bool,
  isHiddenField: PropTypes.bool,
};

CustomFieldText.defaultProps = {
  isNumberField: false,
  textArea: false,
  isHiddenField: false,
};

export default CustomFieldText;
