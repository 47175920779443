import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormCard from './form-card.component';

function FormCardSpaced({ classNameBody, children, ...rest }) {
  return (
    <FormCard classNameBody={classnames('pb-3', classNameBody)} {...rest}>
      {children}
    </FormCard>
  );
}

FormCardSpaced.propTypes = {
  classNameBody: PropTypes.string,
  children: PropTypes.any,
};

FormCardSpaced.defaultProps = {
  classNameBody: '',
};

export default FormCardSpaced;
