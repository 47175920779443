import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { Typography } from 'modules/common';
import { formatAmount } from 'core/utilities';
import '../../widgets.scss';

const CONTRIBUTION_AMOUNT = 25;
const NO_CENTS = new RegExp(/\.00$/);

const onlyShowCentsIfNotZero = amount => {
  const newAmount = formatAmount(amount, false, true);
  if (NO_CENTS.test(newAmount)) return newAmount.substring(0, newAmount.length - 3);
  return newAmount;
};

function WidgetPreviewMultiplier({ widget, primaryColor }) {
  const multipliedAmount = onlyShowCentsIfNotZero(CONTRIBUTION_AMOUNT + widget.multiplierFactor * CONTRIBUTION_AMOUNT);
  return (
    <Container className="widget-preview_multiplier">
      <Row className="d-flex justify-content-between">
        <Col xs={6}>
          <div className="multiplier-text" style={{ backgroundColor: primaryColor, color: 'white' }}>
            <Typography variant="bodyMedium">Your Contribution</Typography>
            <div className="multiplier-text-amt">${CONTRIBUTION_AMOUNT}</div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="multiplier-text">
            <Typography variant="bodyMedium">Your Total Impact!</Typography>
            <div style={{ color: primaryColor }}>{multipliedAmount}!</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewMultiplier.propTypes = {
  widget: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default WidgetPreviewMultiplier;
