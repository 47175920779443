import React from 'react';
import { Icon, ToolTip } from 'modules/common';
import './upsell.scss';

import { UPSELL_COMPONENT_MAP } from './tools';

export const computeIconColor = status => {
  switch (status) {
    case 'PASSWORD_PROTECTED':
      return 'blue';
    case 'LIVE':
      return 'green';
    default:
      return 'gray';
  }
};

export const computeTooltip = status => {
  return status ? 'Upsell Flow' : 'Upsell Flow';
};

export function renderIcons({ upsell_pages }) {
  if (!upsell_pages) return null;

  return (
    <div className="d-flex flex-wrap upsell-icon-container">
      {upsell_pages.map(page => (
        <div className="upsell-icon" key={page.id}>
          {renderUpsellTooltip(page.upsell_page)}
        </div>
      ))}
    </div>
  );
}

export function renderUpsellTooltip(upsell) {
  const upsellComponent = UPSELL_COMPONENT_MAP[upsell.type] || {};
  return (
    <ToolTip tooltip={upsellComponent.title || ''}>
      <Icon icon={upsellComponent.icon || ''} />
    </ToolTip>
  );
}
