import gql from 'graphql-tag';

export const GET_VENDOR_DONATIONS_REPORT = gql`
  query getVendorDonationsReport(
    $organizationRevvUid: String
    $clientChargeRevvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $status: DonationStatusEnum
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $origins: [String!]
    $recipients: [String!]
    $ignoreCustomFields: Boolean
    $bundlerId: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        export_donations_report(
          filters: {
            productName: $productName
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            clientChargeRevvUid: $clientChargeRevvUid
            status: $status
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            origins: $origins
            recipients: $recipients
            ignoreCustomFields: $ignoreCustomFields
            bundlerId: $bundlerId
          }
        )
      }
    }
  }
`;

export const GET_VENDOR_DONATIONS_QUERY = gql`
  query getVendorDonations(
    $page: Int
    $limit: Int
    $organizationRevvUid: String
    $clientChargeRevvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $status: DonationStatusEnum
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $origins: [String!]
    $recipients: [String!]
    $bundlerId: String
  ) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        origins {
          revv_uid
          name
        }

        recipients {
          revv_uid
          name
        }

        donations(
          filters: {
            productName: $productName
            page: $page
            limit: $limit
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            clientChargeRevvUid: $clientChargeRevvUid
            status: $status
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            origins: $origins
            recipients: $recipients
            bundlerId: $bundlerId
          }
        ) {
          current_page
          page_count
          total_count

          donation_amount
          donor_count
          donation_count
          average_donation
          vendor_amount

          results {
            created_at
            client_charge_revv_uid

            first_name
            last_name
            pac_name
            business_name
            fec_id

            amount
            charge_status
            source_url
            email
            phone
            source_code
            recurring
            revv_uid

            source_organization
            campaign {
              name
            }

            occupation
            utm_source
            address
            city
            state
            zip
            employer
            card_brand
            payout_revv_uid
            utm_term
            utm_medium
            utm_campaign
            utm_content

            order_number
            order_revv_uid
            interval
            payment_processor
            recurring_status
            product_name

            allowSameDayRefund
            same_day_refund_expires_at

            contributor_type
            pac_name
            business_name
            type_of_business
            tax_id
            fec_id

            origin_organization_name
            recipient {
              name
              revv_uid
              avatar {
                image {
                  thumbnailUrl
                }
              }
            }
            vendor_amount_cents
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_DONATION_QUERY = gql`
  query getVendorDonation($clientChargeRevvUid: String, $organizationRevvUid: String) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        revv_uid
        donations(filters: { clientChargeRevvUid: $clientChargeRevvUid }) {
          results {
            revv_uid
            created_at
            first_name
            last_name
            charge_status
            source_url
            email
            phone
            refunded_at
            refunded_amount
            source_code
            voidable
            voided_at
            donor_covers_fees
            interval
            canceled_at

            contributor_type
            pac_name
            business_name
            type_of_business
            tax_id

            campaign {
              revv_uid
              name
            }
            recurring
            revv_subscription_id
            card_expiry
            card_last4
            card_brand
            revv_uid
            user_type
            profile_revv_uid
            charge_revv_uid
            refundable
            subscription_status
            custom_fields {
              name
              value
            }
            amount
            fee
            net
            payout_revv_uid
            origin_organization_revv_uid
            recipient {
              revv_uid
              is_connected
              name
            }

            amount_cents
            vendor_amount_cents

            pledges {
              results {
                revv_uid
                created_at
                money_pledge_internal_name
                processing_date
                status
                conduit_amount_cents
                canceled_at
              }
            }
          }
        }
      }
    }
  }
`;

export const REFUND_VENDOR_DONATION_MUTATION = gql`
  mutation refundVendorDonation(
    $organizationRevvUid: String!
    $chargeUid: String!
    $refund: String!
    $partialAmount: String!
  ) {
    organizationRefund(
      chargeRefundForm: {
        organizationRevvUid: $organizationRevvUid
        chargeUid: $chargeUid
        refund: $refund
        partialAmount: $partialAmount
      }
    ) {
      message
      errors
    }
  }
`;
