import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Typography } from 'modules/common';

const ExpandedRowValue = ({ label, value, xs = 6, md = 3, className = '', classNameValue }) => {
  return (
    <Col xs={xs} md={md} className={className}>
      {label && (
        <>
          <Typography variant="bodyMedium" className="mb-0">
            {label}
          </Typography>
          <Typography className={classNameValue}>{value || '-'}</Typography>
        </>
      )}
    </Col>
  );
};

ExpandedRowValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  classNameValue: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
};

export default React.memo(ExpandedRowValue);
