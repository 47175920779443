import { scssColors } from 'core/utilities';

export const customStyles = ({
  hideDropdownIndicator,
  hideMenu,
  prependSearchIcon,
  validationError,
  maxWidthDropDown,
}) => ({
  container: styles => ({
    ...styles,
  }),
  control: styles => ({
    ...styles,
    border: validationError ? `1.4px solid ${scssColors['winred-red-light']}` : `1px solid ${scssColors.mystic}`,
    backgroundColor: validationError ? scssColors.rose : scssColors.white,
    boxShadow: 'none',
    padding: '.22rem 0.375rem .22rem 1rem',
    ':hover': {
      border: validationError ? `1.4px solid ${scssColors['winred-red-light']}` : `1px solid ${scssColors.nepal}`,
    },
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: prependSearchIcon ? 30 : 0,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: scssColors.nepal,
    display: hideDropdownIndicator ? 'none' : 'block',
    ':hover': {
      color: scssColors.lynx,
      cursor: 'pointer',
    },
  }),
  clearIndicator: styles => ({
    ...styles,
    color: scssColors.nepal,
    display: hideDropdownIndicator ? 'none' : 'block',
    paddingRight: '0',
    ':hover': {
      color: scssColors.lynx,
      cursor: 'pointer',
    },
  }),
  input: styles => ({
    ...styles,
    color: scssColors['winred-blue'],
  }),
  menu: styles => ({
    ...styles,
    boxShadow: '5px 5px 20px rgba(30, 43, 58, 0.1)',
    border: `1px solid ${scssColors.catskill}`,
    ...(maxWidthDropDown && { width: 'max-content' }),
    background: 'white',
    marginBottom: 10,
    zIndex: 400,
    display: hideMenu ? 'none' : 'block',
  }),
  menuList: styles => ({
    ...styles,
    ...(maxWidthDropDown && { width: 'max-content' }),
    overflowY: 'scroll',
    // Always show the scrollbar on mobile on webkit
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#FAFAFA',
      borderRadius: '10px',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#bcbcbc',
    },
  }),
  noOptionsMessage: styles => ({
    ...styles,
    color: scssColors.lynx,
    background: 'transparent',
  }),
  option: styles => ({
    ...styles,
    background: 'white',
    minHeight: 37,
    textTransform: 'none',
    color: scssColors['winred-blue'],
    ':hover': {
      background: scssColors['catskill-white'],
      cursor: 'pointer',
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: scssColors['winred-blue'],
  }),
  singleValue: styles => ({
    ...styles,
    color: scssColors['winred-blue'],
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: scssColors['winred-blue-light'],
      color: scssColors.white,
      ':hover': {
        backgroundColor: scssColors['winred-blue-light'],
        color: scssColors.white,
      },
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    backgroundColor: scssColors['winred-blue-light'],
    color: scssColors.white,
    ':hover': {
      backgroundColor: scssColors['winred-blue-light'],
      color: scssColors.white,
    },
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: scssColors['winred-blue-light'],
    color: scssColors.white,
    ':hover': {
      backgroundColor: scssColors['winred-blue-light'],
      color: scssColors.white,
    },
  }),
});
