import React, { createRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';

import { Drawer, DrawerHeader, Typography, FormCardSpaced, AddField, Button } from 'modules/common';
import { useToast } from 'modules/common/hooks';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import { ValidationBlock } from '../../../../core/validation';
import { StepName } from '../../pathways/steps/drawer/step-name.component';
import { StepFooter } from '../../pathways/steps/drawer/step-footer.component';
import { StepRequirements } from '../../pathways/steps/drawer/step-requirements.component';
import { formatStepForUi, generateNewRequirement } from '../../pathways/pathways.tools';
import honeybadger from 'honeybadger-js';
import { CREATE_OR_UPDATE_ORGANIZATION_STEP_MUTATION } from '../../../../core/middleware/queries/pages/pathways.organization.queries';
import { CREATE_OR_UPDATE_STEP_MUTATION } from '../../../../core/middleware/queries/pages/pathways.queries';
import { formatUpsellStepsForServer } from '../../upsell-pathways/react-flow.tools';

export function ConditionDrawer({
  open,
  toggleDrawer,
  selectedCondition,
  onEditSelectedCondition,
  originalEditedCondition,
  availableCustomConditions,
  refetch,
  updatePathwaySteps,
  campaignsList,
  isPathways = false,
}) {
  const shouldRefetch = !!refetch;
  const disabledStep = false;
  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);
  const sendToast = useToast();

  const isNewCondition = selectedCondition?._isNew && !selectedCondition?.created_at;

  const validationBlock = createRef();

  const stepHeader = 'Condition';

  const onConfirmCloseEdit = () => {
    toggleDrawer();
  };

  const [updateStepsMutation] = useMutation(
    isOrganization ? CREATE_OR_UPDATE_ORGANIZATION_STEP_MUTATION : CREATE_OR_UPDATE_STEP_MUTATION
  );
  const onSaveEditedCondition = () => {
    const formattedStep = formatUpsellStepsForServer(selectedCondition);
    const variables = {
      pathway_step: formattedStep,
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };

    return new Promise((resolve, reject) => {
      updateStepsMutation({ variables })
        .then(({ data }) => {
          const response = data.organizationCreateOrUpdatePathwayStep || data.candidateCreateOrUpdatePathwayStep;
          if (response.errors) {
            sendToast({ isError: true, message: response.errors });
            honeybadger.notify(response.errors);
            console.error(response.errors);
          }
          resolve(response);
          if (response.pathway_step) {
            if (shouldRefetch) {
              // edits from steps index require re-fetch for the list
              refetch();
            } else {
              const newStep = formatStepForUi(response.pathway_step);
              if (!!updatePathwaySteps) {
                updatePathwaySteps({ ...newStep, stepId: newStep.id }, !!selectedCondition._isNew);
              }
            }
            sendToast({ isError: false, message: 'Condition Step has successfully been saved.' });
            onConfirmCloseEdit();
          }
        })
        .catch(errors => {
          console.error(errors);
          honeybadger.notify(errors);
          sendToast({ isError: true, message: errors });
        });
    });
  };

  const handleClickOutside = event => {
    const clickedBackdrop = open && backdropRef && backdropRef?.contains(event.target);
    if (clickedBackdrop) {
      onConfirmCloseEdit();
    }
  };

  const _onChangeCondition = useCallback(
    ({ target: { name, value } }) => {
      onEditSelectedCondition({ target: { name: name, value } });
    },
    [onEditSelectedCondition]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  const _addNewRequirement = useCallback(() => {
    const oldConditions = selectedCondition?.conditions || [];
    const newPosition = oldConditions.length + 1;
    const newCondition = generateNewRequirement(newPosition);
    const newConditions = [...oldConditions, newCondition];
    onEditSelectedCondition({ target: { name: 'conditions', value: newConditions } });
  }, [onEditSelectedCondition, selectedCondition]);

  return (
    <div className="upsell-drawer">
      <Drawer
        setbackdropRef={setbackdropRef}
        open={open}
        toggleOpen={onConfirmCloseEdit}
        icon={'times'}
        footer={
          <StepFooter
            selectedStep={selectedCondition}
            onConfirmCloseEdit={onConfirmCloseEdit}
            onSaveEditedStep={onSaveEditedCondition}
            validationBlock={validationBlock}
            originalEditedStep={originalEditedCondition}
            disableStep={disabledStep}
            isNewCondition={isNewCondition}
            type="condition"
          />
        }
        className="upsell-selected"
      >
        <div>
          <DrawerHeader toggleOpen={onConfirmCloseEdit}>
            <div className="d-flex justify-content-center align-items-center">
              <Typography variant="h2" className="mb-0">
                {isNewCondition ? `Create New ${stepHeader}` : `Editing ${stepHeader}`}
              </Typography>
            </div>
          </DrawerHeader>
          <ValidationBlock ref={validationBlock}>
            <Container fluid className={'steps-drawer-content'}>
              <FormCardSpaced>
                <StepName
                  onUpdateEditedStep={_onChangeCondition}
                  editedStep={selectedCondition}
                  disabledStep={disabledStep}
                  type={'condition'}
                />
              </FormCardSpaced>
              <FormCardSpaced>
                <StepRequirements
                  onUpdateEditedStep={_onChangeCondition}
                  editedStep={selectedCondition}
                  conditionOptions={availableCustomConditions?.formattedConditionsArray}
                  predicateOptions={availableCustomConditions?.predicatesForKeysMap}
                  customValueOptions={availableCustomConditions?.customValueOptions}
                  campaignsList={campaignsList}
                  disabledStep={disabledStep}
                  isPathways={isPathways}
                />
                {!disabledStep && <AddField onClick={_addNewRequirement} text={'Add Requirement'} />}
              </FormCardSpaced>
              <FormCardSpaced>
                <Row className="pb-3">
                  <div className="flex-row align-center">
                    <Button square className="py-1 px-2 mr-1 click-disabled">
                      THEN
                    </Button>
                  </div>
                </Row>
                <Row className="pb-2">
                  <div className="flex content-center">
                    <div className="flex-none content-center">
                      <Button variant="dark" square className="py-1 px-2 mr-1 click-disabled">
                        ✓ True
                      </Button>
                    </div>
                    <div className="flex-1 content-center">
                      <Typography className="mb-0 pl-1">
                        If the Donor meets all of the requirements defined above, they will follow the True path. If
                        there are multiple requirements, all must be met for the Donor to follow the True path.
                      </Typography>
                    </div>
                  </div>
                </Row>
                <Row className="pb-2">
                  <div className="flex align-center">
                    <div className="flex-none">
                      <Button variant="dark" square className="py-1 px-2 mr-1 click-disabled">
                        X False
                      </Button>
                    </div>
                    <div className="flex-1">
                      <Typography className="mb-0 pl-1">
                        If the Donor does not meet all of the requirements defined above they will follow the False
                        path.
                      </Typography>
                    </div>
                  </div>
                </Row>
              </FormCardSpaced>
            </Container>
          </ValidationBlock>
        </div>
      </Drawer>
    </div>
  );
}

ConditionDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedCondition: PropTypes.object,
  toggleDrawer: PropTypes.func.isRequired,
  updatePathwaySteps: PropTypes.func,
  campaignsList: PropTypes.array.isRequired,
  onEditSelectedCondition: PropTypes.func,
  originalEditedCondition: PropTypes.object,
  availableCustomConditions: PropTypes.object,
  refetch: PropTypes.func,
  isPathways: PropTypes.bool,
};
