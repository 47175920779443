import React from 'react';
import PropTypes from 'prop-types';

import { AddListItemButton, FormCardSpaced, Input } from 'modules/common';
import { noop } from 'core/utilities';

import Product from './product.component';
import { generateNewProduct, getMinimumPriceCents } from '../tools';

function Products({ campaign, onChange, productFieldOptions }) {
  const products = campaign.merch_products || [];

  const updateProduct = ({ target: { name, value } }, product, selectedProduct) => {
    const newValue = products.map(h => {
      if (h === product) {
        h = { ...h };
        h[name] = value;

        // if selecting a product set the min donation amount tot he min amount as default

        if (selectedProduct) h.merchandise_minimum_price_cents = getMinimumPriceCents(selectedProduct) * 100;
      }
      return h;
    });

    onChange({ target: { name: 'merch_products', value: newValue } });
  };

  const addProduct = () => {
    const value = [...products, generateNewProduct()];
    onChange({ target: { name: 'merch_products', value } });
  };

  const removeProduct = product => {
    const value = products.map(h => {
      if (h === product) {
        h = { ...h };
        h._destroy = true;
      }
      return h;
    });

    onChange({ target: { name: 'merch_products', value } });
  };

  const selectedProductIds = products.filter(p => !p._destroy).map(p => p.merch_product.revv_uid);
  const allowableOptions = (productFieldOptions || []).filter(opt => !selectedProductIds.includes(opt.value));
  const disableAdd = products.filter(c => !c._destroy).length >= 5 || allowableOptions.length === 0;

  const hasProducts = products
    .filter(c => !c._destroy)
    .map(c => c.merch_product.name)
    .join('');

  return (
    <FormCardSpaced title="Select Products" subtitle="Select products you would like to offer via your campaign.">
      <>
        {products.map(product => {
          if (product._destroy) return null;
          return (
            <div key={product.id} className="mb-3">
              <Product
                product={product}
                updateProduct={updateProduct}
                removeProduct={removeProduct}
                productFieldOptions={productFieldOptions || []}
                allowableOptions={allowableOptions}
              />
            </div>
          );
        })}

        <AddListItemButton
          addItem={addProduct}
          addText="Add Product"
          addSubText="Add a product to this campaign."
          disabled={disableAdd}
        />
      </>

      <Input
        hidden
        validateOnMount
        value={hasProducts}
        onChange={noop}
        validators={['required']}
        errorMessages={['You must add at least one product to your campaign.']}
      />
    </FormCardSpaced>
  );
}

Products.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  productFieldOptions: PropTypes.array,
};

export default Products;
