import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

import { convertEpochTime, formatNumberWithCommas } from 'core/utilities';
import { Icon, Table, Typography, ToolTip, IndexTableContextMenu, WinRedChip } from 'modules/common';
import { computeIconColor, computeTooltip, renderIcons } from '../ui.tools';

function UpsellItemDesktop({ items, navigateToItem, dropdownAction, profileCandidate }) {
  const tableBody = items.map(item => {
    const {
      revv_uid,
      name,
      upsell_group_statistics,
      page_count,
      status,
      created_at,
      public_upsell_group,
      bill_pay_exists,
    } = item;

    const iconColor = computeIconColor(status);
    const toolTip = computeTooltip(status);
    const navigateHandler = () => navigateToItem(revv_uid);

    return (
      <Table.Row key={revv_uid} onClick={navigateHandler} onKeyPress={navigateHandler} tabIndex="0" role="link">
        <Table.Cell textAlign="center" className="icon-cell">
          <ToolTip tooltip={toolTip}>
            <Icon icon="donate" color={iconColor} />
          </ToolTip>
        </Table.Cell>
        <Table.Cell>{convertEpochTime(created_at)}</Table.Cell>
        <Table.Cell className="d-flex align-items-center flex-wrap">
          <Typography variant="bodyMedium" className="c--mirage mb-0 mr-2">
            {name}
          </Typography>
          {public_upsell_group ? (
            <WinRedChip className="mr-2" variant="yellow">
              Public
            </WinRedChip>
          ) : null}
        </Table.Cell>
        <Table.Cell className="upsell-icon-cell">{renderIcons(item)}</Table.Cell>
        <Table.Cell>
          {bill_pay_exists ? (
            <WinRedChip className="cursor-default" variant="purple">
              Bill-Pay
            </WinRedChip>
          ) : null}
        </Table.Cell>
        <Table.Cell>{formatNumberWithCommas(page_count)}</Table.Cell>
        <Table.Cell>{((upsell_group_statistics?.percentage || 0) * 100).toFixed(2)}%</Table.Cell>
        <Table.Cell textAlign="center" noClick>
          <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
            <Dropdown.Item
              as={Link}
              to={`/${profileCandidate.organization_revv_uid}/pages/upsell-flows/${revv_uid}`}
              eventKey="details"
            >
              View Details
            </Dropdown.Item>
            <Dropdown.Item eventKey="duplicate">Duplicate</Dropdown.Item>
            <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>
          </IndexTableContextMenu>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Upsell Flow Name</Table.HeaderCell>
          <Table.HeaderCell>Upsells</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>Pages</Table.HeaderCell>
          <Table.HeaderCell>Opt-In Rate</Table.HeaderCell>
          <Table.HeaderCell className="d-none d-md-table-cell" />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
}

UpsellItemDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};

export default UpsellItemDesktop;
