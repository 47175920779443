import { convertEpochTime, formatAmount, formatNumberWithCommas, getCandidate } from 'core/utilities';
import { dateRangeFilterUi, dropdownFilterUi, inputFilterUi, multiDropdownFilterUi } from 'modules/common';
import { generateContributorTypeOptions } from 'modules/people/donors/donors.tools';

export const navTabs = [
  { value: 'all', label: 'All' },
  { value: 'SUCCEEDED', label: 'Succeeded' },
  { value: 'REFUNDED', label: 'Fully Refunded' },
  { value: 'PARTIALLY_REFUNDED', label: 'Partially Refunded' },
  { value: 'DISPUTED', label: 'Disputed' },
];

export const DONOR_TYPE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'DONOR', label: 'WinRed User' },
  { value: 'USER', label: 'Guest' },
];

export const PAGE_TYPE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'HIGH_DOLLAR', label: 'High Dollar' },
  { value: 'VIDEO_PAGE', label: 'Video' },
  { value: 'DONATION_PAGE', label: 'Donation' },
  { value: 'STOREFRONT_CHECKOUT', label: 'Storefront Checkout' },
];

export const PRODUCT_NAME_OPTIONS = [
  { value: 'ONE_CLICK_RECURRING_DONATION', label: 'One-Tap Recurring Donation' },
  { value: 'REVV_USER_RECURRING_DONATION', label: 'Recurring Revv User Donation' },
  { value: 'REGULAR_RECURRING_DONATION', label: 'Regular Recurring Donation' },
  { value: 'ONE_CLICK_DONATION', label: 'One-Tap Donation' },
  { value: 'REVV_USER_REGULAR_DONATION', label: 'Revv User Donation' },
  { value: 'REGULAR_DONATION', label: 'Regular Donation' },
  { value: 'MONEY_BOMB_DONATION', label: 'Money Bomb Donation' },
  { value: 'UPSELL_DONATION', label: 'Upsell Donation' },
  { value: 'DOUBLE_UPSELL_DONATION', label: 'Double Upsell Donation' },
  { value: 'RECURRING_UPSELL_SUBSCRIPTION', label: 'Recurring Upsell Subscription' },
  { value: 'CUSTOM_AMOUNT_UPSELL_DONATION', label: 'Custom Amount Upsell Donation' },
  { value: 'RECONCILED_DONATION', label: 'Reconciled Donation' },
  { value: 'VIRTUAL_TERMINAL', label: 'Virtual Terminal' },
  { value: 'VIRTUAL_TERMINAL_RECURRING_DONATION', label: 'Virtual Terminal (Recurring Donation)' },
];

export const RECURRING_OPTIONS = [
  { value: 'ALL_RECURRING', label: 'All Recurring' },
  { value: 'NON_RECURRING', label: 'Non-Recurring' },
  { value: 'FIRST_SUBSCRIBED', label: 'First Subscribed' },
  { value: 'SUBSEQUENT_DONATIONS', label: 'Subsequent Donations' },
];

export const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'SUCCEEDED', label: 'Succeeded' },
  { value: 'REFUNDED', label: 'Fully Refunded' },
  { value: 'PARTIALLY_REFUNDED', label: 'Partially Refunded' },
  { value: 'DISPUTED', label: 'Disputed' },
  { value: 'VOIDED', label: 'Voided' },
];

export const getDonationStatusOptions = allowTransactionsToBeVoided => {
  let donationStatusOptions = [...STATUS_OPTIONS];

  // if voids not allow then remove from transactionType filters
  if (!allowTransactionsToBeVoided) {
    donationStatusOptions = donationStatusOptions.filter(transactionType => transactionType.value !== 'VOIDED');
  }

  return { donationStatusOptions };
};

export const mapFilterToUi = (filter, allowTransactionsToBeVoided) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });

  const { donationStatusOptions } = getDonationStatusOptions(allowTransactionsToBeVoided);

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: 'all',
    dropdownOptions: donationStatusOptions,
    filterKey: 'activeTabKey',
    label: 'Status',
  });

  if (filter.amountLower && filter.amountUpper) {
    uiFilter.push({
      name: ['amountLower', 'amountUpper'],
      defaultValue: ['', ''],
      uiValue: `Amount: ${formatAmount(filter.amountLower)} to ${formatAmount(filter.amountUpper, false)}`,
    });
    numberOfFilters += 2;
  } else if (filter.amountLower) {
    uiFilter.push({
      name: 'amountLower',
      defaultValue: '',
      uiValue: `Amount Greater Than or Equal to ${formatAmount(filter.amountLower, false)}`,
    });
    numberOfFilters++;
  } else if (filter.amountUpper) {
    uiFilter.push({
      name: 'amountUpper',
      defaultValue: '',
      uiValue: `Amount Less Than or Equal to ${formatAmount(filter.amountUpper, false)}`,
    });
    numberOfFilters++;
  }

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'origins',
    defaultValue: [],
    label: 'Origins',
    requiredFilter: true,
  });

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'recipients',
    defaultValue: [],
    label: 'Recipients',
    requiredFilter: true,
  });

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'recurrence',
    defaultValue: [],
    label: 'Recurring Status',
  });

  // donor details tab
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'firstName',
    label: 'First Name',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'lastName',
    label: 'Last Name',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'email',
    label: 'Email',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'phone',
    label: 'Phone / Mobile',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: '',
    dropdownOptions: DONOR_TYPE_OPTIONS,
    filterKey: 'revvUser',
    label: 'Profile',
  });
  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'contributorType',
    defaultValue: generateContributorTypeOptions(),
    label: 'Contributor Type',
  });

  // page details tab
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'sourceUrl',
    label: 'Source URL',
    defaultValue: '',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: '',
    dropdownOptions: PAGE_TYPE_OPTIONS,
    filterKey: 'pageType',
    label: 'Page Type',
  });

  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'utmSource',
    label: 'UTM Source',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'utmMedium',
    label: 'UTM Medium',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'utmCampaign',
    label: 'UTM Campaign',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'utmTerm',
    label: 'UTM Term',
    defaultValue: '',
  });
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'utmContent',
    label: 'UTM Content',
    defaultValue: '',
  });

  // advanced tab
  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'revvUid',
    label: 'Donation ID',
    defaultValue: '',
  });

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'campaigns',
    defaultValue: [],
    label: 'Campaigns',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    defaultValue: '',
    dropdownOptions: PRODUCT_NAME_OPTIONS,
    filterKey: 'productName',
    label: 'Product Name',
  });

  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'bundlerId',
    label: 'Fundraiser ID',
    defaultValue: '',
  });

  [uiFilter, numberOfFilters] = inputFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    prop: 'sourceCode',
    label: 'Source Code',
    defaultValue: '',
  });

  return [uiFilter, numberOfFilters];
};

export const refundDonationEmail = (donation, profile) => {
  window.open(
    `mailto:support@winred.com?subject=Requesting refund for ${donation.revv_uid}&body=
I am requesting a refund for the following donation:%0D%0A
Committee: ${profile.candidate.name}%0D%0A
Donation ID: ${donation.revv_uid}%0D%0A
Date: ${convertEpochTime(donation.created_at)}%0D%0A
Donor Name: ${donation.first_name} ${donation.last_name}%0D%0A
Email: ${donation.email}%0D%0A
Amount: ${donation.amount}`,
    '_blank'
  );
};

export const requestCancelText =
  'Donations that were split with other committees and have a total of more than $500 cannot be refunded from the WinRed portal. A member of the WinRed team must refund the donation for you. Click Send Request to generate an email with all the details to send to support@winred.com.';

export const requestCancelTextMerchOrder =
  'This donation is associated with a merchandise order. You can refund the donation, but the merchandise order will not be cancelled. Reach out to your fulfillment house or merch@winred.com if you want to attempt to cancel the merchandise order';

export const getDonation = data => {
  if (!data?.viewer) return null;
  const candidate = getCandidate(data);
  const [donation] = candidate.statistics?.results || candidate.donations?.results;
  return donation;
};

export const DONATION_EVENT = {
  refund: 'Refund',
  partial_refund: 'Partial Refund',
  dispute: 'Dispute',
  void: 'Void',
};

export const DONATION_STATUS = {
  DONATION: 'DONATION',
  FULL_REFUND: 'FULL_REFUND',
  FULL_REFUND_SAME_DAY: 'FULL_REFUND_SAME_DAY',
  PARTIAL_REFUND: 'PARTIAL_REFUND',
  DISPUTE: 'DISPUTE',
  VOIDED: 'VOIDED',
};

export const getDonationEventName = (type, section) => {
  if (section === 'title') {
    return DONATION_EVENT[type];
  }
  if (section === 'body') {
    if (type === 'partial_refund') {
      return DONATION_EVENT.refund;
    }
    return DONATION_EVENT[type];
  }
  if (section === 'net') {
    if (type === 'dispute' || 'partial_refund') {
      return DONATION_EVENT.refund;
    }
    return DONATION_EVENT[type];
  }
};

export const getDonationCardColor = donation => {
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      return 'green';
    case DONATION_STATUS.FULL_REFUND:
      return 'gold';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'gold';
    case DONATION_STATUS.DISPUTE:
      return 'red';
    case DONATION_STATUS.VOIDED:
      return 'blue';
    default:
      return '';
  }
};

export const getDonationStatus = status => {
  if (status?.same_day_refund && status?.charge_status === 'FULL_REFUND') return 'Fully Refunded - Same Day';
  switch (status?.charge_status) {
    case DONATION_STATUS.DONATION:
      return 'Succeeded';
    case DONATION_STATUS.FULL_REFUND:
      return 'Fully Refunded';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'Partially Refunded';
    case DONATION_STATUS.DISPUTE:
      return 'Disputed';
    case DONATION_STATUS.VOIDED:
      return 'Voided';
    default:
      return 'Succeeded';
  }
};

export const getDonationCardTitle = donation => {
  if (donation.same_day_refund && donation.charge_status === 'FULL_REFUND') return 'Full Refund - Same Day';
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      return 'Donation';
    case DONATION_STATUS.FULL_REFUND:
      return 'Full Refund';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'Partial Refund';
    case DONATION_STATUS.DISPUTE:
      return 'Dispute';
    case DONATION_STATUS.VOIDED:
      return 'Voided Donation';
    default:
      return 'Donation';
  }
};

export const getDonationAmount = donation => {
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      return formatAmount(donation.amount);
    case DONATION_STATUS.FULL_REFUND:
      return `${formatAmount(donation.amount)} of ${formatAmount(donation.amount)}`;
    case DONATION_STATUS.PARTIAL_REFUND:
      return `${formatAmount(donation.refunded_amount)} of ${formatAmount(donation.amount)}`;
    case DONATION_STATUS.DISPUTE:
      return `${formatAmount(donation.disputed_amount)} of ${formatAmount(donation.amount)}`;
    case DONATION_STATUS.VOIDED:
      return formatAmount(donation.amount);
    default:
      return formatAmount(donation.amount);
  }
};

export const getDonationDetails = donation => {
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      return '';
    case DONATION_STATUS.FULL_REFUND:
      return {
        detailsTitle: 'Refunded',
        // get the latest item out of all the refunds/disputes
        detailsDate: donation.refunds.reduce(
          (highest, current) => (current.refunded_at > highest.refunded_at ? current : highest),
          { refunded_at: 0 }
        ).refunded_at,
      };
    case DONATION_STATUS.PARTIAL_REFUND:
      return {
        detailsTitle: 'Last Partial Refund',
        detailsDate: donation.refunds.reduce(
          (highest, current) => (current.refunded_at > highest.refunded_at ? current : highest),
          { refunded_at: 0 }
        ).refunded_at,
      };
    case DONATION_STATUS.DISPUTE:
      return {
        detailsTitle: 'Last Disputed',
        detailsDate: donation.disputes.reduce(
          (highest, current) => (current.disputed_at > highest.disputed_at ? current : highest),
          { disputed_at: 0 }
        ).disputed_at,
      };
    case DONATION_STATUS.VOIDED:
      return {
        detailsTitle: 'Voided',
        detailsDate: donation.voided_at,
      };
    default:
      return '';
  }
};

export const getDonationIcon = donation => {
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      switch (donation.contributor_type) {
        case 'business':
          return 'briefcase';
        case 'pac':
          return 'sitemap';
        default:
          if (
            donation?.product_name === 'Virtual Terminal' ||
            donation?.product_name === 'Virtual Terminal Recurring Donation'
          )
            return 'donation-vt-icon';
          return 'donate';
      }
    case DONATION_STATUS.FULL_REFUND:
      return 'refund-icon';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'refund-icon';
    case DONATION_STATUS.VOIDED:
      return 'void-icon';
    case DONATION_STATUS.DISPUTE:
      return 'dispute-icon';
    default:
      return 'donate';
  }
};

export const getDonationIconColor = donation => {
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      return 'green';
    case DONATION_STATUS.FULL_REFUND:
      return 'gold';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'gray';
    case DONATION_STATUS.DISPUTE:
      return 'red';
    case DONATION_STATUS.VOIDED:
      return 'black';
    default:
      return 'gray';
  }
};

export const getDonationTooltip = donation => {
  if (donation.same_day_refund && donation.charge_status === 'FULL_REFUND') return 'Full Refund - Same Day';
  switch (donation.charge_status) {
    case DONATION_STATUS.DONATION:
      if (donation?.product_name === 'Virtual Terminal') {
        return 'Virtual Terminal Donation';
      } else if (donation?.product_name === 'Virtual Terminal Recurring Donation') {
        return 'Recurring Virtual Terminal Donation';
      }
      return 'Donation';
    case DONATION_STATUS.FULL_REFUND:
      return 'Full Refund';
    case DONATION_STATUS.PARTIAL_REFUND:
      return 'Partial Refund';
    case DONATION_STATUS.DISPUTE:
      return 'Dispute';
    case DONATION_STATUS.VOIDED:
      return 'Voided';
    default:
      return 'Donation';
  }
};

export const processValue = (value, negateValue = false) => {
  const dollars = value ? formatNumberWithCommas((value / 100).toFixed(2)) : '0.00';

  // always make sure value is negative (ie refunds)
  if (negateValue && value >= 0) value = value * -1;
  return value >= 0 ? `$${dollars}` : `($${dollars.replace('-', '')})`;
};

export const formatTotalFeesForSubItems = donation => {
  let subItemsFees = [];
  subItemsFees.push({ name: 'Processing Fees', value: processValue(donation.processing_fee_cents, true) });
  donation.partner_fees.forEach(item => {
    subItemsFees.push({ name: item.name, value: processValue(item.feeCents, true) });
  });
  return subItemsFees;
};
