import gql from 'graphql-tag';
import { settingsFragment } from './settings.queries';

export const GET_ORGANIZATION_STOREFRONT_SETTINGS = gql`
  query getOrganizationStorefront($organizationRevvUid: String!) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        storefront_settings {
          ...SettingsStorefront
        }
        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${settingsFragment}
`;

export const ACTIVATE_ORGANIZATION_STOREFRONT = gql`
  mutation ActivateOrganizationStorefrontMutation($organizationRevvUid: String!) {
    organizationActivateStorefront(organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
`;

export const SAVE_ORGANIZATION_STOREFRONT = gql`
  mutation UpdateOrganizationStorefrontSettingsMutation($storefrontSettings: BaseStorefrontSettingsInput!) {
    organizationUpdateStorefrontSettings(storefrontSettings: $storefrontSettings) {
      errors
      storefrontSettings {
        ...SettingsStorefront
      }
    }
  }
  ${settingsFragment}
`;

export const UPDATE_ORGANIZATION_STOREFRONT_FULFILLMENT_HOUSE = gql`
  mutation UpdateOrganizationStorefrontFulfillmentHouse(
    $storefrontFulfillmentHouse: StorefrontFulfillmentHouseInput!
    $organizationRevvUid: String
  ) {
    organizationUpdateStorefrontFulfillmentHouse(
      storefrontFulfillmentHouse: $storefrontFulfillmentHouse
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      message
    }
  }
`;
