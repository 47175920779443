import validator from 'validator';

export const hasLowerCase = str => {
  /* If the string is different with lowercase letters changed to uppercase, it has lowercase letters */
  return str.toUpperCase() !== str;
};

export const hasUpperCase = str => {
  /* If the string is different with uppercase letters changed to lowercase, it has uppercase letters */
  return str.toLowerCase() !== str;
};

export const hasNumber = str => {
  return /[0-9]+/.test(str);
};

export const hasSpecialCharacter = str => {
  return /[!@#$%^&*()]+/.test(str);
};

export function isValidURL(str) {
  if (!str) return true;
  const isValidHttpsUrl = validator.isURL(str, { protocols: ['https'], require_host: true, require_tld: true });
  return !!isValidHttpsUrl;
}

export function isMp4File(str) {
  if (!str) return true;

  return /\.mp4$/.test(str);
}

export const noHTTP = value => (/^http(s){0,1}:\/\//.test(value) ? false : true);

export const yesHTTPS = value => (/^https{0,1}:\/\//.test(value) ? true : false);
