import React from 'react';

import { Typography, Icon } from 'modules/common';
import ProfileImage from 'modules/layout/navbar/profileMenus/profile-image.component';
import './candidate-icon.scss';

export function CandidateIconMenu({ onToggleUserMenu, userMenu, candidate }) {
  return (
    <div
      className="d-flex justify-content-between justify-content-lg-center align-items-center menu-box pointer"
      onClick={onToggleUserMenu}
      onKeyPress={onToggleUserMenu}
      role="button"
      tabIndex="0"
    >
      <div className="d-none d-lg-block">
        <ProfileImage candidate={candidate} />
      </div>

      <Typography variant="bodyMedium" className="mb-0 mr-2 p-2 p-lg-0 navbar-candidate-name">
        {candidate.name}
      </Typography>
      <div className="pr-2 d-block d-lg-none">
        <Icon icon={userMenu ? 'caret-up' : 'caret-down'} />
      </div>
      <div className="candidate-menu-icon-container d-none d-lg-block">
        <div
          id="candidate-icon-menu"
          className={userMenu ? 'open' : ''}
          onClick={onToggleUserMenu}
          onKeyPress={onToggleUserMenu}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}
