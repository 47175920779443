import { createSelector } from 'reselect';

export const accountingSelector = state => state.accounting;

export const transactionsFilterSelector = createSelector(
  accountingSelector,
  accounting => accounting.transactionsFilter
);

export const payoutsFilterSelector = createSelector(accountingSelector, accounting => accounting.payoutsFilter);
export const invoicesFilterSelector = createSelector(accountingSelector, accounting => accounting.invoicesFilter);
export const blockedFilterSelector = createSelector(accountingSelector, accounting => accounting.blockedFilter);
