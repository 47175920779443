import React from 'react';
import PropTypes from 'prop-types';

import { ListFilter, AmountInput } from 'modules/common';

function BasicFilter({ filter, onChangeFilter, ...rest }) {
  return (
    <ListFilter onChangeFilter={onChangeFilter} filter={filter} {...rest}>
      <AmountInput
        title="Amount"
        name="amountPredicate"
        value={filter.amount}
        valuePredicate={filter.amountPredicate}
        onChange={onChangeFilter}
      />
    </ListFilter>
  );
}

BasicFilter.propTypes = {
  noSearch: PropTypes.bool,
  onlySearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string.isRequired,
  actionComponent: PropTypes.object,

  filter: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  numberOfFilters: PropTypes.number.isRequired,
};

export default BasicFilter;
