import React from 'react';

import { Icon, Table, Typography, ToolTip } from 'modules/common';
import { convertEpochTime, formatAmount } from 'core/utilities';
import { getTeamDonationIconColor, getTeamDonationTooltip } from '../team-donations.tools';
import '../team-donations.scss';

function renderTable({ items }) {
  if (!items) return null;

  const tableBody = items.map(item => {
    const { revv_uid, created_at, first_name, last_name, email, amount, status } = item;
    const iconColor = getTeamDonationIconColor(status);
    const toolTip = getTeamDonationTooltip(status);

    return (
      <Table.Row key={revv_uid} className="no-pointer">
        <Table.Cell textAlign="center" className="icon-cell">
          <ToolTip tooltip={toolTip}>
            <Icon icon="donate" color={iconColor} />
          </ToolTip>
        </Table.Cell>
        <Table.Cell>{convertEpochTime(created_at)}</Table.Cell>
        <Table.Cell>
          <Typography variant="bodyMedium" className="c--mirage mb-0">{`${first_name} ${last_name}`}</Typography>
        </Table.Cell>
        <Table.Cell className="recording-sensitive">{email}</Table.Cell>
        <Table.Cell>{formatAmount(amount)}</Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table className="team-donations-table">
      <Table.Head>
        <Table.Row className=" no-pointer">
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell className="d-none d-md-table-cell" />
        </Table.Row>
      </Table.Head>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default renderTable;
