import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import defaultImage from 'assets/images/thumb_shield-fallback.png';

export function WinRedImage({ src, ...props }) {
  return <Image src={src || defaultImage} {...props} />;
}

WinRedImage.propTypes = {
  src: PropTypes.string,
};

export default WinRedImage;
