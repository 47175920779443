import React from 'react';
import PropTypes from 'prop-types';

import { FloatGroup, Input } from 'modules/common';
import { noop } from 'core/utilities';

function SocialMediaInput({
  onChange,
  facebookName,
  facebookValue,
  twitterName,
  twitterValue,
  required,
  disabled = false,
}) {
  return (
    <>
      <FloatGroup className="mb-3 prepend-label-facebook">
        <Input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          prepend="facebook.com/"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Facebook Slug"
          maxLength="50"
          value={facebookValue || ''}
          name={facebookName}
          onChange={onChange}
          disabled={disabled}
          validators={required ? ['required'] : []}
          errorMessages={[' ']}
          description='This is the part of your Facebook page&apos;s url that comes after "facebook.com/".'
        />
      </FloatGroup>

      <FloatGroup className="prepend-label-twitter">
        <Input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          prepend="@"
          disabled={disabled}
          name={twitterName}
          placeholder="Twitter Handle"
          value={twitterValue || ''}
          maxLength="50"
          onChange={onChange}
          description='Do not include the "@".'
          validators={required ? ['required'] : []}
          errorMessages={[' ']}
        />
      </FloatGroup>

      {required ? (
        <Input
          hidden
          value={facebookValue || twitterValue}
          onChange={noop}
          validators={['required']}
          errorMessages={['Social Links in Widgets cannot be blank. Enter values or remove the Widget']}
        />
      ) : null}
    </>
  );
}

SocialMediaInput.propTypes = {
  facebookName: PropTypes.string,
  facebookValue: PropTypes.string,
  twitterName: PropTypes.string,
  twitterValue: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SocialMediaInput.defaultProps = {
  required: false,
};

export default SocialMediaInput;
