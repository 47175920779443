import React from 'react';
import PropTypes from 'prop-types';

const TableHead = ({ children, className }) => <thead className={className}>{children}</thead>;

TableHead.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TableHead;
