import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormCardSpaced, MultiImagePrompt, ConfirmModal } from 'modules/common';

function ImageCardMulti({
  name,
  onChange,
  imageType,
  assignedImages,
  title,
  subtitle,
  removeTitle,
  removeBody,
  children,
  className,
  classNameBody,
  disabled = false,
}) {
  const [imageToBeRemoved, setImageToBeRemoved] = useState(null);
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);

  const handleRemoveImage = () => {
    onChange({
      target: {
        name,
        value: assignedImages.map(image =>
          image.image_url === imageToBeRemoved.image_url ? { ...image, destroy: true } : image
        ),
      },
    });
    setShowRemoveLogoModal(false);
  };

  const handleRemoveButtonClick = image => {
    setShowRemoveLogoModal(true);
    setImageToBeRemoved(image);
  };

  return (
    <FormCardSpaced title={title} subtitle={subtitle} className={className} classNameBody={classNameBody}>
      <MultiImagePrompt
        disabled={disabled}
        assignedImages={assignedImages}
        name={name}
        selectImageAction={onChange}
        imageType={imageType}
        removeImageAction={handleRemoveButtonClick}
      />

      <ConfirmModal
        show={showRemoveLogoModal}
        title={removeTitle}
        buttonText="Remove"
        handleClose={() => setShowRemoveLogoModal(false)}
        buttonColor="cancel"
        onSubmit={handleRemoveImage}
      >
        {removeBody}
      </ConfirmModal>
      {children}
    </FormCardSpaced>
  );
}

ImageCardMulti.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
  assignedImage: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  removeTitle: PropTypes.string.isRequired,
  removeBody: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  disabled: PropTypes.bool,
};

ImageCardMulti.defaultProps = {
  className: '',
  classNameBody: '',
};

export default ImageCardMulti;
