import React from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import packageJson from '../../package.json';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || Number.isNaN(b);
  }

  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      shouldForceRefresh: false,

      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (window.caches) {
          // Service worker cache should be cleared with caches.delete()
          window.caches.keys().then(function (names) {
            for (let name of names) window.caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };

    this._isRendered = true;
  }

  componentDidMount() {
    fetch(`/meta.json?dt=${Date.now()}`)
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (this._isRendered) this.setState({ loading: false, shouldForceRefresh });
      })
      .catch(e => {
        console.log(e);
        if (this._isRendered) this.setState({ loading: false, shouldForceRefresh: false });
      });
  }

  componentWillUnmount() {
    this._isRendered = false;
  }

  render() {
    const { loading, shouldForceRefresh, refreshCacheAndReload } = this.state;

    return this.props.children({
      loading,
      shouldForceRefresh,
      refreshCacheAndReload,
    });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.any.isRequired,
};

export default CacheBuster;
