import gql from 'graphql-tag';
import {
  actionFragment,
  partialUpsellStepFragment,
  pathwayFragment,
  stepFragment,
  upsellStepFragment,
} from './pathways.queries';

export const GET_ORGANIZATION_PATHWAYS_QUERY = gql`
  query getOrganizationPathways(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathways(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
          }
        ) {
          current_page
          page_count
          total_count
          results {
            upsell_count
            id
            revv_uid
            name
            pathway_type
            redirect_with_utms
            replace_utm_with_wr_utm
            updated_at
            created_at
            creator
            page_count
            bill_pay_exists
            base_step_joins {
              position
              revv_uid
              step {
                id
                revv_uid
                upsell_page_base_id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_PATHWAY_NAMES_QUERY = gql`
    query getOrganizationPathwayNames(
        $organizationRevvUid: String
        $page: Int
        $limit: Int
        $dateRangeStart: String
        $dateRangeEnd: String
        $name: String
        $pathwayType: PathwayTypeEnum
    ) {
        viewer {
            state_level_organization(revvUid: $organizationRevvUid) {
                pathways(
                    filters: {
                        page: $page
                        limit: $limit
                        dateRangeStart: $dateRangeStart
                        dateRangeEnd: $dateRangeEnd
                        name: $name
                        pathwayType: $pathwayType
                    }
                ) {
                    current_page
                    page_count
                    total_count
                    results {
                        revv_uid
                        id
                        name
                        pathway_type
                    }
                }
            }
        }
    }
`;

export const SEARCH_ORGANIZATION_PATHWAYS_QUERY = gql`
  query searchOrganizationPathways($organizationRevvUid: String, $name: String, $pathwayType: PathwayTypeEnum) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathways(filters: { name: $name, pathwayType: $pathwayType }) {
          total_count
          results {
            ...Pathway
          }
        }
      }
    }
  }
  ${pathwayFragment}
`;

export const GET_ORGANIZATION_PATHWAY_QUERY = gql`
  query getOrganizationPathway($organizationRevvUid: String, $revv_uid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathways(filters: { revvUid: $revv_uid }) {
          total_count
          results {
            redirect_with_utms
            replace_utm_with_wr_utm
            revv_uid
            id
            name
            updated_at
            created_at
            creator
            page_count
            base_step_joins {
              revv_uid
              position
              step {
                ...Step
              }
              pathway {
                id
                revv_uid
              }
              id
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const GET_ORGANIZATION_UPSELL_PATHWAY_QUERY = gql`
  query getOrganizationUpsellPathway($organizationRevvUid: String, $revv_uid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathways(filters: { revvUid: $revv_uid }) {
          total_count
          results {
            redirect_with_utms
            replace_utm_with_wr_utm
            revv_uid
            id
            name
            page_count
            upsell_count
            base_step_joins {
              pathway_true_next_step_id
              pathway_false_next_step_id
              revv_uid
              position
              step {
                ...PartialUpsellStep
              }
              pathway {
                id
                revv_uid
              }
              id
            }
          }
        }
      }
    }
  }
  ${partialUpsellStepFragment}
`;

export const GET_ORGANIZATION_UPSELL_PATHWAY_STATS_QUERY = gql`
    query getUpsellPathwayStats($organizationRevvUid: String, $revv_uid: String) {
        viewer {
            state_level_organization(revvUid: $organizationRevvUid) {
                pathways(filters: { revvUid: $revv_uid }) {
                    results {
                        revv_uid
                        id
                        name
                        base_step_joins {
                            true_accepted
                            true_conversion_rate
                            true_views_count
                            false_accepted
                            false_conversion_rate
                            false_views_count
                            position
                            revv_uid
                            step {
                                revv_uid
                                id
                                statistics {
                                    dollarPerView
                                    donationCount
                                    donationVolume
                                    stepTaken
                                }
                            }
                            pathway {
                                id
                                revv_uid
                            }
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ORGANIZATION_STEPS_QUERY = gql`
  query getOrganizationSteps(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $step_type: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathway_steps(
          filters: {
            page: $page
            limit: $limit
            step_type: $step_type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
          }
        ) {
          total_count
          current_page
          page_count
          results {
            ...Step
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const GET_ORGANIZATION_UPSELL_STEPS_QUERY = gql`
  query getOrganizationUpsellSteps(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $step_type: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $name: String
    $pathwayType: PathwayTypeEnum
    $nodeType: NodeTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathway_steps(
          filters: {
            page: $page
            limit: $limit
            step_type: $step_type
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            name: $name
            pathwayType: $pathwayType
            nodeType: $nodeType
          }
        ) {
          total_count
          current_page
          page_count
          results {
            ...UpsellStep
          }
        }
      }
    }
  }
  ${upsellStepFragment}
`;

export const SEARCH_ORGANIZATION_STEPS_QUERY = gql`
  query searchOrganizationSteps($organizationRevvUid: String, $name: String, $step_type: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { name: $name, step_type: $step_type }) {
          total_count
          results {
            ...Step
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const CREATE_ORGANIZATION_PATHWAY_MUTATION = gql`
  mutation createOrganizationPathway($pathway: BasePathwayInput!, $organizationRevvUid: String!) {
    organizationCreateOrUpdatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway {
        revv_uid
        id
        name
        pathway_type
        redirect_with_utms
        replace_utm_with_wr_utm
        base_step_joins {
          position
          id
          step {
            ...Step
          }
          pathway {
            id
            revv_uid
          }
        }
      }
    }
  }
  ${stepFragment}
`;

export const CREATE_ORGANIZATION_UPSELL_PATHWAY_MUTATION = gql`
  mutation createOrganizationUpsellPathway($pathway: BasePathwayInput!, $organizationRevvUid: String!) {
    organizationCreateOrUpdatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway {
        revv_uid
        id
        name
        pathway_type
        redirect_with_utms
        replace_utm_with_wr_utm
        base_step_joins {
          pathway_true_next_step_id
          pathway_false_next_step_id
          position
          id
          step {
            ...PartialUpsellStep
          }
          pathway {
            id
            revv_uid
          }
        }
      }
    }
  }
  ${partialUpsellStepFragment}
`;

export const CREATE_OR_UPDATE_ORGANIZATION_STEP_MUTATION = gql`
  mutation createOrUpdateOrganizationStep($pathway_step: StepInput!, $organizationRevvUid: String) {
    organizationCreateOrUpdatePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      pathway_step {
        ...Step
      }
    }
  }
  ${stepFragment}
`;

export const GET_ORGANIZATION_AVAILABLE_CONDITIONS_QUERY = gql`
  query getOrganizationAvailableConditionsQuery($organizationRevvUid: String, $pathwayType: PathwayTypeEnum) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathways_available_conditions(filters: { pathwayType: $pathwayType }) {
          key
          name
          additional_field_values
          additional_field_type
          value
          position
          predicates
          condition_type
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_STEP_INACTIVE_ROUTES = gql`
  query getOrganizationStepInactiveRoutes(
    $organizationRevvUid: String
    $revv_uid: String
    $action_status: ActionFilterTypeStatusEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            id
            step_type
            name
            actions(filters: { action_status: $action_status }) {
              ...Action
            }
          }
        }
      }
    }
  }
  ${actionFragment}
`;

export const GET_ORGANIZATION_STEPS_INACTIVE_ROUTES = gql`
  query getOrganizationStepsInactiveRoutes(
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $action_status: ActionFilterTypeStatusEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pathway_steps(filters: { page: $page, limit: $limit }) {
          results {
            revv_uid
            id
            step_type
            name
            actions(filters: { action_status: $action_status }) {
              id
            }
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_PATHWAY_ARCHIVE_MUTATION = gql`
  mutation organizationArchivePathway($organizationRevvUid: String!, $pathway: BasePathwayInput!) {
    organizationArchivePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      message
    }
  }
`;

export const ORGANIZATION_PATHWAY_DUPLICATE_MUTATION = gql`
  mutation organizationDuplicatePathway($organizationRevvUid: String!, $pathway: BasePathwayInput!) {
    organizationDuplicatePathway(pathway: $pathway, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedPathway {
        ...Pathway
      }
      message
    }
  }
  ${pathwayFragment}
`;

export const ORGANIZATION_PATHWAY_STEP_ARCHIVE_MUTATION = gql`
  mutation organizationArchivePathwayStep($organizationRevvUid: String!, $pathway_step: StepInput!) {
    organizationArchivePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      message
    }
  }
`;

export const ORGANIZATION_PATHWAY_STEP_DUPLICATE_MUTATION = gql`
  mutation organizationDuplicatePathwayStep($organizationRevvUid: String!, $pathway_step: StepInput!) {
    organizationDuplicatePathwayStep(pathway_step: $pathway_step, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedPathwayStep {
        ...Step
      }
      message
    }
  }
  ${stepFragment}
`;
