import { createSelector } from 'reselect';
import { sortByName } from 'core/utilities';

export const loginSelector = state => state.login;

export const loginSettingsSelector = createSelector(loginSelector, login => login.settings);

export const userMenuSelector = createSelector(loginSelector, login => login.settings.userMenu);

export const loginStatusSelector = createSelector(loginSelector, login => login.loginStatus);
export const loginTokenSelector = createSelector(loginSelector, login => login.token);

export const loginInitialRouteSelector = createSelector(loginSelector, login => login.initialRoute);
export const profileSelector = createSelector(loginSelector, login => login.profile);

export const profileCandidateSelector = createSelector(profileSelector, profile => profile.candidate);

export const isDonorSelector = createSelector(profileCandidateSelector, candidate => candidate?.isDonor || false);
export const isOrganizationSelector = createSelector(
  profileCandidateSelector,
  candidate => candidate?.isOrganization || false
);
export const isFederalMerchantSelector = createSelector(
  profileCandidateSelector,
  candidate => candidate?.federal_merchant_account || false
);
export const isAgencySelector = createSelector(profileCandidateSelector, candidate => candidate?.isAgency || false);

export const profileCandidateLegalEntitySelector = createSelector(profileCandidateSelector, candidate => {
  return candidate.account?.legal_entity === 'individual';
});

export const profileCandidateMerchIntegrationSelector = createSelector(profileCandidateSelector, profileCandidate => {
  return {
    enableHds: profileCandidate.merchandise_integration[0]?.enableHds ?? false,
    enableAdvoc8: profileCandidate.merchandise_integration[0]?.enableAdvoc8 ?? false,
    enableAceSpecialties: profileCandidate.merchandise_integration[0]?.enableAceSpecialties ?? false,
    enableMspDesign: profileCandidate.merchandise_integration[0]?.enableMspDesign ?? false,
    enableUsCraftBrands: profileCandidate.merchandise_integration[0]?.enableUsCraftBrands ?? false,
    enableGgOutfitters: profileCandidate.merchandise_integration[0]?.enableGgOutfitters ?? false,
    enableManualFulfillment: profileCandidate.merchandise_integration[0]?.enableManualFulfillment ?? false,
    defaultFulfillmentHouse: profileCandidate.merchandise_integration[0]?.defaultFulfillmentHouse ?? false,
  };
});

export const profileCandidateSlugSelector = createSelector(profileCandidateSelector, candidate => candidate.slug);

// flag to show contributor type filters and fields for Business/PAC donations rollout
export const profileCandidateBusinessPacFeatureFlagSelector = createSelector(
  profileCandidateSelector,
  candidate => candidate.enable_business_and_pac_feature || false
);

export const profileCandidateViewSpecialAccounting = createSelector(profileCandidateSelector, profileCandidate => {
  return profileCandidate.enable_sql_transaction_view || false;
});

export const profileOrgApprovedSelector = createSelector(
  profileCandidateSelector,
  candidate => candidate.org_approved_by_win_red || candidate.org_approved_by_revv || false
);

export const globalCustomFieldsSelector = createSelector(profileCandidateSelector, candidate => {
  return (candidate.global_custom_fields || []).map(cf => {
    if (['MULTIPLE_CHOICE', 'RADIO_BUTTONS', 'SELECT_FIELD'].includes(cf.input_type)) {
      cf = { ...cf };
      cf.options = (cf.default_value || '').split(',').map(option => ({ key: option, value: option }));
      cf.default_value = '';
    }

    return cf;
  });
});

export const profileIsAdminSelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isAdmin ?? false
);
export const profileIsEditorSelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isEditor ?? false
);
export const profileIsReadOnlySelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isReadOnly ?? false
);
export const profileIsPagesOnlySelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isPagesOnly ?? false
);
export const profileIsMerchOnlySelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isMerchOnly ?? false
);
export const profileIsMyPagesOnlySelector = createSelector(
  profileCandidateSelector,
  profileCandidate => profileCandidate?.isMyPagesOnly ?? false
);

export const profileStoreFrontIsActive = createSelector(
  profileCandidateSelector,
  candidate => candidate?.has_active_storefront ?? false
);

export const profileMaxPersonalDonationSelector = createSelector(profileCandidateSelector, candidate => {
  return candidate?.maximum_contribution_cents ? candidate?.maximum_contribution_cents / 100 : 3300;
});

// const fulfillmentHouses = {
//   ace: {
//     value: 'ACE',
//     label: 'ACE',
//     type: 'FULFULLMENT_HOUSE_PAGES',
//   },
//   advoc8: {
//     value: 'ADVOC8',
//     label: 'Advoc8',
//     type: 'FULFULLMENT_HOUSE_PAGES',
//   },
// };

export const pagesIndexFeesSelector = createSelector(profileCandidateSelector, candidate => {
  const fees = [
    { value: 'all', label: '' },
    { value: 'PAGES_WITH_FEES', label: 'Pages With Fees', type: 'PAGES_WITH_FEES' },
    { value: 'PAGES_WITHOUT_FEES', label: 'Pages Without Fees', type: 'PAGES_WITHOUT_FEES' },
  ];

  let vendors = [...(candidate.vendors || [])];
  vendors.sort(sortByName);

  vendors?.forEach(vendor => {
    fees.push({
      value: vendor.revv_uid,
      label: vendor.name,
      type: 'VENDOR_PAGES',
    });
  });

  // Removed for now but may get added later.
  // candidate.fulfillment_houses?.forEach(fulfillment_house => {
  //   fees.push(fulfillmentHouses[fulfillment_house]);
  // });

  return fees;
});

export const profileCandidateDisputeRatesSelector = createSelector(profileCandidateSelector, candidate => {
  return candidate.dispute_rates;
});

export const profileCandidateEligibleForFinesSelector = createSelector(profileCandidateSelector, candidate => {
  return candidate.is_eligible_for_fines;
});
