import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useCurrentBreakpointName } from 'react-socks';

import { FormCard, Icon, Typography, WinRedChip } from 'modules/common';
import CollapsibleCard from 'modules/common/collapsibleCard.component';

function UpsellCollapsible({
  updateUpsellCard,
  upsellCard,
  editedUpsell,
  removeUpsellCard,
  index,
  isDragging,
  toggleDraggable,
}) {
  const breakpoint = useCurrentBreakpointName();

  let hasAvailableVendors = false;
  let hasVendorFees = false;

  for (const conduitCandidate of upsellCard.candidates || []) {
    const candidate = conduitCandidate.candidate || conduitCandidate.organization;
    if (candidate.available_vendors?.length) hasAvailableVendors = true;
    if (conduitCandidate.vendorFees?.length) hasVendorFees = true;
  }

  const Component = upsellCard.upsellComponent;
  if (!Component) return null;

  const removeCollapsibleCard = event => {
    event.preventDefault();
    event.stopPropagation();
    removeUpsellCard(upsellCard);
  };

  const onChangeUpsellCard = event => updateUpsellCard(event, upsellCard);

  // use default description if new or upsell doesnt have a description generator function
  const subtitle = upsellCard._isNew
    ? upsellCard.subtitle
    : (upsellCard.generateSubtitle && upsellCard.generateSubtitle(upsellCard)) || upsellCard.subtitle;

  // presets and existing cards closed by default
  const open = upsellCard._isPreset || isDragging ? false : !!upsellCard._isNew;
  const title = upsellCard._isPreset ? upsellCard.public_name : upsellCard.title;

  // can remove upsell card if the parent panel upsell is editable
  const parentUpsellDisabled = editedUpsell._isDisabled;

  const showBillpay = upsellCard._isPreset ? hasVendorFees : hasAvailableVendors || hasVendorFees;

  return (
    <FormCard classNameBody="p-0">
      <CollapsibleCard
        open={open}
        index={index}
        forceRender={false}
        title={
          <div
            className={classnames('flex items-center', {
              'collapse-drag-icon': breakpoint === 'small' || breakpoint === 'xsmall',
            })}
          >
            {parentUpsellDisabled ? null : <Icon icon="bars" color="gray" size="2x" />}

            <div className="ml-4 flex flex-col justify-center">
              <div className="flex md:items-center flex-col md:flex-row">
                <div className="c--mirage font-weight-bold">{title}</div>
                {upsellCard._isPreset && (
                  <WinRedChip variant={upsellCard.active ? 'green' : 'red'} className="md:ml-2 capitalize">
                    {upsellCard.active ? 'Active' : 'Inactive'}
                  </WinRedChip>
                )}
                {showBillpay && hasVendorFees ? (
                  <WinRedChip variant="purple" className="md:ml-2 capitalize">
                    Bill-Pay
                  </WinRedChip>
                ) : null}
              </div>

              <Typography tag="div" className="normal-case tracking-normal mb-0">
                {subtitle}
              </Typography>
            </div>
          </div>
        }
        removeCollapsibleCard={parentUpsellDisabled ? null : removeCollapsibleCard}
        onToggleOpen={toggleDraggable}
        isDraggable={!parentUpsellDisabled}
      >
        <Component upsellCard={upsellCard} onUpdateEditedUpsell={onChangeUpsellCard} />
      </CollapsibleCard>
    </FormCard>
  );
}

UpsellCollapsible.propTypes = {
  updateUpsellCard: PropTypes.func.isRequired,
  upsellCard: PropTypes.object.isRequired,
  editedUpsell: PropTypes.object.isRequired,
  removeUpsellCard: PropTypes.func.isRequired,
  index: PropTypes.any,
};

export default UpsellCollapsible;
