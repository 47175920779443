import React, { createRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader, FormCardSpaced, Icon, Typography, useClipBoard, useToast } from 'modules/common';
import { Breakpoint } from 'react-socks';
import { DuplicateResultsTableMobile } from './list-body/duplicate-results/mobile.component';
import { DuplicateResultsTableDesktop } from './list-body/duplicate-results/desktop.component';
import { ValidationBlock } from 'core/validation';

import {
  CANDIDATE_BULK_PAGE_RETRY_DUPLICATION,
  ORGANIZATION_BULK_PAGE_RETRY_DUPLICATION,
} from 'core/middleware/queries';
import { useMutation } from '@apollo/client';

export const DuplicateResultsBody = ({ page, results, onClose, profileCandidate, isOrganization }) => {
  const pageStatus = useMemo(() => {
    return results && results[0]?.page_status === 'DRAFT' ? 'Draft' : 'Live';
  }, [results]);
  const [loadingPageDuplicate, setLoadingPageDuplicate] = useState({
    loading: false,
    id: null,
  });
  const [duplicateResults, setDuplicateResults] = useState([]);
  const setToast = useToast();
  const resultsPageValidationBlock = createRef();
  const copyToClipBoard = useClipBoard();
  const resultsStatus = useMemo(() => {
    return {
      success: duplicateResults?.filter(item => !item.errors).length,
      error: duplicateResults?.filter(item => item.errors).length,
    };
  }, [duplicateResults]);

  const copyUrl = url => {
    copyToClipBoard({
      text: url,
      toast: 'Duplicated Page URL copied to clipboard.',
    });
  };

  useEffect(() => {
    if (results) {
      setDuplicateResults(results);
    }
  }, [results]);

  const [bulkPageDuplication] = useMutation(
    isOrganization ? ORGANIZATION_BULK_PAGE_RETRY_DUPLICATION : CANDIDATE_BULK_PAGE_RETRY_DUPLICATION
  );

  const onErrorPageDuplication = async ({ id, pageData, pageStatus }) => {
    const { template_revv_uid, custom_name, customSlug, custom_source_code } = pageData;
    if (resultsPageValidationBlock.current.errors()) {
      setToast({
        message: 'Please correct the errors on this page before saving!',
        isError: true,
      });
      return;
    }
    const variables = {
      bulkPageDuplication: {
        revvUid: page.revv_uid,
        organizationRevvUid: profileCandidate.organization_revv_uid,
        page_status: pageStatus,
        duplicationSource: 'BULK_PAGE_CREATOR',
        page_duplication_parameters: [{ template_revv_uid, custom_name, custom_slug: customSlug, custom_source_code }],
      },
    };
    if (isOrganization) {
      variables.bulkPageDuplication.organizationRevvUid = profileCandidate.organization_revv_uid;
    } else {
      variables.organizationRevvUid = profileCandidate.organization_revv_uid;
    }
    try {
      setLoadingPageDuplicate({ loading: true, id });
      const response = await bulkPageDuplication({ variables });
      const { duplicated_pages_result } =
        response.data.candidateBulkDuplicatePageRetry || response.data.organizationBulkDuplicatePageRetry;
      const updatedResults = duplicateResults.map(item => {
        if (item.id === id) {
          return {
            ...duplicated_pages_result,
            id,
          };
        }
        return { ...item };
      });
      setLoadingPageDuplicate({ loading: false, id: null });
      setDuplicateResults(updatedResults);
    } catch (error) {
      setLoadingPageDuplicate({ loading: false, id: null });
      console.error(error.message);
    }
  };

  const tableBodyProps = {
    page,
    items: duplicateResults,
    copyUrl,
    onSubmit: onErrorPageDuplication,
    loading: loadingPageDuplicate,
  };

  return (
    <ValidationBlock ref={resultsPageValidationBlock}>
      <DrawerHeader title="Duplicate Pages" toggleOpen={onClose} />
      <FormCardSpaced className="mb-2">
        <div className="duplication-status flex items-center">
          <div className="success flex items-center text-winred-green font-medium mr-4">
            <Icon icon="check-circle" color="green" className="mr-2 text-xl" />
            {resultsStatus.success} pages created
          </div>
          <div className="failed flex items-center text-winred-red font-medium">
            <Icon color="red" icon="exclamation-circle" className="mr-2 text-xl" />
            {resultsStatus.error} pages failed
          </div>
        </div>
        <hr className="my-3" />
        <Typography variant="bodyMedium" color="mirage" className="mb-0">
          New duplicate pages are set to {pageStatus} and {pageStatus === 'Draft' ? 'are not' : 'are'} publicly viewable
        </Typography>
      </FormCardSpaced>
      <Breakpoint small down>
        <DuplicateResultsTableMobile {...tableBodyProps} />
      </Breakpoint>
      <Breakpoint medium up>
        <DuplicateResultsTableDesktop {...tableBodyProps} />
      </Breakpoint>
    </ValidationBlock>
  );
};

DuplicateResultsBody.propTypes = {
  page: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
};
