export const formatLoadedUpsellOptions = data => {
  const allResults =
    data.viewer?.candidate?.upsells?.results || data.viewer?.state_level_organization?.upsells?.results || [];
  const formattedData = allResults.map(item => {
    return {
      label: item.name,
      revv_uid: item.revv_uid,
      value: item.revv_uid,
    };
  });
  return formattedData;
};

export const formatLoadedUpsellGroupOptions = data => {
  const allResults =
    data.viewer?.candidate?.upsell_groups?.results ||
    data.viewer?.state_level_organization?.upsell_groups?.results ||
    [];
  const formattedData = allResults.map(item => {
    return {
      label: item.name,
      revv_uid: item.revv_uid,
      value: item.revv_uid,
    };
  });
  return formattedData;
};

export const formatLoadedUpsellPathwayOptions = data => {
  const allResults =
    data.viewer?.candidate?.pathways?.results ||
    data.viewer?.state_level_organization?.pathways?.results ||
    [];
  const formattedData = allResults.map(item => {
    return {
      label: item.name,
      revv_uid: item.revv_uid,
      value: item.revv_uid,
    };
  });
  return formattedData;
};
