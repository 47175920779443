/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import {
  Button,
  Typography,
  SpinnerContainer,
  setTitle,
  withAmplitude,
  AmplitudePropTypes,
  withCopyToClipBoard,
  FloatGroup,
  Input,
  ListHeader,
  FormContainer,
  FormColumn,
  FormCard,
  FormCardSpaced,
  ButtonLink,
  ConfirmModal,
  ExternalLink,
} from 'modules/common';

import { ARCHIVE_TEAM_PAGE_MUTATION } from 'core/middleware/queries';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';

import CallToAction from '../components/callToAction.component';
import { cantSaveTeamPage } from '../pages/team-page.tools';
import { ConnectedCommitteesTeamPage } from './committees-team-page.component';
import { extractSlug } from './tools';

class TeamPageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      originalPage: null,
      disableSaveButton: true,
      isArchiving: false,
      confirmArchiveModal: false,
      hasBeenArchived: false, // keep track for prompt
    };

    this.validationBlock = React.createRef();
  }

  /**
   * when we get a copy of the page save an original copy for the save button
   */
  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.page) && !state.originalPage) {
      state.originalPage = cloneDeep(props.page);
    }

    return state;
  }

  savePage = () => {
    if (this.validationBlock.current.errors()) return;
    this.setState({ disableSaveButton: true }, () => {
      const page = this.state.page || this.props.page;

      this.props
        .savePage(page)
        .then(page => {
          page = extractSlug(page);

          this.setState({
            originalPage: null,
            disableSaveButton: true,
            page,
          });
        })
        .catch(() => this.setState({ disableSaveButton: false }));
    });
  };

  archivePage = () => {
    const page = {
      revvUid: this.props.page.revv_uid,
    };

    this.setState({ isArchiving: true });

    this.props
      .archivePage({ page })
      .then(({ errors, data }) => {
        this.setState({ isArchiving: false });

        if (errors) {
          this.props.setToast({
            message: errors,
            isError: true,
          });
          return;
        }

        let { errors: archiveErrors } = data?.candidateArchivePage || data?.organizationArchivePage || {};
        if (archiveErrors?.length > 0) {
          this.props.setToast({
            message: archiveErrors,
            isError: true,
          });
          return;
        }

        const eventParams = {
          'page id': this.props.page.revv_uid,
        };

        this.props.logEvent('archive team page', eventParams);

        this.props.setToast({
          message: 'Your team page has been archived.',
          isError: false,
        });

        this.setState({ hasBeenArchived: true }, () => this.props.history.push('/team-pages/pages'));
      })
      .catch(error => {
        this.setState({ isArchiving: false });
        this.props.setToast({
          message: error.message,
          isError: true,
        });
      });
  };

  renderSave = () => {
    const { disableSaveButton } = this.state;

    return (
      <Button
        variant="secondary"
        size="lg"
        onClick={this.savePage}
        className="mx-0 text-white"
        disabled={disableSaveButton}
      >
        Save
      </Button>
    );
  };

  updatePageField = ({ target: { name, value } }) => {
    // on first update we dont have a local copy of the page so use the props copy first
    // after editing we keep a local state copy and an 'originalPage' copy to compare the
    // two to enable or disable the save button
    let newState = this.state.page ? this.state : { ...this.state, page: this.props.page };
    newState = cloneDeep(newState);

    newState.page[name] = value;
    newState.disableSaveButton = cantSaveTeamPage(newState);

    this.setState(newState);
  };

  copyUrl = () => {
    const latestState = this.state.page ? this.state : { ...this.state, page: this.props.page };

    this.props.copyToClipBoard({
      text: latestState.page.url,
      toast: 'Page URL copied to clipboard.',
      logEventParams: {
        name: 'copy team page url',
        parameters: {
          'page id': latestState.page.revv_uid,
          status: latestState.page.status,
        },
      },
    });
  };

  renderHeader(page) {
    const title = (page && page.publicTitle && page.publicTitle) || this.props.pageTitle;

    return (
      <ListHeader title={title}>
        {page && page.url && <ButtonLink url={page.url} text="Preview" className="mr-2" />}
      </ListHeader>
    );
  }

  renderContent(page) {
    const isNew = !page.revv_uid;

    return (
      <ValidationBlock ref={this.validationBlock}>
        <FormContainer>
          <FormColumn>
            <FormCard
              title="Raise money for your favorite causes!"
              subtitle="Start raising money for your favorite candidates and committees by setting up your own personal
                    fundraising page. This tool allows you to publish a fundraising page and share it with your friends
                    to raise money for the causes you care about on their behalf."
            >
              <FloatGroup>
                <Input
                  type="text"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  maxLength="140"
                  placeholder="Page Title*"
                  name="publicTitle"
                  value={page.publicTitle}
                  onChange={this.updatePageField}
                  description="Give your page a title, which will be displayed in the browser's tab when donors visit your
                    page."
                  validators={['required']}
                  errorMessages={['Public Page Title is required.']}
                />
              </FloatGroup>
            </FormCard>

            <CallToAction
              isTeamPages
              description="Give your page a strong call to action to encourage your friends to donate. You're enthusiastic about this cause and you want potential donors to be, too!"
              page={page}
              updatePageField={this.updatePageField}
            />

            <ConnectedCommitteesTeamPage page={page} updatePageField={this.updatePageField} />

            <FormCardSpaced>
              <Typography>
                By creating a page I agree to WinRed&apos;s{' '}
                <ExternalLink href="https://winred.com/terms/terms-of-use/">terms of use</ExternalLink>.
              </Typography>
              {this.renderSave(page)}
            </FormCardSpaced>

            {isNew ? null : (
              <>
                <FormCardSpaced variant="red" classNameBody="pb-4">
                  <Button variant="cancel" onClick={() => this.setState({ confirmArchiveModal: true })}>
                    Archive Page
                  </Button>
                </FormCardSpaced>

                <ConfirmModal
                  title="Archive Page"
                  buttonText="Archive Page"
                  buttonColor="cancel"
                  handleClose={() => this.setState({ confirmArchiveModal: false })}
                  onSubmit={() => {
                    this.setState({ confirmArchiveModal: false });
                    this.archivePage();
                  }}
                  show={this.state.confirmArchiveModal}
                >
                  Are you sure you want to archive this team page? This cannot be undone.
                </ConfirmModal>
              </>
            )}
          </FormColumn>
        </FormContainer>
      </ValidationBlock>
    );
  }

  render() {
    // on first load we just get page data from props (state has most updated page data after any updates)
    const page = this.state.page || this.props.page;
    const title = (page && page.publicTitle && page.publicTitle) || this.props.pageTitle || '';
    const { originalPage, hasBeenArchived } = this.state;

    const loading = this.props.loading || this.props.isArchiving;

    return (
      <>
        {setTitle(title)}
        <Prompt
          when={!hasBeenArchived && !this.props.loading && !isEqual(page, originalPage)}
          message="You have unsaved changes to your Team Page. Are you sure you want to leave?"
        />
        {this.renderHeader(page)}
        {loading ? <SpinnerContainer /> : this.renderContent(page)}
      </>
    );
  }
}

TeamPageForm.propTypes = {
  copyToClipBoard: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  page: PropTypes.object,
  savePage: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
};

TeamPageForm.defaultProps = {
  page: {},
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(
  withRouter,
  withCopyToClipBoard,
  withAmplitude,
  connect(null, mapDispatchToProps),
  graphql(ARCHIVE_TEAM_PAGE_MUTATION, {
    props: ({ mutate }) => ({
      archivePage: variables => mutate({ variables }),
    }),
  })
)(TeamPageForm);
