import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-bootstrap';
import classname from 'classnames';

import { Icon } from 'modules/common';
import './table-inputs.scss';

function TableInputMove({ className = '' }) {
  return (
    <Col xs="auto" className={classname('input-move-btn', className)}>
      <div className="no-outline">
        <Icon icon="bars" color="gray" size="lg" />
      </div>
    </Col>
  );
}

TableInputMove.propTypes = {
  className: PropTypes.string,
};

export default TableInputMove;
