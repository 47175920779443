import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { profileCandidateSelector } from 'core/login';
import { FormCardSpaced } from 'modules/common';
import { BillPayUpsell } from 'modules/common/bill-pay-activity';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';

import { UpsellName, UpsellAction, UpsellVideoEmbed, EnhancedUpsell, Widgets, ConnectedCampaign } from './sections';

function DoubleDonation(props) {
  const profileCandidate = useSelector(profileCandidateSelector);

  return (
    <div>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
      </FormCardSpaced>

      <ConnectedCampaign {...props} />
      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />

      <Widgets
        {...props}
        widgetOptions={DoubleDonation.widgets}
        primaryColor={props.primaryColor}
        secondaryColor={props.secondaryColor}
      />

      {profileCandidate.available_vendors.length > 0 && (
        <BillPayUpsell
          revv_uid={props.editedUpsell?.revv_uid}
          upsellType="double"
          isPublic={props.editedUpsell?.public_upsell}
          upsellCreator={props.editedUpsell?.upsell_creator_revv_uid}
          isNew={props.editedUpsell?._isNew}
        />
      )}
    </div>
  );
}

DoubleDonation.title = 'Double Donation';
DoubleDonation.description = 'Ask donors to double their donation.';
DoubleDonation.icon = 'upsell-double-donation';
DoubleDonation.type = 'DOUBLE';
DoubleDonation.widgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

DoubleDonation.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default DoubleDonation;
