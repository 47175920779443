import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { FormCardSpaced, Icon, Input, WinRedChip, Toggle, Typography } from 'modules/common';
import { profileCandidateSelector } from 'core/login';
import { SelectedCommittee } from './selected-committee.component';
import { SelectedAmountCommittee } from './selected-amount-committee.component';
import { CommitteeCardBillPay } from './committee-card-billpay.component';

export default function CommitteeCard({
  conduitCandidate,
  committeeHasAmounts = false,
  disabled,
  isTeamPages,
  onChange,
  onRemove,
  splitEvenly,
  selectedCandidates,
  showExtraOptions,
  isOrganization,
  eventName,
  isPetitionPage,
  percentagesAddUpTo100Percent,
  sortingDisabled = false,
  isBillPay = false,
}) {
  const [billpayExpanded, setBillpayExpanded] = useState(false);
  const profileCandidate = useSelector(profileCandidateSelector);

  const isDraggable = !isPetitionPage && splitEvenly;
  const CommitteeComponent = committeeHasAmounts ? SelectedAmountCommittee : SelectedCommittee;

  const candidate = conduitCandidate.candidate || conduitCandidate.organization;

  /** change a committee's data instead of the whole list via onChange (onChange is directly from page builder/upsells) */
  const onChangeCommittee = ({ target: { name, value } }) => {
    const newCandidates = selectedCandidates.map(c => {
      const _c = c.candidate || c.organization;

      if (_c.revv_uid !== candidate.revv_uid) return c;
      return { ...c, [name]: value };
    });

    onChange({ target: { name: eventName, value: newCandidates } });
  };

  // dont show billpay chip if we dont have any vendors to add nor existing vendors added to committee
  let showBillpay = candidate.available_vendors?.length || conduitCandidate.vendorFees?.length;

  // check if we have permission to change billpay - if not and no existing vendor fees added
  //  dont show empty billpay section (user cant add any billpay anyways)
  if (showBillpay && !candidate.can_access_vendors) showBillpay = conduitCandidate.vendorFees?.length > 0;

  const showActivatedBillpay = conduitCandidate.vendorFees?.find(candidateFee => !candidateFee._destroy);

  return (
    <div className="mb-3">
      <FormCardSpaced
        className="committee-card"
        classNameFooter="bg--catskill-white"
        footer={
          isBillPay && billpayExpanded ? (
            <CommitteeCardBillPay conduitCandidate={conduitCandidate} onChangeCommittee={onChangeCommittee} />
          ) : null
        }
      >
        <Row className="d-flex flex-row align-items-center justify-content-between">
          <CommitteeComponent
            conduitCandidate={conduitCandidate}
            isDragable={isDraggable}
            isPetitionPage={isPetitionPage}
            isOrganization={isOrganization}
            isTeamPages={isTeamPages}
            showExtraOptions={showExtraOptions}
            splitEvenly={splitEvenly}
            sortingDisabled={sortingDisabled}
            isBillPay={isBillPay}
            onChange={onChange}
            eventName={eventName}
            disabled={disabled}
            selectedCandidates={selectedCandidates}
          />

          {disabled ? null : (
            <div className="d-block d-lg-none mr-2 cursor-pointer">
              <Icon icon="times" size="lg" onClick={() => onRemove(conduitCandidate)} />
            </div>
          )}

          {disabled ? null : (
            <Col
              lg={6}
              xs={12}
              className="d-md-flex justify-content-lg-end align-items-md-center ml-5 ml-lg-0 pr-0 pl-4 ml-1 pl-lg-0 pt-3 pt-lg-0"
            >
              {/* Only toggle data sharing for other candidates, not the users candidate */}
              {showExtraOptions ? (
                <>
                  <div>
                    {candidate.revv_uid === profileCandidate.revv_uid ? null : (
                      <div className="d-flex align-items-center mr-0 mr-md-3">
                        <Typography className="mb-0 mr-2">Share Data</Typography>
                        <Toggle
                          name="allowDataSharing"
                          value={conduitCandidate.allowDataSharing}
                          onChange={onChangeCommittee}
                        />
                      </div>
                    )}
                  </div>
                  {!splitEvenly && (
                    <div>
                      <Input
                        classNameWrapper="mt-3 mt-md-0 input-dense"
                        errorMessageClasses="hidden"
                        append={<InputGroup.Text>%</InputGroup.Text>}
                        numbersOnly
                        type="number"
                        inputMode="decimal"
                        className="winred-input input-with-prepend"
                        name="defaultPercentage"
                        value={conduitCandidate.defaultPercentage || ''}
                        onChange={onChangeCommittee}
                        disabled={splitEvenly}
                        validators={[percentagesAddUpTo100Percent]}
                        errorMessages={['error']}
                      />
                    </div>
                  )}
                </>
              ) : null}
              {showBillpay ? (
                <WinRedChip
                  className="mt-3 mt-md-0 ml-0 ml-md-3 cursor-pointer"
                  onClick={() => setBillpayExpanded(b => !b)}
                  variant={showActivatedBillpay ? 'purple' : 'default'}
                >
                  Bill-Pay
                  <Icon className="ml-2" color="none" icon={billpayExpanded ? 'caret-up' : 'caret-down'} />
                </WinRedChip>
              ) : null}
            </Col>
          )}

          {disabled ? null : (
            <div className="d-none d-lg-block mr-2 cursor-pointer">
              <Icon icon="times" size="lg" onClick={() => onRemove(conduitCandidate)} />
            </div>
          )}
        </Row>
      </FormCardSpaced>
    </div>
  );
}

CommitteeCard.propTypes = {
  committeeHasAmounts: PropTypes.bool,
  conduitCandidate: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  eventName: PropTypes.string.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  isTeamPages: PropTypes.bool.isRequired,
  isPetitionPage: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedCandidates: PropTypes.array.isRequired,
  showExtraOptions: PropTypes.bool.isRequired,
  splitEvenly: PropTypes.bool.isRequired,
  percentagesAddUpTo100Percent: PropTypes.func.isRequired,
};
