import React from 'react';
import PropTypes from 'prop-types';

import NavBarMenu from '../menu.component';
import '../../nav-bar.scss';

function NavBarMenuDashboard({ activePath, isAgency, removeAccess }) {
  if (removeAccess.includes(true)) return null;

  const path = isAgency ? 'vendors/dashboard' : 'dashboard';
  return <NavBarMenu title="Dashboard" path={path} isActive={activePath.includes('/dashboard')} />;
}

NavBarMenuDashboard.propTypes = {
  activePath: PropTypes.string.isRequired,
  isAgency: PropTypes.bool.isRequired,
};

export default NavBarMenuDashboard;
