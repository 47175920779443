import {
  SET_LOGIN_STATUS,
  LOGOUT_SUCCESS,
  SET_INITIAL_ROUTE,
  SET_PROFILE,
  SET_PROFILE_CANDIDATE,
  LOGIN_STATUSES,
  SET_SETTINGS,
  SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE,
  SWITCH_PROFILE_CANDIDATE,
  SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS,
  SET_ACTIVATE_STOREFRONT,
} from './types';

export const setProfile = data => {
  return {
    type: SET_PROFILE,
    data,
  };
};

export const setProfileCandidate = profile => {
  return {
    type: SET_PROFILE_CANDIDATE,
    profile,
  };
};

export const switchProfileCandidate = data => {
  return {
    type: SWITCH_PROFILE_CANDIDATE,
    data,
  };
};

export const setSettings = settings => {
  return {
    type: SET_SETTINGS,
    settings,
  };
};

export const setInitialRoute = route => {
  return {
    type: SET_INITIAL_ROUTE,
    response: route,
  };
};

export const setLoginStatus = ({ status = LOGIN_STATUSES.NOT_LOGGED_IN, ...args }) => {
  return {
    type: SET_LOGIN_STATUS,
    response: {
      status,
      ...args,
    },
  };
};

export const setActivateStorefront = () => {
  return {
    type: SET_ACTIVATE_STOREFRONT,
  };
};

export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const setProfileCandidateStorefrontActive = () => {
  return {
    type: SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE,
  };
};

export const syncProfileCandidateCustomFields = data => {
  return {
    type: SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS,
    data,
  };
};
