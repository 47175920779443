import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import {
  profileIsReadOnlySelector,
  profileIsPagesOnlySelector,
  profileOrgApprovedSelector,
  isOrganizationSelector,
  profileCandidateSelector,
  profileIsAdminSelector,
  isAgencySelector,
  profileIsMyPagesOnlySelector,
  profileIsMerchOnlySelector,
} from 'core/login';
import { withAmplitude, AmplitudePropTypes } from 'modules/common';
import NavbarToggle from 'modules/layout/navbar/nav-bar-toggle.component';

import { CandidateIconMenu } from './candidate-icon-menu.component';
import Logout from '../logout.component';
import NavbarLogo from './profileMenus/nav-bar-logo.component';
import { FeaturesContext } from 'featureProvider';
import { logClick, getSubnavStates } from './navbar.tools';
import { NavBarMenus } from './menus';
import './nav-bar.scss';

class NavBarMain extends Component {
  static contextType = FeaturesContext;

  state = {
    showLogoutModal: false,
    expanded: false,
  };

  componentDidMount() {
    this.props.setSubNavStates(getSubnavStates(this.props.location.pathname));
  }

  componentDidUpdate(prevProps) {
    // make sure to keep navbar up to date any time we change route
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.setSubNavStates(getSubnavStates(this.props.location.pathname));
    }
  }

  toggleSubNav = subNav => {
    logClick({ subNav, logEvent: this.props.logEvent, subNavStates: this.props.subNavStates });

    this.props.setSubNavStates({
      showPagesSubNav: false,
      showPeopleSubNav: false,
      showRevenueSubNav: false,
      showAccountingSubNav: false,
      showUtilitiesSubNav: false,
      showStoreFrontSubNav: false,
      showBillpaysSubNav: false,
      [subNav]: !this.props.subNavStates[subNav],
    });
  };

  handleLogout = isLoggingOut => {
    this.setState({ showLogoutModal: false });
    if (isLoggingOut) this.props.history.push('/logout');
  };

  toggleNavBar = () => {
    this.setState(oldState => ({ expanded: !oldState.expanded }));
    this.props.closeUserMenu(); // make sure user menu is closed when toggling navbar
  };

  render() {
    const {
      isOrganization,
      profileCandidate,
      isReadOnly,
      isAgency,
      isPagesOnly,
      isMyPagesOnly,
      isMerchOnly,
      isAdmin,
      approved,
      location,
      userMenu,
      onToggleUserMenu,
      children,
    } = this.props;

    const activePath = location.pathname;

    return (
      <div className="top-nav-bar top-nav-bar-winred-user">
        <Logout handleLogout={this.handleLogout} showLogoutModal={this.state.showLogoutModal} />

        <div className="mainnav" ref={this.props.navbarRef}>
          <Container fluid>
            <Row className="justify-content-center no-gutters pt-2 pt-lg-0">
              <Col className="navbar-left">
                <Navbar
                  expand="lg"
                  className="winred-nav d-flex"
                  onSelect={this.props.handleSelect}
                  onToggle={this.toggleNavBar}
                  collapseOnSelect
                  expanded={this.state.expanded}
                >
                  <div className="ml-1 ml-lg-0 mb-2 mb-lg-0">
                    <NavbarLogo />
                  </div>

                  <NavBarMenus
                    isOrganization={isOrganization}
                    isAgency={isAgency}
                    profileCandidate={profileCandidate}
                    isReadOnly={isReadOnly}
                    isPagesOnly={isPagesOnly}
                    isMyPagesOnly={isMyPagesOnly}
                    isMerchOnly={isMerchOnly}
                    isAdmin={isAdmin}
                    approved={approved}
                    activePath={activePath}
                    toggleSubNav={this.toggleSubNav}
                    subNavStates={this.props.subNavStates}
                  />

                  <NavbarToggle
                    title="committee dropdown options"
                    className="order-3 order-lg-2 mr-3 mr-lg-0"
                    onClick={onToggleUserMenu}
                    open={this.state.expanded}
                  />

                  <NavDropdown
                    alignRight
                    className="winred-nav__menu-nav-item dropdown-nav-item my-auto candidate-toggle-icon order-2 order-lg-3 mx-2 pb-4 pb-lg-2"
                    title={
                      <CandidateIconMenu
                        onToggleUserMenu={onToggleUserMenu}
                        userMenu={userMenu}
                        candidate={profileCandidate}
                      />
                    }
                  />
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>

        {children}
      </div>
    );
  }
}

NavBarMain.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isPagesOnly: PropTypes.bool.isRequired,
  isMyPagesOnly: PropTypes.bool.isRequired,
  isMerchOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    isOrganization: isOrganizationSelector(state),
    profileCandidate: profileCandidateSelector(state),
    isReadOnly: profileIsReadOnlySelector(state),
    isPagesOnly: profileIsPagesOnlySelector(state),
    isMyPagesOnly: profileIsMyPagesOnlySelector(state),
    isMerchOnly: profileIsMerchOnlySelector(state),
    isAdmin: profileIsAdminSelector(state),
    approved: profileOrgApprovedSelector(state),
    isAgency: isAgencySelector(state),
  };
};

export default compose(withRouter, withAmplitude, connect(mapStateToProps, null))(NavBarMain);
