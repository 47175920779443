import React from 'react';
import PropTypes from 'prop-types';
import { FormCardSpaced } from 'modules/common';
import { UpsellVideoEmbed, EnhancedUpsell, UpsellName, UpsellAction, Widgets } from './sections';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';

function RecurringDonation(props) {
  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
      </FormCardSpaced>

      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />

      <Widgets {...props} widgetOptions={RecurringDonation.widgets} />
    </>
  );
}

RecurringDonation.title = 'Recurring Donation';
RecurringDonation.description = 'Ask donors to make their donation recurring.';
RecurringDonation.icon = 'upsell-recurring-donation';
RecurringDonation.type = 'RECURRING';
RecurringDonation.widgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

RecurringDonation.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default RecurringDonation;
