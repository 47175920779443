import React from 'react';
import PropTypes from 'prop-types';

import { Typography, FormCardSpaced, Input, FloatGroup, FlexBinaryChoiceRow } from 'modules/common';

export function RecurringInterval({ editedUpsell, onUpdateEditedUpsell }) {
  return (
    <FormCardSpaced
      classNameFooter="bg-catskill-white"
      footer={
        editedUpsell.recurring_available && (
          <>
            <FormCardSpaced>
              <div className="d-flex">
                <Typography variant="bodyMedium" className="mb-0">
                  Recurring Callout (Optional)
                </Typography>
              </div>
              <Typography className="mb-1">
                Customize the callout for the recurring button. If left blank, the default callout will be used.
              </Typography>
              <FloatGroup>
                <Input
                  as="textarea"
                  rows="3"
                  disabled={editedUpsell._disabled}
                  name="recurring_callout"
                  value={editedUpsell.recurring_callout || ''}
                  onChange={onUpdateEditedUpsell}
                  placeholder="Recurring Callout"
                />
              </FloatGroup>
            </FormCardSpaced>
            <FormCardSpaced>
              <FlexBinaryChoiceRow
                title="Recurring Button Style"
                subTitle="Select the style of the recurring button."
                value={editedUpsell.recurring_enable_radio_buttons || false}
                name="recurring_enable_radio_buttons"
                disabled={editedUpsell._disabled}
                onChange={onUpdateEditedUpsell}
                trueText="Radio Button"
                falseText="Checkbox"
              />
            </FormCardSpaced>
          </>
        )
      }
    >
      <FlexBinaryChoiceRow
        title="Recurring Donation Button"
        subTitle="Select whether you would like to display a recurring button on your Upsell."
        value={editedUpsell.recurring_available}
        name="recurring_available"
        disabled={editedUpsell._disabled}
        onChange={onUpdateEditedUpsell}
      />
    </FormCardSpaced>
  );
}

RecurringInterval.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default RecurringInterval;
