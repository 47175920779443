import React from 'react';

import {
  oneHunded,
  clapping,
  collision,
  cool,
  cowboy,
  fire,
  grinningFace,
  heartEyes,
  heart,
  moneyFace,
  partyFace,
  partyPopper,
  raisingHands,
  rocket,
  startStruck,
  star,
  sunglasses,
  thumbsUp,
} from '../../../../../assets/images/widgets/confetti';

export const CONFETTI_ANIMATION_STYLE_OPTIONS = [
  { label: 'Confetti', value: 'CONFETTI' },
  { label: 'Sparkles', value: 'SPARKLES' },
];

export const CONFETTI_SHAPE_OPTIONS = [
  { label: 'Default', value: 'DEFAULT' },
  { label: 'Square', value: 'SQUARE' },
  { label: 'Rectangle', value: 'RECTANGLE' },
  { label: 'Circle', value: 'CIRCLE' },
  { label: 'Rounded Square', value: 'ROUNDED_SQUARE' },
  { label: 'Rounded Rectangle', value: 'ROUNDED_RECTANGLE' },
  { label: 'Star', value: 'STAR' },
];

export const CONFETTI_EMOJI_OPTIONS = [
  { label: '100', value: 'ONE_HUNDRED' },
  { label: 'Clapping', value: 'CLAPPING' },
  { label: 'Collision', value: 'COLLISION' },
  { label: 'Cool', value: 'COOL' },
  { label: 'Cowboy', value: 'COWBOY' },
  { label: 'Fire', value: 'FIRE' },
  { label: 'Grinning Face', value: 'GRINNING_FACE' },
  { label: 'Heart', value: 'HEART' },
  { label: 'Heart Eyes', value: 'HEART_EYES' },
  { label: 'Money Face', value: 'MONEY_FACE' },
  { label: 'Party Face', value: 'PARTY_FACE' },
  { label: 'Party Popper', value: 'PARTY_POPPER' },
  { label: 'Raising Hands', value: 'RAISING_HANDS' },
  { label: 'Rocket', value: 'ROCKET' },
  { label: 'Star', value: 'STAR' },
  { label: 'Starstruck', value: 'STAR_STRUCK' },
  { label: 'Sunglasses', value: 'SUNGLASSES' },
  { label: 'Thumbs Up', value: 'THUMBS_UP' },
];

export const EMOJII_MAP = {
  ONE_HUNDRED: oneHunded,
  CLAPPING: clapping,
  COLLISION: collision,
  COOL: cool,
  COWBOY: cowboy,
  FIRE: fire,
  GRINNING_FACE: grinningFace,
  HEART: heart,
  HEART_EYES: heartEyes,
  MONEY_FACE: moneyFace,
  PARTY_FACE: partyFace,
  PARTY_POPPER: partyPopper,
  RAISING_HANDS: raisingHands,
  ROCKET: rocket,
  STAR: star,
  STAR_STRUCK: startStruck,
  SUNGLASSES: sunglasses,
  THUMBS_UP: thumbsUp,
};

export const CONFETTI_EMOJI_DROPDOWN_OPTIONS = CONFETTI_EMOJI_OPTIONS.map(item => {
  return {
    label: (
      <div className="flex items-center">
        {item.label} <img className="ml-2 w-5 h-5" alt={item.label} src={EMOJII_MAP[item.value]} />
      </div>
    ),
    value: item.value,
  };
});

export const SHAPE_MAP = {
  SQUARE: 'square',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle',
  ROUNDED_SQUARE: 'roundedSquare',
  ROUNDED_RECTANGLE: 'roundedRectangle',
  STAR: 'star',
};
