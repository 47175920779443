import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { WinRedChartTitle, ReportingTrendContainer } from 'modules/common';

function ChartHeader({ children, title, subtitle, results, headerLength }) {
  const trendLength = 12 - headerLength;

  return (
    <Container fluid className="mb-5">
      <Row className="d-flex justify-content-between">
        <Col sm={headerLength}>
          <WinRedChartTitle title={title} subtitle={subtitle} results={results} />
        </Col>
        <Col sm={trendLength}>
          <ReportingTrendContainer>{children}</ReportingTrendContainer>
        </Col>
      </Row>
    </Container>
  );
}

ChartHeader.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  results: PropTypes.array,
  headerLength: PropTypes.number,
};

ChartHeader.defaultProps = {
  headerLength: 6,
};

export default ChartHeader;
