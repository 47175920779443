import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableHeaderCell = ({ children, className }) => (
  <th
    scope="col"
    role="columnheader"
    className={classNames('c--lynx', 'tracking-widest', 'leading-4', 'font-tiny', className)}
  >
    {children}
  </th>
);

TableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TableHeaderCell;
