import produce from 'immer';

import {
  SET_PAGES_FILTER,
  SET_CONNECTED_PAGES_FILTER,
  SET_TEAM_PAGES_FILTER,
  SET_UPSELLS_FILTER,
  SET_CAMPAIGNS_FILTER,
  SET_UPSELLS_ACTIVE_TAB,
  SET_PATHWAYS_FILTER,
  SET_PATHWAYS_ACTIVE_TAB,
} from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialPagesState = {
  pagesFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    page: 1,
    status: 'all',
    activeTabKey: 'all',
    fees: 'all',
  },
  /* We have to do tabbing differently here because on the upsell flows page, the
  upsells tab needs to act like it's own page, not a filter for upsell flows. This
  gives us the ability to clear the search filter without changing tags. */
  activeUpsellTab: 'upsell-pathways',
  upsellFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    page: 1,
    status: 'all',
    type: null,
  },
  activePathwaysTab: 'petition-pathways',
  pathwaysFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    page: 1,
    status: 'all',
    type: null,
    pathwayType: 'PETITION',
  },
  connectedPagesFilter: {
    activeTabKey: 'other',
    page: 1,
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  },
  teamPagesFilter: {
    page: 1,
  },
  campaignsFilter: {
    page: 1,
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  },
};

export default produce((state = initialPagesState, action) => {
  switch (action.type) {
    case SET_PAGES_FILTER:
      return { ...state, pagesFilter: action.filter };
    case SET_UPSELLS_FILTER:
      return { ...state, upsellFilter: action.filter };
    case SET_UPSELLS_ACTIVE_TAB:
      return { ...state, activeUpsellTab: action.tab };
    case SET_PATHWAYS_FILTER:
      return { ...state, pathwaysFilter: action.filter };
    case SET_PATHWAYS_ACTIVE_TAB:
      return { ...state, activePathwaysTab: action.tab };
    case SET_CONNECTED_PAGES_FILTER:
      return { ...state, connectedPagesFilter: action.filter };
    case SET_TEAM_PAGES_FILTER:
      return { ...state, teamPagesFilter: action.filter };
    case SET_CAMPAIGNS_FILTER:
      return { ...state, campaignsFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialPagesState };

    default:
      return state;
  }
}, initialPagesState);
