import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Typography, Icon } from 'modules/common';

function ButtonGroup({ name, onChange, value, buttons, title, generateValue, disabled }) {
  return (
    <div className="mb-4">
      {title ? <Typography variant="h5">{title}</Typography> : null}

      <div className="d-flex align-items-center justify-content-between">
        {buttons.map((button, i) => {
          let buttonLabel = button?.label ?? button;
          const buttonValue = button?.value ?? button;

          const isActive = buttonValue.toUpperCase() === value?.toUpperCase();

          if (generateValue) {
            buttonLabel = <Icon icon={generateValue(buttonLabel)} color={isActive ? 'white' : 'default'} />;
          }

          return (
            <Button
              variant={isActive ? 'primary' : 'default'}
              color={isActive ? 'white' : 'default'}
              alt={!isActive}
              square
              fullWidth
              size="lg"
              key={i}
              disabled={disabled}
              className={classnames('text-capitalize', { 'mr-2': i !== buttons.length - 1 })}
              onClick={() => {
                onChange({ target: { name, value: buttonValue.toUpperCase() } });
              }}
            >
              {buttonLabel}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  generateValue: PropTypes.func,
};

export default ButtonGroup;
