import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import {
  Icon,
  FormCardSpaced,
  Typography,
  FloatGroup,
  Input,
  WinRedChip,
  useLocalStorage,
  Select,
  SpinnerContainer,
} from 'modules/common';
import { createCampaignOptions } from 'modules/pages/common/page-common.tools';

import { GET_UPSELL_CAMPAIGN_OPTIONS, GET_ORGANIZATION_UPSELL_CAMPAIGN_OPTIONS } from 'core/middleware/queries';
import { profileCandidateSelector, isOrganizationSelector } from 'core/login';

function FlowDetails({ editedUpsellGroup, onUpdateEditedUpsellGroup }) {
  const [showUpsellFlowCampaign, setShowUpsellFlowCampaign] = useLocalStorage('upsellFlowCampaign', false);

  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const [campaignOptions, setCampaignOptions] = useState([]);

  const [loadOptions, { loading, called }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_UPSELL_CAMPAIGN_OPTIONS : GET_UPSELL_CAMPAIGN_OPTIONS,
    {
      onCompleted: data => {
        setCampaignOptions(createCampaignOptions(data));
      },
    }
  );

  // lazy load results after 3 chars min
  useEffect(() => {
    // (called && !loading) captures the use case where the query finished and there was no data to return
    if (!showUpsellFlowCampaign || campaignOptions.length > 0 || (called && !loading)) return;
    loadOptions({ variables: { organizationRevvUid: profileCandidate.organization_revv_uid } });
  }, [loadOptions, showUpsellFlowCampaign, profileCandidate.organization_revv_uid, campaignOptions, called, loading]);

  const selectedCampaign = campaignOptions.find(
    campaign => campaign.value.revv_uid === editedUpsellGroup.campaign?.revv_uid
  );

  const toggleExtraOptions = () => setShowUpsellFlowCampaign(state => !state);

  const onUpdateCampaign = ({ target: { value } }) => {
    onUpdateEditedUpsellGroup({ target: { name: 'campaign', value: { revv_uid: value?.revv_uid } } });
  };

  return (
    <FormCardSpaced
      className="mb-3 mt-0"
      classNameFooter="bg--catskill-white"
      title={
        <div className="d-flex mb-1">
          Upsell Flow Details
          <div className="clickable ml-auto" onClick={toggleExtraOptions}>
            <Icon role="button" icon={showUpsellFlowCampaign ? 'cog-solid' : 'cog'} />
          </div>
        </div>
      }
      footer={
        loading ? (
          <SpinnerContainer className="pt-0" />
        ) : showUpsellFlowCampaign ? (
          <FloatGroup className="mb-0">
            <Select
              name="campaign"
              isClearable
              onChange={onUpdateCampaign}
              floatLabel="Campaign"
              placeholder="Select Campaign..."
              value={selectedCampaign}
              options={campaignOptions}
              description="Add a standard campaign to your Upsell Flow to track performance. This campaign will override campaigns set on individual upsells."
            />
          </FloatGroup>
        ) : null
      }
    >
      <Typography>
        Add an Upsell Flow to your page to maximize your donors' value. Upsell Flows are a series of additional asks you
        can make to donors after they make a donation.
      </Typography>
      <Typography>
        Each step in the Upsell Flow is called an Upsell. You can add as many Upsells to your Upsell Flow as you want.
      </Typography>

      {editedUpsellGroup?.public_upsell_group ? (
        <WinRedChip className="my-3" variant="yellow">
          Public Upsell Flow
        </WinRedChip>
      ) : null}

      <FloatGroup className="mb-0">
        <Input
          type="text"
          id="name"
          name="name"
          value={editedUpsellGroup?.name || ''}
          disabled={editedUpsellGroup?._isDisabled}
          onChange={onUpdateEditedUpsellGroup}
          ariaLabel="Internal Upsell Flow Name"
          placeholder="Internal Upsell Flow Name*"
          validators={['required']}
          errorMessages={['Internal Upsell Flow Name is required.']}
        />
      </FloatGroup>
    </FormCardSpaced>
  );
}

FlowDetails.propTypes = {
  editedUpsellGroup: PropTypes.object.isRequired,
  onUpdateEditedUpsellGroup: PropTypes.func.isRequired,
};

export default FlowDetails;
