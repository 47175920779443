import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DrawerHeader,
  EditableList,
  FlexBinaryChoiceRow,
  FormCardSpaced,
  Icon,
  Input,
  NestedDrawer,
  SpinnerContainer,
  WinRedInput,
  WinRedSelectSearch,
  useToast,
} from 'modules/common';
import {
  convertCandidatesForUi,
  formatDuplicateTemplateForServer,
  generateDefaultTemplateData,
  generateTemplateFormData,
  generateVendorFee,
  hasConduitVendors,
} from '../../page.tools';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ORGANIZATION_PAGE_COMMITTEES, GET_PAGE_COMMITTEES } from 'core/middleware/queries';
import { Col, Row } from 'react-bootstrap';
import { replaceSpacesWithDashes, sortByLabel, urlSlugValidator } from 'core/utilities';
import { noop } from 'lodash';
import { ValidationBlock } from 'core/validation';
import {
  CREATE_ORGANIZATION_DUPLICATION_TEMPLATE,
  UPDATE_ORGANIZATION_DUPLICATION_TEMPLATE,
} from 'core/middleware/queries/pages/templates.organization.queries';
import {
  CREATE_CANDIDATE_DUPLICATION_TEMPLATE,
  UPDATE_CANDIDATE_DUPLICATION_TEMPLATE,
} from 'core/middleware/queries/pages/templates.queries';

export const PageTemplateDrawer = ({
  open,
  onClose,
  template,
  profileCandidate,
  isOrganization,
  page,
  setTemplateDrawer,
  refetch,
}) => {
  const [allVendors, setAllVendors] = useState([]);
  const [availableVendors, setAvailableVendors] = useState([]);
  const [formData, setFormData] = useState(generateDefaultTemplateData());
  const [originalData, setOriginalData] = useState(generateDefaultTemplateData());
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const setToast = useToast();
  const pageTemplateValidationBlock = createRef();

  const [createDuplicationTemplate, { error: createTemplateError }] = useMutation(
    isOrganization ? CREATE_ORGANIZATION_DUPLICATION_TEMPLATE : CREATE_CANDIDATE_DUPLICATION_TEMPLATE
  );
  const [updateDuplicationTemplate, { error: updateTemplateError }] = useMutation(
    isOrganization ? UPDATE_ORGANIZATION_DUPLICATION_TEMPLATE : UPDATE_CANDIDATE_DUPLICATION_TEMPLATE
  );

  const savePageTemplate = async (isEdit, template) => {
    if (pageTemplateValidationBlock.current.errors()) {
      setToast({
        message: 'Please correct the errors on this page before saving!',
        isError: true,
      });
      return;
    }
    const variables = {
      template: formatDuplicateTemplateForServer(template),
    };
    if (isOrganization) {
      variables.template.organizationRevvUid = profileCandidate.organization_revv_uid;
    } else {
      variables.organizationRevvUid = profileCandidate.organization_revv_uid;
    }
    const responseDataName = `${isOrganization ? 'organization' : 'candidate'}${
      isEdit ? 'Update' : 'Create'
    }PageDuplicationTemplate`;
    try {
      const saveFunction = isEdit ? updateDuplicationTemplate : createDuplicationTemplate;
      const response = await saveFunction({ variables });
      const { errors } = response.data[responseDataName];
      if (errors) {
        setToast({ message: errors, isError: true });
        return;
      }
      refetch();
      setToast({ message: `Your template has been ${isEdit ? 'updated' : 'created'}.` });
      setTemplateDrawer({ display: false, selectedTemplate: {}, isEdit: false });
    } catch (error) {
      setToast({ message: error, isError: true });
      console.error(error.message);
    }
  };

  const vendorFeeSumValidation = () => {
    if (!formData.partnership_templates) return true;
    const sumOfVendorFees = formData.partnership_templates.reduce((sum, curr) => {
      if (curr._destroy) return sum;
      return parseFloat(curr.feePercentage || 0) + sum;
    }, 0);

    return isNaN(sumOfVendorFees) || sumOfVendorFees < 100;
  };

  const onChangeFormData = ({ target: { name, value } }) => {
    let newData = { ...formData, [name]: value };
    if (newData !== originalData) {
      setIsSaveDisabled(false);
    }
    setFormData(newData);
  };

  const onChangeSlug = ({ target: { name, value } }) => {
    onChangeFormData({ target: { name, value: replaceSpacesWithDashes(value) } });
  };

  const [loadCommittees, { loading, error }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_PAGE_COMMITTEES : GET_PAGE_COMMITTEES,
    {
      variables: { revv_uid: page?.revv_uid, organizationRevvUid: profileCandidate?.organization_revv_uid },
      onCompleted: data => {
        // add new fields to page object then update page object so it looks like conduits were always attached to page
        const candidate = data.viewer.candidate || data.viewer.state_level_organization;
        const [conduitPage] = candidate.pages?.results;

        let newPage = {
          ...page,
          conduitPageCandidates: conduitPage?.conduitPageCandidates,
          conduitPageOrganizations: conduitPage?.conduitPageOrganizations,
        };

        newPage = convertCandidatesForUi(newPage, false);
        // keep track so we know when to show billpay info
        newPage._conduitsHaveVendors = hasConduitVendors(newPage.conduitPageCandidates);
        const theCandidate = newPage?.conduitPageCandidates[0][isOrganization ? 'organization' : 'candidate'];
        let formattedVendors = theCandidate.available_vendors
          .filter(vendor => {
            // if the fee exists on the candidate already then remove it but only if it's not destroyed
            const matchingCandidateFee = newPage.conduitPageCandidates.partnership_templates?.find(
              fee => fee.partnershipId === vendor.id
            );

            return !matchingCandidateFee || matchingCandidateFee._destroy;
          })
          .map(vendor => ({ label: vendor.name, value: vendor }))
          .sort(sortByLabel);
        setAvailableVendors(formattedVendors);
        setAllVendors(formattedVendors);
      },
    }
  );

  useEffect(() => {
    if (open) {
      loadCommittees();
      let data = template?.isEdit ? generateTemplateFormData(template) : generateDefaultTemplateData();
      setOriginalData(data);
      setFormData(data);
    }
  }, [open, template, loadCommittees, setFormData]);

  let backdropRef = useRef();
  const setbackdropRef = node => (backdropRef = node);

  const handleOnClose = () => {
    setIsSaveDisabled(true);
    onClose();
  };

  const handleClickOutside = event => {
    const clickedBackdrop = open && backdropRef && backdropRef.contains(event.target);

    if (clickedBackdrop) {
      handleOnClose();
      return;
    }
  };

  const onSelectVendor = ({ target: { value } }, vendor) => {
    let isEdit = template?.isEdit;
    let selectedId = isEdit ? 'partnershipId' : 'id';
    let newVendorFees = formData.partnership_templates.map(item => {
      if (vendor[selectedId] === item[selectedId]) {
        let newVendor = {
          ...item,
          name: value.name,
          feePercentage: value.defaultFeePercentage,
        };
        if (vendor?._isNew) newVendor.partnershipId = value.id;
        return newVendor;
      }
      return item;
    });
    setAvailableVendors(availableVendors.filter(item => item?.value?.id !== value.id));
    if (
      formData.partnership_templates.filter(item => !item._destroy).length === 1 &&
      formData.name === '' &&
      formData.slug === ''
    ) {
      let newFormData = {
        ...formData,
        partnership_templates: newVendorFees,
        name: value.name,
        slug: value.name
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^0-9a-z_-]/g, ''),
      };
      setFormData({ ...newFormData });
      setIsSaveDisabled(false);
    } else {
      onChangeFormData({ target: { name: 'partnership_templates', value: newVendorFees } });
    }
  };

  const handleOnSave = () => {
    savePageTemplate(template?.isEdit, formData);
  };

  if ((error || createTemplateError || updateTemplateError) & open) {
    setToast({ message: error || createTemplateError || updateTemplateError, isError: true });
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  return (
    <NestedDrawer
      className="page-template-drawer"
      setbackdropRef={setbackdropRef}
      open={open}
      footer={
        <div className="p-3">
          <Button variant="alt" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button variant="success" className="mx-2" onClick={handleOnSave} disabled={isSaveDisabled}>
            Save
          </Button>
        </div>
      }
      isNested
    >
      <DrawerHeader title="Create Page Duplication Template" toggleOpen={handleOnClose} />
      <>
        <ValidationBlock ref={pageTemplateValidationBlock}>
          <FormCardSpaced
            title="Bill-Pay(s) (Vendor Fees)"
            subtitle="Allow vendors to collect a share of the revenue that comes from this page for this specific committee."
          >
            <Input
              className="my-1"
              hidden
              value={''}
              onChange={noop}
              validators={[vendorFeeSumValidation]}
              errorMessages={['The sum of all vendor fees cannot be more than or equal to 100%.']}
            />
            {/* Editable Vendor List */}
            {loading ? (
              <SpinnerContainer />
            ) : (
              <EditableList
                isRequired
                items={formData.partnership_templates || []}
                onChange={onChangeFormData}
                eventName="partnership_templates"
                newItem={generateVendorFee}
                addFieldText="Add Vendor"
                maxNumberOfItems={5}
              >
                {({ updateItem, removeItem }) => {
                  return (
                    <>
                      {formData.partnership_templates?.map(vendor => {
                        if (vendor._destroy) return null;
                        const _updateItem = event => updateItem(event, vendor);
                        const _selectedVendor = allVendors?.find(o => o.label === vendor.name);
                        const _removeItem = () => {
                          if (_selectedVendor) {
                            setAvailableVendors([...availableVendors, _selectedVendor]);
                          }
                          removeItem(vendor);
                        };

                        return (
                          <Row className="flex my-3 justify-between items-start" key={vendor.id}>
                            <Col xs={12} sm={7} className="pr-2 mb-2 sm:mb-0 sm:pr-0">
                              <WinRedSelectSearch
                                wrapperClassName="m-0"
                                name="name"
                                onChange={event => onSelectVendor(event, vendor)}
                                floatLabel="Vendor"
                                placeholder="Select Vendor..."
                                maxLength={100}
                                value={_selectedVendor || ''}
                                options={availableVendors || []}
                                validators={[{ validator: 'required', message: 'Vendor is required.' }]}
                                disabled={vendor.name ? true : false}
                              />
                            </Col>
                            <Col xs={11} sm={4} className="pr-1">
                              <WinRedInput
                                name="feePercentage"
                                value={vendor.feePercentage || ''}
                                onChange={_updateItem}
                                moneyInput
                                max={100}
                                placeholder="Vendor Fee"
                                append="%"
                                doNotWrap
                                validators={[{ message: 'Vendor Fee is required.', validator: 'required' }]}
                              />
                            </Col>
                            <Col xs={1} sm={1} className="pr-0 self-center mb-0">
                              <Icon
                                className="text-align-right cursor-pointer"
                                icon="times"
                                size="lg"
                                onClick={_removeItem}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  );
                }}
              </EditableList>
            )}

            {/* Internal Page Name - name input */}
            <WinRedInput
              classNameContainer="mt-3"
              description="Append an Internal Page Name for this template. This name is not publicly viewable."
              name="name"
              placeholder="Internal Page Name*"
              prepend={<span className="italic">Internal Page Name</span>}
              type="text"
              validators={[{ message: 'Internal Page Name is required.', validator: 'required' }]}
              value={formData.name}
              onChange={onChangeFormData}
              validateOnEveryChange
            />
            <WinRedInput
              description="Append a URL Slug for this template. Ensure that your slug appendage is short, as URL Slugs combined with the appendage can not exceed 50 characters."
              name="slug"
              placeholder="URL Slug*"
              prepend={<span className="italic">URL Slug</span>}
              type="text"
              validators={[
                { message: 'URL Slug is required.', validator: 'required' },
                {
                  message: 'URL slug can only contain lowercase letters, numbers, dashes and underscores.',
                  validator: urlSlugValidator,
                },
                { message: 'URL Slug cannot be over 50 characters', validator: slug => slug.length <= 50 },
              ]}
              value={formData.slug}
              onChange={onChangeSlug}
              validateOnEveryChange
            />
            {/* Internal Page Name - slug input */}
          </FormCardSpaced>
          <FormCardSpaced>
            <FlexBinaryChoiceRow
              title="Upsell Bill-Pay"
              subTitle='When "Yes" is selected, any Bill-Pay on this page will automatically be applied to Double Donation Upsells and any Bill-Pay that has been added to Donation or Panel Upsells will be enforced. When "No" is selected, Bill-Pay on any upsell will not be enforced. Note: WinRed processing fees and relevant merchandise fees will apply to Upsells regardless of "Yes" or "No".'
              value={formData.upsell_agency_fees_enabled}
              name="upsell_agency_fees_enabled"
              onChange={onChangeFormData}
            />
          </FormCardSpaced>
          <FormCardSpaced>
            <FlexBinaryChoiceRow
              title="Subscription Bill-Pay"
              subTitle='When "Yes" is selected, subsequent subscription donations will have the same Bill-Pay as the initial donation. When "No" is selected, subsequent subscription donations will not have any Bill-Pay. Note: WinRed processing fees and relevant merchandise fees will apply to Upsells regardless of "Yes" or "No".'
              value={formData.recurring_agency_fees_enabled}
              name="recurring_agency_fees_enabled"
              onChange={onChangeFormData}
            />
          </FormCardSpaced>
          <FormCardSpaced
            classNameFooter="bg--catskill-white"
            footer={
              formData.source_code_enabled ? (
                <>
                  <WinRedInput
                    name="source_code"
                    placeholder="Source Code"
                    type="text"
                    validators={
                      formData.source_code_enabled && [{ message: 'Source Code is required.', validator: 'required' }]
                    }
                    value={formData.source_code}
                    onChange={onChangeFormData}
                  />
                </>
              ) : null
            }
          >
            <FlexBinaryChoiceRow
              title="Source Code"
              subTitle="Toggle to ‘Yes’ to add Source Code to this template. This will replace any existing Source Code, not append to it."
              value={formData.source_code_enabled}
              name="source_code_enabled"
              onChange={onChangeFormData}
            />
          </FormCardSpaced>
        </ValidationBlock>
      </>
    </NestedDrawer>
  );
};

PageTemplateDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  page: PropTypes.object,
  setTemplateDrawer: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};
