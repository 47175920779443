import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';

import {
  ListHeader,
  BasicFilter,
  IndexList,
  withFilter,
  withDownloadReport,
  withAmplitude,
  Button,
  dateRangeFilterUi,
  searchFilterUi,
  amountFilterUi,
  FilterItems,
  dropdownFilterUi,
} from 'modules/common';
import { setToast } from 'core/toast';
import { GET_TEAM_DONATIONS_QUERY, GET_TEAM_DONATIONS_REPORT } from 'core/middleware/queries';
import { initialRevenueState, setTeamDonationsFilter, teamDonationsFilterSelector } from './store';
import { TeamDonationsListBody, queryBuilder, TeamDonationTrends } from './team-donations';

export const mapFilterToUi = (filter, navTabs) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: navTabs,
    filterKey: 'activeTabKey',
    label: 'Donation Type',
    defaultValue: 'all',
  });

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = amountFilterUi({ filter, uiFilter, numberOfFilters });

  return [uiFilter, numberOfFilters];
};

function TeamsDonationsList(props) {
  const [uiFilter, numberOfFilters] = useMemo(() => mapFilterToUi(props.filter), [props.filter]);

  const renderHeader = () => {
    const disableDownload = !props.data.viewer || (props.data.viewer && props.data.viewer.donations.total_count === 0);

    return (
      <ListHeader
        title={props.listKey}
        activeTabKey={props.filter.activeTabKey}
        actionsBar={
          <>
            <BasicFilter
              filter={props.filterState}
              clearFilter={props.clearFilter}
              submitFilter={props.submitFilter}
              onChangeFilter={props.onChangeFilter}
              searchPlaceholder={props.listKey}
              numberOfFilters={numberOfFilters}
              noSearch
              actionComponent={<TeamDonationTrends data={props.data} />}
            ></BasicFilter>
            <Button variant="primary" size="lg" disabled={disableDownload} onClick={props.downloadReport}>
              Download
            </Button>
          </>
        }
      />
    );
  };

  const createNewTeamPage = () => {
    props.logEvent('create team page');
    props.history.push('/team-pages/pages/new');
  };

  return (
    <IndexList data={props.data} renderHeader={renderHeader} pageTitle={props.listKey} noCandidate>
      <>
        <FilterItems onChangeFilter={props.onChangeFilter} uiFilter={uiFilter} />
        <TeamDonationsListBody
          createNewTeamPage={createNewTeamPage}
          onChangeFilter={props.onChangeFilter}
          donations={props.data.viewer && props.data.viewer.donations}
        />
      </>
    </IndexList>
  );
}

TeamsDonationsList.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  logEvent: PropTypes.func.isRequired,
  data: PropTypes.object,
  reportQuery: PropTypes.object.isRequired,
  queryBuilder: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,

  initialState: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  downloadReportKey: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  setReduxFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    filter: teamDonationsFilterSelector(state),
    downloadReportKey: 'export_donations_report',
    listKey: 'Team Donations',
    initialState: initialRevenueState.teamDonationsFilter,
    reportQuery: GET_TEAM_DONATIONS_REPORT,
    queryBuilder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
    setReduxFilter: filter => dispatch(setTeamDonationsFilter(filter)),
  };
};

export default compose(
  withAmplitude,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_TEAM_DONATIONS_QUERY, {
    options: props => ({ variables: queryBuilder(props.filter) }),
  }),
  withFilter,
  withDownloadReport
)(TeamsDonationsList);
