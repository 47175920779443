import React from 'react';
import PropTypes from 'prop-types';
import { Icon, FormCardSpaced, Card } from 'modules/common';
import { UPSELL_BILL_PAY_INFO } from './config';

const BillPayInfo = ({ upsellType }) => {
  return (
    <FormCardSpaced
      classNameBody="bg-catskill"
      title={
        <div className="d-flex align-items-center">
          <Icon icon="info-circle" size="lg" className="flex-initial mr-2" />
          <Card.Title className="text-mirage">Bill-Pay Information</Card.Title>
        </div>
      }
    >
      <ul className="ml-4">
        {UPSELL_BILL_PAY_INFO[upsellType]?.map((info, index) => (
          <li key={index} className="break-normal list-disc list-outside p text-lynx">
            {info}
          </li>
        ))}
      </ul>
    </FormCardSpaced>
  );
};

BillPayInfo.propTypes = {
  upsellType: PropTypes.string.isRequired,
};

export { BillPayInfo };
