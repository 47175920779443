import gql from 'graphql-tag';

export const VENDOR_SETTINGS_OWNERS_MUTATION = gql`
  mutation saveVendorOwnersSettings(
    $owners: [AccountOwnerInput!]
    $organizationRevvUid: String
    $vendorRevvUid: String!
  ) {
    updateVendorAccountOwners(
      owners: $owners
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors {
        revv_uid
        messages
      }
      owners {
        revv_uid
        email
        first_name
        last_name
        title
        phone
        dob_day
        dob_month
        dob_year
        ssn_last_4
        full_ssn_token_provided
        address
        address2
        city
        state
        zip
        stripe_identity_document_front_id
        stripe_identity_document_back_id
        account_opener
        owner
        status
      }
    }
  }
`;

export const REMOVE_VENDOR_ACCOUNT_OWNER_MUTATION = gql`
  mutation removeVendorAccountOwner($organizationRevvUid: String, $vendorRevvUid: String!, $revvUid: String!) {
    removeVendorAccountOwner(
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
      revvUid: $revvUid
    ) {
      status
      errors
    }
  }
`;
