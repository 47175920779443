import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import './trend.scss';

function TrendContainer({ children, className = '' }) {
  return (
    <Container fluid className="trends-bar">
      <Row className="justify-content-center">
        <Col lg={11} className="px-3">
          <Row className={className}>{children}</Row>
        </Col>
      </Row>
    </Container>
  );
}

TrendContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TrendContainer;
