import gql from 'graphql-tag';

export const exportFragment = gql`
  fragment ReportActivities on ReportActivityResult {
    page_count
    total_count
    current_page
    results {
      revv_uid
      created_at
      recipient
      report_type
      number_of_records
      name
      filename
      delivery_method
      report_recurrence
      timezone
      author
      status
      download_url
    }
  }
`;

export const GET_EXPORTS_QUERY = gql`
  query getReportActivities(
    $page: Int
    $limit: Int
    $revvUid: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $reportRecurrence: ReportRecurrenceTypeEnum
    $reportType: ReportActivityTypeEnum
    $reportStatus: ReportStatusTypeEnum
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        report_activity(
          filters: {
            page: $page
            limit: $limit
            revvUid: $revvUid
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            reportRecurrence: $reportRecurrence
            reportType: $reportType
            reportStatus: $reportStatus
          }
        ) {
          ...ReportActivities
        }
      }
    }
  }
  ${exportFragment}
`;
