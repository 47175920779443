import React from 'react';
import PropTypes from 'prop-types';

import { convertEpochDate, isSameYear, UI_DATE_FORMAT, MONTH_DATE_FORMAT } from 'core/utilities';
import { Typography } from 'modules/common';
import './charts.scss';

function ChartTitle({ title, subtitle, results }) {
  const [startDate, endDate] = [results[0].date, results[results.length - 1].date];

  const startDateFormat = isSameYear(startDate, endDate) ? MONTH_DATE_FORMAT : UI_DATE_FORMAT;
  const dateRange = `${convertEpochDate(startDate, startDateFormat)} - ${convertEpochDate(endDate, UI_DATE_FORMAT)}`;

  return (
    <>
      <Typography className="mb-0" variant="h4">
        {`${title} for ${dateRange}`}
      </Typography>
      <Typography className="mb-0">{subtitle}</Typography>
    </>
  );
}

ChartTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  results: PropTypes.array,
};

export default ChartTitle;
