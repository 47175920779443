import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Typography } from 'modules/common';
import { formatAmount, formatNumberWithCommas } from 'core/utilities';

import './charts.scss';
import 'modules/common/trends/trend.scss';

export function ReportingTrend({ className, name, number, amount, divide, isCents }) {
  return (
    <div
      className={classnames('trend-box pl-0 my-md-0 my-2', className, {
        'trends-divider px-md-3': divide,
        'pl-md-3': !divide,
      })}
    >
      <Typography className="mb-1 d-flex align-items-center">
        <span className="trends-label">{name}</span>
      </Typography>
      <Typography className="mb-0" variant="h4">
        {number ? formatNumberWithCommas(number) : formatAmount(amount, isCents)}
      </Typography>
    </div>
  );
}

ReportingTrend.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amount: PropTypes.number,
  divide: PropTypes.bool,
  isCents: PropTypes.bool,
  percentChange: PropTypes.string,
  percentColor: PropTypes.string,
};

export default ReportingTrend;

export function ReportingTrendContainer({ children }) {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-end reporting-trends-bar">
      {children}
    </div>
  );
}

ReportingTrendContainer.propTypes = {
  children: PropTypes.any,
};
