export const SET_PAGES_FILTER = 'SET_PAGES_FILTER';

export const SET_UPSELLS_FILTER = 'SET_UPSELLS_FILTER';

export const SET_UPSELLS_ACTIVE_TAB = 'SET_UPSELLS_ACTIVE_TAB';

export const SET_PATHWAYS_FILTER = 'SET_PATHWAYS_FILTER';

export const SET_PATHWAYS_ACTIVE_TAB = 'SET_PATHWAYS_ACTIVE_TAB';

export const SET_CONNECTED_PAGES_FILTER = 'SET_CONNECTED_PAGES_FILTER';

export const SET_TEAM_PAGES_FILTER = 'SET_TEAM_PAGES_FILTER';

export const SET_CAMPAIGNS_FILTER = 'SET_CAMPAIGNS_FILTER';
