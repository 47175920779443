import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from 'modules/common';

function Logout({ showLogoutModal, handleLogout }) {
  return (
    <ConfirmModal
      show={showLogoutModal}
      title="Sign Out"
      buttonText="Sign Out"
      buttonColor="primary"
      handleClose={() => handleLogout()}
      onSubmit={() => handleLogout(true)}
    >
      Are you sure you want to sign out?
    </ConfirmModal>
  );
}

Logout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  showLogoutModal: PropTypes.bool.isRequired,
};

export default Logout;
