import React from 'react';
import PropTypes from 'prop-types';

import { FormCard, FloatGroup, Input, Icon, useLocalStorage } from 'modules/common';
import AdditionalOptions from './additionalOptions.component';

function StandardCampaign({ campaign, onChange }) {
  const [showExtraOptions, setShowExtraOptions] = useLocalStorage('committeesExtraOptions', false);

  const toggleExtraOptions = () => setShowExtraOptions(state => !state);

  return (
    <FormCard
      title={
        <div className="d-flex mb-1">
          Campaign Name
          <div className="clickable ml-auto" onClick={toggleExtraOptions}>
            <Icon role="button" icon={showExtraOptions ? 'cog-solid' : 'cog'} />
          </div>
        </div>
      }
    >
      <FloatGroup>
        <Input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Campaign Name"
          maxLength="100"
          value={campaign.name}
          name="name"
          onChange={onChange}
          validators={['required']}
          errorMessages={['Campaign Name is required.']}
        />
      </FloatGroup>
      {showExtraOptions ? <AdditionalOptions campaign={campaign} onChange={onChange} /> : null}
    </FormCard>
  );
}

StandardCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StandardCampaign;
