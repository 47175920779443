import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FloatGroup, Typography, Button } from 'modules/common';
import { StepRequirementRow } from './step-requirement-row.component';
import uuid from 'core/utilities/uuid';
export function StepRequirements({
  editedStep,
  onUpdateEditedStep,
  conditionOptions,
  predicateOptions,
  customValueOptions,
  campaignsList,
  disabledStep,
  isPathways = false,
}) {
  const _onChangeReq = useCallback(
    ({ target: { name, value } }) => {
      onUpdateEditedStep({ target: { name: [name], value } });
    },
    [onUpdateEditedStep]
  );

  if (!editedStep) return null;

  if (editedStep)
    return (
      <>
        <div className="flex-row align-center">
          <Button square className="py-1 px-2 mr-1 click-disabled">
            IF
          </Button>
          <div className="inline-block">
            <Typography>{`Create requirements for your ${isPathways ? 'Condition' : 'Step'}.`}</Typography>
          </div>
        </div>

        <FloatGroup>
          <div>
            {editedStep?.conditions
              ?.filter(condition => condition._destroy !== true)
              ?.map((cond, index) => {
                return (
                  <div key={cond.revv_uid}>
                    {index > 0 ? (
                      <Typography variant="body" className="text-center m-1" key={uuid()}>
                        AND
                      </Typography>
                    ) : null}
                    {predicateOptions && (
                      <StepRequirementRow
                        editedStep={editedStep}
                        condition={cond}
                        conditionOptions={conditionOptions}
                        predicateOptions={predicateOptions}
                        customValueOptions={customValueOptions}
                        index={index}
                        onChange={_onChangeReq}
                        key={cond.revv_uid}
                        campaignsList={campaignsList}
                        disabledStep={disabledStep}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </FloatGroup>
      </>
    );
}

StepRequirements.propTypes = {
  editedStep: PropTypes.object,
  onUpdateEditedStep: PropTypes.func.isRequired,
  conditionOptions: PropTypes.array,
  predicateOptions: PropTypes.object,
  customValueOptions: PropTypes.object,
  campaignsList: PropTypes.array,
  disabledStep: PropTypes.bool,
  isPathways: PropTypes.bool,
};
