export const scssColors = {
  'jagged-ice': '#c4eadc',
  'oyster-pink': '#e7c6c8',
  'winred-green': '#61a578',
  nepal: '#94aac1',
  mystic: '#dbe1ec',
  lynx: '#627890',
  'winred-blue': '#003060',
  catskill: '#e9eef6',
  'winred-red': '#d93a4d',
  'winred-blue-light': '#345980',
  'catskill-white': '#f5f8fa',
  white: '#fff',
  'winred-red-light': '#dd4d5f',
  rose: '#fff8f9',
};
