import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';

import uuid from 'core/utilities/uuid';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  ListHeader,
  Button,
  IndexList,
  withFilter,
  withAmplitude,
  AmplitudePropTypes,
  ListFilter,
  Select,
  FilterItems,
  dateRangeFilterUi,
  searchFilterUi,
  dropdownFilterUi,
  ToolTip,
  Icon,
} from 'modules/common';

import { setToast } from 'core/toast';
import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import useRouterTabs from 'modules/common/hooks/useRouterTabs';
import {
  GET_UPSELLS_LIST_QUERY,
  GET_UPSELL_GROUPS_LIST_QUERY,
  GET_ORGANIZATION_UPSELL_GROUPS_LIST_QUERY,
  GET_ORGANIZATION_UPSELLS_LIST_QUERY,
  GET_ORGANIZATION_UPSELL_QUERY,
  GET_UPSELL_QUERY,
} from 'core/middleware/queries';
import { getCandidateDate } from 'core/utilities/time';
import {
  setUpsellActiveTab,
  setUpsellsFilter,
  upsellTabSelector,
  upsellsFilterSelector,
  initialPagesState,
} from './store';
import { UpsellFlowListBody, UpsellsListBody, queryBuilder } from './upsells';
import { generateUpsellDefaultCandidate } from './upsells/tools';
import {
  SlateUpsell,
  MobileUpsell,
  CustomUpsell,
  DoubleUpsell,
  PanelUpsell,
  RecurringUpsell,
  CreateUpsell,
  VolunteerUpsell,
  MoneyPledgeUpsell,
} from './upsells/drawer/new-upsell';
import './pages.scss';
import UpsellPathwaysListBody from './upsell-pathways/upsell-pathways-list-body';
import { GET_PATHWAYS_QUERY, GET_UPSELL_STEPS_QUERY } from '../../core/middleware/queries/pages/pathways.queries';
import {
  GET_ORGANIZATION_PATHWAYS_QUERY,
  GET_ORGANIZATION_UPSELL_STEPS_QUERY,
} from '../../core/middleware/queries/pages/pathways.organization.queries';
import { ConditionsListBody } from './upsells/conditions-list-body.component';
import cloneDeep from 'lodash/cloneDeep';
import { queryBuilderUpsellPathways } from './upsell-pathways/query-builder.component';

export const mapFilterToUi = filter => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: UPSELL_TYPES_OPTIONS,
    filterKey: 'type',
    label: 'Upsell Type',
  });

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });

  return [uiFilter, numberOfFilters];
};

export const upsellNavTabs = [
  { value: 'upsell-pathways', label: 'Upsell Pathways', url: '/pages/upsell-flows/pathways' },
  { value: 'upsells', label: 'Upsells', url: '/pages/upsell-flows/upsells' },
  { value: 'conditions', label: 'Conditions', url: '/pages/upsell-flows/conditions' },
  { value: 'upsell-flow', label: 'Upsell Flows (Legacy)', url: '/pages/upsell-flows' },
];

const sortByComponentLabel = (a, b) => {
  if (a.label > b.label) return 1;
  else return -1;
};

const UPSELL_TYPES_OPTIONS = [
  SlateUpsell,
  MobileUpsell,
  CustomUpsell,
  DoubleUpsell,
  PanelUpsell,
  RecurringUpsell,
  CreateUpsell,
  VolunteerUpsell,
  MoneyPledgeUpsell,
]
  .map(u => ({ label: u.title, value: u.type }))
  .sort(sortByComponentLabel);

function UpsellList(props) {
  const {
    data,
    activeTabKey,
    onChangeFilter,
    clearFilter,
    isOrganization,
    profileCandidate,
    handleTabChange,
    sendToast,
  } = props;

  const candidate = data?.viewer && (data.viewer.state_level_organization || data.viewer.candidate);
  const history = useHistory();
  const { upsellId } = useParams();

  const [isProcessingData, setIsProcessingData] = useState(false);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [activeTab, changeTab] = useRouterTabs(upsellNavTabs, activeTabKey);
  const [selectedUpsell, setSelectedUpsell] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [originalEditedCondition, setOriginalEditedCondition] = useState(null);

  const [getUpsell, upsellResult] = useLazyQuery(isOrganization ? GET_ORGANIZATION_UPSELL_QUERY : GET_UPSELL_QUERY);
  const [uiFilter, numberOfFilters] = mapFilterToUi(props.filter);

  const onEditSelectedCondition = ({ target: { name, value } }) => {
    setSelectedCondition({ ...selectedCondition, [name]: value });
  };

  const activeTabLabel = useMemo(() => {
    return activeTab?.value === 'upsell-flow' ? 'Upsell Flows' : activeTab?.label;
  }, [activeTab]);

  const activeTabSingular = useMemo(() => {
    return activeTabLabel.substring(0, activeTabLabel.length - 1);
  }, [activeTabLabel]);

  const searchFilterToolTip =
    activeTab?.value === 'upsells'
      ? 'Find an exact match by putting quotes around Upsell Name. e.g. "First Recurring Upsell"'
      : `Find an exact match by putting quotes around ${activeTabSingular} Name. e.g. "First ${activeTabSingular}"`;

  // If the tab changes via the URL, display the correct tab.
  useEffect(() => {
    if (activeTabKey !== activeTab.value) handleTabChange(activeTab.value);
  }, [activeTab, activeTabKey, handleTabChange]);

  // When the upsellId changes, fetch the correct upsell data.
  useEffect(() => {
    if (!upsellId) return;
    if (upsellId === 'new') {
      setSelectedUpsell({});
    } else {
      const variables = { revv_uid: upsellId, organizationRevvUid: profileCandidate.organization_revv_uid };
      try {
        getUpsell({ variables });
      } catch (error) {
        sendToast({
          message: error[0].message,
          isError: true,
        });
        history.push(`${activeTab.url}/`);
      }
    }
  }, [activeTab.url, getUpsell, history, profileCandidate.organization_revv_uid, sendToast, upsellId]);

  useEffect(() => {
    if (upsellResult.error) {
      sendToast({
        message: upsellResult.error,
        isError: true,
      });
      return;
    }

    if (!upsellResult.data) return;

    const candidate = upsellResult.data.viewer.state_level_organization || upsellResult.data.viewer.candidate;
    const { results } = candidate.upsells;

    // Clear the URL if no results are found.
    if (results.length === 0) {
      sendToast({ message: `Upsell ${upsellId} not found.`, isError: true });
      history.push(`${activeTab.url}/`);
    }
    const [upsell] = results;

    // save meta data for generating slate upsells
    const defaultCandidates = generateUpsellDefaultCandidate({
      profileCandidate,
      availablePacCandidates: candidate.available_pac_candidates.results,
    });

    upsell._defaultCandidates = defaultCandidates;

    setSelectedUpsell(upsell || null);
    setDrawerOpen(!!upsell);
  }, [activeTab.url, history, sendToast, upsellId, upsellResult.data, profileCandidate, upsellResult]);

  const toggleDrawer = (newUpsell, refresh) => {
    if (refresh) data.refetch();

    // If the drawer is being closed, refetch the list data.
    history.push(`${activeTab.url}/${newUpsell?.revv_uid || ''}`);

    if (isEmpty(selectedUpsell) && !newUpsell) setDrawerOpen(false);
  };

  const toggleDrawerCallBack = useCallback(
    (clickedCondition, refresh) => {
      if (clickedCondition) {
        setSelectedCondition(clickedCondition);
        setOriginalEditedCondition(cloneDeep(clickedCondition));
        setDrawerOpen(true);
      } else {
        setSelectedCondition(null);
        setOriginalEditedCondition(null);
      }
      if (refresh) data?.refetch();
      if (!clickedCondition) {
        setDrawerOpen(false);
      }
    },
    [setSelectedCondition, setDrawerOpen, setOriginalEditedCondition, data]
  );

  const handleCreateNew = () => {
    if (activeTab.value === 'upsell-flow') {
      history.push(`/${profileCandidate.organization_revv_uid}/pages/new-${activeTab.value}`);
    } else if (activeTab.value === 'upsell-pathways') {
      history.push(`/${profileCandidate.organization_revv_uid}/pages/new-upsell-pathway`);
    } else if (activeTab.value === 'conditions') {
      setSelectedCondition({ _isNew: true, revv_uid: uuid() });
      setDrawerOpen(true);
    } else {
      setSelectedUpsell({});
      setDrawerOpen(true);
    }
  };

  const handleTabChangeWithEvent = ({ target: { value: newTabKey } }) => {
    props.logEvent(`change tab ${newTabKey}`);
    handleTabChange(newTabKey);
    changeTab(newTabKey);
  };

  const renderHeader = () => {
    const type = UPSELL_TYPES_OPTIONS.find(o => o.value === props.filterState.type);
    return (
      <ListHeader
        title={props?.listKey}
        activeTabKey={activeTab?.value}
        onChangeFilter={handleTabChangeWithEvent}
        renderedAt={data?.renderedAt}
        onRefresh={data?.refetch}
        loading={data?.loading}
        navTabs={upsellNavTabs}
        actionsBar={
          <>
            <ToolTip tooltip={searchFilterToolTip} placement="bottom" className="mt-3 mr-2">
              <Icon icon="info-circle" color="gray" size="lg" />
            </ToolTip>
            <ListFilter
              submitFilter={props.submitFilter}
              clearFilter={clearFilter}
              onChangeFilter={onChangeFilter}
              filter={props.filterState}
              searchPlaceholder={activeTabLabel}
              numberOfFilters={numberOfFilters}
              dateHeader="Date Created"
            >
              {activeTab.value === 'upsells' && (
                <Select
                  placeholder="Select Upsell Type"
                  name="type"
                  onChange={props.onChangeFilter}
                  value={type}
                  options={UPSELL_TYPES_OPTIONS}
                />
              )}
            </ListFilter>
            <div className="create-upsell-btn">
              <Button variant="success" size="lg" ariaLabel="create a new upsell flow" onClick={handleCreateNew}>
                {/* Remove the trailing "s". */}
                Create {activeTabSingular}
              </Button>
            </div>
          </>
        }
      />
    );
  };

  return (
    <>
      <IndexList
        noTopPadding={numberOfFilters > 0}
        loading={isProcessingData}
        data={data}
        renderHeader={renderHeader}
        pageTitle={activeTabLabel}
      >
        <FilterItems onChangeFilter={onChangeFilter} uiFilter={uiFilter} />
        {activeTab.value === 'upsell-flow' ? (
          <UpsellFlowListBody
            onChangeFilter={onChangeFilter}
            candidate={candidate}
            handleCreateNewUpsell={handleCreateNew}
            setIsProcessingData={setIsProcessingData}
            refetch={data.refetch}
            filter={props.filter}
            clearFilter={clearFilter}
          />
        ) : activeTab.value === 'upsells' ? (
          <UpsellsListBody
            onChangeFilter={onChangeFilter}
            candidate={candidate}
            handleCreateNew={handleCreateNew}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            selectedUpsell={selectedUpsell}
            setIsProcessingData={setIsProcessingData}
            refetch={data.refetch}
            clearFilter={clearFilter}
            filter={props.filter}
          />
        ) : activeTab.value === 'conditions' ? (
          <ConditionsListBody
            onChangeFilter={onChangeFilter}
            candidate={candidate}
            handleCreateNew={handleCreateNew}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawerCallBack}
            selectedCondition={selectedCondition}
            originalEditedCondition={originalEditedCondition}
            onEditSelectedCondition={onEditSelectedCondition}
            setIsProcessingData={setIsProcessingData}
            refetch={data?.refetch}
            clearFilter={clearFilter}
            filter={props.filter}
          />
        ) : (
          <UpsellPathwaysListBody
            onChangeFilter={onChangeFilter}
            candidate={candidate}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={item => {
              history.push(`${activeTab.url}/${item?.revv_uid || ''}`);
            }} // on click, no drawer, nav to details
            selectedPathway={{}}
            setIsProcessingData={setIsProcessingData}
            refetch={data?.refetch}
            filter={props.filter}
            clearFilter={clearFilter}
          />
        )}
      </IndexList>
    </>
  );
}

UpsellList.propTypes = {
  data: PropTypes.object,
  handleTabChange: PropTypes.func.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  sendToast: PropTypes.func.isRequired,

  profileCandidate: PropTypes.object.isRequired,
  initialState: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  downloadReportKey: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  setReduxFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const initialUpsellTab = props.history.location.pathname.includes('upsells')
    ? 'upsells'
    : props.history.location.pathname.includes('pathways')
    ? 'upsell-pathways'
    : props.history.location.pathname.includes('conditions')
    ? 'conditions'
    : upsellTabSelector(state);

  return {
    activeTabKey: initialUpsellTab,
    filter: upsellsFilterSelector(state),
    downloadReportKey: 'export_upsell_flows_report',
    listKey: 'Upsells',
    initialState: initialPagesState.upsellFilter,
    profileCandidate: profileCandidateSelector(state),
    isOrganization: isOrganizationSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  handleTabChange: tab => {
    dispatch(setUpsellsFilter(initialPagesState.upsellFilter));
    dispatch(setUpsellActiveTab(tab));
  },
  sendToast: message => dispatch(setToast(message)),
  setReduxFilter: filter => dispatch(setUpsellsFilter(filter)),
});

export default compose(
  withAmplitude,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_UPSELL_GROUPS_LIST_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: { organizationRevvUid: props.profileCandidate.organization_revv_uid, ...queryBuilder(props.filter) },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'upsell-flow' || props.isOrganization,
  }),
  graphql(GET_ORGANIZATION_UPSELL_GROUPS_LIST_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: { ...queryBuilder(props.filter), organizationRevvUid: props.profileCandidate.organization_revv_uid },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'upsell-flow' || !props.isOrganization,
  }),
  graphql(GET_UPSELLS_LIST_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      variables: { organizationRevvUid: props.profileCandidate.organization_revv_uid, ...queryBuilder(props.filter) },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }),
    skip: props => props.activeTabKey !== 'upsells' || props.isOrganization,
  }),
  graphql(GET_ORGANIZATION_UPSELLS_LIST_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      variables: { ...queryBuilder(props.filter), organizationRevvUid: props.profileCandidate.organization_revv_uid },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }),
    skip: props => props.activeTabKey !== 'upsells' || !props.isOrganization,
  }),
  graphql(GET_PATHWAYS_QUERY, {
    props: ({ data }) => {
      if (!data.viewer) return { data };
      return { data: { ...data, renderedAt: getCandidateDate() } };
    },
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
        pathwayType: 'UPSELL',
        ...queryBuilderUpsellPathways(props.filter),
      },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'upsell-pathways' || props.isOrganization,
  }),
  graphql(GET_ORGANIZATION_PATHWAYS_QUERY, {
    props: ({ data }) => {
      if (!data.viewer) return { data };
      return { data: { ...data, renderedAt: getCandidateDate() } };
    },
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
        pathwayType: 'UPSELL',
        ...queryBuilderUpsellPathways(props.filter),
      },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'upsell-pathways' || !props.isOrganization,
  }),
  graphql(GET_UPSELL_STEPS_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      variables: {
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
        pathwayType: 'UPSELL',
        nodeType: 'CONDITION_NODE',
        ...queryBuilderUpsellPathways(props.filter),
      },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'conditions' || props.isOrganization,
  }),
  graphql(GET_ORGANIZATION_UPSELL_STEPS_QUERY, {
    props: ({ data }) => ({ data: { ...data, renderedAt: getCandidateDate() } }),
    options: props => ({
      variables: {
        organizationRevvUid: props.profileCandidate.organization_revv_uid,
        pathwayType: 'UPSELL',
        nodeType: 'CONDITION_NODE',
        ...queryBuilderUpsellPathways(props.filter),
      },
      notifyOnNetworkStatusChange: true,
    }),
    skip: props => props.activeTabKey !== 'conditions' || !props.isOrganization,
  }),
  withFilter
)(UpsellList);
