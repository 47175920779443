import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Card, Typography, Select, FormCardSpaced } from 'modules/common';
import { WIDGETS } from './tools';

function WidgetSelector({ onChangeWidgetType, widget, selectableWidgets, children, disabled }) {
  let selectedValue = selectableWidgets.find(widg => widg.value === widget.type);

  // widget may not be selectable since we only allow one of them but this particular widget may be that one
  // so we want to show it in the dropdown but only if its that one that is allow for that type
  let _selectableWidgets = [...selectableWidgets];
  if (widget.type && !selectedValue) {
    const unknownWidget = WIDGETS.find(w => w.value === widget.type);
    _selectableWidgets = [...selectableWidgets, unknownWidget];
    selectedValue = _selectableWidgets.find(widg => widg.value === widget.type);
  }

  return (
    <FormCardSpaced>
      <Row>
        <Col sm={12} md={6}>
          <Card.Title>Widget Type</Card.Title>
          <Typography>Select the type of widget to create.</Typography>
        </Col>
        <Col sm={12} md={6}>
          <Select
            label="Widget Type"
            floatLabel="Widget Type"
            placeholder="Select Widget..."
            disabled={widget.fromExisting || disabled}
            onChange={e => onChangeWidgetType(widget, e.target.value)}
            value={selectedValue}
            options={_selectableWidgets}
            numberOfDropdownItems={4}
            validators={['required']}
            errorMessages={['Widget Type is required.']}
          />
        </Col>
      </Row>
      <div className="mt-3">{children}</div>
    </FormCardSpaced>
  );
}

WidgetSelector.propTypes = {
  children: PropTypes.any,
  widget: PropTypes.object.isRequired,
  selectableWidgets: PropTypes.array.isRequired,
  onChangeWidgetType: PropTypes.func.isRequired,
};

export default WidgetSelector;
