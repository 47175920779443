import { SET_TRANSACTIONS_FILTER, SET_PAYOUTS_FILTER, SET_INVOICES_FILTER, SET_BLOCKED_FILTER } from './types';

export const setTransactionsFilter = filter => ({
  type: SET_TRANSACTIONS_FILTER,
  filter,
});

export const setPayoutsFilter = filter => ({
  type: SET_PAYOUTS_FILTER,
  filter,
});

export const setInvoicesFilter = filter => ({
  type: SET_INVOICES_FILTER,
  filter,
});

export const setBlockedFilter = filter => ({
  type: SET_BLOCKED_FILTER,
  filter,
});
