import gql from 'graphql-tag';

export const storefrontSettingsFragment = gql`
  fragment StorefrontSettings on StorefrontSettings {
    revv_uid
    title
    categories(filters: { page: $page, limit: $limit, revvUid: $revvUid }) {
      current_page
      page_count
      total_count
      results {
        id
        revv_uid
        position
        created_at
        revv_uid
        internal_name
        parent_category {
          id
          internal_name
        }
        sub_category_count
        product_count
        slug
        products {
          revv_uid
          id
          name
          active_in_storefront
        }
        hero_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
        hero_mobile_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
    }
  }
`;

// GET_STOREFRONT_CATEGORIES
export const GET_STOREFRONT_CATEGORIES = gql`
  query getCandidateStorefrontCategories($organizationRevvUid: String, $page: Int, $limit: Int, $revvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        storefront_settings {
          ...StorefrontSettings
        }
      }
    }
  }
  ${storefrontSettingsFragment}
`;

// Notes:
// 1. pass category revvUid for update
// 2. pass "parentCategoryId": null to clear parent category field
// 3. pass destroy: true to remove a product
// 4. pass productId to add a product
// UPDATE_MERCH_CATEGORY
export const UPDATE_MERCH_CATEGORY = gql`
  mutation updateCandidateMerchCategoryMutation($category: MerchCategoryInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      category {
        id
        revv_uid
        position
        created_at
        revv_uid
        internal_name
        parent_category {
          id
          internal_name
        }
        sub_category_count
        product_count
        slug
        products {
          revv_uid
          id
          name
          active_in_storefront
        }
        hero_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
        hero_mobile_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
      }
    }
  }
`;

// CREATE_MERCH_CATEGORY
export const CREATE_MERCH_CATEGORY = gql`
  mutation createCandidateMerchCategoryMutation($category: MerchCategoryInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      category {
        id
        position
        created_at
        revv_uid
        internal_name
        parent_category {
          id
          internal_name
        }
        sub_category_count
        product_count
        slug
        products {
          id
          name
        }
        hero_assigned_image {
          id
          image {
            thumbnailUrl
          }
        }
        hero_mobile_assigned_image {
          id
          image {
            thumbnailUrl
          }
        }
      }
    }
  }
`;

// ARCHIVE_MERCH_CATEGORY
export const ARCHIVE_MERCH_CATEGORY = gql`
  mutation archiveCandidateMerchCategory($category: ArchiveMerchCategoryInput!, $organizationRevvUid: String!) {
    candidateArchiveMerchCategory(category: $category, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
      __typename
    }
  }
`;
