import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

function Membership(props) {
  return (
    <FormCard title="Membership Upsells are not editable using WinRed’s Committee Portal. Use the WinRed Power Portal instead."></FormCard>
  );
}

Membership.title = 'Membership';
Membership.description = '';
Membership.icon = 'upsell-membership';
Membership.type = 'MEMBERSHIP';
Membership.widgets = [];

Membership.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default Membership;
