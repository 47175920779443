import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InputGroup, Form } from 'react-bootstrap';

import FloatGroup from 'modules/common/form/float-group.component';
import SubText from 'modules/common/sub-text.component';
import { ValidatorComponent } from 'core/validation';
import InputErrorMessage from 'modules/common/form/input-error-message.component';

class Input extends ValidatorComponent {
  handleFocusChange = (isActive, setActive) => () => {
    setActive(isActive);
    const { onFocus } = this.props;

    if (typeof onFocus === 'function' && isActive) {
      onFocus();
    } else if (!isActive) {
      /* Call onBlur from ValidatorComponent which will also call onBlur from
      props if it exists. */
      this.onBlur();
    }
  };

  render() {
    let {
      ariaLabel = '',
      placeholder,
      className,
      classNameWrapper,
      pro,
      errorMessages,
      validateOnBlurOnly,
      validateOnMount,
      errorMessageClasses,
      invalidIcon,
      errorIcon,
      isWarning,
      numbersOnly,
      onChange,
      prepend,
      append,
      title,
      name,
      moneyInput,
      description,
      description2,
      floatLabel,
      max,
      type = 'text',
      children,
      hidden,
      alwaysValidate,
      ...rest
    } = this.props;
    const errorMessage = this.errorMessage();

    const _onChange = event => {
      let value = event.target.value || '';

      // if number input make sure only digits are entered
      if (numbersOnly || moneyInput) value = value.replace(/[^0-9.]/g, '');

      if (max) {
        if ((numbersOnly || moneyInput) && value > max) return;
        else if (value.length > max) return;
      }

      // only allow 2 decimals if money input
      if (moneyInput) {
        if (/\.\d\d\d+/.test(value)) return;
      }

      onChange({ target: { name, value } });
    };

    if (moneyInput || numbersOnly) type = 'number';

    // when wrapped in a inputgroup the label goes missing
    // TODO: clean this up - create base input, then input with labels
    // money input, numbers input, filter input, etc. make sure validation works the same
    const Wrapper = append || prepend ? InputGroup : 'div';

    const handleOnWheel = e => {
      if (type === 'number') {
        e.target.blur();
        return;
      }
    };

    return (
      <>
        {title ? <Form.Label className="mb-1">{title}</Form.Label> : null}
        <Wrapper
          className={classnames(classNameWrapper, {
            'winred-input--error': !isWarning && errorMessage && !hidden,
            'winred-input--warning': isWarning && errorMessage && !hidden,
          })}
        >
          {prepend ? (
            <InputGroup.Prepend>
              <InputGroup.Text>{prepend}</InputGroup.Text>
            </InputGroup.Prepend>
          ) : null}

          <FloatGroup.Context.Consumer>
            {floatGroup => (
              <Form.Control
                hidden={hidden}
                name={name}
                type={type}
                onChange={_onChange}
                aira-label={ariaLabel}
                className={classnames(
                  'winred-input',
                  {
                    'winred-input--pro': pro,
                    'winred-input--active': floatGroup.shouldFloat,
                    'input-with-prepend': !!prepend,
                  },
                  className
                )}
                placeholder={floatGroup.active ? '' : placeholder}
                onFocus={this.handleFocusChange(true, floatGroup.setActive)}
                onBlur={this.handleFocusChange(false, floatGroup.setActive)}
                onWheel={handleOnWheel}
                {...rest}
              />
            )}
          </FloatGroup.Context.Consumer>

          {append ? <InputGroup.Append>{append}</InputGroup.Append> : null}
        </Wrapper>
        {description ? (
          <SubText
            description={description}
            description2={description2}
            className={classnames({ 'mb-0': errorMessage })}
          />
        ) : null}
        {children}
        <InputErrorMessage
          errorMessageClasses={errorMessageClasses}
          errorMessage={errorMessage}
          errorIcon={errorIcon || invalidIcon}
          isWarning={isWarning}
        />
      </>
    );
  }
}

Input.propTypes = {
  placeholder: PropTypes.string,
  floatLabel: PropTypes.string,
  description: PropTypes.node,
  description2: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  pro: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  invalidIcon: PropTypes.string,
  errorIcon: PropTypes.string,
  max: PropTypes.number,
};

Input.defaultProps = {
  className: '',
  classNameWrapper: '',
};

export default Input;
