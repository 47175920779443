import gql from 'graphql-tag';
import { upsellGroupFragment, upsellFragment } from './upsells.queries';
import { widgetFragment } from '../widgets.queries';
import {
  campaignFragment,
  conduitPageCandidateFragment,
  conduitPageOrganizationFragment,
  conduitPagesCandidateFragment,
  conduitPagesOrganizationFragment,
  availablePacCandidateFragment,
} from './page-fragments';
import { partialUpsellPathwayFragment } from './pathways.queries';

const organizationPage = gql`
  fragment OrganizationPage on Page {
    created_at
    updated_at
    revv_uid
    url
    internalName
    slug
    publicTitle
    type
    status
    password
    passwordExactMatch
    passwordCallout
    callToAction
    submitButtonText
    steps
    cssOverride
    entryMode
    qrCodeUrl

    enableEmployerNameAndOccupation
    enableEmployerDetails
    employerAddress
    employerCity
    employerState
    employerZip
    shippingAddressPrechecked

    highDollar
    videoPage
    billPayExists
    pageFees {
      revvFeePercentage
    }

    recurringUpsell {
      revv_uid
      conversionRate
    }
    smsOptinUpsell {
      revv_uid
      conversionRate
    }
    doubleUpsell {
      revv_uid
      conversionRate
    }
    primaryColor
    secondaryColor
    backgroundColor
    layout
    position
    enableShippingDetails

    enableMobileField
    requireMobileField
    emailFieldRequired
    enableSmsOptin
    enableShippingDetails
    enableRecaptcha
    smsOptinCallout
    mobileDisclaimerText

    sourceCode
    pageAmounts {
      id
      amount
      position
      description
      preselected
    }

    thermometerCampaign {
      initiative_type
      thermometer_fields {
        goals {
          id
          amount_cents
          position
        }
        page_count
        boost_goal
        query_date
        data_source
      }
      statistics {
        donor_count
        donation_count
        donation_amount
      }
    }
    thermometerWidget {
      revvUid
      label
      name
      type
    }

    enableBusinessDonations
    enablePacDonations
    enablePersonalDonations
    enableAchPayments
    defaultPaymentOption

    recurringAvailable
    recurringIntervalSelectable
    recurringInterval
    recurringEndAt
    recurringEnableRadioButtons
    recurringDefaultValue
    recurringConfirmRadioButtonCallout
    recurringDeclineRadioButtonCallout
    recurringIntervalSelectable
    recurringCallout
    redirectPageRevvUid

    upsellAgencyFeesEnabled
    recurringAgencyFeesEnabled

    confirmationRedirectBoolean
    confirmationParagraph
    confirmationRedirectUrl
    confirmationTwitterHandle
    confirmationFacebookHandle
    confirmationCustomLink
    confirmationCustomLinkCopy
    confirmationVideoEmbed
    confirmationWithUtms
    confirmationWithLeadInfo
    replaceUtmWithWrUtm
    confirmationPreviewPageUrl

    bundlerId
    showBundlerId
    locale
    disclaimer
    longDisclaimer
    officialDisclaimer
    hasOtherField
    logoLocation
    enablePhoneField
    requirePhoneField
    videoEmbed
    fixedBackground
    mobileBannerAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    logoAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    backgroundAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    displayLogoOnMobile

    customFields {
      page_custom_field_id
      global_custom_field_id
      name
      input_type
      position
      position_index
      form_section
      label
      default_value
      placeholder
      required
      unique_parameter
      other_field_enabled
      has_poll_results_widgets
    }

    splitEvenly
    petitionPageFields {
      hasFirstNameField
      hasLastNameField
      hasEmailField
      hasAddressField
      hasCityField
      hasStateField
      hasPostalCodeField
      hasPhoneField
    }
    pathway {
      name
      id
      revv_uid
      pathway_type
    }
    upsellProcessType

    ogTitle
    ogDescription
    ogVideo
    ogAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }

    twitterTitle
    twitterDescription
    twitterAssignedImage {
      id
      image {
        id
        thumbnailUrl
      }
    }
    upsellPageGroup {
      ...UpsellGroup
    }
    campaign {
      ...PageCampaign
    }
    moneyPledgeCampaign {
      revv_uid
      name
    }

    additionalMoneyPledgeCampaigns {
      moneyPledgeCampaign {
        revv_uid
        name
      }
    }

    preCheckMoneyPledge
    widgets {
      ...Widget
    }

    donorCoverFeesCallout
    donorCoverFeesPreChecked
    donorCoverFees
    description
    minimumAmount
    maximumAmount
  }
  ${upsellGroupFragment}
  ${widgetFragment}
  ${campaignFragment}
`;

export const GET_ORGANIZATION_PAGES_QUERY = gql`
  query getOrganizationPages(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $organizationRevvUid: String
    $status: StatusEnum
    $pageType: [TypeEnum!]
    $highDollar: Boolean
    $videoPage: Boolean
    $feeType: VendorFeeFilterEnum
    $feeValue: String
    $profileRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        allow_donations_to_process
        pages(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            status: $status
            pageType: $pageType
            highDollar: $highDollar
            videoPage: $videoPage
            feesType: $feeType
            feesValue: $feeValue
            profileRevvUid: $profileRevvUid
          }
        ) {
          current_page
          page_count
          total_count
          raised_amounts {
            revv_uid
            raised_amount
            leads
          }
          results {
            revv_uid
            created_at
            updated_at
            authorEmail
            authorCallout

            upsellAgencyFeesEnabled
            recurringAgencyFeesEnabled
            highDollar
            videoPage
            billPayExists
            pageFees {
              revvFeePercentage
            }

            internalName
            slug
            status
            url
            type
            qrCodeUrl

            backgroundColor
            backgroundAssignedImage {
              id
              image {
                id
                thumbnailUrl
              }
            }

            conduitPageCandidates {
              ...ConduitPagesCandidate
            }

            conduitPageOrganizations {
              ...ConduitPagesOrganization
            }
          }
        }
        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${conduitPagesCandidateFragment}
  ${conduitPagesOrganizationFragment}
`;

export const GET_ORGANIZATION_PAGE_QUERY = gql`
  query getOrganizationPage(
    $revv_uid: String!
    $organizationRevvUid: String
    $defaultMoneyPledgeId: String
    $profileRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        slug
        money_pledges: campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: 1, revvUid: $defaultMoneyPledgeId }) {
          results {
            revv_uid
            money_pledge_date
          }
        }
        pages(filters: { revvUid: $revv_uid, profileRevvUid: $profileRevvUid }) {
          results {
            ...OrganizationPage
          }
        }
        maximum_contribution_cents
        campaigns {
          total_count
        }

        upsell_groups {
          total_count
        }
        upsells {
          total_count
        }
      }
    }
  }
  ${organizationPage}
`;

export const GET_ORGANIZATION_PAGE_COMMITTEES = gql`
  query getOrganizationPageCommittees($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pages(filters: { revvUid: $revv_uid }) {
          results {
            revv_uid
            conduitPageCandidates {
              ...ConduitPageCandidate
            }
            conduitPageOrganizations {
              ...ConduitPageOrganization
            }
          }
        }
      }
    }
  }
  ${conduitPageCandidateFragment}
  ${conduitPageOrganizationFragment}
`;

export const GET_ORGANIZATION_PAGE_CAMPAIGNS = gql`
  query getOrganizationPageCampaigns($organizationRevvUid: String, $campaignsLimit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $campaignsLimit }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

export const SEARCH_ORGANIZATION_PAGE_CAMPAIGNS = gql`
  query searchOrganizationPageCampaigns($organizationRevvUid: String, $limit: Int, $search: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $limit, search: $search }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

export const SEARCH_ORGANIZATION_PAGE_NAMES = gql`
  query searchOrganizationPageNames($organizationRevvUid: String, $limit: Int, $search: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pages(filters: { limit: $limit, search: $search }) {
          results {
            revv_uid
            internalName
          }
        }
      }
    }
  }
`;

export const GET_NEW_ORGANIZATION_PAGE_QUERY = gql`
  query getNewOrganizationPageQuery(
    $organizationRevvUid: String
    $defaultUpsellGroupId: String
    $defaultMoneyPledgeId: String
    $defaultUpsellPathwayId: String
    $pathwayType: PathwayTypeEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { revvUid: $organizationRevvUid }) {
          results {
            ...AvailablePacCandidate
          }
        }
        money_pledges: campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: 1, revvUid: $defaultMoneyPledgeId }) {
          results {
            revv_uid
            money_pledge_date
          }
        }
        upsell_groups(filters: { revvUid: $defaultUpsellGroupId, limit: 1 }) {
          results {
            ...UpsellGroup
          }
        }
        pathways(filters: { revvUid: $defaultUpsellPathwayId, pathwayType: $pathwayType, limit: 1 }) {
          results {
            ...PartialUpsellPathway
          }
        }
        upsells(filters: { limit: 1 }) {
          results {
            ...Upsell
          }
        }
        default_source_code
        default_upsell_process_type
        default_upsell_group_revv_uid
        default_upsell_pathway_revv_uid
        default_setting {
          enableSmsOptin
          mobileDisclaimerText
          smsOptinCallout
        }
        marketing_setting {
          ogTitle
          ogVideo
          ogDescription
          ogAssignedImage {
            id
            image {
              id
              thumbnailUrl
            }
          }
          twitterTitle
          twitterDescription
          twitterAssignedImage {
            id
            image {
              id
              thumbnailUrl
            }
          }
        }
        campaigns {
          total_count
        }
      }
    }
  }
  ${upsellGroupFragment}
  ${upsellFragment}
  ${availablePacCandidateFragment}
  ${partialUpsellPathwayFragment}
`;

export const GET_ALL_ORGANIZATION_CANDIDATES_QUERY = gql`
  query getPacOrganizationCandidates($limit: Int, $search: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { limit: $limit, search: $search }) {
          results {
            ...AvailablePacCandidate
          }
        }
      }
    }
  }
  ${availablePacCandidateFragment}
`;

export const EXPORT_ORGANIZATION_PETITION_PAGE_LEADS = gql`
  mutation OrganizationExportPetitionPageLeads($page: ExportPageInput!) {
    organizationExportPetitionPageLeads(page: $page) {
      errors
      message
      dammed
      petitionPage {
        revv_uid
        __typename
      }
      __typename
    }
  }
`;

export const CREATE_ORGANIZATION_PAGE_MUTATION = gql`
  mutation createOrganizationPageMutation($page: CreatePageInput!) {
    organizationCreatePage(page: $page) {
      errors
      page {
        ...OrganizationPage
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${organizationPage}
  ${campaignFragment}
`;

export const UPDATE_ORGANIZATION_PAGE_MUTATION = gql`
  mutation updateOrganizationPageMutation($page: UpdatePageInput!) {
    organizationUpdatePage(page: $page) {
      errors
      warning
      page {
        ...OrganizationPage
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${organizationPage}
  ${campaignFragment}
`;

export const ARCHIVE_ORGANIZATION_PAGE_MUTATION = gql`
  mutation ArchiveOrganizationMutation($page: ArchivePageInput!) {
    organizationArchivePage(page: $page) {
      errors
      page {
        ...OrganizationPage
        campaign {
          ...PageCampaign
        }
      }
    }
  }
  ${organizationPage}
  ${campaignFragment}
`;

export const UNARCHIVE_ORGANIZATION_PAGE_MUTATION = gql`
  mutation UnarchivePageMutation($page: UnarchivePageInput!) {
    organizationUnarchivePage(page: $page) {
      errors
      page {
        revv_uid
        slug
        status
      }
    }
  }
`;

export const DUPLICATE_ORGANIZATION_PAGE_MUTATION = gql`
  mutation OrganizationDuplicatePage($page: DuplicatePageInput!) {
    organizationDuplicatePage(page: $page) {
      errors
      duplicatedPage {
        revv_uid
      }
    }
  }
`;

export const ORGANIZATION_BULK_DUPLICATE_PAGE_MUTATION = gql`
  mutation OrganizationBulkDuplicatePage($pageDuplication: BulkDuplicatePageInput!, $organizationRevvUid: String) {
    organizationBulkDuplicatePage(bulkPageDuplication: $pageDuplication, organizationRevvUid: $organizationRevvUid) {
      duplicated_pages_result {
        duplicated_page {
          internalName
          revv_uid
          slug
        }
        errors
        page_duplication_parameters {
          templateRevvUid
          customName
          customSlug
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_REDIRECT_PAGES_QUERY = gql`
  query getOrganizationRedirectPages(
    $revv_uid: String!
    $organizationRevvUid: String
    $page: Int
    $limit: Int
    $search: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        redirect_pages(filters: { revvUid: $revv_uid, page: $page, limit: $limit, search: $search }) {
          results {
            revv_uid
            internalName
            slug
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_ALL_PAGES = gql`
  query getAllPages($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pages(filters: { limit: 9999 }) {
          results {
            revv_uid
            internalName
          }
        }
      }
    }
  }
`;

export const EXPORT_ORGANIZATION_PAGE_DONATIONS = gql`
  mutation OrganizationExportPageDonations($page: ExportPageInput!) {
    organizationExportPageDonations(page: $page) {
      errors
      message
      dammed
      donationPage {
        revv_uid
        __typename
      }
      __typename
    }
  }
`;

export const GET_ORGANIZATION_PAGES_ID_NAME_QUERY = gql`
  query getOrganizationPages(
    $page: Int
    $limit: Int
    $organizationRevvUid: String
    $pageType: [TypeEnum!]
    $search: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        custom_field_pages(filters: { page: $page, limit: $limit, search: $search, pageType: $pageType }) {
          results {
            id
            revv_uid
            created_at
            authorEmail
            publicTitle
            internalName
            slug
            type
            status
            videoPage
            highDollar
            backgroundColor
            backgroundAssignedImage {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_BULK_PAGE_DUPLICATION = gql`
  mutation OrganizationBulkDuplicatePage($pageDuplication: BulkDuplicatePageInput!, $organizationRevvUid: String) {
    organizationBulkDuplicatePage(bulkPageDuplication: $pageDuplication, organizationRevvUid: $organizationRevvUid) {
      process_id
    }
  }
`;

export const ORGANIZATION_BULK_PAGE_RETRY_DUPLICATION = gql`
  mutation OrganizationBulkDuplicatePageRetry(
    $bulkPageDuplication: BulkDuplicatePageInput!
    $organizationRevvUid: String
  ) {
    organizationBulkDuplicatePageRetry(
      bulkPageDuplication: $bulkPageDuplication
      organizationRevvUid: $organizationRevvUid
    ) {
      duplicated_pages_result {
        duplicated_page {
          revv_uid
          internal_name
          slug
          url
        }
        page_duplication_parameters {
          template_revv_uid
          custom_name
          custom_slug
        }
        template {
          partnership_templates {
            name
            feePercentage
            thumbnailUrl
          }
        }
        errors
        page_status
      }
    }
  }
`;

export const ORGANIZATION_GET_BULK_DUPLICATE_PAGES_BACKGROUND_JOB = gql`
  query getBackgroundJob($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        background_job_result(revvUid: $revv_uid) {
          revv_uid
          status
          results {
            duplicated_page {
              revv_uid
              internal_name
              slug
              url
            }
            page_duplication_parameters {
              template_revv_uid
              custom_name
              custom_slug
              custom_source_code
            }
            template {
              partnership_templates {
                name
                feePercentage
                thumbnailUrl
              }
            }
            errors
            page_status
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_SUBDOMAINS = gql`
  query getOrganizationSubdomains($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        custom_subdomains_allowed
        organization_subdomains {
          id
          subdomain
          status
        }
        organization_subdomain {
          subdomain
        }
      }
    }
  }
`;
