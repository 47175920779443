import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { ApolloLink, from, ApolloClient, InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

import { setLoginStatus, LOGIN_STATUSES, SET_LOGIN_STATUS } from './core/login';
import { isDevelopment } from 'core/utilities';

export const createGqlLinks = store => {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API,
    credentials: 'include',
  });

  const authLink = setContext((queryData, { headers }) => {
    const state = store.getState();
    const _headers = { ...headers };
    if (state.login.token) _headers.authorization = `Bearer ${state.login.token}`;
    return { headers: _headers };
  });

  let links = from([authLink, httpLink]);
  if (isDevelopment) {
    const timeStartLink = new ApolloLink((operation, forward) => {
      console.debug(operation);
      operation.setContext({ start: new Date() });
      return forward(operation);
    });

    const logTimeLink = new ApolloLink((operation, forward) => {
      return forward(operation).map(data => {
        // data from a previous link
        const time = new Date() - operation.getContext().start;
        console.debug(`operation ${operation.operationName} took ${time}ms to complete`);
        return data;
      });
    });

    links = from([authLink, timeStartLink, logTimeLink, httpLink]);
  }

  return links;
};

export const createApolloClient = store => {
  return new ApolloClient({
    link: createGqlLinks(store),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            merge(existing = {}, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
        // Updating to node v20 and migrating to @apollo/client resulted in the following error
        // Cache data may be lost when replacing the viewer field of a Query object.

        // Custom merge function for the Query.viewer field has been defined bellow so InMemoryCache can safely merge these objects
        Query: {
          fields: {
            viewer: {
              merge(existing = {}, incoming) {
                const mergedData = { ...existing };
                // Loop through the keys in the incoming data
                Object.keys(incoming).forEach(key => {
                  if (Array.isArray(mergedData[key])) {
                    // If the field is an array, merge the arrays
                    mergedData[key] = [...mergedData[key], ...incoming[key]];
                  } else if (typeof mergedData[key] === 'object' && typeof incoming[key] === 'object') {
                    // If both existing and incoming values are objects, deep merge them
                    mergedData[key] = { ...mergedData[key], ...incoming[key] };
                  } else {
                    // Otherwise, replace the existing value with the incoming value
                    mergedData[key] = incoming[key];
                  }
                });
                return mergedData;
              },
            },
          },
        },
      },
      // we need to make sure ALL objects coming into apollo have a UNIQUE ID!!
      // this covers all current cases of data that we load
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'Page':
          case 'PageAmount':
            return object.id;
          case 'ElasticsearchConduitPageCandidate':
            return object.page_revv_uid;
          case 'CandidateSubscription':
          case 'CandidateTransaction':
          case 'CandidateDonor':
          case 'CandidateDonation':
          case 'UpsellGroupUpsellPage':
          case 'UpsellGroupResult':
            return object.revv_uid || object.conduit_candidate_revv_uid || object.revvUid;
          case 'Candidate':
          case 'ConduitPageCandidate':
          case 'ConduitPageOrganization':
            /* Need to return undefined here because there can be multiple of the same candidate
            with different data on one object. The prime example of this is a panel upsell with
            two slate cards, where both cards have the same candidate but different split
            percentages. */
            return undefined;
          default:
            return defaultDataIdFromObject(object);
        }
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
    connectToDevTools: isDevelopment,
  });
};

export const getUrlInfoForCandidiate = () => {
  // try to get revv uid from url - this is the candidate/agency we will use when logging in
  let uidUrlPath = window.location.pathname.split('/')[1] || '';

  const agencyRevvUid = uidUrlPath.startsWith('rv_acct') ? uidUrlPath : '';
  let organizationRevvUid = uidUrlPath.startsWith('rv_org') ? uidUrlPath : '';

  if (agencyRevvUid) {
    let uidUrlPath2 = window.location.pathname.split('/')[2] || '';
    organizationRevvUid = uidUrlPath2.startsWith('rv_org') ? uidUrlPath2 : '';
  }

  const params = new URLSearchParams(window.location.search);
  const isRevvShare = params.get('isrevvshare') === 'true';
  const _agencyRevvUid = params.get('agencyrevvuid');
  const isAgency = params.get('isagency') === 'true';
  const isAgencyCandidate = params.get('isagencycandidate') === 'true';

  return {
    organizationRevvUid,
    agencyRevvUid: _agencyRevvUid || agencyRevvUid,
    isRevvShare,
    isAgency,
    isAgencyCandidate,
  };
};

export const generateLoginDetails = store => {
  // if local storage doesnt even say we are logged in then we know we're not logged in at all
  // else we tell the store we are attempting to login to see if what we have is valid
  let loginStatus = {};
  try {
    loginStatus = localStorage.getItem(SET_LOGIN_STATUS);
    loginStatus = JSON.parse(loginStatus);
  } catch {
    loginStatus = {};
  }

  if (!loginStatus || loginStatus.token === null) {
    store.dispatch(setLoginStatus({ status: LOGIN_STATUSES.NOT_LOGGED_IN, token: null }));
  } else {
    store.dispatch(
      setLoginStatus({
        status: LOGIN_STATUSES.LOGGING_IN,
        token: loginStatus.token,
      })
    );
  }
};
