import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import classNames from 'classnames';
import { graphql, withApollo } from '@apollo/client/react/hoc';

import { Drawer, SpinnerContainer, withAmplitude, AmplitudePropTypes, DrawerHeader } from 'modules/common';
import uuid from 'core/utilities/uuid';
import { profileCandidateSelector } from 'core/login';
import { setToast } from 'core/toast';
import { ValidationBlock } from 'core/validation';
import {
  CREATE_UPSELL_MUTATION,
  UPDATE_UPSELL_MUTATION,
  CREATE_ORGANIZATION_UPSELL_MUTATION,
  UPDATE_ORGANIZATION_UPSELL_MUTATION,
  GET_NEW_ORGANIZATION_UPSELL_QUERY,
  GET_NEW_UPSELL_QUERY,
} from 'core/middleware/queries';
import { profileMaxPersonalDonationSelector, isOrganizationSelector } from 'core/login/login.selectors';
import UpsellFooter from './drawer/upsell-footer.component';
import DrawerBody from './drawer/drawer-body.component';

import { UPSELL_COMPONENT_MAP, generateNewUpsell, addUpsellMetaData, formatUpsellForUi, updateUpsell } from './tools';
import { formatUpsellForServer } from './format.tools';
import './new-upsell.scss';

class UpsellDrawer extends Component {
  state = {
    selectedUpsellComponent: null, // the upsell react component we are displaying in the drawer

    editedUpsell: null, // the upsell being edited in the drawer
    originalEditedUpsell: null, // original copy of upsell to see if changes were made
    originalSelectedUpsell: null, // original upsell

    showConfirmClose: false, // show confirm modal for closing an upsell form in the drawer
    savingUpsell: false, // when saving a new upsell or saving an edited upsell
    creatingNewUpsell: false, // when querying data needed to generate new upsell

    loading: false,
  };
  validationBlock = React.createRef();

  setbackdropRef = node => (this.backdropRef = node);

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  handleClickOutside = event => {
    const { open } = this.props;

    const clickedBackdrop = open && this.backdropRef && this.backdropRef.contains(event.target);
    if (clickedBackdrop) {
      this.onConfirmCloseEdit();
      return;
    }

    // only log on outside click when open
    // for some reason react-select clear svg/path elements are
    // considered 'clicked outside' so ignore those types of clicks
    const clickedOutside = open && this.componentRef && !this.componentRef.contains(event.target);
    const clickedClear = event.target.tagName !== 'path' && event.target.tagName !== 'svg';

    if (clickedOutside && clickedClear) {
      this.onConfirmCloseEdit();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (isEmpty(props.selectedUpsell) || isEqual(props.selectedUpsell, state.originalSelectedUpsell)) return null;

    /**
     * when selecting an existing upsell to edit
     */
    const newEditedUpsell = addUpsellMetaData({
      ...formatUpsellForUi(cloneDeep(props.selectedUpsell), props.profileCandidate),
      _id: props.selectedUpsell.id,
    });

    return {
      selectedUpsellComponent: UPSELL_COMPONENT_MAP[props.selectedUpsell.type],
      editedUpsell: newEditedUpsell,
      originalEditedUpsell: cloneDeep(newEditedUpsell),
      originalSelectedUpsell: cloneDeep(props.selectedUpsell),
    };
  }

  /**
   * the currently updated upsell is kept separate in state
   * just update the internal state until we save
   */
  onUpdateEditedUpsell = ({ target: { name, value } }) => {
    let editedUpsell = this.state.editedUpsell || {};
    this.setState({ editedUpsell: updateUpsell(editedUpsell, name, value) });
  };

  /**
   * when saving the edited upsell to the backend
   */
  onSaveEditedUpsell = () => {
    const {
      createUpsell,
      createOrganizationUpsell,
      isOrganization,
      profileCandidate,
      setToast,
      updateOrganizationUpsell,
      updateUpsell,
    } = this.props;
    if (this.validationBlock.current.errors()) return;

    const editedUpsell = cloneDeep(this.state.editedUpsell);
    const formattedUpsell = formatUpsellForServer(editedUpsell);
    formattedUpsell.organizationRevvUid = profileCandidate.organization_revv_uid;

    let saveUpsell = this.state.editedUpsell._isNew ? createUpsell : updateUpsell;

    if (isOrganization) {
      saveUpsell = this.state.editedUpsell._isNew ? createOrganizationUpsell : updateOrganizationUpsell;
    }

    this.setState({ savingUpsell: true }, () => {
      saveUpsell({ upsell: formattedUpsell })
        .then(({ data }) => {
          let { errors } =
            data.candidateCreateUpsell ||
            data.candidateUpdateUpsell ||
            data.organizationCreateUpsell ||
            data.organizationUpdateUpsell;

          if (errors) {
            const error = (errors.graphQLErrors || errors)[0];
            setToast({ message: error, isError: true });
            this.setState({ savingUpsell: false });
            return;
          }
          this.onConfirmCloseEdit();
          setToast({ message: `Upsell has been successfully ${editedUpsell._id ? 'saved' : 'created'}.` });
        })
        .catch(errors => {
          const error = (errors.graphQLErrors || errors)[0];
          setToast({
            message: error ? error.message : error || 'Unknown Error',
            isError: true,
          });

          this.setState({ savingUpsell: false });
        });
    });
  };

  /**
   * close the edit drawer - reset select upsell
   */
  onConfirmCloseEdit = () => {
    const shouldRefetch = this.state.editedUpsell?._isNew || false;
    this.setState(
      {
        selectedUpsellComponent: null,
        editedUpsell: null,
        originalEditedUpsell: null,
        savingUpsell: false,
      },
      () => this.props.toggleDrawer(null, shouldRefetch)
    );
  };

  /**
   * when selecting to create a new upsell
   */
  onSelectNewUpsell = () => {
    this.setState({ editedUpsell: { type: null, revv_uid: uuid(), _isNew: true } }, this.scrollToDrawerTop);
  };

  /**
   * when opening the drawer or selecting an upsell form - we always want to make sure
   * we are at the top of the drawer/form since there's scrolling inside the drawer
   */
  scrollToDrawerTop() {
    this.drawerRef &&
      this.drawerRef.scrollIntoView &&
      this.drawerRef.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
  }

  /**
   * when creating a new upsell, we set which upsell type from selectedUpsellComponent
   */
  setUpsellComponent = async selectedUpsellComponent => {
    try {
      this.setState({ gettingUpsellData: true });
      const variables = {
        revvUid: this.props.isOrganization
          ? this.props.profileCandidate.organization_revv_uid
          : this.props.profileCandidate.revv_uid,
        organizationRevvUid: this.props.profileCandidate.organization_revv_uid,
      };

      const { data } = await this.props.client.query({
        query: this.props.isOrganization ? GET_NEW_ORGANIZATION_UPSELL_QUERY : GET_NEW_UPSELL_QUERY,
        variables,
      });

      this.setState({ creatingNewUpsell: false });

      const candidate = data?.viewer?.candidate || data?.viewer?.state_level_organization;
      const availablePacCandidates = candidate?.available_pac_candidates;

      const newUpsell = {
        ...generateNewUpsell({
          upsellType: selectedUpsellComponent.type,
          maxDonation: this.props.maxPersonalDonation,
          profileCandidate: this.props.profileCandidate,
          availablePacCandidates,
          enableDonorCoverFees: candidate.enable_donor_cover_fees,
        }),
        type: selectedUpsellComponent.type,
        _isNew: true,
      };

      this.setState(
        { selectedUpsellComponent, editedUpsell: newUpsell, originalEditedUpsell: cloneDeep(newUpsell) },
        this.scrollToDrawerTop
      );
    } catch (error) {
      this.setState({ creatingNewUpsell: false });
      this.props.setToast({
        message: error,
        isError: true,
      });
    }
  };

  /**
   * save ref of app drawer to scroll to top on open
   */
  setRef = node => (this.drawerRef = node);

  render() {
    const { selectedUpsellComponent, editedUpsell, savingUpsell, creatingNewUpsell, originalEditedUpsell } = this.state;
    const { open, primaryColor, secondaryColor, isOrganization } = this.props;

    return (
      <div className="upsell-drawer">
        <Drawer
          open={open}
          className={classNames({
            'upsell-selected': selectedUpsellComponent,
          })}
          leftAlignTitle={!!selectedUpsellComponent}
          subtitle={selectedUpsellComponent?.description}
          icon={selectedUpsellComponent?.icon ?? 'times'}
          setbackdropRef={this.setbackdropRef}
          footer={
            <UpsellFooter
              editedUpsell={editedUpsell}
              originalEditedUpsell={originalEditedUpsell}
              selectedUpsellComponent={selectedUpsellComponent}
              onConfirmCloseEdit={this.onConfirmCloseEdit}
              onSaveEditedUpsell={this.onSaveEditedUpsell}
              validationBlock={this.validationBlock}
            />
          }
        >
          <div ref={this.setRef}>
            <DrawerHeader
              title={selectedUpsellComponent?.title ?? 'Create New Upsell'}
              toggleOpen={this.onConfirmCloseEdit}
            />
            {savingUpsell || creatingNewUpsell ? (
              <SpinnerContainer overlay />
            ) : (
              <>
                <ValidationBlock ref={this.validationBlock}>
                  <DrawerBody
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    selectedUpsellComponent={selectedUpsellComponent}
                    setUpsellComponent={this.setUpsellComponent}
                    onUpdateEditedUpsell={this.onUpdateEditedUpsell}
                    editedUpsell={editedUpsell}
                    isOrganization={isOrganization}
                  />
                </ValidationBlock>
              </>
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

UpsellDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  createUpsell: PropTypes.func.isRequired,
  updateUpsell: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  isNewUpsellGroup: PropTypes.bool,
  loading: PropTypes.bool,
  client: PropTypes.object.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  maxPersonalDonation: PropTypes.number.isRequired,
  isOrganization: PropTypes.bool,
  profileCandidate: PropTypes.object,
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
  maxPersonalDonation: profileMaxPersonalDonationSelector(state),
  profileCandidate: profileCandidateSelector(state),
});
const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(
  withRouter,
  withAmplitude,
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(CREATE_UPSELL_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      createUpsell: variables => {
        return mutate({
          variables: { ...variables, organizationRevvUid: ownProps.profileCandidate.organization_revv_uid },
        });
      },
    }),
  }),
  graphql(UPDATE_UPSELL_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      updateUpsell: variables =>
        mutate({
          variables: { ...variables, organizationRevvUid: ownProps.profileCandidate.organization_revv_uid },
        }),
    }),
  }),
  graphql(CREATE_ORGANIZATION_UPSELL_MUTATION, {
    props: ({ mutate }) => ({
      createOrganizationUpsell: variables => mutate({ variables }),
    }),
  }),
  graphql(UPDATE_ORGANIZATION_UPSELL_MUTATION, {
    props: ({ mutate }) => ({
      updateOrganizationUpsell: variables =>
        mutate({
          variables,
        }),
    }),
  })
)(UpsellDrawer);
