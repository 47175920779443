import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { profileMaxPersonalDonationSelector } from 'core/login';
import { FormCardSpaced, Card, FlexBinaryChoiceRow, Input } from 'modules/common';

function UpsellAmountMultiplierFooter({ editedUpsell, onUpdateEditedUpsell, maxDonationAmounts }) {
  const maxPersonalDonation = useSelector(profileMaxPersonalDonationSelector);

  const disableOtherField =
    editedUpsell.page_amounts.filter(amt => amt._isMultiplier === editedUpsell.multiplier_amounts && !amt._destroy)
      .length >= maxDonationAmounts;

  const maxDonationAmount = maxPersonalDonation;
  const minDonationAmount = 1;

  // if min and max are set to defaults values or zero then no else yes show customized submenu
  const [showMinMaxAmount, setShowMinMaxAmount] = useState(
    editedUpsell.minimum_amount !== minDonationAmount || editedUpsell.maximum_amount !== maxDonationAmount
  );

  return (
    <>
      <Card variant="alt" className="p-4 no-top-border-radius mb-3">
        {editedUpsell.multiplier_amounts ? (
          <FormCardSpaced className="mt-0 mb-3">
            <FlexBinaryChoiceRow
              title="Rounded Results"
              subTitle="Amounts above $10 will be rounded to nearest $10."
              subTitle2="Amounts under $10 will be rounded to the nearest $1."
              value={editedUpsell.round_values}
              name="round_values"
              disabled={editedUpsell._isDisabled}
              onChange={onUpdateEditedUpsell}
            />
          </FormCardSpaced>
        ) : null}

        <FormCardSpaced className="mt-0">
          <FlexBinaryChoiceRow
            title="Show Other Field"
            subTitle="Allow donors to enter a custom amount."
            value={editedUpsell.include_other_field}
            name="include_other_field"
            disabled={disableOtherField || editedUpsell._isDisabled}
            onChange={onUpdateEditedUpsell}
          />
        </FormCardSpaced>

        {editedUpsell.multiplier_amounts ? (
          <>
            <FormCardSpaced>
              <FlexBinaryChoiceRow
                title="Set Minimum and Maximum Amounts for Multiplication"
                subTitle="Minimum and maximum that donation amount can be multiplied from."
                value={showMinMaxAmount}
                name="showMinMaxAmount"
                disabled={editedUpsell._isDisabled}
                onChange={({ target: { value } }) => {
                  const newEditedUpsell = { ...editedUpsell };

                  if (!value) {
                    newEditedUpsell.minimum_amount = minDonationAmount;
                    newEditedUpsell.maximum_amount = maxDonationAmount;
                  }

                  setShowMinMaxAmount(value);
                  onUpdateEditedUpsell({ target: { name: 'upsell_pages', value: newEditedUpsell } });
                }}
              />
            </FormCardSpaced>
            {!showMinMaxAmount ? null : (
              <Card variant="alt" className="pt-4 no-top-border-radius">
                <Container>
                  <Row>
                    <Col md={6} xs={12}>
                      <Input
                        description="The minimum amount to be used when determining the button amounts. $1 is the default minimum."
                        type="number"
                        id="minimum_amount"
                        prepend="$"
                        validateOnBlurOnly
                        name="minimum_amount"
                        disabled={editedUpsell._isDisabled}
                        value={editedUpsell.minimum_amount}
                        moneyInput
                        onChange={onUpdateEditedUpsell}
                        validators={['required']}
                        errorMessages={['Minimum Amount is required.']}
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <Input
                        type="number"
                        inputMode="decimal"
                        description="The maximum amount used by the multiplier upsell. If the donation amount exceeds the maxmimum amount, then the maximum amount will be used."
                        id="maximum_amount"
                        prepend="$"
                        validateOnBlurOnly
                        name="maximum_amount"
                        disabled={editedUpsell._isDisabled}
                        value={editedUpsell.maximum_amount}
                        moneyInput
                        onChange={onUpdateEditedUpsell}
                        validators={['required']}
                        errorMessages={['Maximum Amount is required.']}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </>
        ) : null}
      </Card>
    </>
  );
}

UpsellAmountMultiplierFooter.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  maxDonationAmounts: PropTypes.number,
};

export default UpsellAmountMultiplierFooter;
