import { ExternalLink, Icon, Typography } from 'modules/common';
import React from 'react';

export const TimeoutScreen = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-10/12 sm:w-5/12 text-center">
        <Icon icon="exclamation-circle" color="gray" size="3x"></Icon>
        <Typography color="mirage" className="text-lg mt-2 mb-2">
          Your pages are still being duplicated!
        </Typography>
        <Typography color="mirage" className="mb-0">
          We will notify you via email once the process is complete. Please do not bulk duplicate the same pages
          again.&nbsp;
          <ExternalLink href="https://support.winred.com/en/articles/9338955-bulk-page-duplication">
            Learn More
          </ExternalLink>
        </Typography>
      </div>
    </div>
  );
};
