import { cloneDeep } from 'lodash';

import { formatImageForServer } from 'modules/common';
import { formatWidgetsForServer } from 'modules/common/widgets/tools';
import { formatConduitsForServer } from 'modules/pages/pages/page.tools';
import {
  CommitteeUpsell,
  SlateUpsell,
  SingleUpsell,
  CustomUpsell,
  DoubleUpsell,
  MobileUpsell,
  RecurringUpsell,
  VolunteerUpsell,
  MultipleUpsell,
  CreateUpsell,
  MoneyPledgeUpsell,
} from './drawer/new-upsell';
import { PANEL_UPSELLS_MAP } from './drawer/new-upsell/sections/panel-upsell/tools';
import { formatUpsellPagesForUi } from './tools';

const STANDARD_UPSELLS = [
  DoubleUpsell.type,
  RecurringUpsell.type,
  SingleUpsell.type,
  VolunteerUpsell.type,
  MobileUpsell.type,
  MoneyPledgeUpsell.type,
  MultipleUpsell.type,
  CreateUpsell.type,
  SlateUpsell.type,
];

const UPSELLS_WITH_CAMPAIGNS = [SlateUpsell.type, DoubleUpsell.type];

export const formatUpsellGroupForUpdateServer = upsellGroup => {
  upsellGroup = JSON.parse(JSON.stringify(upsellGroup));

  const newUpsellGroup = {
    updatedAt: upsellGroup.updated_at,
    name: upsellGroup.name,
    revvUid: upsellGroup.revv_uid,
    campaignRevvUid: upsellGroup?.campaign?.revv_uid ?? null,
    upsellPages: upsellGroup.upsell_pages.map(formatUpsellPageForUpsellGroupForServer),
  };

  return newUpsellGroup;
};

export const formatUpsellGroupForCreateServer = upsellGroup => {
  upsellGroup = JSON.parse(JSON.stringify(upsellGroup));

  const newUpsellGroup = {
    name: upsellGroup.name,
    campaignRevvUid: upsellGroup?.campaign?.revv_uid ?? null,
    upsellPages: upsellGroup.upsell_pages.map(formatUpsellPageForUpsellGroupForServer),
  };

  return newUpsellGroup;
};

const formatUpsellPageForUpsellGroupForServer = (upsell, index) => {
  const formattedUpsellGroup = {
    position: index + 1,
    upsellPageId: upsell.upsell_page.id,
  };

  if (!upsell._isNew) formattedUpsellGroup.id = upsell.id;
  if (!upsell._isNew && upsell._destroy) formattedUpsellGroup.destroy = true;
  return formattedUpsellGroup;
};

export const formatUpsellForServer = upsell => {
  let formattedUpsell = { name: upsell.name, type: upsell.type };
  if (!upsell._isNew) formattedUpsell.revvUid = upsell.revv_uid;

  if ([CustomUpsell.type, ...STANDARD_UPSELLS].includes(upsell.type)) {
    formattedUpsell.paragraph = upsell.paragraph;
    formattedUpsell.accept = upsell.accept;
    formattedUpsell.videoEmbed = upsell.video_embed;
    formattedUpsell.logo_assigned_image = upsell.logo_assigned_image;

    // image formatting
    formattedUpsell = formatImageForServer(formattedUpsell, 'logo_assigned_image');
    if (formattedUpsell.logo_assigned_image) {
      formattedUpsell.logoAssignedImage = formattedUpsell.logo_assigned_image;
      delete formattedUpsell.logo_assigned_image;
    } else {
      delete formattedUpsell.logo_assigned_image;
    }
  }

  if (upsell.type === MoneyPledgeUpsell.type) {
    delete formattedUpsell.enhanced;
    if (upsell.money_pledge_campaign?.revv_uid)
      formattedUpsell.moneyPledgeCampaignRevvUid = upsell.money_pledge_campaign.revv_uid;
  }

  if (UPSELLS_WITH_CAMPAIGNS.includes(upsell.type)) {
    formattedUpsell.campaignRevvUid = upsell.campaign?.revv_uid;
  }

  if ('PANEL' === upsell.type) {
    formattedUpsell.randomizedSort = upsell.randomized_sort;
    formattedUpsell.paragraph = upsell.paragraph;

    if (upsell.locked_cards && !Number.isNaN(upsell.locked_cards)) {
      formattedUpsell.lockedCards = parseInt(upsell.locked_cards, 10);
    } else {
      formattedUpsell.lockedCards = 0; // zero means dont lock any cards
    }

    // format upsell cards
    formattedUpsell.cards = cloneDeep(upsell.cards || [])
      .map((card, index) => {
        if (card._isNew && card._destroy) return null;

        let formattedCard = {
          position: index + 1,
          destroy: card._destroy || false,
          card: {
            type: card.type,
          },
        };

        // preset card only props
        if (card._isPreset) {
          formattedCard.card.presetPanelCard = true;
          formattedCard.card.id = card.baseCardId;
        }

        // logo image for all cards
        formattedCard.card.logoAssignedImage = card.logo_assigned_image;
        formattedCard.card = formatImageForServer(formattedCard.card, 'logoAssignedImage');

        // format back ids for existing upsell cards
        if (!card._isNew && card.baseCardId) formattedCard.id = card.baseCardId;
        if (!card._isNew && card.upsellCardId) formattedCard.card.id = card.upsellCardId;

        // general card inputs
        if (
          [
            PANEL_UPSELLS_MAP.DOUBLE.value,
            PANEL_UPSELLS_MAP.RECURRING.value,
            PANEL_UPSELLS_MAP.PASSWORD.value,
            PANEL_UPSELLS_MAP.MOBILEOPTIN.value,
          ].includes(card.type)
        ) {
          formattedCard.card.accept = card.accept;
        }

        // slate inputs
        if ([PANEL_UPSELLS_MAP.CONDUITPANEL.value].includes(card.type)) {
          formattedCard.card = { ...formattedCard.card, ...formatSlateUpsellForServer(card) };
          formattedCard.card.splitEvenly = card.split_evenly;
        }

        if (PANEL_UPSELLS_MAP.MONEYPLEDGE.value === card.type) {
          formattedCard.card.accept = card.accept;
          formattedCard.card.moneyPledgeCampaignRevvUid = card.money_pledge_campaign.revv_uid;
        }

        formattedCard.card.campaignRevvUid = card.campaign?.revv_uid ?? null;

        return formattedCard;
      })
      .filter(v => Boolean(v));
  }

  if (STANDARD_UPSELLS.includes(upsell.type)) {
    formattedUpsell.decline = upsell.decline;
  }

  if ([CommitteeUpsell.type, SlateUpsell.type, 'PANEL'].includes(upsell.type)) {
    formattedUpsell.geographicalSort = upsell.geographical_sort;
  }

  // widgets
  if (upsell.widgets) {
    formattedUpsell.widgets = formatWidgetsForServer(upsell.widgets);
  }

  if ([SingleUpsell.type, SlateUpsell.type].includes(upsell.type)) {
    formattedUpsell.splitEvenly = upsell.split_evenly;
  }

  if (SingleUpsell.type === upsell.type) {
    formattedUpsell.pageAmount = formatUpsellAmounts(upsell.page_amounts, upsell.type)[0];
  }

  if ([MultipleUpsell.type, SlateUpsell.type].includes(upsell.type)) {
    formattedUpsell.pageAmounts = formatUpsellAmounts(upsell.page_amounts, upsell.type);
  }

  if (SlateUpsell.type === upsell.type) {
    formattedUpsell = { ...formattedUpsell, ...formatSlateUpsellForServer(upsell) };
  }

  // this is add committees but with amounts attached to them
  if (CommitteeUpsell.type === upsell.type && upsell.conduit_page_candidates) {
    formattedUpsell.conduitPageCandidates = upsell.conduit_page_candidates
      .map(conduitCandidate => {
        const candidate = conduitCandidate.candidate || conduitCandidate.organization;

        const formattedCandidate = {
          pageAmounts: formatUpsellAmounts(conduitCandidate.pageAmounts, upsell.type),
          conduitCandidateRevvUid: candidate.revv_uid,
        };

        if (conduitCandidate._isNew && conduitCandidate._destroy) return null;
        if (!conduitCandidate._isNew && conduitCandidate._destroy) formattedCandidate.destroy = true;

        return formattedCandidate;
      })
      .filter(v => Boolean(v));
  }

  return formattedUpsell;
};

const formatUpsellAmounts = (pageAmounts, type, isMultiplier = false) => {
  const newAmounts = pageAmounts
    .filter(amount => !(amount._destroy && amount._isNew))
    .map((donation, index) => {
      // If this is a new donation and doesn't match the multiplier type, remove it.
      if (isMultiplier !== donation._isMultiplier && donation._isNew) {
        return null;
      }

      const newAmount = {
        amount: parseFloat(donation.amount),
        position: index + 1,
        description: donation.description || '',
        preselected: donation.preselected || false,
      };

      if (!donation._isNew && donation.id) newAmount.id = donation.id;
      if (!donation._isNew && donation.revv_uid) newAmount.revv_uid = donation.revv_uid;
      if (isMultiplier !== donation._isMultiplier && type !== MultipleUpsell.type) newAmount.destroy = true;
      if (donation._destroy) newAmount.destroy = true;

      return newAmount;
    })
    .filter(v => Boolean(v));

  return newAmounts;
};

const formatSlateUpsellForServer = upsell => {
  let newUpsell = {};

  newUpsell.pageAmounts = formatUpsellAmounts(upsell.page_amounts, upsell.type, upsell.multiplier_amounts);
  if (upsell.type === PANEL_UPSELLS_MAP.CONDUITPANEL.value) {
    newUpsell.includeOtherField = upsell.include_other_field;
  } else {
    // Slate upsells have a show_other_amount_field flag instead of a include_other_field flag.
    newUpsell.showOtherAmountField = upsell.include_other_field;
  }
  newUpsell.multiplierAmounts = upsell.multiplier_amounts;
  if (upsell.maximum_amount) {
    newUpsell.maximumAmount = parseFloat(upsell.maximum_amount);
  }
  if (upsell.minimum_amount) {
    newUpsell.minimumAmount = parseFloat(upsell.minimum_amount);
  }
  
  newUpsell.recurringCallout = upsell.recurring_callout;
  newUpsell.recurringAvailable = upsell.recurring_available
  newUpsell.recurringEnableRadioButtons = upsell.recurring_enable_radio_buttons
  newUpsell.roundValues = upsell.round_values || false;
  newUpsell.donorCoverFees = upsell.donor_cover_fees;

  const { conduitPageCandidates, conduitPageOrganizations } = formatConduitsForServer({
    item: {
      conduitPageCandidates: upsell.candidates,
    },
  });
  newUpsell.conduitPageCandidates = conduitPageCandidates;
  newUpsell.conduitPageOrganizations = conduitPageOrganizations;

  return newUpsell;
};

export const formatUpsellGroupForUi = (data, profileCandidate) => {
  if (!data.viewer) return { data: { ...data, upsellGroup: null, upsellCount: null } };
  const candidate = data.viewer && (data.viewer.state_level_organization || data.viewer.candidate);
  const upsellCount = candidate?.upsells?.total_count;

  const [upsellGroup] = candidate?.upsell_groups?.results || [];
  if (!upsellGroup) return { data: { ...data, upsellGroup: null, upsellCount: null } };
  upsellGroup.upsell_pages = (upsellGroup.upsell_pages || []).map(upsell =>
    formatUpsellPagesForUi(upsell, null, profileCandidate)
  );

  return { data: { ...data, upsellGroup, upsellCount } };
};

export const formatUpsellListForUi = data => {
  if (!data.viewer) return { data: { ...data, upsellDropdownOptions: [] } };
  const candidate = data.viewer && (data.viewer.state_level_organization || data.viewer.candidate);

  const upsellDropdownOptions = formatUpsellDropdown(candidate);
  return { data: { ...data, upsellDropdownOptions } };
};

export const formatUpsellDropdown = candidate => {
  let upsellDropdownOptions =
    candidate?.upsells?.results?.map(ug => ({
      label: ug.public_upsell ? `${ug.name} (Public via ${ug.upsell_creator})` : ug.name, // format name if public upsell flow
      value: ug,
    })) ?? [];

  // move public upsells to the bottom of dropdown
  upsellDropdownOptions.sort((a, b) => {
    if (a.value.public_upsell - b.value.public_upsell === 0) {
      return a.label < b.label ? -1 : 1;
    }

    return a.value.public_upsell - b.value.public_upsell;
  });

  return upsellDropdownOptions;
};

export const formatUpsellFlowDropdown = candidate => {
  let upsellGroupsDropdownOptions =
    candidate?.upsell_groups?.results?.map(ug => ({
      label: ug.public_upsell_group ? `${ug.name} (Public via ${ug.upsell_creator})` : ug.name, // format name if public upsell flow
      value: ug,
    })) ?? [];

  // move public upsell groups to the bottom of dropdown
  upsellGroupsDropdownOptions.sort((a, b) => {
    if (a.value.public_upsell_group - b.value.public_upsell_group === 0) {
      return a.label < b.label ? -1 : 1;
    }

    return a.value.public_upsell_group - b.value.public_upsell_group;
  });

  return upsellGroupsDropdownOptions;
};
