import gql from 'graphql-tag';

export const GET_ITERABLE_DEFAULT_FIELDS_AND_AVAILABLE_OPTIONS = gql`
  query getIterableOptions($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        default_iterable_fields {
          lead {
            name
            value
          }
          purchase {
            name
            value
          }
          subscriber {
            name
            value
          }
        }
        available_iterable_fields {
          lead {
            name
            value
          }
          purchase {
            name
            value
          }
          subscriber {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_ITERABLE_DEFAULT_FIELDS_AND_AVAILABLE_OPTIONS = gql`
  query getOrganizationIterableOptions($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        default_iterable_fields {
          lead {
            name
            value
          }
          purchase {
            name
            value
          }
          subscriber {
            name
            value
          }
        }
        available_iterable_fields {
          lead {
            name
            value
          }
          purchase {
            name
            value
          }
          subscriber {
            name
            value
          }
        }
      }
    }
  }
`;
