import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCurrentBreakpointName } from 'react-socks';

import { useAmplitude, useLocalStorage } from 'modules/common';
import {
  profileCandidateSelector,
  isOrganizationSelector,
  isAgencySelector,
  profileIsReadOnlySelector,
  profileIsPagesOnlySelector,
  profileOrgApprovedSelector,
  setSettings,
  loginSettingsSelector,
  profileIsMyPagesOnlySelector,
  profileIsMerchOnlySelector,
} from 'core/login';

import NavBarMain from 'modules/layout/navbar/nav-bar.component';
import DonorNavBar from 'modules/layout/navbar/donor-nav-bar.component';
import ToastHandler from './toastHandler.container.js';

import { NavBarSubMenus } from './navbar/menus';
import CandidateDrawer from './navbar/candidate-drawer/candidate.drawer.component';
import './navbar/candidate-drawer/candidate-drawer.scss';

function NavBarContainer({ children, userIsDonor = false }) {
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const isAgency = useSelector(isAgencySelector);
  const isReadOnly = useSelector(profileIsReadOnlySelector);
  const isPagesOnly = useSelector(profileIsPagesOnlySelector);
  const isMyPagesOnly = useSelector(profileIsMyPagesOnlySelector);
  const isMerchOnly = useSelector(profileIsMerchOnlySelector);
  const approved = useSelector(profileOrgApprovedSelector);
  const loginStateStore = useSelector(loginSettingsSelector);
  const dispatch = useDispatch();

  const [userMenu, toggleUserMenu] = useLocalStorage('userMenu', true);
  const breakpoint = useCurrentBreakpointName();

  const [subNavStates, setSubNavStates] = useState({
    showPagesSubNav: false,
    showPeopleSubNav: false,
    showRevenueSubNav: false,
    showAccountingSubNav: false,
    showUtilitiesSubNav: false,
    showStoreFrontSubNav: false,
    showBillpaysSubNav: false,
  });

  const menuRef = useRef(null);
  const [logEvent] = useAmplitude();
  const history = useHistory();

  const navbarRef = useRef(null);

  const [height, setHeight] = useState(0);

  // keep track of main navbar height so the candiate idebar can adjust height
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!navbarRef.current) return;
    setHeight(navbarRef.current.clientHeight);
  });

  const handleSelect = (eventKey, event, isExternalLink) => {
    event?.preventDefault();
    if (!eventKey) return;

    // log click event in amplitude
    let eventName = ((event && event.target && event.target.text) || '').trim().toLowerCase();
    if (event && event.target.getAttribute('alt')) {
      eventName = event.target.getAttribute('alt');
    }

    // dont do this for settings - we already have custom event logs for this
    if (!eventName.includes('settings') && eventName) {
      logEvent(`navbar click ${eventName}`);
    }

    if (isExternalLink) return window.open(eventKey, '_blank');
    history.push(eventKey);
  };

  const onToggleUserMenu = useCallback(() => {
    toggleUserMenu(c => !c);
    // when userMenu sidebar is toggled, dispatch to update userMenu status in redux store
    const newSettings = { ...loginStateStore, userMenu: userMenu };
    dispatch(setSettings(newSettings));
  }, [toggleUserMenu, dispatch, loginStateStore, userMenu]);

  // close candidate menu on toggle of navbar menu on mobile
  // on mobile always close candidate menu when selecting candidate
  const closeUserMenu = useCallback(
    onMobileOnly => {
      if (!onMobileOnly) toggleUserMenu(false);

      // if only on mobile then check to see if we are mobile view before closing
      if (['xsmall', 'small', 'medium'].includes(breakpoint)) {
        return toggleUserMenu(false);
      }
    },
    [toggleUserMenu, breakpoint]
  );

  return (
    <div>
      {userIsDonor ? (
        <DonorNavBar />
      ) : (
        <>
          <NavBarMain
            navbarRef={navbarRef}
            toggleUserMenu={toggleUserMenu}
            userMenu={userMenu}
            onToggleUserMenu={onToggleUserMenu}
            subNavStates={subNavStates}
            setSubNavStates={setSubNavStates}
            closeUserMenu={closeUserMenu}
            handleSelect={handleSelect}
          />
          <div className="top-subnav-bar" style={{ top: `${height}px` }}>
            <NavBarSubMenus
              isOrganization={isOrganization}
              isAgency={isAgency}
              handleSelect={handleSelect}
              profileCandidate={profileCandidate}
              showPagesSubNav={subNavStates.showPagesSubNav}
              showPeopleSubNav={subNavStates.showPeopleSubNav}
              showRevenueSubNav={subNavStates.showRevenueSubNav}
              showAccountingSubNav={subNavStates.showAccountingSubNav}
              showStoreFrontSubNav={subNavStates.showStoreFrontSubNav}
              showUtilitiesSubNav={subNavStates.showUtilitiesSubNav}
              showBillpaysSubNav={subNavStates.showBillpaysSubNav}
              isReadOnly={isReadOnly}
              isPagesOnly={isPagesOnly}
              isMyPagesOnly={isMyPagesOnly}
              isMerchOnly={isMerchOnly}
              approved={approved}
            />
          </div>
        </>
      )}

      <div className="h-100">
        <ToastHandler isTop />

        <div className="d-flex h-100">
          <div className={'site-wrap ' + (!userIsDonor && userMenu ? 'open' : '')}>{children}</div>

          {userIsDonor ? null : (
            <div
              style={{ top: `${height}px` }}
              className={classnames('candidate-menu-container', {
                open: userMenu,
              })}
              ref={menuRef}
            >
              <CandidateDrawer closeUserMenu={closeUserMenu} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

NavBarContainer.propTypes = {
  userIsDonor: PropTypes.bool,
  children: PropTypes.node,
};

export default NavBarContainer;
