import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Input, FloatGroup } from 'modules/common';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Continue({ editedUpsell, onUpdateEditedUpsell, className, disabled }) {
  return (
    <Container className={className}>
      <Row>
        <Col sm={12} className="px-0">
          <Typography variant="h5">Continue Button Text</Typography>
          <Typography>Enter copy for the continue button.</Typography>
          <FloatGroup>
            <Input
              type="text"
              disabled={disabled}
              name="continue_button_text"
              value={editedUpsell.continue_button_text}
              onChange={onUpdateEditedUpsell}
              id="continue_button_text"
              ariaLabel="Continue Button Text"
              placeholder="Continue Button Text"
              validators={[]}
              errorMessages={[]}
            />
          </FloatGroup>
        </Col>
      </Row>
    </Container>
  );
}

Continue.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  className: PropTypes.string,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Continue.defaultProps = {
  disabled: false,
};

export default Continue;
