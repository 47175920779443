import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import { isEqual } from 'lodash';
import Input from './input.component';
import FloatGroup from 'modules/common/form/float-group.component';
import Icon from 'modules/common/icon.component';
import SubText from 'modules/common/sub-text.component';
import InputErrorMessage from 'modules/common/form/input-error-message.component';

import { ValidatorComponent } from 'core/validation';
import { customStyles } from './select-styling';

const PrependedSearchIcon = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <Icon icon="search" size="lg" color="gray" style={{ position: 'absolute', left: 6 }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

PrependedSearchIcon.propTypes = {
  children: PropTypes.any,
};

class SelectComponent extends ValidatorComponent {
  /* Save options and styles in state so they can be conditionally updated to improve performance. */
  state = {
    dropdownOptions: [],
    selectStyles: {},
  };

  components = this.props.prependSearchIcon ? { ValueContainer: PrependedSearchIcon } : {};

  // Calculate the options and styles based on props here.
  static getDerivedStateFromProps(props, state) {
    const { hideDropdownIndicator, hideMenu, options } = props;

    const selectStyles = customStyles({
      ...props,
      hideDropdownIndicator,
      hideMenu: hideMenu,
      showScrollbarOnMenu: props.numberOfDropdownItems < (options?.length || 0),
    });

    let newOptions = options;

    // do we add an empty option that's selectable? (only if any options to even select)
    if (props.isClearable && options && options?.length !== 0) {
      newOptions = [{ value: '', label: props.allDefault ? 'All' : '' }, ...options];
    }

    // If the values are the same, don't update state.
    if (isEqual(newOptions, state.dropdownOptions) && isEqual(selectStyles, state.selectStyles)) return null;
    return {
      dropdownOptions: newOptions,
      selectStyles,
    };
  }

  handleFocusChange = (isActive, setActive) => () => {
    setActive(isActive);
    const { onFocus } = this.props;

    if (typeof onFocus === 'function' && isActive) {
      onFocus();
    } else if (!isActive) {
      /* Call onBlur from ValidatorComponent which will also call onBlur from props if it exists. */
      this.onBlur();
    }
  };

  render() {
    const {
      inputType = 'text',
      placeholder,
      noOptionsMessage,
      // eslint-disable-next-line no-unused-vars
      options = [],
      className,
      classNameWrapper,
      pro,
      name,
      errorMessages,
      isClearable,
      disabled,
      errorMessageClasses,
      validateOnMount,
      invalidIcon,
      errorIcon,
      isWarning,
      prependSearchIcon,
      value,
      onChange,
      isMulti,
      title,
      label,
      isSearchable,
      noLabel,
      description,
      floatLabel,
      numberOfDropdownItems = 3,
      wrapperClassName,
      children,
      ...rest
    } = this.props;

    if (disabled) {
      return (
        <FloatGroup>
          <Input
            type={inputType}
            disabled
            placeholder={placeholder}
            value={value?.label || value || ''}
            floatLabel={floatLabel}
          />
        </FloatGroup>
      );
    }

    const errorMessage = this.errorMessage();

    /**
     * wrapper to keep event structure standardized
     */
    const _onChange = event => {
      let newValue = event && (event.value !== undefined || event.value !== null) ? event.value : event;

      // if multi select then we need whole event object
      if (isMulti) newValue = event;
      onChange({ target: { name, value: newValue } });
    };

    // if searchable or already has title we dont want floating label on search
    const Wrapper = title || noLabel ? 'div' : FloatGroup;

    return (
      <>
        <FloatGroup.Context.Consumer>
          {floatGroup => (
            <>
              <Wrapper className={classnames('winred-input-select', wrapperClassName)}>
                {title ? <Form.Label className="mb-1">{title}</Form.Label> : null}
                <Select
                  name={name}
                  value={value || ''}
                  components={components}
                  maxMenuHeight={numberOfDropdownItems * 40}
                  options={this.state.dropdownOptions}
                  styles={this.state.selectStyles}
                  className={classnames(
                    '',
                    {
                      'winred-input--active': floatGroup.shouldFloat,
                      'winred-input--error_select': errorMessage,
                    },
                    className
                  )}
                  placeholder={placeholder !== undefined ? placeholder : 'Select...'}
                  noOptionsMessage={() => noOptionsMessage || 'No results found.'}
                  onFocus={this.handleFocusChange(true, floatGroup.setActive)}
                  onBlur={this.handleFocusChange(false, floatGroup.setActive)}
                  onChange={_onChange}
                  isMulti={isMulti}
                  isSearchable={isSearchable}
                  floatLabel={floatLabel}
                  label={label || floatLabel}
                  {...rest}
                />
                {description ? (
                  <SubText description={description} className={classnames({ 'mb-0': errorMessage })} />
                ) : null}
                {children}
                <InputErrorMessage
                  errorMessageClasses={errorMessageClasses}
                  errorMessage={errorMessage}
                  errorIcon={errorIcon || invalidIcon}
                  isWarning={isWarning}
                />
              </Wrapper>
            </>
          )}
        </FloatGroup.Context.Consumer>
      </>
    );
  }
}

SelectComponent.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  pro: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isMulti: PropTypes.bool,
  errorIcon: PropTypes.string,
  invalidIcon: PropTypes.string,
  classNameWrapper: PropTypes.string,
  noLabel: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  children: PropTypes.node,
  hideMenu: PropTypes.bool,
};
SelectComponent.defaultProps = {
  numberOfDropdownItems: 3,
  hideDropdownIndicator: false,
};

export default React.memo(SelectComponent);
