import gql from 'graphql-tag';

export const donationFragments = {
  donations: gql`
    fragment ReportingDonations on ReportingResult {
      current_page
      page_count
      total_count
      donation_amount
      email_count
      donation_count
      average_donation

      results {
        created_at

        first_name
        last_name
        pac_name
        business_name
        fec_id
        amount
        charge_status
        source_url
        email
        phone
        source_code
        recurring
        revv_subscription_id
        revv_uid
        same_day_refund
        source_organization
        campaign {
          name
        }

        occupation
        utm_source
        address
        city
        state
        zip
        employer
        card_brand
        payout_revv_uid
        utm_term
        utm_medium
        utm_campaign
        utm_content

        order_number
        order_revv_uid
        interval
        payment_processor
        recurring_status
        product_name

        contributor_type
        pac_name
        business_name
        type_of_business
        tax_id
        fec_id
      }
    }
  `,
  donation: gql`
    fragment ReportingDonation on ReportingResult {
      results {
        created_at
        first_name
        last_name
        charge_status
        source_url
        email
        phone
        refunded_at
        refunded_amount
        source_code
        voidable
        voided_at
        interval
        canceled_at
        subscription_created_at
        same_day_refund
        allowSameDayRefund
        same_day_refund_expires_at
        donor_covers_fees
        contributor_type
        pac_name
        business_name
        type_of_business
        tax_id
        bundler_id
        virtual_terminal_profile_email

        campaign {
          revv_uid
          name
        }
        recurring
        revv_subscription_id
        card_expiry
        card_last4
        card_brand
        revv_uid
        user_type
        profile_revv_uid
        charge_revv_uid
        refundable
        is_split_donation
        order_number
        subscription_status
        custom_fields {
          name
          value
        }
        amount
        fee
        net
        payout_revv_uid

        pledges {
          results {
            revv_uid
            created_at
            money_pledge_internal_name
            processing_date
            status
            conduit_amount_cents
            canceled_at
          }
        }
        total_fee_cents
        processing_fee_cents
        partner_fees {
          name
          feeCents
        }
        donationEvents {
          type
          gross_cents
          created_at
          bill_pay_fees_recovered_cents
          processing_fees_recovered_cents
          net_cents
          payout_revv_uid
        }
      }
    }
  `,
};

export const GET_DONATIONS_REPORT = gql`
  query getDonationsReport(
    $search: String
    $page: Int
    $limit: Int
    $revvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $conduitCandidateRevvUid: String
    $status: DonationStatusEnum
    $campaignRevvUids: [String!]
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $payoutRevvUid: String
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $candidateSearch: Boolean
    $origins: [String!]
    $recipients: [String!]
    $organizationRevvUid: String
    $ignoreCustomFields: Boolean
    $bundlerId: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_statistics_report(
          filters: {
            search: $search
            productName: $productName
            page: $page
            limit: $limit
            revvUid: $revvUid
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            conduitCandidateRevvUid: $conduitCandidateRevvUid
            status: $status
            campaignRevvUids: $campaignRevvUids
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            payoutRevvUid: $payoutRevvUid
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            candidateSearch: $candidateSearch
            origins: $origins
            recipients: $recipients
            ignoreCustomFields: $ignoreCustomFields
            bundlerId: $bundlerId
          }
        )
      }
    }
  }
`;

export const GET_DONATIONS_QUERY = gql`
  query getDonations(
    $search: String
    $page: Int
    $limit: Int
    $revvUid: String
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $profileRevvUid: String
    $conduitCandidateRevvUid: String
    $status: DonationStatusEnum
    $campaignRevvUids: [String!]
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $firstName: String
    $lastName: String
    $sourceUrl: String
    $pageType: PageTypeEnum
    $sourceCode: String
    $email: String
    $phone: String
    $mobile: String
    $revvUser: DonorTypeEnum
    $contributorType: [ContributorTypeEnum!]
    $payoutRevvUid: String
    $recurrence: [RecurrenceTypeEnum!]
    $sortOrder: String
    $amountLower: String
    $amountUpper: String
    $productName: [ProductNameTypeEnum!]
    $candidateSearch: Boolean
    $origins: [String!]
    $recipients: [String!]
    $organizationRevvUid: String
    $bundlerId: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        origins {
          revv_uid
          name
        }
        recipients {
          revv_uid
          name
        }
        campaigns {
          results {
            revv_uid
            name
          }
        }
        statistics(
          filters: {
            search: $search
            productName: $productName
            page: $page
            limit: $limit
            revvUid: $revvUid
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            profileRevvUid: $profileRevvUid
            conduitCandidateRevvUid: $conduitCandidateRevvUid
            status: $status
            campaignRevvUids: $campaignRevvUids
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmCampaign: $utmCampaign
            utmTerm: $utmTerm
            utmContent: $utmContent
            firstName: $firstName
            lastName: $lastName
            sourceUrl: $sourceUrl
            pageType: $pageType
            sourceCode: $sourceCode
            email: $email
            phone: $phone
            mobile: $mobile
            revvUser: $revvUser
            contributorType: $contributorType
            payoutRevvUid: $payoutRevvUid
            recurrence: $recurrence
            sortOrder: $sortOrder
            amountLower: $amountLower
            amountUpper: $amountUpper
            candidateSearch: $candidateSearch
            origins: $origins
            recipients: $recipients
            bundlerId: $bundlerId
          }
        ) {
          ...ReportingDonations
        }
      }
    }
  }
  ${donationFragments.donations}
`;

export const GET_DONATION_QUERY = gql`
  query getDonation($revv_uid: String, $organizationRevvUid: String, $contributorType: [ContributorTypeEnum!]) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        revv_uid
        statistics(filters: { revvUid: $revv_uid, contributorType: $contributorType }) {
          ...ReportingDonation
        }
      }
    }
  }
  ${donationFragments.donation}
`;

export const REFUND_DONATION_MUTATION = gql`
  mutation refundDonation(
    $organizationRevvUid: String
    $chargeUid: String!
    $refund: String!
    $partialAmount: String!
    $candidateUid: String!
  ) {
    refund(
      organizationRevvUid: $organizationRevvUid
      chargeRefundForm: {
        candidateUid: $candidateUid
        chargeUid: $chargeUid
        refund: $refund
        partialAmount: $partialAmount
      }
    ) {
      message
      errors
    }
  }
`;
export const CANDIDATE_CREATE_MOOV_ACCOUNT = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String) {
    moovCreateDonorAccount(firstName: $firstName, lastName: $lastName, email: $email) {
      errors
      dammed
      accountId
      accountToken
      email
    }
  }
`;

export const VIRTUAL_TERMINAL_CANDIDATE_DONATION = gql`
  mutation createCandidateVirtualTerminalDonation(
    $virtualTerminalDonation: VirtualTerminalDonationInput!
    $organizationRevvUid: String!
  ) {
    candidateCreateVirtualTerminalDonation(
      organizationRevvUid: $organizationRevvUid
      virtualTerminalDonation: $virtualTerminalDonation
    ) {
      errors
      message
    }
  }
`;
