import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Typography, WinRedInputMoney, WinRedInputNumbers } from 'modules/common';
import { Col, Row } from 'react-bootstrap';
import { THERMOMETER_TYPES_PREPEND_MAP } from './thermometer-fields';

export const ThermometerGoalList = ({ thermometerType, isMoneyThermometer, goals, onChange, validators }) => {
  const SelectedComponent = isMoneyThermometer ? WinRedInputMoney : WinRedInputNumbers;
  const onChangeGoal = (value, index) => {
    let tempGoalsArray = goals.map((item, i) => {
      if (i !== index) return item;
      return { ...item, amount_cents: value };
    });
    onChange({ target: { name: 'goals', value: tempGoalsArray } });
  };

  const handleAddGoal = () => {
    onChange({ target: { name: 'goals', value: [...goals, { amount_cents: null, _isNew: true }] } });
  };

  const handleRemoveGoal = (goal, index) => {
    let tempGoalsArray = [];
    if (goal._isNew) {
      tempGoalsArray = goals.filter((item, i) => i !== index);
    } else {
      tempGoalsArray = goals.map((item, idx) => {
        if (index !== idx) return item;
        return { ...item, destroy: true };
      });
    }

    onChange({ target: { name: 'goals', value: tempGoalsArray } });
  };
  return (
    <>
      <Typography color="mirage" className="mb-0 font-medium">
        Thermometer Goals
      </Typography>
      <Typography className="mb-2">
        Add goals to your thermometer. When the first is achieved then the next goal will be presented to the user.
      </Typography>
      {goals.map((goal, idx) => {
        if (goal?.destroy) {
          return null;
        }
        let commonGoalProps = {
          prepend: THERMOMETER_TYPES_PREPEND_MAP[thermometerType],
          name: 'newGoalValue',
          placeholder: 'Goal Amount',
          value: goal.amount_cents,
          onChange: e => onChangeGoal(e.target.value, idx),
          max: 999999,
          validators,
        };
        let selectedGoalProps = isMoneyThermometer
          ? { ...commonGoalProps, isCents: true }
          : { ...commonGoalProps, removeDecimalPoint: !isMoneyThermometer };
        return (
          <Row key={idx}>
            <Col>
              <SelectedComponent {...selectedGoalProps} />
            </Col>
            {idx === 0 ? null : (
              <Col xs="auto" className="d-flex justify-content-center align-items-center pl-0 mb-4">
                <div className="pointer no-outline" onClick={() => handleRemoveGoal(goal, idx)} role="button">
                  <Icon icon="times" color="gray" size="lg" />
                </div>
              </Col>
            )}
          </Row>
        );
      })}
      <Card
        variant="green"
        className="d-flex justify-content-center align-items-center p-2  cursor-pointer"
        onClick={handleAddGoal}
      >
        <Row>
          <Col>Add Additional Goal</Col>
        </Row>
      </Card>
    </>
  );
};

ThermometerGoalList.propTypes = {
  goals: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array.isRequired,
};
