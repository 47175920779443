import gql from 'graphql-tag';

export const INVITE_USER_MUTATION = gql`
  mutation inviteUser(
    $email: String!
    $roleType: RoleTypeEnum!
    $candidateRevvUid: String!
    $organizationRevvUid: String!
  ) {
    inviteUser(
      email: $email
      roleType: $roleType
      organizationRevvUid: $organizationRevvUid
      candidateRevvUid: $candidateRevvUid
    ) {
      errors
      user {
        revv_uid
        first_name
        last_name
        email
      }
    }
  }
`;

export const REMOVE_USERS_MUTATION = gql`
  mutation removeUsers($profileUids: [String!]!, $organizationRevvUid: String) {
    removeUsers(profileUids: $profileUids, organizationRevvUid: $organizationRevvUid) {
      errors
      allRemoved
    }
  }
`;
