import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { ExpandedRowValue, Typography, WinRedChip } from 'modules/common';
// import { formatAmount } from 'core/utilities';
// import { fulfillmentHouseMap } from '../page.tools';

function ExpandedRowBody({ item }) {
  const candidates = [...item.conduitPageCandidates, ...item.conduitPageOrganizations];

  return (
    <Container fluid className="mt-3 px-5 page-list-expanded cursor-default">
      {item.upsellAgencyFeesEnabled || item.recurringAgencyFeesEnabled ? (
        <Row>
          <Col sm={12} className="d-flex mb-3">
            {item.upsellAgencyFeesEnabled ? <WinRedChip variant="purple">Upsell Bill-Pay Enabled</WinRedChip> : null}
            {item.recurringAgencyFeesEnabled ? (
              <WinRedChip variant="purple" className={item.upsellAgencyFeesEnabled ? 'ml-3' : ''}>
                Subscription Bill-Pay Enabled
              </WinRedChip>
            ) : null}
          </Col>
        </Row>
      ) : null}

      {/* {Object.entries(item.fulfillmentProducts).map(([fulfillmentHouse, products], index) => {
        if (products.length === 0) return null;

        return (
          <Row key={index}>
            <Col sm={2}>
              <Typography variant="bodyMedium">{fulfillmentHouseMap[fulfillmentHouse] || fulfillmentHouse}</Typography>
            </Col>
            <Col sm={10} className="d-flex">
              <Container>
                <Row>
                  {products.map((product, index) => {
                    return (
                      <ExpandedRowValue
                        key={index}
                        sm={6}
                        md={3}
                        label={product.name}
                        value={`${formatAmount(product.price_cents, true)} Base Price`}
                        className="pl-0 pl-md-2 pb-3 sub-table-col"
                      />
                    );
                  })}
                </Row>
              </Container>
            </Col>
          </Row>
        );
      })} */}

      {candidates.map((conduitCandidate, index) => {
        if (!conduitCandidate.vendorFees || conduitCandidate.vendorFees.length === 0) return null;
        const _candidate = conduitCandidate.candidate || conduitCandidate.organization;

        return (
          <Row key={index}>
            <Col sm={2}>
              <Typography variant="bodyMedium">{_candidate.name}</Typography>
            </Col>
            <Col sm={10} className="d-flex">
              <Container>
                <Row>
                  {conduitCandidate.vendorFees?.map(fee => {
                    return (
                      <ExpandedRowValue
                        key={fee.id}
                        sm={6}
                        md={3}
                        label={fee.name}
                        value={`${fee.feePercentage}% Bill-Pay Fee`}
                        className="pl-0 pl-md-2 pb-3 sub-table-col"
                      />
                    );
                  })}
                </Row>
              </Container>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}

ExpandedRowBody.propTypes = {
  item: PropTypes.object.isRequired,
};

export { ExpandedRowBody };
