import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ color = '#94aac1', now, min, max, className, height = '30px', borderRadius = '0.25rem' }) => {
  const minValue = min || 0;
  const maxValue = max || 100;

  let nowValue = now || 0;
  nowValue = Math.max(nowValue, minValue);
  nowValue = Math.min(nowValue, maxValue);

  return (
    <div className={classNames('progress', className)} style={{ borderRadius, height }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${(nowValue / maxValue) * 100}%`, backgroundColor: color, borderRadius }}
        aria-valuenow={nowValue}
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  variant: PropTypes.oneOf(['success', 'orange', 'default']),
  now: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
};

export default ProgressBar;
