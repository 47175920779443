export const thermometerWidgetOptionsCreate = [
  { value: 'REVENUE', label: 'Revenue' },
  { value: 'SUBSCRIBERS', label: 'Subscribers' },
  { value: 'DONOR_PROFILES', label: 'Donor Profiles' },
];
export const thermometerWidgetOptions = [
  { value: 'REVENUE', label: 'Revenue' },
  { value: 'DONATIONS', label: 'Donations' },
  { value: 'SUBSCRIBERS', label: 'Subscribers' },
  { value: 'DONOR_PROFILES', label: 'Donor Profiles' },
];
export const thermometerWidgetOptionsPetition = [{ value: 'LEADS', label: 'Leads' }];

export const thermometeDataSourceOptions = [
  { value: 'ALL_PAGES', label: 'All Pages' },
  { value: 'MOST_RECENT_PAGES', label: 'Pages with this Thermometer' }
];

export const THERMOMETER_TYPES_MAP = {
  REVENUE: 'Revenue',
  DONATIONS: 'Donations',
  SUBSCRIBERS: 'Subscribers',
  DONOR_PROFILES: 'Donor Profiles',
  LEADS: 'Leads',
};

export const THERMOMETER_TYPES_PREPEND_MAP = {
  REVENUE: '$',
  DONATIONS: '$',
  SUBSCRIBERS: 'Subscriber Goal',
  DONOR_PROFILES: 'Donor Profile Goal',
  LEADS: 'Subscriber Goal',
};

export const THERMOMETER_TYPES_PREVIEW_MAP = {
  REVENUE: 'Revenue',
  DONATIONS: 'Donations',
  SUBSCRIBERS: 'Subscribers',
  DONOR_PROFILES: 'Donor Profiles',
  LEADS: 'Supporters',
};

export const THERMOMETER_DATA_SOURCE_MAP = {
  ALL_PAGES: 'All Pages',
  MOST_RECENT_PAGES: 'Pages with this Thermometer',
};
