import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Dropdown } from 'react-bootstrap';
import { CommitteeComponent } from '../committee.component';
import { IndexTableContextMenu, WinRedInput } from 'modules/common';
import { replaceSpacesWithDashes, urlSlugValidator } from 'core/utilities';

export const TemplateTableMobile = ({ items, dropdownAction, onCheck, selectedTemplates, onChange }) => {
  if (!items) return null;

  return items.map((item, index) => {
    const { id, name, slug, upsell_agency_fees_enabled, recurring_agency_fees_enabled, source_code } = item;
    const isSelected = selectedTemplates.includes(id);
    const handleOnCheck = ({ target: { checked } }) => {
      onCheck(checked, id);
    };
    const onChangeSlug = ({ target: { name, value } }) => {
      handleOnChange({ target: { name, value: replaceSpacesWithDashes(value) } });
    };
    const handleOnChange = ({ target: { name, value } }) => {
      onChange(id, name, value);
    };
    return (
      <Fragment key={index}>
        <Row className="list-item-card flex items-start w-11/12 mx-auto">
          <Col xs={1} className="">
            <Form.Check type="checkbox" checked={isSelected} onChange={handleOnCheck} />
          </Col>
          <Col xs={10}>
            <div className="vendorFees flex flex-col justify-center mb-2">
              {item?.partnership_templates?.length > 0
                ? item?.partnership_templates.map(committee => {
                    return <CommitteeComponent item={committee} key={committee.id} isReults />;
                  })
                : null}
            </div>
            <WinRedInput
              name="name"
              placeholder="Internal Page Name"
              className="mb-2"
              type="text"
              validators={
                isSelected && [
                  { message: 'Internal Page Name is required', validator: 'required' },
                  {
                    message: 'Internal Page Name cannot be over 140 characters',
                    validator: pageName => pageName.length <= 140,
                  },
                ]
              }
              value={name}
              onChange={handleOnChange}
            />
            <WinRedInput
              name="slug"
              placeholder="URL Slug"
              className="mb-2"
              type="text"
              validators={
                isSelected && [
                  { message: 'URL Slug is required', validator: 'required' },
                  {
                    message: 'URL slug can only contain lowercase letters, numbers, dashes and underscores.',
                    validator: urlSlugValidator,
                  },
                  { message: 'URL Slug cannot be over 50 characters', validator: slug => slug.length <= 50 },
                ]
              }
              value={slug}
              onChange={onChangeSlug}
            />
            <WinRedInput
              name="source_code"
              placeholder="Source Code"
              type="text"
              value={source_code}
              onChange={handleOnChange}
            />

            <div className="flex items-center mt-4">
              {upsell_agency_fees_enabled ? (
                <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center mr-2">
                  Upsell
                </div>
              ) : null}
              {recurring_agency_fees_enabled ? (
                <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center">
                  Subscription
                </div>
              ) : null}
            </div>
          </Col>
          <Col xs={1}>
            <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
              <Dropdown.Item eventKey="edit">Edit</Dropdown.Item>
              <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
            </IndexTableContextMenu>
          </Col>
        </Row>
      </Fragment>
    );
  });
};

TemplateTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  dropdownAction: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  selectedTemplates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
