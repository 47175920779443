import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';

import { Typography, Toggle, ExternalLink } from 'modules/common';
import './index.scss';

const SmallFlexBinaryChoiceRow = ({
  name,
  title = '',
  subTitle = '',
  value = false,
  onChange,
  className = 'mb-4',
  classNameItems = '',
  children,
  disabled,
  learnMoreLink,
}) => {
  return (
    <div className={classnames('toggler', className)}>
      <Row className={classnames('justify-content-between align-items-center', classNameItems)}>
        {title || subTitle ? (
          <Col>
            {title ? <Typography className="toggler-header">{title}</Typography> : null}
            {subTitle ? <Typography className="toggler-subtitle">{subTitle}{' '}{learnMoreLink ? <ExternalLink href={learnMoreLink}>Learn More.</ExternalLink> : null }</Typography> : null}
          </Col>
        ) : null}

        <Col xs="auto" className="toggler-button-container d-flex align-items-center">
          <div className={classnames('toggler', { 'toggler-disabled': disabled })}>
            <Toggle name={name} value={value} onChange={onChange} disabled={disabled} />
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

SmallFlexBinaryChoiceRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

SmallFlexBinaryChoiceRow.defaultProps = {
  className: '',
  value: false,
};

export default SmallFlexBinaryChoiceRow;
