import gql from 'graphql-tag';

const SETTINGS_ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationSettings on StateLevelOrganization {
    name
    first_name
    last_name
    created_at
    payout_frequency
    revv_uid
    disclaimer
    opt_in_to_directory
    storefront_opt_in_to_directory
    confirmation_dialog_contribution_cents
    office
    district
    state
    processor

    timezone
    contact_email
    default_page_uid
    default_upsell_group_revv_uid
    default_money_bomb_revv_uid
    default_upsell_pathway_revv_uid
    default_upsell_process_type
    slug
    default_source_code
    statement_descriptor
    maximum_contribution_cents
    display_global_disclaimer
    global_disclaimer
    recurring_callout
    disclaimer
    long_disclaimer
    enable_employer_fields
    custom_subdomains_allowed
    enable_donor_cover_fees
    organization_subdomain {
      subdomain
    }
    organization_subdomains {
      id
      subdomain
      status
    }

    avatar {
      id
      image {
        id
        thumbnailUrl
      }
    }

    users {
      revv_uid
      first_name
      last_name
      email
      role_type(revvUid: $organizationRevvUid)
      install_two_factor
      two_factor_required
    }

    account {
      business_name
      business_website
      business_phone
      tax_id
      address
      address2
      city
      state
      zip
      status
      legal_entity
    }

    owners {
      revv_uid
      email
      first_name
      last_name
      title
      phone
      dob_day
      dob_month
      dob_year
      ssn_last_4
      address
      address2
      city
      state
      zip
      stripe_identity_document_front_id
      stripe_identity_document_back_id
      account_opener
      owner
      status
      full_ssn_token_provided
    }

    bank_accounts {
      revv_uid
      account_holder_name
      routing_number
      last4
      account_holder_type
      bank_name
      status
      currency
      country
      default_for_currency
    }

    account_closure_setting {
      accountClosureActivity {
        results {
          id
          date
          email
          activityType
          objectType
          changedFrom
          changedTo
        }
      }
      status
      remove_from_conduiting
      remove_from_conduiting_on
      archive_pages_on
      archive_pages
      cancel_recurring_and_pledges_on
      cancel_recurring_and_pledges
      confirmation_paragraph
      archive_petition_pages
      archive_petition_pages_on
      confirmation_petition_paragraph
      redirect_page {
        revv_uid
        publicTitle
      }
      redirect_petition_page {
        revv_uid
        publicTitle
      }
      pause_recurring
      pause_recurring_on
      unpause_recurring_on
      unpause_account_on
    }

    allow_donations_to_process

    bulk_action_history {
      money_pledge {
        created_at
        whodunnit
      }
      recurring_callout {
        created_at
        whodunnit
      }
      upsell_flow {
        created_at
        whodunnit
      }
      upsell_pathway {
        created_at
        whodunnit
      }
      sms {
        created_at
        whodunnit
      }
      open_graph {
        created_at
        whodunnit
      }
      exit_intent_widget {
        created_at
        whodunnit
      }
      donor_covers_fees {
        created_at
        whodunnit
      }
    }

    can_accept_business_donations
    can_accept_pac_donations
    hide_verification_message
    contribution_setting {
      enable_business_donations
      business_donations_by_default
      business_donations_maximum_amount_cents
      enable_business_donations_type_of_business
      enable_business_donations_tax_id
      enable_pac_donations
      pac_donations_maximum_amount_cents
      enable_pac_donations_fec_id

      type_of_business_callout
      enable_business_donations_type_of_business_dropdown
      additional_business_disclaimer
      additional_pac_disclaimer

      business_donations_acknowledged
      pac_donations_acknowledged
      pac_donation_terms_acknowledged_at
      business_donation_terms_acknowledged_at
      pac_donation_terms_acknowledged_by
      business_donation_terms_acknowledged_by
    }
    default_setting {
      smsOptinCallout
      enableSmsOptin
      mobileDisclaimerText
    }
    marketing_setting {
      ogTitle
      ogVideo
      ogDescription
      ogAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      twitterTitle
      twitterDescription
      twitterAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_SETTINGS_QUERY = gql`
  query getOrganizationSettings($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        ...OrganizationSettings
      }
    }
  }
  ${SETTINGS_ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION_BANK_ACCOUNT_ACTIVITY = gql`
  query getOrganizationSettingsBankLogs($organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        bank_accounts_activity {
          results {
            email
            type
            last4
            date
          }
        }
      }
    }
  }
`;

export const SETTINGS_ORGANIZATION_MUTATION = gql`
  mutation saveOrganizationSettings(
    $candidate: OrganizationInput!
    $account: CandidateAccountInput!
    $organizationRevvUid: String!
  ) {
    updateOrganization(organization: $candidate) {
      errors
      state_level_organization {
        ...OrganizationSettings
      }
    }
    organizationUpdateAccount(account: $account, organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
  ${SETTINGS_ORGANIZATION_FRAGMENT}
`;

export const ACCT_CLOSURE_UPDATE_ORGANIZATION_SETTINGS_MUTATION = gql`
  mutation saveAccountClosureSettings(
    $accountClosureSetting: CandidateAccountClosureSettingInputType!
    $organizationRevvUid: String
  ) {
    organizationUpdateAccountClosureSetting(
      accountClosureSetting: $accountClosureSetting
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      account_closure_setting {
        status
        remove_from_conduiting
        remove_from_conduiting_on
        archive_pages_on
        archive_pages
        cancel_recurring_and_pledges_on
        cancel_recurring_and_pledges
        confirmation_paragraph
        archive_petition_pages
        archive_petition_pages_on
        confirmation_petition_paragraph
        redirect_page {
          revv_uid
          publicTitle
        }
        redirect_petition_page {
          revv_uid
          publicTitle
        }
        pause_recurring
        pause_recurring_on
        unpause_recurring_on
        unpause_account_on
      }
    }
  }
`;