import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';

import Table from '../table.component';
import '../table.scss';

function TableRowExpanded({ children, colspan, fluid, className }) {
  return (
    <>
      <Table.Row className="winred-table-expanded-container">
        <Table.Cell colSpan={colspan} className={className}>
          <Container fluid={fluid}>
            <Row>{children}</Row>
          </Container>
        </Table.Cell>
      </Table.Row>
    </>
  );
}

TableRowExpanded.defaultProps = {
  colspan: 8,
};

TableRowExpanded.propTypes = {
  children: PropTypes.any.isRequired,
  colspan: PropTypes.number,
  fluid: PropTypes.bool,
  className: PropTypes.string,
};

export default TableRowExpanded;
