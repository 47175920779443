import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import 'modules/common/common.scss';

function InfoCardDetails({ header, children }) {
  return (
    <>
      <Row>
        <Col xs={12} sm={children ? 7 : 12} className="info-card-details">
          {header}
        </Col>
        <Col xs={12} sm={5} className="info-card-details pt-2 pt-sm-0">
          {children}
        </Col>
      </Row>
    </>
  );
}

InfoCardDetails.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default InfoCardDetails;
