import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';

import UpsellItemDesktop from './upsell-item/desktop.component';
import UpsellItemMobile from './upsell-item/mobile.component';

function UpsellItem({ upsell, setSelectedUpsellToRemove, onEditExistingUpsell, disabledUpsellFlow }) {
  // we only want to sort on icon click - everything else open editor
  const _onEditExistingUpsell = useCallback(
    ({ target }) => {
      if (target.tagName === 'svg') return;
      onEditExistingUpsell(upsell);
    },
    [onEditExistingUpsell, upsell]
  );

  const _onUpsellItemRemove = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedUpsellToRemove(upsell);
    },
    [setSelectedUpsellToRemove, upsell]
  );

  return (
    <>
      <Breakpoint small down>
        <UpsellItemMobile
          upsell={upsell}
          onEditExistingUpsell={_onEditExistingUpsell}
          onUpsellItemRemove={_onUpsellItemRemove}
          disabledUpsellFlow={disabledUpsellFlow}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <UpsellItemDesktop
          upsell={upsell}
          onEditExistingUpsell={_onEditExistingUpsell}
          onUpsellItemRemove={_onUpsellItemRemove}
          disabledUpsellFlow={disabledUpsellFlow}
        />
      </Breakpoint>
    </>
  );
}

UpsellItem.propTypes = {
  upsell: PropTypes.object.isRequired,
  setSelectedUpsellToRemove: PropTypes.func.isRequired,
  onEditExistingUpsell: PropTypes.func.isRequired,
  disabledUpsellFlow: PropTypes.bool.isRequired,
};

export default UpsellItem;
