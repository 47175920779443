import gql from 'graphql-tag';

export const GET_SETTINGS_ALL_PAGES = gql`
  query getSettingsAllPages($limit: Int, $status: StatusEnum, $organizationRevvUid: String, $videoPage: Boolean) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        pages(filters: { limit: $limit, status: $status, videoPage: $videoPage }) {
          results {
            revv_uid
            internalName
          }
        }
        upsell_groups(filters: { limit: $limit }) {
          results {
            revv_uid
            name
          }
        }
          pathways(filters: { limit: $limit, pathwayType: UPSELL }) {
            results {
                revv_uid
                name
            }
        }
        campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: $limit }) {
          results {
            revv_uid
            name
            money_pledge_date
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_SUBDOMAIN = gql`
  mutation createCandidateSubdomain($organizationRevvUid: String, $subdomain: String!) {
    candidateCreateSubdomain(organizationRevvUid: $organizationRevvUid, subdomain: $subdomain) {
      subdomain {
        id
        status
      }
      message
      errors
      dammed
    }
  }
`;
export const DELETE_CUSTOM_SUBDOMAIN = gql`
  mutation destroySubdomain($organizationRevvUid: String, $subdomain: String!) {
    candidateDestroySubdomain(organizationRevvUid: $organizationRevvUid, subdomain: $subdomain) {
      message
      errors
      dammed
    }
  }
`;

export const VERIFY_CANDIDATE_SUBDOMAIN = gql`
  mutation verifyCandidateSubdomain($organizationRevvUid: String, $subdomain_id: Int!) {
    candidateVerifySubdomain(organizationRevvUid: $organizationRevvUid, subdomain_id: $subdomain_id) {
      message
      errors
      dammed
    }
  }
`;
