import React from 'react';
import uuid from 'core/utilities/uuid';

import MoneyPledgeUpsell from './money-pledge-upsell.component';
import StandardUpsell from './standard-upsell.component';
import StandardUpsellCampaigns from './standard-upsell-campaigns.component';
import SlateUpsell from './slate-upsell.component';

const generateSlateSubtitle = upsellCard => {
  if (!upsellCard.conduit_page_candidates || upsellCard.conduit_page_candidates.length === 0) return '';

  // only show first three names. if more than 3 then show 'and x others'
  const names = upsellCard.conduit_page_candidates.map(c => c.candidate?.name || c.organization?.name);

  const campaignName = upsellCard.campaign?.name ? `Campaign: ${upsellCard.campaign.name}` : '';

  if (upsellCard.conduit_page_candidates.length <= 3) {
    return (
      <div>
        <div>{`(${names.join(', ')})`}</div>
        <div>{campaignName}</div>
      </div>
    );
  }

  const [first, second, third, ...rest] = names;
  const otherWord = rest.length === 1 ? 'other' : 'others';

  return (
    <div>
      <div>{`(${first}, ${second}, ${third}, and ${rest.length} ${otherWord}.)`}</div>
      <div>{campaignName}</div>
    </div>
  );
};

export const PANEL_UPSELLS_MAP = {
  CONDUITPANEL: {
    upsellComponent: SlateUpsell,
    title: 'Donation',
    subtitle: 'Ask donors to donate to your committee and/or other committees.',
    label: 'Donation Card',
    value: 'CONDUITPANEL',
    generateSubtitle: generateSlateSubtitle,
  },
  DOUBLE: {
    upsellComponent: StandardUpsellCampaigns,
    title: 'Double Donation',
    value: 'DOUBLE',
    subtitle: 'Ask donors to double their donation.',
    generateSubtitle: upsellCard => (upsellCard.accept ? `(${upsellCard.accept})` : `-`),
  },
  RECURRING: {
    upsellComponent: StandardUpsell,
    title: 'Recurring Donation',
    value: 'RECURRING',
    subtitle: 'Ask donors to make their donation recurring.',
    generateSubtitle: upsellCard => (upsellCard.accept ? `(${upsellCard.accept})` : `-`),
  },
  MONEYPLEDGE: {
    upsellComponent: MoneyPledgeUpsell,
    title: 'Money Pledge',
    subtitle: 'Ask donors to donate via a money pledge.',
    label: 'Money Pledge',
    value: 'MONEYPLEDGE',
    generateSubtitle: upsellCard => upsellCard?.money_pledge_campaign?.name,
  },
  PASSWORD: {
    upsellComponent: StandardUpsell,
    title: 'Create Account',
    value: 'PASSWORD',
    subtitle: 'Ask donors to create an account after their donation.',
    generateSubtitle: upsellCard => (upsellCard.accept ? `(${upsellCard.accept})` : `-`),
  },
  MOBILEOPTIN: {
    upsellComponent: StandardUpsell,
    title: 'Mobile Opt-In',
    value: 'MOBILEOPTIN',
    subtitle: 'Ask donors to opt in to SMS messages.',
    generateSubtitle: upsellCard => (upsellCard.accept ? `(${upsellCard.accept})` : `-`),
  },
};

export const createDropdownOptions = () =>
  Object.values(PANEL_UPSELLS_MAP).map(option => ({
    label: option.label || option.title,
    value: option.value,
  }));

export const generateDefaultAmounts = (include_other_field, isMultiplier) => {
  const amounts = [
    {
      amount: isMultiplier ? 0.25 : 25,
      description: '',
      _isMultiplier: isMultiplier,
      id: uuid(),
      _isNew: true,
      preselected: false,
    },
    {
      amount: isMultiplier ? 0.5 : 50,
      description: '',
      _isMultiplier: isMultiplier,
      id: uuid(),
      _isNew: true,
      preselected: false,
    },
  ];

  if (!include_other_field) {
    amounts.push({
      amount: isMultiplier ? 1 : 100,
      description: '',
      _isMultiplier: isMultiplier,
      id: uuid(),
      _isNew: true,
      preselected: false,
    });
  }

  return amounts;
};

export const generateNewPanelUpsellCard = ({ editedUpsell, upsellType, maxDonation }) => {
  let newUpsell = {
    type: upsellType,
    _isDisabled: false,
    id: uuid(), // for drag and drop only
  };

  if (newUpsell.type === PANEL_UPSELLS_MAP.CONDUITPANEL.value) {
    newUpsell = {
      ...newUpsell,
      page_amounts: [...generateDefaultAmounts(true, false), ...generateDefaultAmounts(true, true)],
      include_other_field: true,
      campaign: null,
      logo_assigned_image: null,
      multiplier_amounts: true,
      minimum_amount: 1,
      maximum_amount: maxDonation,
      round_values: true,
      split_evenly: true,
      // when generating the panel upsell we attached _defaultCandidates array
      // so we can use that when generating any panel cards that need this array
      candidates: editedUpsell._defaultCandidates || [],
    };
  } else if (newUpsell.type === PANEL_UPSELLS_MAP.DOUBLE.value) {
    newUpsell = {
      ...newUpsell,
      accept: 'Yes, double my donation!',
      logo_assigned_image: null,
      campaign: null,
    };
  } else if (newUpsell.type === PANEL_UPSELLS_MAP.RECURRING.value) {
    newUpsell = {
      ...newUpsell,
      accept: 'Yes, make my donation recurring!',
      logo_assigned_image: null,
    };
  } else if (newUpsell.type === PANEL_UPSELLS_MAP.PASSWORD.value) {
    newUpsell = {
      ...newUpsell,
      accept: 'Create Account',
      logo_assigned_image: null,
    };
  } else if (newUpsell.type === PANEL_UPSELLS_MAP.MOBILEOPTIN.value) {
    newUpsell = {
      ...newUpsell,
      accept: 'Sign Up',
      logo_assigned_image: null,
    };
  } else if (newUpsell.type === PANEL_UPSELLS_MAP.MONEYPLEDGE.value) {
    newUpsell = {
      ...newUpsell,

      accept: 'Join the Money Pledge!',
      logo_assigned_image: null,
    };
  }

  return newUpsell;
};
