import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'modules/common';
import '../custom.scss';

function CustomFieldParagraphPreview({ formState }) {
  return (
    <div>
      <Card.Title className="preview-title">{formState.label}</Card.Title>
      <pre className="pre-body">{formState.default_value}</pre>
    </div>
  );
}

CustomFieldParagraphPreview.propTypes = {
  formState: PropTypes.object.isRequired,
};

export default CustomFieldParagraphPreview;
