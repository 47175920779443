import gql from 'graphql-tag';

export const GET_ORGANIZATION_SETTINGS_ALL_PAGES = gql`
  query getOrganizationSettingsAllPages(
    $limit: Int
    $status: StatusEnum
    $organizationRevvUid: String
    $videoPage: Boolean
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        pages(filters: { limit: $limit, status: $status, videoPage: $videoPage }) {
          results {
            revv_uid
            internalName
          }
        }
        upsell_groups(filters: { limit: $limit }) {
          results {
            revv_uid
            name
          }
        }
        pathways(filters: { limit: $limit, pathwayType: UPSELL }) {
            results {
                revv_uid
                name
            }
        }
        campaigns(filters: { initiativeType: [MONEYPLEDGE], limit: $limit }) {
          results {
            revv_uid
            name
            money_pledge_date
          }
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION_CUSTOM_SUBDOMAIN = gql`
  mutation createOrganizationSubdomain($organizationRevvUid: String, $subdomain: String!) {
    organizationCreateSubdomain(organizationRevvUid: $organizationRevvUid, subdomain: $subdomain) {
      subdomain {
        id
        status
      }
      message
      errors
      dammed
    }
  }
`;
export const DELETE_ORGANIZATION_CUSTOM_SUBDOMAIN = gql`
  mutation destroyOrganizationSubdomain($organizationRevvUid: String, $subdomain: String!) {
    organizationDestroySubdomain(organizationRevvUid: $organizationRevvUid, subdomain: $subdomain) {
      message
      errors
      dammed
    }
  }
`;

export const VERIFY_ORGANIZATION_SUBDOMAIN = gql`
  mutation verifyOrganizationSubdomain($organizationRevvUid: String, $subdomain_id: Int!) {
    organizationVerifySubdomain(organizationRevvUid: $organizationRevvUid, subdomain_id: $subdomain_id) {
      message
      errors
      dammed
    }
  }
`;
