import React, { useMemo } from 'react';
import { ProgressBar } from 'modules/common/loading';
import Typography from 'modules/common/typography.component';
import { formatNumberWithCommas } from 'core/utilities';

const WidgetPreviewCustomFieldsResults = ({ widget }) => {
  const isPercentage = useMemo(() => {
    return widget?.pollResultsDisplayType === 'PERCENTAGE';
  }, [widget?.pollResultsDisplayType]);
  const pollResultsStats = useMemo(() => {
    const resultType = isPercentage ? 'percentage' : 'wholeNumber';
    return widget?.pollResultsStats?.[resultType];
  }, [widget?.pollResultsStats, isPercentage]);

  if (!widget?.pollResultsCustomField?.page_custom_field_id) return null;

  return (
    <div>
      <Typography className="mb-3 font-medium poll-results-color">{widget?.pollResultsLabel}</Typography>
      <div className="results-container">
        {pollResultsStats
          ? Array.from(Object.keys(pollResultsStats)).map(key => {
              if (key === 'Other') return null;
              return (
                <div className="result-item mb-2">
                  <Typography className="mb-2 poll-results-color">{`${key} - ${
                    isPercentage ? `${pollResultsStats[key]}%` : formatNumberWithCommas(pollResultsStats[key])
                  } `}</Typography>
                  <ProgressBar
                    color={widget?.pollResultsColor}
                    min={0}
                    max={100}
                    now={widget?.pollResultsStats['percentage'][key]}
                    borderRadius="1rem"
                    height="15px"
                  />
                </div>
              );
            })
          : null}
        {widget?.pollResultsOtherFieldResults ? (
          <div className="result-item mb-2">
            <Typography className="mb-2 poll-results-color">
              Other - {pollResultsStats['Other']}
              {isPercentage ? '%' : ''}
            </Typography>
            <ProgressBar
              color={widget?.pollResultsColor}
              min={0}
              max={100}
              now={widget?.pollResultsStats['percentage']['Other']}
              borderRadius="1rem"
              height="15px"
            />
          </div>
        ) : null}
      </div>
      <Typography className="m-0">
        Note: If you have edited this custom field on a different page, your edits will not show in this preview, but
        will show on the landing page.
      </Typography>
    </div>
  );
};

export default WidgetPreviewCustomFieldsResults;
