import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'react-recompose';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withApollo } from '@apollo/client/react/hoc';

import { Form, Container, Col, Row } from 'react-bootstrap';

import { Button, FloatGroup, Input, Typography, Card, setTitle } from 'modules/common';
import { setToast } from 'core/toast';
import { hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter } from 'core/utilities';
import { CHANGE_PASSWORD_MUTATION } from 'core/middleware/queries';

import logo from 'assets/images/winred-logo.svg';

class ResetPasswordContainer extends Component {
  state = {
    newPassword: '',
    confirmNewPassword: '',
  };

  minimumPasswordLength = 8;

  changeField = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  isPasswordInvalid = () => {
    const { newPassword, confirmNewPassword } = this.state;
    let errorMessage = null;

    if (newPassword.length < 8 || confirmNewPassword < this.minimumPasswordLength) {
      errorMessage = `The password must be at least ${this.minimumPasswordLength} characters.`;
    } else if (newPassword !== confirmNewPassword) {
      errorMessage = 'The passwords must match!';
    } else if (!hasUpperCase(newPassword)) {
      errorMessage = 'The password must have at least one uppercase letter.';
    } else if (!hasLowerCase(newPassword)) {
      errorMessage = 'The password must have at least one lowercase letter.';
    } else if (!hasNumber(newPassword)) {
      errorMessage = 'The password must have at least one number.';
    } else if (!hasSpecialCharacter(newPassword)) {
      errorMessage = 'The password must have at least one special character.';
    }

    if (errorMessage) {
      this.props.setToast({
        message: errorMessage,
        isError: true,
      });
      return true;
    }

    return false;
  };

  onSubmit = event => {
    event.preventDefault();
    if (this.isPasswordInvalid()) return;

    const mutationVariables = { password: this.state.newPassword };

    this.props.client
      .mutate({
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: mutationVariables,
        operationName: 'login',
      })
      .then(this.onMutationSuccess)
      .catch(this.onMutationError);
  };

  onMutationSuccess = () => {
    const message = `` || `Password has been changed successfully!`;
    this.props.setToast({
      message,
    });

    this.props.history.push('/login');
  };

  onMutationError = () => {
    this.props.setToast({
      message: 'There was an error. Check your connection and try again.',
      isError: true,
    });
  };

  render() {
    const { newPassword, confirmNewPassword } = this.state;

    return (
      <div className="d-flex flex-column justify-content-center h-100">
        {setTitle('Forgot Password')}
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="mb-5 text-center">
                <img src={logo} alt="logo" className="height-6 mb-5" />

                <Typography variant="h1">Reset your password</Typography>
                <Typography>
                  Enter a new password below. Make sure you enter the exact same password in both fields.
                </Typography>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={6} md={5} lg={4}>
              <Card>
                <Card.Body>
                  <Form onSubmit={this.onSubmit}>
                    <FloatGroup>
                      <Input
                        type="password"
                        placeholder="New Password"
                        required
                        name="newPassword"
                        value={newPassword}
                        onChange={this.changeField}
                      />
                    </FloatGroup>
                    <FloatGroup className="mb-1">
                      <Input
                        type="password"
                        placeholder="Confirm New Password"
                        required
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={this.changeField}
                      />
                    </FloatGroup>
                    <Typography>Min. 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character.</Typography>
                    <Button
                      variant="success"
                      onClick={this.onSubmit}
                      size="lg"
                      fullWidth
                      type="submit"
                      ariaLabel="Set Password"
                      className="mb-0"
                      disabled={
                        newPassword.length < this.minimumPasswordLength ||
                        confirmNewPassword < this.minimumPasswordLength
                      }
                    >
                      Set Password
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ResetPasswordContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  setToast: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(withRouter, withApollo, connect(null, mapDispatchToProps))(ResetPasswordContainer);
