import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, Typography } from 'modules/common';
import { WinRedSelectOrSearch } from '../../common/form/inputs/winred-select-or-search.component';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from '../../../core/login';
import { SEARCH_ORGANIZATION_PAGE_CAMPAIGNS, SEARCH_PAGE_CAMPAIGNS } from '../../../core/middleware/queries';
import CampaignMerchProductDisplay from './campaign-merch-products.component';

export function Campaign({
  item,
  onChange,
  className,
  campaignOptions = [],
  subtitle,
  disabled = false,
  inUpsells = false,
}) {
  const selectedCampaignValue = useMemo(() => {
    return item?.campaign && campaignOptions?.length > 0
      ? campaignOptions?.find(camp => camp.value?.revv_uid === item.campaign?.revv_uid)
      : item.campaign
      ? { value: item.campaign, label: item.campaign.name }
      : '';
  }, [campaignOptions, item.campaign]);

  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);

  const searchCampaignsQuery = isOrganization ? SEARCH_ORGANIZATION_PAGE_CAMPAIGNS : SEARCH_PAGE_CAMPAIGNS;

  const baseVariables = {
    organizationRevvUid: profileCandidate.organization_revv_uid,
    limit: 20,
  };

  const onChangeCampaign = useCallback(
    input => {
      if (input.target.value !== selectedCampaignValue?.value) {
        const newChosenCampaign = {
          ...input?.target?.value?.value,
          label: input?.target?.value?.label || input?.target?.value?.name,
        };
        onChange({
          target: {
            name: 'campaign',
            value: newChosenCampaign,
          },
        });
      }
    },
    [selectedCampaignValue, onChange]
  );

  const campaignFormatter = data => {
    const formattedData = (
      data?.viewer?.candidate?.campaigns?.results || data?.viewer?.state_level_organization?.campaigns?.results
    )?.map(item => {
      return {
        label: item.name,
        value: item,
      };
    });
    return formattedData;
  };

  // Use memoization to only recalculate the variable value when one of its dependencies changes
  const updatedCampaignOptions = useMemo(() => {
    // Filter out campaigns that have recurring_required true unless the campaign is attached to the upsell
    if (inUpsells && ['DOUBLE', 'CONDUIT', 'CONDUITPANEL'].includes(item.type)) {
      const selectedCampaignUID = selectedCampaignValue?.value?.revv_uid;
      const filteredOptions = campaignOptions.filter(campaign => {
        const isRecurringRequired = campaign.value.recurring_donation_required;
        const isSameCampaign = campaign.value.revv_uid === selectedCampaignUID;
        return !isRecurringRequired || isSameCampaign;
      });
      return filteredOptions;
    }

    return campaignOptions;
  }, [inUpsells, item, selectedCampaignValue, campaignOptions]);

  return (
    <FormCardSpaced
      title="Campaign"
      subtitle={subtitle}
      className={className}
      footer={
        selectedCampaignValue?.value?.merch_products?.length > 0 ? (
          <div>
            <Typography variant="bodyMedium" className="mb-0">
              Attached Merchandise Campaign
            </Typography>
            <Typography>
              The following product(s) will be offered via the attached merchandise campaign. If the donor meets a
              minimum donation amount, they will qualify for the corresponding merchandise gift. If the product has
              multiple variants, the donor will be prompted to choose the variant they want to receive. Donors will
              receive only one item.
            </Typography>

            <Typography variant="bodyMedium" className="mb-0 mt-3">
              Attached via {selectedCampaignValue?.value?.name} Merchandise Campaign.
            </Typography>

            <CampaignMerchProductDisplay selectedCampaignValue={selectedCampaignValue} />
          </div>
        ) : null
      }
    >
      <WinRedSelectOrSearch
        labelSelectInput="Campaign"
        placeHolderSelectInput="Select a Campaign..."
        baseQueryVariables={baseVariables}
        onChangeSelectInput={onChangeCampaign}
        searchQuery={searchCampaignsQuery}
        name="campaign"
        dataFormatter={campaignFormatter}
        valueSearchInput={selectedCampaignValue}
        placeHolderSearchInput={'Search for a Campaign...'}
        valueSelectInput={selectedCampaignValue}
        optionsSelectInput={updatedCampaignOptions}
        valueIsObject={true}
        isClearable={true}
      />
    </FormCardSpaced>
  );
}

Campaign.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  campaignOptions: PropTypes.array,
  subtitle: PropTypes.string,
  inUpsells: PropTypes.bool,
};
