import gql from 'graphql-tag';
import { widgetFragment } from '../widgets.queries';
import {
  conduitPageCandidateFragment,
  conduitPageOrganizationFragment,
  availablePacCandidateFragment,
  campaignFragment,
} from './page-fragments';

export const upsellFragment = gql`
  fragment Upsell on Upsell {
    id
    active
    created_at
    updated_at
    revv_uid
    name
    public_name
    upsell_preview_url
    preset_panel_card

    public_upsell
    bill_pay_exists
    upsell_creator
    upsell_creator_revv_uid

    type
    paragraph
    accept
    decline
    upsell_group_count
    split_evenly
    show_other_amount_field
    enhanced
    donor_cover_fees
    logo_assigned_image {
      id
      image {
        id
        thumbnailUrl
      }
    }
    video_embed
    recurring_available
    recurring_by_default
    recurring_interval
    recurring_callout
    recurring_enable_radio_buttons
    recurring_end_at
    recurring_interval_selectable    
    page_amount {
      revv_uid
      amount
      position
      description
    }
    page_amounts {
      id
      revv_uid
      amount
      position
      description
      preselected
    }
    widgets {
      ...Widget
    }
    multiplier_amounts
    round_values
    minimum_amount
    maximum_amount

    upsell_statistics {
      view
      accepted
      percentage
      revenue_per_view
    }
    money_pledge_campaign {
      revv_uid
      name
      initiative_type
    }

    campaign {
      revv_uid
    }
    conduit_page_candidates {
      ...ConduitPageCandidate
    }
    conduit_page_organizations {
      ...ConduitPageOrganization
    }
    geographical_sort
    randomized_sort
    locked_cards

    cards {
      id
      position
      card {
        id
        created_at
        updated_at
        revv_uid
        name
        public_name
        type
        paragraph
        accept
        preset_panel_card
        decline
        geographical_sort

        multiplier_amounts
        minimum_amount
        maximum_amount
        round_values

        upsell_group_count
        split_evenly
        include_other_field
        enhanced
        active
        campaign {
          revv_uid
        }
        logo_assigned_image {
          id
          image {
            id
            thumbnailUrl
          }
        }
        video_embed
        recurring_available
        recurring_callout
        recurring_enable_radio_buttons
        recurring_by_default
        recurring_interval
        recurring_end_at
        recurring_interval_selectable

        page_amounts {
          id
          revv_uid
          amount
          position
        }
        money_pledge_campaign {
          revv_uid
          name
        }
        conduit_page_candidates {
          ...ConduitPageCandidate
        }
        conduit_page_organizations {
          ...ConduitPageOrganization
        }
      }
    }
  }
  ${widgetFragment}
  ${conduitPageCandidateFragment}
  ${conduitPageOrganizationFragment}
`;

export const upsellGroupFragment = gql`
  fragment UpsellGroup on UpsellGroup {
    created_at
    updated_at
    revv_uid
    name
    campaign {
      revv_uid
    }
    page_count
    upsell_count
    bill_pay_exists
    public_upsell_group
    upsell_creator
    upsell_creator_revv_uid
    upsell_group_statistics {
      view
      accepted
      percentage
    }
    upsell_pages {
      id
      position
      upsell_page {
        ...Upsell
      }
    }
  }
  ${upsellFragment}
`;

// dropdown to add an upsell to an upsell group
export const GET_NEW_UPSELLS_QUERY = gql`
  query getUpsells($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: 9999 }, include_public: true) {
          results {
            revv_uid
            name
            type
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
          }
        }
      }
    }
  }
`;

// when selecting a new upsell to create get data needed to generate it
export const GET_NEW_UPSELL_QUERY = gql`
  query getNewUpsell($organizationRevvUid: String, $revvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        enable_donor_cover_fees
        available_pac_candidates(filters: { revvUid: $revvUid }) {
          results {
            ...AvailablePacCandidate
          }
        }
      }
    }
  }
  ${availablePacCandidateFragment}
`;

// upsell group details
export const GET_UPSELL_GROUP_QUERY = gql`
  query getUpsellGroup($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells {
          total_count
        }
        upsell_groups(filters: { revvUid: $revv_uid }) {
          results {
            ...UpsellGroup
          }
        }
      }
    }
  }
  ${upsellGroupFragment}
`;

// when adding an existing upsell group to a page (need to get rest of details)
export const GET_ADD_UPSELL_GROUP_QUERY = gql`
  query getAddUpsellGroup($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { revvUid: $revv_uid }, include_public: true) {
          results {
            ...UpsellGroup
          }
        }
      }
    }
  }
  ${upsellGroupFragment}
  ${upsellFragment}
`;

// get campaigns for slate/connected campaign upsell cards
export const GET_UPSELL_CAMPAIGNS = gql`
  query getUpsellCampaigns($limit: Int!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD, MERCHANDISE], limit: $limit }) {
          results {
            ...PageCampaign
          }
        }
      }
    }
  }
  ${campaignFragment}
`;

// get an upsell for upsell details view
export const GET_UPSELL_QUERY = gql`
  query getUpsell($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        available_pac_candidates(filters: { revvUid: $revv_uid }) {
          results {
            ...AvailablePacCandidate
          }
        }
        upsells(filters: { revvUid: $revv_uid }, include_public: true) {
          results {
            ...Upsell
          }
        }
      }
    }
  }
  ${upsellFragment}
  ${availablePacCandidateFragment}
`;

// get list of all upsells for list view
export const GET_UPSELLS_LIST_QUERY = gql`
  query getUpsellsList(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
    $search: String
    $type: UpsellTypeEnum
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
            type: $type
          }
        ) {
          current_page
          page_count
          total_count
          results {
            revv_uid
            public_upsell
            bill_pay_exists
            name
            type
            created_at
            updated_at
            upsell_group_count
            upsell_pathway_count
            upsell_preview_url
            upsell_statistics {
              view
              accepted
              percentage
              revenue_per_view
            }
          }
        }
      }
    }
  }
`;

// get list of all upsell groups for list view
export const GET_UPSELL_GROUPS_LIST_QUERY = gql`
  query getUpsellGroupsList(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsell_groups(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            search: $search
          }
        ) {
          current_page
          page_count
          total_count
          results {
            revv_uid
            name
            created_at
            updated_at
            page_count
            upsell_count
            public_upsell_group
            bill_pay_exists
            upsell_group_statistics {
              view
              accepted
              percentage
            }
            upsell_pages {
              id
              position
              upsell_page {
                type
                revv_uid
              }
            }
          }
        }
      }
    }
  }
`;

// get list of all upsells and upsell groups for adding upsell groups/upsells
export const GET_UPSELLS_AND_UPSELL_GROUPS_QUERY = gql`
  query getUpsellsAndUpsellGroup($limit: Int!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            type
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
            money_pledge_campaign {
              money_pledge_date
            }
          }
        }
        upsell_groups(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            public_upsell_group
            upsell_creator
            upsell_creator_revv_uid
          }
        }
      }
    }
  }
`;

export const GET_UPSELLS_QUERY = gql`
  query getExistingUpsells($limit: Int!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit }, include_public: true) {
          results {
            name
            revv_uid
            type
            public_upsell
            upsell_creator
            upsell_creator_revv_uid
            money_pledge_campaign {
              money_pledge_date
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_UPSELLS_QUERY = gql`
  query searchExistingUpsells($limit: Int!, $organizationRevvUid: String, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            name
            revv_uid
          }
        }
      }
    }
  }
`;

export const SEARCH_UPSELLS_ID_QUERY = gql`
  query searchExistingUpsellIds($limit: Int!, $organizationRevvUid: String, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsells(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            id
            revv_uid
            name
            type
          }
        }
      }
    }
  }
`;

export const SEARCH_UPSELLS_GROUP_QUERY = gql`
  query searchExistingUpsellGroups($limit: Int!, $organizationRevvUid: String, $search: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { limit: $limit, search: $search }, include_public: true) {
          results {
            name
            revv_uid
          }
        }
      }
    }
  }
`;

export const CREATE_UPSELL_MUTATION = gql`
  mutation createUpsellMutation($upsell: CreateUpsellInput!, $organizationRevvUid: String) {
    candidateCreateUpsell(upsell: $upsell, organizationRevvUid: $organizationRevvUid) {
      errors
      upsell {
        ...Upsell
      }
    }
  }
  ${upsellFragment}
`;

export const CREATE_UPSELL_GROUP_MUTATION = gql`
  mutation createUpsellGroupMutation($upsellGroup: CreateUpsellGroupInput!, $organizationRevvUid: String) {
    candidateCreateUpsellGroup(upsellGroup: $upsellGroup, organizationRevvUid: $organizationRevvUid) {
      errors
      upsellGroup {
        ...UpsellGroup
      }
    }
  }
  ${upsellGroupFragment}
`;

export const DUPLICATE_CANDIDATE_UPSELL_MUTATION = gql`
  mutation CandidateDuplicateUpsell($upsell: DuplicateUpsellInput!, $organizationRevvUid: String) {
    candidateDuplicateUpsell(upsell: $upsell, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedUpsell {
        revv_uid
        name
        type
      }
    }
  }
`;

export const DUPLICATE_CANDIDATE_UPSELL_FLOW_MUTATION = gql`
  mutation CandidateDuplicateUpsellFlow($upsellGroup: DuplicateUpsellGroupInput!, $organizationRevvUid: String) {
    candidateDuplicateUpsellGroup(upsellGroup: $upsellGroup, organizationRevvUid: $organizationRevvUid) {
      errors
      duplicatedUpsellGroup {
        revv_uid
        name
      }
    }
  }
`;

export const UPDATE_UPSELL_MUTATION = gql`
  mutation updateUpsellMutation($upsell: UpdateUpsellInput!, $organizationRevvUid: String) {
    candidateUpdateUpsell(upsell: $upsell, organizationRevvUid: $organizationRevvUid) {
      errors
      upsell {
        ...Upsell
      }
    }
  }
  ${upsellFragment}
`;

export const UPDATE_UPSELL_GROUP_MUTATION = gql`
  mutation updateUpsellGroupMutation($upsellGroup: UpdateUpsellGroupInput!, $organizationRevvUid: String) {
    candidateUpdateUpsellGroup(upsellGroup: $upsellGroup, organizationRevvUid: $organizationRevvUid) {
      errors
      upsellGroup {
        ...UpsellGroup
      }
    }
  }
  ${upsellGroupFragment}
`;

export const GET_UPSELL_GROUPS_QUERY = gql`
  query getUpsellGroups($limit: Int!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        upsell_groups(filters: { limit: $limit }) {
          results {
            ...UpsellGroup
          }
        }
      }
    }
  }
  ${upsellGroupFragment}
`;

export const UPSELL_FLOW_ARCHIVE_MUTATION = gql`
  mutation candidateArchiveUpsellFlow($upsellFlow: ArchiveUpsellFlowInput!, $organizationRevvUid: String) {
    candidateArchiveUpsellFlow(upsellFlow: $upsellFlow, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
    }
  }
`;

export const UPSELL_ARCHIVE_MUTATION = gql`
  mutation ArchiveUpsellMutation($upsell: ArchiveUpsellInput!, $organizationRevvUid: String) {
    candidateArchiveUpsell(upsell: $upsell, organizationRevvUid: $organizationRevvUid) {
      errors
      message
      dammed
    }
  }
`;

export const GET_PRESET_UPSELL_CARDS = gql`
  query getPresetPanelCards($limit: Int, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        preset_panel_cards(filters: { limit: $limit }) {
          results {
            active
            public_name
            revv_uid
          }
        }
      }
    }
  }
`;

export const GET_PRESET_UPSELL_CARD = gql`
  query getPresetPanelCard($revv_uid: String!, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        preset_panel_cards(filters: { revvUid: $revv_uid }) {
          results {
            ...Upsell
          }
        }
      }
    }
  }
  ${upsellFragment}
`;

export const GET_UPSELL_CAMPAIGN_OPTIONS = gql`
  query getUpsellCampaignOptions($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        campaigns(filters: { initiativeType: [STANDARD], limit: 9999 }) {
          results {
            revv_uid
            name
          }
        }
      }
    }
  }
`;
