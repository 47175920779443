import { createSelector } from 'reselect';

export const pagesSelector = state => state.pages;

export const pagesFilterSelector = createSelector(pagesSelector, pages => pages.pagesFilter);

export const upsellTabSelector = createSelector(pagesSelector, pages => pages.activeUpsellTab);

export const upsellsFilterSelector = createSelector(pagesSelector, pages => pages.upsellFilter);

export const teamPagesFilterSelector = createSelector(pagesSelector, pages => pages.teamPagesFilter);

export const connectedPagesFilterSelector = createSelector(pagesSelector, pages => pages.connectedPagesFilter);

export const campaignsFilterSelector = createSelector(pagesSelector, pages => pages.campaignsFilter);

export const pathwaysTabSelector = createSelector(pagesSelector, pages => pages.activePathwaysTab);

export const pathwaysFilterSelector = createSelector(pagesSelector, pages => pages.pathwaysFilter);
