import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import WinRedSelect from './winred-select.component';

function WinRedFormSelect({ title, ...rest }) {
  return (
    <Form.Group className="mb-3">
      <Form.Label className="mb-1">{title}</Form.Label>
      <WinRedSelect {...rest} />
    </Form.Group>
  );
}

WinRedFormSelect.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WinRedFormSelect;
