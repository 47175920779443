import React from 'react';
import PropTypes from 'prop-types';

import { FloatGroup, Input, Card, ImageCard, WinRedTextEditor } from 'modules/common';

function WidgetPopup({ widget, updateWidget, disabled = false, headerPlaceHolder, buttonPlaceholder, imageSubtitle }) {
  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="popupHeaderCopy"
          placeholder={headerPlaceHolder || 'Popup Header Copy'}
          value={widget.popupHeaderCopy || ''}
          maxLength="25"
          disabled={disabled}
          onChange={updateWidget}
        />
      </FloatGroup>

      <ImageCard
        assignedImage={widget.widgetImage}
        imageType="WIDGET_IMAGE"
        name="widgetImage"
        disabled={disabled}
        onChange={updateWidget}
        className="border-0"
        classNameBody="px-0 border-0"
        title="Popup Image"
        subtitle={imageSubtitle}
        removeTitle="Remove Popup Image"
        removeBody="Are you sure you want to remove this popup image from your popup widget?"
      />

      <div className="mt-3">
        <Card.Title className="mb-3">Popup Body Copy</Card.Title>
        <WinRedTextEditor
          initialValue={widget.popupBodyCopy}
          value={widget.popupBodyCopy}
          disabled={disabled}
          name="popupBodyCopy"
          onChange={updateWidget}
        />
      </div>

      <Card.Title className="mb-3">Popup Button Copy</Card.Title>
      <FloatGroup>
        <Input
          type="text"
          name="popupButtonCopy"
          placeholder={buttonPlaceholder || 'Popup Button Copy'}
          value={widget.popupButtonCopy || ''}
          maxLength="25"
          disabled={disabled}
          onChange={updateWidget}
        />
      </FloatGroup>

      <Card.Title className="mb-3">Cookie Expiration</Card.Title>

      <FloatGroup>
        <Input
          type="text"
          name="cookieExpiration"
          placeholder="Cookie Expiration"
          value={widget.cookieExpiration}
          max={30}
          numbersOnly
          disabled={disabled}
          onChange={updateWidget}
          description={
            'Please enter a numerical value to represent the number of days to set the cookie for. A cookie is used to track if the popup has already been shown to a specific visitor. If the popup has been shown, it will not show again until the cookie expires. The maximum value for this field is "30" and a value of "0" will always show the popup.'
          }
        />
      </FloatGroup>
    </>
  );
}

WidgetPopup.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetPopup;
