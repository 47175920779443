/* eslint-disable no-case-declarations */
import produce from 'immer';

import {
  LOGIN_STATUSES,
  LOGOUT_SUCCESS,
  SET_INITIAL_ROUTE,
  SET_PROFILE,
  SWITCH_PROFILE_CANDIDATE,
  SET_LOGIN_STATUS,
  SET_PROFILE_CANDIDATE,
  SET_SETTINGS,
  SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE,
  SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS,
  SET_ACTIVATE_STOREFRONT,
} from './types';

import { initSettingsState, getUserCandidate } from './login-reducer.tools';

const initialState = {
  initialRoute: null,
  loginStatus: LOGIN_STATUSES.NOT_LOGGED_IN,
  token: null,
  doesTwoFactorNeedInstalled: null,
  profile: {
    candidate: {},
    candidates: [],
    state_level_organizations: [],
    vendors: [],
    default_email_body_text: '',
  },
  settings: {
    ...initSettingsState,
  },
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_ROUTE:
      draft.initialRoute = action.response;
      break;

    case SET_SETTINGS:
      const { settings } = action;
      draft.settings = { ...settings };
      const candidateRevvUid = draft.profile.candidate.revv_uid;

      try {
        window.localStorage.setItem(candidateRevvUid, JSON.stringify(action.settings));
      } catch {
        console.error(`could not save new settings`);
      }
      break;

    // set inital profile when user logs in
    case SET_PROFILE:
      const { profile } = action.data;
      draft.profile = { ...profile };
      break;

    // gets rest of candidate data so we can set the profile candidate
    case SET_PROFILE_CANDIDATE:
      draft.profile = getUserCandidate({
        apiProfile: action.profile,
        reduxProfile: draft.profile,
      });
      break;

    case SWITCH_PROFILE_CANDIDATE:
      const { profile: switchedProfile, selectedSwitchCandidate } = action.data;

      draft.profile = getUserCandidate({
        apiProfile: switchedProfile,
        reduxProfile: draft.profile,
        selectedCandidate: selectedSwitchCandidate,
      });

      break;

    case SET_LOGIN_STATUS:
      draft.loginStatus = action.response.status;
      draft.token = action.response.token;

      // need so we know if we are setting up 2fa or verifying on verify page
      draft.doesTwoFactorNeedInstalled = action.response.doesTwoFactorNeedInstalled;

      if (action.response.organizationRevvUid) draft.organizationRevvUid = action.response.organizationRevvUid;

      // save response to local storage
      try {
        if (localStorage) localStorage.setItem(SET_LOGIN_STATUS, JSON.stringify(action.response));
      } catch (e) {
        // Don't do anything since this is handled in the login container.
      }
      break;

    case SYNC_PROFILE_CANDIDATE_CUSTOM_FIELDS:
      // find new custom fields created and add them to the profile candidate's global custom fields
      const currentGlobalCustomFields = draft.profile.candidate.global_custom_fields;

      const newCustomFields =
        action.data?.filter(cf => {
          return !currentGlobalCustomFields.find(
            globalCf => globalCf.global_custom_field_id === cf.global_custom_field_id
          );
        }) ?? [];

      draft.profile.candidate.global_custom_fields = [
        ...draft.profile.candidate.global_custom_fields,
        ...newCustomFields,
      ];
      break;

    case SET_ACTIVATE_STOREFRONT:
      draft.profile.candidate.has_active_storefront = true;
      break;

    case LOGOUT_SUCCESS:
      draft.loginStatus = LOGIN_STATUSES.NOT_LOGGED_IN;
      draft.token = null;
      localStorage.setItem(SET_LOGIN_STATUS, null);
      break;

    case SET_PROFILE_CANDIDATE_STOREFRONT_ACTIVE:
      draft.profile.candidate.has_active_storefront = true;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
