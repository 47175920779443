import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

import { GET_ALL_PUBLIC_CANDIDATES_QUERY } from 'core/middleware/queries';
import { setToast } from 'core/toast';
import { ConnectedCommitteeList } from 'modules/pages/common/addCommittee/committee-list.component';
import { SelectedCommittee } from 'modules/pages/common/addCommittee/selected-committee.component';

import 'modules/pages/pages.scss';
import 'modules/pages/common/addCommittee/committees.scss';

const TEAM_PAGE_DESCRIPTION =
  'WinRed allows you to raise money for multiple candidates and committees at once. To add a committee to your page, simply search for it below and select it from the options. If a committee is on WinRed, it will appear as an option when you start typing its name. Any committees added to your page will split the proceeds of all donations by default, although donors can always edit the allocation when they donate.';
const addCommitteeButtonDescription = `Add committees to your page.`;

function CommitteesTeamPage({ page, updatePageField, data }) {
  const selectedCandidates = page.conduitPageCandidates;

  return (
    <div className="team-pages-committee">
      <ConnectedCommitteeList
        eventName="conduitPageCandidates"
        onChange={updatePageField}
        selectedCandidates={selectedCandidates}
        CommitteeComponent={SelectedCommittee}
        isTeamPages
        addCommitteeDescription={TEAM_PAGE_DESCRIPTION}
        addCommitteeButtonDescription={addCommitteeButtonDescription}
        GET_COMMITTEES_QUERY={GET_ALL_PUBLIC_CANDIDATES_QUERY}
      />
    </div>
  );
}

CommitteesTeamPage.propTypes = {
  page: PropTypes.object.isRequired,
  updatePageField: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export const ConnectedCommitteesTeamPage = compose(connect(null, mapDispatchToProps))(CommitteesTeamPage);
