import { WinRedSelectGrouped } from '../../../../../common/form/inputs/winred-select-grouped.component';
import { TableInput, TableInputContainer, WinRedSelect } from '../../../../../common';
import { StepValueInput } from '../StepValueInput.component';
import React from 'react';
import PropTypes from 'prop-types';

export function StepRequirementsRowMobile({
  condition,
  _onChangeKey,
  conditionOptions,
  selectedConditionKey,
  _onChangePredicate,
  selectedConditionPredicate,
  predicateOptions,
  _onChangeValue,
  customValueOptions,
  campaignsList,
  fieldValidators,
  _onRemove,
  disabledStep,
}) {
  return (
    <>
      <TableInputContainer onRemove={_onRemove}>
        <TableInput>
          <WinRedSelectGrouped
            maxMenuHeight={300}
            onChange={_onChangeKey}
            options={conditionOptions}
            value={selectedConditionKey}
            name="key"
            placeholder="Select Field"
            validators={fieldValidators}
            disabled={disabledStep}
          />
        </TableInput>
        {selectedConditionKey?.value && (
          <TableInput>
            <WinRedSelect
              key={condition?.key + condition?.predicate + "_key"}
              disabled={disabledStep}
              onChange={_onChangePredicate}
              name="predicate"
              value={selectedConditionPredicate}
              options={predicateOptions[selectedConditionKey.value]}
              placeholder="Select Predicate..."
              validators={[
                {
                  message: 'Predicate is required.',
                  validator: 'required',
                },
              ]}
            />
          </TableInput>
        )}
        {selectedConditionPredicate && (
          <TableInput>
            <StepValueInput
              disabledStep={disabledStep}
              onChange={_onChangeValue}
              name="value"
              requirementField={selectedConditionKey}
              predicate={condition?.predicate}
              condition={condition}
              customValueOptions={customValueOptions}
              campaignsList={campaignsList}
            />
          </TableInput>
        )}
      </TableInputContainer>
    </>
  );
}

StepRequirementsRowMobile.propTypes = {
  condition: PropTypes.object.isRequired,
  _onChangeKey: PropTypes.func.isRequired,
  conditionOptions: PropTypes.array,
  selectedConditionKey: PropTypes.object,
  _onChangePredicate: PropTypes.func.isRequired,
  selectedConditionPredicate: PropTypes.object,
  predicateOptions: PropTypes.object,
  _onChangeValue: PropTypes.func.isRequired,
  customValueOptions: PropTypes.object,
  campaignsList: PropTypes.array,
  fieldValidators: PropTypes.array,
  _onRemove: PropTypes.func.isRequired,
  disabledStep: PropTypes.bool,
};
