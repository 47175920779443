import gql from 'graphql-tag';

export const GET_ORGANIZATION_INVOICES_QUERY = gql`
  query getOrganizationInvoices(
    $amount: Float
    $amountPredicate: RansackPredicate
    $limit: Int
    $page: Int
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        transactions(
          filters: {
            limit: $limit
            page: $page
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: [PAYOUT, INVOICE]
          }
        ) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            initiated_at
            estimated_arrival_at
            transaction_type
            amount_cents
            description
            status
            reportUrl
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_INVOICE_QUERY = gql`
  query getOrganizationInvoice(
    $organizationRevvUid: String
    $revvUid: String
    $paymentPage: Int
    $paymentLimit: Int
    $refundPage: Int
    $refundLimit: Int
    $disputePage: Int
    $disputeLimit: Int
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        enable_split_contributions_and_fees

        invoice(revvUid: $revvUid) {
          revv_uid
          created_at
          status
          total_cents
          payout_revv_uid
          estimated_arrival_at
          initiated_at
          statement_descriptor
          reportUrl

          payments(filters: { page: $paymentPage, limit: $paymentLimit }) {
            current_page
            page_count
            total_count
            results {
              revv_uid
              charge_revv_uid
              created_at
              amount_cents
              transaction_type
              name
              fee_cents
            }
          }
          refunds(filters: { page: $refundPage, limit: $refundLimit }) {
            current_page
            page_count
            total_count
            results {
              revv_uid
              charge_revv_uid
              created_at
              amount_cents
              transaction_type
              name
              fee_cents
            }
          }
          disputes(filters: { page: $disputePage, limit: $disputeLimit }) {
            current_page
            page_count
            total_count
            results {
              revv_uid
              charge_revv_uid
              created_at
              amount_cents
              transaction_type
              name
              fee_cents
            }
          }
        }
      }
    }
  }
`;
