import { formatAmount } from 'core/utilities';
import { WinRedImage } from 'modules/common';
import React from 'react';
import { fulfillmentHouseMap } from '../pages';

const CampaignMerchProductDisplay = ({ selectedCampaignValue }) => {
  return selectedCampaignValue?.value?.merch_products?.map((product, index) => {
    return (
      <div key={product.merch_product.name} className={index === 0 ? '' : 'mt-3'}>
        <div className="border-catskill bg--catskill-white border-radius-4 py-3 d-flex flex-column flex-lg-row justify-content-lg-between">
          <div className="d-block d-lg-flex">
            <div className="campaign-product-image mr-3 ml-4 mb-2 mb-lg-0">
              <WinRedImage className="img-fluid" src={product.merch_product?.product_images[0]?.image_url} />
            </div>

            <div className="pr-4 pl-4 pl-lg-0 mb-3 mb-lg-0">
              <div className="c--lynx text-uppercase font-weight-bold tracking-widest">Product</div>
              <div className="font-weight-bold">{product.merch_product.name}</div>
            </div>
          </div>

          <div className="d-flex flex-column flex-lg-row">
            <div className="px-4 mb-3 mb-lg-0">
              <div className="c--lynx text-uppercase font-weight-bold tracking-widest">Variants</div>
              <div className="font-weight-bold">{product.merch_product.variants_count || '-'}</div>
            </div>
            <div className="px-4 mb-3 mb-lg-0">
              <div className="c--lynx text-uppercase font-weight-bold tracking-widest">Vendor</div>
              <div className="font-weight-bold">
                {fulfillmentHouseMap[product.merch_product.fulfillment_house] ||
                  product.merch_product.fulfillment_house}
              </div>
            </div>
            <div className="px-4">
              <div className="c--lynx text-uppercase font-weight-bold tracking-widest">
                <span className="mr-1">Min. Donation</span>
              </div>
              <div className="font-weight-bold">
                {product.merchandise_minimum_price_cents
                  ? formatAmount(product.merchandise_minimum_price_cents, true)
                  : '-'}
              </div>
            </div>
            <div className="px-4">
              <div className="c--lynx text-uppercase font-weight-bold tracking-widest">
                <span className="mr-1">Recurring Required</span>
              </div>
              <div className="font-weight-bold">
                {selectedCampaignValue?.value.recurring_donation_required ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default CampaignMerchProductDisplay;
