import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'react-recompose';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';

import { Button, Card, FloatGroup, Input, Typography, setTitle } from 'modules/common';
import { setToast } from 'core/toast';
import { FORGOT_PASSWORD_MUTATION } from 'core/middleware/queries';
import logo from 'assets/images/winred-logo.svg';

class ForgotPasswordContainer extends Component {
  state = {
    email: '',
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onCancel = event => {
    event.preventDefault();
    this.props.history.push('/login');
  };

  onSubmit = event => {
    event.preventDefault();
    const mutationVariables = { email: this.state.email };

    this.props.client
      .mutate({
        mutation: FORGOT_PASSWORD_MUTATION,
        variables: mutationVariables,
        operationName: 'login',
      })
      .then(this.onMutationSuccess)
      .catch(this.onMutationError);
  };

  onMutationSuccess = response => {
    const message =
      response && response.forgotPassword
        ? response.forgotPassword.message
        : `We've sent password reset instructions to ${this.state.email}`;
    this.props.setToast({
      message,
    });

    this.props.history.replace('/');
  };

  onMutationError = () => {
    this.props.setToast({
      message: 'There was an error. Check your connection and try again.',
      isError: true,
    });
  };

  render() {
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        {setTitle('Forgot Password')}
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="text-center mb-5">
                <img src={logo} alt="logo" className="height-6 mb-5" />
                <Typography variant="h1">Forgot your password?</Typography>
                <Typography variant="body">
                  {/*eslint-disable-next-line react/no-unescaped-entities*/}
                  Enter your email and we'll send you a link to reset your password.
                </Typography>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={6} md={5} lg={4}>
              <Card>
                <Card.Body>
                  <Form onSubmit={this.onSubmit}>
                    <FloatGroup>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </FloatGroup>

                    <Button
                      variant="success"
                      size="lg"
                      fullWidth
                      type="submit"
                      disabled={this.state.loading}
                      ariaLabel="reset link"
                    >
                      {this.state.loading ? 'Sending Reset Link...' : 'Send Reset Link'}
                    </Button>

                    <div className="text-center mt-2">
                      <a href="/" onClick={this.onCancel}>
                        Cancel
                      </a>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ForgotPasswordContainer.propTypes = {
  setToast: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default compose(withRouter, withApollo, connect(null, mapDispatchToProps))(ForgotPasswordContainer);
