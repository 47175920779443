import React from 'react';
import PropTypes from 'prop-types';
import { WinRedLazySelect, WinRedSelect } from './index';

export function WinRedSelectOrSearch({
  name,
  labelSelectInput,
  placeHolderSelectInput,
  onChangeSelectInput,
  valueSelectInput,
  optionsSelectInput,
  description,
  validations,
  valueSearchInput,
  placeHolderSearchInput,
  loadingSearch,
  searchQuery,
  baseQueryVariables,
  dataFormatter,
  valueIsObject,
  isClearable,
  searchKey,
  filterFunction,
}) {
  /*
   * If optionsSelectInput array has options, then standard dropdown select will render,
   * otherwise, a search-select will render.
   * */
  return (
    <div>
      {optionsSelectInput?.length > 0 ? (
        <WinRedSelect
          name={name}
          floatLabel={labelSelectInput}
          placeholder={placeHolderSelectInput}
          onChange={onChangeSelectInput}
          options={optionsSelectInput || []}
          description={description}
          validators={validations?.validators}
          errorMessages={validations?.errorMessages}
          isLoading={loadingSearch}
          valueIsObject={valueIsObject}
          isClearable={isClearable}
          value={valueSelectInput}
        />
      ) : (
        <WinRedLazySelect
          name={name}
          baseVariables={baseQueryVariables}
          onChange={onChangeSelectInput}
          query={searchQuery}
          placeholder={placeHolderSearchInput}
          formatLoadedOptions={dataFormatter}
          initialValue={valueSearchInput}
          valueIsObject={valueIsObject}
          description={description}
          validators={validations?.validators}
          errorMessages={validations?.errorMessages}
          isClearable={isClearable}
          searchKey={searchKey}
          filterFunction={filterFunction}
        />
      )}
    </div>
  );
}

WinRedSelectOrSearch.propTypes = {
  name: PropTypes.string.isRequired,
  labelSelectInput: PropTypes.string.isRequired,
  placeHolderSelectInput: PropTypes.string.isRequired,
  onChangeSelectInput: PropTypes.func.isRequired,
  valueSelectInput: PropTypes.any,
  optionsSelectInput: PropTypes.array,
  description: PropTypes.string,
  validations: PropTypes.object,
  valueSearchInput: PropTypes.any,
  placeHolderSearchInput: PropTypes.string,
  loadingSearch: PropTypes.bool,
  searchQuery: PropTypes.object.isRequired,
  baseQueryVariables: PropTypes.object.isRequired,
  dataFormatter: PropTypes.func.isRequired,
  valueIsObject: PropTypes.bool,
  isClearable: PropTypes.bool,
  searchKey: PropTypes.string,
  filterFunction: PropTypes.func,
};
