import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({ children, className, innerRef }) => (
  <tbody className={className} ref={innerRef}>
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  innerRef: PropTypes.any,
};

export default TableBody;
