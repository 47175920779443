import React from 'react';
import PropTypes from 'prop-types';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {
  NavBarMenuPages,
  NavBarMenuPeople,
  NavBarMenuRevenue,
  NavBarMenuAccounting,
  NavBarMenuUtilities,
  NavBarMenuDashBoard,
  NavBarMenuStoreFront,
  NavBarMenuBillpays,
} from './';

import {
  PAGES_CHILD_PATHS,
  PEOPLE_CHILD_PATHS,
  REVENUE_CHILD_PATHS,
  ACCOUNTING_CHILD_PATHS,
  UTILITIES_CHILD_PATHS,
  STOREFRONT_CHILD_PATHS,
  BILLPAY_CHILD_PATHS,
} from './menu.tool';

import NavBarMenu from './menu.component';
import '../nav-bar.scss';

function NavBarMenus({
  isOrganization,
  isAgency,
  profileCandidate,
  isReadOnly,
  isPagesOnly,
  isAdmin,
  isMerchOnly,
  isMyPagesOnly,
  approved,
  activePath,
  subNavStates,
  toggleSubNav,
}) {
  const {
    showPagesSubNav,
    showRevenueSubNav,
    showPeopleSubNav,
    showUtilitiesSubNav,
    showAccountingSubNav,
    showStoreFrontSubNav,
    showBillpaysSubNav,
  } = subNavStates;

  const hasActiveChildPages = !!PAGES_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildPeople = !!PEOPLE_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildRevenue = !!REVENUE_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildAccounting = !!ACCOUNTING_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildUtilities = !!UTILITIES_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildStoreFront = !!STOREFRONT_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));
  const hasActiveChildBillpays = !!BILLPAY_CHILD_PATHS.find(eventKey => activePath.includes(eventKey));

  return (
    <Navbar.Collapse id="main-navbar" className="order-sm-12 order-2">
      <Nav activeKey={activePath}>
        <NavBarMenuDashBoard
          activePath={activePath}
          isAgency={isAgency}
          removeAccess={[isReadOnly, isMyPagesOnly, isMerchOnly]}
        />

        <NavBarMenuBillpays
          removeAccess={[!isAgency, isMyPagesOnly, isMerchOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildBillpays={hasActiveChildBillpays}
          showMobileSubNav={showBillpaysSubNav}
          isOrganization={isOrganization}
          organizationRevvUid={profileCandidate.organization_revv_uid}
        />

        <NavBarMenuPages
          removeAccess={[isAgency, isReadOnly, isMerchOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildPages={hasActiveChildPages}
          showMobileSubNav={showPagesSubNav}
          isOrganization={isOrganization}
          organizationRevvUid={profileCandidate.organization_revv_uid}
          isMyPagesOnly={isMyPagesOnly}
        />

        <NavBarMenuPeople
          isAgency={isAgency}
          removeAccess={[isAgency, isPagesOnly, isMerchOnly, isMyPagesOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildPeople={hasActiveChildPeople}
          activePath={activePath}
          showMobileSubNav={showPeopleSubNav}
          organizationRevvUid={profileCandidate.organization_revv_uid}
        />

        <NavBarMenuRevenue
          isAgency={isAgency}
          removeAccess={[isReadOnly, isPagesOnly, isMerchOnly, isMyPagesOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildRevenue={hasActiveChildRevenue}
          activePath={activePath}
          showMobileSubNav={showRevenueSubNav}
          organizationRevvUid={profileCandidate.organization_revv_uid}
        />

        <NavBarMenuAccounting
          isAgency={isAgency}
          removeAccess={[isReadOnly, isPagesOnly, isMerchOnly, isMyPagesOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildAccounting={hasActiveChildAccounting}
          activePath={activePath}
          isOrganization={isOrganization}
          showMobileSubNav={showAccountingSubNav}
          organizationRevvUid={profileCandidate.organization_revv_uid}
        />

        <NavBarMenuUtilities
          isAgency={isAgency}
          removeAccess={[isReadOnly, isPagesOnly, isMerchOnly, isMyPagesOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildUtilities={hasActiveChildUtilities}
          activePath={activePath}
          showMobileSubNav={showUtilitiesSubNav}
          organizationRevvUid={profileCandidate.organization_revv_uid}
          isOrganization={isOrganization}
          profileCandidate={profileCandidate}
        />

        <NavBarMenuStoreFront
          removeAccess={[isAgency, isReadOnly, isPagesOnly, isMyPagesOnly]}
          toggleSubNav={toggleSubNav}
          hasActiveChildStoreFront={hasActiveChildStoreFront}
          activePath={activePath}
          showMobileSubNav={showStoreFrontSubNav}
          organizationRevvUid={profileCandidate.organization_revv_uid}
          approved={approved}
        />

        {isAdmin ? (
          <NavBarMenu
            title="Settings"
            path={isAgency ? 'vendors/settings' : 'settings'}
            isActive={activePath === `/${profileCandidate.organization_revv_uid}/settings`}
          />
        ) : null}

        {/* {isAgency ? (
          <NavBarMenu
            title="Settings"
            path="vendors/settings"
            isActive={activePath === `/${profileCandidate.organization_revv_uid}/vendors/settings`}
          />
        ) : null} */}
      </Nav>
    </Navbar.Collapse>
  );
}

NavBarMenus.propTypes = {
  profileCandidate: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isPagesOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
  activePath: PropTypes.string.isRequired,
  toggleSubNav: PropTypes.func.isRequired,
  subNavStates: PropTypes.object.isRequired,
};

export default NavBarMenus;
