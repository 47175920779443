import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { AddField, DragDrop, ExternalLink, FormCard, Icon, WinRedChip } from 'modules/common';
import uuid from 'core/utilities/uuid';
import { ReportFieldRow } from './report-field-row.component';
import { isAgencySelector } from 'core/login';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  zIndex: 100000,
});

function ReportFormat({ report, columnOptions, isCustomReport, onChange }) {
  const isAgency = useSelector(isAgencySelector);

  const addColumn = useCallback(
    () =>
      onChange({
        target: {
          name: 'report_columns',
          value: [...report.report_columns, { id: uuid(), key: '', name: '', _isNew: true }],
        },
      }),
    [report.report_columns, onChange]
  );

  const handleRemoveAll = useCallback(() => {
    onChange({ target: { name: 'report_columns', value: report.report_columns.map(c => ({ ...c, destroy: true })) } });
  }, [report, onChange]);

  const filteredReportColumns = useMemo(() => {
    return report.report_columns.filter(column => !column?.destroy);
  }, [report.report_columns]);

  const learnMoreLink = useMemo(() => {
    if (isAgency) return 'https://support.winred.com/en/articles/7239601-vendor-custom-reports';

    return isCustomReport
      ? 'https://support.winred.com/en/articles/6742620-custom-reports'
      : 'https://support.winred.com/en/articles/4063521-payout-reports';
  }, [isCustomReport, isAgency]);

  return (
    <FormCard
      className="pb-3"
      classNameTitle="d-flex justify-between"
      subtitle={
        <>
          Format your report by selecting the columns you would like to include. You can drag and drop to rearrange the
          order in which the columns will appear. <ExternalLink href={learnMoreLink}>Learn More.</ExternalLink>
        </>
      }
      title={
        <>
          Report Format
          {filteredReportColumns.length > 0 && (
            <WinRedChip onClick={handleRemoveAll}>
              <Icon className="mr-2" color="text-lynx" icon="minus-circle" size="lg" />
              Remove All
            </WinRedChip>
          )}
        </>
      }
    >
      <DragDrop items={report.report_columns} onUpdate={onChange} name="report_columns" getItemStyle={getItemStyle}>
        {({ item }) => {
          if (item.destroy) return null;

          return (
            <ReportFieldRow
              key={item.id}
              report={report}
              row={item}
              columnOptions={columnOptions}
              onChange={onChange}
            />
          );
        }}
      </DragDrop>
      <AddField text="Add Field" onClick={addColumn} />
    </FormCard>
  );
}
ReportFormat.propTypes = {
  report: PropTypes.object.isRequired,
  columnOptions: PropTypes.array.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportFormat };
