import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Select, ColorPickerInput, FlexBinaryChoiceRow, FormCardSpaced, ImageCard } from 'modules/common';
import {
  CONFETTI_ANIMATION_STYLE_OPTIONS,
  CONFETTI_EMOJI_DROPDOWN_OPTIONS,
  CONFETTI_EMOJI_OPTIONS,
  CONFETTI_SHAPE_OPTIONS,
} from './confetti/fields';
import { generateConfettiTriggerDropwdown } from '../tools';

function WidgetConfettiAnimation({ widget, updateWidget, disabled = false, page }) {
  let confettiTrigerElements = useMemo(() => {
    return generateConfettiTriggerDropwdown(page);
  }, [page]);

  const confettiTriggerElementValue = confettiTrigerElements?.find(opt => opt.value === widget.confettiTriggerElement);
  const confettiAnimationStyleValue = CONFETTI_ANIMATION_STYLE_OPTIONS.find(
    opt => opt.value === widget.confettiAnimationStyle
  );
  const confettiShapeValue = CONFETTI_SHAPE_OPTIONS.find(opt => opt.value === widget.confettiShape);
  const confettiEmojiValue = CONFETTI_EMOJI_OPTIONS.find(opt => opt.value === widget.confettiEmoji);

  return (
    <div className="confetti-animation">
      <Select
        label="Confetti Trigger Element"
        floatLabel="Confetti Trigger Element"
        placeholder="Select Confetti Trigger Element..."
        name="confettiTriggerElement"
        onChange={updateWidget}
        disabled={disabled}
        value={confettiTriggerElementValue}
        options={confettiTrigerElements}
        validators={['required']}
        description="Select which item will trigger the confetti animation when clicked."
        errorMessages={['Confetti Trigger Element is required.']}
      />
      <Select
        label="Confetti Animation Style"
        floatLabel="Confetti Animation Style"
        placeholder="Select Confetti Animation Style..."
        name="confettiAnimationStyle"
        onChange={updateWidget}
        disabled={disabled}
        value={confettiAnimationStyleValue}
        options={CONFETTI_ANIMATION_STYLE_OPTIONS}
        validators={['required']}
        description="Select the type of animation to use when the effect is triggered."
        errorMessages={['Confetti Animation Style is required.']}
      />
      <FormCardSpaced className="mb-3" classNameFooter="pb-1 widget-button-animation_footer">
        <FlexBinaryChoiceRow
          name="confettiUseEmoji"
          title="Confetti Style"
          trueText="Emojis"
          falseText="Confetti"
          disabled={disabled}
          subTitle="Select whether you would like to use emojis or confetti in your animation."
          value={widget.confettiUseEmoji}
          onChange={updateWidget}
        />
      </FormCardSpaced>
      {widget.confettiUseEmoji ? (
        <Select
          label="Emoji"
          floatLabel="Emoji"
          placeholder="Select Emoji..."
          name="confettiEmoji"
          onChange={updateWidget}
          disabled={disabled}
          value={confettiEmojiValue}
          options={CONFETTI_EMOJI_DROPDOWN_OPTIONS}
          validators={['required']}
          description="Select which emoji you would like to use in your animation."
          errorMessages={['Emoji is required.']}
        />
      ) : (
        <Select
          label="Confetti Shape"
          floatLabel="Confetti Shape"
          placeholder="Select Confetti Shape..."
          name="confettiShape"
          onChange={updateWidget}
          disabled={disabled}
          value={confettiShapeValue}
          options={CONFETTI_SHAPE_OPTIONS}
          validators={['required']}
          description="Select the default value for a mix of shapes."
          errorMessages={['Confetti Shape is required.']}
        />
      )}
      {widget.confettiUseEmoji ? (
        <ImageCard
          assignedImage={widget.widgetImage}
          imageType="WIDGET_IMAGE"
          name="widgetImage"
          disabled={disabled}
          onChange={updateWidget}
          className="border-0"
          classNameBody="px-0 border-0"
          title="Custom Image"
          subtitle="Upload an image to use in your confetti animation. Uploading an image will override the use of any emoji selected in the dropdown field above. Images must have a transparent background. The recommended image size is 50x50."
          removeTitle="Remove Custom Image"
          removeBody="Are you sure you want to remove this custom image from your confetti widget?"
        />
      ) : null}
      {widget.confettiUseEmoji ? null : (
        <ColorPickerInput
          value={widget.confettiColor}
          name="confettiColor"
          setColorAction={updateWidget}
          disabled={disabled}
          placeholder="Confetti Color"
          label="Confetti Color"
          description="Select a color for the animated confetti effect. Leave blank for a mix of colors."
        />
      )}
    </div>
  );
}

WidgetConfettiAnimation.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WidgetConfettiAnimation;
