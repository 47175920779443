import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import '../../widgets.scss';
import { ProgressBar, Typography } from 'modules/common';
import { getThermometerPreviewData, isMoneyThermometerType } from '../thermometer/tools';
import { THERMOMETER_TYPES_PREVIEW_MAP } from '../../drawer/thermometer/thermometer-fields';
import { formatNumberWithCommas } from 'core/utilities';

function WidgetPreviewThermometer({ widget }) {
  const { accumulating_amount, selectedGoal, percentage } = getThermometerPreviewData(widget);
  const isWidgetSet = Object.keys(widget.statistics).length !== 0;
  const isMoneyThermometer = isMoneyThermometerType(widget?.thermometer_type);
  if (!isWidgetSet) return null;
  return (
    <Container>
      <Typography className={`text-center ${!widget.thermometerLabel && 'invisible'}`}>
        {widget.thermometerLabel ? widget.thermometerLabel : 'Placeholder'}
      </Typography>
      <ProgressBar variant="default" min={0} max={100} now={percentage * 100} />
      <div className="d-flex justify-between mt-3 px-3">
        <Typography color="mirage" className="font-medium text-center">
          {isMoneyThermometer ? '$' : ''}
          {formatNumberWithCommas(accumulating_amount)} <br /> {THERMOMETER_TYPES_PREVIEW_MAP[widget?.thermometer_type]}
        </Typography>
        <Typography color="mirage" className="font-medium text-center">
          {isMoneyThermometer ? '$' : ''}0 <br /> Your Impact
        </Typography>
        <Typography color="mirage" className="font-medium text-center">
          {isMoneyThermometer ? '$' : ''}
          {formatNumberWithCommas(selectedGoal)} <br /> Goal
        </Typography>
      </div>
    </Container>
  );
}

WidgetPreviewThermometer.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default WidgetPreviewThermometer;
