import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced } from 'modules/common';
import '../common.scss';
import './info-card.scss';

function InfoCard({ title, children }) {
  return (
    <div className="detail-card-container">
      <FormCardSpaced className="detail-card" title={title}>
        {children}
      </FormCardSpaced>
    </div>
  );
}

InfoCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default React.memo(InfoCard);
