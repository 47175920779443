import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, SpinnerContainer, Typography } from 'modules/common';
import { CommitteeComponent } from '../committee.component';
import { DuplicateErrorComponent } from './duplicate-page-error.component';

export const DuplicateResultsTableMobile = ({ items, copyUrl, onSubmit, loading }) => {
  if (!items) return null;

  return items?.map((item, index) => {
    const {
      page_duplication_parameters: { customName, customSlug },
      template,
    } = item;

    const handleOnCopy = () => {
      copyUrl(item?.duplicated_page?.url);
    };

    if (loading.loading && loading.id === item.id) {
      return <SpinnerContainer className="py-5" />;
    }

    return (
      <Fragment key={index}>
        <div className="list-item-card flex flex-col items-start w-11/12 mx-auto">
          <div className="flex w-full">
            <div className="status-info flex items-center mb-3 mr-3">
              <Icon
                icon={item.errors ? 'exclamation-circle' : 'check-circle'}
                color={item.errors ? 'red' : 'green'}
                className="mr-2 text-xl"
              />
              <div className="page-name flex flex-col">
                <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
                  {customName}
                </Typography>
                <Typography className={`mb-0 ${item.errors ? 'text-winred-red' : ''}`}>{customSlug}</Typography>
              </div>
            </div>
            <div className="vendorFees w-full">
              {template?.partnership_templates.map((template, index) => {
                return <CommitteeComponent item={template} key={index} />;
              })}
            </div>
          </div>
          <div className="flex justify-between items-center w-full mt-3">
            <div className="labels flex items-center">
              {template?.upsell_agency_fees_enabled ? (
                <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center mr-2">
                  Upsell
                </div>
              ) : null}
              {template?.recurring_agency_fees_enabled ? (
                <div className="bg-purple-light text-purple-dark border-purple-dark label-status px-3 py-1 font-medium rounded-full inline-block text-center">
                  Subscription
                </div>
              ) : null}
            </div>
            <Button skinnyButton onClick={handleOnCopy} variant="primary" disabled={item.errors}>
              Copy URL
            </Button>
          </div>
          {item?.errors ? <DuplicateErrorComponent data={item} onSubmit={onSubmit} isMobile={true} /> : null}
        </div>
      </Fragment>
    );
  });
};

DuplicateResultsTableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  copyUrl: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
};
