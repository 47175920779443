import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Card, Typography, Select, FormCard } from 'modules/common';
import { customFieldTypes } from '../custom.tools';
import '../custom.scss';

function CustomFieldType({ children, onChangeCustomFieldType, selectedCustomField, disabled }) {
  const selectedValue = customFieldTypes.find(ft => ft.value === selectedCustomField.input_type);

  return (
    <FormCard footer={children}>
      <Row>
        <Col sm={12} md={6}>
          <Card.Title>Field Type</Card.Title>
          <Typography>Select the type of field to create.</Typography>
        </Col>
        <Col sm={12} md={6}>
          <Select
            label="Field Type"
            floatLabel="Field Type"
            disabled={selectedCustomField.fromExisting || disabled}
            onChange={e => onChangeCustomFieldType(selectedCustomField.page_custom_field_id, e.target.value)}
            value={selectedValue}
            options={customFieldTypes}
            validators={['required']}
            errorMessages={['Field Type is required.']}
          />
        </Col>
      </Row>
    </FormCard>
  );
}

CustomFieldType.propTypes = {
  children: PropTypes.object,
  selectedCustomField: PropTypes.object.isRequired,
  onChangeCustomFieldType: PropTypes.func.isRequired,
};

export default CustomFieldType;
