import React from 'react';
import PropTypes from 'prop-types';

import { formatNumberWithCommas } from 'core/utilities';
import { Icon, ToolTip } from 'modules/common';
import { computeIconColor, computeTooltip } from '../../../upsells/ui.tools';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function ConditionItemsMobile({ items, navigateToItem }) {
  return items.map(item => {
    const {
      revv_uid,
      name,
      status,
      pathway_count,
    } = item;

    const iconColor = computeIconColor(status);
    const toolTip = computeTooltip(status);
    const navigateHandler = () => navigateToItem(item);

    return (
      <div
        key={revv_uid}
        className="list-item-card"
        onClick={navigateHandler}
        onKeyPress={navigateHandler}
        tabIndex="0"
        role="link"
      >
        <Row className="no-gutters">
          <Col xs="2" className="list-item-card-icon">
            <ToolTip tooltip={toolTip}>
              <Icon icon="condition-icon" color={iconColor} size="sm" />
            </ToolTip>
          </Col>
          <Col xs="10">
            <Row className="no-gutters">
              <Col className="list-item-card-title">{name}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{formatNumberWithCommas(pathway_count)} Attached Upsell Pathway</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });
}

ConditionItemsMobile.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
};
