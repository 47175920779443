import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Checkbox, FormCardSpaced, useTimezoneOptions, WinRedSelect } from 'modules/common';
import { profileCandidateSelector } from 'core/login';

function ReportTimezone({ isNew, report, onChange }) {
  const profileCandidate = useSelector(profileCandidateSelector);

  const [timezoneOptions] = useTimezoneOptions();

  useEffect(() => {
    if (isNew && profileCandidate) onChange({ target: { name: 'timezone', value: profileCandidate.timezone } });
  }, [isNew, onChange, profileCandidate]);

  const selectedTimezone = useMemo(() => {
    const selectedTimezone = timezoneOptions.find(timezone => timezone.value === report.timezone);

    if (selectedTimezone) return selectedTimezone;

    return null;
  }, [report, timezoneOptions]);

  return (
    <FormCardSpaced title="Report Timezone">
      <WinRedSelect
        description="Select the timezone for this report. Report Frequency, Report Conditions, and the data displayed in this report will use this timezone."
        placeholder="Timezone"
        name="timezone"
        numberOfDropdownItems={4}
        options={timezoneOptions}
        value={selectedTimezone}
        onChange={e => onChange({ target: { ...e.target, value: e.target.value } })}
      />
      <div className="bg-white border rounded p-3 mb-3">
        <Checkbox
          name="use_timezone_in_report"
          label="Override timezone to use Universal Time Coordinated (UTC) for the data displayed in this report."
          value={!report.use_timezone_in_report}
          onChange={event => onChange({ target: { name: 'use_timezone_in_report', value: !event.target.value } })}
        />
      </div>
    </FormCardSpaced>
  );
}

ReportTimezone.propTypes = {
  isNew: PropTypes.bool,
  report: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportTimezone };
