import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RequiredLabel from './required.component';
import { Form } from 'react-bootstrap';
import { Card, FloatGroup, Input } from 'modules/common';

function CustomFieldToggleTextPreview({ formState, isNumberField, disabled }) {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState(formState.default_value || '');
  useEffect(() => setValue(formState.default_value), [formState.default_value]);

  return (
    <div>
      <Card.Title className="preview-title"></Card.Title>
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
        label={
          <>
            {formState.label || ''}
            <RequiredLabel formState={formState} />
          </>
        }
      />

      {checked && (
        <FloatGroup>
          <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            disabled={disabled}
            placeholder={formState.placeholder}
            spellCheck="false"
            value={value}
            maxLength="255"
            numbersOnly={isNumberField}
            onChange={e => {
              const value = e.target.value || '';
              setValue(value);
            }}
          />
        </FloatGroup>
      )}
    </div>
  );
}

CustomFieldToggleTextPreview.propTypes = {
  formState: PropTypes.object.isRequired,
  isNumberField: PropTypes.bool,
};

CustomFieldToggleTextPreview.defaultProps = {
  isNumberField: false,
};

export default CustomFieldToggleTextPreview;
