import React from 'react';
import PropTypes from 'prop-types';
import { Breakpoint } from 'react-socks';
import { useSelector } from 'react-redux';

import { Pagination, EmptyList, FormContainer, FormColumn, Typography, ExternalLink } from 'modules/common';
import { profileCandidateSelector } from 'core/login';

import { ListViewDesktop } from './list-view-desktop';
import { ListViewMobile } from './list-view-mobile';

export function BillPayActivity({ billPayData, page, setPage, isSettingsTab = false }) {
  const onPageChange = ({ target: { value } }) => setPage(value);
  const profileCandidate = useSelector(profileCandidateSelector);

  if (billPayData.results.length === 0) {
    return (
      <FormContainer>
        <FormColumn>
          <EmptyList text="No Bill-Pay Logs" description="We couldn't find any Bill-Pay Logs." name="billpay" />
        </FormColumn>
      </FormContainer>
    );
  }

  return (
    <div className="mt-3">
      <FormContainer>
        <FormColumn>
          <div className="bg--white pb-3">
            <div className="pl-3 py-4 mb-0">
              <Typography variant="h5" className="mb-0">
                Bill-Pay Logs
              </Typography>
              <ExternalLink href="http://support.winred.com/en/articles/5698360-bill-pay">Learn More</ExternalLink>
            </div>

            <Breakpoint small down>
              <ListViewMobile
                items={billPayData.results}
                isSettingsTab={isSettingsTab}
                profileCandidate={profileCandidate}
              />
            </Breakpoint>

            <Breakpoint medium up>
              <ListViewDesktop
                items={billPayData.results}
                isSettingsTab={isSettingsTab}
                profileCandidate={profileCandidate}
              />
            </Breakpoint>
          </div>

          <Pagination
            currentPage={page}
            pageCount={billPayData.page_count}
            totalCount={billPayData.total_count}
            setPage={onPageChange}
          />
        </FormColumn>
      </FormContainer>
    </div>
  );
}

BillPayActivity.propTypes = {
  billPayData: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  isSettingsTab: PropTypes.bool,
};
