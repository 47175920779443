import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, FormCardSpaced, WinRedSelect, Typography, SpinnerContainer, useToast } from 'modules/common';
import { Col, Row } from 'react-bootstrap';
import {
  GET_ORGANIZATIONS_THERMOMETER_CAMPAIGNS_QUERY,
  GET_ORGANIZATION_THERMOMETER_CAMPAIGN_QUERY,
  GET_THERMOMETER_CAMPAIGNS_QUERY,
  GET_THERMOMETER_CAMPAIGN_QUERY,
} from 'core/middleware/queries';
import { useLazyQuery } from '@apollo/client';
import {
  thermometerWidgetOptions,
  thermometerWidgetOptionsPetition,
} from '../../drawer/thermometer/thermometer-fields';
import { formatThermometerForUi, generateThermometerOptions } from '../../drawer/thermometer/formating';

export const SelectExistingThermometer = ({
  widget,
  toggleSelectExisting,
  onChange,
  isOrganization,
  profileCandidate,
  loadedThermometers,
  setLoadedThermometers,
  setSelectedThermometer,
}) => {
  const setToast = useToast();
  const isPetitionPage = widget.isPetitionPage;
  const thermometerType = isPetitionPage ? thermometerWidgetOptionsPetition : thermometerWidgetOptions;

  const [getAllThermometers, { loading: getAllThermomtersLoading, getAllThermometersError }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATIONS_THERMOMETER_CAMPAIGNS_QUERY : GET_THERMOMETER_CAMPAIGNS_QUERY,
    {
      variables: {
        limit: 9999,
        organizationRevvUid: profileCandidate.organization_revv_uid,
        initiativeType: thermometerType.map(item => {
          return item.value;
        }),
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const { results } = data?.viewer[isOrganization ? 'state_level_organization' : 'candidate']?.campaigns;
        setLoadedThermometers({ loaded: true, results: generateThermometerOptions(results) });
      },
    }
  );
  const [getThermometerDetails, { loading: getThermometerDetailsLoading }] = useLazyQuery(
    isOrganization ? GET_ORGANIZATION_THERMOMETER_CAMPAIGN_QUERY : GET_THERMOMETER_CAMPAIGN_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const { results } = data?.viewer[isOrganization ? 'state_level_organization' : 'candidate']?.campaigns;
        const updatedWidget = formatThermometerForUi(results[0], widget);
        onChange({ target: { name: 'widgets', value: updatedWidget } });
        setSelectedThermometer(true);
      },
    }
  );

  const onSelectItem = ({ target: { value } }) => {
    try {
      getThermometerDetails({
        variables: {
          organizationRevvUid: profileCandidate.organization_revv_uid,
          revvUid: value.revv_uid,
        },
      });
    } catch (error) {
      setToast({ message: error, isError: true });
    }
  };

  useEffect(() => {
    if (!loadedThermometers.loaded) {
      getAllThermometers();
    }
  }, [getAllThermometers, loadedThermometers]);

  if (getAllThermomtersLoading || getThermometerDetailsLoading) {
    return (
      <FormCardSpaced>
        <SpinnerContainer />
      </FormCardSpaced>
    );
  }

  if (getAllThermometersError) {
    setToast({ message: getAllThermometersError, isError: true });
  }

  return (
    <FormCardSpaced>
      <Row>
        <Col xs={12} lg={5}>
          <Typography variant="h5" className="mb-1">
            Add Existing Thermometer
          </Typography>
          <Typography>Search for previously created thermometer by name bellow. </Typography>
        </Col>
        <Col xs={12} lg={6}>
          <WinRedSelect
            placeholder="Select Thermometer"
            name=""
            options={loadedThermometers.results}
            value={''}
            onChange={onSelectItem}
          />
        </Col>
        <Col xs={12} lg={1}>
          <Icon className="mt-2 ml-3 pointer" icon="times" color="gray" size="lg" onClick={toggleSelectExisting} />
        </Col>
      </Row>
    </FormCardSpaced>
  );
};

SelectExistingThermometer.propTypes = {
  toggleSelectExisting: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  profileCandidate: PropTypes.object.isRequired,
};
