import React from 'react';
import { Helmet } from 'react-helmet';

function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export default function setTitle(title, shouldTitleCase = true) {
  return (
    <Helmet defer={false}>
      <title>{shouldTitleCase ? titleCase(title || '') : title} | WinRed</title>
    </Helmet>
  );
}
