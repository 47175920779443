import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import { PageNotFound } from 'modules/common';
import Logout from 'modules/login';
import TeamPagesList from 'modules/pages/team-pages-list.container';
import TeamPageDetails from 'modules/pages/team-pages-details.container';
import NewTeamPage from 'modules/pages/team-pages-new.container';
import TeamsDonationsList from 'modules/revenue/team-donations-list.container';
function DonorUserRoutes(props) {
  return (
    <Switch>
      <Route exact path="/team-pages/pages" component={TeamPagesList} />
      <Route
        exact
        path="/team-pages/pages/new"
        component={() => {
          // if we duplicate a page then try to change tab React tries to rerender
          // this component since we are on a different route technically - we dont want this
          // since we already have the page data as it will destroy the data we retrieved
          const revv_uid = props.location.state && props.location.state.revv_uid;
          return <NewTeamPage key={revv_uid} />;
        }}
      />
      <Route exact path="/team-pages/pages/:pageId" component={TeamPageDetails} />
      <Route exact path="/team-pages/donations" component={TeamsDonationsList} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/" render={() => <Redirect to="/team-pages/pages" />} />
      <Route exact path="/login" render={() => <Redirect to="/team-pages/pages" />} />
      <Route exact path="/users/sign_in" render={() => <Redirect to="/team-pages/pages" />} />{' '}
      {/* coming from reset password */}
      <Route exact path="/page-not-found" component={PageNotFound} />
      {/* for some reason routes like /my-pagesg were not redirecting so we force one instead */}
      <Route
        render={() => {
          props.history.push('/page-not-found');
          return <Redirect to="/page-not-found" />;
        }}
      />
    </Switch>
  );
}

DonorUserRoutes.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};
export default withRouter(DonorUserRoutes);
