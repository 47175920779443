import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, WinRedInput } from '../form';
import WinRedModal from './winred-modal.component';
import Typography from '../typography.component';
import classnames from 'classnames';

function ConfirmModal({
  children,
  classNameBody,
  title,
  show,
  onSubmit,
  handleClose,
  buttonText,
  buttonColor,
  showCancelButton,
  onCancel,
  cancelButtonColor,
  cancelButtonText,
  disableSubmit,
  subtitle,
  size,
  leftAlignFooterButtons,
  requiredConfirmationText,
}) {
  const [confirmationText, setConfirmationText] = useState('');
  // dont allow both buttons to be the same color...
  if ((buttonColor === 'error' || buttonColor === 'cancel') && cancelButtonColor === 'cancel') {
    cancelButtonColor = 'outline-primary';
  }

  const onChangeConfirmationText = ({ target: { name, value } }) => {
    setConfirmationText(value);
  };

  const renderCancelButton = () => (
    <Button ariaLabel="cancel" variant={cancelButtonColor} onClick={onCancel || onSubmit}>
      {cancelButtonText}
    </Button>
  );

  const renderFooter = () => {
    return (
      <>
        {requiredConfirmationText && (
          <div className="confirmation-input px-4">
            <Typography variant="bodyMedium">
              Please Enter "{requiredConfirmationText}" in the field below and Select "{buttonText}" to confirm these
              changes.
            </Typography>
            <WinRedInput
              onChange={onChangeConfirmationText}
              name="confirmationTextValue"
              value={confirmationText}
              validators={[
                {
                  validator: input => {
                    return input === requiredConfirmationText;
                  },
                  message: 'Please enter confirmation text.',
                },
              ]}
            />
          </div>
        )}
        <Modal.Footer
          className={classnames('d-flex align-items-between', { 'justify-content-start': leftAlignFooterButtons })}
        >
          {showCancelButton && !leftAlignFooterButtons && renderCancelButton()}
          <Button
            disabled={disableSubmit || (requiredConfirmationText && requiredConfirmationText !== confirmationText)}
            ariaLabel={buttonText}
            variant={buttonColor}
            onClick={onSubmit}
          >
            {buttonText}
          </Button>
          {showCancelButton && leftAlignFooterButtons && renderCancelButton()}
        </Modal.Footer>
      </>
    );
  };

  return (
    <WinRedModal show={show} onHide={handleClose} size={size}>
      {!title && !subtitle ? null : (
        <>
          <Modal.Header closeButton>
            <Modal.Title as="h5" className="winred-h5">
              {title}
            </Modal.Title>
          </Modal.Header>
          {subtitle ? <Typography className="pl-4">{subtitle}</Typography> : null}
        </>
      )}

      <Modal.Body className={classNameBody}>{children}</Modal.Body>
      {renderFooter()}
    </WinRedModal>
  );
}

ConfirmModal.propTypes = {
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableSubmit: PropTypes.bool,
  classNameBody: PropTypes.string,
  size: PropTypes.string,
  leftAlignFooterButtons: PropTypes.bool,
  requiredConfirmationText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  showCancelButton: false,
  title: '',
  subtitle: '',
  buttonText: 'Okay',
  buttonColor: 'primary',
  cancelButtonColor: 'cancel',
  cancelButtonText: 'Cancel',
  classNameBody: '',
  disableSubmit: false,
  size: 'lg',
  leftAlignFooterButtons: false,
  requiredConfirmationText: null,
};

export default ConfirmModal;
