import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import { noop } from 'core/utilities';

const FloatGroupContext = React.createContext({
  setActive: noop,
  active: null,
  shouldFloat: false,
});

class FloatGroup extends Component {
  state = {
    active: false,
  };

  setActive = active => this.setState({ active });

  render() {
    const { children, noLabel, floatLabel, ...rest } = this.props;

    // get values needed to input label
    let inputChild = children;
    if (children && Array.isArray(children) && children.find) {
      inputChild = children.find(child => child?.props?.name) || {};
    }

    const placeholder = inputChild.props?.placeholder || '';
    const _floatLabel = inputChild.props?.floatLabel || inputChild.props?.label || inputChild.props?.placeholder;
    const name = inputChild.props?.name ?? '';
    const value = inputChild.props?.value ?? '';

    return (
      <FloatGroupContext.Provider
        value={{
          setActive: this.setActive.bind(this),
          active: value ? value : this.state.active,
          shouldFloat: true,
        }}
      >
        <Form.Group {...rest}>
          <div className="float-label">
            {noLabel || !placeholder ? null : (
              <Label htmlFor={name}>{value || floatLabel ? _floatLabel || placeholder : placeholder}</Label>
            )}
            {children}
          </div>
        </Form.Group>
      </FloatGroupContext.Provider>
    );
  }
}

class Label extends Component {
  static contextType = FloatGroupContext;

  render() {
    const { children, className, ...rest } = this.props;
    const activeClass = this.context.active ? 'active' : '';
    const useClassName = classNames('placeholder', 'input', activeClass, className);
    return (
      <Form.Label {...rest} className={useClassName}>
        {children}
      </Form.Label>
    );
  }
}

FloatGroup.propTypes = {
  children: PropTypes.node.isRequired,
  noLabel: PropTypes.bool,
  floatLabel: PropTypes.bool,
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FloatGroup.Context = FloatGroupContext;
FloatGroup.Label = Label;

export default FloatGroup;
