import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import honeybadger from 'honeybadger-js';

import { profileSelector } from 'core/login';
import { Button, Icon, Typography, sendToIntercom, setTitle } from 'modules/common';
import uuid from 'core/utilities/uuid';
import { withAmplitude } from '../tools';

class ErrorComponent extends React.Component {
  state = {
    errors: null,
  };

  componentDidMount() {
    if (!this.props.noReport) {
      console.error(this.props.error);

      const context = {
        badgerId: this.props.badgerId,
        version: window.appVersion,
        url: window.location.href,
        originComponent: this.props.originComponent || '',
      };

      console.error(context);
      honeybadger.notify(this.props.error, { context });
    }

    this.props.logEvent('500 page', {
      'honeybadger id': this.props.badgerId,
    });

    sendToIntercom(this.props.profile, this.props.badgerId);
  }

  /**
   * apollo doesnt catch retry errors itself - we need to handle that
   */
  handleError = () => {
    if (!this.props.errorAction) return;

    const errorResponse = this.props.errorAction();
    if (errorResponse.catch) errorResponse.catch(errors => this.setState({ errors }));
  };

  render() {
    let { error, errorAction, errorIcon = 'sync', errorText = 'Retry' } = this.props;
    let { errors } = this.state;

    // state comes first then any error props
    error = errors || error;

    if (Array.isArray(error)) error = error[0];

    /* If there is a graphQL error, show that since it will have problem details. */
    if (error?.graphQLErrors) {
      error = error.graphQLErrors[0]?.message || error.graphQLErrors[0] || error.graphQLErrors || error;
    }

    if (error?.networkError) {
      error = error.networkError[0]?.message || error.networkError[0] || error.networkError || error;
    }

    error = error?.message || error || 'Unknown Error';

    return (
      <div className="main-data-error" role="status">
        {setTitle('Error')}
        <Typography variant="h1" className="header">
          <Icon icon="exclamation-triangle" style={{ marginRight: '0.5rem' }} />
          Error
        </Typography>

        <div className="body">
          {error ? <p>{error}</p> : ''}
          {error?.problems?.map((problem, index) => (
            <p key={index}>
              {problem.path}: {problem.explanation}
            </p>
          ))}

          <p>Error ID: {this.props.badgerId}</p>

          {errorAction && (
            <Button alt onClick={this.handleError}>
              <span>
                <Icon icon={errorIcon} /> {errorText}
              </span>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ErrorComponent.propTypes = {
  error: PropTypes.any.isRequired,
  logEvent: PropTypes.func.isRequired,
  errorAction: PropTypes.func,
  errorIcon: PropTypes.string,
  errorText: PropTypes.string,
  originComponent: PropTypes.string,
  profile: PropTypes.object,
  noReport: PropTypes.bool,
  badgerId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: profileSelector(state),
    badgerId: uuid(),
  };
};

export default compose(withAmplitude, connect(mapStateToProps, null))(ErrorComponent);
