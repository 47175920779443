import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

function useValidationContainer() {
  const [inputs, setInputs] = useState([]);

  // use ref so we dont trigger a rerender when errors are added to this container
  const containerTriggeredErrors = useRef([]);

  // attach input to this container via the input's useEffect
  const attach = useCallback(component => setInputs(inputs => [component, ...inputs]), []);

  // detach inputs to this container when they are removed via the input's useEffect callback
  const detach = useCallback(
    component => setInputs(inputs => inputs.filter(c => c.validatorId !== component.validatorId)),
    []
  );

  // trigger validation in all attached inputs and save errors in array to return to container
  const checkValidationErrors = useCallback(() => {
    containerTriggeredErrors.current = [];

    for (const input of inputs) {
      const error = input.checkIfIsInvalid();
      if (error) containerTriggeredErrors.current.push({ error, input });
    }

    return containerTriggeredErrors.current;
  }, [inputs, containerTriggeredErrors]);

  // the validation container itself that holds the contexxt to attach/detach inputs to this validation container
  const ValidationContainer = useMemo(() => {
    return ({ children }) => (
      <ValidationContext.Provider value={{ attach, detach }}>{children}</ValidationContext.Provider>
    );
  }, [attach, detach]);

  return [ValidationContainer, checkValidationErrors];
}

useValidationContainer.propTypes = {
  children: PropTypes.any.isRequired,
};

export default useValidationContainer;

export const ValidationContext = React.createContext();
