import produce from 'immer';

import { getCandidateDate } from 'core/utilities/time';
import {
  SET_DONATIONS_FILTER,
  SET_SUBSCRIPTIONS_FILTER,
  SET_TEAM_DONATIONS_FILTER,
  SET_MONEYPLEDGE_FILTER,
  CLEAR_MONEYPLEDGE_FILTER,
} from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialRevenueState = {
  donationsFilter: {
    page: 1,

    origins: [{ value: 'all_winred', label: 'All WinRed' }],
    recipients: [],
    // default value is today - we want to always make sure the computed today's date is actually today so create new date object
    dateRangeStart: () => getCandidateDate(),
    dateRangeEnd: () => getCandidateDate(),
    recurrence: null,
    activeTabKey: 'all', // this is status (this is for the tabs)

    // donor details
    firstName: null,
    lastName: null,
    amountLower: null,
    amountUpper: null,
    amountPredicate: null,
    revvUser: '',
    email: null,
    phone: null,
    contributorType: [],

    // utms
    sourceUrl: null,
    pageType: '',
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,

    // advanced
    revvUid: null,
    // payoutRevvUid: null,
    campaigns: [],
    sourceCode: null,
    bundlerId: null,

    productName: null,
  },

  moneyPledgeFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    isRevvUser: null,
    activeTabKey: 'all',
    page: 1,
    interval: null,
  },

  subscriptionsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    isRevvUser: null,
    activeTabKey: 'all',
    page: 1,
    interval: null,
    contributorType: [],
    recipient: null,
  },

  teamDonationsFilter: {
    search: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    amount: null,
    amountPredicate: 'EQ',
    isRevvUser: null,
    activeTabKey: 'all',
    page: 1,
  },
};

export const revenueReducer = produce((state = initialRevenueState, action) => {
  let filter = {};

  switch (action.type) {
    case SET_DONATIONS_FILTER:
      filter = { ...action.filter };
      return { ...state, donationsFilter: filter };
    case SET_MONEYPLEDGE_FILTER:
      filter = { ...action.filter };
      return { ...state, moneyPledgeFilter: filter };
    case CLEAR_MONEYPLEDGE_FILTER:
      return { ...state, moneyPledgeFilter: { ...initialRevenueState.statisticsFilter } };
    case SET_SUBSCRIPTIONS_FILTER:
      return { ...state, subscriptionsFilter: action.filter };
    case SET_TEAM_DONATIONS_FILTER:
      return { ...state, teamDonationsFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialRevenueState };
    default:
      return state;
  }
}, initialRevenueState);
