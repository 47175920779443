import React, { useRef, useEffect, createRef, useState } from 'react';
import { NestedDrawer, DrawerHeader, Typography, useToast, Error, Button } from 'modules/common';
import { ValidationBlock } from 'core/validation';
import { ImageDrawerBody } from './image-drawer-body.component';
import {
  GET_IMAGES_QUERY,
  GET_ORGANIZATION_IMAGES_QUERY,
  GET_VENDOR_IMAGES_QUERY,
  UPLOAD_IMAGE_MUTATION,
  UPLOAD_ORGANIZATION_IMAGE_MUTATION,
  UPLOAD_VENDOR_IMAGE_MUTATION,
} from 'core/middleware/queries';
import { useMutation, useQuery } from '@apollo/client';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isAgencySelector, isOrganizationSelector, profileCandidateSelector } from 'core/login';
import './image-drawer.scss';

const MAX_FILE_SIZE = 1024 * 1024;

export const ImagePromptDrawer = ({
  open,
  toggleDrawer,
  imageType,
  maxFileSize,
  imageUri,
  selectImageAction,
  name,
  isNested,
  assignedImages,
  isMultiUpload = false,
}) => {
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [imageList, setImageList] = useState([]);
  const setToast = useToast();
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const isAgency = useSelector(isAgencySelector);
  const [disableDoneButton, setDisableDoneButton] = useState(true);

  const onSelectImageId = newId => {
    setDisableDoneButton(newId === null ? true : false);
    setSelectedImageId(newId);
  };

  const { loading, error, refetch } = useQuery(
    isOrganization ? GET_ORGANIZATION_IMAGES_QUERY : isAgency ? GET_VENDOR_IMAGES_QUERY : GET_IMAGES_QUERY,
    {
      variables: {
        [isAgency ? 'vendorRevvUid' : 'organizationRevvUid']: profileCandidate.organization_revv_uid,
        imageType: imageType,
        limit: 1000,
        sort: 'DESC',
        sortDirectionEnum: 'CREATED_AT',
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const candidate = data.viewer?.candidate || data.viewer?.state_level_organization || data.viewer?.vendor;
        const images = candidate?.images?.results ?? [];
        setImageList(images);
        if (selectedImageId === null && imageUri) {
          const newSelectedImageId = imageList.findIndex(image => image.thumbnail === imageUri);
          if (newSelectedImageId !== -1) onSelectImageId(newSelectedImageId);
        }
      },
    }
  );

  const [uploadNewImage] = useMutation(
    isAgency
      ? UPLOAD_VENDOR_IMAGE_MUTATION
      : isOrganization
      ? UPLOAD_ORGANIZATION_IMAGE_MUTATION
      : UPLOAD_IMAGE_MUTATION
  );

  const handleFiles = async acceptedFiles => {
    const files = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }));
    const file = files[0];

    // if a file the user tried to upload was invalid then we wont have anything here
    if (!file) return;

    if (file.size > maxFileSize) {
      alert(`The file you chose is too large. The maximum size is ${maxFileSize / 1000} Kb.`);
      return;
    }

    const mutationVariables = {
      newImage: file,
      imageType,
    };

    if (!isAgency) {
      mutationVariables.organizationRevvUid = profileCandidate.organization_revv_uid;
    } else {
      mutationVariables.vendorRevvUid = profileCandidate.organization_revv_uid;
    }

    try {
      const result = await uploadNewImage({ variables: mutationVariables });

      // get the new uploaded image object anbd add to list of images we uploaded
      const uploadImage =
        result.data &&
        (result.data.candidateUploadImage || result.data.organizationUploadImage || result.data.vendorUploadImage);

      if (uploadImage && uploadImage.errors === null) {
        const { image } = uploadImage;
        const newImageList = [{ ...image }, ...imageList];
        setImageList(newImageList);
        onSelectImageId(image.id);
      }
    } catch (error) {
      setToast({
        message: error,
        isError: true,
      });
    }
  };

  const onDone = () => {
    let selectedImageIndex = imageList.findIndex(image => image.id === selectedImageId);
    if (selectedImageId !== null && imageList[selectedImageIndex]) {
      const imageId = imageList[selectedImageIndex].id;
      const uri = imageList[selectedImageIndex].thumbnailUrl;

      const value = {
        imageId,
        image: {
          id: imageId,
          thumbnailUrl: uri,
        },
      };

      selectImageAction({ target: { name, value } });
    }

    // reset everything as we have what we need
    onCancel();
  };

  const onMultipleUploadDone = () => {
    const index = assignedImages.map(image => image.image.id).indexOf(selectedImageId);
    if (index !== -1) {
      if (assignedImages[index].destroy) {
        let tempImage = assignedImages[index];
        delete tempImage.destroy;
        selectImageAction({
          target: {
            name,
            value: assignedImages.map(image => (image.image.id === tempImage.image.id ? tempImage : image)),
          },
        });
        onCancel();
        return;
      }
      setToast({
        message: 'This image already exists for the product',
        isError: true,
      });
      return;
    }
    let selectedImageIndex = imageList.findIndex(image => image.id === selectedImageId);

    selectImageAction({
      target: {
        name,
        value: [
          ...assignedImages,
          { image: { id: imageList[selectedImageIndex].id }, image_url: imageList[selectedImageIndex].thumbnailUrl },
        ],
      },
    });

    // reset everything as we have what we need
    onCancel();
  };

  const onCancel = () => {
    onSelectImageId(null);
    toggleDrawer();
  };

  let backdropRef = useRef();
  const validationBlock = createRef();
  const setbackdropRef = node => (backdropRef = node);

  const handleClickOutside = event => {
    const clickedBackdrop = open && backdropRef && backdropRef?.contains(event.target);
    if (clickedBackdrop) {
      onCancel();
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    };
  });

  const onSelectedImageDeletion = () => {
    selectImageAction({ target: { name, value: null } });
  };

  if (error) {
    return (
      <Col className="text-center mb-3">
        <Error error={error} errorAction={refetch} />
      </Col>
    );
  }

  return (
    <NestedDrawer
      className="image-upload-drawer"
      setbackdropRef={setbackdropRef}
      open={open}
      toggleOpen={onCancel}
      icon="times"
      isNested={isNested}
      footer={
        <div className="p-3">
          <Button variant="alt" onClick={onCancel} className="mr-3">
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={disableDoneButton}
            onClick={isMultiUpload ? onMultipleUploadDone : onDone}
          >
            Done
          </Button>
        </div>
      }
    >
      <div>
        <DrawerHeader toggleOpen={onCancel}>
          <div className="text-center">
            <Typography variant="h2" className="mb-0">
              Choose Image
            </Typography>
          </div>
        </DrawerHeader>
        <div className="px-2">
          <ValidationBlock ref={validationBlock}>
            <ImageDrawerBody
              loading={loading}
              handleFiles={handleFiles}
              imagesList={imageList}
              maxFileSize={maxFileSize}
              selectedImageId={selectedImageId}
              setSelectedImageId={onSelectImageId}
              refetch={refetch}
              imageUri={imageUri}
              onSelectedImageDeletion={onSelectedImageDeletion}
            />
          </ValidationBlock>
        </div>
      </div>
    </NestedDrawer>
  );
};

ImagePromptDrawer.defaultProps = {
  maxFileSize: MAX_FILE_SIZE,
};
