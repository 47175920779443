import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { useLocalStorage } from 'modules/common/hooks';
import { FormCard, Icon, Input, FloatGroup, EmptyList, FlexBinaryChoiceRow, FormCardSpaced } from 'modules/common';
import { profileCandidateSelector } from 'core/login';

import AdditionalOptions from './additionalOptions.component';
import Disclaimer from './forms/disclaimer.component';
import Products from './forms/products.component';
import { ExternalLink } from 'modules/common/links/external-link.component';

function MerchandiseCampaign({ campaign, onChange, productFieldOptions, profileCandidate }) {
  const [showExtraOptions, setShowExtraOptions] = useLocalStorage('committeesExtraOptions', false);

  const handleNewProducts = () =>
    window.open(`https://winred.com/product-catalog/?committee_id=${profileCandidate.organization_revv_uid}`, '_blank');

  const toggleExtraOptions = () => setShowExtraOptions(state => !state);

  if (productFieldOptions.length === 0) {
    return (
      <EmptyList
        text="Start selling merchandise!"
        description="WinRed's Merchandise service allow you to sell and fulfill merchandise with minimal effort! Go to our Product Catalog to get started selling merchandise!"
        icon="tshirt"
        buttonText="Product Catalog"
        onClick={handleNewProducts}
      />
    );
  }

  return (
    <>
      <FormCard
        title={
          <div className="d-flex mb-1">
            Campaign Name
            <div className="clickable ml-auto" onClick={toggleExtraOptions}>
              <Icon role="button" icon={showExtraOptions ? 'cog-solid' : 'cog'} />
            </div>
          </div>
        }
      >
        <FloatGroup>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Campaign Name"
            maxLength="100"
            value={campaign.name}
            name="name"
            onChange={onChange}
            validators={['required']}
            errorMessages={['Campaign Name is required.']}
          />
        </FloatGroup>
        {showExtraOptions ? <AdditionalOptions campaign={campaign} onChange={onChange} /> : null}
      </FormCard>
      <FormCardSpaced>
        <FlexBinaryChoiceRow
          name="recurring_donation_required"
          title="Recurring Donation Required to Trigger Merchandise Campaign"
          subTitle={
            <>
              Select yes to require donors to opt-in to recurring donations in order to trigger this merchandise
              campaign.&nbsp;
              <ExternalLink href="https://support.winred.com/en/articles/4188864-create-a-merchandise-campaign">
                Learn More
              </ExternalLink>
              {campaign.recurring_donation_required && (
                <div style={{ color: 'red' }}>
                  Note: Merchandise Campaigns with Recurring Required cannot be added to upsells. If this campaign is
                  already attached to a Double Donation Upsell or a Panel Upsell card, then the Double Donation Upsell
                  or Panel Upsell card will not show to donors.
                </div>
              )}
            </>
          }
          value={campaign.recurring_donation_required}
          onChange={onChange}
        />
      </FormCardSpaced>
      <Products campaign={campaign} onChange={onChange} productFieldOptions={productFieldOptions} />
      <Disclaimer campaign={campaign} onChange={onChange} />
    </>
  );
}

MerchandiseCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  productFieldOptions: PropTypes.array,
  profileCandidate: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profileCandidate: profileCandidateSelector(state),
  };
};

export default compose(connect(mapStateToProps, null))(MerchandiseCampaign);
