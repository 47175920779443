import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { WinRedInput, WinRedInputNumbers, WinRedLazySelect, WinRedSelect } from '../../../../common';
import { US_STATES_FULL_NAME } from '../../../../settings/tabs/shared';
import { PAGE_TYPES_CONDITIONS, conditionValueOptionsMap } from '../../pathways.tools';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from '../../../../../core/login';
import { GET_CAMPAIGNS_QUERY, GET_ORGANIZATIONS_CAMPAIGNS_QUERY } from '../../../../../core/middleware/queries';

const validators = [
  {
    message: 'Value is required.',
    validator: 'required',
  },
  {
    validator: inputArray => {
      if (Array.isArray(inputArray)) {
        return inputArray.length > 0;
      } else {
        return true;
      }
    },
    message: 'Value is required.',
  },
];

export function StepValueInput({
  onChange,
  name,
  condition,
  predicate,
  customValueOptions,
  campaignsList,
  disabledStep,
}) {
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const selectedCampaign = useMemo(() => {
    if (Array.isArray(condition.value)) {
      const selectedCampaigns = condition?.value?.map(conditionValue => {
        const parsedValue = typeof conditionValue === 'object' ? conditionValue.value : conditionValue;
        return campaignsList?.find(camp => camp.revv_uid === parsedValue);
      });
      const formattedCampaigns = selectedCampaigns?.map(camp => {
        if (camp?.name.length > 25) {
          return { label: camp?.name.substring(0, 25) + '...', value: camp?.revv_uid };
        } else {
          return { label: camp?.name, value: camp?.revv_uid };
        }
      });
      return formattedCampaigns;
    } else {
      const matchedCampaign = campaignsList?.find(camp => camp?.revv_uid === condition.value);
      const formattedResult = matchedCampaign ? { value: condition.value, label: matchedCampaign.name } : null;
      return formattedResult;
    }
  }, [campaignsList, condition.value]);

  const variables = { organizationRevvUid: profileCandidate.organization_revv_uid, initiativeType: ['STANDARD'] };

  const campaignSearchQuery = isOrganization ? GET_ORGANIZATIONS_CAMPAIGNS_QUERY : GET_CAMPAIGNS_QUERY;

  const formatCampaigns = data => {
    const campaignResults =
      (data?.viewer?.state_level_organization?.campaigns || data?.viewer?.candidate?.campaigns)?.results || [];
    const campaignOptions = campaignResults?.map(camp => {
      return {
        value: camp.revv_uid,
        label: camp.name,
      };
    });
    return campaignOptions;
  };

  const isMultiSelect = useMemo(() => {
    return condition?.predicate === 'ONE_OF' || condition?.predicate === 'NONE_OF';
  }, [condition?.predicate]);

  const isBinaryOption = useMemo(() => {
    return Object.keys(conditionValueOptionsMap)?.includes(condition?.key);
  }, [condition?.key]);

  const isCustomOption = useMemo(() => {
    return Object.keys(customValueOptions)?.includes(condition?.key);
  }, [condition?.key, customValueOptions]);

  const shouldHideInput = useMemo(() => {
    return (
      predicate === 'IS_PRESENT' || predicate === 'IS_BLANK' || predicate === 'EXISTS' || predicate === 'DOES_NOT_EXIST'
    );
  }, [predicate]);

  const selectedStates = useMemo(() => {
    if (condition.key === 'state' || condition.key === 'donor_state') {
      const states = condition?.value?.map(state => {
        return US_STATES_FULL_NAME.find(y => y.value === state?.value || y.value === state);
      });
      return states;
    }
  }, [condition.key, condition.value]);

  const selectedPageType = useMemo(() => {
    if (Array.isArray(condition.value)) {
      const pageTypes = condition?.value?.map(type => {
        return PAGE_TYPES_CONDITIONS.find(y => y.value === type?.value || y.value === type);
      });
      return pageTypes;
    } else {
      return PAGE_TYPES_CONDITIONS?.find(pageType => pageType?.value === condition.value);
    }
  }, [condition.value]);

  const selectedBinaryOption = useMemo(() => {
    const availableOptions = conditionValueOptionsMap[condition?.key]?.options;
    return availableOptions?.find(
      opt => opt.value === condition.value || (condition?.value && opt.value === condition?.value[0])
    );
  }, [condition.key, condition.value]);

  const selectedCustomOption = useMemo(() => {
    if (condition.key === 'postal_code' || condition.key === 'zip_code') {
      return null;
    }
    const availableOptions = customValueOptions[condition?.key];
    if (isMultiSelect) {
      const selectedOptions = condition.value?.map(conditionValue => {
        const parsedValue = typeof conditionValue === 'object' ? conditionValue.value : conditionValue;
        const option = availableOptions?.find(opt => opt.value === parsedValue);
        return option
          ? option.label.length > 25
            ? { ...option, label: option.label.substring(0, 25) + '...' }
            : { ...option, label: option.label }
          : null;
      });
      return selectedOptions;
    } else {
      const option = availableOptions?.find(
        opt => opt.value === condition.value || (condition?.value && opt.value === condition?.value[0])
      );
      return option
        ? option.label.length > 25
          ? { ...option, label: option.label.substring(0, 25) + '...' }
          : { ...option, label: option.label }
        : null;
    }
  }, [condition.key, condition.value, customValueOptions, isMultiSelect]);

  if (shouldHideInput) return <div></div>;

  const placeholder = 'Value';

  return condition?.key === 'state' || condition?.key === 'donor_state' ? (
    <WinRedSelect
      isMulti={true}
      options={US_STATES_FULL_NAME}
      onChange={onChange}
      name={name}
      value={selectedStates || ''}
      placeholder={condition.value?.length > 0 ? null : placeholder}
      validators={validators}
      disabled={disabledStep}
    />
  ) : condition?.key === 'page_type' ? (
    <WinRedSelect
      isMulti={!!isMultiSelect}
      options={PAGE_TYPES_CONDITIONS}
      onChange={onChange}
      name={name}
      value={selectedPageType || ''}
      placeholder={condition.value?.length > 0 ? null : placeholder}
      validators={validators}
      disabled={disabledStep}
    />
  ) : condition?.key === 'base_page_id' ? (
    <WinRedLazySelect
      isMulti={!!isMultiSelect}
      value={selectedCampaign}
      name={name}
      placeholder={
        (condition?.predicate === 'ONE_OF' && condition.value?.length) ||
        (condition?.predicate === 'NONE_OF' && condition.value?.length)
          ? ''
          : placeholder
      }
      onChange={onChange}
      baseVariables={variables}
      query={campaignSearchQuery}
      formatLoadedOptions={formatCampaigns}
      disabled={disabledStep}
      validators={validators}
    />
  ) : isBinaryOption ? (
    <WinRedSelect
      options={conditionValueOptionsMap[condition?.key]?.options}
      onChange={onChange}
      name={name}
      value={selectedBinaryOption || []}
      placeholder={placeholder}
      validators={validators}
      disabled={disabledStep}
    />
  ) : isCustomOption ? (
    <WinRedSelect
      options={customValueOptions[condition?.key]}
      onChange={onChange}
      isMulti={!!isMultiSelect}
      name={name}
      value={selectedCustomOption || ''}
      placeholder={
        (condition?.predicate === 'ONE_OF' && condition.value?.length) ||
        (condition?.predicate === 'NONE_OF' && condition.value?.length)
          ? null
          : placeholder
      }
      validators={validators}
      disabled={disabledStep}
    />
  ) : condition?.key === 'random_split' ? (
    <WinRedInputNumbers
      onChange={onChange}
      name={name}
      value={condition?.value || ''}
      placeholder={placeholder}
      validators={validators}
      type="number"
      disabled={disabledStep}
      max={100}
      removeDecimalPoint={true}
    />
  ) : condition?.key !== 'postal_code' && condition?.key !== 'zip_code' && isMultiSelect ? (
    <WinRedSelect
      options={customValueOptions[condition?.key]}
      onChange={onChange}
      name={name}
      value={selectedCustomOption || ''}
      placeholder={placeholder}
      validators={validators}
      isMulti={true}
      disabled={disabledStep}
    />
  ) : condition?.key === 'session_total_amount' || condition?.key === 'email_hpc' ? (
    <WinRedInputNumbers
      onChange={onChange}
      name={name}
      value={condition?.value || ''}
      placeholder={placeholder}
      validators={validators}
      type="number"
      disabled={disabledStep}
      removeDecimalPoint={true}
    />
  ) : condition.key === 'postal_code' || condition.key === 'zip_code' ? (
    <WinRedInputNumbers
      onChange={onChange}
      name={name}
      value={condition?.value || ''}
      placeholder={placeholder}
      validators={validators}
      type={condition.key === 'email_lifetime' ? 'number' : undefined}
      disabled={disabledStep}
      removeDecimalPoint={true}
      csvZipCodeValues={true}
    />
  ) : (
    <WinRedInput
      onChange={onChange}
      name={name}
      value={condition?.value || ''}
      placeholder={placeholder}
      validators={validators}
      type={condition.key === 'email_lifetime' ? 'number' : undefined}
      disabled={disabledStep}
    />
  );
}

StepValueInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  condition: PropTypes.object.isRequired,
  predicate: PropTypes.string.isRequired,
  campaignsList: PropTypes.array,
  availableCustomConditions: PropTypes.object,
  disabledStep: PropTypes.bool,
};
