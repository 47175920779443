import uuid from 'core/utilities/uuid';

// seperate slug prefix and slug or generate slug prefix if no slug exists
export function extractSlug(page) {
  if (page.slug) {
    let [slugPrefix, ...slug] = page.slug.split('-');
    if (!slugPrefix) slugPrefix = `${uuid().split('-')[0]}-`;
    page.slugPrefix = `${slugPrefix}-`;
    page.slug = slug.join('-');
  } else {
    page.slugPrefix = `${uuid().split('-')[0]}-`;
  }

  return page;
}
