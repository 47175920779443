import React from 'react';
import PropTypes from 'prop-types';

import { FormCard, Typography, Icon } from 'modules/common';

function CommitteeConnectBillPayOnly({ committee }) {
  return (
    <FormCard variant="green" className="mt-0" classNameBody="pb-3">
      <Typography variant="h4" className="font-medium text-winred-blue-charcoal mb-3">
        Bill-Pay Only
      </Typography>
      <Typography variant="bodyMedium" className="text-winred-blue-charcoal mb-2">
        What {committee?.name} will have access to.
      </Typography>

      <ul className="text-winred-green font-medium leading-loose ml-3 mb-2">
        <li>
          <Icon color="green" icon="check" className="mr-3" size="1x" />
          Can be added to Pages and Upsells as a Bill-Pay
        </li>
        <li>
          <Icon color="green" icon="check" className="mr-3" size="1x" />
          Can view Page and Upsells where their Bill-Pay is attached
        </li>
        <li>
          <Icon color="green" icon="check" className="mr-3" size="1x" />
          Can view and export data where their Bill-Pay is attached
        </li>
      </ul>

      <Typography variant="bodyMedium" className="text-winred-blue-charcoal mb-2">
        What {committee?.name} will not have access to.
      </Typography>
      <ul className="text-winred-red font-medium leading-loose ml-3">
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Can create and edit Pages and Upsells
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Access to all Donor, Donation & Lead Data
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Access to the Accounting Tab & Payout Reports
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Add and remove users
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Edit Organizational Settings
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Build & download reports
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Set up emails, notifications, and third party integrations
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Use the Bulk Updater tool
        </li>
        <li>
          <Icon color="red" icon="times" className="mr-3" size="1x" />
          Activate storefront and create products
        </li>
      </ul>
    </FormCard>
  );
}

CommitteeConnectBillPayOnly.propTypes = {
  committee: PropTypes.object.isRequired,
};

export { CommitteeConnectBillPayOnly };
