import React from 'react';
import PropTypes from 'prop-types';
import { FlexBinaryChoiceRow, FormCardSpaced, Input, FloatGroup } from 'modules/common';

function RandomizeCards({ editedUpsell, onUpdateEditedUpsell }) {
  return (
    <FormCardSpaced
      footer={
        editedUpsell.randomized_sort ? (
          <FloatGroup>
            <Input
              type="text"
              max={editedUpsell.cards?.length ?? 0}
              numbersOnly
              name="locked_cards"
              disabled={editedUpsell._isDisabled}
              description="Prevent cards from being randomized. For example, by entering 3, the page will lock the first 3 cards to the top of the page."
              placeholder="Locked Cards"
              value={editedUpsell.locked_cards || ''}
              onChange={onUpdateEditedUpsell}
            />
          </FloatGroup>
        ) : null
      }
    >
      <FlexBinaryChoiceRow
        name="randomized_sort"
        title="Randomize Cards"
        subTitle="When enabled, the page will randomize the order of the cards."
        value={editedUpsell.randomized_sort || false}
        onChange={onUpdateEditedUpsell}
        disabled={editedUpsell._isDisabled || !editedUpsell.cards?.length}
      />
    </FormCardSpaced>
  );
}

RandomizeCards.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default RandomizeCards;
