const BILL_PAY_ACTIVITY_TYPES = {
  RECURRING_BILL_PAY_ENABLED: {
    displayName: 'Recurring Bill-Pay Enabled',
    valueFormat: null,
    hasLink: true,
  },
  RECURRING_BILL_PAY_DISABLED: {
    displayName: 'Recurring Bill-Pay Disabled',
    valueFormat: null,
    hasLink: true,
  },
  UPSELL_BILL_PAY_DISABLED: {
    displayName: 'Upsell Bill-Pay Disabled',
    valueFormat: null,
    hasLink: true,
  },
  UPSELL_BILL_PAY_ENABLED: {
    displayName: 'Upsell Bill-Pay Enabled',
    valueFormat: null,
    hasLink: true,
  },
  BILL_PAY_FEE_ADDED: {
    displayName: 'Bill-Pay Fee Added',
    valueFormat: 'percent',
    hasLink: true,
  },
  BILL_PAY_FEE_REMOVED: {
    displayName: 'Bill-Pay Fee Removed',
    valueFormat: 'percent',
    hasLink: true,
  },
  BILL_PAY_FEE_UPDATED: {
    displayName: 'Bill-Pay Fee Updated',
    valueFormat: 'percent',
    hasLink: true,
  },
  VENDOR_BILL_PAY_CONNECTED: {
    displayName: 'Vendor Bill-Pay Connected',
    valueFormat: 'percent',
  },
  VENDOR_BILL_PAY_DISCONNECTED: {
    displayName: 'Vendor Bill-Pay Disconnected',
    valueFormat: 'percent',
  },
  VENDOR_DEFAULT_FEE_CHANGED: {
    displayName: 'Vendor Default Fee Changed',
    valueFormat: 'percent',
  },
};

const UPSELL_BILL_PAY_INFO = {
  slate: [
    'For Bill-Pay fees to take effect, "Upsell Bill-Pay" must be enabled on the donation page via the "Committees" tab.',
  ],
  double: [
    'For Bill-Pay fees to take effect, "Upsell Bill-Pay" must be enabled on the donation page via the "Committees" tab.',
    'The Bill-Pay fees for this Double Donation Upsell are set on the donation page via the "Committees" tab.',
  ],
  panel: [
    'For any Donation Upsell card or Double Donation card, in order for Bill-Pay fees to take effect, "Upsell Bill-Pay" must be enabled on the donation page via the "Committees" tab.',
    'For any Double Donation card, the Bill-Pay fee percentages are set on the donation page via the "Committees" tab.',
    'For any Recurring Donation card, in order for Bill-Pay fees to take effect, "Subscription Bill-Pay" must be enabled on the donation page via the "Committees" tab.',
  ],
};

export { BILL_PAY_ACTIVITY_TYPES, UPSELL_BILL_PAY_INFO };
