import React from 'react';
import PropTypes from 'prop-types';

import { FloatGroup, Input } from 'modules/common';

function WidgetMidnight({ widget, updateWidget, disabled = false }) {
  return (
    <>
      <FloatGroup>
        <Input
          type="text"
          name="countdownToMidnightLabel"
          placeholder="Countdown to Midnight Label"
          value={widget.countdownToMidnightLabel}
          maxLength="54"
          onChange={updateWidget}
          disabled={disabled}
          description="Add a donor-facing callout above your countdown."
        />
      </FloatGroup>
    </>
  );
}

WidgetMidnight.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetMidnight;
