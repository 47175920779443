import gql from 'graphql-tag';

export const CREATE_CANDIDATE_BANK_ACCOUNT = gql`
  mutation createCandidateBankAccount($bankAccount: BankAccountInput!, $organizationRevvUid: String) {
    createCandidateBankAccount(bankAccount: $bankAccount, organizationRevvUid: $organizationRevvUid) {
      errors
      bankAccount {
        revv_uid
        account_holder_name
        routing_number
        last4
        account_holder_type
        bank_name
        status
        currency
        country
        default_for_currency
      }
    }
  }
`;

export const DEFAULT_CANDIDATE_BANK_ACCOUNT = gql`
  mutation defaultCandidateBankAccount($revv_uid: String!, $organizationRevvUid: String) {
    defaultCandidateBankAccount(revvUid: $revv_uid, organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
    }
  }
`;

export const REMOVE_CANDIDATE_BANK_ACCOUNT = gql`
  mutation removeCandidateBankAccount($revv_uid: String!, $organizationRevvUid: String) {
    removeCandidateBankAccount(revvUid: $revv_uid, organizationRevvUid: $organizationRevvUid) {
      errors
      status
      dammed
    }
  }
`;

export const TRIGGER_PAYOUT_MUTATION = gql`
  mutation triggerPayout($organizationRevvUid: String) {
    triggerPayout(organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
`;
