import { createSelector } from 'reselect';

const vendorsSelector = state => state.vendors;

const billpayPagesFilterSelector = createSelector(vendorsSelector, pages => pages.billpayPagesFilter);

const billpayUpsellsFilterSelector = createSelector(vendorsSelector, pages => pages.billpayUpsellsFilter);

const dashboardFilterSelector = createSelector(vendorsSelector, pages => pages.dashboardFilter);

const transfersFilterSelector = createSelector(vendorsSelector, pages => pages.transfersFilter);

const customReportsFilterSelector = createSelector(vendorsSelector, vendors => vendors.customReportsFilter);

export {
  dashboardFilterSelector,
  billpayUpsellsFilterSelector,
  billpayPagesFilterSelector,
  transfersFilterSelector,
  vendorsSelector,
  customReportsFilterSelector,
};
