import { combineReducers } from 'redux';

import { toastReducer } from 'core/toast';
import { loginReducer } from 'core/login';

import { dashboardReducer } from 'modules/dashboard/store';
import { pagesReducer } from 'modules/pages/store';
import { peopleReducer } from 'modules/people/store';
import { revenueReducer } from 'modules/revenue/store';
import { accountingReducer } from 'modules/accounting/store';
import { utilitiesReducer } from 'modules/utilities/store';
import { storefrontReducer } from 'modules/storefront/store';
import { vendorsReducer } from 'modules/vendors/store';

export const createRootReducer = history => (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;
  }

  const appReducer = combineReducers({
    toast: toastReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    pages: pagesReducer,
    people: peopleReducer,
    revenue: revenueReducer,
    accounting: accountingReducer,
    utilities: utilitiesReducer,
    storefront: storefrontReducer,
    vendors: vendorsReducer,
  });

  return appReducer(state, action);
};
