import { SET_ORDERS_FILTER, SET_PRODUCTS_FILTER } from './types';

export const setOrdersFilter = filter => ({
  type: SET_ORDERS_FILTER,
  filter,
});

export const setProductsFilter = filter => ({
  type: SET_PRODUCTS_FILTER,
  filter,
});
