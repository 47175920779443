import React from 'react';
import PropTypes from 'prop-types';

import { Slider } from 'modules/common';

function WidgetMultiplier({ widget, updateWidget, disabled = false }) {
  return (
    <>
      <Slider
        title="Multiplier"
        name="multiplierFactor"
        onChange={updateWidget}
        min={1}
        max={15}
        step={0.5}
        disabled={disabled}
        value={widget.multiplierFactor}
        description="Select a number to multiply the donation amount by."
      />
    </>
  );
}

WidgetMultiplier.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetMultiplier;
