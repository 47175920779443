import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Button, ConfirmModal } from 'modules/common';

function UpsellFooter({
  selectedUpsellComponent,
  onConfirmCloseEdit,
  onSaveEditedUpsell,
  editedUpsell,
  originalEditedUpsell,
  validationBlock,
  disabledUpsellFlow = false,
}) {
  const disabled = disabledUpsellFlow || editedUpsell?._isDisabled;

  const [showCloseClose, setShowCloseModal] = useState(false);
  const [showSaveModal, setSaveModal] = useState(false);

  if (!selectedUpsellComponent) return null;
  const closeModal = () => setShowCloseModal(false);
  const closeSaveModal = () => setSaveModal(false);

  const abandonChanges = () => {
    setShowCloseModal(false);
    onConfirmCloseEdit();
  };

  const disableAbandonChanges = (!editedUpsell._isNew && isEqual(editedUpsell, originalEditedUpsell)) || disabled;

  return (
    <Container className="new-upsell-footer">
      <Row>
        <Col className="d-flex">
          <Button
            variant="outline-primary"
            className="mr-2"
            onClick={() => {
              // if flag set then close upsell edit immediately else ask if user is sure
              if ((editedUpsell._meta && editedUpsell._meta.ignoreAbandon) || disableAbandonChanges) {
                abandonChanges();
              } else {
                setShowCloseModal(true);
              }
            }}
          >
            Back
          </Button>
          {disabled ? null : (
            <Button
              variant="success"
              className="mr-2"
              onClick={() => {
                if (validationBlock?.current.errors()) return;

                // if the upsell is a part of other pages we need to confirm before saving
                if (editedUpsell.upsell_group_count && editedUpsell.upsell_group_count > 1) return setSaveModal(true);
                setSaveModal(false);
                onSaveEditedUpsell();
              }}
            >
              Save
            </Button>
          )}

          {!editedUpsell.upsell_preview_url ? null : (
            <Button variant="primary" onClick={() => window.open(editedUpsell.upsell_preview_url)}>
              Preview
            </Button>
          )}
        </Col>
      </Row>

      <ConfirmModal
        show={showCloseClose}
        title="Abandon Changes"
        buttonText="Abandon"
        showCancelButton
        cancelButtonColor="primary"
        handleClose={closeModal}
        buttonColor="error"
        onCancel={closeModal}
        onSubmit={abandonChanges}
      >
        Are you sure you want to abandon your changes?
      </ConfirmModal>

      <ConfirmModal
        show={showSaveModal}
        title="Saving Upsell"
        buttonText="Save"
        showCancelButton
        handleClose={closeSaveModal}
        onCancel={closeSaveModal}
        onSubmit={() => {
          setSaveModal(false);
          onSaveEditedUpsell();
        }}
      >
        This upsell has been added to other upsell flows. Editing this upsell will also edit it for all other upsell
        flows it is added to.
      </ConfirmModal>
    </Container>
  );
}

UpsellFooter.propTypes = {
  selectedUpsellComponent: PropTypes.any,
  editedUpsell: PropTypes.object,
  validationBlock: PropTypes.any,
  originalEditedUpsell: PropTypes.object,
  onConfirmCloseEdit: PropTypes.func.isRequired,
  onSaveEditedUpsell: PropTypes.func.isRequired,
  disabledUpsellFlow: PropTypes.bool,
};

export default UpsellFooter;
