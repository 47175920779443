import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { noop } from 'core/utilities';
import { Icon } from 'modules/common';
import './index.scss';

const Toggle = ({ name, value, onChange, disabled, ...restProps }) => {
  const onClick = e => {
    const event = e;
    event.target = { name, value: !value };
    onChange(event);
  };

  return (
    <Icon
      icon="toggle-on"
      size="2x"
      transform="grow-4"
      onClick={disabled ? noop : onClick}
      color={disabled ? 'lightGreen' : value ? 'green' : 'gray'}
      className={classNames({ 'toggle-off': !value, pointer: !disabled })}
      {...restProps}
    />
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
Toggle.defaultProps = {
  disabled: false,
};

export default Toggle;
