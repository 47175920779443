import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from 'modules/common';

function Merchandise(props) {
  return (
    <FormCard title="Merchandise Upsells are not editable using WinRed’s Committee Portal. Use the WinRed Power Portal instead."></FormCard>
  );
}

Merchandise.title = 'Merchandise';
Merchandise.description = '';
Merchandise.icon = 'upsell-merchandise';
Merchandise.type = 'MERCHANDISE';
Merchandise.widgets = [];

Merchandise.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default Merchandise;
