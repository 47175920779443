import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, FloatGroup, Typography, Card } from 'modules/common';
import RequiredLabel from './required.component';

function CustomFieldTextPreview({ formState, isNumberField, textArea, isHiddenField, disabled }) {
  const [value, setValue] = useState(formState.default_value || '');
  useEffect(() => setValue(formState.default_value || ''), [formState.default_value]);

  if (isHiddenField) {
    return <Typography>You can&apos;t see a preview because the field is hidden.</Typography>;
  }

  return (
    <div>
      <Card.Title className="preview-title">
        {formState.label}
        <RequiredLabel formState={formState} />
      </Card.Title>

      <FloatGroup>
        {textArea ? (
          <Input
            as="textarea"
            id="value"
            name="value"
            rows="10"
            disabled={disabled}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={formState.placeholder || ''}
            value={value}
            maxLength="255"
            onChange={e => setValue(e.target.value)}
          />
        ) : (
          <Input
            name="placeholder"
            autoComplete="off"
            disabled={disabled}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={formState.placeholder || ''}
            value={value}
            maxLength="255"
            numbersOnly={isNumberField}
            onChange={e => {
              const value = e.target.value || '';
              setValue(value);
            }}
          />
        )}
      </FloatGroup>
    </div>
  );
}

CustomFieldTextPreview.propTypes = {
  formState: PropTypes.object.isRequired,
  isNumberField: PropTypes.bool,
  textArea: PropTypes.bool,
  isHiddenField: PropTypes.bool,
};

CustomFieldTextPreview.defaultProps = {
  isNumberField: false,
  textArea: false,
  isHiddenField: false,
};

export default CustomFieldTextPreview;
