import gql from 'graphql-tag';

export const GET_DASHBOARD_ORIGIN_REPORTS = gql`
  query getDashBoardRecipientReports(
    $page: Int
    $limit: Int
    $dateRangeEnd: String
    $dateRangeStart: String
    $revvUids: [String!]
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        origins {
          name
          revv_uid
        }
        origin_report(
          filters: {
            search: $search
            page: $page
            limit: $limit
            dateRangeEnd: $dateRangeEnd
            dateRangeStart: $dateRangeStart
            sourceRevvUids: $revvUids
          }
        ) {
          gross_amount
          total_count
          page_count
          current_page
          results {
            amount_donors
            raised_amount
            average_gift
            name
            revv_uid
            avatar {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_RECIPIENT_REPORTS = gql`
  query getDashBoardOriginReports(
    $page: Int
    $limit: Int
    $dateRangeEnd: String
    $dateRangeStart: String
    $revvUids: [String!]
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        recipients {
          name
          revv_uid
        }
        recipient_report(
          filters: {
            page: $page
            search: $search
            limit: $limit
            dateRangeEnd: $dateRangeEnd
            dateRangeStart: $dateRangeStart
            recipientRevvUids: $revvUids
          }
        ) {
          gross_amount
          current_page
          page_count
          total_count
          results {
            name
            amount_donors
            raised_amount
            average_gift
            revv_uid
            avatar {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_ORIGIN_REPORTS_REPORT = gql`
  query getDashBoardRecipientReportsReport(
    $page: Int
    $limit: Int
    $dateRangeEnd: String
    $dateRangeStart: String
    $revvUids: [String!]
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_origin_report(
          filters: {
            page: $page
            search: $search
            limit: $limit
            dateRangeEnd: $dateRangeEnd
            dateRangeStart: $dateRangeStart
            revvUids: $revvUids
          }
        )
      }
    }
  }
`;

export const GET_DASHBOARD_RECIPIENT_REPORTS_REPORT = gql`
  query getDashBoardOriginReportsReport(
    $page: Int
    $limit: Int
    $dateRangeEnd: String
    $dateRangeStart: String
    $revvUids: [String!]
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_recipient_report(
          filters: {
            page: $page
            search: $search
            limit: $limit
            dateRangeEnd: $dateRangeEnd
            dateRangeStart: $dateRangeStart
            recipientRevvUids: $revvUids
          }
        )
      }
    }
  }
`;
