import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getRefreshDateTime } from 'core/utilities';
import { Button } from 'modules/common';
import withAmplitude from './tools/amplitude.component';
import './refresh-data.scss';

function RefreshData({ onRefresh, loading, renderedAt, dataType, logEvent }) {
  const [time, setTime] = useState(loading ? null : renderedAt);

  useEffect(() => {
    if (!loading) setTime(renderedAt);
  }, [loading, setTime, renderedAt]);

  const _onRefresh = () => {
    logEvent(`manual refresh ${dataType} index`);
    onRefresh();
  };

  return (
    <div className="refresh-data">
      <Button variant="alt" onClick={_onRefresh} disabled={loading}>
        <span>Refresh</span>
      </Button>
      <div>{time ? getRefreshDateTime(time) : null}</div>
    </div>
  );
}

RefreshData.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  logEvent: PropTypes.func.isRequired,
  dataType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  renderedAt: PropTypes.instanceOf(Date),
};
export default withAmplitude(RefreshData);
