import { formatImageForServer } from 'modules/common';
import { formatDateUTC, getCandidateDate } from 'core/utilities/time';
import WidgetMultiplier from './widgetTypes/multiplier.component';
import WidgetPreviewMultiplier from './widgetTypes/previews/multiplier.component';
import WidgetShotclock from './widgetTypes/shotclock.component';
import WidgetPreviewShotclock from './widgetTypes/previews/shotclock.component';
import WidgetCountdown from './widgetTypes/countdown.component';
import WidgetPreviewCountdown from './widgetTypes/previews/countdown.component';
import WidgetSocial from './widgetTypes/social.component';
import WidgetPreviewSocial from './widgetTypes/previews/social.component';
import WidgetMidnight from './widgetTypes/midnight.component';
import WidgetPreviewMidnight from './widgetTypes/previews/midnight.component';
import WidgetPopup from './widgetTypes/popup.component';
import WidgetPreviewPopup from './widgetTypes/previews/popup.component';
import WidgetDonation from './widgetTypes/donation.component';
import WidgetPreviewDonation from './widgetTypes/previews/donation.component';
import WidgetAmountAnimation from './widgetTypes/amount-animation.component';
import WidgetPreviewAmountAnimation from './widgetTypes/previews/amount-animation.component';
import WidgetAcceptAnimation from './widgetTypes/accept-animation.component';
import WidgetPreviewAcceptAnimation from './widgetTypes/previews/accept-animation.component';
import WidgetThermometer from './widgetTypes/thermometer.component';
import WidgetPreviewThermometer from './widgetTypes/previews/thermometer.component';
import WidgetConfettiAnimation from './widgetTypes/confetti-animation.component';
import WidgetConfettiAnimationPreview from './widgetTypes/previews/confetti-animation.component';
import { getWidgetId } from './widgetTypes/thermometer/tools';
import WidgetCustomFieldResults from './widgetTypes/custom-fields-results.component';
import WidgetPreviewCustomFieldsResults from './widgetTypes/previews/custom-fields-results.component';
import { formatBlackDefaultTextColor } from '../form/tools';

export const WIDGETS_MAP = {
  MULTIPLIER: {
    label: 'Multiplier',
    value: 'MULTIPLIER',
    component: WidgetMultiplier,
    previewComponent: WidgetPreviewMultiplier,
  },
  EXITINTENTPOPUP: {
    label: 'Exit Intent Popup',
    value: 'EXITINTENTPOPUP',
    component: WidgetPopup,
    previewComponent: WidgetPreviewPopup,
  },
  SHOTCLOCK: {
    label: 'Shotclock',
    value: 'SHOTCLOCK',
    component: WidgetShotclock,
    previewComponent: WidgetPreviewShotclock,
  },
  COUNTDOWN: {
    label: 'Countdown',
    value: 'COUNTDOWN',
    component: WidgetCountdown,
    previewComponent: WidgetPreviewCountdown,
  },
  COUNTDOWNTOMIDNIGHT: {
    label: 'Countdown to Midnight',
    value: 'COUNTDOWNTOMIDNIGHT',
    component: WidgetMidnight,
    previewComponent: WidgetPreviewMidnight,
  },
  DONATIONSTREAM: {
    label: 'Donation Stream',
    value: 'DONATIONSTREAM',
    component: WidgetDonation,
    previewComponent: WidgetPreviewDonation,
  },
  THERMOMETER: {
    label: 'Thermometer',
    value: 'THERMOMETER',
    component: WidgetThermometer,
    previewComponent: WidgetPreviewThermometer,
  },
  SOCIALLINKS: {
    label: 'Social Links',
    value: 'SOCIALLINKS',
    component: WidgetSocial,
    previewComponent: WidgetPreviewSocial,
  },
  AMOUNTBUTTONANIMATION: {
    label: 'Amount Button Animation',
    value: 'AMOUNTBUTTONANIMATION',
    component: WidgetAmountAnimation,
    previewComponent: WidgetPreviewAmountAnimation,
  },
  ACCEPTBUTTONANIMATION: {
    label: 'Accept Button Animation',
    value: 'ACCEPTBUTTONANIMATION',
    component: WidgetAcceptAnimation,
    previewComponent: WidgetPreviewAcceptAnimation,
  },
  CONFETTI: {
    label: 'Confetti Animation',
    value: 'CONFETTI',
    component: WidgetConfettiAnimation,
    previewComponent: WidgetConfettiAnimationPreview,
  },
  POLLRESULTS: {
    label: 'Custom Fields Results',
    value: 'POLLRESULTS',
    component: WidgetCustomFieldResults,
    previewComponent: WidgetPreviewCustomFieldsResults,
  },
};

export const WIDGETS = [
  { label: WIDGETS_MAP.MULTIPLIER.label, value: WIDGETS_MAP.MULTIPLIER.value },
  { label: WIDGETS_MAP.EXITINTENTPOPUP.label, value: WIDGETS_MAP.EXITINTENTPOPUP.value },
  { label: WIDGETS_MAP.AMOUNTBUTTONANIMATION.label, value: WIDGETS_MAP.AMOUNTBUTTONANIMATION.value },
  { label: WIDGETS_MAP.ACCEPTBUTTONANIMATION.label, value: WIDGETS_MAP.ACCEPTBUTTONANIMATION.value },
  { label: WIDGETS_MAP.SHOTCLOCK.label, value: WIDGETS_MAP.SHOTCLOCK.value },
  { label: WIDGETS_MAP.COUNTDOWN.label, value: WIDGETS_MAP.COUNTDOWN.value },
  { label: WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.label, value: WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value },
  { label: WIDGETS_MAP.DONATIONSTREAM.label, value: WIDGETS_MAP.DONATIONSTREAM.value },
  { label: WIDGETS_MAP.THERMOMETER.label, value: WIDGETS_MAP.THERMOMETER.value },
  { label: WIDGETS_MAP.SOCIALLINKS.label, value: WIDGETS_MAP.SOCIALLINKS.value },
  { label: WIDGETS_MAP.CONFETTI.label, value: WIDGETS_MAP.CONFETTI.value },
  { label: WIDGETS_MAP.POLLRESULTS.label, value: WIDGETS_MAP.POLLRESULTS.value },
];

export const onlyOneWidgetAllowed = [
  WIDGETS_MAP.MULTIPLIER.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.THERMOMETER.value,
];

export const petitionPageWidgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.THERMOMETER.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

export const conduitPageWidgets = [
  WIDGETS_MAP.MULTIPLIER.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.DONATIONSTREAM.value,
  WIDGETS_MAP.AMOUNTBUTTONANIMATION.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.THERMOMETER.value,
  WIDGETS_MAP.CONFETTI.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

export const storefrontWidgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
];

export const SPEED_OPTIONS = [
  { label: 'Slow', value: 60, speed: 0.03 },
  { label: 'Medium', value: 45, speed: 0.06 },
  { label: 'Fast', value: 30, speed: 0.12 },
  { label: 'Very Fast', value: 15, speed: 0.2 },
];

export const DEFAULT_CUSTOM_POLL_RESULTS = {
  pollResultsLabel: '',
  pollResultsPageId: null,
  pollResultsPage: null,
  pollResultsColor: '#426488',
  pollResultsDisplayType: 'PERCENTAGE',
  pollResultsCustomFieldId: null,
  pollResultsCustomField: null,
  pollResultsOtherFieldResults: false,
  pollResultsStats: null,
  pollResultsBoostValues: '',
  boostPollResults: false,
  customFieldResultsBoostValues: null,
};

export const CUSTOM_POLL_RESULTS_TYPE_MAP = {
  MULTIPLE_CHOICE: 'Multiple Choice',
  RADIO_BUTTONS: 'Radio Buttons',
  CHECK_BOX: 'Check Box',
};

export const generateNewWidget = widgets => {
  return {
    id: getWidgetId(widgets),
    type: null,

    _name: 'New Widget',
    _isNew: true,
    _destroy: false,
    _isCardOpen: true,
  };
};

export const getWidgetDefaults = ({ inputType, isPetitionPage, secondaryColor, maxPersonalDonation }) => {
  const maxDonationAmount = maxPersonalDonation;

  switch (inputType) {
    case WIDGETS_MAP.MULTIPLIER.value:
      return { multiplierFactor: 1 };
    case WIDGETS_MAP.SHOTCLOCK.value:
      return { shotclockLength: 1, shotclockLabel: `${isPetitionPage ? 'Sign Up' : 'Donate'} Now!` };
    case WIDGETS_MAP.COUNTDOWN.value:
      return { countdownEnd: '', countdownLabel: `${isPetitionPage ? 'Sign Up' : 'Donate'} Today!` };
    case WIDGETS_MAP.THERMOMETER.value:
      return initialThermometerFields(isPetitionPage);
    case WIDGETS_MAP.SOCIALLINKS.value:
      return { facebookHandle: '', twitterHandle: '' };
    case WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value:
      return { countdownToMidnightLabel: `${isPetitionPage ? 'Sign Up' : 'Donate'} Before Midnight!` };
    case WIDGETS_MAP.EXITINTENTPOPUP.value:
      return {
        popupHeaderCopy: 'Wait, before you go...',
        popupBodyCopy: `<p><span style="color: #000000;">We can't win this fight without you, we need your immediate support!</span></p>`,
        popupButtonCopy: isPetitionPage ? 'Complete This Form' : 'Complete My Donation',
        cookieExpiration: '',
        widgetImage: null,
      };
    case WIDGETS_MAP.DONATIONSTREAM.value:
      return {
        donationStreamAnimationDuration: 45,
        donationStreamMinimumDonation: 1,
        donationStreamMaximumDonation: maxDonationAmount,
        donationStreamCustomCopy: '',
        donationStreamDirect: false,
        donationStreamTextColor: '',
        donationStreamBackgroundColor: '',
        donationStreamHighlightColor: '',
      };
    case WIDGETS_MAP.AMOUNTBUTTONANIMATION.value:
      return {
        amountButtonAnimationPosition: 1,
        amountButtonAnimationColor: secondaryColor,
        amountButtonAnimationStyle: 'BOUNCE',
        amountButtonAnimationDelay: 0,
        amountButtonAnimationDuration: 'NORMAL',
        amountButtonAnimationRepeat: false,
        amountButtonAnimationRepeatAfter: 0,
      };
    case WIDGETS_MAP.ACCEPTBUTTONANIMATION.value:
      return {
        acceptButtonAnimationColor: secondaryColor,
        acceptButtonAnimationStyle: 'BOUNCE',
        acceptButtonAnimationDelay: 0,
        acceptButtonAnimationDuration: 'NORMAL',
        acceptButtonAnimationRepeat: false,
        acceptButtonAnimationRepeatAfter: 0,
      };
    case WIDGETS_MAP.CONFETTI.value:
      return {
        confettiAnimationStyle: 'CONFETTI',
        confettiTriggerElement: 'AMOUNT_BUTTON_1',
        confettiColor: '',
        confettiShape: 'DEFAULT',
        confettiUseEmoji: false,
        confettiEmoji: 'ONE_HUNDRED',
        widgetImage: null,
      };
    case WIDGETS_MAP.POLLRESULTS.value:
      return DEFAULT_CUSTOM_POLL_RESULTS;
    default:
      return {};
  }
};

export const widgetComponentSelector = type => (WIDGETS_MAP[type] || {}).component;
export const widgetPreviewComponentSelector = type => (WIDGETS_MAP[type] || {}).previewComponent;

const intBasedProps = [
  'cookieExpiration',
  'donationStreamMinimumDonation',
  'donationStreamMaximumDonation',
  'amountButtonAnimationDelay',
  'amountButtonAnimationRepeatAfter',
  'amountButtonAnimationPosition',
  'acceptButtonAnimationDelay',
  'acceptButtonAnimationRepeatAfter',
];

// set these to zero by default instead of null
const setToZeroProps = [
  'amountButtonAnimationDelay',
  'amountButtonAnimationRepeatAfter',
  'acceptButtonAnimationDelay',
  'acceptButtonAnimationRepeatAfter',
  'cookieExpiration',
];

export const formatWidgetsForServer = widgets => {
  return widgets
    .map(widgetObj => {
      let { _isNew, _destroy, id, _name, ...widget } = widgetObj;

      if (_isNew && _destroy) return null;
      if (!_isNew) widget.id = id;
      if (!_isNew && _destroy) widget.destroy = true;

      // convert any string props to int or null
      intBasedProps.forEach(propKey => {
        if (widget[propKey] && !Number.isNaN(widget[propKey])) {
          widget[propKey] = parseInt(widget[propKey], 10);
        } else {
          widget[propKey] = setToZeroProps.includes(propKey) ? 0 : null;
        }
      });

      widget = formatImageForServer(widget, 'widgetImage');

      if (!widget.boostPollResults) {
        widget.pollResultsBoostValues = '';
      } else {
        widget.pollResultsBoostValues = JSON.stringify(widget.customFieldResultsBoostValues);
      }

      delete widget.__typename;
      delete widget._disabled;
      delete widget._isCardOpen;
      delete widget.pollResultsCustomField;
      delete widget.pollResultsStats;
      delete widget.pollResultsPage;
      delete widget.boostPollResults;
      delete widget.customFieldResultsBoostValues;
      return widget;
    })
    .filter(v => Boolean(v));
};

export const formatWidgetsAndThermometersForServer = page => {
  const thermometerWidgets = { ...page }?.widgets?.filter(widget => widget.type === 'THERMOMETER');
  const hasObjectWithDestroyFalse = thermometerWidgets.some(obj => obj._destroy === false);
  let selectedThermometer = {};
  if (thermometerWidgets.length > 1 && hasObjectWithDestroyFalse) {
    selectedThermometer = thermometerWidgets.filter(widget => widget._destroy === false)[0];
  } else {
    selectedThermometer = thermometerWidgets[0];
  }
  if (selectedThermometer) {
    page.thermometerWidget = {
      revvUid: selectedThermometer.revv_uid,
      label: selectedThermometer.thermometerLabel,
    };
    if (selectedThermometer._destroy) {
      page.thermometerWidget.destroy = true;
    }
  }
  let formattedWidgets = page?.widgets?.filter(widget => widget.type !== 'THERMOMETER');

  page.widgets = formattedWidgets
    ?.map(widgetObj => {
      let { _isNew, _destroy, id, _name, ...widget } = widgetObj;

      if (_isNew && _destroy) return null;
      if (!_isNew) widget.id = id;
      if (!_isNew && _destroy) widget.destroy = true;

      // convert any string props to int or null
      intBasedProps.forEach(propKey => {
        if (widget[propKey] && !Number.isNaN(widget[propKey])) {
          widget[propKey] = parseInt(widget[propKey], 10);
        } else {
          widget[propKey] = setToZeroProps.includes(propKey) ? 0 : null;
        }
      });

      widget = formatImageForServer(widget, 'widgetImage');

      delete widget.__typename;
      delete widget._disabled;
      delete widget._isCardOpen;

      if (!widget.boostPollResults) {
        widget.pollResultsBoostValues = '';
      } else {
        widget.pollResultsBoostValues = JSON.stringify(widget.customFieldResultsBoostValues);
      }

      delete widget.pollResultsPage;
      delete widget.pollResultsShowOtherField;
      delete widget.pollResultsCustomField;
      delete widget.pollResultsStats;
      delete widget.boostPollResults;
      delete widget.customFieldResultsBoostValues;
      return widget;
    })
    .filter(v => Boolean(v));
  return page;
};

export const formatThermometerWidgetForUi = (thermometerCampaign, thermometerWidget, widgets) => {
  const {
    initiative_type,
    statistics,
    thermometer_fields: { goals, page_count, boost_goal, query_date, data_source },
  } = thermometerCampaign;
  const { label, name, revvUid } = thermometerWidget;
  const widget = {
    boost: boost_goal,
    goals,
    id: getWidgetId(widgets),
    internal_name: name,
    isEdit: true,
    isPetitionPage: initiative_type === 'LEADS',
    page_count,
    query_date: query_date ? Date.parse(formatDateUTC(parseInt(query_date * 1000))) : '',
    revv_uid: revvUid,
    statistics,
    thermometerLabel: label ? label : '',
    thermometer_type: initiative_type,
    data_source: data_source || "Pages with this Thermometer",
    type: 'THERMOMETER',
    _destroy: false,
    _name: 'Thermometer',
  };
  return widget;
};

export const formatWidgetsForUi = (widgets, disabled = false) => {
  return widgets.map(widget => {
    return {
      ...widget,
      _name: (WIDGETS_MAP[widget.type] || {}).label,
      _destroy: widget._destroy,
      _disabled: disabled,
      _isCardOpen: false,
      countdownEnd: widget.countdownEnd ? getCandidateDate(widget.countdownEnd) : null,
      acceptButtonAnimationRepeatAfter: widget.acceptButtonAnimationRepeatAfter
        ? widget.acceptButtonAnimationRepeatAfter / 1000
        : null,
      boostPollResults: widget.pollResultsBoostValues ? true : false,
      customFieldResultsBoostValues:
        widget.type === 'POLLRESULTS' ? formatBoostCustomFieldResultsForUi(widget, widget.pollResultsCustomField) : {},
      popupBodyCopy: formatBlackDefaultTextColor(widget.popupBodyCopy),
    };
  });
};

export const formatBoostCustomFieldResultsForUi = (widget, customField) => {
  if (widget?.pollResultsBoostValues?.length > 0) {
    return JSON.parse(widget.pollResultsBoostValues);
  } else {
    //For new widget pull options from custom field
    let pollResultsBoostValues = {};
    let cf = widget?.pollResultsCustomField || customField;
    if (cf && ['MULTIPLE_CHOICE', 'RADIO_BUTTONS'].includes(cf.input_type) && cf.default_value.length > 0) {
      (cf.default_value || '').split(',').map(option => (pollResultsBoostValues[option.trim()] = 0));
      return pollResultsBoostValues;
    }
  }
};

export const initialThermometerFields = (isPetitionPage, onCancel = false, widget) => {
  return {
    // when onCancel is true, preserves widget revv_uid if widget was canceled and then removed
    revv_uid: onCancel ? widget?.revv_uid : '',
    thermometerLabel: '',
    internal_name: '',
    thermometer_type: isPetitionPage ? 'LEADS' : 'REVENUE',
    query_date: new Date(),
    goals: [{ amount: null, _isNew: true }],
    boost: null,
    statistics: {},
    isPetitionPage,
    page_count: null,
    isEdit: false,
    data_source: 'MOST_RECENT_PAGES',
  };
};

export const resetThermometerFields = (isPetitionPage, onCancel = false, widget) => {
  return {
    id: widget.id,
    type: widget.type,
    _destroy: widget._destroy,
    _disabled: widget._disabled,
    _name: widget._name,
    _isCardOpen: widget._isCardOpen,
    // when onCancel is true, preserves widget revv_uid if widget was canceled and then removed
    revv_uid: onCancel ? widget?.revv_uid : '',
    thermometerLabel: '',
    internal_name: '',
    thermometer_type: isPetitionPage ? 'LEADS' : 'REVENUE',
    query_date: new Date(),
    goals: [{ amount_cents: '', _isNew: true }],
    boost: '',
    statistics: {},
    isPetitionPage,
    page_count: null,
    isEdit: false,
    data_source: 'MOST_RECENT_PAGES',
  };
};

export const generateConfettiTriggerDropwdown = page => {
  let newAmountButtons = page?.pageAmounts
    .filter(item => !item?._destroy)
    .map((item, index) => {
      return { label: `$${item.amount} Donation Amount Button`, value: `AMOUNT_BUTTON_${index + 1}` };
    });
  if (page?.recurringAvailable) {
    newAmountButtons = [...newAmountButtons, { label: 'Recurring Checkbox', value: 'RECURRING_BUTTON' }];
  }
  if (page?.donorCoverFees) {
    newAmountButtons = [...newAmountButtons, { label: 'Donors Cover Fee Checkbox', value: 'DONORS_COVER_FEE_BUTTON' }];
  }
  if (page?.moneyPledgeCampaign) {
    newAmountButtons = [...newAmountButtons, { label: `Money Pledge Checkbox`, value: 'MONEY_PLEDGE_BUTTON' }];
  }
  return [...newAmountButtons];
};

export const createPageResultsOptions = data => {
  if (!data?.viewer) return [];

  const candidate = data.viewer.candidate || data.viewer.state_level_organization;
  const pageResultDropdownOptions = candidate.custom_field_pages.results.map(page => ({
    label: page.internalName,
    value: page,
  }));

  return pageResultDropdownOptions;
};
