import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import NavBarSubMenuPages from './submenus/pages.component';
import NavBarSubMenuPeople from './submenus/people.component';
import NavBarSubMenuRevenue from './submenus/revenue.component';
import NavBarSubMenuAccounting from './submenus/accounting.component';
import NavBarSubMenuUtilities from './submenus/utilities.component';
import NavBarSubMenuMerchandise from './submenus/storefront.component';
import NavBarSubMenuBillPays from './submenus/billpays.component';

import '../nav-bar.scss';

function NavBarSubMenus({
  isOrganization,
  isAgency,
  location,
  handleSelect,
  profileCandidate,
  isPagesOnly,
  isReadOnly,
  isMerchOnly,
  isMyPagesOnly,
  approved,
  showPagesSubNav,
  showPeopleSubNav,
  showRevenueSubNav,
  showAccountingSubNav,
  showUtilitiesSubNav,
  showStoreFrontSubNav,
  showBillpaysSubNav,
}) {
  return (
    <>
      <NavBarSubMenuBillPays
        removeAccess={[!isAgency, isMyPagesOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        showBillpaysSubNav={showBillpaysSubNav}
      />

      <NavBarSubMenuPages
        isAgency={isAgency}
        removeAccess={[isReadOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        isOrganization={isOrganization}
        showPagesSubNav={showPagesSubNav}
        isMyPagesOnly={isMyPagesOnly}
      />

      <NavBarSubMenuPeople
        isAgency={isAgency}
        removeAccess={[isPagesOnly, isMyPagesOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        showPeopleSubNav={showPeopleSubNav}
      />

      <NavBarSubMenuRevenue
        isAgency={isAgency}
        removeAccess={[isReadOnly, isPagesOnly, isMyPagesOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        showRevenueSubNav={showRevenueSubNav}
      />

      <NavBarSubMenuAccounting
        isAgency={isAgency}
        removeAccess={[isReadOnly, isPagesOnly, isMyPagesOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        showAccountingSubNav={showAccountingSubNav}
      />

      <NavBarSubMenuUtilities
        isAgency={isAgency}
        removeAccess={[isReadOnly, isPagesOnly, isMyPagesOnly, isMerchOnly]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        isOrganization={isOrganization}
        showUtilitiesSubNav={showUtilitiesSubNav}
        profileCandidate={profileCandidate}
      />

      <NavBarSubMenuMerchandise
        removeAccess={[isReadOnly, isPagesOnly, isMyPagesOnly, isAgency]}
        pathname={location.pathname}
        handleSelect={handleSelect}
        organizationRevvUid={profileCandidate.organization_revv_uid}
        showStoreFrontSubNav={showStoreFrontSubNav}
        approved={approved}
      />
    </>
  );
}

NavBarSubMenus.propTypes = {
  location: PropTypes.object.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  profileCandidate: PropTypes.object.isRequired,
  showPagesSubNav: PropTypes.bool.isRequired,
  showPeopleSubNav: PropTypes.bool.isRequired,
  showRevenueSubNav: PropTypes.bool.isRequired,
  showAccountingSubNav: PropTypes.bool.isRequired,
  showUtilitiesSubNav: PropTypes.bool.isRequired,
  showStoreFrontSubNav: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isPagesOnly: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
};

export default withRouter(NavBarSubMenus);
