import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Typography from 'modules/common/typography.component';
import Icon from 'modules/common/icon.component';

function InputErrorMessage({ errorMessage, isWarning, errorIcon, errorMessageClasses }) {
  if (!errorMessage) return null;

  return (
    <Typography variant={isWarning ? 'warning' : 'error'} className={classnames('mb-0', errorMessageClasses)}>
      {errorMessage && errorIcon && (
        <Icon icon={errorIcon} color={isWarning ? 'gold' : 'error'} className="mr-1"></Icon>
      )}
      {errorMessage}
    </Typography>
  );
}

InputErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  isWarning: PropTypes.bool,
  errorIcon: PropTypes.string,
  errorMessageClasses: PropTypes.string,
};

export default InputErrorMessage;
