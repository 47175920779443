import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import FloatGroup from 'modules/common/form/float-group.component';
import Typography from 'modules/common/typography.component';
import SubText from 'modules/common/sub-text.component';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import { ValidatorComponent } from 'core/validation';
import { formatDate } from 'core/utilities';
import Input from './input.component';
import './index.scss';

class WinRedDatePicker extends ValidatorComponent {
  state = {
    hoverLabel: false,
  };

  onChange = value => {
    const { onChange, name } = this.props;
    this.setState({ hoverLabel: !!value });
    onChange({ target: { name, value } });
  };

  onBlur = () => {
    const { onBlur, value } = this.props;
    this.setState({ hoverLabel: !!value || false });
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  onFocus = () => {
    const { onFocus } = this.props;
    this.setState({ hoverLabel: true });
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  render() {
    const {
      inputType,
      noOptionsMessage,
      className,
      pro,
      errorMessages,
      isClearable,
      options,
      selected,
      placeholder,
      componentRef,
      value,
      valueFormat,
      disabled,
      fullWidth,
      errorMessageClasses,
      errorIcon,
      invalidIcon,
      isWarning,
      title,
      onChange,
      name,
      description,
      ...rest
    } = this.props;

    const { hoverLabel } = this.state;
    const errorMessage = this.errorMessage();

    if (disabled) {
      // show formatted date when we are using a regular input element
      const uiValue = value ? formatDate(value, valueFormat) : '';

      return (
        <>
          {title ? (
            <Typography variant="bodyMedium" className="mb-1">
              {title}
            </Typography>
          ) : null}
          <Input type={inputType} disabled placeholder={placeholder} value={uiValue} description={description} />
        </>
      );
    }

    return (
      <div
        className={classnames({
          'winred-date-picker-full-width': fullWidth,
        })}
      >
        <FloatGroup.Context.Consumer>
          {floatGroup => (
            <>
              {title ? (
                <Typography variant="bodyMedium" className="mb-1">
                  {title}
                </Typography>
              ) : null}

              <DatePicker
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
                placeholder={hoverLabel ? null : placeholder}
                onChange={this.onChange}
                ref={componentRef}
                name={name}
                selected={value}
                popperPlacement="bottom-start"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                className={classnames(
                  'form-control',
                  'winred-input',
                  {
                    'winred-input--pro': pro,
                    'winred-input--active': floatGroup.shouldFloat,
                    'winred-input--error': errorMessage,
                  },
                  className
                )}
                placeholderText={placeholder}
                {...rest}
              />
            </>
          )}
        </FloatGroup.Context.Consumer>

        {description ? <SubText description={description} className={classnames({ 'mb-0': errorMessage })} /> : null}
        <InputErrorMessage
          errorMessageClasses={errorMessageClasses}
          errorMessage={errorMessage}
          errorIcon={errorIcon || invalidIcon}
          isWarning={isWarning}
        />
      </div>
    );
  }
}

WinRedDatePicker.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  valueFormat: PropTypes.string,
  pro: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

WinRedDatePicker.defaultProps = {
  inputType: 'text',
  valueFormat: 'MM/dd/yyyy',
};

export default WinRedDatePicker;
