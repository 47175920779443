import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Navbar from 'react-bootstrap/Navbar';
import './nav-bar.scss';
import { Icon, Typography } from 'modules/common';

export default function NavbarToggle({ open, className, onClick, ...rest }) {
  return (
    <Navbar.Toggle
      onClick={onClick}
      className={classNames(
        'navbar-toggle d-flex justify-content-center align-items-center d-block d-lg-none',
        className
      )}
      aria-controls="main-navbar"
      data-target="#main-navbar"
      {...rest}
    >
      <Typography className="mb-0 mr-1">Menu</Typography>
      <Icon icon={open ? 'caret-up' : 'caret-down'} />
    </Navbar.Toggle>
  );
}

NavbarToggle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
