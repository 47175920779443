import React from 'react';
import WinRedInput from './winred-input.component';
import './inputs.scss';

import SubText from 'modules/common/sub-text.component';

function WinRedFormInput({ description, subDescription, ...rest }) {
  return (
    <div className="mb-4">
      <WinRedInput {...rest}>
        {description ? <SubText description={description} description2={subDescription} /> : null}
      </WinRedInput>
    </div>
  );
}

export default WinRedFormInput;
