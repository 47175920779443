import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { profileCandidateSelector } from 'core/login';

export default function useRouterTabs(tabsList, initialTab) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const profileCandidate = useSelector(profileCandidateSelector);
  const formattedTabsList = useMemo(
    () => tabsList.map(t => ({ ...t, url: `/${profileCandidate.organization_revv_uid}${t.url}` })),
    [profileCandidate, tabsList]
  );

  const [tab, setTab] = useState(formattedTabsList.find(t => t.value === initialTab));

  const getBasePath = useCallback(() => {
    return Object.values(params).reduce(
      (path, param) => path.replace('/' + param, ''),
      location.pathname.replace(/\/$/, '')
    );
  }, [params, location.pathname]);

  const changeTab = useCallback(
    tabName => {
      const newTab = formattedTabsList.find(t => t.value === tabName);
      // Don't change tabs if we are already on the correct tab or can't find the tab.
      if (!newTab || tabName === tab.value) return;
      history.push(newTab.url);
      setTab(newTab);
    },
    [history, formattedTabsList, tab]
  );

  useEffect(() => {
    const basePath = getBasePath();
    const newTab = formattedTabsList.find(t => t.url === basePath);
    if (!newTab || basePath === tab.url) return;
    // Set the tab based on the URL
    setTab(newTab);
  }, [changeTab, getBasePath, formattedTabsList, tab]);

  return [tab, changeTab];
}
