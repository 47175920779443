import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';

import UpsellCard from './upsell-card.component';

import {
  SlateUpsell,
  MobileUpsell,
  CustomUpsell,
  DoubleUpsell,
  PanelUpsell,
  RecurringUpsell,
  CreateUpsell,
  VolunteerUpsell,
  MoneyPledgeUpsell,
  // Deprecated Upsells
  // CommitteeUpsell,
  // SingleUpsell,
  // MultipleUpsell,
} from './new-upsell';

import '../new-upsell.scss';
import { Typography } from 'modules/common';

function DrawerBody({
  setUpsellComponent,
  selectedUpsellComponent,
  editedUpsell,
  onUpdateEditedUpsell,
  upsells = [],
  primaryColor,
  secondaryColor,
  hidePanelUpsell = false,
}) {
  const UpsellComponent = selectedUpsellComponent;
  const _setUpsellComponent = component => setUpsellComponent(component);

  // gray out panel upsells if already exists
  const disableCard = !!(upsells || []).find(
    upsell => upsell.upsell_page?.type === PanelUpsell.type && !upsell?._destroy
  );

  return (
    <Container fluid className="upsell-drawer-content">
      {UpsellComponent && editedUpsell ? (
        <UpsellComponent
          editedUpsell={editedUpsell}
          onUpdateEditedUpsell={onUpdateEditedUpsell}
          upsells={upsells}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      ) : (
        <>
          <div className="mt-5">
            <Typography variant="h5" className="mb-2">
              Immediate Donations
            </Typography>
            <Row>
              <UpsellCard
                title={SlateUpsell.title}
                description={SlateUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={SlateUpsell}
              />
              <UpsellCard
                title={DoubleUpsell.title}
                description={DoubleUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={DoubleUpsell}
              />

              {!hidePanelUpsell && (
                <UpsellCard
                  title={PanelUpsell.title}
                  description={PanelUpsell.description}
                  setUpsellComponent={_setUpsellComponent}
                  component={PanelUpsell}
                  disableCard={disableCard}
                />
              )}
            </Row>
          </div>
          <div className="mt-4">
            <Typography variant="h5" className="mb-2">
              Future Donations
            </Typography>
            <Row>
              <UpsellCard
                title={RecurringUpsell.title}
                description={RecurringUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={RecurringUpsell}
              />

              <UpsellCard
                title={MoneyPledgeUpsell.title}
                description={MoneyPledgeUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={MoneyPledgeUpsell}
              />
            </Row>
          </div>
          <div className="my-4">
            <Typography variant="h5" className="mb-2">
              Non-Donation Actions
            </Typography>
            <Row className="">
              <UpsellCard
                title={CreateUpsell.title}
                description={CreateUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={CreateUpsell}
              />
              <UpsellCard
                title={MobileUpsell.title}
                description={MobileUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={MobileUpsell}
              />
              <UpsellCard
                title={VolunteerUpsell.title}
                description={VolunteerUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={VolunteerUpsell}
              />
              <UpsellCard
                title={CustomUpsell.title}
                description={CustomUpsell.description}
                setUpsellComponent={_setUpsellComponent}
                component={CustomUpsell}
              />
            </Row>
          </div>
        </>
      )}
    </Container>
  );
}

DrawerBody.propTypes = {
  setUpsellComponent: PropTypes.func.isRequired,
  selectedUpsellComponent: PropTypes.any,
  editedUpsell: PropTypes.object,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  upsells: PropTypes.array,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  isOrganization: PropTypes.bool,
  hidePanelUpsell: PropTypes.bool,
};
DrawerBody.defaultProps = {
  upsells: [],
};

export default DrawerBody;
