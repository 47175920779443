import { Col, Row } from 'react-bootstrap';
import { WinRedSelectGrouped } from '../../../../../common/form/inputs/winred-select-grouped.component';
import { Icon, WinRedSelect } from '../../../../../common';
import { StepValueInput } from '../StepValueInput.component';
import React from 'react';
import PropTypes from 'prop-types';

export function StepRequirementsRowDesktop({
  condition,
  _onChangeKey,
  conditionOptions,
  selectedConditionKey,
  _onChangePredicate,
  selectedConditionPredicate,
  predicateOptions,
  _onChangeValue,
  customValueOptions,
  campaignsList,
  fieldValidators,
  _onRemove,
  disabledStep,
}) {
  return (
    <Row>
      <Col>
        <WinRedSelectGrouped
          maxMenuHeight={300}
          onChange={_onChangeKey}
          options={conditionOptions}
          value={selectedConditionKey}
          name="key"
          placeholder="Select Field"
          validators={fieldValidators}
          maxWidthDropDown={true}
          disabled={disabledStep}
        />
      </Col>
      <Col>
        {selectedConditionKey?.value && (
          <WinRedSelect
            key={condition?.key + condition?.predicate + "_predicate_key"}
            onChange={_onChangePredicate}
            disabled={disabledStep}
            name="predicate"
            maxWidthDropDown={false}
            value={selectedConditionPredicate}
            options={predicateOptions[selectedConditionKey.value]}
            placeholder="Select Predicate..."
            validators={[
              {
                message: 'Predicate is required.',
                validator: 'required',
              },
            ]}
          />
        )}
      </Col>
      <Col>
        {selectedConditionPredicate && (
          <StepValueInput
            disabledStep={disabledStep}
            onChange={_onChangeValue}
            name="value"
            requirementField={selectedConditionKey}
            predicate={condition?.predicate}
            condition={condition}
            customValueOptions={customValueOptions}
            campaignsList={campaignsList}
          />
        )}
      </Col>
      <Col sm={1} className="d-flex align-items-center">
        <div onClick={() => _onRemove()} className="cursor-hover-pointer">
          <Icon icon="times"></Icon>
        </div>
      </Col>
    </Row>
  );
}

StepRequirementsRowDesktop.propTypes = {
  condition: PropTypes.object.isRequired,
  _onChangeKey: PropTypes.func.isRequired,
  conditionOptions: PropTypes.array,
  selectedConditionKey: PropTypes.object,
  _onChangePredicate: PropTypes.func.isRequired,
  selectedConditionPredicate: PropTypes.object,
  predicateOptions: PropTypes.object,
  _onChangeValue: PropTypes.func.isRequired,
  customValueOptions: PropTypes.object,
  campaignsList: PropTypes.array,
  fieldValidators: PropTypes.array,
  _onRemove: PropTypes.func.isRequired,
  disabledStep: PropTypes.bool,
};
