import { gql } from 'apollo-boost';

export const GET_ORGANIZATION_IMAGES = gql`
  query getOrganizationImages(
    $organizationRevvUid: String
    $imageType: [ImageTypeEnum!]
    $page: Int
    $limit: Int
    $search: String
    $sort: SortDirectionEnum
    $sort_by: ImageSortByEnum
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        images(
          filters: { imageType: $imageType, page: $page, limit: $limit, search: $search, sort: $sort, sortBy: $sort_by }
        ) {
          current_page
          page_count
          total_count
          results {
            id
            type
            imageFileName
            created_at
            dimensions
            fileSize
            fileSizeKb
            originalUrl
            largeUrl
            mediumUrl
            iconUrl
            thumbnailUrl
            squareUrl
            imageProcessing
            assignedImages {
              id
              created_at
              imageAssignableType
              imageAssignableId
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_ORGANIZATION_IMAGE = gql`
  mutation organizationRemoveImageMutation($imageAttributes: RemoveImageInput!) {
    organizationRemoveImage(imageAttributes: $imageAttributes) {
      errors
    }
  }
`;
