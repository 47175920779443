import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import '../../widgets.scss';
import { Typography } from 'modules/common';

function WidgetPreviewShotclock({ widget }) {
  const [timer, setTimer] = useState(widget.shotclockLength * 60);

  useEffect(() => {
    setTimer(widget.shotclockLength * 60);
  }, [widget.shotclockLength, setTimer]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) setTimer(timer - 1);
    }, 1000);

    // we dont clear because after clearing we cant set a new timer value
    return () => clearInterval(intervalId);
  }, [widget.shotclockLength, setTimer, timer]);

  const minutes = timer / 60;
  const tens = Math.floor(minutes / 10).toFixed(0);
  const ones = Math.floor(minutes % 10).toFixed(0);

  const seconds = timer % 60;
  const tensSeconds = Math.floor(seconds / 10).toFixed(0);
  const onesSeconds = Math.floor(seconds % 10).toFixed(0);

  return (
    <Container className="widget-preview_shotclock">
      <Typography>{widget.shotclockLabel}</Typography>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <div>
            <div className="countdown-numbers">
              <div>{tens}</div>
              <div>{ones}</div>
            </div>
            <div className="countdown-label">MINUTES</div>
          </div>
          <div className="countdown-seperator">:</div>
          <div>
            <div className="countdown-numbers">
              <div>{tensSeconds}</div>
              <div>{onesSeconds}</div>
            </div>
            <div className="countdown-label">SECONDS</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewShotclock.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default WidgetPreviewShotclock;
