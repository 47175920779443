import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const componentMatches = (componentA, componentB) => {
  if (componentA?.validatorId && componentB?.validatorId) return componentA.validatorId === componentB.validatorId;
  return componentA === componentB;
};

export default class ValidationBlock extends PureComponent {
  inputs = [];

  attach = component => {
    const doesntExist = !this.inputs.find(c => componentMatches(c, component));
    if (doesntExist) this.inputs.push(component);
  };

  detach = component => {
    this.inputs = this.inputs.filter(c => !componentMatches(c, component));
  };

  // An option to check for what input names have errors on submit
  inputNames = () => {
    let inputNames = [];
    this.inputs.reduce((errors, input) => {
      const isInvalid = input.checkIfIsInvalid();
      if (isInvalid) {
        // <Input common component stores input properties in props value
        if (!input.ref) {
          inputNames.push(input?.props?.name);
        }
        // <WinRedInput common component stores input properties in ref
        else {
          inputNames.push(input.ref?.name);
        }
      }
      return input;
    }, []);
    return inputNames.length > 0 ? inputNames : false;
  };

  /* Validate all registered inputs and if there is an error, increment the count. */
  errors = () => {
    const errors = this.inputs.reduce((errors, input) => {
      const isInvalid = input.checkIfIsInvalid();
      if (isInvalid) errors.push(isInvalid);
      return errors;
    }, []);

    return errors.length > 0 ? errors : false;
  };

  render() {
    return (
      <ValidationContext.Provider
        value={{
          attach: this.attach,
          detach: this.detach,
        }}
      >
        {this.props.children}
      </ValidationContext.Provider>
    );
  }
}

ValidationBlock.propTypes = {
  children: PropTypes.any.isRequired,
};

export const ValidationContext = React.createContext();
