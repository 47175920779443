import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Icon, ToolTip, TableRowExpandedMobile, WinRedChip } from 'modules/common';
import { convertEpochTime } from 'core/utilities';
import { computePageTooltip, computePageIcon, computePageIconColor } from '../ui.tools';
import GrossResultsDisplay from '../../components/grossResultsDisplay.component';

import { ExpandedRowBody } from './expanded-row-body.component';
import { PagesDropdownMenu } from './dropdown-menu.component';

function PagesTableMobile({ items, navigateToItem, expandItem, expandedRows, dropdownAction, profileCandidate }) {
  if (!items) return null;

  return items.map(item => {
    const { revv_uid, created_at, internalName, slug, type, pageGrossResults, billPayExists, authorEmail } = item;

    const icon = computePageIcon(item);
    const iconColor = computePageIconColor(item);
    const toolTip = computePageTooltip(item);
    const navigateHandler = () => navigateToItem(revv_uid);
    const isExpanded = expandedRows[revv_uid];
    const _expandItem = event => expandItem(event, revv_uid);

    return (
      <Fragment key={revv_uid}>
        <div className="list-item-card" onClick={navigateHandler} onKeyPress={navigateHandler} tabIndex="0" role="link">
          <Row className="no-gutters">
            <Col xs="2" className="list-item-card-icon">
              <ToolTip tooltip={toolTip}>
                <Icon icon={icon} color={iconColor} />
              </ToolTip>
            </Col>
            <Col xs="7">
              <div className="list-item-card-title">{internalName}</div>
              <div className="list-item-card-text">{slug}</div>
              <div className="list-item-card-text">
                <GrossResultsDisplay type={type} pageGrossResults={pageGrossResults} />
              </div>
              <div className="list-item-card-text">
                {convertEpochTime(created_at)} <br /> {authorEmail && `by ${authorEmail}`}
              </div>
              {billPayExists ? (
                <div className="list-item-card-text mt-2" onClick={_expandItem} role="button">
                  <WinRedChip variant="purple">
                    Bill-Pay
                    <Icon
                      className="ml-2"
                      color="purple"
                      icon={isExpanded ? 'minus-circle' : 'plus-circle'}
                      size="lg"
                    />
                  </WinRedChip>
                </div>
              ) : null}
            </Col>
            <Col xs="3" className="flex justify-end" onClick={e => e.stopPropagation()}>
              <PagesDropdownMenu dropdownAction={dropdownAction} item={item} profileCandidate={profileCandidate} />
            </Col>
          </Row>
        </div>

        {isExpanded && (
          <TableRowExpandedMobile>
            <ExpandedRowBody item={item} />
          </TableRowExpandedMobile>
        )}
      </Fragment>
    );
  });
}

export default PagesTableMobile;
