import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';

import { profileCandidateSelector } from 'core/login';
import '../nav-bar.scss';

function NavBarMenu({ title, path, isActive, children }) {
  const profileCandidate = useSelector(profileCandidateSelector);

  return (
    <Nav.Item
      className={classNames('winred-nav__nav-item', {
        'winred-nav__nav-item--active': isActive,
      })}
    >
      <Nav.Link
        eventKey={`/${profileCandidate.organization_revv_uid}/${path}`}
        href={`/${profileCandidate.organization_revv_uid}/${path}`}
        className="winred-nav__nav-link"
      >
        {title || children}
      </Nav.Link>
    </Nav.Item>
  );
}

NavBarMenu.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default NavBarMenu;
