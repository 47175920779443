import React from 'react';
import { Icon } from 'modules/common';

const amountPredicates = {
  EQ: { icon: <Icon icon="equals" className="mx-1" />, value: 'EQ' },
  GTE: { icon: <Icon icon="greater-than-equal" className="mx-1" />, value: 'GTE' },
  LTE: { icon: <Icon icon="less-than-equal" className="mx-1" />, value: 'LTE' },
};

export default amountPredicates;
