import { createStore } from 'redux';
import { createRootReducer } from 'index.reducer';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const configureStore = () => {
  const store = createStore(
    createRootReducer(history),
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
};

export const store = configureStore();
