import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { profileCandidateSelector, isOrganizationSelector } from 'core/login';
import { EmptyList, Pagination, ConfirmModal, useAmplitude, Typography } from 'modules/common';
import { setToast } from 'core/toast';
import {
  UPSELL_FLOW_ARCHIVE_MUTATION,
  ORGANIZATION_UPSELL_FLOW_ARCHIVE_MUTATION,
  DUPLICATE_CANDIDATE_UPSELL_FLOW_MUTATION,
  DUPLICATE_ORGANIZATION_UPSELL_FLOW_MUTATION,
} from 'core/middleware/queries';

import ListBodyDesktop from './list-body/desktop.component';
import ListBodyMobile from './list-body/mobile.component';
import { isEmptyList } from 'modules/common/lists/pagination.tools';

const UPSELL_DESCRIPTION =
  "Upsell Flows are a series of additional asks you can make to donors after they make a donation. Create an Upsell Flow and add it to your page to maximize your donor's value.";

function UpsellFlowListBody({ candidate, onChangeFilter, setIsProcessingData, refetch, filter, clearFilter }) {
  const [logEvent] = useAmplitude();
  const history = useHistory();
  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const dispatch = useDispatch();
  const sendToast = message => dispatch(setToast(message));

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedArchiveUpsell, setSelectedArchiveUpsell] = useState(null);
  const [selectedDuplicateUpsell, setSelectedDuplicateUpsell] = useState(null);

  const [duplicate, { loading }] = useMutation(
    isOrganization ? DUPLICATE_ORGANIZATION_UPSELL_FLOW_MUTATION : DUPLICATE_CANDIDATE_UPSELL_FLOW_MUTATION
  );
  const [archive, { loading: loadingArchive }] = useMutation(
    isOrganization ? ORGANIZATION_UPSELL_FLOW_ARCHIVE_MUTATION : UPSELL_FLOW_ARCHIVE_MUTATION
  );

  const navigateToItem = uuid => {
    history.push(`${history.location.pathname}/${uuid}`);
  };
  const dropdownAction = (eventKey, item) => {
    switch (eventKey) {
      case 'archive':
        setShowArchiveModal(true);
        setSelectedArchiveUpsell(item);
        break;
      case 'duplicate':
        if (item.bill_pay_exists) {
          setShowDuplicateModal(true);
          setSelectedDuplicateUpsell(item);
        } else {
          duplicateUpsellFlow(item);
        }
        break;
      default:
        break;
    }
  };

  const duplicateUpsellFlow = async item => {
    const duplicateUpsellRevvUid = item.revv_uid || selectedDuplicateUpsell.revv_uid;
    const upsellGroup = {
      revvUid: duplicateUpsellRevvUid,
      organizationRevvUid: profileCandidate.organization_revv_uid,
    };

    setShowDuplicateModal(false);
    setIsProcessingData(loading);

    try {
      const { data } = await duplicate({
        variables: {
          upsellGroup,
          organizationRevvUid: profileCandidate.organization_revv_uid,
        },
      });

      let { errors: error, duplicatedUpsellGroup } =
        data.candidateDuplicateUpsellGroup || data.organizationDuplicateUpsellGroup;

      setIsProcessingData(loading);

      if (error && error.length > 0) {
        sendToast({
          message: error[0],
          isError: true,
        });
        return;
      }

      logEvent('duplicated upsell flow', {
        'upsell flow id': duplicateUpsellRevvUid,
      });

      sendToast({
        message: 'Upsell Flow duplicated.',
      });

      navigateToItem(duplicatedUpsellGroup.revv_uid);
    } catch (error) {
      let { message } = error;

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const { upsell_groups = {} } = candidate || {};
  const { current_page, page_count, total_count, results } = upsell_groups;
  const attachedPages = selectedArchiveUpsell?.upsell_group_count || selectedArchiveUpsell?.page_count;

  const confirmArchiveFlow = async () => {
    const archivedUpsellRevvUid = selectedArchiveUpsell.revv_uid;

    // Close the modal and clear the selected upsell.
    setShowArchiveModal(false);
    setIsProcessingData(loadingArchive);

    const upsellFlow = { revvUid: archivedUpsellRevvUid, organizationRevvUid: profileCandidate.organization_revv_uid };

    try {
      const { data } = await archive({
        variables: { upsellFlow, organizationRevvUid: profileCandidate.organization_revv_uid },
      });

      setIsProcessingData(loadingArchive);

      let { errors: error } = data.candidateArchiveUpsellFlow || data.organizationArchiveUpsellFlow;
      if (error && error.length > 0) {
        sendToast({
          message: error[0],
          isError: true,
        });
        return;
      }

      logEvent('upsell flow archive', {
        'upsell flow id': archivedUpsellRevvUid,
      });

      sendToast({
        message: 'Your upsell flow has been archived.',
        isError: false,
      });

      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  if (isEmptyList(results, filter)) {
    return (
      <>
        <EmptyList
          text="No Upsell Flows"
          description={UPSELL_DESCRIPTION}
          buttonText="Create Upsell Flow"
          icon="donate"
          as={Link}
          to={`/${profileCandidate.organization_revv_uid}/pages/new-upsell-flow`}
        />
      </>
    );
  }

  return (
    <>
      <Breakpoint small down>
        <ListBodyMobile items={results} navigateToItem={navigateToItem} profileCandidate={profileCandidate} />
      </Breakpoint>
      <Breakpoint medium up>
        <ListBodyDesktop
          items={results}
          navigateToItem={navigateToItem}
          dropdownAction={dropdownAction}
          profileCandidate={profileCandidate}
        />
      </Breakpoint>

      <Pagination
        currentPage={current_page}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        results={results}
        filter={filter}
        clearFilter={clearFilter}
      />

      <ConfirmModal
        show={showArchiveModal}
        title={`Archive Upsell Flow`}
        buttonText={`Archive Upsell Flow`}
        buttonColor="error"
        handleClose={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
        onSubmit={confirmArchiveFlow}
      >
        {selectedArchiveUpsell && selectedArchiveUpsell.page_count > 0
          ? `Are you sure you want to archive this upsell flow? It currently belongs to ${attachedPages} page${
              attachedPages !== 1 ? 's' : ''
            }. Archiving it will remove it from those pages.`
          : `Are you sure you want to archive this upsell flow? This cannot be undone.`}
      </ConfirmModal>

      <ConfirmModal
        show={showDuplicateModal}
        buttonText={`Duplicate Upsell Flow`}
        buttonColor="primary"
        showCancelButton={true}
        handleClose={() => setShowDuplicateModal(false)}
        onCancel={() => setShowDuplicateModal(false)}
        onSubmit={duplicateUpsellFlow}
        results={results}
        filter={filter}
        clearFilter={clearFilter}
      >
        <Typography variant="h4" className="mt-5 mb-3 text-center">
          Are you sure you want to duplicate this upsell flow?
        </Typography>
        <p className="text-center mb-4">
          The upsell flow you are trying to duplicate has <span className="c--winred-red">Bill-Pay fees attached </span>
          to one or more candidates
        </p>
      </ConfirmModal>
    </>
  );
}

UpsellFlowListBody.propTypes = {
  candidate: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  setIsProcessingData: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export { UpsellFlowListBody, UPSELL_DESCRIPTION };
