import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './drawer.scss';

export function Drawer({ isLeft = false, open, children, className = '', setbackdropRef, footer }) {
  return (
    <>
      <div
        ref={setbackdropRef}
        className={classnames({
          'drawer-open-back': open,
        })}
      ></div>
      <div
        className={classnames('drawer', className, {
          open: open,
          'drawer-left': isLeft,
          'drawer-right': !isLeft,
        })}
      >
        <div className="drawer-content">{children}</div>
        {footer ? <div className="drawer-footer">{footer}</div> : null}
      </div>
    </>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isLeft: PropTypes.bool,
  children: PropTypes.any.isRequired,
  footer: PropTypes.any,
};
