import {
  SlateUpsell,
  SingleUpsell,
  MultipleUpsell,
  MerchandiseUpsell,
  ProductUpsell,
  SubscribableUpsell,
  MembershipUpsell,
  CommitteeUpsell,
  MatchProUpsell,
  PanelUpsell,
} from './drawer/new-upsell';

export const DISABLED_UPSELL_TYPES = [
  CommitteeUpsell.type,
  MerchandiseUpsell.type,
  ProductUpsell.type,
  SubscribableUpsell.type,
  MembershipUpsell.type,
  MatchProUpsell.type,
];

export const SHOW_REVENUE_PER_VIEW = [
  SlateUpsell.type,
  SingleUpsell.type,
  MultipleUpsell.type,
  MerchandiseUpsell.type,
  PanelUpsell.type,
  CommitteeUpsell.type,
];
