import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { profileMaxPersonalDonationSelector } from 'core/login';
import { Card, FormCard, Input, Typography, Button } from 'modules/common';
import { noop } from 'core/utilities';
import UpsellDonations from '../upsell-donations.component';
import './amount-multiplier.scss';
import { useMultiplierAmounts, DEFAULT_PREVIEW_AMT } from './tool';

export default function UpsellAmountMultiplierAmounts({ editedUpsell, onUpdateEditedUpsell, maxDonationAmounts }) {
  const maxPersonalDonation = useSelector(profileMaxPersonalDonationSelector);
  const [previewAmount, setPreviewAmount] = useState(DEFAULT_PREVIEW_AMT);
  const [amounts, preselectedIndex] = useMultiplierAmounts({ previewAmount, maxPersonalDonation, editedUpsell });

  // only allow 2 amounts since "show other field" will be the third or vice versa
  const maxNumberOfItems = editedUpsell.include_other_field ? maxDonationAmounts - 1 : maxDonationAmounts;

  if (!editedUpsell.multiplier_amounts) {
    return (
      <Container className="p-0">
        <Row>
          <Col xs={12}>
            <UpsellDonations
              editedUpsell={editedUpsell}
              noCard
              onUpdateEditedUpsell={onUpdateEditedUpsell}
              maxNumberOfItems={maxNumberOfItems}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="p-0">
      <Row>
        <Col md={6} xs={12}>
          <Card.Title subtitle="Dynamic amounts allow you to show relevant options to your donors. Donors who give $10 will see different options than donors who give $100. Try the Preview tool to see how it works."></Card.Title>
          <UpsellDonations
            editedUpsell={editedUpsell}
            noCard
            isMultiplier
            onUpdateEditedUpsell={onUpdateEditedUpsell}
            maxNumberOfItems={maxNumberOfItems}
          />
        </Col>
        <Col md={6} xs={12}>
          <FormCard className="mt-0 multiplier-preview">
            <Typography>Preview</Typography>
            <Input
              type="number"
              id="previewDonation"
              prepend="$"
              name="previewDonation"
              value={previewAmount}
              moneyInput
              onChange={e => setPreviewAmount(e.target.value)}
            />
            <div className="multipier-preview__buttons">
              {amounts.map((amt, idx) => {
                return (
                  <Button onClick={noop} key={amt} variant={idx === preselectedIndex ? 'cancel' : 'primary'}>
                    ${amt}
                  </Button>
                );
              })}
              {editedUpsell.include_other_field ? (
                <Input
                  type="number"
                  inputMode="decimal"
                  id="otherDonation"
                  name="Other"
                  value=""
                  placeholder="Other"
                  disabled
                  onChange={noop}
                />
              ) : null}
            </div>
          </FormCard>
        </Col>
      </Row>
    </Container>
  );
}

UpsellAmountMultiplierAmounts.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  maxDonationAmounts: PropTypes.number,
};
