import gql from 'graphql-tag';
import { leadFragments } from './leads.queries';

export const GET_VENDOR_LEADS_REPORT = gql`
  query getVendorLeadsReport(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $sourceType: LeadSourceEnum
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_leads_report(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sourceType: $sourceType
            search: $search
          }
        )
      }
    }
  }
`;

export const GET_VENDOR_LEADS_QUERY = gql`
  query getVendorLeads(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $sourceType: LeadSourceEnum
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        leads(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sourceType: $sourceType
            search: $search
          }
        ) {
          ...Leads
        }
      }
    }
  }
  ${leadFragments.leads}
`;

export const GET_VENDOR_LEAD_QUERY = gql`
  query getVendorLead($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        leads(filters: { revvUid: $revv_uid }) {
          ...Lead
        }
      }
    }
  }
  ${leadFragments.lead}
`;
