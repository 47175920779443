import gql from 'graphql-tag';

export const GET_DASHBOARD_DONATIONS = gql`
  query getDashBoardDonations(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        donations_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          count
          average_donation_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_RECURRING = gql`
  query getDashBoardRecurring(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        recurring_donations_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_GROWTH = gql`
  query getDashBoardGrowth(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        subscriptions_mrr_growth_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          net_subscription_amount
          active_subscriptions_mrr {
            date
            donation_amount
          }
          canceled_subscriptions_mrr {
            date
            donation_amount
          }
          net_subscriptions_mrr {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_SUBSCRIPTIONS = gql`
  query getDashBoardSubscriptions(
    $graphInterval: GraphIntervalEnum!
    $dateRangeStart: String
    $dateRangeEnd: String!
    $dateRange: GraphDateRangeEnum!
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        subscriptions_mrr_graph(
          filters: {
            graphInterval: $graphInterval
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            dateRange: $dateRange
          }
        ) {
          gross_amount
          results {
            date
            donation_amount
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_CANDIDATES = gql`
  query getDashBoardCandidates($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        connected_conduit_candidates {
          gross_amount
          results {
            revv_uid
            name
            raised_amount
          }
        }
        origin_report(filters: { page: 1, limit: 5 }) {
          gross_amount
          results {
            revv_uid
            name
            raised_amount
            avatar {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
        recipient_report(filters: { page: 1, limit: 5 }) {
          gross_amount
          results {
            revv_uid
            name
            raised_amount
            avatar {
              id
              image {
                id
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
`;
