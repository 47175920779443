import gql from 'graphql-tag';

export const subscriptionFragments = {
  subscriptions: gql`
    fragment Subscriptions on CandidateSubscriptionResult {
      current_page
      page_count
      total_count

      results {
        created_at
        first_name
        last_name
        amount
        source_url
        email
        phone
        source_code
        recurring
        revv_uid
        interval
        subscription_status
        contributor_type
        pac_name
        business_name
      }
    }
  `,
  subscription: gql`
    fragment Subscription on CandidateSubscriptionResult {
      results {
        created_at
        first_name
        last_name
        amount
        canceled_at
        end_at
        source_url
        source_code
        email
        phone
        recurring
        card_expiry
        card_last4
        card_brand
        revv_uid
        profile_revv_uid
        user_type
        interval
        subscription_status
        contributor_type
        pac_name
        business_name
      }
    }
  `,
};

export const GET_SUBSCRIPTIONS_REPORT = gql`
  query getSubscriptionsReport(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_subscriptions_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS_QUERY = gql`
  query getSubscriptions(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $dateRangeStart: String
    $dateRangeEnd: String
    $interval: SubscriptionIntervalEnum
    $subscriptionStatus: SubscriptionStatusEnum
    $search: String
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        subscription_statistics(
          filters: {
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            contributorType: $contributorType
          }
        ) {
          remaining_this_month {
            subscription_count
            subscription_revenue
            subscription_average
          }
          total_active {
            subscription_count
            subscription_average
            subscription_revenue
          }
        }
        subscriptions(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            interval: $interval
            subscriptionStatus: $subscriptionStatus
            search: $search
            contributorType: $contributorType
          }
        ) {
          ...Subscriptions
        }
      }
    }
  }
  ${subscriptionFragments.subscriptions}
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelSubscription($organizationRevvUid: String!, $revvUid: String!) {
    candidateCancelSubscription(organizationRevvUid: $organizationRevvUid, revvUid: $revvUid) {
      errors
      subscription {
        status
      }
    }
  }
`;

export const GET_SUBSCRIPTION_QUERY = gql`
  query getSubscription($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        subscriptions(filters: { revvUid: $revv_uid }) {
          ...Subscription
        }
      }
    }
  }
  ${subscriptionFragments.subscription}
`;
