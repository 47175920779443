import { extractDate } from 'core/utilities';

/**
 * Please don't touch any of this unless you know what you are doing!!!
 *
 * JS dates automatically convert anything they get to local time zone
 * but the server wants UTC (and will convert haphazardly if needed).
 *
 * Since all we really want is the date for the date picker we always just grab
 * the date and create a Date object in the timezone we want given the SAME date.
 *
 * from server to local:
 *      we extract the date only ( extractDate ) and make a date object with the
 *      local timezone this way the time is local to whatever the computer is but
 *      the date is forced to be what the server gave us.
 *
 * from local to server:
 *      we extract the date only ( extractDate ) and make a date object with a
 *      UTC timezone (00:00:00 UTC)- this way the server always gets UTC time
 *      and doesnt try to do whatever it does to convert to it's UTC time.
 *      The DATE (not datetime) is forced to always be the same.
 *
 * to DatePicker:
 *      We make a copy of the page object via lodash cloneDeep which does some
 *      funky stuff to the date so to always make sure we have what we need
 *      we just do all that crap again ( convertRecurringForDatePicker )
 *      when coming from server to local to make sure everything is copacetic
 */
export const convertReccuringForServer = page => {
  if (!page.recurringEndAt) return page;
  const { year, month, day, isDateValid } = extractDate(page.recurringEndAt);

  // force recurringEndAt to be UTC for the server but with the SAME date
  if (isDateValid) page.recurringEndAt = `${year}-${month}-${day} 23:59:59 UTC`;

  return page;
};

export const convertRecurringForUi = page => {
  if (!page.recurringEndAt) return page;
  const { today, isDateValid } = extractDate(page.recurringEndAt);

  if (isDateValid) page.recurringEndAt = today;
  return page;
};

export const convertRecurringForDatePicker = recurringEndAt => {
  if (!recurringEndAt) return null;
  let newRecurringEndAt = null;

  const { today, isDateValid } = extractDate(recurringEndAt);

  // the date object coming back is ALWAYS the same day just in the local timezone
  if (isDateValid) newRecurringEndAt = today;
  return newRecurringEndAt;
};
