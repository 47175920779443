import React from 'react';
import PropTypes from 'prop-types';

import {
  ColorPickerInput,
  ExternalLink,
  FlexBinaryChoiceRow,
  FloatGroup,
  FormCardSpaced,
  Input,
  Select,
  Typography,
} from 'modules/common';
import { SPEED_OPTIONS } from '../tools';
import { Row } from 'react-bootstrap';

function WidgetDonation({ widget, updateWidget, disabled = false }) {
  let animationSpeedValue = SPEED_OPTIONS.find(opt => opt.value === widget.donationStreamAnimationDuration);
  let options = SPEED_OPTIONS;

  const handleResetColors = () => {
    updateWidget({
      target: {
        name: 'widgets',
        value: {
          donationStreamHighlightColor: '',
          donationStreamTextColor: '',
          donationStreamBackgroundColor: '',
        },
      },
    });
  };

  // if a custom speed add to list
  if (!animationSpeedValue && widget.donationStreamAnimationDuration) {
    animationSpeedValue = {
      label: 'Other',
      value: widget.donationStreamAnimationDuration,
    };
    options = [...options, animationSpeedValue];
  }

  return (
    <>
      <Select
        label="Animation Speed"
        floatLabel="Animation Speed"
        placeholder="Select Speed..."
        name="donationStreamAnimationDuration"
        onChange={updateWidget}
        disabled={disabled}
        value={animationSpeedValue}
        options={options}
        validators={['required']}
        errorMessages={['Animation Speed is required.']}
      />

      <FloatGroup className="prepend-label-dollar">
        <Input
          moneyInput
          prepend="$"
          disabled={disabled}
          name="donationStreamMinimumDonation"
          placeholder="Minimum Amount"
          value={widget.donationStreamMinimumDonation}
          onChange={updateWidget}
          description="The minimum amount for a donation to be included in the donation stream."
        />
      </FloatGroup>

      <FloatGroup className="prepend-label-dollar">
        <Input
          moneyInput
          prepend="$"
          disabled={disabled}
          name="donationStreamMaximumDonation"
          placeholder="Maximum Amount"
          value={widget.donationStreamMaximumDonation}
          onChange={updateWidget}
          description="The maximum amount for a donation to be included in the donation stream."
        />
      </FloatGroup>

      <FloatGroup>
        <Input
          type="text"
          disabled={disabled}
          name="donationStreamCustomCopy"
          placeholder="Custom Copy"
          value={widget.donationStreamCustomCopy}
          maxLength="54"
          onChange={updateWidget}
          description="Custom copy shown at the end of the donation stream."
        />
      </FloatGroup>
      <FormCardSpaced className="mb-4">
        <FlexBinaryChoiceRow
          title="Donation Stream Type"
          subTitle={
            <>
              The Donation Stream displays real-time Donor data on the page. Select whether that data is Random or
              Live.&nbsp;
              <ExternalLink href="https://support.winred.com/en/articles/4837085-add-widgets-to-your-pages#h_dab306a43a">
                Learn More
              </ExternalLink>
            </>
          }
          disabled={disabled}
          value={widget.donationStreamDirect}
          name="donationStreamDirect"
          onChange={updateWidget}
          falseText="Random"
          trueText="Live"
          flipButtons={true}
        />
      </FormCardSpaced>
      <>
        <Typography>
          Override the colors being used from Creative & Layout tab. Once changed, select Reset to return to using
          Creative & Layout colors.{' '}
          <span
            className="text-winred-red underline hover:text-winred-red-light cursor-pointer "
            onClick={handleResetColors}
          >
            Reset.
          </span>
        </Typography>

        <Row className="px-1">
          <ColorPickerInput
            className="mx-1 flex flex-auto"
            value={widget.donationStreamHighlightColor}
            name="donationStreamHighlightColor"
            disabled={disabled}
            setColorAction={updateWidget}
            placeholder="Primary Color"
            label="Primary Color"
            description="Colors the donation amount."
          />

          <ColorPickerInput
            className="mx-1 flex flex-auto"
            value={widget.donationStreamTextColor}
            name="donationStreamTextColor"
            disabled={disabled}
            setColorAction={updateWidget}
            placeholder="Secondary Color"
            label="Secondary Color"
            description="Colors the donated by text."
          />

          <ColorPickerInput
            className="mx-1 flex flex-auto"
            value={widget.donationStreamBackgroundColor}
            name="donationStreamBackgroundColor"
            disabled={disabled}
            setColorAction={updateWidget}
            placeholder="Background Color"
            label="Background Color"
            description="Colors the Donation Stream's background."
          />
        </Row>
      </>
    </>
  );
}

WidgetDonation.propTypes = {
  widget: PropTypes.object.isRequired,
  updateWidget: PropTypes.func.isRequired,
};

export default WidgetDonation;
