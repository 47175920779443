// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { enableAllPlugins } from 'immer';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from '@honeybadger-io/react';

import MainLayout from 'modules/layout/main.layout';
import { store, history } from './configureStore';
import { createRootReducer } from 'index.reducer';
import { createApolloClient, generateLoginDetails } from './index.tools';
import { buildIconLibrary } from './core/utilities/icon-loader';
import honeybadgerConfig from './core/utilities/honeybadger.js';
import { HBError, AMPLITUDE_KEY, amplitude, AmplitudeProvider } from 'modules/common';
import './index.scss';

// set version var to easily check
import packageJson from '../package.json';
window.appVersion = packageJson.version;
console.log(`WinRed Version ${window.appVersion}`);

enableAllPlugins();
buildIconLibrary();

// set component breakpoints
setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices (not sure which one's this big)
  { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  { large: 992 }, // smaller laptops
  { xlarge: 1200 }, // laptops and desktops
]);

// try to see if we are logged in already
generateLoginDetails(store);

const client = createApolloClient(store);

const render = () => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ErrorBoundary honeybadger={honeybadgerConfig} ErrorComponent={HBError}>
          <Router>
            <BreakpointProvider>
              <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={AMPLITUDE_KEY}>
                <Route path="/" component={MainLayout} />
              </AmplitudeProvider>
            </BreakpointProvider>
          </Router>
        </ErrorBoundary>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

render();

if (module.hot) {
  module.hot.accept('./modules/layout/main.layout', () => {
    render();
  });

  module.hot.accept('./index.reducer', () => {
    store.replaceReducer(createRootReducer(history));
  });
}
