import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography } from 'modules/common';

function BlockApproval({ hasValue, recommended }) {
  const text = recommended ? 'Recommended' : 'Blocking Committee Approval';
  const variant = recommended ? 'warning' : 'error';
  const iconColor = recommended ? 'gold' : 'error';

  return (
    !hasValue && (
      <Typography variant={variant} className="mt-1 mb-0">
        <Icon icon="exclamation-circle" color={iconColor} className="mr-1"></Icon>
        {text}
      </Typography>
    )
  );
}

BlockApproval.propTypes = {
  hasValue: PropTypes.any,
  recommended: PropTypes.bool,
};

BlockApproval.defaultProps = {
  hasValue: false,
  recommended: false,
};

export default BlockApproval;
