import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { Button, Icon, Image } from 'modules/common';
import '../../widgets.scss';

function WidgetPreviewPopup({ widget, primaryColor }) {
  return (
    <Container className="widget-preview_popup">
      <Row className="d-flex justify-content-center">
        <Col xs={12} className="widget-popup">
          {widget.popupHeaderCopy !== '' ? (
            <div className="widget-popup__header" style={{ backgroundColor: primaryColor }}>
              <div>{widget.popupHeaderCopy}</div>
              <Icon icon="times" color="white" size="lg" />
            </div>
          ) : null}

          {widget.widgetImage?.image?.thumbnailUrl ? (
            <div className="widget-popup-image">
              <Image src={widget.widgetImage.image.thumbnailUrl} />
            </div>
          ) : null}

          {widget.popupBodyCopy !== '' && (
            <div
              className="widget-popup__body"
              dangerouslySetInnerHTML={{
                __html: widget.popupBodyCopy,
              }}
            ></div>
          )}
          {widget.popupButtonCopy !== '' && (
            <div className="widget-popup__footer">
              <Button style={{ backgroundColor: primaryColor, textTransform: 'none' }}>{widget.popupButtonCopy}</Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewPopup.propTypes = {
  widget: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default WidgetPreviewPopup;
