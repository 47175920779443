import React from 'react';
import PropTypes from 'prop-types';
import FormColumn from './form-column.component';

function FormDoubleColumnSidebar({ children, className = '', ...rest }) {
  return (
    <FormColumn className={'order-lg-2 order-md-2 order-sm-1 ' + className} xs={12} md={4} lg={3} {...rest}>
      {children}
    </FormColumn>
  );
}

FormDoubleColumnSidebar.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FormDoubleColumnSidebar;
