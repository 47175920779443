import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  FormCardSpaced,
  FlexBinaryChoiceRow,
  Typography,
  AddField,
  ExternalLink,
  ReportCondition,
} from 'modules/common';
import uuid from 'core/utilities/uuid';
import { isAgencySelector } from 'core/login';

const OPERATOR_OPTIONS = { ANY: 'RANSACK_ANY', ALL: 'RANSACK_ALL' };

function ReportConditions({
  availableConditions,
  conditionAttributesOptions,
  report: { search_conditions, condition_operator },
  onChange,
}) {
  const isAgency = useSelector(isAgencySelector);

  const onChangeConditions = ({ target: { name, value } }) =>
    onChange({ target: { name, value: value ? OPERATOR_OPTIONS.ANY : OPERATOR_OPTIONS.ALL } });

  const addCondition = useCallback(
    () =>
      onChange({
        target: { name: 'search_conditions', value: search_conditions.concat([{ id: uuid(), _isNew: true }]) },
      }),
    [search_conditions, onChange]
  );

  const handleConditionChange = useCallback(
    ({ id, key, value }) => {
      onChange({
        target: {
          name: 'search_conditions',
          value: [
            ...search_conditions.map(condition => {
              if (id !== condition.id) return condition;

              return { ...condition, [key]: value };
            }),
          ],
        },
      });
    },
    [search_conditions, onChange]
  );

  const renderedCardFooter = useMemo(() => {
    const filteredConditions = (search_conditions || []).filter(c => !c?.destroy);

    return (
      <>
        {filteredConditions.map((condition, index) => (
          <div key={condition.id}>
            <ReportCondition
              condition={condition}
              conditions={availableConditions}
              conditionAttributesOptions={conditionAttributesOptions}
              onChange={handleConditionChange}
            />
            {index + 1 !== filteredConditions.length && (
              <Typography className="text-center">
                {condition_operator === OPERATOR_OPTIONS.ALL ? 'AND' : 'OR'}
              </Typography>
            )}
          </div>
        ))}
        <AddField text="Add Condition" onClick={addCondition} />
      </>
    );
  }, [
    availableConditions,
    search_conditions,
    condition_operator,
    conditionAttributesOptions,
    addCondition,
    handleConditionChange,
  ]);

  return (
    <FormCardSpaced classNameFooter="bg--catskill-white" footer={renderedCardFooter}>
      <FlexBinaryChoiceRow
        className="mb-0"
        falseText="AND"
        name="condition_operator"
        onChange={onChangeConditions}
        subTitle={
          <>
            If you select OR then data that matches any of the conditions below will be included in the report. If you
            select AND then all data must meet all of the conditions below to be included in the report.{' '}
            <ExternalLink
              href={
                isAgency
                  ? 'https://support.winred.com/en/articles/7239601-vendor-custom-reports'
                  : 'https://support.winred.com/en/articles/6742620-custom-reports'
              }
            >
              Learn More
            </ExternalLink>
          </>
        }
        title="Report Conditions"
        trueText="OR"
        value={condition_operator === OPERATOR_OPTIONS.ANY}
      />
    </FormCardSpaced>
  );
}

ReportConditions.propTypes = {
  availableConditions: PropTypes.array.isRequired,
  conditionAttributesOptions: PropTypes.array.isRequired,
  report: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportConditions };
