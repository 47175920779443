import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { Card, Typography, Icon } from 'modules/common';
import './add-list-item-button.scss';

function AddListItemButton({ addItem, addText, addSubText, disabled, className }) {
  return (
    <Card
      variant={disabled ? 'gray' : 'green'}
      className={classNames('add-card', className, { pointer: !disabled })}
      onClick={() => !disabled && addItem()}
    >
      <Row>
        <Col xs={12} className="d-flex align-items-center">
          <Icon
            icon="plus-circle"
            color={disabled ? 'gray' : 'green'}
            size="lg"
            className={classNames({ 'c--lynx': disabled })}
          />
          <div className="add-text">
            <h5 className={classNames('mb-0 mt-0', { 'c--lynx': disabled })}>{addText}</h5>
            {addSubText ? <Typography className="mt-0 mb-0">{addSubText}</Typography> : null}
          </div>
        </Col>
      </Row>
    </Card>
  );
}

AddListItemButton.propTypes = {
  addItem: PropTypes.func.isRequired,
  addText: PropTypes.string.isRequired,
  addSubText: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
AddListItemButton.defaultProps = {
  addSubText: '',
};

export default AddListItemButton;
