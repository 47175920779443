import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { Typography } from 'modules/common';

const CustomReportCardRow = ({ label, value, children }) => {
  const ellipisText = useMemo(() => {
    if (!value) return '';

    return value.length > 180 ? `${value.substring(0, 181)}...` : value;
  }, [value]);

  return (
    <Row className="p-4 custom-report-list-border">
      <Col className="d-flex">
        <Typography className="mb-0 custom-report-list-card-title">{label}</Typography>
        {children ? (
          children
        ) : (
          <Typography variant="bodyMedium" className="mb-0 custom-report-list-card-value">
            {ellipisText}
          </Typography>
        )}
      </Col>
    </Row>
  );
};

CustomReportCardRow.propTypes = {
  children: PropTypes.array,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { CustomReportCardRow };
