import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import { NavList } from 'modules/common';
import '../../nav-bar.scss';

function SubMenuContainer({ pathname, handleSelect, label, children, showSubmenu }) {
  return (
    <div className={classnames('subnav', showSubmenu ? 'visible' : 'invisible hide-sub-menu')}>
      <Container fluid className="no-gutters-xs d-none d-lg-block">
        <Row className="justify-content-center">
          <Col lg="11">
            <NavList activeKey={pathname} onSelect={handleSelect} variant="tabs" ariaLabel={label}>
              {children}
            </NavList>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

SubMenuContainer.propTypes = {
  pathname: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showSubmenu: PropTypes.bool.isRequired,
};

export default SubMenuContainer;
