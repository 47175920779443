import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';

function NavBarMenuAccounting({
  toggleSubNav,
  hasActiveChildAccounting,
  showMobileSubNav,
  organizationRevvUid,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildAccounting,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showAccountingSubNav')}>
          Accounting
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size={'xs'}
            color={hasActiveChildAccounting ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>

      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        {!isAgency ? (
          <>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/accounting/ledger`}
              href={`/${organizationRevvUid}/accounting/ledger`}
            >
              Ledger
            </Nav.Link>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/accounting/payouts`}
              href={`/${organizationRevvUid}/accounting/payouts`}
            >
              Payouts
            </Nav.Link>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/accounting/payouts/report`}
              href={`/${organizationRevvUid}/accounting/payouts/report`}
            >
              Payout Report
            </Nav.Link>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/accounting/notifications/payouts`}
              href={`/${organizationRevvUid}/accounting/notifications/payouts`}
            >
              Payout Notification
            </Nav.Link>
            <Nav.Link
              eventKey={`/${organizationRevvUid}/accounting/blocked`}
              href={`/${organizationRevvUid}/accounting/blocked`}
            >
              Blocked Donors
            </Nav.Link>
          </>
        ) : (
          <Nav.Link
            eventKey={`/${organizationRevvUid}/vendors/accounting/transfers`}
            href={`/${organizationRevvUid}/vendors/accounting/transfers`}
          >
            Transfers
          </Nav.Link>
        )}
      </MobileSubMenu>
    </>
  );
}

NavBarMenuAccounting.propTypes = {
  toggleSubNav: PropTypes.func.isRequired,
  hasActiveChildAccounting: PropTypes.bool.isRequired,
  showMobileSubNav: PropTypes.bool.isRequired,
};

export default NavBarMenuAccounting;
