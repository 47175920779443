import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import classnames from 'classnames';

import Button from '../button.component';
import './dropdown.scss';

function WinRedDropdownContainer({ children, title = 'Create New', variant = 'success', disabled, className = '' }) {
  return (
    <div className={classnames(className, 'winred-dropdown')}>
      <Dropdown disabled={disabled}>
        <Dropdown.Toggle disabled={disabled} variant={variant} size="lg" as={Button} className="p-3">
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

WinRedDropdownContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WinRedDropdownContainer;
