import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';

import { Card, Typography, Icon, ButtonLink, WinRedChip } from 'modules/common';
import { convertEpochTime, formatNumberWithCommas, noEvent } from 'core/utilities';
import '../../upsell.scss';

function UpsellItemMobile({ upsell, onEditExistingUpsell, onUpsellItemRemove, disabledUpsellFlow }) {
  const { name, upsell_statistics = {}, upsell_preview_url } = upsell.upsell_page;

  return (
    <Card onClick={onEditExistingUpsell} className="pointer">
      <Card.Body>
        <Row className="mb-3">
          {disabledUpsellFlow ? null : (
            <Col xs="auto" className="cursor-grab pr-0">
              <Icon icon="bars" color="gray" size="lg" />
            </Col>
          )}
          <Col>
            <Typography className="mb-0" variant="h5">
              {name}
            </Typography>
            {upsell.upsell_page?.public_upsell ? (
              <WinRedChip className="mt-3" variant="yellow">
                Public
              </WinRedChip>
            ) : null}
            {upsell.upsell_page?.bill_pay_exists ? (
              <WinRedChip className="mt-3" variant="purple">
                Bill-Pay
              </WinRedChip>
            ) : null}
            <Typography className="mb-0">{convertEpochTime(upsell.created_at)}</Typography>
          </Col>
          {disabledUpsellFlow ? null : (
            <Col xs="auto" onClick={onUpsellItemRemove}>
              <Icon icon="times" color="gray" size="lg" />
            </Col>
          )}
        </Row>
        <Row className="ml-3 d-flex justify-content-between">
          {upsell.upsell_page?.public_upsell ? null : (
            <div className="d-flex align-items-center">
              <Col xs="auto">
                <Typography className="mb-0" variant="bodyMedium">
                  Opt-in Rate
                </Typography>
                <Typography className="mb-0">{((upsell_statistics.percentage || 0) * 100).toFixed(0)}%</Typography>
              </Col>
              <Col xs="auto">
                <Typography className="mb-0" variant="bodyMedium">
                  Views
                </Typography>
                <Typography className="mb-0">{formatNumberWithCommas(upsell_statistics.view)}</Typography>
              </Col>
              <Col xs="auto">
                <Typography className="mb-0" variant="bodyMedium">
                  Accepted
                </Typography>
                <Typography className="mb-0">{formatNumberWithCommas(upsell_statistics.accepted)}</Typography>
              </Col>
            </div>
          )}

          <Col xs="auto" onClick={noEvent} onKeyPress={noEvent} role="button" tabIndex="0" className="mt-2 mt-md-0">
            {upsell_preview_url ? <ButtonLink url={upsell_preview_url} text="Preview" /> : null}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

UpsellItemMobile.propTypes = {
  upsell: PropTypes.object.isRequired,
  onEditExistingUpsell: PropTypes.func.isRequired,
  onUpsellItemRemove: PropTypes.func.isRequired,
  disabledUpsellFlow: PropTypes.bool.isRequired,
};

export default UpsellItemMobile;
