import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Typography, Icon, FormCard } from 'modules/common';
import { ImagePromptDrawer } from './image-prompt/image-drawer/imagePrompt-drawer.component';

function ImagePrompt({
  imageUri,
  disabled,
  removeImageAction,
  name,
  selectImageAction,
  imageType,
  maxFileSize,
  isNested = false,
}) {
  const [showGallery, setShowGallery] = useState(false);
  const showGalleryModal = () => setShowGallery(true);

  const onToggleDrawer = () => {
    setShowGallery(!showGallery);
  };

  return (
    <>
      <FormCard
        variant="upload"
        disabled={disabled}
        onClick={disabled ? null : showGalleryModal}
        className="mb-3"
        classNameBody={classnames('d-flex flex-column align-items-center p-4', { pointer: !disabled })}
      >
        {imageUri ? (
          <img src={imageUri} alt="selection" className="w-100 max-width-18" />
        ) : (
          <>
            <Icon icon="plus-circle" size="3x" />
            <Typography noGutter className="mt-1">
              Choose Image
            </Typography>
          </>
        )}
      </FormCard>

      {!disabled && imageUri && (
        <FormCard
          variant="upload"
          onClick={removeImageAction}
          className="mb-3"
          classNameBody="d-flex flex-column align-items-center pointer p-3"
        >
          <Typography className="mb-0 p--medium">Remove</Typography>
        </FormCard>
      )}

      <ImagePromptDrawer
        open={showGallery}
        toggleDrawer={onToggleDrawer}
        maxFileSize={maxFileSize}
        name={name}
        selectImageAction={selectImageAction}
        imageUri={imageUri}
        imageType={imageType}
        isNested={isNested}
      />
    </>
  );
}

ImagePrompt.propTypes = {
  name: PropTypes.string.isRequired,
  imageUri: PropTypes.string,
  imageType: PropTypes.string.isRequired,
  maxFileSize: PropTypes.number,
  selectImageAction: PropTypes.func.isRequired,
  removeImageAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ImagePrompt;
