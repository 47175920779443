import { getDonationStatus } from 'modules/revenue/donations/donations.tools';
import { Typography } from '../index';
import uuid from 'core/utilities/uuid';

export const createTimelineEvents = (data, type) => {
  if (!data) return [];

  const events = [
    {
      title: `${type} Created`,
      id: data.revv_uid + data.created_at,
      date: data.created_at,
    },
  ];

  if (data.canceled_at) {
    events.push({
      title: `${type} Canceled`,
      id: data.revv_uid + data.canceled_at,
      date: data.canceled_at,
    });
  }

  if (data.refunded_at) {
    events.push({
      title: `Donation ${getDonationStatus(data.new_charge_status)}`,
      id: data.revv_uid + data.refunded_at,
      date: data.refunded_at,
    });
  }

  if (data.voided_at) {
    events.push({
      title: `${type} Voided`,
      id: data.revv_uid + data.voided_at,
      date: data.voided_at,
    });
  }

  return events;
};

export const createPetitionPathwayEvents = (regularSteps, endSteps) => {
  if (!regularSteps) return [];

  let events;
  events = [
    {
      id: uuid(),
      title: (
        <Typography italic className="m-0 p-0" key={uuid()}>
          Start Petition Page
        </Typography>
      ),
    },
  ];
  regularSteps
    .filter(step => step?._destroy !== true)
    .forEach(step => events.push({ title: step.name, anchorId: step.revv_uid, id: uuid() }));
  const activeEndStep = endSteps?.find(step => !step._destroy);
  if (activeEndStep) {
    events.push({
      id: uuid(),
      title: (
        <Typography italic className="m-0 p-0" key={uuid()}>
          {activeEndStep.name}
        </Typography>
      ),
    });
  } else {
    events.push({
      id: uuid(),
      title: (
        <Typography italic className="m-0 p-0" key={uuid()}>
          All Other Users
        </Typography>
      ),
    });
  }

  return events;
};
