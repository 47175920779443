import React from 'react';
import { Typography, Card } from 'modules/common';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export function AddField({ onClick, text = 'Add Field', className = '' }) {
  return (
    <Card variant="green" className={classnames(className, 'cursor-pointer')} onClick={onClick}>
      <Card.Body className="text-center p-3">
        <Typography className="m-0">{text}</Typography>
      </Card.Body>
    </Card>
  );
}

AddField.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
