import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Button, ConfirmModal, FormCard } from 'modules/common';

function DeleteReport({ onDelete }) {
  const [showConfirm, setShowConfirm] = useState(false);

  const toggleShowConfirm = () => setShowConfirm(state => !state);

  return (
    <Row className="mb-3">
      <Col xs={12}>
        <FormCard variant="red" className="pb-3">
          <Button variant="error" onClick={toggleShowConfirm}>
            Delete Report
          </Button>
        </FormCard>
      </Col>
      <ConfirmModal
        show={showConfirm}
        title="Delete Report"
        buttonText="Delete Report"
        buttonColor="cancel"
        cancelButtonColor="default"
        showCancelButton
        handleClose={toggleShowConfirm}
        onCancel={toggleShowConfirm}
        onSubmit={() => {
          toggleShowConfirm();
          onDelete();
        }}
      >
        Are you sure you want to delete this report?
      </ConfirmModal>
    </Row>
  );
}

DeleteReport.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export { DeleteReport };
