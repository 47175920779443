import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from '../../../core/login';
import { ConfirmModal, EmptyList, LIST_PAGE_SIZE, Pagination, SpinnerContainer } from '../../common';
import { Breakpoint } from 'react-socks';
import { ConditionItemsDesktop } from '../pages/conditions/list-body/condition-items-desktop.component';
import honeybadger from 'honeybadger-js';
import { setToast } from '../../../core/toast';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_ORGANIZATION_AVAILABLE_CONDITIONS_QUERY,
  ORGANIZATION_PATHWAY_STEP_ARCHIVE_MUTATION,
  ORGANIZATION_PATHWAY_STEP_DUPLICATE_MUTATION,
} from '../../../core/middleware/queries/pages/pathways.organization.queries';
import {
  GET_AVAILABLE_CONDITIONS_QUERY,
  PATHWAY_STEP_ARCHIVE_MUTATION,
  PATHWAY_STEP_DUPLICATE_MUTATION,
} from '../../../core/middleware/queries/pages/pathways.queries';
import { ConditionDrawer } from '../pages/conditions/condition-drawer.component';
import { formatConditionOptions } from '../pathways/pathways.tools';
import { GET_CAMPAIGNS_NAMES_QUERY, GET_ORGANIZATIONS_CAMPAIGNS_NAMES_QUERY } from '../../../core/middleware/queries';
import { ConditionItemsMobile } from '../pages/conditions/list-body/condition-items-mobile.component';
import PropTypes from 'prop-types';
import { isEmptyList } from 'modules/common/lists/pagination.tools';

export function ConditionsListBody({
  candidate,
  isDrawerOpen,
  selectedCondition,
  originalEditedCondition,
  onEditSelectedCondition,
  toggleDrawer,
  onChangeFilter,
  handleCreateNew,
  setIsProcessingData,
  refetch,
  clearFilter,
  filter,
}) {
  const [availableCustomConditions, setAvailableCustomConditions] = useState({});
  const [campaignNamesList, setCampaignNamesList] = useState(null);

  const profileCandidate = useSelector(profileCandidateSelector);
  const isOrganization = useSelector(isOrganizationSelector);
  const [selectedArchiveCondition, setSelectedArchiveCondition] = useState(null);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const dispatch = useDispatch();

  const getCampaignNamesQuery = profileCandidate.isOrganization
    ? GET_ORGANIZATIONS_CAMPAIGNS_NAMES_QUERY
    : GET_CAMPAIGNS_NAMES_QUERY;

  const {
    loading: campaignNameLoading,
    error: campaignNameError,
    data: campaignNameData,
  } = useQuery(getCampaignNamesQuery, {
    variables: {
      organizationRevvUid: profileCandidate.organization_revv_uid,
    },
    onCompleted: data => {
      const campaignsListResults = (
        campaignNameData.viewer?.state_level_organization?.campaigns || campaignNameData.viewer?.candidate?.campaigns
      )?.results;
      setCampaignNamesList(campaignsListResults);
    },
  });

  const availableConditionsQuery = isOrganization
    ? GET_ORGANIZATION_AVAILABLE_CONDITIONS_QUERY
    : GET_AVAILABLE_CONDITIONS_QUERY;

  const { loading: loadingAvailableConditions, error: errorAvailableConditions } = useQuery(availableConditionsQuery, {
    variables: {
      organizationRevvUid: profileCandidate.organization_revv_uid,
      pathwayType: 'UPSELL',
    },
    onCompleted: data => {
      const availableConditions = (data.viewer.candidate || data.viewer.state_level_organization)
        ?.pathways_available_conditions;
      setAvailableCustomConditions(formatConditionOptions(availableConditions));
    },
  });

  const sendToast = message => dispatch(setToast(message));

  const [duplicate, { loading: loadingDuplicate }] = useMutation(
    isOrganization ? ORGANIZATION_PATHWAY_STEP_DUPLICATE_MUTATION : PATHWAY_STEP_DUPLICATE_MUTATION
  );
  const [archive, { loading: loadingArchive }] = useMutation(
    isOrganization ? ORGANIZATION_PATHWAY_STEP_ARCHIVE_MUTATION : PATHWAY_STEP_ARCHIVE_MUTATION
  );
  const duplicateCondition = async conditionStep => {
    setIsProcessingData(loadingDuplicate);
    const pathway_step = { id: conditionStep.id, name: conditionStep.name, step_type: conditionStep.step_type };

    try {
      const { data } = await duplicate({
        variables: {
          pathway_step,
          organizationRevvUid: profileCandidate.organization_revv_uid,
        },
      });

      let { errors: error, duplicatedPathwayStep } =
        data.candidateDuplicatePathwayStep || data.organizationDuplicatePathwayStep;
      setIsProcessingData(loadingDuplicate);

      if (error && error.length > 0) {
        sendToast({
          message: error[0],
          isError: true,
        });
        return;
      }

      sendToast({
        message: 'Condition duplicated.',
      });
      toggleDrawer({ ...duplicatedPathwayStep, isNew: true });
      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;
      honeybadger.notify(message);
      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const { current_page, page_count, total_count, results } = candidate?.pathway_steps;

  const confirmArchive = async () => {
    const archivedConditionId = selectedArchiveCondition?.id;

    setShowArchiveModal(false);
    setIsProcessingData(loadingArchive);

    const pathway_step = { id: archivedConditionId };
    try {
      const { data } = await archive({
        variables: { pathway_step, organizationRevvUid: profileCandidate.organization_revv_uid },
      });

      setIsProcessingData(loadingArchive);

      let { errors: error } = data.candidateArchivePathwayStep || data.organizationArchivePathwayStep;

      if (error && error.length > 0) {
        sendToast({
          message: error,
          isError: true,
        });
        return;
      }

      sendToast({
        message: 'Your Condition has been archived.',
        isError: false,
      });

      refetch();
    } catch (error) {
      setIsProcessingData(false);
      let { message } = error;
      honeybadger.notify(message);

      sendToast({
        message: message,
        isError: true,
      });
    }
  };

  const dropdownAction = (eventKey, item) => {
    switch (eventKey) {
      case 'details':
        toggleDrawer(item);
        break;
      case 'archive':
        setShowArchiveModal(true);
        setSelectedArchiveCondition(item);
        break;
      case 'duplicate':
        duplicateCondition(item);
        break;
      default:
        break;
    }
  };

  const attachedPages = selectedArchiveCondition?.page_count;

  if (loadingAvailableConditions || campaignNameLoading) return <SpinnerContainer />;

  if (campaignNameError) {
    sendToast({ isError: true, message: campaignNameError });
  }

  if (errorAvailableConditions) {
    sendToast({ isError: true, message: errorAvailableConditions });
  }

  if (isEmptyList(candidate?.pathway_steps?.results, filter)) {
    return (
      <>
        <EmptyList
          text="No Conditions"
          description="Conditions allow you to segment your Donors within an Upsell Pathway based on specific criteria. Once defined, Conditions will determine which Upsell(s) a Donor should be shown based on their specific data and previous interactions on WinRed."
          buttonText="Create Condition"
          icon="condition-icon"
          onClick={handleCreateNew}
        />
        <ConditionDrawer
          open={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          selectedCondition={selectedCondition}
          onEditSelectedCondition={onEditSelectedCondition}
          originalEditedCondition={originalEditedCondition}
          availableCustomConditions={availableCustomConditions}
          refetch={refetch}
          campaignsList={campaignNamesList}
        />
      </>
    );
  }

  return (
    <>
      <Breakpoint small down>
        <ConditionItemsMobile items={results} navigateToItem={toggleDrawer} />
      </Breakpoint>
      <Breakpoint medium up>
        <ConditionItemsDesktop items={results} navigateToItem={toggleDrawer} dropdownAction={dropdownAction} />
      </Breakpoint>

      <Pagination
        logEventName="upsell-pathways"
        currentPage={current_page}
        limit={LIST_PAGE_SIZE}
        pageCount={page_count}
        totalCount={total_count}
        setPage={onChangeFilter}
        results={results}
        filter={filter}
        clearFilter={clearFilter}
      />

      <ConfirmModal
        show={showArchiveModal}
        title="Archive Condition"
        buttonText="Archive Condition"
        buttonColor="error"
        showCancelButton
        handleClose={() => setShowArchiveModal(false)}
        onCancel={() => setShowArchiveModal(false)}
        onSubmit={confirmArchive}
      >
        {attachedPages > 0
          ? `Are you sure you want to archive this Condition? It currently belongs to ${attachedPages} upsell pathways${
              attachedPages !== 1 ? 's' : ''
            }. Archiving it will remove it from those pathways.`
          : `Are you sure you want to archive this Condition? This cannot be undone.`}
      </ConfirmModal>
      <ConditionDrawer
        open={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        selectedCondition={selectedCondition}
        onEditSelectedCondition={onEditSelectedCondition}
        originalEditedCondition={originalEditedCondition}
        availableCustomConditions={availableCustomConditions}
        refetch={refetch}
        campaignsList={campaignNamesList}
      />
    </>
  );
}

ConditionsListBody.propTypes = {
  candidate: PropTypes.object,
  isDrawerOpen: PropTypes.bool.isRequired,
  selectedCondition: PropTypes.object,
  originalEditedCondition: PropTypes.object,
  onEditSelectedCondition: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  setIsProcessingData: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};
