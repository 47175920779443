import produce from 'immer';

import {
  SET_EXPORTS_FILTER,
  SET_CUSTOM_REPORTS_FILTER,
  SET_INTEGRATIONS_FILTER,
  SET_AB_TESTING_FILTER,
  SET_MEDIA_LIBRARY_FILTER,
} from './types';
import { SWITCH_PROFILE_CANDIDATE } from 'core/login/types';

export const initialUtilitiesState = {
  exportsFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    page: 1,
    status: null,
    report_type: null,
    recurrence: null,
  },
  customReportsFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    report_type: null,
    page: 1,
  },
  integrationsFilter: {
    dateRangeStart: null,
    dateRangeEnd: null,
    type: null,
    active: 'ACTIVE',
    search: null,
    page: 1,
    source: null,
  },
  abTestingFilter: {
    activeTabKey: 'ALL',
    dateRangeEnd: null,
    dateRangeStart: null,
    page: 1,
    search: null,
    status: 'ALL',
  },
  mediaLibraryFilter: {
    activeTabKey: 'ALL',
    page: 1,
    search: null,
    imageType: [],
  },
};

export default produce((state = initialUtilitiesState, action) => {
  switch (action.type) {
    case SET_EXPORTS_FILTER:
      return { ...state, exportsFilter: action.filter };
    case SET_CUSTOM_REPORTS_FILTER:
      return { ...state, customReportsFilter: action.filter };
    case SET_INTEGRATIONS_FILTER:
      return { ...state, integrationsFilter: action.filter };
    case SET_AB_TESTING_FILTER:
      return { ...state, abTestingFilter: action.filter };
    case SET_MEDIA_LIBRARY_FILTER:
      return { ...state, mediaLibraryFilter: action.filter };
    case SWITCH_PROFILE_CANDIDATE:
      return { ...initialUtilitiesState };
    default:
      return state;
  }
}, initialUtilitiesState);
