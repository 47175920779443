import { useRef } from 'react';
import { useAmplitude } from 'modules/common';

function useAmplitudeEffect({ item = undefined, eventName, generateEventProps }) {
  const logEventRef = useRef(false);
  const [logEvent] = useAmplitude();

  // only allow this to happen one time once data is loaded
  if (logEventRef.current || item !== undefined) return;

  logEventRef.current = true;
  logEvent(eventName, generateEventProps ? generateEventProps() : null);
}

export default useAmplitudeEffect;
