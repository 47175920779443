import { useCallback } from 'react';
import { useToast, useAmplitude } from 'modules/common';

export function useClipBoard() {
  const setToast = useToast();
  const [logEvent] = useAmplitude();

  const copyToClipBoard = useCallback(
    ({ text, toast, logEventParams }) => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;

      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      if (logEventParams) logEvent(logEventParams.name, logEventParams.parameters);
      if (toast) setToast({ message: toast });
    },
    [logEvent, setToast]
  );

  return copyToClipBoard;
}
