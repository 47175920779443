import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import '../../widgets.scss';
import { Typography, Button, WinRedAnimate, Input } from 'modules/common';
import { noop, formatAmount } from 'core/utilities';

function WidgetPreviewAmountAnimation({ widget, donationAmounts, secondaryColor }) {
  return (
    <Container className="widget-preview_btnAnimation">
      <Typography>{widget.countdownLabel || ''}</Typography>
      <Row>
        <Col xs={12} className="widget-preview_btnAnimation_btn-wrapper">
          {donationAmounts.map((amt, index) => {
            if (widget.amountButtonAnimationPosition - 1 === index) {
              return (
                <WinRedAnimate
                  key={amt.id}
                  animation={widget.amountButtonAnimationStyle}
                  delay={widget.amountButtonAnimationDelay}
                  speed={widget.amountButtonAnimationDuration}
                  isRepeating={widget.amountButtonAnimationRepeat}
                  repeatInterval={widget.amountButtonAnimationRepeatAfter}
                >
                  <Button style={{ backgroundColor: widget.amountButtonAnimationColor }} onClick={noop}>
                    {formatAmount(amt.amount, false, false)}
                  </Button>
                </WinRedAnimate>
              );
            }

            return (
              <Button key={amt.id} style={{ backgroundColor: secondaryColor }} onClick={noop}>
                {formatAmount(amt.amount, false, false)}
              </Button>
            );
          })}
          <Input
            moneyInput
            classNameWrapper="widget-preview_btnAnimation_btn-other"
            id="otherDonation"
            name="Other"
            value=""
            placeholder="Other"
            disabled
            onChange={noop}
          />
        </Col>
      </Row>
    </Container>
  );
}

WidgetPreviewAmountAnimation.propTypes = {
  widget: PropTypes.object.isRequired,
  donationAmounts: PropTypes.array.isRequired,
  secondaryColor: PropTypes.string.isRequired,
};

export default WidgetPreviewAmountAnimation;
