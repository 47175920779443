import React from 'react';

import CandidatesDrawerList from './candidates-drawer-list.component';
import { CandidateDrawerFooter } from './candidate-drawer-footer.component';
import './candidate-drawer.scss';

export default function CandidateDrawer({ closeUserMenu }) {
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div>
        <CandidatesDrawerList closeUserMenu={closeUserMenu} />
      </div>

      <CandidateDrawerFooter />
    </div>
  );
}
