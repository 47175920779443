import { isDevelopment } from 'core/utilities';
import honeybadger from 'honeybadger-js';

export const sendToIntercom = (profile, honeyId) => {
  // if donor user dont enable intercom
  if (!profile.candidate) return removeIntercom();

  const company = {
    company_id: profile.candidate?.organization_revv_uid,
    name: profile.candidate?.name,
    created_at: profile.candidate?.created_at,
    Level: profile.candidate.isOrganization ? 'State' : 'Federal',
  };

  const intercomUser = {
    app_id: 'wiir0icl',
    user_id: profile.revv_uid,
    name: profile.full_name,
    email: profile.email,
    created_at: profile.created_at,
    user_hash: profile.intercom_user_hash,
    company,
  };

  if (honeyId) intercomUser['honeybadger id'] = honeyId;
  if (isDevelopment) console.debug('intercom', intercomUser);

  try {
    window.Intercom('boot', intercomUser);
  } catch (error) {
    console.error({ error });
    const context = {
      intercomUser: intercomUser,
      url: window.location.href,
      user_event: "try executing window.Intercom('boot', intercomUser) at intercom.tools.js",
      profile_email: profile.email,
      profile_intercom_user_hash: profile.intercom_user_hash,
      honeyId: honeyId,
    };
    honeybadger.notify(error, { context });
  }
};

export const removeIntercom = () => {
  window.Intercom && window.Intercom('shutdown');
};
