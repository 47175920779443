import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, WinRedInput } from 'modules/common';

export function StepName({ editedStep, onUpdateEditedStep, disabledStep, type }) {
  const stepType = useMemo(() => {
    if (type === 'condition') {
      return 'Condition';
    }
    return editedStep?.step_type === 'END_STEP' ? 'End Step' : 'Step';
  }, [editedStep?.step_type, type]);
  return (
    <>
      <Card.Title subtitle={`A descriptive name for your ${stepType}.`}>{stepType} Name</Card.Title>
      <WinRedInput
        key={editedStep?.revv_uid}
        type="text"
        name="name"
        value={editedStep?.name || ''}
        onChange={onUpdateEditedStep}
        disabled={disabledStep}
        aria-label={`${stepType} Name`}
        placeholder={`${stepType} Name*`}
        validators={[
          {
            message: `${stepType} Name is required.`,
            validator: 'required',
          },
        ]}
      />
    </>
  );
}

StepName.propTypes = {
  editedStep: PropTypes.object,
  onUpdateEditedStep: PropTypes.func.isRequired,
  disabledStep: PropTypes.bool,
  type: PropTypes.string,
};
