import { useState, useCallback, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { isOrganizationSelector, profileCandidateSelector } from 'core/login';
import { useToast } from 'modules/common';

const useRecursiveNetworkRequest = ({
  // mutation that sends data
  initialMutation,
  // query for getting job results and status
  getResultsQuery,
  // How many times to re try results query
  retryCount,
  // milliseconds between each re-request
  retryInterval,
  // function for completed status
  onComplete,
  // query data name for initial mutation
  mutationFieldName,
  // function for maxed out request
  onMaxedOut,
  // time before first results request
  initialWaitTime,
  // milliseconds amount for progress interval
  intervalTime,
}) => {
  const isOrganization = useSelector(isOrganizationSelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const [progressInterval, setProgressInterval] = useState(0);
  const [requestAttempt, setRequestAttempt] = useState(0);

  const setToast = useToast();

  const timerIdRef = useRef(null);
  const countingIdRef = useRef(null);

  const clearQueryTimers = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
      timerIdRef.current = null;
    }
    if (countingIdRef.current) {
      clearTimeout(countingIdRef.current);
      countingIdRef.current = null;
    }
  };

  const [recursiveRequestMutation, { error: mutationError }] = useMutation(initialMutation, {
    onCompleted: data => {
      const { process_id } =
        data[isOrganization ? `organization${mutationFieldName}` : `candidate${mutationFieldName}`];
      if (process_id) {
        recursiveNetworkRequest(process_id, initialWaitTime, 0);
      }
    },
  });

  const [getStatusAndResults] = useLazyQuery(getResultsQuery);

  const recursiveNetworkRequest = useCallback(
    async (id, timeoutInterval, retries) => {
      if (retries > retryCount) {
        clearQueryTimers();
        onMaxedOut();
        return;
      }
      // Creates incremented progress value for visual display before first results request
      if (retries === 0) {
        countingIdRef.current = await setInterval(async () => {
          setProgressInterval(prevState => prevState + 1);
        }, intervalTime + 250);
      }
      timerIdRef.current = await setTimeout(async () => {
        try {
          const response = await getStatusAndResults({
            variables: {
              revv_uid: id,
              organizationRevvUid: profileCandidate.organization_revv_uid,
            },
          });
          const candidate = response?.data?.viewer?.candidate || response?.data?.viewer?.state_level_organization;
          const { status, results } = candidate?.background_job_result;

          if (status === 'COMPLETED') {
            clearQueryTimers();
            onComplete(results);
            return;
          }

          if (status === 'PROCESSING') {
            setRequestAttempt(retries + 1);
            recursiveNetworkRequest(id, retryInterval, retries + 1);
          }
        } catch (err) {
          clearQueryTimers();
          setToast({ message: err.message, isError: true });
        }
      }, timeoutInterval);
    },
    [
      retryCount,
      retryInterval,
      getStatusAndResults,
      profileCandidate.organization_revv_uid,
      intervalTime,
      onComplete,
      onMaxedOut,
      setToast,
    ]
  );

  return {
    recursiveRequestMutation,
    mutationError,
    progressInterval,
    requestAttempt,
    clearQueryTimers,
  };
};

export default useRecursiveNetworkRequest;
