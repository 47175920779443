import React from 'react';

export function PaginationDesktop({ uiTotalCount, pageButtons, roundedTotalPaginationNumber, currentPage, ellipses }) {
  return (
    <div>
      <span className="mr-2">{uiTotalCount} Results</span>
      {roundedTotalPaginationNumber > 5 ? (
        currentPage <= 3 ? (
          <>
            {pageButtons[0]}
            {pageButtons[1]}
            {pageButtons[2]}
            {pageButtons[3]}
            {ellipses}
            {pageButtons[pageButtons.length - 1]}
          </>
        ) : currentPage === pageButtons.length - 1 ? (
          <>
            {pageButtons[0]}
            {ellipses}
            {pageButtons[currentPage - 3]}
            {pageButtons[currentPage - 2]}
            {pageButtons[currentPage - 1]}
            {pageButtons[pageButtons.length - 1]}
          </>
        ) : currentPage === pageButtons.length - 2 ? (
          <>
            {pageButtons[0]}
            {ellipses}
            {pageButtons[currentPage - 2]}
            {pageButtons[currentPage - 1]}
            {pageButtons[currentPage]}
            {pageButtons[pageButtons.length - 1]}
          </>
        ) : currentPage === pageButtons.length ? (
          <>
            {pageButtons[0]}
            {ellipses}
            {pageButtons[currentPage - 4]}
            {pageButtons[currentPage - 3]}
            {pageButtons[currentPage - 2]}
            {pageButtons[pageButtons.length - 1]}
          </>
        ) : (
          <>
            {pageButtons[0]}
            {ellipses}
            {pageButtons[currentPage - 2]}
            {pageButtons[currentPage - 1]}
            {pageButtons[currentPage]}
            {ellipses}
            {pageButtons[pageButtons.length - 1]}
          </>
        )
      ) : (
        pageButtons
      )}
    </div>
  );
}
