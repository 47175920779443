import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, ConfirmModal, Icon, Typography, useToast } from 'modules/common';
import '../../../upsells/new-upsell.scss';
import React, { useState, useMemo } from 'react';
import { isEqual } from 'lodash';

export function StepFooter({
  selectedStep,
  originalEditedStep,
  onConfirmCloseEdit,
  onSaveEditedStep,
  validationBlock,
  disableStep,
  isNewCondition,
  type,
}) {
  const disabled = disableStep || selectedStep?._isDisabled;

  const [showCloseClose, setShowCloseModal] = useState(false);

  const sendToast = useToast();

  const closeModal = () => setShowCloseModal(false);

  const abandonChanges = () => {
    if (disableStep) {
      onConfirmCloseEdit();
      return null;
    }
    setShowCloseModal(true);
  };

  const hasName = useMemo(() => {
    return !!selectedStep?.name;
  }, [selectedStep?.name]);

  const routePercentageFull = useMemo(() => {
    const trafficSum = selectedStep?.actions
      ?.filter(action => !action._destroy)
      ?.map(action => {
        return parseFloat(action?.percentage);
      })
      ?.reduce((sum, percentage) => sum + percentage, 0);
    return trafficSum === 100;
  }, [selectedStep?.actions]);

  const hasCondition = useMemo(() => {
    return (
      selectedStep?.conditions?.filter(condition => !condition._destroy && condition?.key && condition?.predicate)
        .length > 0
    );
  }, [selectedStep?.conditions]);

  const hasRedirect = useMemo(() => {
    return selectedStep?.actions?.filter(action => !action._destroy && action?.percentage && action?.value).length > 0;
  }, [selectedStep?.actions]);

  const disableAbandonChanges =
    (!selectedStep?._isNew && isEqual(selectedStep, originalEditedStep)) ||
    disabled ||
    (selectedStep?.step_type === 'REGULAR_STEP' && !hasCondition) ||
    !hasRedirect ||
    !hasName ||
    !routePercentageFull;

  const disableConditionChanges =
    (!selectedStep?._isNew && isEqual(selectedStep, originalEditedStep)) || disabled || !hasCondition || !hasName;

  return (
    <Container className="new-upsell-footer">
      <Row>
        <Col className="flex items-center">
          <Button
            variant="outline-primary"
            className="mr-2"
            onClick={() => {
              abandonChanges();
            }}
          >
            Cancel
          </Button>
          {disabled ? null : (
            <Button
              variant="success"
              className="mr-2"
              disabled={type === 'condition' ? disableConditionChanges : disableAbandonChanges}
              onClick={() => {
                if (validationBlock?.current?.errors()) return;
                if (type !== 'condition' && selectedStep?.actions?.filter(action => !action._destroy).length < 1) {
                  sendToast({ isError: true, message: 'Please add at least 1 redirect route action to your Step.' });
                  return;
                }
                if (
                  selectedStep?.step_type === 'REGULAR_STEP' &&
                  selectedStep?.conditions?.filter(condition => !condition._destroy).length < 1
                ) {
                  sendToast({ isError: true, message: 'Please add at least 1 condition to your Step.' });
                  return;
                }
                onSaveEditedStep();
              }}
            >
              Save
            </Button>
          )}
          {type === "condition" && !isNewCondition ? (
            <div className="flex items-center">
              <Icon icon="exclamation-triangle" className="mr-1" color="gold" size="1x" />
              <Typography className="mb-0" italic>
              Changes to this Condition will apply to each instance of it, across all Upsell Pathways and Pages where it is used.
              </Typography>
            </div>
          ) : null}
        </Col>
      </Row>

      <ConfirmModal
        show={showCloseClose}
        title="Abandon Changes"
        buttonText="Abandon"
        showCancelButton
        handleClose={closeModal}
        onCancel={closeModal}
        onSubmit={() => {
          setShowCloseModal(false);
          onConfirmCloseEdit();
        }}
      >
        Are you sure you want to abandon your changes?
      </ConfirmModal>
    </Container>
  );
}

StepFooter.propTypes = {
  selectedStep: PropTypes.object,
  originalEditedStep: PropTypes.object,
  onConfirmCloseEdit: PropTypes.func.isRequired,
  onSaveEditedStep: PropTypes.func.isRequired,
  validationBlock: PropTypes.any,
  disableStep: PropTypes.bool,
  type: PropTypes.string,
};
