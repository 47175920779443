import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import './table-inputs.scss';

function TableInput({ children, ...rest }) {
  return (
    <Col xs={12} lg={3} className="table-input" {...rest}>
      {children}
    </Col>
  );
}

TableInput.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableInput;
