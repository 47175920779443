import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import { EmptyList, Pagination, withAmplitude, AmplitudePropTypes } from 'modules/common';
import TeamListBodyDesktop from './list-body/desktop.component';
import TeamListBodyMobile from './list-body/mobile.component';

function TeamPagesListBody({ history, match, pages, handleCreateNewPage, onChangeFilter }) {
  const { current_page, page_count, total_count, results } = pages;
  const navigateToItem = uuid => history.push(`${match.url}/${uuid}`);

  if (!results || results.length === 0) {
    return (
      <>
        <EmptyList
          text="No Team Pages"
          description="Create a page to start raising money for your favorite candidates and committees!"
          buttonText="Create Team Page"
          icon="donate"
          name="page"
          onClick={handleCreateNewPage}
        />
      </>
    );
  }

  return (
    <>
      <Breakpoint small down>
        <TeamListBodyMobile items={results} navigateToItem={navigateToItem} />
      </Breakpoint>
      <Breakpoint medium up>
        <TeamListBodyDesktop items={results} navigateToItem={navigateToItem} />
      </Breakpoint>
      <Pagination currentPage={current_page} pageCount={page_count} totalCount={total_count} setPage={onChangeFilter} />
    </>
  );
}

TeamPagesListBody.propTypes = {
  logEvent: AmplitudePropTypes.logEvent.isRequired,
  pages: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  handleCreateNewPage: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default compose(withRouter, withAmplitude)(TeamPagesListBody);
