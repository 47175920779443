import gql from 'graphql-tag';
import { EMAIL_FRAGMENT } from './emails.queries';

export const GET_ORGANIZATION_EMAILS_QUERY = gql`
  query organizationGetEmails($organizationRevvUid: String, $page: Int, $limit: Int) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        emails(filters: { page: $page, limit: $limit }) {
          results {
            ...Email
          }
        }
      }
    }
  }
  ${EMAIL_FRAGMENT}
`;

export const SEND_ORGANIZATION_TEST_EMAIL_MUTATION = gql`
  mutation organizationSendTestEmail($attributes: TestEmailInput!) {
    organizationTestEmail(emailAttributes: $attributes) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_EMAIL_MUTATION = gql`
  mutation organizationUpdateEmail($email: UpdateEmailInput!) {
    organizationUpdateEmail(emailAttributes: $email) {
      errors
    }
  }
`;
