import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import logo from 'assets/images/winred-logo.svg';
import Logout from 'modules/layout/logout.component';
import './layout.scss';

function FooterComponent({ history }) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = isLoggingOut => {
    setShowLogoutModal(false);
    if (isLoggingOut) history.push('/logout');
  };

  return (
    <Container as="footer" fluid className="footer-container">
      <Logout handleLogout={handleLogout} showLogoutModal={showLogoutModal} />
      <Row>
        <Col xs="12" className="footer-col">
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="11" className="pl-0 footer-links">
                <div className="d-sm-inline-block d-block text-center text-lg-left footer-item">
                  <Link to="/">
                    <img src={logo} alt="WinRed logo" />
                  </Link>
                </div>
                <div className="d-sm-inline-block d-block text-center text-lg-left footer-item">
                  <a href="https://winred.com/terms/terms-of-use/" target="_blank" rel="noopener noreferrer">
                    Terms
                  </a>
                </div>
                <div className="d-sm-inline-block d-block text-center text-lg-left footer-item">
                  <a href="https://winred.com/privacy/?utm_source=winred-app" target="_blank" rel="noopener noreferrer">
                    Privacy
                  </a>
                </div>
                <div className="d-sm-inline-block d-block text-center text-lg-left footer-item">
                  <a href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                    Support
                  </a>
                </div>
                <div
                  className="d-sm-inline-block d-block text-center text-lg-left footer-item"
                  onKeyPress={() => setShowLogoutModal(true)}
                  onClick={() => setShowLogoutModal(true)}
                  role="button"
                  tabIndex="0"
                >
                  Sign Out
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

FooterComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(FooterComponent);
