import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Nav from 'react-bootstrap/Nav';

function Item({ children, eventKey, activeKey, disabled = false, ampkey, onSelect, ...props }) {
  const selectedItem = eventKey === activeKey;

  return (
    <Nav.Item
      className={classNames('nav-item', {
        'winred-nav__nav-item--active': !disabled && selectedItem,
        'winred-nav__nav-item': !disabled,
      })}
      aria-selected={selectedItem ? 'true' : 'false'}
      data-ampkey={ampkey}
    >
      <Nav.Link eventKey={eventKey} onSelect={onSelect} {...props}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string,
  activeKey: PropTypes.string,
  disabled: PropTypes.bool,
  ampkey: PropTypes.string,
};

export default Item;
