import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BsButton from 'react-bootstrap/Button';

const forwardRef = (props, ref) => {
  const { className, children, variant, alt, fullWidth, square, disabled, ariaLabel, skinnyButton, url, ...rest } =
    props;
  /**
   * variants:
   * - primary (blue)
   * - success (green)
   * - default (white)
   * - cancel (red)
   */

  return (
    // TODO: refactor so we dont rely on alt
    <BsButton
      as={url ? Link : 'button'}
      to={url}
      className={classNames(className, 'btn', 'winred-btn', {
        [`btn-${variant} winred-btn--${variant}`]: !alt,
        [`winred-btn--alt winred-btn--alt--${variant}`]: alt && variant,
        rounded: square,
        'w-100': fullWidth,
        'winred-btn--alt': alt && !variant,
        'py-2 px-3': skinnyButton,
      })}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      {...rest}
      ref={ref}
    >
      {children}
    </BsButton>
  );
};
forwardRef.displayName = 'Button';

const Button = React.forwardRef(forwardRef);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.string,
  alt: PropTypes.bool,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  skinnyButton: PropTypes.bool,
};

Button.defaultProps = {
  alt: false,
  className: '',
  fullWidth: false,
  skinnyButton: false,
  square: false,
  variant: 'primary',
};

forwardRef.propTypes = Button.propTypes;

export default Button;
