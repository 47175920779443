import { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidationContext } from './validation-block.component.js';
import isEqual from 'lodash/isEqual';

class ValidatorComponent extends Component {
  static contextType = ValidationContext;
  state = {
    errorIdx: -1,
  };

  componentDidMount() {
    if (this.context) this.context.attach(this);
    if (this.props.validateOnMount) this.checkIfIsInvalid();
  }

  componentWillUnmount() {
    if (this.context) this.context.detach(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.alwaysValidate) return this.checkIfIsInvalid();

    /* Validate inputs when either the value or the validator changes */
    if (prevProps.value === this.props.value && isEqual(this.props.validators, prevProps.validators)) return;

    // only validate on blur if flag enabled
    if (this.props.validateOnBlurOnly) return;
    this.checkIfIsInvalid();
  }

  checkIfIsInvalid = () => {
    /* Return no error if validators aren't provided. */
    if (!this.props.validators) return false;

    const hasErrors = this.props.validators.reduce((inputHasError, validator, idx) => {
      /* Check to see if this input value has an error using the current validator. */
      const validatorHasError = this.inputHasErrorCheck(this.props.value, validator);

      /* If the input hasn't been marked as having an error yet and this 
        validator reports an error, mark the input as having an error at this location. 
      */
      if (!inputHasError && validatorHasError) {
        this.setState({ errorIdx: idx });
        return validatorHasError ? this.props.errorMessages[idx] || validatorHasError : validatorHasError;

        /* If this validator previously had an error but it doesn't now, clear the error state for the input. */
      } else if (inputHasError && this.state.errorIdx === idx && !validatorHasError) {
        this.setState({ errorIdx: -1 });
        return validatorHasError;
      }

      // /* If none of the cases match, return the previous input error state if there is one. */
      if (validatorHasError) return this.props.errorMessages[idx] || validatorHasError;

      /* If none of the cases match, return the previous input error state. */
      return inputHasError;
    }, this.state.errorIdx > -1);

    return hasErrors;
  };

  inputHasErrorCheck = (value, validator) => {
    /* If the validator is a function, use it to validate this input */

    if (typeof validator === 'function') {
      /* The function should return true for valid inputs and false for 
      invalid inputs. We return the opposite so we not (!) the function's 
      result */
      return !validator(value);
    } else if (validator instanceof RegExp) {
      /* The regex should return true for valid inputs and false for 
      invalid inputs. We return the opposite so we not (!) the regex 
      match result */

      return !value.toString().match(validator);
    } else if (typeof validator === 'string') {
      switch (validator) {
        case 'required':
          if (!value) {
            return true;
          } else {
            return false;
          }
        /* If no validator was provided or the validator is unknown, don't report any errors. */
        default:
          return false;
      }
    } else {
      console.error('Invalid validator!', validator);
      return false;
    }
  };

  errorMessage = () => {
    if (!this.props.errorMessages) return null;

    const { errorIdx } = this.state;
    if (errorIdx === -1 || errorIdx === undefined) return null;

    if (this.props.errorMessages[errorIdx] !== null || this.props.errorMessages[errorIdx] !== undefined) {
      return this.props.errorMessages[errorIdx];
    } else {
      return 'Error!';
    }
  };

  /* Validate onBlur if this is a required field. */
  onBlur = () => {
    const { onBlur } = this.props;
    this.checkIfIsInvalid();

    if (onBlur && typeof onBlur === 'function') {
      onBlur();
    }
  };
}

ValidatorComponent.propTypes = {
  validators: PropTypes.array.isRequired,
  onBlur: PropTypes.func,
  errorMessages: PropTypes.array.isRequired,
  validateOnMount: PropTypes.bool,
  value: PropTypes.any,
  validateOnBlurOnly: PropTypes.bool,
};

export default ValidatorComponent;
