import gql from 'graphql-tag';

export const integrationFragment = gql`
  fragment Integration on Integration {
    revvUid
    type
    name
    note
    source
    active

    integrationActivity(filters: { page: 1, limit: 9999 }) {
      results {
        id
        activityType
        date
        email
      }
    }

    aristotleFields {
      apiKey
    }
    cmdiFields {
      cmdiReportType
      ftpPath
      ftpAddress
      username
      password
    }
    directSndFields {
      apiKey
      baseDomain
      sendUrlAndUtmData
    }
    ispFields {
      accountName
    }
    facebookConversionFields {
      apiKey
      facebookPixelId
      testEventCodeId
    }
    facebookPixelFields {
      pixelId
    }
    googleAnalyticsFields {
      trackingId
    }
    googleTagManagerFields {
      containerId
    }
    ftpFields {
      ftpAddress
      ftpPath
      password
      username
    }
    webhookFields {
      url
      extendedRetries
      httpMethod
      headerIntegrationKeyValues {
        id
        key
        staticValue
        ikvType
      }
      cccIntegrationKeyValues {
        id
        key
        staticValue
        commandKey
        ikvType
        custom
      }

      conduitCandidateChargeCreatedEnabled
      conduitCandidateChargeRefundedEnabled
      conduitCandidateChargeDisputeLostEnabled
      cccIntegrationFilters {
        id
        attributeName
        ikvType
        value
        predicate
      }

      leadSignUpEnabled
      leadIntegrationKeyValues {
        id
        key
        staticValue
        commandKey
        ikvType
        custom
      }
      leadIntegrationFilters {
        id
        attributeName
        ikvType
        value
        predicate
      }

      subscriptionCreatedEnabled
      subscriptionIntegrationKeyValues {
        id
        key
        staticValue
        commandKey
        ikvType
        custom
      }
    }
    s3Fields {
      bucket
      privateKey
      publicKey
      region
    }
    zapierFields {
      zaps {
        id
        targetUrl
        createdAt
        event
      }
    }
    nucleusFields {
      clientId
    }
    m2xFields {
      clientId
    }
    tealiumFields {
      account
      profile
    }
    salesforceMarketingCloudFields {
      privateKey
      publicKey
      businessUnitMid
      authenticationBaseUri
      emailDataExtensionEnabled
      mobileDataExtensionEnabled
      petitionEmailDataExtensionEnabled
      emailDataExtensionName
      mobileDataExtensionName
      petitionEmailDataExtensionName
      emailDataExtensionStatus
      mobileDataExtensionStatus
      petitionEmailDataExtensionStatus
    }
    tatangoSmsFields {
      email
      apiKey
      defaultListId
      disableDoubleOptinProcess
      disableOptinConfirmationMessage
    }
    iterableFields {
      apiKey
      listId
      leadListId
      resubscribe
      purchaseIntegrationKeyValues {
        id
        key
        commandKey
        staticValue
        ikvType
      }
      subscriberIntegrationKeyValues {
        id
        key
        commandKey
        staticValue
        ikvType
      }
      leadIntegrationKeyValues {
        id
        key
        commandKey
        staticValue
        ikvType
      }
    }
    nationBuilderFields {
      nationSlug
      oauthUrl
      connected
      listId
      personIntegrationKeyValues {
        id
        key
        commandKey
        staticValue
        ikvType
        lambdaOptions
      }
      donationIntegrationKeyValues {
        id
        key
        commandKey
        staticValue
        ikvType
        lambdaOptions
      }
    }
    trailblazerFields {
      workspace
      oauthUrl
      connected
    }
  }
`;

export const GET_INTEGRATION_QUERY = gql`
  query getIntegration($organizationRevvUid: String, $revvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        integrations(filters: { revvUid: $revvUid }) {
          results {
            ...Integration
          }
        }
      }
    }
  }
  ${integrationFragment}
`;

export const DELETE_INTEGRATION = gql`
  mutation DeleteIntegrationMutation($integration: DeleteIntegrationInput!, $organizationRevvUid: String) {
    candidateDeleteIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;

export const GET_ORGANIZATION_INTEGRATION_QUERY = gql`
  query getOrganizationIntegration($revvUid: String, $organizationRevvUid: String) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        integrations(filters: { revvUid: $revvUid }) {
          results {
            ...Integration
          }
        }
      }
    }
  }
  ${integrationFragment}
`;

export const UPDATE_INTEGRATION = gql`
  mutation IntegrationMutation($integration: IntegrationInput!, $organizationRevvUid: String) {
    candidateCreateOrUpdateIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      integration {
        revvUid
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_INTEGRATION = gql`
  mutation OrganizationIntegrationMutation($integration: IntegrationInput!) {
    organizationCreateOrUpdateIntegration(integration: $integration) {
      errors
      integration {
        revvUid
      }
    }
  }
`;

export const UPDATE_VENDOR_INTEGRATION = gql`
  mutation VendorIntegrationMutation($integration: IntegrationInput!, $organizationRevvUid: String) {
    vendorCreateOrUpdateIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      integration {
        revvUid
      }
    }
  }
`;

export const DELETE_ORGANIZATION_INTEGRATION = gql`
  mutation DeleteOrganizationnIntegrationMutation($integration: DeleteIntegrationInput!) {
    organizationDeleteIntegration(integration: $integration) {
      errors
      message
    }
  }
`;

export const GET_VENDOR_INTEGRATION_QUERY = gql`
  query getVendorIntegration($revvUid: String, $organizationRevvUid: String) {
    viewer {
      vendor(revvUid: $organizationRevvUid) {
        integrations(filters: { revvUid: $revvUid }) {
          results {
            ...Integration
          }
        }
      }
    }
  }
  ${integrationFragment}
`;

export const DELETE_VENDOR_INTEGRATION = gql`
  mutation DeleteVendorIntegrationMutation($integration: DeleteIntegrationInput!, $organizationRevvUid: String) {
    vendorDeleteIntegration(integration: $integration, organizationRevvUid: $organizationRevvUid) {
      errors
      message
    }
  }
`;
