import { convertEpochTime } from 'core/utilities';

const formatValue = (type, value) => {
  switch (type) {
    case 'percent':
      if (!value) return '-';

      return `${value}%`;
    default:
      return value;
  }
};

const isVendorActivity = activityType => {
  switch (activityType) {
    case 'VENDOR_BILL_PAY_CONNECTED' | 'VENDOR_BILL_PAY_DISCONNECTED' | 'VENDOR_DEFAULT_FEE_CHANGED':
      return true;
    default:
      return false;
  }
};

const generateBillPaySentence = activityData => {
  switch (activityData.activityType) {
    case 'BILL_PAY_FEE_ADDED':
      return `Bill-Pay for ${activityData.vendorName} was set to ${activityData.changedTo}% for ${
        activityData.organizationName
      } on ${convertEpochTime(activityData.date)} by ${activityData.email}.`;
    case 'BILL_PAY_FEE_REMOVED':
      return `Bill-Pay for ${activityData.vendorName} was removed from ${
        activityData.organizationName
      } on ${convertEpochTime(activityData.date)} by ${activityData.email}.`;
    case 'BILL_PAY_FEE_UPDATED':
      return `Bill-Pay for ${activityData.vendorName} was updated from ${activityData.changedFrom}% to ${
        activityData.changedTo
      }% for ${activityData.organizationName} on ${convertEpochTime(activityData.date)} by ${activityData.email}.`;
    case 'BILL_PAY_CAMPAIGN_ADDED':
      return `Merchandise Bill Pay Campaign titled ${activityData.changedTo} was added on ${convertEpochTime(
        activityData.date
      )} by ${activityData.email}`;
    case 'BILL_PAY_CAMPAIGN_REMOVED':
      return `Merchandise Bill Pay Campaign titled ${activityData.changedFrom} was removed from ${convertEpochTime(
        activityData.date
      )} by ${activityData.email}`;
    case 'BILL_PAY_CAMPAIGN_UPDATED':
      return `Merchandise Bill Pay Campaign was updated from ${activityData.changedFrom} to ${
        activityData.changedTo
      } on ${convertEpochTime(activityData.date)} by ${activityData.email}`;
    default:
      return activityData.activityType;
  }
};

const mostRecentBillPaySentence = activityData => {
  const isPageBuilder = activityData.objectType === 'Page';
  switch (activityData.activityType) {
    case 'BILL_PAY_FEE_ADDED':
      return `Bill-Pay ${isPageBuilder ? '(Agency Fee)' : ''} added on ${convertEpochTime(activityData.date)} by ${
        activityData.email
      }`;
    case 'BILL_PAY_FEE_UPDATED':
      return `Bill-Pay ${isPageBuilder ? '(Agency Fee)' : ''} updated on ${convertEpochTime(activityData.date)} by ${
        activityData.email
      }`;
    default:
      return '';
  }
};

const linkBuilder = (profileCandidateRevvUid, revvUid, linkType) => {
  switch (linkType) {
    case 'Upsell':
      return `/${profileCandidateRevvUid}/pages/upsell-flows/upsells/${revvUid}`;
    case 'Page':
      return `/${profileCandidateRevvUid}/pages/my-pages/${revvUid}`;
    default:
  }
};

export { formatValue, generateBillPaySentence, linkBuilder, mostRecentBillPaySentence, isVendorActivity };
