import {
  dateRangeFilterUi,
  searchFilterUi,
  amountFilterUi,
  dropdownFilterUi,
  multiDropdownFilterUi,
} from 'modules/common';
import { getDonationDonorName } from 'core/utilities';

import { US_STATES_FULL_NAME } from 'modules/settings/tabs/shared/us-states.tool';
import { generateContributorTypeOptions } from 'modules/people/donors/donors.tools';

export const TRANSACTION_TYPE_MAP = {
  DONATION: 'donation',
  REFUND: 'refund',
  PAYOUT: 'payout',
  DISPUTE: 'dispute',
  DISPUTE_FEE: 'dispute_fee',
  CHARGE: 'charge',
  CHARGE_DISPUTE: 'charge_dispute',
  CHARGE_DISPUTE_FEE: 'charge_dispute_fee',
  INVOICE: 'invoice',
  VOID: 'void',
  ADJUSTMENT: 'adjustment',
};

export const TRANSACTION_ICON_MAP = {
  [TRANSACTION_TYPE_MAP.DONATION]: 'donate',
  [TRANSACTION_TYPE_MAP.REFUND]: 'refund-icon',
  [TRANSACTION_TYPE_MAP.PAYOUT]: 'invoice-in',
  [TRANSACTION_TYPE_MAP.DISPUTE]: 'exclamation-triangle',
  [TRANSACTION_TYPE_MAP.DISPUTE_FEE]: 'exclamation-triangle',
  [TRANSACTION_TYPE_MAP.CHARGE]: 'donate',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE]: 'exclamation-triangle',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE_FEE]: 'exclamation-triangle',
  [TRANSACTION_TYPE_MAP.INVOICE]: 'invoice-out',
  [TRANSACTION_TYPE_MAP.VOID]: 'void-icon',
  [TRANSACTION_TYPE_MAP.ADJUSTMENT]: 'donate',
};

export const TRANSACTION_ICON_COLOR_MAP = {
  [TRANSACTION_TYPE_MAP.DONATION]: 'green',
  [TRANSACTION_TYPE_MAP.REFUND]: 'yellow',
  [TRANSACTION_TYPE_MAP.PAYOUT]: 'green',
  [TRANSACTION_TYPE_MAP.DISPUTE]: 'red',
  [TRANSACTION_TYPE_MAP.DISPUTE_FEE]: 'red',
  [TRANSACTION_TYPE_MAP.CHARGE]: 'green',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE]: 'red',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE_FEE]: 'red',
  [TRANSACTION_TYPE_MAP.INVOICE]: 'green',
  [TRANSACTION_TYPE_MAP.VOID]: 'black',
  [TRANSACTION_TYPE_MAP.ADJUSTMENT]: 'yellow',
};

export const TRANSACTION_NAME_MAP = {
  [TRANSACTION_TYPE_MAP.DONATION]: 'Donation',
  [TRANSACTION_TYPE_MAP.REFUND]: 'Refund',
  [TRANSACTION_TYPE_MAP.PAYOUT]: 'Payout',
  [TRANSACTION_TYPE_MAP.DISPUTE]: 'Dispute',
  [TRANSACTION_TYPE_MAP.DISPUTE_FEE]: 'Dispute Fee',
  [TRANSACTION_TYPE_MAP.CHARGE]: 'Donation',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE]: 'Donation Dispute',
  [TRANSACTION_TYPE_MAP.CHARGE_DISPUTE_FEE]: 'Donation Dispute Fee',
  [TRANSACTION_TYPE_MAP.INVOICE]: 'Invoice',
  [TRANSACTION_TYPE_MAP.VOID]: 'Void',
  [TRANSACTION_TYPE_MAP.ADJUSTMENT]: 'Adjustment',
};

export const computeTransactionType = transaction_type => {
  return TRANSACTION_NAME_MAP[transaction_type] || transaction_type || 'Unknown';
};

export const computeTransactionIcon = transaction => {
  // donation/charges have different icons depending on contributor type
  if (
    TRANSACTION_TYPE_MAP.CHARGE === transaction.transaction_type ||
    TRANSACTION_TYPE_MAP.DONATION === transaction.transaction_type
  ) {
    switch (transaction.contributor_type) {
      case 'business':
        return 'briefcase';
      case 'pac':
        return 'sitemap';
      default:
        return 'donate';
    }
  }

  return TRANSACTION_ICON_MAP[transaction.transaction_type] || transaction.transaction_type || 'exchange';
};

export const computeTransactionIconColor = transaction_type => {
  return TRANSACTION_ICON_COLOR_MAP[transaction_type] || 'gray';
};

export const computeTransactionName = transaction => {
  const name = getDonationDonorName(transaction);
  if (name) return name;

  if (transaction.name) return transaction.name;
  if (transaction.description) return transaction.description;

  switch (transaction.transaction_type) {
    case TRANSACTION_TYPE_MAP.PAYOUT:
      return transaction.code_name || 'Unknown';
    case TRANSACTION_TYPE_MAP.CHARGE_DISPUTE_FEE:
      return `Dispute Fee`;
    case TRANSACTION_TYPE_MAP.CHARGE_DISPUTE:
      return 'Dispute';
    default:
      return 'Unknown';
  }
};

export const TRANSACTIONS_US_STATES_FULL_NAME = [{ value: '', label: 'All States' }, ...US_STATES_FULL_NAME];

export const TRANSACTION_TYPE_OPTIONS = [
  { value: 'DISPUTE', label: 'Dispute' },
  { value: 'DISPUTE_FEE', label: 'Dispute Fee' },
  { value: 'DONATION', label: 'Donation' },
  { value: 'INVOICE', label: 'Invoice' },
  { value: 'PAYOUT', label: 'Payout' },
  { value: 'REFUND', label: 'Refund' },
  { value: 'VOID', label: 'Void' },
  { value: 'ADJUSTMENT', label: 'Adjustment' },
  { value: 'PAYOUT_ADJUSTMENT', label: 'Payout Adjustment' },
];

export const ENTITY_OPTIONS = [
  { value: '', label: 'All Entities' },
  { value: 'individual', label: 'Individual' },
  { value: 'spouse', label: 'Spouse' },
  { value: 'corporation', label: 'Corporation' },
];

export const mapFilterToUiTransactions = (filter, isOrganization, allowTransactionsToBeVoided) => {
  let uiFilter = [];
  let numberOfFilters = 0;

  [uiFilter, numberOfFilters] = dateRangeFilterUi({ filter, uiFilter, numberOfFilters });
  [uiFilter, numberOfFilters] = searchFilterUi({ filter, uiFilter, numberOfFilters });

  [uiFilter, numberOfFilters] = amountFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    predicateKey: 'feePredicate',
    amountKey: 'fee',
    label: 'Fee',
  });
  [uiFilter, numberOfFilters] = amountFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    predicateKey: 'grossPredicate',
    amountKey: 'gross',
    label: 'Gross',
  });
  [uiFilter, numberOfFilters] = amountFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    predicateKey: 'netPredicate',
    amountKey: 'net',
    label: 'Amount',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: TRANSACTIONS_US_STATES_FULL_NAME,
    filterKey: 'state',
    label: 'State',
  });

  [uiFilter, numberOfFilters] = dropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    dropdownOptions: ENTITY_OPTIONS,
    filterKey: 'entity',
    label: 'Entity',
  });

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'contributorType',
    defaultValue: generateContributorTypeOptions(),
    label: 'Contributor Type',
  });

  const { transactionTypeOptions } = getTransactionTypeOptions(isOrganization, allowTransactionsToBeVoided);

  [uiFilter, numberOfFilters] = multiDropdownFilterUi({
    filter,
    uiFilter,
    numberOfFilters,
    filterKey: 'transactionType',
    defaultValue: transactionTypeOptions,
    label: filter.transactionType?.length > 1 ? 'Transaction Types' : 'Transaction Type',
  });

  return [uiFilter, numberOfFilters];
};

export const getTransactionTypeOptions = (isOrganization, allowTransactionsToBeVoided, filter) => {
  let transactionTypeOptions = [...TRANSACTION_TYPE_OPTIONS];

  // orgs dont have dispute fee so remove from transactionType filters, federal doesnt have invoices
  if (isOrganization) {
    transactionTypeOptions = transactionTypeOptions.filter(transactionType => transactionType.value !== 'DISPUTE_FEE');
    if (filter) {
      filter = { ...filter };
      if (filter.transactionType) {
        filter.transactionType = filter.transactionType.filter(
          transactionType => transactionType.value !== 'DISPUTE_FEE'
        );
      }
    }
  } else {
    transactionTypeOptions = transactionTypeOptions.filter(transactionType => transactionType.value !== 'INVOICE');
    if (filter) {
      filter = { ...filter };
      if (filter.transactionType) {
        filter.transactionType = filter.transactionType.filter(transactionType => transactionType.value !== 'INVOICE');
      }
    }
  }

  // if voids not allow then remove from transactionType filters
  if (!allowTransactionsToBeVoided) {
    transactionTypeOptions = transactionTypeOptions.filter(transactionType => transactionType.value !== 'VOID');
    if (filter) {
      filter = { ...filter };
      if (filter.transactionType) {
        filter.transactionType = filter.transactionType.filter(transactionType => transactionType.value !== 'VOID');
      }
    }
  }

  return { transactionTypeOptions, filter };
};
