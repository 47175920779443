import React from 'react';

import { RouterLink } from 'modules/common';
import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';
import { store } from '../../configureStore';

export const nameExtractor = user => `${user.first_name} ${user.last_name}`;

export const linkToURL = (url, fullUrl) => {
  let shortUrl = fullUrl ? url : url.replace(/^http(s)?:\/\//, '');
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      {shortUrl}
    </a>
  );
};

export const getDonationDonorName = donation => {
  if (donation.contributor_type === 'pac') return donation.pac_name;
  if (donation.contributor_type === 'business') return donation.business_name;
  if (donation.first_name && donation.last_name) return `${donation.first_name} ${donation.last_name}`;
};

export const linkToEmail = email => {
  const emailLink = `mailto: ${email}`;

  return (
    <a target="_blank" rel="noopener noreferrer" href={emailLink}>
      {email}
    </a>
  );
};

export const payoutExtractor = (item, key) => (item.payout ? item.payout[key] : '');

export const getAvatar = data => {
  let avatar = data.avatar?.image?.thumbnailUrl;
  return avatar || data.avatar_image_url || defaultCandidateAvatar;
};

export const linkToPayout = revvUid => {
  const state = store.getState();
  const profileCandidate = state?.login?.profile?.candidate;
  return (
    <RouterLink to={`/${profileCandidate?.organization_revv_uid}/accounting/payouts/${revvUid}`}>{revvUid}</RouterLink>
  );
};

export const linkToInvoice = revvUid => {
  const state = store.getState();
  const profileCandidate = state?.login?.profile?.candidate;
  return (
    <RouterLink to={`/${profileCandidate?.organization_revv_uid}/accounting/invoices/${revvUid}`}>{revvUid}</RouterLink>
  );
};
