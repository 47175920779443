import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'core/utilities/uuid';

import { Input } from 'modules/common';
import DonationAmount from 'modules/pages/common/donations/donation-amount.component';
import DonationAmounts from 'modules/pages/common/donations/donation-amounts.component';

const eventName = 'page_amounts';

function UpsellDonations({
  editedUpsell,
  onUpdateEditedUpsell,
  maxNumberOfItems,
  subtitle,
  noCard,
  isMultiplier = false,
}) {
  const amountNotDuplicated = donation => {
    const matchingAmounts = editedUpsell.page_amounts.filter(d => {
      /* Amounts that have been deleted shouldn't be considered. */
      if (d._destroy || d.destroy) return false;

      // eslint-disable-next-line eqeqeq
      return d.amount == donation && d._isMultiplier === isMultiplier;
    });

    return matchingAmounts.length <= 1;
  };

  const onlyOnePreselected = () => {
    const numPreselected = editedUpsell.page_amounts.reduce((onlyOne, d) => {
      // Amounts that have been deleted shouldn't be considered.
      if (d._destroy || d.destroy) return onlyOne;
      // If this donation isn't of the type of the slate upsell, ignore it.
      if (d._isMultiplier !== isMultiplier) return onlyOne;

      if (d.preselected) onlyOne += 1;
      return onlyOne;
    }, 0);
    // Only one amount may be preselected.
    return numPreselected <= 1;
  };

  const createNewDonation = () => {
    return { amount: '', description: '', _isMultiplier: isMultiplier, id: uuid(), preselected: false };
  };

  // keep track of dynamic vs static amounts shown on the ui
  const amounts = isMultiplier
    ? editedUpsell.page_amounts.filter(amt => amt._isMultiplier)
    : editedUpsell.page_amounts.filter(amt => !amt._isMultiplier);

  return (
    <>
      <DonationAmounts
        eventName={eventName}
        amounts={editedUpsell.page_amounts}
        updateAmounts={onUpdateEditedUpsell}
        newDonation={createNewDonation}
        maxNumberOfItems={maxNumberOfItems}
        subtitle="Customize your suggested donation amount buttons."
        noCard={noCard}
        isMultiplier={isMultiplier}
        disabled={editedUpsell._isDisabled}
      >
        {({ updateItem, removeItem }) => {
          return amounts.map(donation => {
            if (donation._destroy) return null;

            return (
              <DonationAmount
                key={donation.id || donation.position}
                donation={donation}
                updateAmount={updateItem}
                removeAmount={removeItem}
                disabled={editedUpsell._isDisabled}
                value={donation.amount}
                isMultiplier={isMultiplier}
                validators={['required', amountNotDuplicated]}
                errorMessages={[
                  'The donation amount is required.',
                  isMultiplier ? 'This donation multiplier already exists.' : 'This donation amount already exists.',
                ]}
              />
            );
          });
        }}
      </DonationAmounts>
      {/* Validate that only one amount is preselected */}
      <Input
        type="hidden"
        validators={[onlyOnePreselected]}
        errorMessages={['Only one amount can be preselected']}
        errorMessageClasses="text-center"
      />
    </>
  );
}

UpsellDonations.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  maxNumberOfItems: PropTypes.number,
  subtitle: PropTypes.string,
  noCard: PropTypes.bool,
  isMultiplier: PropTypes.bool,
};

UpsellDonations.defaultProps = {
  maxNumberOfItems: 9999,
};

export default UpsellDonations;
