import uuid from 'core/utilities/uuid';
import { capitalize, startCase, toLower } from 'lodash';
import { sortByPosition } from '../../../core/utilities';

export const humanizePredicateLabel = {
  EQUAL: 'Equals',
  NOT_EQUAL: 'Not Equal To',
  EXISTS: 'Is Present',
  DOES_NOT_EXIST: 'Is Blank',
  ONE_OF: 'One Of',
  NONE_OF: 'None Of',
};
export const formatConditionOptions = availableConditions => {
  let formatedConditionOptions = {};
  let predicatesForKeysMap = {};
  let customValueOptions = {};
  availableConditions
    ?.filter(
      item =>
        !item.additional_field_type ||
        item.additional_field_type === 'check_box' ||
        item.additional_field_type === 'radio_buttons' ||
        item.additional_field_type === 'multiple_choice'
    )
    ?.forEach(item => {
      const { condition_type, key, predicates, name, additional_field_type, additional_field_values } = item;
      if (additional_field_values) {
        customValueOptions[key] = additional_field_values.map(val => {
          return { value: val, label: val };
        });
      }
      const upsellStateNativeKeys = ['zip_code', 'donor_state', 'mobile_number_opt_in', 'mobile_number'];

      const conditionTypeLabel = additional_field_type
        ? 'Custom Fields'
        : condition_type === 'UPSELL_STATE' && upsellStateNativeKeys.includes(key)
        ? 'NATIVE Fields'
        : condition_type === 'UPSELL_STATE'
        ? 'SYSTEM Fields'
        : condition_type + ' Fields';

      if (!formatedConditionOptions[conditionTypeLabel]) {
        formatedConditionOptions[conditionTypeLabel] = {
          condition_type_label: conditionTypeLabel,
          label: conditionTypeLabel,
          options: [],
        };
      }
      formatedConditionOptions[conditionTypeLabel].options.push({
        label: name || startCase(key),
        value: key,
        condition_type: condition_type,
        condition_type_label: conditionTypeLabel,
      });

      predicatesForKeysMap[key] = predicates.map(predicate => {
        return {
          value: predicate,
          label: humanizePredicateLabel[predicate] || startCase(toLower(predicate)),
        };
      });
    });

  let formattedConditionsArray = [];

  for (const key in formatedConditionOptions) {
    formattedConditionsArray.push(formatedConditionOptions[key]);
  }

  return { formattedConditionsArray, predicatesForKeysMap, customValueOptions };
};

export const generateNewRedirect = () => {
  return {
    percentage: 0,
    revv_uid: uuid(),
    value: null,
    _isNew: true,
    _destroy: false,
  };
};

export const generateNewRequirement = position => {
  return {
    key: null,
    predicate: null,
    value: null,
    revv_uid: uuid(),
    position: position,
    _isNew: true,
    _destroy: false,
  };
};

export const generateNewStep = pathwayId => {
  return {
    name: null,
    position: null,
    step_type: 'REGULAR_STEP',
    revv_uid: uuid(),
    created_at: null,
    updated_at: null,
    conditions: [generateNewRequirement()],
    actions: [generateNewRedirect()],
    attached_pathways: [
      {
        revv_uid: pathwayId,
      },
    ],
    _isNew: true,
    _destroy: false,
  };
};

export const generateNewEndStep = pathwayId => {
  return {
    name: null,
    position: null,
    step_type: 'END_STEP',
    revv_uid: uuid(),
    created_at: null,
    updated_at: null,
    actions: [generateNewRedirect()],
    attached_pathways: [
      {
        revv_uid: pathwayId,
      },
    ],
    _isNew: true,
    _destroy: false,
  };
};

export const generateNewPathway = () => {
  return {
    name: '',
    revv_uid: uuid(),
    created_at: Date.now().toString(),
    updated_at: Date.now().toString(),
    redirect_with_utms: true,
    replace_utm_with_wr_utm: true,
    pathway_steps: [],
    _isNew: true,
    _destroy: false,
  };
};

export const hasDuplicateField = (requirements, inputValue) => {
  const fieldsCountMap = {};
  return requirements.some(req => {
    if (fieldsCountMap[req.key] !== undefined && req.key === inputValue.value && !req._destroy) {
      return true;
    } else {
      if (!req._destroy) fieldsCountMap[req.key] = 1;
      return false;
    }
  });
};

export const formatConditionValue = condition => {
  if (!condition.value) return [];
  if (Array.isArray(condition.value)) {
    const formattedValue = condition.value?.map(val => {
      if (val?.value) {
        return val.value;
      } else {
        return val;
      }
    });
    return formattedValue;
  }
  if (condition.key === 'postal_code' || condition.key === 'zip_code') {
    const zipArray = condition.value.split(',').map(item => item.trim());
    return zipArray;
  }
  return [condition.value];
};
export const formatStepsForServer = step => {
  let formattedStep = {
    name: step.name,
    id: step.id,
    step_type: step.step_type,
  };
  const formattedActions = step.actions
    ?.filter(act => !(act._isNew && act._destroy))
    ?.map((act, index) => {
      if (act._isNew) {
        return {
          percentage: parseFloat(act.percentage),
          position: act.position || index + 1,
          value: act.value,
          destroy: act._destroy,
          name: 'action name',
        };
      }
      return {
        name: 'action name',
        percentage: parseFloat(act.percentage),
        position: act.position || index + 1,
        id: act.id,
        revv_uid: act.revv_uid,
        value: act.value,
        destroy: act._destroy,
      };
    });
  formattedStep.actions = formattedActions;
  const formattedConditions = step.conditions
    ?.filter(cond => !(cond._isNew && cond._destroy))
    ?.map((cond, index) => {
      const conditionValue =
        cond.predicate === 'IS_PRESENT' ||
        cond.predicate === 'IS_BLANK' ||
        cond.predicate === 'EXISTS' ||
        cond.predicate === 'DOES_NOT_EXIST'
          ? []
          : formatConditionValue(cond);
      const conditionType =
        cond.condition_type === 'Custom Fields' ? 'NATIVE' : cond.condition_type.replace(' Fields', '');
      if (cond._isNew) {
        return {
          name: 'condition name',
          key: cond.key,
          position: cond.position || index + 1,
          predicate: cond.predicate,
          value: conditionValue,
          destroy: cond._destroy,
          condition_type: conditionType,
        };
      }
      return {
        name: 'condition name',
        key: cond.key,
        position: cond.position || index + 1,
        predicate: cond.predicate,
        id: cond.id,
        value: conditionValue,
        destroy: cond._destroy,
        condition_type: conditionType,
      };
    });
  formattedStep.conditions = formattedConditions;
  formattedStep.pathway_type = 'PETITION';
  return formattedStep;
};

export const formatStepForUi = step => {
  const formattedConditions = step?.conditions?.map(condition => {
    let parsedValue;
    if (typeof condition.value === 'string' && !Array.isArray(condition.value)) {
      try {
        parsedValue = JSON.parse(condition.value);
      } catch (e) {
        console.error('Error parsing value: ', e);
      }
    } else {
      parsedValue = condition.value;
    }
    return { ...condition, value: parsedValue };
  });
  return { ...step, conditions: formattedConditions };
};

export const formatStepsForUi = steps => {
  const formattedSteps = steps?.map(step => {
    return formatStepForUi(step);
  });
  return formattedSteps;
};

export const formatPaginatedStepsListForUi = steps => {
  const formattedSteps = steps?.results?.map(step => {
    return formatStepForUi(step);
  });
  return { ...steps, results: formattedSteps };
};

const findExistingJoinId = (stepId, existingJoins) => {
  return existingJoins?.find(join => stepId === join.step.id)?.id;
};

export const formatPathwayForServer = pathway => {
  let formattedPathway = { ...pathway };
  let baseStepJoins = [];
  baseStepJoins = pathway.pathway_steps?.map((step, index) => {
    if (step._destroy && step._isNew) return null;
    else {
      return {
        destroy: step._destroy,
        position: step.position || index + 1,
        id: step.joinId || findExistingJoinId(step.id, pathway.base_step_joins),
        pathway_step_id: step.id,
      };
    }
  });
  pathway.pathway_end_steps?.forEach(endStep => {
    if (endStep._destroy && endStep._isNew) return null;
    else {
      baseStepJoins.push({
        destroy: endStep._destroy,
        position: endStep.position || baseStepJoins.length + 1,
        id: endStep.joinId || findExistingJoinId(endStep.id, pathway.base_step_joins),
        pathway_step_id: endStep.id,
      });
    }
  });
  formattedPathway.base_step_joins = baseStepJoins;
  formattedPathway.pathway_type = 'PETITION';

  delete formattedPathway.upsell_count;
  delete formattedPathway.pathway_end_steps;
  delete formattedPathway._destroy;
  delete formattedPathway._isNew;
  delete formattedPathway.revv_uid;
  delete formattedPathway.created_at;
  delete formattedPathway.updated_at;
  delete formattedPathway.creator;
  delete formattedPathway.page_count;
  delete formattedPathway.__typename;
  delete formattedPathway.pathway_steps;
  return formattedPathway;
};

export const formatPathwayForUi = pathway => {
  let regularSteps = [];
  let endSteps = [];
  pathway?.base_step_joins?.sort(sortByPosition).forEach(stepJoin => {
    if (stepJoin.step?.step_type === 'REGULAR_STEP') {
      regularSteps.push({ ...stepJoin.step, joinId: stepJoin.id });
    } else {
      endSteps.push({ ...stepJoin.step, joinId: stepJoin.id });
    }
  });

  const formattedPathway = {
    ...pathway,
    pathway_steps: formatStepsForUi(regularSteps),
    pathway_end_steps: formatStepsForUi(endSteps),
  };
  return formattedPathway;
};

const trueFalseOptions = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];
export const conditionValueOptionsMap = {
  sms_opt_in: {
    options: trueFalseOptions,
  },
  mobile_number_opt_in: {
    options: trueFalseOptions,
  },
  email_has_donated: {
    options: trueFalseOptions,
  },
  number_has_donated: {
    options: trueFalseOptions,
  },
  email_win_red: {
    options: trueFalseOptions,
  },
  number_win_red: {
    options: trueFalseOptions,
  },
  email_has_account: {
    options: trueFalseOptions,
  },
  email_active_pledges: {
    options: trueFalseOptions,
  },
  email_merchandise: {
    options: trueFalseOptions,
  },
  email_merchandise_win_red: {
    options: trueFalseOptions,
  },
  email_blocked: {
    options: trueFalseOptions,
  },
  user_id: {
    options: trueFalseOptions,
  },
  email_active_subscriptions: {
    options: trueFalseOptions,
  },
  recurring_donation_opt_in: {
    options: trueFalseOptions,
  },
  money_pledge_opt_in: {
    options: trueFalseOptions,
  },
  donor_covers_fees: {
    options: trueFalseOptions,
  },
  merchandise_order_on_landing_page: {
    options: trueFalseOptions,
  },
  dynamic_amounts: {
    options: trueFalseOptions,
  },
  donor_accepted_previous_upsell: {
    options: trueFalseOptions,
  },
};

export const formatConditionKeyName = (condition, campaignsList, availableCustomConditions) => {
  let fullOptionsList = [];
  availableCustomConditions?.formattedConditionsArray?.forEach(list => {
    fullOptionsList = fullOptionsList.concat(list?.options);
  });
  const customOption = fullOptionsList.find(opt => opt.value === condition.key);
  if (customOption) {
    return customOption?.label;
  }
  return capitalize(condition.key);
};

export const formatConditionValueLabel = (condition, campaignsList, availableCustomConditions) => {
  const conditionValue = Array.isArray(condition.value) ? condition.value[0] : condition.value;
  if (conditionValue === 'true' || conditionValue === 'false') {
    return capitalize(condition.value);
  }
  if (condition.key === 'base_page_id') {
    const selectedCampaigns = condition?.value?.map(conditionValue => {
      const parsedValue = typeof conditionValue === 'object' ? conditionValue.value : conditionValue;
      return campaignsList?.find(camp => camp.revv_uid === parsedValue);
    });
    return selectedCampaigns?.map(camp => camp?.name).join(', ');
  }
  return (condition?.value).join(', ');
};

export const PAGE_TYPES_CONDITIONS = [
  {
    value: 'high-dollar',
    label: 'High Dollar',
  },
  {
    value: 'donation',
    label: 'Donation',
  },
  {
    value: 'video',
    label: 'Video',
  },
];
