import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced, useMoneyPledgeOptions, SpinnerContainer, Error } from 'modules/common';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';
import { MoneyPledge, UpsellName, UpsellVideoEmbed, UpsellAction, ImageOnUpsell, Widgets } from './sections';

function MoneyPledgeUpsell(props) {
  const { moneyPledgeOptions, loading, error } = useMoneyPledgeOptions();
  const { onUpdateEditedUpsell } = props;

  useEffect(() => {
    onUpdateEditedUpsell({ target: { name: 'moneyPledgeOptions', value: moneyPledgeOptions } });
  }, [moneyPledgeOptions, onUpdateEditedUpsell]);

  if (loading) return <SpinnerContainer />;
  if (error) return <Error error={error} />;

  return (
    <div className="moneypledge-upsell">
      <FormCardSpaced>
        <UpsellName {...props} />
        <MoneyPledge {...props} moneyPledgeOptions={moneyPledgeOptions} />
        <ImageOnUpsell {...props} />
        <div className="pt-3">
          <UpsellAction {...props} />
        </div>
      </FormCardSpaced>
      <UpsellVideoEmbed {...props} />
      <Widgets
        {...props}
        widgetOptions={MoneyPledgeUpsell.widgets}
        primaryColor={props.primaryColor}
        secondaryColor={props.secondaryColor}
      />
    </div>
  );
}

MoneyPledgeUpsell.title = 'Money Pledge';
MoneyPledgeUpsell.description = 'Ask donors to opt-in to a Money Pledge.';
MoneyPledgeUpsell.icon = 'upsell-money-pledge';
MoneyPledgeUpsell.type = 'MONEYPLEDGE';
MoneyPledgeUpsell.widgets = [
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.POLLRESULTS.value,
];

MoneyPledgeUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default MoneyPledgeUpsell;
