import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Typography, Icon, FormCard } from 'modules/common';
import { Row } from 'react-bootstrap';
import { ImagePromptDrawer } from './image-prompt/image-drawer/imagePrompt-drawer.component';

function MultiImagePromp({
  assignedImages,
  disabled,
  removeImageAction,
  name,
  selectImageAction,
  imageType,
  maxFileSize,
}) {
  const [showGallery, setShowGallery] = useState(false);
  const showGalleryModal = () => setShowGallery(true);

  const onToggleDrawer = () => {
    setShowGallery(!showGallery);
  };

  return (
    <>
      {assignedImages.length > 0 && !assignedImages.every(image => image.destroy) ? (
        <div className="d-flex flex-wrap p-3">
          {assignedImages.map(image => {
            if (!image.destroy) {
              return (
                <Row key={image.id || image.imageId} className="flex flex-col mr-4">
                  <FormCard className="h-48">
                    {<img src={image.image_url} alt="selection" className="w-44 h-40 object-contain" />}
                  </FormCard>
                  {!disabled && (
                    <FormCard
                      onClick={() => removeImageAction(image)}
                      className="mb-3"
                      classNameBody="d-flex flex-column align-items-center pointer p-3"
                    >
                      <Typography className="mb-0 p--medium">Remove</Typography>
                    </FormCard>
                  )}
                </Row>
              );
            } else {
              return null;
            }
          })}
          <Row>
            <FormCard
              variant="upload"
              disabled={disabled}
              onClick={disabled ? null : showGalleryModal}
              className="mb-3 w-56"
              classNameBody={classnames('d-flex flex-column justify-content-center align-items-center p-4', {
                pointer: !disabled,
              })}
            >
              <Icon icon="plus-circle" size="3x" />
              <Typography noGutter className="mt-1">
                Choose Image
              </Typography>
            </FormCard>
          </Row>
        </div>
      ) : (
        <FormCard
          variant="upload"
          disabled={disabled}
          onClick={disabled ? null : showGalleryModal}
          className="mb-3"
          classNameBody={classnames('d-flex flex-column align-items-center p-4', { pointer: !disabled })}
        >
          <Icon icon="plus-circle" size="3x" />
          <Typography noGutter className="mt-1">
            Choose Image
          </Typography>
        </FormCard>
      )}
      <ImagePromptDrawer
        open={showGallery}
        toggleDrawer={onToggleDrawer}
        maxFileSize={maxFileSize}
        name={name}
        selectImageAction={selectImageAction}
        assignedImages={assignedImages}
        imageType={imageType}
        isMultiUpload
      />
    </>
  );
}

MultiImagePromp.propTypes = {
  name: PropTypes.string.isRequired,
  imageUri: PropTypes.string,
  imageType: PropTypes.string.isRequired,
  maxFileSize: PropTypes.number,
  selectImageAction: PropTypes.func.isRequired,
  removeImageAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MultiImagePromp;
