import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'modules/common';
import winRedLogoFallback from '../../../../../assets/images/thumb_shield-fallback.png';

export const CommitteeComponent = ({ item, isResults = false }) => {
  const imageUrl = useMemo(() => {
    let url = isResults ? item?.thumbnailUrl : item?.avatar?.image?.thumbnailUrl;
    if (url) return url;
    return winRedLogoFallback;
  }, [isResults, item]);
  return (
    <div className="flex items-center justify-start mb-2">
      <div className="mr-2">
        <div
          className="w-9 h-9 rounded-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        ></div>
      </div>
      <div>
        <Typography variant="bodyMedium" className="c--mirage mb-0 break-words">
          {item?.originalName || item?.name}
        </Typography>
        <Typography className="mb-0">{`${item?.feePercentage}% Bill-Pay Fee`}</Typography>
      </div>
    </div>
  );
};

CommitteeComponent.propTypes = {
  item: PropTypes.object.isRequired,
};
