import React from 'react';
import PropTypes from 'prop-types';
import { Icon, FormCardSpaced, Typography, Button } from 'modules/common';
import { Col, Row } from 'react-bootstrap';
import { THERMOMETER_TYPES_MAP } from '../../drawer/thermometer/thermometer-fields';
import { formatDate } from 'core/utilities';

export const SelectedThermometer = ({ widget, toggleDrawer, onRemoveSelection }) => {
  const { internal_name, thermometer_type, page_count, query_date } = widget;
  const handleEditThermometer = () => {
    toggleDrawer();
  };

  return (
    <FormCardSpaced>
      <Row>
        <Col>
          <Typography color="mirage" className="font-medium mb-1">
            {internal_name}
          </Typography>
          <Typography>Thermometer Type: {THERMOMETER_TYPES_MAP[thermometer_type]} </Typography>
        </Col>
        <Col xs="auto" className="d-flex justify-content-center align-items-center">
          <Typography color="mirage" className="font-medium mb-1 mr-3">
            {query_date ? `Start Date: ${formatDate(parseInt(query_date))}` : `No Start Date`}
          </Typography>
          <Typography color="mirage" className="font-medium mb-1 mr-3">
            On {page_count} Page(s)
          </Typography>
          <Button variant="alt" onClick={handleEditThermometer}>
            Edit
          </Button>
          <Icon className="ml-4 pointer" icon="times" color="gray" size="lg" onClick={onRemoveSelection} />
        </Col>
      </Row>
    </FormCardSpaced>
  );
};

SelectedThermometer.propTypes = {
  widget: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onRemoveSelection: PropTypes.func.isRequired,
};
