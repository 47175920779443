import gql from 'graphql-tag';

export const leadFragments = {
  leads: gql`
    fragment Leads on LeadResult {
      current_page
      page_count
      total_count

      results {
        revv_uid
        created_at
        email
        first_name
        last_name
        source
      }
    }
  `,
  lead: gql`
    fragment Lead on LeadResult {
      results {
        revv_uid
        created_at
        first_name
        last_name
        email
        source
        referer
        source_url
        campaign_name
        initiative_campaign_revv_uid
        address
        city
        state
        postal_code
        number
        sms_opt_in
        one_click
        abandoned_donation_data {
          amount_cents
          subscribed
        }
        custom_field_data {
          name
          value
        }
      }
    }
  `,
};

export const GET_LEADS_REPORT = gql`
  query getLeadsReport(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $sourceType: LeadSourceEnum
    $search: String
    $organizationRevvUid: String
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_leads_report(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sourceType: $sourceType
            search: $search
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_LEADS_QUERY = gql`
  query getLeads(
    $page: Int
    $limit: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $sourceType: LeadSourceEnum
    $search: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        leads(
          filters: {
            page: $page
            limit: $limit
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            sourceType: $sourceType
            search: $search
          }
        ) {
          ...Leads
        }
      }
    }
  }
  ${leadFragments.leads}
`;

export const GET_LEAD_QUERY = gql`
  query getLead($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        leads(filters: { revvUid: $revv_uid }) {
          ...Lead
        }
      }
    }
  }
  ${leadFragments.lead}
`;
