import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Icon } from './';

const VARIANT_COLOR_MAP = {
  primary: 'blue',
  secondary: 'green',
};

const activeClasses =
  'bg--winred-blue-light hover-border-winred-blue-light border-winred-blue-light hover-c--white hover-bg--winred-blue-hover';

const forwardRef = (props, ref) => {
  const { variant, icon, active, disabled, text, className, ...rest } = props;

  const color = variant ? VARIANT_COLOR_MAP[variant] : 'blue';

  return (
    <Button
      variant="default"
      className={classNames(
        'rounded-pill font-normal p-3 leading-none border no-outline',
        { 'border-mystic bg--white hover-bg--white hover-border--nepal': !active, [activeClasses]: active },
        className
      )}
      ref={ref}
      size="lg"
      disabled={disabled}
      {...rest}
    >
      <div className="d-flex">
        <Icon
          className={classNames({ 'c--white': !disabled, 'hover-c--white': active })}
          color={active ? 'white' : color}
          icon={icon}
        />
        {text ? <div className="ml-2">{text}</div> : null}
      </div>
    </Button>
  );
};
forwardRef.displayName = 'IconButton';

const IconButton = React.forwardRef(forwardRef);

IconButton.propTypes = {
  text: PropTypes.any,
  variant: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

forwardRef.propTypes = IconButton.propTypes;

export default IconButton;
