import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { WinRedChip, FormCard, Typography, Button, Icon } from 'modules/common';
import { convertEpochTime } from 'core/utilities';

import {
  COMMITTEE_CONNECTION_CARD_VARIANTS,
  COMMITTEE_CONNECT_ACTIVITY_TYPES,
  getCommitteeConnectActivityText,
} from './committee-connect.tools';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';
function CommitteeCard({ committee, variant = COMMITTEE_CONNECTION_CARD_VARIANTS.CONNECTED, footerAction, onEdit }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [limit, setLimit] = useState(3);

  const avatarUrl =
    committee.connectedCommittee?.avatar?.image?.thumbnailUrl ||
    committee.vendor?.avatar?.image?.thumbnailUrl ||
    defaultCandidateAvatar;
  const details = committee?.connectedCommittee || committee?.vendor || {};

  const handleOpenW9 = () => {
    window.open(committee.vendor.w9_form.originalUrl, '_blank');
  };

  const _expand = totalCount => {
    setIsExpanded(!isExpanded);
    setLimit(!isExpanded ? totalCount : 3);
  };

  return (
    <FormCard
      classNameBody="px-0"
      classNameFooter="py-3 px-4"
      footer={
        <>
          {committee?.vendor?.w9_form?.originalUrl ? (
            <Button className="float-right py-2 px-3 ml-2" variant="primary" onClick={handleOpenW9}>
              Download W-9
            </Button>
          ) : null}
          {variant === COMMITTEE_CONNECTION_CARD_VARIANTS.CONNECTED ? (
            <Button className="float-right py-2 px-3" variant="outline-primary" onClick={footerAction}>
              Disconnect
            </Button>
          ) : null}
        </>
      }
    >
      <header className="flex items-center mb-4 px-4">
        <img className="h-8 w-8 rounded-full flex-none object-cover" alt="connection profile" src={avatarUrl} />

        <Typography variant="h4" className="ml-4 grow">
          {details.name || ''}
        </Typography>

        <Typography
          className={`border rounded px-3 py-2 m-0 font-medium ml-auto ${
            variant === COMMITTEE_CONNECTION_CARD_VARIANTS.CONNECTED
              ? 'bg--panache border-surf-crest text-winred-green'
              : 'bg--catskill-white'
          }`}
        >
          {variant === COMMITTEE_CONNECTION_CARD_VARIANTS.CONNECTED ? 'Connected' : 'Disconnected'}
        </Typography>
      </header>

      <main>
        <div className="flex border-t border-catskill py-3 px-4">
          <Typography className="w-2/12 m-0 text-lynx">Date Created</Typography>

          <Typography className="font-medium text-sm mb-0 text-mirage ml-2">
            {convertEpochTime(committee.created_at)}
          </Typography>
        </div>

        <div className="flex border-t border-catskill py-2.5 px-4 d-flex align-items-center">
          <Typography className="w-2/12 m-0 text-lynx">Default Fee</Typography>

          <Typography className="font-medium text-sm mb-0 text-mirage mr-3 ml-2">
            {committee.defaultFeePercentage}%
          </Typography>
          {onEdit && (
            <Button size="sm" variant="outline-primary" onClick={() => onEdit(committee)}>
              Edit
            </Button>
          )}
        </div>

        {details.contact_info && (
          <div className="flex border-t border-catskill py-2.5 px-4 d-flex align-items-center">
            <Typography className="w-2/12 m-0 text-lynx">Contact</Typography>

            <div className="mr-3 ml-2">
              <Typography className="font-medium text-sm mb-0 text-mirage">
                {details.contact_info.first_name} {details.contact_info.last_name}
              </Typography>
              <Typography className="font-medium text-sm mb-0 text-mirage">{details.contact_info.email}</Typography>
            </div>
          </div>
        )}

        {details.contact_address && (
          <div className="flex border-t border-catskill py-2.5 px-4 d-flex align-items-center">
            <Typography className="w-2/12 m-0 text-lynx">Address</Typography>

            <div className="mr-3 ml-2">
              <Typography className="font-medium text-sm mb-0 text-mirage">
                {details.contact_address.address || ''}
              </Typography>
              <Typography className="font-medium text-sm mb-0 text-mirage">
                {details.contact_address.address2 || ''}
              </Typography>
              <Typography className="font-medium text-sm mb-0 text-mirage">
                {details.contact_address.city}, {details.contact_address.state} {details.contact_address.zip}
              </Typography>
            </div>
          </div>
        )}

        {committee.vendorConnectActivity.results.length > 0 && (
          <div className="flex border-t border-catskill py-3 px-4">
            <Typography className="w-2/12 m-0 text-lynx">Activity</Typography>

            <div className="w-10/12">
              <div className={isExpanded ? 'overflow-y-auto max-h-96' : ''}>
                {committee.vendorConnectActivity.results.slice(0, limit).map(activity => {
                  return (
                    <Typography
                      key={activity.id}
                      className={`font-medium text-sm mb-0 ml-2 ${
                        activity.activityType === COMMITTEE_CONNECT_ACTIVITY_TYPES.VENDOR_BILL_PAY_DISCONNECTED
                          ? 'c--winred-red'
                          : 'text-mirage'
                      }`}
                    >
                      {getCommitteeConnectActivityText(activity)}
                    </Typography>
                  );
                })}
              </div>

              {committee.vendorConnectActivity.results.length > 3 && (
                <WinRedChip className="mt-2" onClick={() => _expand(committee.vendorConnectActivity.results.length)}>
                  View All
                  <Icon
                    className="ml-2"
                    color="text-lynx"
                    icon={isExpanded ? 'minus-circle' : 'plus-circle'}
                    size="lg"
                  />
                </WinRedChip>
              )}
            </div>
          </div>
        )}
      </main>
    </FormCard>
  );
}

CommitteeCard.propTypes = {
  committee: PropTypes.object.isRequired,
  variant: PropTypes.string,

  footerAction: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
};

export { CommitteeCard };
