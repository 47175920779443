import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FormCard, Typography } from 'modules/common';

function PreviewSideBar({ children, selectedCustomField }) {
  const slug = selectedCustomField.unique_parameter || 'custom-field-slug';

  return (
    <FormCard className="preview-card" title="Preview">
      <Typography italic>
        Autofill this field via url by adding
        <span className="font-weight-bold"> ?{slug}=value </span>
        to the end of your url.
      </Typography>
      <div className="sidebar-toggle-wrapper">
        <Row className="justify-content-between">
          <Col>{children}</Col>
        </Row>
      </div>
    </FormCard>
  );
}

PreviewSideBar.propTypes = {
  children: PropTypes.any,
  selectedCustomField: PropTypes.object.isRequired,
};

export default PreviewSideBar;
