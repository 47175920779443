import gql from 'graphql-tag';

export const GET_TRANSACTIONS_ORGANIZATION_QUERY = gql`
  query getOrganizationTransactions(
    $limit: Int
    $page: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: RansackPredicate
    $transactionType: [TransactionTypeEnum!]
    $state: StateEnum
    $organizationRevvUid: String
    $contributorType: [ContributorTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        transactions(
          filters: {
            limit: $limit
            page: $page
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: $transactionType
            state: $state
            contributorType: $contributorType
          }
        ) {
          total_count
          page_count
          current_page
          results {
            revv_uid
            created_at
            transaction_type
            charge_revv_uid
            amount_cents
            description
            status
            business_name
            pac_name
            contributor_type
            reportUrl
          }
        }
        refund_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: REFUND
            state: $state
            contributorType: $contributorType
          }
        ) {
          gross_amount
          count
        }
        dispute_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: DISPUTE
            state: $state
            contributorType: $contributorType
          }
        ) {
          gross_amount
          count
        }
        donations_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: DONATION
            state: $state
            contributorType: $contributorType
          }
        ) {
          gross_amount
          count
        }
        payouts_trends: transaction_trend(
          filters: {
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: PAYOUT
            state: $state
            contributorType: $contributorType
          }
        ) {
          gross_amount
          count
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_TRANSACTIONS_REPORT = gql`
  query getOrganizationTransactionsReport(
    $limit: Int
    $page: Int
    $dateRangeStart: String
    $dateRangeEnd: String
    $amount: Float
    $amountPredicate: RansackPredicate
    $transactionType: [TransactionTypeEnum!]
    $state: StateEnum
    $organizationRevvUid: String
    $sortBy: TransactionSortByEnum
    $reportType: TransactionReportTypeEnum
    $contributorType: [ContributorTypeEnum!]
  ) {
    viewer {
      state_level_organization(revvUid: $organizationRevvUid) {
        export_transactions_report(
          filters: {
            limit: $limit
            page: $page
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            amount: $amount
            amountPredicate: $amountPredicate
            transactionType: $transactionType
            state: $state
            sortBy: $sortBy
            reportType: $reportType
            contributorType: $contributorType
          }
        )
      }
    }
  }
`;
