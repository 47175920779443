import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { GET_PRESET_UPSELL_CARDS } from 'core/middleware/queries';
import { FormCard, Error, SpinnerContainer } from 'modules/common';
import { BillPayUpsell } from 'modules/common/bill-pay-activity';
import { profileCandidateSelector } from 'core/login';

import {
  UpsellName,
  Geographical,
  RandomizeCards,
  UpsellAction,
} from 'modules/pages/upsells/drawer/new-upsell/sections';
import { UpsellCards } from 'modules/pages/upsells/drawer/new-upsell/sections/panel-upsell';

function PanelUpsell(props) {
  const profileCandidate = useSelector(profileCandidateSelector);

  const variables = { limit: 9999, organizationRevvUid: profileCandidate.organization_revv_uid };
  const { loading, error, data } = useQuery(GET_PRESET_UPSELL_CARDS, { variables });

  const { presetOptions, presetCards } = useMemo(() => {
    if (!data || !data.viewer) return { presetOptions: [], presetCards: [] };

    const candidate = data.viewer.candidate || data.viewer.state_level_organization;
    // There is currently no query for state level preset upsell panels, in which case the candidate returns null
    const presetCards = candidate ? candidate.preset_panel_cards.results : [];

    const presetOptions = presetCards
      .filter(preset => preset.active)
      .map(upsellCard => ({
        label: upsellCard.public_name,
        value: upsellCard.revv_uid,
      }));

    return { presetOptions, presetCards };
  }, [data]);

  if (loading) {
    return (
      <div className="mb-5">
        <SpinnerContainer />
      </div>
    );
  }
  if (error) return <Error error={error} />;

  return (
    <>
      <FormCard>
        <UpsellName {...props} />
        <UpsellAction isRequired={false} {...props} />
      </FormCard>

      <Geographical {...props} />
      <RandomizeCards {...props} />

      <UpsellCards {...props} presetOptions={presetOptions} presetCards={presetCards} />

      {props.editedUpsell?._conduitsHaveVendors && (
        <BillPayUpsell
          revv_uid={props.editedUpsell?.revv_uid}
          upsellType="panel"
          isPublic={props.editedUpsell?.public_upsell}
          upsellCreator={props.editedUpsell?.upsell_creator_revv_uid}
          isNew={props.editedUpsell?._isNew}
        />
      )}
    </>
  );
}

PanelUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  upsells: PropTypes.array.isRequired,
};

PanelUpsell.title = 'Panel';
PanelUpsell.description = 'Panel of upsell cards that donors can take action on without leaving the page.';
PanelUpsell.icon = 'upsell-panel';
PanelUpsell.type = 'PANEL';
PanelUpsell.widgets = [];

export default PanelUpsell;
