import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BsTable from 'react-bootstrap/Table';

import { TableBody, TableCell, TableHeaderCell, TableHead, TableRow } from './';

const Table = ({ className, children, ...restProps }) => (
  <BsTable role="table" className={classNames('winred-table', 'table-hover', className)} {...restProps}>
    {children}
  </BsTable>
);

Table.propTypes = {
  ...BsTable.propTypes,
  children: PropTypes.node,
};

Table.Head = TableHead;
Table.Row = TableRow;
Table.HeaderCell = TableHeaderCell;
Table.Body = TableBody;
Table.Cell = TableCell;

export default Table;
