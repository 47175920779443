import gql from 'graphql-tag';

const VENDOR_SETTINGS_FRAGMENT = gql`
  fragment VendorSettingsVendor on Vendor {
    name
    created_at
    revv_uid
    timezone
    account_type
    role_type
    processor
    users {
      revv_uid
      first_name
      last_name
      email
      role_type
      install_two_factor
      two_factor_required
    }
    user_history {
      revv_uid
      updated_at
      created_at
      email
      action
      user_email
      role_name
    }
    avatar {
      image {
        id
        thumbnailUrl
      }
    }
    bank_accounts {
      revv_uid
      account_holder_name
      routing_number
      last4
      account_holder_type
      bank_name
      status
      currency
      country
      default_for_currency
    }
    bank_accounts_activity {
      total_count
      results {
        email
        type
        last4
        date
      }
    }
    stripe_owner_email
    stripe_account_id
    owners {
      revv_uid
      email
      first_name
      last_name
      title
      phone
      dob_day
      dob_month
      dob_year
      ssn_last_4
      full_ssn_token_provided
      address
      address2
      city
      state
      zip
      stripe_identity_document_front_id
      stripe_identity_document_back_id
      account_opener
      owner
      status
    }
    contact_info {
      first_name
      last_name
      email
    }
    contact_address {
      address
      address2
      city
      state
      zip
    }
    w9_form {
      id
      originalUrl
      type
    }
  }
`;

export const VENDOR_IMAGES_FRAGMENT = gql`
  fragment VendorImages on Vendor {
    images(filters: { imageType: $imageType, limit: $limit, sort: $sort, sortBy: $sort_by }) {
      results {
        id
        imageFileName
        created_at
        originalUrl
        thumbnailUrl
        assignedImages {
          id
          created_at
          imageAssignableType
          imageAssignableId
        }
      }
    }
  }
`;

export const GET_VENDOR_SETTINGS_QUERY = gql`
  query getVendorSettings($vendorRevvUid: String) {
    viewer {
      vendor(revvUid: $vendorRevvUid) {
        ...VendorSettingsVendor
      }
    }
  }
  ${VENDOR_SETTINGS_FRAGMENT}
`;

export const VENDOR_SETTINGS_MUTATION = gql`
  mutation saveVendorSettings($vendor: VendorInput!) {
    updateVendor(vendor: $vendor) {
      errors
      vendor {
        ...VendorSettingsVendor
      }
    }
  }
  ${VENDOR_SETTINGS_FRAGMENT}
`;

export const VENDOR_UPLOAD_DOC_MUTATION = gql`
  mutation uploadDocumentMutation($vendorRevvUid: String!, $document: File!, $documentType: DocumentTypeEnum!) {
    uploadVendorDocument(
      vendorRevvUid: $vendorRevvUid
      document: { documentType: $documentType, document: $document }
    ) {
      document {
        id
        type
        originalUrl
      }
      errors
    }
  }
`;
