import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Input, FloatGroup } from 'modules/common';

function DonationAmount({
  donation,
  disabled,
  updateAmount,
  removeAmount,
  validators,
  errorMessages,
  isMultiplier,
  children,
}) {
  return (
    <div className={classNames('donation-container', { 'flex-row align-items-center': children })}>
      <div className="donation-amount">
        <FloatGroup className="prepend-label-dollar">
          <Input
            type="number"
            id="amount"
            inputMode="decimal"
            name="amount"
            disabled={disabled}
            ariaLabel={isMultiplier ? 'Donation Amount Multiplier' : 'Donation Amount'}
            placeholder={isMultiplier ? 'Donation Amount Multiplier' : 'Donation Amount'}
            prepend={isMultiplier ? 'x' : '$'}
            append={
              disabled ? null : (
                <Icon
                  className="position-absolute pointer transform-center font-normal close-btn"
                  icon="times"
                  onClick={() => removeAmount(donation)}
                />
              )
            }
            value={donation.amount}
            moneyInput
            onChange={event => updateAmount(event, donation)}
            validators={validators}
            errorMessages={errorMessages}
          />
        </FloatGroup>
      </div>
      {children}
    </div>
  );
}

DonationAmount.propTypes = {
  donation: PropTypes.object.isRequired,
  updateAmount: PropTypes.func.isRequired,
  removeAmount: PropTypes.func.isRequired,
  validators: PropTypes.array.isRequired,
  errorMessages: PropTypes.array.isRequired,
  isMultiplier: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

DonationAmount.defaultProps = {
  disabled: false,
  children: null,
};

export default DonationAmount;
