import React from 'react';
import { Row, Col } from 'react-bootstrap';

function FilterDrawerSection({ children }) {
  return (
    <Row>
      <Col lg="12">
        <div className="drawer-filter_section">
          <div className="drawer-filter_body">
            <Row>{children}</Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FilterDrawerSection;
