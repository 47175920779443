import React from 'react';

import { convertEpochTime } from 'core/utilities';
import { Table, Typography } from 'modules/common';

import { BILL_PAY_ACTIVITY_TYPES } from './config';
import { formatValue, linkBuilder, isVendorActivity } from './utils';
import { ActivityLink } from './activity-link';

export function ListViewDesktop({ items, isSettingsTab, profileCandidate }) {
  return (
    <div className="mt-3">
      <Table>
        <Table.Head className="bg-white">
          <Table.Row>
            <Table.HeaderCell className="w-25">Activity</Table.HeaderCell>
            <Table.HeaderCell>Vendor</Table.HeaderCell>
            <Table.HeaderCell>Committee</Table.HeaderCell>
            <Table.HeaderCell>Changed From</Table.HeaderCell>
            <Table.HeaderCell>Changed To</Table.HeaderCell>
            <Table.HeaderCell>By</Table.HeaderCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {items.map(billPay => {
            const activity = BILL_PAY_ACTIVITY_TYPES[billPay.activityType];
            const link = billPay.objectLink
              ? billPay.objectLink
              : linkBuilder(profileCandidate.organization_revv_uid, billPay.objectRevvUid, billPay.objectType);

            return (
              <Table.Row key={billPay.id}>
                <Table.Cell className="w-25 align-middle">
                  <Typography variant="bodyMedium" className="mb-0">
                    {activity?.displayName}
                  </Typography>

                  {isSettingsTab && activity.hasLink && (
                    <ActivityLink
                      url={link}
                      target={billPay.objectLink ? 'blank' : null}
                      isArchived={billPay.isArchived}
                    >
                      {billPay.objectLinkText || `/${billPay.objectRevvUid}`}
                    </ActivityLink>
                  )}
                </Table.Cell>
                <Table.Cell className="align-middle">{billPay.vendorName || '-'}</Table.Cell>
                <Table.Cell className="align-middle">
                  <div>{billPay.organizationName || '-'}</div>
                  {billPay.hostOrganizationName && (
                    <ul className="pl-4 mb-0 list-disc list-outside">
                      <li>{`via ${billPay.hostOrganizationName}`}</li>
                    </ul>
                  )}
                </Table.Cell>
                <Table.Cell className="align-middle">
                  {formatValue(activity?.valueFormat, billPay.changedFrom) || '-'}
                  {isVendorActivity(billPay.activityType) && <span className="display: block">default</span>}
                </Table.Cell>
                <Table.Cell className="align-middle">
                  {formatValue(activity?.valueFormat, billPay.changedTo) || '-'}
                  {isVendorActivity(billPay.activityType) && <span className="display: block">default</span>}
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <p className="m-0">{billPay.email}</p>
                    <p className="m-0">{convertEpochTime(billPay.date)}</p>
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
