import gql from 'graphql-tag';

export const orderFragments = {
  orders: gql`
    fragment Orders on MerchOrderResult {
      current_page
      page_count
      total_count

      results {
        revv_uid
        created_at
        first_name
        last_name
        total_price
        additional_amount
        email
        order_status
        order_number

        contributor_type
        pac_name
        business_name
      }
    }
  `,
  order: gql`
    fragment Order on MerchOrderResult {
      results {
        revv_uid
        created_at
        email
        is_revv_user

        first_name
        last_name
        shipping_address
        shipping_city
        shipping_state
        shipping_zip

        contributor_type
        pac_name
        business_name

        order_status
        order_number
        quantity

        sub_total_price
        shipping_cost
        total_price
        additional_amount

        charge_status
        refunded_amount
        disputed_amount

        refunds {
          amount_refunded
          refunded_at
        }
        disputes {
          amount_disputed
          disputed_at
        }

        profile_revv_uid
        charge_revv_uid

        order_items {
          revv_uid
          name
          quantity
        }

        shipments {
          shiphero_order_id
          tracking_url
          tracking_number
          carrier
          shipped_items {
            name
            quantity
            quantity_count
          }
        }
      }
    }
  `,
};

export const GET_ORDERS_REPORT = gql`
  query getOrdersReport(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $status: MerchOrderStatusTypeEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
    $ignoreCustomFields: Boolean
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        export_orders_report(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            status: $status
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
            ignoreCustomFields: $ignoreCustomFields
          }
        )
      }
    }
  }
`;

export const GET_ORDERS_QUERY = gql`
  query getOrders(
    $page: Int
    $limit: Int
    $amount: Float
    $amountPredicate: Predicate
    $status: MerchOrderStatusTypeEnum
    $search: String
    $dateRangeStart: String
    $dateRangeEnd: String
    $organizationRevvUid: String
  ) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        orders(
          filters: {
            page: $page
            limit: $limit
            amount: $amount
            amountPredicate: $amountPredicate
            status: $status
            search: $search
            dateRangeStart: $dateRangeStart
            dateRangeEnd: $dateRangeEnd
          }
        ) {
          ...Orders
        }
      }
    }
  }
  ${orderFragments.orders}
`;

export const GET_ORDER_QUERY = gql`
  query getOrder($revv_uid: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        orders(filters: { revvUid: $revv_uid }) {
          ...Order
        }
      }
    }
  }
  ${orderFragments.order}
`;
