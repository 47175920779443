import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { Error, SpinnerContainer, FormCardSpaced, Typography, WinRedChip, Icon } from 'modules/common';

const LogActivityView = ({ activity = [], error, loading, totalCount, genertateSentence, title = 'Bill-Pay Logs' }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [limit, setLimit] = useState(3);

  if (loading) return <SpinnerContainer />;
  if (error) return <Error error={error} />;

  const _expand = () => {
    setIsExpanded(!isExpanded);
    setLimit(!isExpanded ? totalCount : 3);
  };

  if (activity.length === 0) return null;

  return (
    <FormCardSpaced classNameBody="bg-catskill text-mirage" title={title}>
      <div className={isExpanded ? 'overflow-y-auto max-h-96' : ''}>
        {activity.slice(0, limit).map(activity => {
          return (
            <Typography className="mb-0 py-2 border-b border-mystic last:border-0 text-lynx" key={activity.id}>
              {genertateSentence(activity)}
            </Typography>
          );
        })}
      </div>

      {totalCount > 3 && (
        <WinRedChip className="mt-2" onClick={_expand}>
          View All
          <Icon className="ml-2" color="text-lynx" icon={isExpanded ? 'minus-circle' : 'plus-circle'} size="lg" />
        </WinRedChip>
      )}
    </FormCardSpaced>
  );
};

LogActivityView.propTypes = {
  activity: PropTypes.array,
  error: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,

  genertateSentence: PropTypes.func.isRequired,
};

export { LogActivityView };
