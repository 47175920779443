import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';

function FormColumn({ children, ...rest }) {
  return (
    <Col lg={11} {...rest}>
      {children}
    </Col>
  );
}

FormColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
};

export default FormColumn;
