import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Card } from 'modules/common';
import './index.scss';

function FormCard({
  title,
  subtitle,
  children,
  footer,
  className,
  classNameTitle,
  classNameBody,
  classNameFooter,
  classNameSubtitle,
  ...rest
}) {
  return (
    <Card className={classnames('form-card ', className)} {...rest}>
      <Card.Body className={classNameBody}>
        <div>
          {title ? (
            <Card.Title
              subtitle={subtitle}
              className={classnames(classNameTitle, { 'mb-3': !subtitle })}
              classNameSubtitle={classNameSubtitle}
            >
              {title}
            </Card.Title>
          ) : null}
        </div>

        {children}
      </Card.Body>
      {footer ? <Card.Footer className={classNameFooter}>{footer}</Card.Footer> : null}
    </Card>
  );
}

FormCard.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameFooter: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  footer: PropTypes.any,
  children: PropTypes.any,
};

FormCard.defaultProps = {
  title: '',
  subtitle: '',
  className: '',
  classNameBody: '',
  classNameFooter: '',
};

export default FormCard;
