import { useState } from 'react';
import { store } from '../../../configureStore';

export default function useLocalStorage(key, initialValue) {
  const state = store.getState();
  const organizationRevvUid = state?.login?.profile?.candidate?.organization_revv_uid;

  const getOrganizationStore = () => {
    let organizationStore = null;
    try {
      organizationStore = window.localStorage.getItem(organizationRevvUid);
      organizationStore = organizationStore ? JSON.parse(organizationStore) : {};
    } catch (error) {
      console.error(error);
      organizationStore = {};
    }

    return organizationStore;
  };

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const organizationStore = getOrganizationStore();
      return organizationStore.hasOwnProperty(key) ? organizationStore[key] : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      const organizationStore = getOrganizationStore();
      organizationStore[key] = valueToStore;
      setStoredValue(valueToStore);
      window.localStorage.setItem(organizationRevvUid, JSON.stringify(organizationStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}
