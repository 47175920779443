import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/common';

const ButtonLink = ({ url, text, children, disabled, ...rest }) => {
  if (disabled) return null;

  return (
    <Button as="a" href={url} target="_blank" rel="noopener noreferrer" size="lg" variant="primary" {...rest}>
      {text || children}
    </Button>
  );
};

ButtonLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(ButtonLink);
