import React from 'react';
import ProfileImage from 'modules/layout/navbar/profileMenus/profile-image.component';
import { Typography } from '.';

export function RecipientOrigin({ candidate, name, viaName }) {
  return (
    <div className="flex items-center content-center">
      <ProfileImage candidate={candidate} label="committee avatar" />
      <div className="ml-2">
        <Typography variant="bodyMedium" className="mb-0 c--mirage">
          {name}
        </Typography>
        {viaName && viaName !== name && (
          <ul className="list-disc list-outside ml-4">
            <li>
              <Typography className="mb-0">via {viaName}</Typography>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
