import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';

import { setToast } from 'core/toast';
import withAmplitude, { AmplitudePropTypes } from './amplitude.component';

function withCopyToClipBoard(FeatureComponent) {
  function WithCopyToClipBoardWrapper({ logEvent, setToast, ...props }) {
    const copyToClipBoard = ({ text, toast, logEventParams }) => {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;

      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      if (logEventParams) logEvent(logEventParams.name, logEventParams.parameters);
      if (toast) setToast({ message: toast });
    };

    return <FeatureComponent {...props} logEvent={logEvent} setToast={setToast} copyToClipBoard={copyToClipBoard} />;
  }

  WithCopyToClipBoardWrapper.propTypes = {
    logEvent: AmplitudePropTypes.logEvent.isRequired,
    setToast: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = dispatch => {
    return {
      setToast: message => dispatch(setToast(message)),
    };
  };

  return compose(withAmplitude, connect(null, mapDispatchToProps))(WithCopyToClipBoardWrapper);
}

export default withCopyToClipBoard;
