import gql from 'graphql-tag';

export const SETTINGS_INDIVIDUAL_MUTATION = gql`
  mutation saveOrganizationIndividualAccount(
    $individual: IndividualAccountInputType!
    $organizationRevvUid: String!
    $candidate: OrganizationInput!
  ) {
    individualUpdateAccount(account: $individual, organizationRevvUid: $organizationRevvUid) {
      errors
      account {
        business_website
      }
      owner {
        revv_uid
        email
        first_name
        last_name
        title
        phone
        dob_day
        dob_month
        dob_year
        ssn_last_4
        address
        address2
        city
        state
        zip
        stripe_identity_document_front_id
        stripe_identity_document_back_id
        account_opener
        full_ssn_token_provided
        owner
        status
      }
    }
    updateOrganization(organization: $candidate) {
      errors
      state_level_organization {
        timezone
      }
    }
  }
`;
