import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { Card, Typography, DoubleAddListItemButton, ConfirmModal, Button } from 'modules/common';
import { setToast } from 'core/toast';

import { profileCandidateSelector } from '../../../../core/login';
import { WinRedSelectOrSearch } from '../../../common/form/inputs/winred-select-or-search.component';

/**
 * UI for adding an existing upsell or creating a new one
 */
function GenerateFlow({
  onSelectNewUpsell,
  onSelectExistingUpsell,
  upsellDropdownOptions,
  searchText,
  searchSubText,
  searchPlaceholder,
  removeText,
  removeTitle,
  removeButtonText,
  removeButton,
  createText,
  createSubText,
  addText,
  addSubText,
  disabled,
  searchQuery,
  formatLoadedOptions,
  additionalQueryVars = null,
  searchKey,
}) {
  const [showAddExisting, setShowAddExisting] = useState(false);
  const [showDeleteExistingModal, setShowDeleteExistingModal] = useState(false);
  const profileCandidate = useSelector(profileCandidateSelector);

  const _onSelectExistingUpsell = event => {
    onSelectExistingUpsell(event);
    setShowAddExisting(false);
  };

  const queryVars = { organizationRevvUid: profileCandidate.organization_revv_uid, limit: 200 };

  if (additionalQueryVars) {
    for (const key in additionalQueryVars) {
      if (additionalQueryVars.hasOwnProperty(key)) {
        queryVars[key] = additionalQueryVars[key];
      }
    }
  }

  const disableAdd = upsellDropdownOptions?.length === 0;

  return (
    <>
      {showAddExisting ? (
        <Card>
          <Card.Body className="pb-4">
            <Typography variant="h5">{searchText}</Typography>
            <Typography className="mb-3">{searchSubText}</Typography>
            <WinRedSelectOrSearch
              valueSearchInput={''}
              labelSelectInput={''}
              placeHolderSelectInput={searchPlaceholder}
              placeHolderSearchInput={searchPlaceholder}
              baseQueryVariables={queryVars}
              valueSelectInput=""
              onChangeSelectInput={_onSelectExistingUpsell}
              searchQuery={searchQuery}
              name={''}
              dataFormatter={formatLoadedOptions}
              optionsSelectInput={upsellDropdownOptions}
              valueIsObject={false}
              searchKey={searchKey}
            />
          </Card.Body>
          <Card.Footer className="p-0">
            <Card variant="alt" className="border-0">
              <Card.Body className="d-flex justify-content-end pb-4">
                <Button variant="cancel" onClick={() => setShowDeleteExistingModal(true)}>
                  {removeButton}
                </Button>
              </Card.Body>
            </Card>
          </Card.Footer>
        </Card>
      ) : (
        <DoubleAddListItemButton
          addItem1={onSelectNewUpsell}
          addText1={createText}
          addSubText1={createSubText}
          addItem2={() => setShowAddExisting(true)}
          addText2={addText}
          addSubText2={addSubText}
          disable1={disabled}
          disable2={disabled || (disableAdd && !searchQuery)}
        />
      )}

      <ConfirmModal
        show={showDeleteExistingModal}
        title={removeTitle}
        buttonText={removeButtonText}
        buttonColor="cancel"
        handleClose={() => setShowDeleteExistingModal(false)}
        onSubmit={() => {
          setShowDeleteExistingModal(false);
          setShowAddExisting(false);
        }}
      >
        {removeText}
      </ConfirmModal>
    </>
  );
}

GenerateFlow.propTypes = {
  onSelectNewUpsell: PropTypes.func.isRequired,
  onSelectExistingUpsell: PropTypes.func.isRequired,
  isPartOfPage: PropTypes.bool,
  upsellDropdownOptions: PropTypes.array,
  searchText: PropTypes.string,
  searchSubText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  removeText: PropTypes.string,
  removeTitle: PropTypes.string,
  removeButtonText: PropTypes.string,
  removeButton: PropTypes.string,
  createText: PropTypes.string,
  createSubText: PropTypes.string,
  addText: PropTypes.string,
  addSubText: PropTypes.string,
  searchQuery: PropTypes.object,
  formatLoadedOptions: PropTypes.func,
  additionalQueryVars: PropTypes.object,
  searchKey: PropTypes.string,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setToast: message => dispatch(setToast(message)),
  };
};

export default connect(null, mapDispatchToProps)(GenerateFlow);
