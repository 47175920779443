import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Icon, ToolTip, WinRedChip } from 'modules/common';
import { convertEpochTime, formatNumberWithCommas } from 'core/utilities';
import { computeIconColor, computeTooltip, renderIcons } from '../ui.tools';

function renderUpsellItemMobile({ items, navigateToItem }) {
  if (!items) return null;

  return items.map(item => {
    const {
      revv_uid,
      name,
      upsell_group_statistics,
      page_count,
      status,
      created_at,
      public_upsell_group,
      bill_pay_exists,
    } = item;

    const iconColor = computeIconColor(status);
    const toolTip = computeTooltip(status);
    const navigateHandler = () => navigateToItem(revv_uid);

    return (
      <div
        key={revv_uid}
        className="list-item-card"
        onClick={navigateHandler}
        onKeyPress={navigateHandler}
        tabIndex="0"
        role="link"
      >
        <Row className="no-gutters">
          <Col xs="2" className="list-item-card-icon">
            <ToolTip tooltip={toolTip}>
              <Icon icon="donate" color={iconColor} />
            </ToolTip>
          </Col>
          <Col xs="10">
            <Row className="no-gutters">
              <Col className="list-item-card-title">
                {name}
                {public_upsell_group ? (
                  <WinRedChip className="ml-0 ml-md-2 mt-1 mt-md-0" variant="yellow">
                    Public
                  </WinRedChip>
                ) : null}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{renderIcons(item)}</Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                {bill_pay_exists ? (
                  <WinRedChip className="ml-0 ml-md-2 mt-1 mt-md-0 cursor-default" variant="purple">
                    Bill-Pay
                  </WinRedChip>
                ) : null}
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">
                Opt-In Rate: {(upsell_group_statistics.percentage * 100).toFixed(2)}%
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">Attached Pages: {formatNumberWithCommas(page_count)}</Col>
            </Row>
            <Row className="no-gutters">
              <Col className="list-item-card-text">{convertEpochTime(created_at)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });
}

renderUpsellItemMobile.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
};

export default renderUpsellItemMobile;
