import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import './add-list-item-button.scss';
import AddListItemButton from './add-list-item-button.component';

function DoubleAddListItemButtons({
  addItem1,
  addText1,
  addSubText1,
  disable1,
  addItem2,
  addText2,
  addSubText2,
  disable2,
}) {
  return (
    <Container fluid className="double-add-list-items">
      <Row>
        <Col sm={12} lg={12} className="d-flex justify-content-between mb-3 px-0">
          <AddListItemButton
            className="w-100"
            addItem={addItem1}
            addText={addText1}
            addSubText={addSubText1}
            disabled={disable1}
          />

          <AddListItemButton
            className="w-100"
            addItem={addItem2}
            addText={addText2}
            addSubText={addSubText2}
            disabled={disable2}
          />
        </Col>
      </Row>
    </Container>
  );
}

DoubleAddListItemButtons.propTypes = {
  addItem1: PropTypes.func.isRequired,
  addText1: PropTypes.string.isRequired,
  addSubText1: PropTypes.string.isRequired,
  disable1: PropTypes.bool,

  addItem2: PropTypes.func.isRequired,
  addText2: PropTypes.string.isRequired,
  addSubText2: PropTypes.string.isRequired,
  disable2: PropTypes.bool,
};

export default DoubleAddListItemButtons;
