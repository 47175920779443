import gql from 'graphql-tag';

export const CREATE_VENDOR_BANK_ACCOUNT = gql`
  mutation createVendorBankAccount(
    $bankAccount: BankAccountInput!
    $organizationRevvUid: String
    $vendorRevvUid: String!
  ) {
    createVendorBankAccount(
      bankAccount: $bankAccount
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors
      bankAccount {
        revv_uid
        account_holder_name
        routing_number
        last4
        account_holder_type
        bank_name
        status
        currency
        country
        default_for_currency
      }
    }
  }
`;

export const DEFAULT_VENDOR_BANK_ACCOUNT = gql`
  mutation defaultVendorBankAccount($revv_uid: String!, $organizationRevvUid: String, $vendorRevvUid: String!) {
    defaultVendorBankAccount(
      revvUid: $revv_uid
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors
      dammed
    }
  }
`;

export const REMOVE_VENDOR_BANK_ACCOUNT = gql`
  mutation removeVendorBankAccount($revv_uid: String!, $organizationRevvUid: String, $vendorRevvUid: String!) {
    removeVendorBankAccount(
      revvUid: $revv_uid
      organizationRevvUid: $organizationRevvUid
      vendorRevvUid: $vendorRevvUid
    ) {
      errors
      status
      dammed
    }
  }
`;
