import { formatDate, formatAmount } from 'core/utilities';

export const dateRangeFilterUi = ({
  filter,
  uiFilter,
  numberOfFilters,
  label = 'Date Range',
  startKey = 'dateRangeStart',
  endKey = 'dateRangeEnd',
}) => {
  // default value is today - we want to always make sure the computed today's date is actually today so create new date object
  const startValue = typeof filter[startKey] === 'function' ? filter[startKey]() : filter[startKey];
  const endValue = typeof filter[endKey] === 'function' ? filter[endKey]() : filter[endKey];

  if (!startValue && !endValue) return [uiFilter, numberOfFilters];

  if (startValue && endValue) {
    uiFilter.push({
      name: [startKey, endKey],
      defaultValue: [null, null],
      uiValue: `${label}: ${formatDate(startValue)} to ${formatDate(endValue)}`,
    });
    numberOfFilters += 2;
  } else if (startValue) {
    uiFilter.push({
      name: startKey,
      defaultValue: null,
      uiValue: `${label} Start: ${formatDate(startValue)}`,
    });
    numberOfFilters++;
  } else {
    uiFilter.push({
      name: endKey,
      defaultValue: null,
      uiValue: `${label} End: ${formatDate(endValue)}`,
    });
    numberOfFilters++;
  }

  return [uiFilter, numberOfFilters];
};

export const searchFilterUi = ({ filter, uiFilter, numberOfFilters, key = 'search', label = 'Search' }) => {
  return inputFilterUi({ filter, uiFilter, numberOfFilters, prop: key, label });
};

export const inputFilterUi = ({ filter, uiFilter, numberOfFilters, prop, label, defaultValue = null }) => {
  if (filter[prop]) {
    uiFilter.push({
      name: prop,
      defaultValue,
      uiValue: `${label}: ${filter[prop]}`,
    });
    numberOfFilters++;
  }

  return [uiFilter, numberOfFilters];
};

export const amountFilterUi = ({
  filter,
  uiFilter,
  numberOfFilters,
  label = 'Amount',
  predicateKey = 'amountPredicate',
  amountKey = 'amount',
}) => {
  if (!filter[amountKey]) return [uiFilter, numberOfFilters];

  if (filter[predicateKey] === 'EQ') label += ` Equals`;
  if (filter[predicateKey] === 'GTE') label += ` Greater Than or Equal to`;
  if (filter[predicateKey] === 'LTE') label += ` Less Than or Equal to`;

  uiFilter.push({
    name: [predicateKey, amountKey],
    defaultValue: ['EQ', null],
    uiValue: `${label}: ${formatAmount(filter[amountKey], false)}`,
  });
  numberOfFilters++;

  return [uiFilter, numberOfFilters];
};

export const percentFilterUi = ({
  filter,
  uiFilter,
  numberOfFilters,
  label = 'Percentage',
  predicateKey = 'predicate',
  percentKey = 'percentage',
}) => {
  if (!filter[percentKey]) return [uiFilter, numberOfFilters];

  if (filter[predicateKey] === 'EQ') label += ` Equals`;
  if (filter[predicateKey] === 'GTE') label += ` Greater Than or Equal to`;
  if (filter[predicateKey] === 'LTE') label += ` Less Than or Equal to`;

  uiFilter.push({
    name: [predicateKey, percentKey],
    defaultValue: ['EQ', null],
    uiValue: `${label}: ${filter[percentKey]}%`,
  });
  numberOfFilters++;

  return [uiFilter, numberOfFilters];
};

export const dropdownFilterUi = ({
  filter, // the filter itself
  uiFilter, // filter pillbox array being built to show on ui
  numberOfFilters, // keep track of number of filters for fitler dropdown number
  dropdownOptions,
  filterKey, // these two allow filter to know what is default value to hide pillbox if set
  defaultValue = null,
  showDefault = false, // always show default filter value in pillboxes
  label, // the label shown in the pillbox
}) => {
  if (!showDefault && (!filter[filterKey] || filter[filterKey] === defaultValue)) return [uiFilter, numberOfFilters];

  const selectedDropdownOption = dropdownOptions.find(o => o.value === filter[filterKey]);
  if (selectedDropdownOption) {
    uiFilter.push({
      name: filterKey,
      defaultValue: defaultValue,
      uiValue: label ? `${label}: ${selectedDropdownOption.label}` : selectedDropdownOption.label,
    });
    numberOfFilters++;
  }

  return [uiFilter, numberOfFilters];
};

export const multiDropdownFilterUi = ({ filter, uiFilter, numberOfFilters, filterKey, defaultValue = null, label, requiredFilter }) => {
  if (!filter[filterKey]) return [uiFilter, numberOfFilters];

  // could be a function we have to call to generate array first
  // compare concated list of values (cant compare two arrays directly) to see if is default value
  let filterValue = typeof filter[filterKey] === 'function' ? filter[filterKey]() : filter[filterKey];
  filterValue = filterValue.map(opt => opt.value).join(', ');

  const _defaultValue = defaultValue.map(opt => opt.value).join(', ');
  if (filterValue === _defaultValue || !filterValue) return [uiFilter, numberOfFilters];

  uiFilter.push({
    name: filterKey,
    defaultValue: defaultValue,
    uiValue: `${label}: ${filter[filterKey].map(opt => opt.label).join(', ')}`,
    requiredFilter: requiredFilter,
  });
  numberOfFilters++;

  return [uiFilter, numberOfFilters];
};
