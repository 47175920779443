import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CANDIDATE_CREATE_MOOV_ACCOUNT, ORGANIZATION_CREATE_MOOV_ACCOUNT } from 'core/middleware/queries';
import Input from '../input.component';
import { Col, Row } from 'react-bootstrap';
import { useToast } from 'modules/common/hooks';
import Typography from 'modules/common/typography.component';
import PropTypes from 'prop-types';

const MoovCreditCardInput = ({
  cardData: { cardNumber, cardDate, cardZip, cardCvv },
  onChangeError,
  setIsFormReady,
  handleMoovToken,
  moovCardValidationError,
  isOrganization,
  data,
  profileCandidate,
  onChange,
  setIsCardLoading,
}) => {
  const setToast = useToast();
  const [moovAccountId, setMoovAccountId] = useState('');
  const [moovCardId, setMoovCardId] = useState('');
  const [isTokenInitialized, setIsTokenInitialized] = useState(false);
  const [isCardInitialized, setIsCardInitialized] = useState(false);
  const [showDisabledText, setShowDisabledText] = useState(true);
  const cardFormInputRef = useRef(null);
  const cardNumberInputRef = useRef(null);
  const cardDateInputRef = useRef(null);
  const cardZipInputRef = useRef(null);
  const cardCvvInputRef = useRef(null);
  const showCvv = !profileCandidate.moov_cvv_optional;

  const [createMoovAccount] = useMutation(
    isOrganization ? ORGANIZATION_CREATE_MOOV_ACCOUNT : CANDIDATE_CREATE_MOOV_ACCOUNT
  );

  const enableCardInput = () => {
    if (cardNumberInputRef.current) cardNumberInputRef.current.disabled = false;
    if (cardDateInputRef.current) cardDateInputRef.current.disabled = false;
    if (cardZipInputRef.current) cardZipInputRef.current.disabled = false;
    if (cardCvvInputRef.current) cardCvvInputRef.current.disabled = false;
  };

  useEffect(() => {
    if (data.first_name !== '' && data.last_name !== '') {
      enableCardInput();
      setShowDisabledText(false);
    } else {
      setShowDisabledText(true);
    }
  }, [data.first_name, data.last_name, isCardInitialized]);

  useEffect(() => {
    if (moovCardId !== '' && moovAccountId !== '' && !isTokenInitialized) {
      handleMoovToken({ moovCardId, moovAccountId });
      setIsTokenInitialized(true);
    }
  }, [moovCardId, moovAccountId, handleMoovToken, isTokenInitialized]);

  const fetchAccessToken = useCallback(async () => {
    const variables = {
      organizationRevvUid: profileCandidate.organization_revv_uid,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
    };

    if (!data.moov_account_card_id) {
      try {
        const response = await createMoovAccount({ variables });
        const { accountId, accountToken } = response.data.moovCreateDonorAccount;
        if (response.data.moovCreateDonorAccount.email) {
          onChange({ target: { name: 'moov_account_email', value: response.data.moovCreateDonorAccount.email } });
        }
        setMoovAccountId(accountId);
        return { accountId, accountToken };
      } catch (error) {
        setToast({ isError: true, message: 'There has been an error creating your moov account.' });
        console.error('Error fetching access token:', error);
      }
    }
  }, [
    createMoovAccount,
    data.email,
    data.first_name,
    data.last_name,
    data.moov_account_card_id,
    profileCandidate.organization_revv_uid,
    setToast,
    onChange,
  ]);

  const handleReadableStream = useCallback(
    result => {
      if (!(result.body instanceof ReadableStream)) {
        console.error('The provided argument is not a ReadableStream.');
        return;
      }

      const reader = result.body.getReader();
      let receivedData = '';

      const readStream = ({ done, value }) => {
        if (done) {
          let jsonData = JSON.parse(receivedData);
          setMoovCardId(jsonData.cardID);
          setIsTokenInitialized(false);
          setIsCardLoading(false);
          return;
        }
        receivedData += new TextDecoder().decode(value);

        reader
          .read()
          .then(readStream)
          .catch(error => {
            console.error('Error reading from stream', error);
          });
      };

      reader
        .read()
        .then(readStream)
        .catch(error => {
          console.error('Error reading from stream', error);
        });
    },
    [setIsCardLoading]
  );

  useEffect(() => {
    if (!isCardInitialized) {
      cardFormInputRef.current = document.querySelector('moov-form');
      let elementRefs = {
        'card-number': cardNumberInputRef,
        'expiration-date': cardDateInputRef,
        text: cardZipInputRef,
      };
      if (showCvv) {
        elementRefs = {
          ...elementRefs,
          'card-security-code': cardCvvInputRef,
        };
      }
      const defaultStyles = {
        fontSize: '15px',
        color: '#1e2b3a',
        fontWeight: '500',
      };

      // Set refs and styles
      Object.keys(elementRefs).forEach(key => {
        const ref = elementRefs[key];
        ref.current = document.querySelector(`moov-${key}-input`);
        ref.current.inputStyle = defaultStyles;
      });

      const handleOnChange = (ref, stateKey, validationMessage) => e => {
        setIsCardLoading(true);
        let newState = { ...stateKey };
        newState.isUsed = true;
        newState.isValid = ref.current.validity.valid;
        if (
          ref.current.validationMessage === 'Please fill out this field.' ||
          ref.current.validationMessage === 'Please match the requested format.'
        ) {
          newState.validationMessage = validationMessage;
        } else {
          newState.validationMessage = ref.current.validationMessage;
        }

        onChangeError({ target: { name: stateKey, value: newState } });
      };

      cardNumberInputRef.current.onChange = handleOnChange(
        cardNumberInputRef,
        'cardNumber',
        'Please enter a valid card number'
      );
      cardDateInputRef.current.onChange = handleOnChange(
        cardDateInputRef,
        'cardDate',
        'Please enter a valid expiration date'
      );
      cardZipInputRef.current.onChange = handleOnChange(
        cardZipInputRef,
        'cardZip',
        'Please enter a valid postal code.'
      );
      if (showCvv)
        cardCvvInputRef.current.onChange = handleOnChange(cardCvvInputRef, 'cardCvv', 'Please enter a valid CVV.');

      cardFormInputRef.current.onSuccess = handleReadableStream;
      cardFormInputRef.current.onError = error => {
        setIsCardLoading(false);
        onChange({ target: { name: 'moov_account_card_id', value: '' } });
        setToast({ isError: true, message: 'There has been an error with your card. Please try again' });
      };
      setIsCardInitialized(true);
    }
  }, [isCardInitialized, onChangeError, setToast, showCvv, setIsCardLoading, onChange, handleReadableStream]);

  const onCardSubmission = useCallback(async () => {
    let tokenForRequest = cardFormInputRef.current.oauthToken;
    try {
      if (!data.moov_account_id) {
        const { accountId, accountToken } = await fetchAccessToken();
        cardFormInputRef.current.oauthToken = accountToken;
        cardFormInputRef.current.accountID = accountId;
        tokenForRequest = accountToken;
      }
      cardFormInputRef.current.requestHeaders = {
        'content-type': 'application/json',
        Authorization: `Bearer ${tokenForRequest}`,
      };
      cardFormInputRef.current.submit();
    } catch (error) {
      console.error({ message: error.message });
    }
  }, [fetchAccessToken, data.moov_account_id]);

  useEffect(() => {
    let cardFields = [cardNumber, cardDate, cardZip];
    if (showCvv) cardFields.push(cardCvv);
    const hasFieldBeenUsed = cardFields.some(field => field.isUsed);
    const areAllFieldsValidAndUsed = cardFields.every(field => field.isValid && field.isUsed);
    if (hasFieldBeenUsed) {
      if (areAllFieldsValidAndUsed) {
        setIsFormReady(true);
        onCardSubmission();
      } else {
        setIsFormReady(false);
      }
    }
    // eslint-disable-next-line
  }, [cardNumber, cardDate, cardZip, cardCvv, setIsFormReady, showCvv]);

  return (
    <div>
      <div style={{ visibility: 'hidden' }}>
        <moov-form name="moov-form" method="POST" action={`/accounts/${moovAccountId}/cards`}></moov-form>
      </div>
      <Row>
        <Col xs={showCvv ? 8 : 12} md={showCvv ? 5 : 6} className={showCvv ? 'pr-0' : 'pr-3 md:pr-0'}>
          <div
            className={`moov-credit-card ${
              (!cardNumber.isValid && cardNumber.isUsed) || (!cardNumber.isValid && moovCardValidationError)
                ? 'moov-card-error'
                : ''
            }`}
          >
            <moov-card-number-input
              placeholder="Card Number"
              formname="moov-form"
              name="cardNumber"
              autocomplete="new-password"
              required
              disabled
            ></moov-card-number-input>
          </div>
        </Col>
        <Col xs={showCvv ? 4 : 6} md={showCvv ? 2 : 3} className="md:pr-0">
          <div
            className={`moov-credit-card ${
              (!cardDate.isValid && cardDate.isUsed) || (!cardDate.isValid && moovCardValidationError)
                ? 'moov-card-error'
                : ''
            }`}
          >
            <moov-expiration-date-input
              formname="moov-form"
              name="expiration"
              autocomplete="new-password"
              disabled
              required
            ></moov-expiration-date-input>
          </div>
        </Col>
        {showCvv ? (
          <Col xs={6} md={2} className="pr-0">
            <div
              className={`moov-credit-card ${
                (!cardCvv.isValid && cardCvv.isUsed) || (!cardCvv.isValid && moovCardValidationError)
                  ? 'moov-card-error'
                  : ''
              }`}
            >
              <moov-card-security-code-input
                formname="moov-form"
                name="cardCvv"
                placeholder="CVV"
                autocomplete="new-password"
                disabled
                required
              ></moov-card-security-code-input>
            </div>
          </Col>
        ) : null}
        <Col xs={6} md={3}>
          <div
            className={`moov-credit-card ${
              (!cardZip.isValid && cardZip.isUsed) || (!cardZip.isValid && moovCardValidationError)
                ? 'moov-card-error'
                : ''
            }`}
          >
            <moov-text-input
              formname="moov-form"
              placeholder="ZIP"
              name="billingAddress.postalCode"
              pattern="^\d{5}(-\d{4})?$"
              required
              minLength="5"
              maxLength="5"
              disabled
            ></moov-text-input>
          </div>
        </Col>
      </Row>
      {showDisabledText ? (
        <Typography className="mt-2">Please enter first and last name before entering card information</Typography>
      ) : null}
    </div>
  );
};

MoovCreditCardInput.propTypes = {
  cardData: PropTypes.object.isRequired,
  onChangeError: PropTypes.func.isRequired,
  setIsFormReady: PropTypes.func.isRequired,
  handleMoovToken: PropTypes.func.isRequired,
  moovCardValidationError: PropTypes.bool.isRequired,
  isOrganization: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  profileCandidate: PropTypes.object.isRequired,
};

const WinRedMoovCreditCardInput = ({
  isOrganization,
  profileCandidate,
  data,
  handleMoovToken,
  moovCardValidationError,
  onChange,
  setIsCardLoading,
}) => {
  const [isFormReady, setIsFormReady] = useState('');
  const [cardNumber, setCardNumber] = useState({
    isUsed: false,
    isValid: true,
    validationMessage: '',
  });
  const [cardDate, setCardDate] = useState({
    isUsed: false,
    isValid: true,
    validationMessage: '',
  });
  const [cardZip, setCardZip] = useState({
    isUsed: false,
    isValid: true,
    validationMessage: '',
  });
  const [cardCvv, setCardCvv] = useState({
    isUsed: false,
    isValid: true,
    validationMessage: '',
  });

  const errorMessage = useMemo(() => {
    const inputArray = [cardNumber, cardDate, cardZip, cardCvv];
    let message = '';
    inputArray.forEach(input => {
      if (input.validationMessage) {
        if (message !== '') message += ', ';
        message += input.validationMessage;
      }
    });
    return message;
  }, [cardNumber, cardDate, cardZip, cardCvv]);

  const onChangeError = ({ target: { name, value } }) => {
    if (name === 'cardNumber') {
      setCardNumber(value);
    }
    if (name === 'cardDate') {
      setCardDate(value);
    }
    if (name === 'cardZip') {
      setCardZip(value);
    }
    if (name === 'cardCvv') {
      setCardCvv(value);
    }
  };

  return (
    <div className="moov-credit-card-container">
      <>
        <MoovCreditCardInput
          onChangeError={onChangeError}
          isFormReady={isFormReady}
          setIsFormReady={setIsFormReady}
          cardData={{ cardNumber, cardDate, cardZip, cardCvv }}
          handleMoovToken={handleMoovToken}
          moovCardValidationError={moovCardValidationError}
          data={data}
          isOrganization={isOrganization}
          profileCandidate={profileCandidate}
          onChange={onChange}
          setIsCardLoading={setIsCardLoading}
        />
      </>
      <Input
        hidden
        value={isFormReady}
        name="moov-card"
        onChange={() => {}}
        validators={['required']}
        errorMessages={[errorMessage || 'All fields required to process the donation']}
      />
    </div>
  );
};

WinRedMoovCreditCardInput.propTypes = {
  isOrganization: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleMoovToken: PropTypes.func.isRequired,
  moovCardValidationError: PropTypes.bool.isRequired,
};

export default WinRedMoovCreditCardInput;
