import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FormCard, Typography, Button, Icon, useClipBoard } from 'modules/common';
import { isAgencySelector, profileCandidateSelector } from 'core/login';

import { COMMITTEE_CONNECTION_CARD_VARIANTS } from './committee-connect.tools';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';

import 'modules/common/committee-connect/committees.scss';

function CommitteeConnectionCard({ committee, variant, onCancelRequest, onOpenApprove }) {
  const isAgency = useSelector(isAgencySelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const copyToClipBoard = useClipBoard();

  const copyApprovalLink = useCallback(() => {
    const url = `${window.location.origin}/${committee.revv_uid}${
      !isAgency ? '/vendors/settings#committees' : '/settings#vendors'
    }`;

    copyToClipBoard({
      text: url,
      toast: 'Approval link copied to clipboard.',
    });
  }, [copyToClipBoard, committee, isAgency]);

  const descriptionText = useMemo(() => {
    let text = '';

    switch (variant) {
      case COMMITTEE_CONNECTION_CARD_VARIANTS.SENT: {
        if (!isAgency) {
          text = `Awaiting ${committee.is_revv_share ? 'Bill-Pay Only' : 'Full Access'} approval from ${
            committee.name
          }`;
          break;
        }

        text = `Awaiting ${profileCandidate.is_revv_share ? 'Bill-Pay Only' : 'Full Access'} approval from ${
          committee.name
        }`;
        break;
      }

      default: {
        if (!isAgency) {
          text = `Connection received from ${committee.name}, awaiting ${
            committee.is_revv_share ? 'Bill-Pay Only' : 'Full Access'
          } approval.`;
          break;
        }

        text = `Connection received from ${committee.name}, awaiting ${
          profileCandidate.is_revv_share ? 'Bill-Pay Only' : 'Full Access'
        } approval.`;
      }
    }

    return text;
  }, [committee, isAgency, profileCandidate, variant]);

  return (
    <FormCard classNameBody="flex flex-wrap flex-xl-nowrap pb-4 items-center border rounded">
      <div className="flex items-center">
        <img
          className="h-8 w-8 rounded-full flex-none object-cover"
          alt="connection profile"
          src={committee?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar}
        />

        <div className="ml-4">
          <Typography variant="h4">{committee.name}</Typography>
          <Typography className="mb-0">{descriptionText}</Typography>
        </div>
      </div>

      <div className="mx-auto ml-xl-auto mr-xl-0 flex flex-wrap mt-3 mt-xl-0 percent justify-around">
        {variant === COMMITTEE_CONNECTION_CARD_VARIANTS.RECIEVED ? (
          <Button className="text-nowrap" onClick={onOpenApprove}>
            Approve or Decline
          </Button>
        ) : (
          <>
            <Button className="text-nowrap mr-1" onClick={() => onCancelRequest(committee)}>
              Cancel Request
            </Button>
            <Button className="text-nowrap" onClick={copyApprovalLink}>
              Copy Approval Link <Icon icon="copy" color="white" className="ml-2" />
            </Button>
          </>
        )}
      </div>
    </FormCard>
  );
}

CommitteeConnectionCard.propTypes = {
  committee: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([COMMITTEE_CONNECTION_CARD_VARIANTS.RECIEVED, COMMITTEE_CONNECTION_CARD_VARIANTS.SENT]),

  onCancelRequest: PropTypes.func,
  onOpenApprove: PropTypes.func,
};

export { CommitteeConnectionCard };
