import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Icon, Avatar } from 'modules/common';
import { getAvatar } from 'core/utilities';

import { CommitteeCardDetails } from './committee-card-details.component';
import './committees.scss';

/**
 * UI for when a committee/candidate is selected
 */
function SelectedCommittee({
  conduitCandidate,
  getAvatar,
  isDragable = false,
  isPetitionPage = false,
  isOrganization = false,
  isTeamPages = false,
  showExtraOptions = false,
  splitEvenly = false,
  sortingDisabled = false,
  isBillPay = false,
}) {
  const candidate = conduitCandidate.candidate || conduitCandidate.organization;

  const avatar = useMemo(() => getAvatar(candidate, isOrganization), [getAvatar, candidate, isOrganization]);
  const shouldShowPercentage = !showExtraOptions && !isOrganization && !splitEvenly && !isPetitionPage;

  return (
    <Col xs={10} lg={5}>
      <Row className="align-items-center">
        {sortingDisabled ? null : (
          <Col xs="auto" className="pl-2 pr-0 d-flex align-items-center">
            <Icon icon="bars" color={isDragable ? 'grey' : 'white'} size="lg" />
          </Col>
        )}

        <Col xs="auto" className="pr-0">
          <Avatar avatar={avatar} />
        </Col>

        <Col>
          <CommitteeCardDetails
            conduitCandidate={conduitCandidate}
            isTeamPages={isTeamPages}
            shouldShowPercentage={shouldShowPercentage}
            isBillPay={isBillPay}
          />
        </Col>
      </Row>
    </Col>
  );
}

SelectedCommittee.propTypes = {
  conduitCandidate: PropTypes.object.isRequired,
  getAvatar: PropTypes.func,
  generateCommitteeName: PropTypes.func,
  isOrganization: PropTypes.bool,
  isDragable: PropTypes.bool,
  isTeamPages: PropTypes.bool,
  shouldShowPercentage: PropTypes.bool,
  isBillPay: PropTypes.bool,
};

SelectedCommittee.defaultProps = {
  getAvatar: getAvatar,
  isOrganization: false,
  isDragable: false,
  isTeamPages: false,
};

const component = React.memo(SelectedCommittee);
export { component as SelectedCommittee };
