import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './nested-drawer.scss';

export function NestedDrawer({ isLeft = false, open, children, className = '', setbackdropRef, footer, isNested }) {
  return ReactDOM.createPortal(
    <>
      <div
        ref={setbackdropRef}
        className={classnames({
          'nested-drawer-open-back': open,
        })}
      ></div>
      <div
        className={classnames('nested-drawer', className, {
          open: open,
          'nested-drawer-left': isLeft,
          'nested-drawer-right': !isLeft,
          'full-width': !isNested,
          'nested-width': isNested,
        })}
      >
        <div className="nested-drawer-content">{children}</div>
        {footer ? <div className="nested-drawer-footer">{footer}</div> : null}
      </div>
    </>,
    document.body
  );
}

NestedDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isLeft: PropTypes.bool,
  children: PropTypes.any.isRequired,
  footer: PropTypes.any,
};
