import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { CollapsibleCard } from 'modules/common';

function FilterDrawerSectionCollapse({ children, title }) {
  return (
    <Row>
      <Col lg="12">
        <div className="drawer-filter_section">
          <CollapsibleCard open={false} title={title}>
            <div className="drawer-filter_body">
              <Row>{children}</Row>
            </div>
          </CollapsibleCard>
        </div>
      </Col>
    </Row>
  );
}

FilterDrawerSectionCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterDrawerSectionCollapse;
