import { useSelector } from 'react-redux';
import { isAgencySelector, isOrganizationSelector } from 'core/login';

/**
 *  we use skip option in apollo to know which query/Gqls to call -
 *  we use this selector to figure out if we should be skipping the current query or not
 */
export const skipQuery = ({ props, isOrganizationQuery = false, isAgencyQuery = false }) => {
  const { isOrganization, isAgency } = props;

  // if agency
  if (isAgencyQuery && isAgency) return false;

  // if state level user
  if (isOrganizationQuery && isOrganization) return false;

  // if federal level user
  if (!isOrganizationQuery && !isOrganization && !isAgencyQuery && !isAgency) return false;

  return true;
};

export const getGql = ({
  isOrganization = false,
  isAgency = false,
  organizationGql = '',
  agencyGql = '',
  federalGql = '',
}) => {
  if (isAgency) return agencyGql;
  if (isOrganization) return organizationGql;
  if (!isOrganization) return federalGql;
};

export const useGetGql = ({ organizationGql, agencyGql, federalGql }) => {
  const isOrganization = useSelector(isOrganizationSelector);
  const isAgency = useSelector(isAgencySelector);

  return getGql({ isOrganization, isAgency, organizationGql, agencyGql, federalGql });
};
