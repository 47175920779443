import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { convertEpochTime } from 'core/utilities';
import { Icon, Table, ToolTip, IndexTableContextMenu } from 'modules/common';

export function ConditionItemsDesktop({ items, navigateToItem, dropdownAction }) {
  const tableBody = items.map(item => {
    const {
      revv_uid,
      name,
      created_at,
      pathway_count,
    } = item;

    const navigateHandler = () => navigateToItem(item);

    return (
      <Table.Row key={revv_uid} onClick={navigateHandler} onKeyPress={navigateHandler} tabIndex="0" role="link">
        <Table.Cell textAlign="center" className="icon-cell">
          <ToolTip tooltip="Condition">
            <Icon icon="condition-icon" size="sm" />
          </ToolTip>
        </Table.Cell>
        <Table.Cell>{convertEpochTime(created_at)}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>{pathway_count}</Table.Cell>
        <Table.Cell textAlign="center" noClick>
          <IndexTableContextMenu onSelect={eventKey => dropdownAction(eventKey, item)}>
            <Dropdown.Item eventKey="details">View Details</Dropdown.Item>
            <Dropdown.Item eventKey="duplicate">Duplicate</Dropdown.Item>
            {pathway_count === 0 && <Dropdown.Item eventKey="archive">Archive</Dropdown.Item>}
          </IndexTableContextMenu>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell className="d-none d-md-table-cell" />
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Condition Name</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell>Attached Pathways</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Head>
      <Table.Body className="pointer">{tableBody}</Table.Body>
    </Table>
  );
}

ConditionItemsDesktop.propTypes = {
  items: PropTypes.array.isRequired,
  navigateToItem: PropTypes.func.isRequired,
  dropdownAction: PropTypes.func.isRequired,
};
