import React from 'react';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import { Icon } from 'modules/common';
import MobileSubMenu from './mobile-submenu.component';
import '../../nav-bar.scss';

function NavBarMenuRevenue({
  toggleSubNav,
  hasActiveChildRevenue,
  showMobileSubNav,
  organizationRevvUid,
  isAgency,
  removeAccess,
}) {
  if (removeAccess.includes(true)) return null;

  const donationsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/revenue/donations`
    : `/${organizationRevvUid}/revenue/donations`;

  const subscriptionsRoute = isAgency
    ? `/${organizationRevvUid}/vendors/revenue/subscriptions`
    : `/${organizationRevvUid}/revenue/subscriptions`;

  return (
    <>
      <Nav.Item
        className={classNames('winred-nav__nav-item', {
          'winred-nav__nav-item--active': hasActiveChildRevenue,
        })}
      >
        <Nav.Link className="winred-nav__nav-link" onClick={() => toggleSubNav('showRevenueSubNav')}>
          Revenue
          <Icon
            icon={`chevron-${showMobileSubNav ? 'up' : 'down'}`}
            size={'xs'}
            color={hasActiveChildRevenue ? 'blue' : 'inactive'}
            className="winred-nav--caret"
          />
        </Nav.Link>
      </Nav.Item>
      <MobileSubMenu showMobileSubNav={showMobileSubNav}>
        <Nav.Link eventKey={donationsRoute}>Donations</Nav.Link>
        {isAgency ? null : <Nav.Link eventKey={subscriptionsRoute}>Subscriptions</Nav.Link>}
        {isAgency ? null : (
          <Nav.Link eventKey={`/${organizationRevvUid}/revenue/money-pledges`}>Money Pledges</Nav.Link>
        )}
      </MobileSubMenu>
    </>
  );
}

export default NavBarMenuRevenue;
