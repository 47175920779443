import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  FormCardSpaced,
  FlexBinaryChoiceRow,
  WinRedSelect,
  FREQUENCY_OPTIONS,
  FREQUENCY_OPTIONS_TEXT_MAP,
} from 'modules/common';

function ReportFrequency({ report, onChange }) {
  const handleChange = e => {
    if (e.target.value) {
      onChange({ target: { name: 'frequency_enum', value: 'MONTHLY' } });
      return;
    }

    onChange({ target: { name: 'frequency_enum', value: 'CUSTOM' } });
  };

  const selectedFrequency = useMemo(() => {
    const selectedFrequency = FREQUENCY_OPTIONS.find(option => option.value === report.frequency_enum);

    if (selectedFrequency)
      return {
        label: FREQUENCY_OPTIONS_TEXT_MAP[selectedFrequency.value],
        value: selectedFrequency.value,
      };

    return null;
  }, [report]);

  const renderedCardFooter = useMemo(() => {
    if (report.frequency_enum === null || report.frequency_enum === 'CUSTOM') return null;

    return (
      <>
        <WinRedSelect
          description="Select the schedule on which to run your report. This will disable date range in report conditions."
          placeholder="Schedule Frequency"
          name="frequency_enum"
          numberOfDropdownItems={4}
          options={FREQUENCY_OPTIONS.filter(option => option.value !== 'CUSTOM').map(option => ({
            label: FREQUENCY_OPTIONS_TEXT_MAP[option.value],
            value: option.value,
          }))}
          value={selectedFrequency}
          onChange={onChange}
        />
        <FormCardSpaced classNameFooter="bg--catskill-white">
          <FlexBinaryChoiceRow
            className="mb-0"
            falseText="Inactive"
            name="active"
            subTitle="Set this scheduled report to active or inactive."
            title="Report Status"
            trueText="Active"
            value={report.active}
            onChange={e => onChange({ target: { ...e.target, value: e.target.value } })}
          />
        </FormCardSpaced>
      </>
    );
  }, [report, selectedFrequency, onChange]);

  return (
    <FormCardSpaced classNameFooter="bg--catskill-white" footer={renderedCardFooter}>
      <FlexBinaryChoiceRow
        className="mb-0"
        falseText="Manual"
        name="frequency_enum"
        subTitle="Select whether this report should run on an automatic recurring schedule or run manually."
        title="Report Frequency"
        trueText="Scheduled"
        value={report.frequency_enum !== null && report.frequency_enum !== 'CUSTOM'}
        onChange={handleChange}
      />
    </FormCardSpaced>
  );
}

ReportFrequency.propTypes = {
  report: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { ReportFrequency };
