import React from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { WinRedDatePicker } from 'modules/common';

const DateRangeInput = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startName = 'dateRangeStart',
  endName = 'dateRangeEnd',
  title,
  classNameContainer,
  disabled,
  showPlacholder = false,
  required = false,
}) => {
  const _startDate = typeof startDate === 'function' ? startDate() : startDate;
  const _endDate = typeof endDate === 'function' ? endDate() : endDate;

  return (
    <>
      <Form.Label>{title}</Form.Label>
      <InputGroup className="date-range-input">
        <WinRedDatePicker
          value={_startDate}
          onChange={onStartDateChange}
          className="start-date"
          classNameContainer={classNameContainer}
          name={startName}
          popperPlacement="bottom-end"
          disabled={disabled}
          placeholder={showPlacholder ? 'Start Date' : ''}
          validators={required ? [{ validator: 'required', message: 'Start Date is required' }] : []}
        />
        <div className="input-group-prepend input-group-append">
          <InputGroup.Text>To</InputGroup.Text>
        </div>
        <WinRedDatePicker
          value={_endDate}
          onChange={onEndDateChange}
          className="end-date"
          classNameContainer={classNameContainer}
          name={endName}
          popperPlacement="bottom-end"
          disabled={disabled}
          placeholder={showPlacholder ? 'End Date' : ''}
          validators={required ? [{ validator: 'required', message: 'End Date is required' }] : []}
        />
      </InputGroup>
    </>
  );
};

export default DateRangeInput;
