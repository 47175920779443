import gql from 'graphql-tag';

export const GET_BLOCKED_DONORS = gql`
  query getBlockedDonors($email: String, $organizationRevvUid: String!, $limit: Int, $page: Int) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        blocked_donors(filters: { limit: $limit, email: $email, page: $page }) {
          current_page
          page_count
          total_count
          results {
            email
            block_source
            expiration_date
            reason
            revv_uid
            deleted_at
            created_at
            added_by
          }
        }
      }
    }
  }
`;

export const GET_BLOCKED_DONOR_REPORT = gql`
  query getBlockedDonorReport($search: String, $organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        payouts(filters: { search: $search }) {
          results {
            exportPayoutDetailReport
            revv_uid
          }
        }
      }
    }
  }
`;

export const GET_BLOCKED_DONOR = gql`
  query getBlockedDonor($organizationRevvUid: String, $revvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        blocked_donors(filters: { revvUid: $revvUid }) {
          results {
            email
            block_source
            expiration_date
            reason
            revv_uid
            deleted_at
            created_at
            added_by
          }
        }
      }
    }
  }
`;

export const CREATE_BLOCKED_DONOR = gql`
  mutation createBlockedDonor(
    $emails: [String!]!
    $organizationRevvUid: String!
    $expirationDate: String
    $reason: String
  ) {
    candidateBulkBlockDonors(
      emails: $emails
      organizationRevvUid: $organizationRevvUid
      expirationDate: $expirationDate
      reason: $reason
    ) {
      message
    }
  }
`;

export const UNBLOCK_BLOCKED_DONOR = gql`
  mutation unblockBlockedDonor($email: String!, $organizationRevvUid: String!) {
    candidateUnblockDonor(email: $email, organizationRevvUid: $organizationRevvUid) {
      message
      errors
    }
  }
`;

export const GET_AUTO_BLOCKED_DONOR_SETTINGS = gql`
  query getAutoBlock($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        maximum_contribution_cents
        auto_block_donors_settings {
          auto_blocked_donors_maximum_contribution_cents
          enable_auto_blocked_donors
          end_date
          start_date
        }
      }
    }
  }
`;

export const GET_AUTO_BLOCK_ACTIVITY = gql`
  query getAutoBlock($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        auto_block_activity {
          date
          email
          type
        }
      }
    }
  }
`;

export const CREATE_AUTOMATIC_BLOCKED_DONORS = gql`
  mutation candidateCreateAutoBlockDonors(
    $startDate: String!
    $endDate: String!
    $autoBlockedDonorsMaximumContributionCents: Int!
    $organizationRevvUid: String
  ) {
    candidateCreateAutoBlockDonors(
      startDate: $startDate
      endDate: $endDate
      autoBlockedDonorsMaximumContributionCents: $autoBlockedDonorsMaximumContributionCents
      organizationRevvUid: $organizationRevvUid
    ) {
      errors
      dammed
      message
      autoBlockDonorsSettings {
        enable_auto_blocked_donors
        start_date
        end_date
        auto_blocked_donors_maximum_contribution_cents
      }
    }
  }
`;
export const END_AUTOMATIC_BLOCKED_DONORS = gql`
  mutation candidateEndAutoBlockDonors($organizationRevvUid: String) {
    candidateEndAutoBlockDonors(organizationRevvUid: $organizationRevvUid) {
      errors
      dammed
      message
      autoBlockDonorsSettings {
        enable_auto_blocked_donors
        start_date
        end_date
      }
    }
  }
`;
