import React from 'react';
import PropTypes from 'prop-types';

import { FormContainer, FormColumn, FormCard, Typography, Input } from 'modules/common';

function CommitteeDisconnectForm({ committee, disconnectText, loading, onChange }) {
  return (
    <FormContainer>
      <FormColumn>
        <FormCard
          title={`Are you sure you want to disconnect from ${committee.name}?`}
          subtitle="Disconnecting will remove all Bill-Pays from pages and upsells."
        >
          <Input
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            description="Bill-Pays associated with existing Subscriptions will remain. New subscriptions will not have a Bill-Pay associated."
            disabled={loading}
            name="disconnectText"
            placeholder="DISCONNECT"
            spellCheck="false"
            title={
              <Typography className="m-0 font-medium">
                Confirm by typing <span className="text-winred-red">DISCONNECT</span> below.
              </Typography>
            }
            type="text"
            value={disconnectText}
            onChange={onChange}
          />
        </FormCard>
      </FormColumn>
    </FormContainer>
  );
}

CommitteeDisconnectForm.propTypes = {
  committee: PropTypes.object.isRequired,
  disconnectText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { CommitteeDisconnectForm };
